
import { isNullOrUndefined } from '.';

export const addQuery = (query) => {
  const location = { ...browserHistory.getCurrentLocation() };
  Object.assign(location.query, query);
  browserHistory.push(location);
};


// NOT USED ?????
export const serializeObjectToQuery = obj => {
  const res = Object.keys(obj)
    .filter(key => !isNullOrUndefined(obj[key]))
    .map(key => `${key  }=${  encodeURIComponent(obj[key])}`)
    .join('&');

  if (res.length) {
    return `?${  res}`;
  }

  return res;
};
