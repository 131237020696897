export default class HiddenItemsFormatter {
  constructor(allItems = [], hiddenItems = []) {
    this.allItems = allItems;
    this.hiddenItems = hiddenItems;
  }

  formatHiddenItemsAsPlainElements = () => {
    return this.allItems.filter(item => this.hiddenItems.includes(item.id));
  }
}
