import * as constants from './constants';

import { hpChartTabs } from 'helpers/chart';
import ResourcesService from 'helpers/resourses/ResourcesService';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';

import { searchMedication, removeRightSideElementPlanPrescription } from 'api/medications';
import { searchDiagnoses } from 'api/diagnoses';
import { fetchCptCodes, fetchHcpcCodes } from 'api/charts';

const functionMap = {
  medications: searchMedication,
  diagnoses: searchDiagnoses,
  [hpChartTabs.BILLING.systems.CPT.resourceType]: fetchCptCodes,
  [BILLING_SYSTEMS.HCPC.resourceType]: fetchHcpcCodes,
};

export const addResources = (resourceType, items) => ({
  type: constants.RESOURCES_ADD,
  payload: {
    resourceType,
    items,
  },
});

export const addResource = (resourceType, item) => ({
  type: constants.RESOURCE_ADD,
  payload: { resourceType, item },
});

export const removeResource = (resourceType, itemId) => ({
  types: [
    constants.RESOURCE_REMOVE,
    constants.RESOURCE_REMOVE_SUCCESS,
    constants.RESOURCE_REMOVE_FAIL,
  ],
  promise: () => removeRightSideElementPlanPrescription(itemId),
  resourceType,
  itemId,
});

export const fetchResources = ({
  systemId, resourceType, searchRequest = '', favorites,
}) => ({
  types: [
    constants.RESOURCES_FETCH,
    constants.RESOURCES_FETCH_SUCCESS,
    constants.RESOURCES_FETCH_FAIL,
  ],
  promise: async () => {
    const fetchResourceResponse = await functionMap[resourceType](searchRequest, favorites);
    const resourcesProducer = new ResourcesService(resourceType);

    return resourcesProducer.fetchResourcesResponseParse(fetchResourceResponse);
  },
  resourceType,
  systemId,
});

export const fetchResourcesNoCaching = (data) => async () => {
  const {
    resourceType,
    searchRequest = '',
    favorites
  } = data;

  const fetchResourceResponse = await functionMap[resourceType](searchRequest, favorites);
  const resourcesProducer = new ResourcesService(resourceType);

  return resourcesProducer.fetchResourcesResponseParse(fetchResourceResponse);
};
