import * as providersConstants from 'helpers/providers/constants';

export const getProviderOptions = (providers, isFetched) => {
  let options = [];

  if (!isFetched) return options;

  options = providers.map((provider) => {
    return {
      value: provider.id,
      label: `${provider.firstName} ${provider.lastName}`,
    };
  });

  return options;
};

export const getDashboardProvidersOptions = (optionsFromServer) => {
  return [
    ...optionsFromServer,
    providersConstants.ALL_PROVIDERS_ITEM,
  ];
};

export const getDashboardActiveProviderOption = (options, activeProviderId, userProviderId) => {
  const defaultProviderId = activeProviderId === null ? userProviderId : activeProviderId;

  return options.filter(o => o.value === defaultProviderId)[0];
};

export const getChartActiveProviderOption = (options, activeProviderId, userProviderId) => {
  const defaultProviderId = activeProviderId || userProviderId;

  return options.filter(o => o.value === defaultProviderId)[0];
};

export const fetchProvidersRequestData = () => {
  return {
    permissionNames: providersConstants.PERMISSIONS_FOR_PROVIDER,
  };
};

export const isCurrentUserProvider = (permissionsFromContext) => {
  return permissionsFromContext.signEncounterNote;
};
