import React from 'react';
import PropTypes from 'prop-types';

import AdditionalCheckboxConnector from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxConnector';

import AdditionalEmptyMessageCheckbox from 'components/FMSSPage/AdditionalEmptyMessageCheckbox';
import UserEditForm from 'components/UserEditForm';
import Loader from 'components/Loader';
import AllergyForm from '../AllergyForm';
import allergyContainer from '../AllergyContainer';

import { FMSS_TYPES } from 'helpers/fmss/constants';

const Allergy = allergyContainer(AllergyForm);

const AdditionalEmptyMessageFamilyCheckboxWrapper = AdditionalCheckboxConnector(
  AdditionalEmptyMessageCheckbox, FMSS_TYPES.ALLERGY
);

const AllergiesPage = ({
  children,
  patientId,
  hasActiveAllergies,
  isInitialized,
  allergyId,
  createAllergy,
  updateAllergy,
  deleteAllergy,
  linkBase,
  isDrChronoAttached,
}) => {
  if (!isInitialized) return <Loader />;

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Active allergies', indexLink: true },
        { url: { pathname: `${linkBase}/inactive` }, name: 'Inactive allergies' },
      ]}
      labelVisible={!hasActiveAllergies}
      labelText="No active allergies"
      customLabelRenderer={<AdditionalEmptyMessageFamilyCheckboxWrapper isDrChronoAttached={isDrChronoAttached} label="No known drug allergies" patientId={patientId} />}
      formComponent={
        <Allergy
          allergyId={allergyId}
          patientId={patientId}
          onCreate={createAllergy}
          onUpdate={updateAllergy}
          onDelete={deleteAllergy}
        />
      }
    >
      {children}
    </UserEditForm.Root>
  );
};

AllergiesPage.propTypes = {
  children: PropTypes.node.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasActiveAllergies: PropTypes.bool.isRequired,
  isInitialized: PropTypes.bool.isRequired,
  allergyId: PropTypes.string,
  createAllergy: PropTypes.func.isRequired,
  updateAllergy: PropTypes.func.isRequired,
  deleteAllergy: PropTypes.func.isRequired,
  linkBase: PropTypes.string.isRequired,
  isDrChronoAttached: PropTypes.bool.isRequired,
};

export default AllergiesPage;
