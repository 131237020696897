import React from 'react';
import { number, func, node } from 'prop-types';
import { compose } from 'redux';
import { Outlet, useNavigate, useParams } from 'react-router';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PersonShortInfo from 'components/PersonShortInfo';
import Tabs from 'components/Tabs';
import Loader from 'components/Loader';

import patientContainer, { fetchLayerHOC } from 'containers/Patients/PatientContainer';
import cx from './PatientPage.module.scss';


class PatientPage extends React.Component {
  componentDidMount() {
    const {
      patientId,
      addRecentPatient,
      navigate
    } = this.props;

    const {
      permissions: { createEditPatients }
    } = this.context;

    if (patientId === 'new' && !createEditPatients) {
      navigate('/app/doctor');
    }

    if (patientId !== 'new') {
      addRecentPatient(patientId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.patientId !== nextProps.patientId && nextProps.patientId && nextProps.patientId !== 'new') {
      nextProps.addRecentPatient(nextProps.patientId);
    }
  }

  render() {
    const {
      patient,
      patientId,
      children,
    } = this.props;

    const { permissions } = this.context;

    const isCreatingPatient = patientId === 'new';

    if (!patient) {
      return <Loader />;
    }

    if (isCreatingPatient && !permissions.createEditPatients) return null;

    const tabsHead = [{
      title: 'Demographics',
      url: `/app/doctor/patients/${patientId}`,
      indexLink: true
    }, {
      title: 'Encounters Hx',
      url: `/app/doctor/patients/${patientId}/encounters`,
      disabled: isCreatingPatient || !permissions.viewEditPatientClinicalInformation,
    }, {
      title: 'Medication List',
      url: `/app/doctor/patients/${patientId}/medications`,
      disabled: isCreatingPatient || !permissions.viewEditPatientClinicalInformation,
    }, {
      title: 'Allergy List',
      url: `/app/doctor/patients/${patientId}/allergies`,
      disabled: isCreatingPatient || !permissions.viewEditPatientClinicalInformation,
    }, {
      title: 'F/M/S/S/S Hx',
      url: `/app/doctor/patients/${patientId}/fmss`,
      disabled: isCreatingPatient || !permissions.viewEditPatientClinicalInformation,
    }, {
      title: 'diagnosis List',
      url: `/app/doctor/patients/${patientId}/diagnoses`,
      disabled: isCreatingPatient || !permissions.viewEditPatientClinicalInformation,
    }];

    return (
      <div className={cx.wrapper}>
        <PersonShortInfo
          person={patient}
        />
        <div className={cx.tabsWrapper}>
          <Tabs headerItems={tabsHead}>
            {children}
          </Tabs>
        </div>
      </div>
    );
  }
}

PatientPage.contextType = AppContext;

PatientPage.propTypes = {
  patientId: number.isRequired,
  navigate: func.isRequired,
  addRecentPatient: func.isRequired,
  children: node.isRequired
};

const PatientComponent = compose(
  patientContainer,
  fetchLayerHOC
)(PatientPage);

const Wrapper = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  return (
    <PatientComponent
      patientId={patientId}
      navigate={navigate}
    >
      <Outlet context={[patientId]} />
    </PatientComponent>
  );
};


export default Wrapper;
