import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImageStyle from 'helpers/images/styles/background-image';

import cx from './ProfileImage.module.scss';

const ProfileImage = ({
  imageUrl = null,
}) => (
  <div
    className={cx['wrapper']}
    style={{
      ...BackgroundImageStyle.define(imageUrl),
    }}
  />
);

ProfileImage.propTypes = {
  imageUrl: PropTypes.string,
};

export default ProfileImage;
