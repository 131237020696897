import React from 'react';
import PropTypes from 'prop-types';

import cx from './CalendarIcon.module.scss';

const CalendarIcon = (props) => {
  const {
    onClick,
  } = props;

  return (
    <div
      className={cx['calendar-icon']}
      onClick={onClick}
    />
  );
};

CalendarIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CalendarIcon;