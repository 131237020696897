import React from 'react';
import { compose } from 'redux';

import { fetchLayerHOC as paymentInfoFetchLayer } from 'containers/UserPaymentInfoContainer';

import PasswordConfirm from 'components/Modal/PasswordConfirm';

const PasswordConfirmWithCard = (props) => {
  return <PasswordConfirm {...props} />;
};

const PasswordConfirmWithCardWrapper = compose(
  paymentInfoFetchLayer,
)(PasswordConfirmWithCard);

export default PasswordConfirmWithCardWrapper;
