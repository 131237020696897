import React from 'react';
import { Outlet } from 'react-router-dom';

import Tabs from 'components/Tabs';

const tabsHead = [
  {
    title: 'Manager',
    url: '/app/admin/user',
    indexLink: true,
    disabled: false,
  },
  // {
  //   title: 'Access Login',
  //   url: '/admin/user/access-login',
  //   disabled: false,
  // }
];

export const UserTab = () => {
  return (
    <Tabs headerItems={tabsHead}>
      <Outlet />
    </Tabs>
  );
};
