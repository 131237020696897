import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PatientAge from 'components/PatientAge';

import getGenderValue from 'helpers/elements/tags/converters/gender';

import cx from './About.module.scss';

const About = (props) => {
  const {
    firstName,
    lastName,
    gender = null,
    dob = null,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const genderValue = getGenderValue(gender);

  return (
    <div className={cx['info']}>
      <h3 className={cx['username']}>{firstName} {lastName}</h3>
      <div className={cx['about']}>
        {gender && <span className={cx['about-item']}>{genderValue}</span>}
        {dob && (
          <PatientAge
            dob={dob}
            color={PatientAge.colors.light_blue}
          />
        )}
        {dob && <span className={cx['about-item']}>{timeZoneDateConverter.formattedDob(dob)}</span>}
      </div>
    </div>
  );
};

About.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  gender: PropTypes.string,
  dob: PropTypes.number,
};

export default About;
