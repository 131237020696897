export const FMSS_TYPES = {
  FAMILY: 'family',
  MEDICAL: 'medical',
  SURGICAL: 'surgical',
  ALLERGY: 'allergy',
  PROGRESS_NOTE_FAMILY: 'progress_note_family',
  PROGRESS_NOTE_MEDICAL: 'progress_note_medical',
  PROGRESS_NOTE_SURGICAL: 'progress_note_surgical',
};

export const FMSS_NO_KNOWN_ENTITY = {
  ALLERGY: 'No Known Allergy',
  ALLERGY_DR_CHRONO: 'No Known Drug Allergies (NKDA)',
};
