import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';

import orderBy from 'lodash/orderBy';

import rolesContainer, { fetchLayerHOC as fetchRoles } from 'containers/RolesContainer';

import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import EmptyMessage from 'components/UserEditForm/EmptyMessage';
import UserRow from '../UserRow';
import { AddUserButton } from './AddUserButton';

import ChoiceLinkToNavigationToUser from 'helpers/users/ChoiceLinkToNavigationToUser';


const tableColumns = [
  {
    title: '',
    subtitle: '',
    sortable: false,
    width: '25%',
  },
  {
    title: 'user',
    subtitle: 'Name',
    sortable: true,
    width: '35%',
  },
  {
    title: 'role',
    subtitle: 'Type',
    sortable: false,
    width: '40%',
  },
];

class UsersTable extends Component {
  state = {
    sortBy: 'user',
    desc: false,
  };

  setSortBy = (column) => {
    if (this.state.sortBy === column) {
      this.setState({ desc: !this.state.desc });
      return;
    }
    this.setState({
      sortBy: column,
      desc: false,
    });
  };

  getUpdateRoleFunk = (userId, currentUserId) => {
    if (userId === currentUserId) {
      return this.props.updatePermissions;
    }

    return this.props.updateUserPermissions;
  };

  render() {
    const {
      users,
      currentUserId,
      updateUserPermissionsLocal,
      showModal,
      roles,
      location,
      route,
      active
    } = this.props;

    const selectedUser = parseInt(location.query?.userId);

    const {
      setSortBy,
    } = this;

    const { desc, sortBy } = this.state;

    const headerColumns = tableColumns.slice(1);
    const firstColumn = tableColumns[0];

    if (!users.length) {
      return (
        <div>
          <EmptyMessage>{`No ${(active && 'active') || 'inactive'} Users`}</EmptyMessage>
        </div>
      );
    }

    const direction = desc ? 'desc' : 'asc';

    const renderedItems = orderBy(users.map(user => ({
      ...user,
      user: `${user.firstName} ${user.lastName}`,
    })), sortBy, direction);

    const navigateToUser = ({ id, enable }) => {
      const { router: { push } } = this.props;

      push({
        pathname: ChoiceLinkToNavigationToUser.choose(enable),
        query: { userId: id }
      });
    };

    return (
      <Table color="blue">
        <thead>
          <tr>
            <TableHead
              {...firstColumn}
            >
              {active && <AddUserButton />}
            </TableHead>
            {headerColumns.map((column, index) => (
              <TableHead
                {...column}
                key={index}
                onClick={() => setSortBy(column.title)}
                desc={column.title === sortBy ? desc : undefined}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {renderedItems.map(
            user => (
              <UserRow
                key={user.id}
                roles={roles}
                fields={tableColumns}
                user={user}
                onClick={() => navigateToUser({ id: user.id, enable: user.enable })}
                isSelected={user.id === selectedUser}
                isCurrentUser={user.id === currentUserId}
                onRoleChange={this.getUpdateRoleFunk(user.id, currentUserId)}
                updateUserPermissionsLocal={updateUserPermissionsLocal}
                showModal={showModal}
              />
            )
          )}
        </tbody>
      </Table>
    );
  }
}

UsersTable.propTypes = {
  updatePermissions: PropTypes.func.isRequired,
  updateUserPermissions: PropTypes.func.isRequired,
  updateUserPermissionsLocal: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: func
  }).isRequired
};

export default compose(
  withRouter,
  rolesContainer,
  fetchRoles
)(UsersTable);
