import React from 'react';
import cx from 'styles/Document.module.scss';

const TermsOfUse = () => (
  <div className={cx['page-container']}>
    <h1 className={cx['page-title']}>
      Terms of Use
    </h1>

    <div className={cx['paragraph']}>
    These terms of use (these “Terms”) apply to visitors of EHRsynergy's (“we” “us” or “our”) website located at https://ehrsynergy.com (our “Site”) as well as registered users of our EHRsynergy®-branded information and technology System accessible through the Site and related mobile applications (our System). Please review these Terms carefully. Please also review our EHRsynergy Privacy Policy respectively, which describe how we and our partners collect, use, and share certain information when you use our System. By accessing our System, you are agreeing to these Terms and the applicable privacy policy.
    </div>

    <h2 className={cx['sub-header']}>
    1. Accessing our System
    </h2>

    <h3 className={cx['sub-header']}>
    1.1 Public Services
    </h3>

    <div className={cx['paragraph']}>
    We may make some Services available without registering or obtaining a password. We call these “Public Services.” You may make personal, non-commercial use of the Public Services so long as you comply with these Terms. This personal use includes linking to information appearing in the Public Services, provided you do not represent yourself as an employee, agent or representative of EHRsynergy, LLC. Personal use also includes temporary caching by your browser or a proxy server.
    </div>

    <div className={cx['paragraph']}>
    We reserve all rights not expressly granted to you. This means that if you wish to use the Public Services in a way that is not authorized above, you must receive our permission prior to such use.
    </div>

    <h3 className={cx['sub-header']}>
    1.2 Protected Services
    </h3>

    <div className={cx['paragraph']}>
    Some of our services are protected by technical measures intended to safeguard the confidentiality, integrity and accessibility of sensitive information our users store and share using our System. We call these “Protected Services”. One such safeguard is requiring each user to be authenticated by means of authorization (which we call, “Credentials”), such as unique identifiers, API keys, passwords, and the like. In order to obtain Credentials, you must provide certain information about yourself. If you are registering for a Protected Service, or accessing or using, or attempting to access or use, a Protected Service on behalf of, or for the benefit of, someone other than yourself – such as your employer, client or customer (each, a “Principal”) – you must also identify and provide information about each Principal.
    </div>

    <div className={cx['paragraph']}>
    If you sign up for one of our Protected Services you may be asked to agree to a user agreement, such as our Healthcare Provider User Agreement (your “User Agreement”). In such cases, you will be asked to agree to the terms of your User Agreement, for example, by checking a box or clicking on a button with terms such as “I agree” or the like. In the event of a conflict between the terms of your User Agreement and of these Terms, the terms of your User Agreement shall control.
    </div>

    <div className={cx['paragraph']}>
    Your Credentials are unique to you. You should immediately notify us by contacting our support team if your Credentials have been stolen or compromised. You are responsible for all activities that occur under your Credentials until you have properly notified us that your Credentials have been stolen or compromised. Further, you represent, warrant and covenant that:
      <ul className={cx['list']}>
        <li>
          the information you submit in obtaining your Credentials is complete and accurate and identifies you and the name of each of your Principals;
        </li>
        <li>
          you will not share your Credentials with anyone else;
        </li>
        <li>
          you will not circumvent, or attempt to circumvent, any technical measures that we have put in place to safeguard the Protected Services;
        </li>
        <li>
          you will not, without our prior written approval, access or use, or attempt to access or use, any portion of the Protected Services other than with (i) a commercial browser (such as Chrome, Internet Explorer or Mozilla Firefox), (ii) an application made for mobile or handheld device(s) that is developed and distributed by us, or (iii) our application programming interface (“API”) using Credentials issued to you directly by us, and only us; and
        </li>
        <li>
          You will not access or use, or attempt to access or use, a Protected Service without validly-issued active Credentials.
        </li>
      </ul>
    </div>

    <div className={cx['paragraph']}>
    We reserve the right to suspend or terminate your access to any Protected Service at any time, with or without cause or notice. We shall not be liable to you in any way if we suspend or terminate your access to a Protected Service or our System.
    </div>

    <h3 className={cx['sub-header']}>
    1.3 Additional Safeguards
    </h3>

    <div className={cx['paragraph']}>
    To further protect the confidentiality, integrity and availability of the information housed and shared in our System, as well as the stability of our System, you agree to the following additional safeguards. Accordingly, you agree that you will not, nor will you attempt to:
      <ul className={cx['list']}>
        <li>
      access, use or disseminate our System, nor any information or files accessible via our System, in a manner that violates any applicable law or regulation or the rights of any individual or entity;
        </li>
        <li>
      sell or transfer any information included in our System or use such information to market any product or service – including by sending, or facilitating the sending of, unsolicited emails or so-called “spam;”
        </li>
        <li>
      probe, scan or test the vulnerability of our System, or of the system or network supporting our System, or circumvent any security or authentication measures;
        </li>
        <li>
      disable, bypass, defeat, avoid, remove, deactivate or otherwise circumvent any technical measures we have implemented to safeguard the stability of our System, or the confidentiality, integrity or availability of any information, content or data hosted or housed in our System;
        </li>
        <li>
      introduce to our System any software, code or other device that in any way (i) permits unauthorized access to our systems or any software, hardware, files or data located thereon, (ii) disables or damages or otherwise interferes with or adversely affects the operation of our systems or any software, hardware, files or data located thereon, or (iii) overburdens or interferes with the proper functioning of our System;
        </li>
        <li>
      disassemble, decompile or reverse engineer our System;
        </li>
        <li>
      harvest, retrieve, index or publish any portion of our System unless you are a Public Search Engine engaging in Public Search Services;
        </li>
        <li>
      disable or circumvent our API usage safeguards, including safeguards designed to regulate the nature or amount of data you are permitted to extract from our System, or the frequency of which you may access such data; or make calls to our API other than those authorized in our API documentation;
        </li>
        <li>
      remove any copyright, trademark or other proprietary rights notices contained in our System; or
        </li>
        <li>
      engage in any activity other than those expressly permitted in these Terms and your User Agreement(s).
        </li>
      </ul>
    </div>

    <h3 className={cx['sub-header']}>
    1.4 Use of the System by and on behalf of Minors
    </h3>

    <div className={cx['paragraph']}>
    You are not eligible to use our System (including obtaining a Credential or entering into a User Agreement) unless you are at least 18 years old and otherwise have the legal capacity to enter into a binding contract in your jurisdiction.
    </div>

    <div className={cx['paragraph']}>
    If you are the parent or guardian of an unemancipated minor, you may use the System and enter into a User Agreement on behalf of such minor. By doing so, you represent and warrant that you have the legal capacity to act on behalf of such minor; and you acknowledge and agree, in such capacity, that all provisions of these Terms (and User Agreement, if applicable) that applicable to you are equally applicable to such minor.
    Under no circumstances may our System be used by a child under 13 years old.
    </div>

    <h3 className={cx['sub-header']}>
    1.5 United States Only
    </h3>

    <div className={cx['paragraph']}>
    Access to our System is administered in the United States (“US”) and is intended for users in the US. You may not use our System in any jurisdiction where offering, accessing or using our System would be illegal or unlawful.
    </div>

    <h1 className={cx['header']}>
    2. Nature of the Content Appearing in our System
    </h1>

    <h3 className={cx['sub-header']}>
    2.1 Overview
    </h3>

    <div className={cx['paragraph']}>
    Our System may include text, data, graphics, images, video or other content (collectively, “Content”) created by us or third parties, including other users, professionals, partners, advertisers, sponsors, consumers and governmental agencies. The Content is provided for general informational purposes, but should not be relied upon for personal, professional, medical or regulatory decisions. And we do not ensure the completeness, timeliness or accuracy of the Content.
    </div>

    <h3 className={cx['sub-header']}>
    2.2 Blog Posts; Ratings; Surveys; Comments; User-Submitted Content
    </h3>

    <div className={cx['paragraph']}>
    Our System may allow you to access blogs, message boards, chat services, surveys and other forums where various users can share information, opinions, chats and other Content. We generally do not pre-screen or monitor user-submitted Content, and such Content may simply represent a user’s opinion or Content a user finds interesting. Our System may also include survey results, ratings or testimonials (“Evaluations”) from patients, clients or other customers (“Patients”) of healthcare professionals (“Professionals”) that may endorse, recommend, critique, analyze, evaluate or otherwise describe the Professionals and the nature or quality of the services received by such patient, client or customer. Such Evaluations are anecdotal first-hand accounts of individual Patients, and are neither professional judgments nor the product of medical science. Such Evaluations do not in any way constitute or imply our endorsement or recommendation of any Professional. Further, Evaluations are subject to errors and biases that are common in anecdotal first-hand accounts, and should not to be presumed to be reliable or error-free.
    </div>

    <h3 className={cx['sub-header']}>
    2.3 Directories: Physicians, Consultants, and Other Professionals
    </h3>

    <div className={cx['paragraph']}>
    Our System may include listings and directories (“Directories”) to help you Professionals. The Directories are provided for your convenience. The Directories are not comprehensive, but rather generally represent Professionals who use our System and who have chosen to participate in the Directories. Further, we do not evaluate any Professional and the listing of a Professional does not in any way constitute a recommendation of such Professional. Before obtaining services or treatment from any Professional listed in a Directory, you should take the same care you would under any other circumstance, including by confirming licensure and specialty certifications. The Professionals are solely responsible for the appropriateness and quality of the services they provide. Additionally, the Directories rely on information submitted by Professionals themselves. Unless Professionals provide us with current information, the Directory information may not be timely or accurate. You should confirm such information before obtaining services or treatment from a Professional. As a convenience, the System may permit you to request an appointment with a Professional. However, Professionals are responsible for maintaining their own schedules, and we cannot ensure that any given Professional will be available, nor that such Professional will not cancel his or her appointment.
    </div>

    <h3 className={cx['sub-header']}>
    2.4 Advertising
    </h3>

    <div className={cx['paragraph']}>
    Our System may include advertisements or promotional messages sponsored by third parties (collectively, “Ads”). The manufacturers, services providers, distributors and resellers of the products and services identified in the Ads are solely responsible for the accuracy of their Ads and the suitability, efficacy, safety and quality of such products or services. An Ad does not in any way constitute or imply our recommendation or endorsement of such product or service.
    </div>

    <h3 className={cx['sub-header']}>
    2.5 Links to Other Sites
    </h3>

    <div className={cx['paragraph']}>
    Our System may contain hyperlinks (including hyperlinked Ads) to websites operated by third parties, or to materials or information made available by third parties. Such links do not constitute or imply our endorsement of such third parties, or of the content of their sites, or the suitability, efficacy, safety or quality of their products or services, or the information privacy or security practices of their websites.
    </div>

    <h3 className={cx['sub-header']}>
    2.6 No Medical Advice
    </h3>

    <div className={cx['paragraph']}>
    Some Content may include health- or medical-related information. Such Content is provided for general informational purposes only. We do not directly or indirectly practice medicine, render medical advice, or dispense medical services via our System or otherwise, and nothing contained in our System should be intended to be a medical diagnosis or treatment. No medical professional/patient relationship is created by your use of our System or the Content. Always seek the advice of your physician or other qualified health professional with any questions you may have regarding a medical condition, and never disregard professional medical advice or delay seeking treatment based on any Content or other information included in the System. If you think you may have a medical emergency, call your healthcare professional or your local emergency number (usually 911) immediately.
    </div>

    <h3 className={cx['sub-header']}>
    2.7 Clinical Decision Support Information
    </h3>

    <div className={cx['paragraph']}>
    If you are a Professional, the Content may include information to assist you in clinical decision-making. This may include information and reminders concerning drug interactions, allergies, dosages, as well as general healthcare related information and resources, such as assessments. We may also provide forums for our users to exchange information. The information and materials available through our System are for informational purposes only and are not intended to constitute professional advice, diagnosis or treatment, or to substitute for your professional judgment.
    </div>

    <h3 className={cx['sub-header']}>
    2.8 No Legal or Regulatory Advice
    </h3>

    <div className={cx['paragraph']}>
    Some Content may include regulatory related information pertaining to you or your business. Such Content is provided for informational purposes only. We are not providing legal or regulatory advice and no attorney/client relationship is created by your use of our System or the Content. Accordingly, always seek the advice of your attorney or advisor with any questions you may have regarding a law, regulation, or dispute.
    </div>


    <h1 className={cx['header']}>
    3. Your Interactions and Communications with Us and Our System
    </h1>

    <h3 className={cx['sub-header']}>
    3.1 Visiting Our Website; Signing-Up for Our System; Emailing Us
    </h3>

    <div className={cx['paragraph']}>
    When you sign-up for or log-in to one of our Protected Services, or when you submit information on a web form or email or instant message us, you are communicating with us electronically. When you visit our website, we and certain third-party service providers collect information during your visit. In each case, the nature of the information we collect, the duration and manner of how we retain it, how we use it, and to whom we disclose it, is governed by our EHRsynergy Privacy Policy.
    </div>

    <div className={cx['paragraph']}>
    As part of providing our System to you, we may need to provide you with certain communications, such as service announcements and administrative messages. Such communication may be by email, instant message, a posting in our System or some other mechanism. You consent to receive such communications from us electronically. If you have submitted information to us through the System or registered for one or more of the Protected Services, our responses to you or our Service announcements and administrative messages we provide you are considered part of the System themselves, which you may not be able to opt-out from.
    </div>

    <div className={cx['paragraph']}>
    You agree that all agreements, notices, authorizations, disclosures and other communications that we provide to you electronically, as well as any acceptances, agreements, consents or authorizations that you provide to us electronically, satisfy any and all legal requirement(s) that such communications be in writing.
    </div>

    <h3 className={cx['sub-header']}>
    3.2 Your Participation in Our Public Forums
    </h3>

    <div className={cx['paragraph']}>
    We may offer one or more forums for the exchange of information among our users. You acknowledge that any text, data, graphics, images, video or other content (“Content”) that you submit in any of our forums (including discussion groups, blogs, surveys, ratings, comment forms, or message boards, collectively, “Public Forums”) is available to the public. Notwithstanding the foregoing, we are under no obligation to display any of your Content that you submit, and we reserve the right to remove or edit your Content at any time, for any or no reason.
    </div>

    <div className={cx['paragraph']}>
    It is important that you act responsibly when submitting Content to a Public Forum. You acknowledge that any Content that you submit in a Public Forum is available to the public. You are solely responsible for any Content that you post on the Public Forums or transmit to other users of our System. You acknowledge that any information you post in a Public Forum may be available to the public, and may result in your receiving communications from others outside our System.
    </div>

    <div className={cx['paragraph']}>
    Your participation in our Public Forums is contingent on your acknowledgment and agreement with the following:
      <ul className={cx['list']}>
        <li>
      You will only disclose information about yourself on a Public Forum that you consider suitable for public disclosure. You will not disclose information that personally identifies you unless you intend for that information to be disclosed publicly. We strongly recommend that you refrain from disclosing any sensitive information about yourself on a Public Forum, including information about any medical condition.
        </li>
        <li>
      You will not violate the privacy rights of others, including disclosing information about anyone else’s medical or financial condition or any other sensitive subjects.
        </li>
        <li>
      You will ensure that any Content that you submit to Public Forums is accurate. If you are rating or reviewing a Professional, you agree to provide your honest appraisals of such Professional, without using inappropriate language or making gratuitous personal criticisms.
        </li>
        <li>
      You will not post any Content that you do not have the right to post; you will not violate any person’s or entity’s intellectual property or proprietary rights, including copyrights, trademarks or trade secret rights.
        </li>
        <li>
      We will not be liable to you for any Content you submit to any Public Forum.
        </li>
      </ul>
    </div>

    <h3 className={cx['sub-header']}>
    3.3 Removal of Content
    </h3>

    <div className={cx['paragraph']}>
    You understand and agree that we may, but are not obligated to, monitor, edit or remove any Content for any or no reason at any time. We are not responsible, however, for any delay or failure in removing any Content.
    </div>

    <h3 className={cx['sub-header']}>
    3.4 Reporting Violations
    </h3>

    <div className={cx['paragraph']}>
    We may provide you with tools with which to report violations of the Community Standards or other provisions of these Terms. Notwithstanding the availability of such tools, you acknowledge and agree that we are under no obligation to take any action with respect to any such report.
    </div>

    <h3 className={cx['sub-header']}>
    3.5 Copyright Policy
    </h3>

    <div className={cx['paragraph']}>
    We will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to us. If you believe that your Content has been copied in a way that constitutes copyright infringement, please provide us with the following information:
      <ul className={cx['list']}>
        <li>
        A physical or electronic signature of the copyright owner or a person authorized to act on behalf of the owner, if someone other than you, of an exclusive right that is allegedly infringed;
        </li>
        <li>
        Identification of the copyrighted work claimed to have been infringed;
        </li>
        <li>
        Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;
        </li>
        <li>
        A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
        </li>
        <li>
        A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner; and
        </li>
        <li>
        Your contact information, including your address, telephone number, and email address.
        </li>
      </ul>
    </div>

    <div className={cx['paragraph']}>
    We reserve the right to remove Content alleged to be infringing without prior notice, at our sole discretion, and without liability to you. In appropriate circumstances, EHRsynergy will also terminate a user’s account if the user is determined to be a repeat infringer.
    </div>

    <div className={cx['paragraph']}>
    Our designated copyright agent for notice of alleged copyright infringement is:
    </div>


    <div className={cx['paragraph']}>
    EHRsynergy, LLC <br />
    1736 E. Charleston Blvd, Ste 731 <br />
    Las Vegas, NV 89104
    </div>

    <h1 className={cx['header']}>
    4. Miscellaneous
    </h1>


    <h3 className={cx['sub-header']}>
    4.1 Ownership
    </h3>

    <div className={cx['paragraph']}>
    You retain ownership of the intellectual property rights you hold in Content you submit into our System. When you submit Content into our System, you thereby grant us and those we work with a worldwide, perpetual, royalty-free right to store, host, reproduce, create derivative works of (such as translations, adaptations, reformatted versions and aggregated, anonymized or de-identified versions), publish, publicly perform, display, use and distribute such Content as further described in these Terms, your User Agreement and the EHRsynergy Privacy Policy, as applicable.
    </div>

    <div className={cx['paragraph']}>
    If you submit to us any ideas, suggestions or proposals (collectively, “Suggestions”) relating to our System or other products or services by any means – such as through “Contact Us,” by email or other communication channels, one of our communities or user forums, or to our customer support or other personnel.  With respect to such Suggestions (1) we are under no obligation of confidentiality, express or implied, with respect to such Suggestions; (2) we are entitled to use or disclose (or choose not to use or disclose) such Suggestions in any way and for any purpose; (3) such Suggestions automatically become our property without any obligation; and (4) you are not entitled to any accounting, compensation or reimbursement of any kind from us under any circumstances.
    </div>

    <div className={cx['paragraph']}>
    Except for your Content, as between you and us, all right, title and interest in and to our System, the Content, and the structure, organization and arrangement thereof, are and remain the exclusive property of us and our licensors. Except for the limited specific rights we expressly grant you above, you may not reproduce, modify or prepare derivative works based upon, distribute, sell, transfer, publicly display, publicly perform, exploit or otherwise use our System or any Content.
    </div>

    <h3 className={cx['sub-header']}>
    4.2. Violations
    </h3>

    <div className={cx['paragraph']}>
    We reserve the right to monitor any and all use of our System, and investigate any activity we suspect violates these Terms, a User Agreement, our rights or interest, or the rights or interests of any person or entity.
    </div>

    <div className={cx['paragraph']}>
    We reserve the right, to the fullest extent permitted under law, to cooperate with any governmental authority or third party investigating conduct that may be illegal or harm any individual or entity or violates their respective rights. If, for example, a user threatens to physically harm another user or any other individual, we reserve the right to fully cooperate with law enforcement authorities and the threatened individual. You hereby consent to our cooperation in such investigation.
    </div>

    <h3 className={cx['sub-header']}>
    4.3 Indemnification
    </h3>

    <div className={cx['paragraph']}>
      You will indemnify, defend and hold harmless EHRsynergy and any of its affiliates, officers, directors, employees, agents, representatives, partners and licensors from and against any and all claims, actions, proceedings, suits, liabilities, damages, settlements, penalties, fines, losses, or expenses, including attorneys’ fees, costs and disbursements, arising out of or in any way connected with your use of our System.
    </div>

    <h3 className={cx['sub-header']}>
    4.4 Disputes; Governing Law; Jurisdiction
    </h3>

    <div className={cx['paragraph']}>
    The interpretation of these Terms and the resolution of any disputes arising under these Terms shall be governed by the laws of the State of Nevada, without regard to its conflict of laws provisions. These Terms shall not be governed by the United Nations Convention on Contract for the International Sale of Goods, the application of which is expressly disclaimed. If any action or other proceeding is brought on or in connection with these Terms, you agree to submit to the personal jurisdiction of the state and federal courts located in the City and County of Las Vegas in the State of Nevada, and agree not to bring any action relating to the use of our System or to these of these Terms in any court in any jurisdiction other than the state or federal courts located in the county of Las Vegas, State of Nevada. We shall have the right to commence and prosecute any legal or equitable action or proceeding before any US or non-US court of competent jurisdiction to enforce these Terms or to protect our or any third party’s rights in our State of Nevada or any data, information or other content made available via our State of Nevada. You hereby waive any right to a jury trial. You also agree that we may bring suit in court to enjoin any violation of these Terms without the posting of a bond or security, in addition to whatever remedies we might have at law. In any dispute between you and us where we prevail, we shall be entitled to recover our reasonable attorney fees, court costs, disbursements, and other legal expenses.
    </div>

    <h3 className={cx['sub-header']}>
    4.5 Termination
    </h3>

    <div className={cx['paragraph']}>
    You agree and acknowledge that we may suspend or terminate your authorization to access our System, with or without notice or cause, for any or no reason, and without any liability to you. Sections 4.1 through 4.14 shall survive any termination or expiration of these Terms.
    </div>

    <h3 className={cx['sub-header']}>
    4.6 Disclaimers and Limitations on Liability
    </h3>

    <div className={cx['paragraph']}>
    ACCESS TO OUR SYSTEM AND THE INFORMATION AND CONTENT CONTAINED THEREON IS PROVIDED “AS IS” AND “AS AVAILABLE” AND WE HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE. WE DO NOT WARRANT THAT THE SYSTEM WILL BE UNINTERRUPTED, ERROR FREE OR WITHOUT BREACHES OF SECURITY AND YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON OUR SYSTEM OR THE INFORMATION IN OUR SYSTEM, INCLUDING INACCURATE OR INCOMPLETE INFORMATION. WE EXPRESSLY DISCLAIM ANY LIABILITY WITH RESPECT TO ANY INJURY CAUSED BY ANY USER, OR ANY DAMAGE SUFFERED BY ANY USER, AS A RESULT OF THE ACTIONS OR INACTIONS OF ANY OTHER USER. IF YOU ARE DISSATISFIED WITH OUR SYSTEM OR ANY CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING AND ACCESSING OUR SYSTEM. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, SO IN THESE JURISDICTIONS THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO THE USER.
    </div>

    <h3 className={cx['sub-header']}>
    4.7 Risks You Assume
    </h3>

    <div className={cx['paragraph']}>
    WITHOUT LIMITING ANY OF THE OTHER RISKS WE HAVE DISCLOSED TO YOU IN THESE TERMS, YOU ARE SOLELY RESPONSIBLE FOR YOUR USE OF OUR SYSTEM, INCLUDING ANY CONTENT YOU SUBMIT TO US AND YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE AND ACCESS TO OUR SYSTEM AND THE INFORMATION AND CONTENT CONTAINED THEREIN, AND ANY SITES LINKED THROUGH OUR SYSTEM AND ANY DATA TRANSMITTED THROUGH OUR SYSTEM IS AT YOUR SOLE RISK. ACCORDINGLY, WE DO NOT ASSUME ANY LIABILITY TO YOU FOR OR RELATING TO ANY OF YOUR ACTIONS, INCLUDING THE PUBLICATION OF ANY CONTENT YOU SUBMIT OR OUR EXERCISE OF THE RIGHTS YOU GRANT TO US WITH RESPECT THERETO.
    </div>

    <h3 className={cx['sub-header']}>
    4.8 Limitation of Liability
    </h3>

    <div className={cx['paragraph']}>
    IN NO EVENT SHALL WE OR ANY OF OUR AFFILIATES, OR OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS AND LICENSORS (COLLECTIVELY, THE “EHRSYNERGY ENTITIES”) BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF INFORMATION, HOWEVER CAUSED AND WHETHER BASED ON CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF WE HAVE BEEN APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING IN THESE TERMS TO THE CONTRARY, OUR AGGREGATE LIABILITY IN RESPECT OF ANY CLAIM OR ACTION YOU MAY BRING AGAINST US OR ANY OF THE EHRSYNERGY ENTITIES, REGARDLESS OF FORM OF ACTION OR THEORY OF LIABILITY, SHALL BE LIMITED TO THE GREATER OF (1) ONE HUNDRED UNITED STATES DOLLARS (US $100), AND (2) THE AGGREGATE FEES ACTUALLY PAID BY YOU TO US FOR THE SIX (6) MONTH PERIOD PRECEDING THE EVENT FIRST GIVING RISE TO SUCH CLAIM OR ACTION. YOU ACKNOWLEDGE THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE UNKNOWN OR UNSUSPECTED. ACCORDINGLY, YOU AGREE TO WAIVE THE BENEFIT OF ANY LAW, INCLUDING, TO THE EXTENT APPLICABLE, CALIFORNIA CIVIL CODE § 1542 (OR SIMILAR PROVISIONS OF THE LAWS OF OTHER STATES), WHICH STATES,
    </div>

    <div className={cx['paragraph']}>
    A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.
    </div>

    <div className={cx['paragraph']}>
    IN THE CASE OF A JURISDICTION THAT RESTRICTS LIMITATION CLAUSES, THIS LIMITATION SHALL BE APPLIED TO THE GREATEST EXTENT PERMITTED BY LAW. NOTHING IN THESE TERMS OF USE IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE THAT MAY NOT BE LAWFULLY TERMINATED.
    </div>

    <h3 className={cx['sub-header']}>
    4.9 Severability
    </h3>

    <div className={cx['paragraph']}>
    If any provision of these Terms is deemed invalid or unenforceable, then (a) that provision shall be construed to the extent necessary to make it valid and enforceable in such a manner as comes closest to preserving the intentions of such provision, and (b) the remaining provisions shall remain in full force and effect.
    </div>

    <h3 className={cx['sub-header']}>
    4.10 No Waiver
    </h3>

    <div className={cx['paragraph']}>
    Our failure at any time to require performance by you of any provision of these Terms shall in no way affect our right to enforce such provision, nor shall the waiver of any breach by you of any provision herein constitute a waiver of any succeeding breach or the provision itself.
    </div>

    <h3 className={cx['sub-header']}>
    4.11 Interpretation
    </h3>

    <div className={cx['paragraph']}>
    For the purpose of these Terms, “you” means the individual user of our System. If your access or use of our System is, directly or indirectly, on behalf of one or more third parties (such as, without limitation, your employer or client, or your employer’s client, if your employer has been engaged to access our System (any such employer, client or other third party, a “Principal”)), then “you” also refers to such Principal. If you are using our System on behalf of a Principal, (a) you represent and warrant that you have the authority to bind, and have bound, such Principal to these Terms; and (b) you agree to be jointly and severally liable for any breach of these Terms by Principal.
    </div>

    <div className={cx['paragraph']}>
    “EHRsynergy,” “we,” “our,” and “us” means, collectively, EHRsynergy, LLC, and our current and future affiliates.
    </div>

    <div className={cx['paragraph']}>
    In addition, the words “include,” “includes” and “including” shall be deemed to be followed by the phrase “without limitation.” The word “will” shall be construed to have the same meaning and effect as the word “shall.” The word “or” shall be construed to have the same meaning and effect as “and/or.” The words “herein,” “hereof” and “hereunder,” and words of similar import, shall be construed to refer to these Terms. The headings used in these Terms are used for convenience only and are not to be considered in construing or interpreting these Terms.
    </div>

    <div className={cx['paragraph']}>
    These Terms, together with your User Agreement, constitute the entire agreement between you and EHRsynergy regarding any services accessed via our System, and supersede all previous communications, representations, or understandings, either oral or written, relating to the subject matter hereof; provided, however, (a) in the event of an express conflict between any specific provision included in these Terms and an express provision in a User Agreement, the provision set forth in User Agreement shall prevail, and (b) these Terms shall cover all rights, obligations, terms and conditions not expressly addressed in such User Agreement.
    </div>

    <h3 className={cx['sub-header']}>
    4.12 Electronic Contracting
    </h3>

    <div className={cx['paragraph']}>
    Your use of our System includes the ability to enter into agreements and/or to make transactions electronically. YOU ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND BY SUCH AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU ENTER INTO THE SYSTEM, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS.
    </div>

    <h3 className={cx['sub-header']}>
    4.13 Assignment
    </h3>

    <div className={cx['paragraph']}>
    We may freely assign these Terms in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise.
    </div>

    <h3 className={cx['sub-header']}>
    4.14 Amendments
    </h3>

    <div className={cx['paragraph']}>
    We may update or change our System or the provisions set forth in these Terms from time to time and recommend that you review these Terms on a regular basis. You understand and agree that your continued use of our System after these Terms have been updated or changed constitutes your acceptance of the revised Terms. Without limiting the foregoing, if we make a change to these Terms that materially affects your use of the System, we may post notice or notify you via email or our website(s) of any such change. The most current version of the Terms shall govern and supersede all previous versions.
    </div>

    <div className={cx['paragraph']}>
    Date of Last Revision: January 1, 2019
    </div>
  </div>
);

export default TermsOfUse;
