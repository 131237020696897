const request = require('superagent-promise')(require('superagent'), Promise);

export const setToken = code =>
  request.get(`/api/rest/drChrono/token/${code}`).end();

export const turnSync = syncDirection =>
  request
    .post('/api/rest/drChrono/sync/turn')
    .query({ isTurnOn: syncDirection })
    .end();

export const fetchOffices = () =>
  request
    .get('/api/rest/drChrono/offices')
    .end();

export const fetchActiveOffice = () =>
  request
    .get('/api/rest/drChrono/offices/active')
    .end();

export const setOfficeForLoggedUser = (data) =>
  request
    .post('/api/rest/drChrono/office/update')
    .send(data);

export const removeToken = () =>
  request.del('/api/rest/drChrono/delete/token/currentUser').end();

export const fetchToken = () =>
  request
    .get('/api/rest/drChrono/token/currentUser')
    .end();

export const sso = data =>
  request
    .post('/api/rest/drChrono/sso')
    .send(data)
    .end();
