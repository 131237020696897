import { combineReducers } from 'redux';
import byId from './byId';
import byPage from './byPage';
import fromSearch from './fromSearch';
import isFetching from './isFetching';
import isDownloadingImage from './isDownloadingImage';
import isSearching from './isSearching';
import error from './error';
import recent from './recent';
import active from './active';

export default combineReducers({
  byId,
  byPage,
  fromSearch,
  isFetching,
  isSearching,
  error,
  recent,
  isDownloadingImage,
  active,
});
