import React from 'react';
import { compose } from 'redux';

import diagnosesContainer, { withDiagnosesFetchLayer } from 'containers/Diagnoses/DiagnosesContainer';
import DiagnosesPage from 'containers/Diagnoses/DiagnosesPage';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';

const DiagnosesComponent = compose(
  diagnosesContainer,
  withDiagnosesFetchLayer
)(DiagnosesPage);

export const Diagnoses = () => {
  const [patientId] = useOutletContext();
  const [searchParams] = useSearchParams();
  return (
    <DiagnosesComponent
      filter="active"
      patientId={patientId}
      diagnosisId={searchParams.get('diagnosis')}
      linkBase={`/app/doctor/patients/${patientId}/diagnoses`}
    >
      <Outlet />
    </DiagnosesComponent>
  );
};
