import React from 'react';
import { Link } from 'react-router-dom';

import { LINK_TO_NEW_USER_FORM } from 'helpers/users/constants';

export const AddUserButton = () => {
  return (
    <Link
      to={LINK_TO_NEW_USER_FORM}
      className={'button--submit'}
    >
      Add user
    </Link>
  );
};

