export default class DrChronoSSOService {
  static LIVE_CLIENT_ID = 'kMAwozfiFYyu1OcczIZn7MfhyWPPUxyaEf3bygmv';

  constructor(redirectURI) {
    this.redirectURI = redirectURI;
  }

  drChronoAuthUrl = () =>
    `https://drchrono.com/o/authorize?client_id=${DrChronoSSOService.LIVE_CLIENT_ID}&redirect_uri=${this.redirectURI}&response_type=code`;
}
