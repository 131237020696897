import { handleActions } from 'redux-actions';

import * as constants from '../constants';
import * as socialElementsConstants from '../../socialElements/constants';
import { isNullOrUndefined } from 'helpers';

import SocialInitialElement from 'payload/social/elements/initializing';

import SocialActiveElementsResponse from 'response/social/elements/active/fetch';

const initialState = {
};

export default handleActions({
  [constants.SOCIAL_SYSTEM_ADD]: (state, action) => {
    if (state[action.payload.systemId]) return state;

    return {
      ...state,
      [action.payload.systemId]: [],
    };
  },
  [constants.PATIENT_SOCIAL_FETCH]: state => ({
    ...state,
    isFetching: true,
  }),
  [constants.PATIENT_SOCIAL_FETCH_SUCCESS]: (state, action) => {
    const nextState = { ...state, isFetching: false };
    const { result } = action.payload;
    if (isNullOrUndefined(result.social)) return nextState;

    action.payload.result.social.forEach((system) => {
      nextState[system.id] = SocialActiveElementsResponse.parse(system.elements);
    });

    return nextState;
  },
  [constants.PATIENT_SOCIAL_FETCH_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),
  [constants.SOCIAL_ELEMENT_ADD]: (state, action) => {
    if (action.payload.elementToAdd.needToAdd === false) return state;
    const { systemId, elementToCreate } = action.payload;
    const nextState = { ...state };

    // create system if it doesn't exist
    if (!nextState[systemId]) {
      nextState[systemId] = [];
    }

    let indexExistedElement = -1;
    nextState[systemId].forEach((element) => {
      if (element.id === elementToCreate.id) {
        indexExistedElement = nextState[systemId].indexOf(element);
      }
    });

    const initializedElement = SocialInitialElement.initializeElement(elementToCreate);

    // if element exists we return state
    if (indexExistedElement !== -1) {
      nextState[systemId][indexExistedElement] = {
        ...nextState[systemId][indexExistedElement],
        ...initializedElement,
      };

      return nextState;
    }

    nextState[systemId] = [
      ...nextState[systemId],
      initializedElement,
    ];

    return nextState;
  },
  [constants.SOCIAL_ELEMENTS_REMOVE]: (state, action) => {
    const nextState = { ...state };
    const {
      systemId,
      elementIds,
    } = action.payload;

    let nextArray = [...nextState[systemId]];

    nextArray = nextArray.filter(element => !elementIds.includes(element.id));

    if (nextArray.length > 0) {
      nextState[systemId] = nextArray;

      return nextState;
    }

    delete nextState[systemId];

    return nextState;
  },
  [socialElementsConstants.UPDATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      result,
      oldElement,
      newElement,
      socialId,
    } = action.payload;

    const index = nextState[socialId].findIndex(el => el.id === oldElement.id);

    if (index === -1) {
      return nextState;
    }

    const nextArray = [...nextState[socialId]];
    nextArray[index] = {
      ...oldElement,
      ...newElement,
      id: result,
      isUpdating: false,
    };

    nextState[socialId] = nextArray;

    return nextState;
  },
  [socialElementsConstants.UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      localData,
    } = action.payload;

    const {
      socialId,
      newElement,
    } = localData;

    const index = nextState[socialId].findIndex(el => el.id === newElement.id);

    if (index === -1) {
      return nextState;
    }

    const nextArray = [...nextState[socialId]];

    nextArray[index] = {
      ...nextArray[index],
      ...newElement,
    };

    nextState[socialId] = nextArray;

    return nextState;
  },
}, initialState);
