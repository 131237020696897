import {
  ACCESS_ACCOUNT,
  ACCESS_ACCOUNT_FAIL,
  ACCESS_ACCOUNT_SUCCESS,
  IMPERSONATE_LOGOUT,
  IMPERSONATE_LOGOUT_SUCCESS,
  IMPERSONATE_LOGOUT_FAIL
} from 'modules/impersonate/constants';

import { switchUserLogin, switchUserLogout } from 'api/impersonate';

export const impersonateLogin = (data) => ({
  types: [
    ACCESS_ACCOUNT,
    ACCESS_ACCOUNT_SUCCESS,
    ACCESS_ACCOUNT_FAIL
  ],
  promise: () => switchUserLogin(data),
  userId: data?.id
});

export const impersonateLogout = () => ({
  types: [
    IMPERSONATE_LOGOUT,
    IMPERSONATE_LOGOUT_SUCCESS,
    IMPERSONATE_LOGOUT_FAIL
  ],
  promise: () => switchUserLogout()
});
