import { formatDiagnosisForServer as format } from 'helpers/diagnoses';

const request = require('superagent-promise')(require('superagent'), Promise);

// icd10 diagnoses search
export const searchDiagnoses = (searchRequest, favorites) => {
  if (!favorites) {
    return request.post('/api/rest/diagnosis/search?page=0&limit=25')
      .send({ searchRequest })
      .end();
  }

  return request.post('/api/rest/diagnosis/note/search?page=0&limit=10000')
    .send({
      searchRequest,
      ids: favorites,
    })
    .end();
};

// get diagnoses by patient (active or inactive)
export const fetchDiagnoses = (patientId, filter) =>
  request.get(`/api/rest/diagnose/patient/${filter}/${patientId}`)
    .end();

export const fetchDiagnosis = diagnosisId =>
  request.get(`/api/rest/diagnose/${diagnosisId}`)
    .end();

export const createDiagnosis = (diagnosis) => {
  return request.post('/api/rest/diagnose/create')
    .send(format(diagnosis))
    .end();
};

export const updateDiagnosis = (diagnosis) => {
  return request.post('/api/rest/diagnose/update')
    .send(format(diagnosis))
    .end();
};

export const deleteDiagnosis = diagnosisId =>
  request.post('/api/rest/diagnose/delete')
    .send({ id: diagnosisId })
    .end();
