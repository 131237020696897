import React from 'react';
import cx from 'styles/Document.module.scss';

const PrivacyPolicy = () => (
  <div className={cx['page-container']}>
    <h1 className={cx['page-title']}>EHRsynergy Privacy Policy</h1>
    <div className={cx.date}>
      Effective: October 1, 2017
    </div>
    <h2 className={cx.header}>Introduction</h2>
    <div className={cx.paragraph}>Our mission is to drive better and more affordable healthcare through innovative software and information solutions for healthcare providers throughout the care continuum, their patients and health researchers. Our solution includes our electronic health record and other services for healthcare providers, patients and third parties.</div>

    <div className={cx.paragraph}>This Privacy Policy (this “Policy”) applies to the medical charting software and information services we offer through our website located at <a className={cx.link} href="https://ehrsynergy.com" rel="noopener noreferrer" target="_blank">https://ehrsynergy.com</a>, our cloud-based electronic health record and other services for healthcare providers, patients and third parties (collectively, our System). This Policy does not apply to any other services. Maintaining your trust is important to us, and we strongly encourage you to read this Policy in full.</div>

    <div className={cx.paragraph}>The purpose of this Policy is to describe how we and our partners collect, use, and share information about you. This Policy may incidentally describe how our System gathers and uses information about other individuals or information about you that may be submitted by another user. This Privacy Policy, however, only applies to how we and our partners collect, use, and share information about you with respect to the System covered by our EHRsynergy Terms of Use or our Healthcare Provider User Agreement (“User Agreement”), and not to any other service we may offer to any other individual or customer.</div>

    <div className={cx.paragraph}>Some of our users – such as healthcare providers – are subject to laws and regulations governing the use and disclosure of health information they create or receive, including the Health Insurance Portability and Accountability Act of 1996, as amended from time to time, together with the regulations adopted thereunder (“HIPAA”). When we store, process or transmit “individually identifiable health information” (as defined by HIPAA) on behalf of a healthcare provider who has entered into a Healthcare Provider User Agreement, we do so as its “business associate” (as also defined by HIPAA). Under this agreement, we cannot use or disclose individually identifiable health information in a way that the provider itself may not. We are also required to, among other things, apply reasonable and appropriate measures to safeguard the confidentiality, integrity, and availability of the individually identifiable health information we store and process on behalf of such providers. For the purpose of this Policy, the term “healthcare provider” means any user who is a “health care provider” (as defined by HIPAA) or any user who is a member of such health care provider’s “workforce” (as also defined by HIPAA). For additional information regarding our business associate obligations, please see Sections 4.1.8 and 9 of our Healthcare Provider User Agreement.</div>

    <h2 className={cx['sub-header']}>Information Collected by Our System</h2>

    <h3 className={cx['underlined-header']}>Information You Submit or We Collect on Your Behalf:</h3>
    <div className={cx.paragraph}>
      We collect information from you when you:
      <ul className={cx.list}>
        <li>
          Enter information into our System, such as when you register for our System or complete a form;
        </li>
        <li>
          Upload a document, image, or other data file onto our System;
        </li>
        <li>
          Contact us; or
        </li>
        <li>
          Make a customer service request.
        </li>
      </ul>
    </div>

    <div className={cx.paragraph}>
      We also collect information on your behalf when you authorize us to retrieve and import information from another user or other third party within our System or as set forth in the User Agreement.
    </div>

    <div className={cx.paragraph}>
      Information we collect about you may include your name, address, telephone number, email address, or the information you enter onto or upload to our System.
    </div>

    <h3 className={cx['underlined-header']}>Automatically Collected Information:</h3>
    <div className={cx.paragraph}>
      We and our partners automatically gather information whenever you visit, log in, or otherwise interact with our System.. We and our partners use the technologies described below and similar technologies that may not be expressly described (which we collectively call “Engagement Tools”) to gather this information to enhance and operate our System in a number of ways, such as to:
      <ul className={cx.list}>
        <li>
          Save user preferences and information;
        </li>
        <li>
          Preserve session settings and activity;
        </li>
        <li>
          Authenticate users;
        </li>
        <li>
          Enable support and security features;
        </li>
        <li>
          Tailor the delivery of informational messages, media, advertising and other content; and
        </li>
        <li>
          Analyze the performance and use of our System and its various features and content.
        </li>
      </ul>
    </div>

    <div className={cx.paragraph}>
      Even if you do not register with us or submit any information onto our System, our Engagement Tools will automatically receive information about, and the software running on, the computer, mobile phone, or tablet (each, a “Device”) you use to interact with our System.
    </div>

    <div className={cx.paragraph}>
      <span className={cx.underlined}>Device Information</span>: When you interact with our System, we collect information about your Device such as the URL of services your Device is requesting and the referring web pages, your IP address, Device type, operating system, browser type, application identifier, and, under certain circumstances, the location information your Device sends to us.
    </div>

    <div className={cx.paragraph}>
      <span className={cx.underlined}>Cookies & Similar Technologies</span>: We and our partners collect information about you and your Devices through cookies, web beacons, and similar technologies. A "cookie" is a small data file sent from a website and stored on your Device to identify your Device in the future and allow for an enhanced personalized user experience based on your previous activity on the website. A "session cookie" disappears after you close your web browser, or may expire after a fixed period of time. A "persistent cookie" remains after you close your web browser and may be accessed every time you use our System. We and our partners may use both session and persistent cookies onto our System. You should consult your web browser to modify your cookie settings. Please note that if you delete or choose not to accept cookies from us, you may not be able to use certain features of our System.
    </div>

    <div className={cx.paragraph}>
      Some of our partners may deploy these technologies directly onto our System. These third parties may collect information over time about your use of our System, as well as your online activities across other websites or online services. Some third parties may allow you to opt-out of targeted advertising based on this information. You can find more information about these opt-outs from the <a className={cx.link} href="http://optout.networkadvertising.org/" rel="noopener noreferrer" target="_blank">Network Advertising Initiative (NAI)</a> and the <a className={cx.link} href="http://optout.aboutads.info/" rel="noopener noreferrer" target="_blank">Digital Advertising Alliance (DAA)</a>.
    </div>

    <h3 className={cx['underlined-header']}>Information from Other Sources:</h3>
    <div className={cx.paragraph}>
      We may receive or proactively gather information about you from other sources and add it to information we otherwise have about you for any purpose described in this Policy. This may include situations where a third party seeks to communicate with you through the System or establish an “Integration” (as more fully described below under the heading, Third Party Integrations).
    </div>

    <h2 className={cx['sub-header']}>How We Use Information</h2>
    <div className={cx.paragraph}>
      We may use the information we collect for the following purposes:
      <ul className={cx.list}>
        <li>
          Operating our System and developing new functionality and features;
        </li>
        <li>
          Responding to questions and communications, or obtaining your feedback about our System;
        </li>
        <li>
          Administering and logging your participation in educational and informational programs, including webinars and other classes, and any product or support matters that may arise from such programs;
        </li>
        <li>
          Preparing and delivering announcements about features, functionality, terms of use, or other aspects of our System or your interests and informing you about offers for services or products we believe may be of interest to you, including from third party sponsors;
        </li>
        <li>
          Providing you with more relevant content, including clinical support tools, assessments or medical-related information or services, patient support programs, advertising, or other programs appearing onto our System or third-party services;
        </li>
        <li>
          Analyzing usage trends and patterns and measuring the effectiveness of content, programs, advertising or the features or functionality of the System, including emails that may be sent by us to you;
        </li>
        <li>
          Preparing reports for any of the purposes described in this Policy, including for current or future sponsors, advertisers or other partners to show utilization or trends about the use of our System. Such reports may include demographic or other general user information, but will not include personally identifiable information unless the recipient has agreed to confidentiality obligations;
        </li>
        <li>
          Safeguarding and protecting our System, the information we collect, and the rights of us, our users or third parties, and in response to legal process;
        </li>
        <li>
          Any other purpose described in this Policy or your User Agreement; or
        </li>
        <li>
          When we otherwise have your permission.
        </li>
      </ul>
    </div>

    <h2 className={cx['sub-header']}>
      How our System Allow Users to Share Information:
    </h2>

    <h3 className={cx['underlined-header']}>
    One-on-One Communications:
    </h3>
    <div className={cx.paragraph}>
      Our System can be used to facilitate one-on-one communications between healthcare providers and our support team.
    </div>

    <h3 className={cx['underlined-header']}>
      Directories:
    </h3>
    <div className={cx.paragraph}>
      If you are a healthcare provider who has entered into a Healthcare Provider User Agreement, you will have the ability to have your contact and directory information listed in one or more of our professional directories of healthcare providers in our System that users and/or the general public may be able to view. These directories include profile information (e.g., contact, specialty and other information) and other features that allow users and/or the general public to locate and contact those listed in the directory.
    </div>

    <div className={cx.paragraph}>
      If you visit our System seeking to contact or schedule an appointment with a provider listed in one of our directories, you may need to submit personally identifiable and other information.
    </div>

    <h3 className={cx['underlined-header']}>
      Public Forums:
    </h3>
    <div className={cx.paragraph}>
      Our System may include public forums that allow users to communicate with groups of users or the general public. Information a user posts in one of our communities may be available to a wide range of individuals, and should be presumed public. We strongly advise users to exercise care in selecting what information they share with our communities or public forums, and strongly recommend against sharing any personally identifiable, health, or other sensitive information that could directly or indirectly be traced to any individual, including themselves.
    </div>

    <h3 className={cx['underlined-header']}>
      Surveys, Feedback, Informational Programs:
    </h3>
    <div className={cx.paragraph}>
      From time to time you may receive survey requests through emails or displays within our System that request feedback on a variety of topics. These programs may be sponsored or funded by third parties, and may include branded or unbranded content about medical conditions, treatments and products, or safety and regulatory information resources. If you choose to engage with or use one of these requests, you may be asked to provide information that may be used to supplement information that you submitted to our System. This information may be shared with the sponsor of the program.
    </div>

    <h3 className={cx['underlined-header']}>
      Records:
    </h3>
    <div className={cx.paragraph}>
      Our System allows users to store personally identifiable and health information (“Records”), including Records that may contain information which identifies other users. The users of the System may share all or portions of these Records on their discretion.
    </div>

    <div className={cx.paragraph}>
      You should be aware that this Policy covers only the information you submit through our System. If you contact or exchange information with another user in person or in any other way, such activity is not covered by this Policy. We are not responsible for the actions of persons with whom you share your Records and other information.
    </div>

    <h3 className={cx['underlined-header']}>
      Communications:
    </h3>
    <div className={cx.paragraph}>
      We may communicate administrative or System-related announcements through email or other communications within our System. These communications may be “real time” communications or communications triggered automatically upon the occurrence of certain events or dates – such as a repeated sign-in failure or an appointment notification. Please note that you may not be able to opt out of receiving certain messages from us.
    </div>

    <div className={cx.paragraph}>
      Any communications from individuals who are not users of our System, or that we send in connection with business agreements or subject matter other than the User Agreements, are not covered by this Policy. If, for example, you contact us regarding a job opening, that communication to us is not covered by this Policy even though that job opening may have been posted in our System.
    </div>

    <h3 className={cx['underlined-header']}>
      Third Party Integrations
    </h3>
    <div className={cx.paragraph}>
      Our System may allow you to connect your account in our System with third parties, such as pharmacy or insurance companies (“Integration”). Integrations can also be initiated by third parties seeking to establish Integrations with you, such as when a clinical laboratory desires to transmit lab results directly into a healthcare provider’s electronic health record account rather than transmitting the result by fax or other means. Your use of these Integrations is entirely optional. Should you choose to utilize these Integrations, you may be prompted to give us permission to perform certain actions in your account, such as creating, updating or deleting certain information. Please note that if you choose to utilize any Integration, any information you elect to provide to third parties will be subject to whatever agreement you have with them, including, if applicable, their terms of use or privacy policy, and not this Policy. To disable an Integration, you can contact us through one of the methods described at https://ehrsynergy.com/contacts.
    </div>

    <h3 className={cx['underlined-header']}>
      Sharing of Information
    </h3>
    <div className={cx.paragraph}>
      We may share information you submit to us with third parties under the following circumstances:
      <ul className={cx.list}>
        <li>
        When your account has been issued by an account administrator with administrative rights over your account, your account administrator will have access to your account information;
        </li>
        <li>
        With third party service providers that have agreed to confidentiality obligations, which may include, as applicable, business associate contract obligations;
        </li>
        <li>
        If you are a healthcare provider who has entered into a Healthcare Provider User Agreement, we may share information with the third parties who are subject to confidentiality obligations that you have elected to establish Integrations with, or who seek to establish Integrations with you, and to facilitate, maintain and monitor the utilization of such Integrations;
        </li>
        <li>
        If you are a healthcare provider who has entered into a Healthcare Provider User Agreement, we may also share information with third parties who are subject to confidentiality obligations who are funding or administering certain branded or unbranded content about medical conditions, treatments and products, or safety and regulatory information resources, such as clinical decision support tools, patient savings offers, co-pay offset or discount programs, medication adherence programs, and other similar programs, and with sponsors of advertising appearing within our System. The purposes of such sharing may include administration, recordkeeping or compliance obligations, and assessing the effectiveness or utilization of any such program.
        </li>
        <li>
        If you receive any honoraria or payment in connection with a survey or request for feedback, your information may be shared with the funding source or sponsor of such survey or program;
        </li>
        <li>
        To protect our System, the information we collect, and the rights of us, our users, and any third parties, including to verify your identity;
        </li>
        <li>
        To detect, prevent, investigate, or address fraud, illegal activity, or violations of our terms and agreements;
        </li>
        <li>
        In response to legal process, such as a search warrant, court order, or subpoena, or when we have a good faith belief that the law requires us to do so;
        </li>
        <li>
        With our current and future subsidiaries or corporate affiliates or actual or potential investors;
        </li>
        <li>
        In connection with a potential or actual sale, merger, transfer, exchange, reorganization or other disposition (whether of assets, stock, or otherwise) of all or a portion of the business conducted by our System. If such a transaction occurs, the acquiring company’s use of your information will remain subject to this Policy, as may be subsequently amended;
        </li>
        <li>
        Any other purposes described in this Policy or your User Agreement; or
        </li>
        <li>
        When we otherwise have your permission.
        </li>
      </ul>
    </div>

    <h3 className={cx['underlined-header']}>
      Security
    </h3>
    <div className={cx.paragraph}>
      To help prevent unauthorized access, maintain data accuracy, and protect against the inappropriate use of the information we collect, store, and transmit, we deploy a range of technical, physical and administrative safeguards. Under our Healthcare Provider User Agreement and applicable law, we are required to apply reasonable and appropriate measures to safeguard the confidentiality, integrity, and availability of individually identifiable health information residing on, and processed by, those elements of our System that we operate as a business associate on behalf of healthcare providers. It is important to remember, however, that no system can guarantee 100% security at all times. Accordingly, we cannot guarantee the security of information stored on or transmitted to or from our System.
    </div>

    <h3 className={cx['underlined-header']}>
      Third Party Services
    </h3>
    <div className={cx.paragraph}>
      This Policy applies only to our System. It does not apply to services offered by third parties, including websites and other online services that our System may display links to or to advertisements appearing within the System. When you click on such links or advertisements, you will be visiting websites or interactive services operated by third parties, who have their own information collection practices and may also collect information through the use of Engagement Tools. We do not have control over how any third party collects or uses information, so you should review their privacy policies to learn of their practices.
    </div>

    <h3 className={cx['underlined-header']}>
      Changes to this Policy
    </h3>
    <div className={cx.paragraph}>
      We believe in continuous innovation, which, along with changes in our business, may require that we amend this Policy from time to time. We will post a revised Policy along with its effective date on this page. Because this Policy can change at any time, we encourage you to reread it periodically to see if there have been any changes, amendments, or updates. If you object to the changes or any terms within this Policy or the User Agreements, you should discontinue using our System. Your continued use of our System following the effective date means that you have consented to the Policy, as amended, changed, or updated.
    </div>

    <h3 className={cx['underlined-header']}>
      Viewing and Updating Your Information
    </h3>
    <div className={cx.paragraph}>
      Our System aims to provide you with access to the information you submit and the means to update it within our System consistent with applicable law. This can be accomplished by logging into our System and updating that information, or contacting a customer support representative, although please be advised of the important limitations described below. Under certain circumstances, we may ask you to verify your identity before your request is processed.
    </div>

    <div className={cx.paragraph}>
      Please note that, unless you have administrative rights over another user’s account pursuant to our Healthcare Provider User Agreement, you are not entitled to access, update, or delete the content of another user’s account.
    </div>

    <div className={cx.paragraph}>
      If you have shared information placed in our System with another user or a third party in person or in any other way, you will not be able to access, update, or delete that shared information. Further, if another user of our System submits information that identifies you, you will not be able to access, update, or delete that information.
    </div>

    <div className={cx.paragraph}>
      Certain users – such as healthcare providers – may be required under applicable laws or regulations to retain information about you for extended periods of time or indefinitely. Additionally, we may have independent obligations under applicable laws or regulations to retain such information indefinitely. Finally, for disaster recovery and business continuity purposes we retain copies of data stored by our System for indefinite periods of time.
    </div>

    <div className={cx.paragraph}>
      HIPAA grants patients certain rights to access and amend certain health information that their healthcare providers retain about them. Patients should submit requests to access or amend their health information directly to their healthcare providers.
    </div>
    <br />
    <br />
    <br />
    <h3 className={cx['underlined-header']}>
      Contact Us
    </h3>

    <div className={cx.paragraph}>
      If you have questions regarding this Policy, please contact us at: <br/>
      EHRsynergy, LLC<br/>
      1736 E. Charleston Blvd, Ste 731<br/>
      Las Vegas, NV 89104
    </div>
  </div>
);


export default PrivacyPolicy;
