import * as chartsSelectors from 'modules/charts/selectors';
import { copyPreviousNote, updateCptCode, updateSelectUserDate } from 'modules/charts/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => ({
  cptCode: chartsSelectors.getChartById(state.charts, ownProps.chartId).cptCode,
});

const mapActionCreators = {
  copyPreviousNote,
  updateCptCode,
  updateSelectUserDate,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
