import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from 'modules/providers/actions';
import * as selectors from 'modules/providers/selectors';
import { getUserInfo } from 'modules/user/selectors';

export const withProvidersFetchLayer = Component => props => {
  const {
    fetchProviders,
    isFetching,
  } = props

  useEffect(() => {
    const fetchProvidersWrapper = async () => {
      if (isFetching) return;

      await fetchProviders();
    }

    fetchProvidersWrapper()
  }, [])

  return <Component {...props} />;
};

withProvidersFetchLayer.propTypes = {
  fetchProviders: PropTypes.func.isRequired,
  isFetched: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  providers: selectors.getProviders(state),
  providersWithSeat: selectors.getProvidersWithSeat(state),
  isFetching: selectors.getIsFetching(state),
  isFetched: selectors.getIsFetched(state),
  activeProviderId: selectors.getActiveProviderId(state),
  userInfo: getUserInfo(state.user),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
