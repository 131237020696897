import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import PaymentsPlansForm from '../PaymentsPlansCard';
import paymentsPlansContainer, { fetchLayerHOC } from 'containers/PaymentsPlansContainer';

const PaymentPlansContainer = compose(
  paymentsPlansContainer,
  fetchLayerHOC
);

const PaymentPlansFormComponent = PaymentPlansContainer(PaymentsPlansForm);

const PaymentsPlansList = (props) => {
  return (
    <PaymentPlansFormComponent {...props} />
  );
};

PaymentsPlansList.propTypes = {
  router: PropTypes.object,
  routes: PropTypes.array,
};

export default PaymentsPlansList;
