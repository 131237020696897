import React, { useContext } from 'react';
import { bool, func, object } from 'prop-types';

import { EditModeContext } from './context';

import Checkbox from 'components/Checkbox/index';

import cx from './ElementsViewItem.module.scss';

import { elementsViewItemActionsModalData } from 'components/ElementsViewItemActions/data';

const RegularElementCheckbox = (props) => {
  const { currentEditModeAction } = useContext(EditModeContext);
  const {
    isElementsViewItemActionsButtonClicked,
    isCheckboxToMergeItemsClicked,
    setItemsForMerge,
    element
  } = props;

  const isTheSameEditModeAction = () => {
    return currentEditModeAction === elementsViewItemActionsModalData.mergeElements.id;
  };

  const isTheSameElement = () => {
    return isElementsViewItemActionsButtonClicked === true;
  };

  const getCheckedColor = () => {
    if (isTheSameEditModeAction() && !isTheSameElement()) {
      return Checkbox.CHECKED_COLOR.YELLOW;
    } if (isTheSameEditModeAction() && isTheSameElement()) {
      return Checkbox.CHECKED_COLOR.GREEN;
    }

    return Checkbox.CHECKED_COLOR.GREEN;
  };

  const isCheckBoxCheckedWrapper = () => {
    return (isTheSameEditModeAction() && isCheckboxToMergeItemsClicked === true)
           || isElementsViewItemActionsButtonClicked === true;
  };

  const handleCheckboxClick = () => {
    if (isTheSameEditModeAction() === true && !isTheSameElement()) {
      setItemsForMerge(element);
    }
  };

  return (
    <div
      className={cx.wrapper}
    >
      <Checkbox
        checked={isCheckBoxCheckedWrapper()}
        onClick={handleCheckboxClick}
        loading={false}
        checkboxSize={Checkbox.SIZE.LARGE}
        checkedColor={getCheckedColor()}
      >
        {element.name}
      </Checkbox>
    </div>
  );
};

export default RegularElementCheckbox;

RegularElementCheckbox.propTypes = {
  isElementsViewItemActionsButtonClicked: bool.isRequired,
  element: object.isRequired,
  setItemsForMerge: func.isRequired,
  isCheckboxToMergeItemsClicked: bool.isRequired,
};
