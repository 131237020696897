import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { SEVERITY_MAP } from 'helpers/billing/constants';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import { build } from 'helpers/URLUtils';

import cx from './DropShading.module.scss';

const DropShading = ({ pastdue }) => {
  if (!pastdue.showPaymentWarning) return null;

  const {
    severity,
    isReadOnly,
    isBeforeReadOnly,
    daysToReadOnly,
    isOrangeIcon,
  } = pastdue;

  const styles = cssClassesResolver([
    cx.message,
    cx[`message-${SEVERITY_MAP[severity]}`]
  ]);

  return (
    <div
      className={cx.wrapper}
    >
      <div className={cx.panel}>
        {severity === 2 && <img alt="Red" src={require('images/warning-red.png')} className={cx['warning-triangle']} />}
        {isOrangeIcon && <img alt="Orange" src={require('images/warning-orange.png')} className={cx['warning-triangle']} />}
        <span className={styles}>
            You need to update your payment info.
        </span>
        {isBeforeReadOnly === false && isReadOnly === false && <span>Time left: {daysToReadOnly} days</span>}
        {isBeforeReadOnly === true && isReadOnly === false && <span>You have the one day !!!</span>}
        {isReadOnly === true && isBeforeReadOnly === false && <span>You are in read-only mode</span>}
        <Link
          to={build({
            pathname: '/app/doctor/settings/financial/payments_plans',
            query: {
              subscribe: 1,
            },
          })}
          className={cx.link}
        >
          Update payment info
        </Link>
      </div>
    </div>
  );
};

DropShading.propTypes = {
  pastdue: PropTypes.object.isRequired,
};

export default DropShading;
