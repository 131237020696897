import React from 'react';
import { Link } from 'react-router-dom';

import cx from './Logo.module.scss';

const Logo = () => (
  <div className={cx.wrapper}>
    <Link to="/app/doctor" className={cx['root-link']}>
      <div className={cx['logo']}>
        <span>ehr</span>
        synergy
      </div>
    </Link>
  </div>
);

export default Logo;
