import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Switch from 'routes/Switch';
import RootWrapper from 'routes/containers/Root';
import { LoginRoute } from './routes/Login';
import { PrivacyPolicyRoute } from './routes/PrivacyPolicy';
import { TermsOfUseRoute } from './routes/TermsOfUse';
import { UserAgreementRoute } from './routes/UserAgreement';
import { RecoveryRoute } from './routes/Recovery';
import { DrChronoLoginRoute } from './routes/DrChronoLogin';
import { AdminRoute } from './routes/Admin';
import { AppRoute } from './routes/App';
import { RegistrationRoutes } from 'routes/routes/Registration';

export default createBrowserRouter([
  {
    path: '/',
    element: <Switch />,
    children: [
      {
        path: 'app',
        element: <RootWrapper />,
        children: [
          AdminRoute,
          AppRoute,
          LoginRoute,
          PrivacyPolicyRoute,
          TermsOfUseRoute,
          UserAgreementRoute,
          RecoveryRoute,
          RegistrationRoutes,
          DrChronoLoginRoute
        ]
      }
    ]
  },
]);
