import difference from 'lodash/difference';

import { getSubElemntNumberGroupList } from 'helpers';

export default class NumberGroupsDifferenceAfterRemoving {
  static define = (newElementName, oldElementName) => {
    const oldSubElementNumberGroups = getSubElemntNumberGroupList(oldElementName);
    const newSubElementNumberGroups = getSubElemntNumberGroupList(newElementName);

    const isSubElementsRemoved = newSubElementNumberGroups.length < oldSubElementNumberGroups.length;

    if (isSubElementsRemoved) {
      return difference(oldSubElementNumberGroups, newSubElementNumberGroups);
    }

    return [];
  }
}
