import React from 'react';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './ComponentSection.module.scss';

const ComponentSection = ({
  title,
  children,
  className,
}) => (
  <div
    className={
      cssClassesResolver([
        cx['section'],
        className
      ])
    }
  >
    {title && <h3 className={cx['title']}>{title}</h3>}
    {children}
  </div>
);

ComponentSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ComponentSection;
