export const PAYMENT_INFO_SAVE = 'PAYMENT_INFO_SAVE';
export const PAYMENT_INFO_SAVE_SUCCESS = 'PAYMENT_INFO_SAVE_SUCCESS';
export const PAYMENT_INFO_SAVE_FAIL = 'PAYMENT_INFO_SAVE_FAIL';

export const PAYMENT_INFO_FETCH = 'PAYMENT_INFO_FETCH';
export const PAYMENT_INFO_FETCH_SUCCESS = 'PAYMENT_INFO_FETCH_SUCCESS';
export const PAYMENT_INFO_FETCH_FAIL = 'PAYMENT_INFO_FETCH_FAIL';

export const DO_PAYMENT = 'DO_PAYMENT';
export const DO_PAYMENT_SUCCESS = 'DO_PAYMENT_SUCCESS';
export const DO_PAYMENT_FAIL = 'DO_PAYMENT_FAIL';

