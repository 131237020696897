import React from 'react';
import PropTypes from 'prop-types';
import { spring, presets, TransitionMotion } from 'react-motion';

import cx from './ChartReportVisibilityLayer.module.scss';

export default class ChartReportVisibilityLayer extends React.Component {
  static propTypes = {
    isReportVisible: PropTypes.bool.isRequired,
    toggleChartReport: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  }

  stopPropagation = e => e.stopPropagation();

  willLeave = () => ({
    opacity: spring(0),
    left: spring(100),
  })

  willEnter= () => ({
    opacity: 0,
    left: 100,
  })

  render() {
    const { isReportVisible, toggleChartReport, children } = this.props;
    const { willEnter, willLeave, stopPropagation } = this;

    const styles = !isReportVisible ? [] : [{
      key: '0',
      style: {
        opacity: spring(0.3, presets.noWobble),
        left: spring(0, presets.noWobble),
      },
    }];

    return (
      <TransitionMotion
        willLeave={willLeave}
        willEnter={willEnter}
        styles={styles}
      >
        {interploatedStyles => {
          return (
            <div>
              {interploatedStyles.map(({ key, style }) => (
                <div
                  key={key}
                  className={cx['wrapper']}
                  onClick={isReportVisible && toggleChartReport}
                  style={{
                    backgroundColor: `rgba(0,0,0,${style.opacity})`,
                    // BUG: ie11 pointerEvents is stuck on none if we don't check style.opacity
                    pointerEvents: !isReportVisible && style.opacity < 0.3 && 'none',
                  }}
                >
                  <div
                    className={cx['report']}
                    onClick={stopPropagation}
                    style={{
                      transform: `translateX(${style.left}%)`,
                    }}
                  >
                    {children}
                  </div>
                </div>
              ))}
            </div>
          );
        }}
      </TransitionMotion>
    );
  }
}
