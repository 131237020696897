import { handleActions } from 'redux-actions';

import * as constants from './constants';

const initialState = null;

export default handleActions({
  [constants.CHARTS_FETCH]: (state, action) => null,
  [constants.CHARTS_FETCH_SUCCESS]: (state, action) => null,
  [constants.CHARTS_FETCH_FAIL]: (state, action) => ({
    status: action.error.status,
    message: action.error.message,
  }),
}, initialState);

export const getDashboardError = state => state;
