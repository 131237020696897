import { handleActions } from 'redux-actions';

import itemReducer, { initialState as itemInitialState } from './item/';

import * as constants from '../constants';
import { CHART_DELETE_SUCCESS } from 'modules/charts/constants';

import { addNewProperty, isNullOrUndefined } from 'helpers';

import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

const initialState = {};

export const handleResponseFromCreatingHistoryItem = (responseFromServer) => {
  if (isNullOrUndefined(responseFromServer)) {
    return {};
  }

  if (typeof responseFromServer === 'number') {
    return { id: responseFromServer };
  }

  let reorganizedResponse = {};

  Object.keys(responseFromServer).forEach((responseItem) => {
    if (typeof responseFromServer[responseItem] === 'object' && !isEmpty(responseFromServer[responseItem])) {
      reorganizedResponse = {
        ...reorganizedResponse,
        ...responseFromServer[responseItem],
      };
    } else {
      reorganizedResponse = addNewProperty(responseItem, responseFromServer[responseItem], reorganizedResponse);
    }
  });

  return reorganizedResponse;
};

const createNewItem = (action) => {
  return {
    ...action.payload.item,
    ...handleResponseFromCreatingHistoryItem(action.payload.result),
  };
};

const createActionWithNewItem = (action) => {
  const newItem = createNewItem(action);
  const newAction = { ...action };

  newAction.payload.item = newItem;

  return newAction;
};

const updateItem = (state, action, itemId = action.payload.itemId) => {
  const newAction = createActionWithNewItem(action);

  return {
    ...state,
    [itemId]: itemReducer(state[itemId], newAction),
  };
};

export default handleActions({
  [constants.HISTORY_ITEMS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.map((item) => {
      return {
        data: {
          ...state[item.id],
          ...item,
        },
        status: itemInitialState.status,
      };
    }), item => item.data.id),
  }),
  [constants.HISTORY_ITEM_FETCH]: (state, action) => ({
    ...state,
    [action.payload.itemId]: itemReducer(state[action.payload.itemId], action),
  }),
  [constants.HISTORY_ITEM_FETCH_SUCCESS]: updateItem,
  [constants.HISTORY_ITEM_FETCH_FAIL]: updateItem,
  [constants.HISTORY_ITEM_CREATE_SUCCESS]: (state, action) => {
    const itemId = handleResponseFromCreatingHistoryItem(action.payload.result).id;

    return updateItem(state, action, itemId);
  },
  [constants.HISTORY_ITEM_UPDATE]: (state, action) => {
    const itemId = action.payload.item.id;
    return updateItem(state, action, itemId);
  },
  [constants.HISTORY_ITEM_UPDATE_SUCCESS]: (state, action) => {
    const itemId = action.payload.item.id;
    return updateItem(state, action, itemId);
  },
  [constants.HISTORY_ITEM_UPDATE_FAIL]: (state, action) => {
    const itemId = action.payload.item.id;
    return updateItem(state, action, itemId);
  },
  [constants.HISTORY_ITEM_DELETE]: (state, action) => {
    const nextState = { ...state };
    delete nextState[action.payload.itemId];
    return nextState;
  },
  [constants.HISTORY_ITEM_DELETE_FAIL]: updateItem,
  [CHART_DELETE_SUCCESS]: (state, action) => {
    const nextState = { ...state };

    const itemsToDelete = Object.values(nextState).filter((o) => {
      return o.data.chartId === action.payload.chartId;
    });

    itemsToDelete.forEach((item) => {
      nextState[item.data.id] = itemReducer(state[item.data.id], action);
    });

    return nextState;
  },
}, initialState);
