import React from 'react';
import cx from './EmptyMessage.module.scss';
import PropTypes from 'prop-types';

const EmptyMessage = ({
  children,
}) => (
  <div className={cx['warning']}>{children}</div>
);

EmptyMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default EmptyMessage;
