import moment from 'moment';

import {
  addElementWithoutHxrvEffect,
  removeElementWithoutHxrvEffect,
  saveChart
} from 'modules/chartingSessions/actions';
import { addResources } from '../resources/actions';
import { fetchChartingAssets, addAssetSystem } from '../chartingAssets/actions';
import { fetchPatientImage } from 'modules/patients/actions';
import { getSystemById } from 'modules/chartingAssets/selectors';
import * as chartsConstants from './constants';
import { getChartCCString, getChartCompletionPercentage } from 'modules/rootSelectors';

import { hpChartTabs } from 'helpers/chart';
import { parseRightSideCpt } from 'helpers/resourses/fetchResoursesParsers/cptParser';
import stringParser from 'helpers/common/string/string-parser';

import * as api from 'api/charts';
import { partialUpdateChart } from 'api/charting';
import { getChartById } from 'modules/charts/selectors';

const visitTypes = {
  1: {
    id: 1,
    name: 'Home',
  },
  2: {
    id: 2,
    name: 'Skype',
  },
  3: {
    id: 3,
    name: 'Office Visit',
  },
};

const chartingTypes = {
  1: {
    id: 1,
    name: 'H&P Note',
  },
  2: {
    id: 2,
    name: 'SOAP Note',
  },
  3: {
    id: 3,
    name: 'Custom',
  },
};

const ICD10_SYSTEM_ID = 35;
const MEDICATIONS_SYSTEM_ID = 36;

export const fetchChartsByPage = (page, sortBy, isAsc, providerId) => ({
  types: [
    chartsConstants.CHARTS_BY_PAGE_FETCH,
    chartsConstants.CHARTS_BY_PAGE_FETCH_SUCCESS,
    chartsConstants.CHARTS_BY_PAGE_FETCH_FAIL,
  ],
  promise: () => api.fetchChartsByPage(page, false, sortBy, isAsc, providerId || 0),
  page,
  signedStatus: 'unsigned',
  sortBy,
  isAsc,
  providerId,
});

export const fetchLastChartsWithSorting = (sortBy, isAsc, providerId) => ({
  types: [
    chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH,
    chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS,
    chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_FAIL,
  ],
  promise: () => api.fetchLastChartsWithSorting(sortBy, isAsc, providerId)
});

export const fetchPatientCharts = patientId => ({
  types: [
    chartsConstants.PATIENT_CHARTS_FETCH,
    chartsConstants.PATIENT_CHARTS_FETCH_SUCCESS,
    chartsConstants.PATIENT_CHARTS_FETCH_FAIL,
  ],
  promise: () => api.fetchPatientCharts(patientId),
  patientId: stringParser(patientId),
});

const resourceMapper = item => ({
  id: item.id,
  name: item.name,
});

export const fetchPatientChartsByPage = (patientId, page, sortBy, isAsc) => ({
  types: [
    chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH,
    chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH_SUCCESS,
    chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH_FAIL,
  ],
  promise: () => api.fetchPatientChartsByPage(patientId, page, sortBy, isAsc),
  patientId: stringParser(patientId),
  page,
  sortBy,
  isAsc,
});

export const fetchChart = chartId => ({
  types: [
    chartsConstants.CHART_FETCH,
    chartsConstants.CHART_FETCH_SUCCESS,
    chartsConstants.CHART_FETCH_FAIL,
  ],
  promise: (dispatch) => {
    return api.fetchChart(chartId)
      .then((res) => {
        const { body } = res;
        const { patient } = body;
        const {
          imageName,
          id,
        } = patient;


        if (imageName) {
          dispatch(fetchPatientImage(imageName, id))
        }
        return res;
      })
      .then((res) => {
        if (res.status === 204) return res;

        let medications = [];
        let diagnoses = [];

        res.body.chart.note.forEach((step) => {
          step.systems.forEach((system) => {
            if (system.id === ICD10_SYSTEM_ID) {
              diagnoses = system.elements.map(resourceMapper);
            }
            if (system.id === MEDICATIONS_SYSTEM_ID) {
              medications = system.elements.map(resourceMapper);
            }
          });
        });

        dispatch(addResources('diagnoses', diagnoses));
        dispatch(addResources('medications', medications));

        return res;
      });
  },
  chartId: stringParser(chartId),
});

export const createChart = (chart, request = api.createChart) => ({
  types: [
    chartsConstants.CHART_CREATE,
    chartsConstants.CHART_CREATE_SUCCESS,
    chartsConstants.CHART_CREATE_FAIL,
  ],
  promise: () => request(chart),
  localChart: {
    createdDate: moment().valueOf(),
    status: 0,
    signed: false,
    patientId: chart.patientId,
    chartingType: chartingTypes[chart.chartingType],
    visitType: visitTypes[chart.visitType],
  },
});

export const updateChart = chart => ({
  type: chartsConstants.CHART_UPDATE_LOCAL,
  payload: {
    localChart: chart,
  },
});

export const updateChartExtraData = (newChart, prevChart) => ({
  types: [
    chartsConstants.CHART_UPDATE,
    chartsConstants.CHART_UPDATE_SUCCESS,
    chartsConstants.CHART_UPDATE_FAIL,
  ],
  promise: () => api.updateChartExtraData(newChart),
  localChart: newChart,
  prevChart,
});

export const fetchVitals = (patientId, chartId) => ({
  types: [
    chartsConstants.VITALS_FETCH,
    chartsConstants.VITALS_FETCH_SUCCESS,
    chartsConstants.VITALS_FETCH_FAIL,
  ],
  promise: () => api.fetchVitals(patientId),
  patientId,
  chartId,
});

export const saveVitals = vitals => ({
  types: [
    chartsConstants.VITALS_SAVE,
    chartsConstants.VITALS_SAVE_SUCCESS,
    chartsConstants.VITALS_SAVE_FAIL,
  ],
  promise: () => api.saveVitals(vitals),
  patientId: vitals.patientId,
  chartId: vitals.chartId,
  vitals,
});

export const changeSignedStatus = ({
  chartId,
  signed,
  callback,
}) => ({
  types: [
    chartsConstants.CHART_CHANGE_SIGNED_STATUS,
    chartsConstants.CHART_CHANGE_SIGNED_STATUS_SUCCESS,
    chartsConstants.CHART_CHANGE_SIGNED_STATUS_FAIL,
  ],
  promise: dispatch => api.changeSignedStatus({ id: chartId, sign: signed })
    .then(
      (res) => {
        if (callback) {
          callback()
        }

        return res;
      },
      err => Promise.reject(err)
    ),
  chartId,
  signed,
});

export const copyPreviousNote = (chartId, copyFrom) => ({
  types: [
    chartsConstants.COPY_PREVIOUS_NOTE,
    chartsConstants.COPY_PREVIOUS_NOTE_SUCCESS,
    chartsConstants.COPY_PREVIOUS_NOTE_FAIL,
  ],
  promise: async (dispatch, getState) => {
    let result;
    let error;

    try {
      result = await api.copyPreviousNote(chartId, copyFrom);
      await dispatch(fetchChartingAssets(chartId));
      let response = [...result.body];
      const state = getState();
      // надо вставить типы систем чтоб отличить что пришли медикейшены или диагнозы
      response = response.map((section) => {
        const newSection = { ...section };

        newSection.systems = newSection.systems.map((system) => {
          const assetSystem = getSystemById(state.chartingAssets, system.id);
          const type = assetSystem?.type || system?.systemType;
          const newSystem = { ...system };
          // if (!assetSystem) {
          //   newSystem.chartingId = newSection.chartingId;
          //   dispatch(addAssetSystem(newSystem));
          // }

          if (type === 1) {
            dispatch(addResources('diagnoses', system.elements.map(o => ({ name: o.name, id: o.id }))));
          }

          if (type === 2) {
            dispatch(addResources('medications', system.elements.map(o => ({ name: o.name, id: o.id }))));
          }

          return { ...newSystem, type };
        });

        return newSection;
      });

      result.body = response;
    } catch (e) {
      error = e;
      await dispatch(fetchChartingAssets(chartId));
    }

    if (error) {
      return Promise.reject(error);
    }
    return result;
  },
  chartId,
});

export const deleteChart = (chartId) => ({
  types: [
    chartsConstants.CHART_DELETE,
    chartsConstants.CHART_DELETE_SUCCESS,
    chartsConstants.CHART_DELETE_FAIL,
  ],
  promise: () => api.deleteChart(chartId),
  chartId,
});

export const updateCptCodeInStore = dataForStore => ({
  type: chartsConstants.UPDATE_CPT_CODE,
  payload: { dataForStore },
});

export const sendCompletionStatusToServer = (chartId) => ({
  types: [
    chartsConstants.UPDATE_CHART_COMPLETION_STATUS,
    chartsConstants.UPDATE_CHART_COMPLETION_STATUS_SUCCESS,
    chartsConstants.UPDATE_CHART_COMPLETION_STATUS_FAIL,
  ],
  promise: async (dispatch, getState) => {
    const status = getChartCompletionPercentage(getState(), chartId);
    return partialUpdateChart({ chartId, completionStatus: status });
  },
});

export const sendCompletionStatusToServerWrapper = chartId => (dispatch, getState) => {
  const { charts } = getState()
  const { cptCode } = getChartById(charts, chartId);
  if (chartId && cptCode) {
    dispatch(sendCompletionStatusToServer(chartId));
  }
}

export const updateCptCode = (dataToRequest, dataForStore) => async (dispatch) => {
  api.updateChartCpt(dataToRequest);

  await dispatch(updateCptCodeInStore(dataForStore));

  const { chartId } = dataToRequest;
  const { cptCode, oldCptCode } = dataForStore;

  const systemId = hpChartTabs.BILLING.systems.CPT.id

  if (oldCptCode !== null) {
    await dispatch(removeElementWithoutHxrvEffect({
      chartId,
      systemId,
      element: parseRightSideCpt(oldCptCode)
    }))
  }

  await dispatch(addElementWithoutHxrvEffect({
      chartId,
      systemId,
      element: parseRightSideCpt(cptCode)
  }))

  dispatch(saveChart(chartId, hpChartTabs.BILLING.step));

  dispatch(sendCompletionStatusToServerWrapper(chartId))
};

export const updateSelectUserDate = (chartId, selectUserDate) => ({
  types: [
    chartsConstants.UPDATE_SELECT_USER_DATE,
    chartsConstants.UPDATE_SELECT_USER_DATE_SUCCESS,
    chartsConstants.UPDATE_SELECT_USER_DATE_FAIL,
  ],
  promise: () => partialUpdateChart({ chartId, dos: selectUserDate }),
  chartId,
  selectUserDate,
});

export const updatePatientCc = (chartId, step) => ({
  types: [
    chartsConstants.UPDATE_PATIENT_CC,
    chartsConstants.UPDATE_PATIENT_CC_SUCCESS,
    chartsConstants.UPDATE_PATIENT_CC_FAIL,
  ],
  promise: (dispatch, getState) => {
    const patientCc = getChartCCString(getState(), chartId);

    const { charts } = getState();
    const { cptCode } = getChartById(charts, chartId);
    if (chartId && cptCode) {
      const status = getChartCompletionPercentage(getState(), chartId);
      return partialUpdateChart({ chartId, patientCc, completionStatus: status });
    }

    return partialUpdateChart({ chartId, patientCc });
  },
});

export const setChartProvider = (providerId, chartId, providers) => ({
  types: [
    chartsConstants.CHART_PROVIDER_SET,
    chartsConstants.CHART_PROVIDER_SET_SUCCESS,
    chartsConstants.CHART_PROVIDER_SET_FAIL,
  ],
  promise: () => api.setChartProvider(providerId, chartId),
  providerId,
  chartId,
  providers,
});

export const saveChartDetails = (chartId, specialSystems) => ({
  type: chartsConstants.SAVE_CHARTS_DETAILS_SUCCESS,
  payload: { chartId, specialSystems },
});
