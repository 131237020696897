import DrChronoConnectionInfo from 'helpers/drChrono/DrChronoConnectionInfo';

const noDrChronoAccount = () => false;

const presentDrChronoAccount = () => true;

const isNotAttachedDrChronoAccount = (response) => {
  const drChronoConnectionInfo = new DrChronoConnectionInfo(response);

  return !drChronoConnectionInfo.isNullErrorDTO();
};

export default (status) => {
  switch (status) {
    case 204:
      return noDrChronoAccount;
    case 201:
      return isNotAttachedDrChronoAccount;
    case 200:
      return presentDrChronoAccount;
    default:
      return noDrChronoAccount
  }
};
