import React from 'react';
import PropTypes from 'prop-types';

import verge from 'verge';
import striptags from 'vendor/striptags/';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PosNegButton from 'components/PosNegButton';
import AddButton from 'components/ElementsViewAddButton';
import Checkbox from 'components/Checkbox/index';
import SubelementsModal from 'components/NoteSystems/SubelementsModal';

import { elementTemplateMap, hpChartTabs, isBillingSystemType, isTheSameSystemBySystemId } from 'helpers/chart';
import * as favoritesHelpers from 'helpers/favorites/favoritesHelpers';
import * as helpers from 'helpers';
import * as elementsConstants from 'helpers/elements/constants';
import DisableCreationElementAndSubElement from 'helpers/elements/disable-creation';
import getFavoriteIdBySystem from 'helpers/favorites/get-favorite-id-by-system';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './SearchResults.module.scss';

const itemNameStyles = ({ isROS }) => ({
  wrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['item-name', isROS && 'item-name--ros']
  })
});

const searchResultsStyles = () => ({
  item: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'item',
    globalStyles: 'break-text'
  })
});

const ItemName = ({ name, searchValue, isROS }) => {
  let index;

  if (searchValue && searchValue.length) {
    index = name.toLowerCase().indexOf(searchValue.toLowerCase());
  }

  const resolvedStyles = itemNameStyles({ isROS });

  if (index === undefined || index === -1) return <span className={resolvedStyles.wrapper}>{name}</span>;

  return (
    <span className={resolvedStyles.wrapper}>
      {name.slice(0, index)}
      <span className={cx.highlight}>
        {name.slice(index, index + searchValue.length)}
      </span>
      {name.slice(index + searchValue.length)}
    </span>
  );
};

const favoriteStyles = ({ isBroad, isExact }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [isBroad && 'broadMatch', isExact && 'exactMatch']
});

const resolvedMatchingFavorites = (match) => ({
  isBroad: match === 'broad',
  isExact: match === 'exact',
});

const resolvedFavoritesStyles = match =>
  favoriteStyles(
    resolvedMatchingFavorites(match)
  );

const ROSItem = ({
  item, className, searchValue, onClick, match,
}) => (
  <div className={className}>
    <PosNegButton
      onClick={() => onClick(true)}
      className={resolvedFavoritesStyles(match)}
    />
    {' '}
    <PosNegButton
      neg
      onClick={() => onClick(false)}
      className={resolvedFavoritesStyles(match)}
    />
    {' '}
    <ItemName name={item.name} searchValue={searchValue} isROS />
  </div>
);

const regularItem = (props) => {
  const {
    item,
    className,
    searchValue,
    onClick,
    match,
    circleButtonText,
  } = props;

  return (
    <button type="button" className={className} onClick={() => onClick()}>
      <span className={cx['button-wrapper']}>
        <AddButton
          circleButtonText={circleButtonText}
          component="span"
          className={resolvedFavoritesStyles(match)}
        />
        <ItemName name={item.name} searchValue={searchValue} />
      </span>
    </button>
  );
};

const regularItemCheckbox = props => (
  <span className={cx['checkbox-wrapper']}>
    <Checkbox
      checked={false}
      onClick={props.onClick}
      loading={false}
      checkboxSize={Checkbox.SIZE.LARGE}
    >
      <ItemName name={props.item.name} searchValue={props.searchValue} />
    </Checkbox>

  </span>
);

class SearchResults extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      setNumber: undefined,
      typeToAdd: undefined,
      listMaxHeight: 0,
    };

    this.arrowShadow = React.createRef();
    this.arrow = React.createRef();
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.stagedElement !== this.props.stagedElement) {
      this.setState({
        subelementsOpened: this.props.stagedElement,
        setNumber: this.props.setNumber,
        typeToAdd: this.props.stagedElementType,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getSortedItems = () => {
    const {
      items,
      exactMatch,
      broadMatch,
      systemId,
    } = this.props;

    return favoritesHelpers.sortItems(
      items,
      exactMatch,
      broadMatch,
      systemId
    );
  };

  handleNewElementClick = async (type) => {
    const {
      createElement,
      searchValue,
      step,
      disableSubelements,
      selectElementCallback,
    } = this.props;

    const res = await createElement({
      name: searchValue,
      chartingId: step && parseInt(step, 10),
    }, {
      type,
    });

    const element = res.body.id || res.body;

    if (!disableSubelements && searchValue.includes('??')) {
      const { setNumber } = helpers.parseElementNameWithVariables(searchValue).variables[0];

      this.setState({
        subelementsOpened: element,
        setNumber,
        typeToAdd: type,
      });
    } else {
      await selectElementCallback(element);
    }

    return res;
  };

  handleExistingElementClick = async (item, type) => {
    const {
      disableSubelements,
      addElement,
      systemType,
      selectElementCallback,
      systemId
    } = this.props;

    if (!disableSubelements && item.name.includes('??')) {
      const { setNumber } = helpers.parseElementNameWithVariables(item.name).variables[0];

      this.setState({
        subelementsOpened: item.id,
        setNumber,
        typeToAdd: type,
      });

      return;
    }

    const elementToAdding = isBillingSystemType(systemType)
      ? item
      : {
        id: item.id,
        type,
        systemType,
        name: item.name,
        favoriteId: item.favoriteId,
      };

    await addElement(elementToAdding);

    selectElementCallback(elementToAdding.id);
  };

  handleItemClick = async (item, type) => {
    const {
      addElement,
      useCheck,
    } = this.props;

    if (useCheck) {
      return addElement(item);
    }

    if (item.id === 'new') {
      return this.handleNewElementClick(type);
    }

    return this.handleExistingElementClick(item, type);
  };

  closeSubelements = () => {
    this.setState({
      subelementsOpened: undefined,
      setNumber: undefined,
    });
  };

  handleWindowResize = () => {
    this.setState({ listMaxHeight: verge.viewportH() / 4 });
  };

  subelementsRef = {};

  isExistedSearchableElement = (searchValue) => {
    const {
      systemId,
      allElements,
      items,
    } = this.props;

    const isPlanPrescription = isTheSameSystemBySystemId(systemId, hpChartTabs.Plan.systems.PRESCRIPTIONS.id);

    if (isPlanPrescription) {
      return this.findSearchableElement(items, searchValue);
    }

    return this.findSearchableElement(allElements, searchValue);
  };

  findSearchableElement = (allElements, searchValue) => {
    return allElements.map(o => !!o && o.name.trim().toLowerCase()).includes(searchValue.trim().toLowerCase());
  };

  render() {
    const {
      style,
      isROS,
      searchValue,
      addElement,
      allowCreation,
      fetching,
      exactMatch,
      broadMatch,
      systemId,
      step,
      chartId,
      createSubelement,
      disableSubelements,
      social,
      patientId,
      addIndexToAutoSet,
      useCheck,
      items,
      saveChart,
      selectSubElementCallback,
      subElementsRef
    } = this.props;

    const {
      typeToAdd,
      setNumber,
      subelementsOpened,
    } = this.state;

    const {
      permissions
    } = this.context;

    const broadItems = favoritesHelpers.getFavoritesArray(broadMatch);
    const exactItems = favoritesHelpers.getFavoritesArray(exactMatch);

    let Item = regularItem;
    if (isROS) Item = ROSItem;
    if (useCheck === true) Item = regularItemCheckbox;

    const sortedItems = favoritesHelpers.sortItems(items, exactMatch, broadMatch, systemId);

    const elementAlreadyExists = this.isExistedSearchableElement(searchValue);

    const showCreateButton = !elementAlreadyExists && allowCreation && !!searchValue.trim().length;

    const createElementName = striptags(searchValue, Object.keys(elementTemplateMap));

    const disableSubelementCreation = DisableCreationElementAndSubElement.isDisabled(social, permissions);

    const resolvedStyles = searchResultsStyles();

    return (
      <div className={cx.wrapper}>
        <div
          className={cx['inner-wrapper']}
          style={style}
        >
          <span className={cx['arrow-shadow']} ref={ref => { this.arrowShadow = ref; }} />
          <span className={cx.arrow} ref={ref => { this.arrow = ref; }} />
          <div
            className={cx.list}
            style={{
              maxHeight: this.state.listMaxHeight,
            }}
          >
            {sortedItems.map(item => (
              <div key={item.id}>
                <Item
                  item={item}
                  className={resolvedStyles.item}
                  searchValue={searchValue}
                  onClick={type => this.handleItemClick(item, type)}
                  match={favoritesHelpers.getItemMatch(getFavoriteIdBySystem(systemId, item), broadItems, exactItems, step === 1)}
                  key={item.id}
                />
                <SubelementsModal
                  ref={subElementsRef}
                  selectSubElementCallback={selectSubElementCallback}
                  disableSubelements={disableSubelements}
                  item={item}
                  step={step}
                  chartId={chartId}
                  saveChart={saveChart}
                  createSubelement={createSubelement}
                  patientId={patientId}
                  addIndexToAutoSet={addIndexToAutoSet}
                  addElement={addElement}
                  disableSubelementCreation={disableSubelementCreation}
                  social={social}
                  systemId={systemId}
                  typeToAdd={typeToAdd}
                  closeSubelements={this.closeSubelements}
                  setNumber={setNumber}
                  subelementsOpened={subelementsOpened}
                  exactMatch={exactMatch}
                  broadMatch={broadMatch}
                />
              </div>
            ))}

            {showCreateButton && !!createElementName.length && (
              <Item
                key="new"
                circleButtonText={elementsConstants.ELEMENTS_TYPES.new.text}
                item={{
                  name: createElementName,
                }}
                className={resolvedStyles.item}
                onClick={type => this.handleItemClick({ name: searchValue, id: 'new' }, type)}
              />
            )}

            {!sortedItems.length && (!showCreateButton || !createElementName.length) && !fetching && (<div>No results found</div>)}
            {!sortedItems.length && !showCreateButton && fetching && (<div>Loading...</div>)}
          </div>
        </div>
        {fetching && <div ref={ref => { this.overlay = ref; } } className={cx.overlay} />}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <SearchResults {...props} subElementsRef={ref} />
));

SearchResults.contextType = AppContext;

SearchResults.propTypes = {
  items: PropTypes.array,
  allElements: PropTypes.array,
  chartId: PropTypes.number,
  selectElementCallback: PropTypes.func,
  selectSubElementCallback: PropTypes.func,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

SearchResults.defaultProps = {
  items: [],
  allElements: [],
  selectElementCallback: () => {},
  selectSubElementCallback: null,
  exactMatch: {},
  broadMatch: {},
};
