import cx
  from 'components/NotAllowedStartNewNote/NotAllowedStartNewNoteButton.module.scss';
import React from 'react';

const NotAllowedStartNewNoteButton = () => {
  return (
    <span style={{ cursor: 'not-allowed' }}>
      <span className={cx['icon']} />
      <span className={cx['text']}>Start New Note</span>
    </span>
  );
};

export default NotAllowedStartNewNoteButton;
