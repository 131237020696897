import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ReactPaginate from 'react-paginate';

import useIsMounted from 'hooks/useIsMounted';

import ComponentSection from 'components/ComponentSection';
import PageError from 'components/PageError';
import PageEmpty from 'components/PageEmpty';
import Loader from 'components/Loader';
import AddButton from 'components/AddButton';
import PatientsImport from 'components/PatientsImport';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import styles from 'containers/Encounters/EncountersTable/EncountersTable.module.scss';
import PatientsList from '../PatientsList';
import PatientsSearch from '../PatientsSearch';

import stringParser from 'helpers/common/string/string-parser';

import { uploadCSV } from 'api/patients';

import cx from './PatientsContent.module.scss';

const importButtonStyles = cssClassesResolver([
  'generic-button', cx.import
]);

const PatientsContent = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { isMounted } = useIsMounted();

  const {
    clearPatientsSearch,
    fetchPatientsPage,
    patientsByPage,
    totalPages,
    patientsFromSearchWithTrueOrder,
    isFetching,
    isSearching,
    searchPatients,
    searchQuery,
    error,
    fetchPatients,
  } = props;

  const { permissions } = useContext(AppContext);

  useEffect(() => {
    return () => {
      if (isMounted) {
        clearPatientsSearch();
      }
    };
  }, []);

  const handlePageClick = (data) => {
    const activePage = stringParser(data.selected);

    setCurrentPage(activePage);
    fetchPatientsPage(activePage);
  };

  const patients = patientsByPage(currentPage) || [];

  const activePatients = Object.values(patients).filter(o => o.active);

  const isFromSearch = searchQuery && !!searchQuery.length;

  const patientsToRender = isFromSearch ? patientsFromSearchWithTrueOrder : activePatients;

  const fetching = isFetching || isSearching;

  const paginationVisible = totalPages > 1 && !isFromSearch;

  return (
    <div className={cx.wrapper}>
      {isFetching ? <Loader /> : ''}

      <div className={cx['header-wrapper']}>
        <div className={cx['search-wrapper']}>
          {!!activePatients.length && !error && (
            <PatientsSearch
              onSearch={searchPatients}
              searchQuery={searchQuery}
              isSafeSearching
            />
          )}
          {!error && permissions.createEditPatients && (
            <span className={cx['new-patient-link-wrapper']}>
            <AddButton
              link="/app/doctor/patients/new"
            >
              Add New Patient
            </AddButton>
          </span>
          )}
        </div>
        <div>
          {!error && (
          <PatientsImport
            uploadCSV={uploadCSV}
            fetchPatients={() => fetchPatientsPage(0)}
          >
            <button type="button" className={importButtonStyles}>Import from CSV</button>
          </PatientsImport>
          )}
        </div>
      </div>

      {!!activePatients.length && !error && (
        <ComponentSection title="patients" className={cx['patients-section']}>
          <PatientsList
            patients={patientsToRender}
            fetching={fetching}
            error={error}
          />
        </ComponentSection>
      )}

      {paginationVisible &&
      <div className={cx['pagination-wrapper']}>
        <ReactPaginate
          previousLabel="«"
          nextLabel="»"
          breakLabel={<i>...</i>}
          breakClassName="break-me"
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={styles.pagination}
          subContainerClassName="pages pagination"
          activeClassName={styles.active}
        />
      </div>
      }

      {!activePatients.length && !error && !isFetching && (
        <PageEmpty
          message="No patients found"
        />
      )}
      {error && (
        <PageError
          message={error.message}
          reload={fetchPatients}
        />
      )}
    </div>
  );
};

export default PatientsContent;

PatientsContent.propTypes = {
  clearPatientsSearch: PropTypes.func.isRequired,
  fetchPatientsPage: PropTypes.func.isRequired,
  fetchPatients: PropTypes.func.isRequired,
};
