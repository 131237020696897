import React, { PureComponent } from 'react';
import Dashboard from '../Dashboard';

class DashboardWrapper extends PureComponent {
  state = {
    activeSignedPage: 0,
    activeUnsignedPage: 0,
    sortUnsignedBy: 'DOS',
    sortSignedBy: 'DOS',
    isUnsignedAsc: false,
    isSignedAsc: false,
  };

  onSortChange = (sortBy, isAsc, signedStatus) => {
    this.setState({ sortBy, isAsc });

    if (signedStatus === 'signed') {
      this.setState({ sortSignedBy: sortBy, isSignedAsc: isAsc });
    } else if (signedStatus === 'unsigned') {
      this.setState({ sortUnsignedBy: sortBy, isUnsignedAsc: isAsc });
    }
  };

  onPageChange = (page, signedStatus, callback = () => {}) => {
    if (signedStatus === 'signed') {
      this.setState({ activeSignedPage: page }, () => callback());
    } else if (signedStatus === 'unsigned') {
      this.setState({ activeUnsignedPage: page }, () => callback(page));
    }
  };

  render = () => {
    return (
      <Dashboard
        {...this.state}
        onSortChange={this.onSortChange}
        onPageChange={this.onPageChange}
      />
    );
  };
}

export default DashboardWrapper;
