import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  isModalOpen: false,
  isPlansModalOpen: false,
  isBillingInfoModalOpen: false,
};

export default handleActions({
  [constants.SET_PURCHASE_SEAT_MODALS_VIEW]: (state, action) => ({
    ...initialState,
    ...action.payload.modalsView,
  }),
}, initialState);
