import React from 'react';
import { shape, number, string, bool } from 'prop-types';
import { compose } from 'redux';

import PatientEncountersContainer, { withPatientEncountersFetchLayer } from 'containers/Encounters/PatientEncountersContainer';
import BlockedForReadOnlyFetchLayer from 'containers/BlockedForReadOnly/BlockedForReadOnlyFetchLayer';

import PatientImage from '../PatientImage';
import Info from '../Info';
import RecentVisits from '../RecentVisits';

const Encounters = compose(
  PatientEncountersContainer,
  withPatientEncountersFetchLayer
)(RecentVisits);

const ShortPatientInfo = (props) => {
  const {
    patient,
    permissions : { viewEditPatientClinicalInformation },
  } = props;

  const { id, email, imageUrl } = patient;

  return (
    <>
      <PatientImage
        patientId={id}
        imageUrl={imageUrl}
      />
      <Info email={email} patient={patient} />
      {viewEditPatientClinicalInformation && (
        <BlockedForReadOnlyFetchLayer>
          <Encounters
            patientId={id}
            patient={patient}
            componentType="short-patient-info"
          />
        </BlockedForReadOnlyFetchLayer>
      )}
    </>
  );
};

ShortPatientInfo.propTypes = {
  patient: shape({
    id: number.isRequired,
    email: string,
    imageUrl: string
  }).isRequired,
  permissions: shape({
    viewEditPatientClinicalInformation: bool
  }).isRequired,
};

export default ShortPatientInfo;
