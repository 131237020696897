import React from 'react';
import PropTypes from 'prop-types';

import cx from './TabsBody.module.scss';

const TabsBody = ({
  type,
  children,
}) => {
  return (
    <div className={type === 'inner' ? cx['wrapper-inner'] : cx['wrapper']}>
      {children}
    </div>
  );
};

TabsBody.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

export default TabsBody;
