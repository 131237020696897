import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportSystem from './ReportSystem';
import ChartReportHeadline from '../../ChartReportHeadline';
import ChartReportSection from '../../ChartReportSection';
import SystemNoteContainer from 'containers/SystemNoteContainer';

import cx from '../HPNote.module.scss';

import Modal from 'components/Modal';

import { withRouter } from 'containers/RouterParams';

import { systemsTypesToExcludeReportRendering } from 'helpers/systems/ids';
import isEmpty from 'helpers/common/array/is-empty';

const ConnectedReportSystem = SystemNoteContainer(ReportSystem);

const modalData = {
  type: 'confirm',
  title: 'Remove system',
  content: '<p>Are you sure you want to remove this system?</p><p class="warning-text"><strong>Warning:</strong> All enclosed elements will be removed!</p>',
};

const modalDataFixed = {
  type: 'confirm',
  title: 'Clear contents',
  content: '<p><strong style="color: orange">Warning:</strong> Are you sure you want to remove all enclosed elements?</p>',
};

class ReportChartingStep extends Component {
  state = {
    isModalOpen: false,
    systemToDelete: null,
  };

  onElementRemove = (systemId, element) => this.props.removeElement({ chartId: this.props.chartId, systemId, element, step: this.props.step });

  onElementUpdate = async (systemId, element) => {
    const {
      addElement,
      saveChart,
      chartId,
      step,
    } = this.props;

    await addElement(chartId, systemId, element);

    saveChart(chartId, step);
  };

  openModal = (system) => {
    // Modal component is shit and doesn't update if its props
    // are changed when it is already rendered, so we have
    // to update system first and then show modal
    this.setState({
      systemToDelete: system,
    }, () => this.setState({ isModalOpen: true }));
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      systemToDelete: null,
    });
  };

  modalConfirm = () => {
    const { systemToDelete } = this.state;

    this.props.removeSystem(this.props.chartId, systemToDelete);

    this.transitionToSystem(undefined, undefined, true);

    this.closeModal();
  };

  transitionToSystem = (systemId, location = this.props.location, replace) => {
    const func = replace ? this.props.router.replace : this.props.router.push;

    func({
      pathname: location.pathname,
      query: {
        ...location.query,
        system: systemId,
      },
    });
  };

  render() {
    const {
      systems,
      enclosedElements,
      toggleChartReport,
      chartId,
      step,
      viewMode,
      patient,
      title,
      star,
    } = this.props;

    const { openModal, closeModal, modalConfirm } = this;
    const { isModalOpen, systemToDelete } = this.state;

    // check if step has enclosed elements, because it can have none and still have empty systems
    const stepHasEnclosedElements = systems.reduce((acc, val) => acc || (enclosedElements[val.id] && !!enclosedElements[val.id].length), false);

    const systemsToDisplay = systems.filter(system => !systemsTypesToExcludeReportRendering.includes(system.type));
    const areNoSystemsToDisplay = isEmpty(systemsToDisplay);
    if (!systems.length || !stepHasEnclosedElements || areNoSystemsToDisplay) return null;

    let filteredSystems = systemsToDisplay;

    if (step === 6) {
      const nonEditableSystems = systems.filter(system => (system.type !== 5) && (system.name.toLowerCase() !== 'follow-up'));
      const followUpSystem = systems.filter(system => system.name.toLowerCase() === 'follow-up');
      const editableSystems = systems.filter(system => system.type === 5);

      filteredSystems = [...nonEditableSystems, ...editableSystems, ...followUpSystem];
      filteredSystems = filteredSystems.filter(system => !systemsTypesToExcludeReportRendering.includes(system.type));
    }

    return (
      <ChartReportSection>
        {star && <div className={cx['report-star']} />}

        <ChartReportHeadline
          link={!viewMode && `/app/doctor/charts/${chartId}?step=${step}`}
          toggleChartReport={!viewMode && toggleChartReport}
        >
          {title}
        </ChartReportHeadline>

        {filteredSystems
          .map(system => (
              <ConnectedReportSystem
                key={system.id}
                system={system}
                systemId={system.id}
                chartId={chartId}
                elements={enclosedElements[system.id]}
                removeElement={element => this.onElementRemove(system.id, element)}
                onElementUpdate={element => this.onElementUpdate(system.id, element)}
                onSystemRemove={openModal}
                viewMode={viewMode}
                patient={patient}
                // for navigation to progress note
                toggleChartReport={toggleChartReport}
                step={step}
              />
            )
          )}

        <Modal
          data={systemToDelete?.fixed ? modalDataFixed : modalData}
          isOpen={isModalOpen}
          onModalClose={closeModal}
          onModalConfirm={modalConfirm}
        />

      </ChartReportSection>
    );
  }
}

ReportChartingStep.propTypes = {
  removeSystem: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  toggleChartReport: PropTypes.func.isRequired,
  saveChart: PropTypes.func.isRequired,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  viewMode: PropTypes.bool,
  systems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  enclosedElements: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  star: PropTypes.bool,
  systemNotes: PropTypes.object.isRequired,
  deleteSystemNote: PropTypes.func.isRequired,
};

export default withRouter(ReportChartingStep);
