import * as api from 'api/roles';
import * as constants from './constants';

const formatRoleData = (role) => {
  const permissionIds = role.permissions.map((p) => p.id);

  return {
    id: role.id,
    name: role.name,
    permissions: permissionIds,
  };
};

export const fetchRoles = () => ({
  types: [
    constants.ROLES_FETCH,
    constants.ROLES_FETCH_SUCCESS,
    constants.ROLES_FETCH_FAIL,
  ],
  promise: api.fetchRoles,
});

export const removeRole = (id) => ({
  types: [
    constants.ROLE_REMOVE,
    constants.ROLE_REMOVE_SUCCESS,
    constants.ROLE_REMOVE_FAIL,
  ],
  promise: () => api.removeRole(id),
  id,
});

export const createRole = (role) => ({
  types: [
    constants.ROLE_CREATE,
    constants.ROLE_CREATE_SUCCESS,
    constants.ROLE_CREATE_FAIL,
  ],
  promise: () => {
    const roleData = formatRoleData(role);

    return api.createRole(roleData);
  },
  role,
});

export const updateRole = (role) => ({
  types: [
    constants.ROLE_UPDATE,
    constants.ROLE_UPDATE_SUCCESS,
    constants.ROLE_UPDATE_FAIL,
  ],
  promise: () => {
    const roleData = formatRoleData(role);

    return api.updateRole(roleData);
  },
  role,
});
