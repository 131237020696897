import Modal from 'components/Modal';
import React from 'react';
import PropTypes from 'prop-types';

import cx from './DirtyCheckLayer.module.scss';

import UpdateBlocker from './UpdateBlocker';

export default Component => (class DirtyCheckLayer extends React.Component {
  // static propTypes = {
  //   dirtyCheckIdentity: PropTypes.string.isRequired,
  //   router: PropTypes.object.isRequired,
  //   routes: PropTypes.array.isRequired,
  //   location: PropTypes.object.isRequired,
  //   responsiveHeight: PropTypes.bool,
  //   shouldUpdate: PropTypes.func,
  // };
  //
  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     dirty: false,
  //     showModal: false,
  //     prev: props[props.dirtyCheckIdentity],
  //     next: null,
  //   };
  // }
  //
  // componentDidMount() {
  //   // let's hope form component is rendered last in routing chain and pray for the best
  //   this.hook = this.props.router.setRouteLeaveHook(this.props.routes[this.props.routes.length - 1], this.routerWillLeave);
  // }
  //
  // componentWillUnmount() {
  //   if (this.hook) {
  //     this.hook();
  //   }
  // }
  //
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const identifier = this.props[this.props.dirtyCheckIdentity];
  //   const nextIdentifier = nextProps[this.props.dirtyCheckIdentity];
  //
  //   if (
  //     identifier !== nextIdentifier
  //       && this.state.dirty
  //       && identifier === this.state.prev
  //   ) {
  //     this.preventTransition(nextProps.location);
  //     return;
  //   }
  //
  //   if (nextIdentifier !== this.state.prev) {
  //     this.setState({
  //       prev: nextIdentifier,
  //     });
  //   }
  // }
  //
  // preventTransition = (nextLocation) => {
  //   this.setState({
  //     next: nextLocation.pathname + nextLocation.search,
  //   });
  //   this.openModal();
  //
  //   this.props.router.replace({ pathname: this.props.location.pathname, query: this.props.location.query });
  // };
  //
  // routerWillLeave = (route) => {
  //   if (this.state.dirty) {
  //     this.setState({
  //       next: route.pathname + route.search,
  //     });
  //     this.openModal();
  //   }
  //
  //   return !this.state.dirty;
  // };
  //
  // openModal = () => this.setState({ showModal: true });
  //
  // closeModal = () => this.setState({ showModal: false });
  //
  // confirm = () => {
  //   this.setState({ dirty: false, showModal: false }, () => {
  //     if (this.state.next) {
  //       this.props.router.push(this.state.next);
  //     }
  //   });
  // };
  //
  // setDirty = (dirty, callback) => {
  //   this.setState({ dirty }, callback && callback());
  // };

  render() {
    // const { responsiveHeight } = this.props;

    return (
      // <div
      //   className={cx[responsiveHeight ? 'wrapper-responsive-height' : 'wrapper']}
      // >
      //   <UpdateBlocker
      //     dirty={this.state.dirty}
      //     identifier={this.props[this.props.dirtyCheckIdentity]}
      //     shouldUpdate={this.props.shouldUpdate}
      //   >
          <Component
            {...this.props}
            // setDirty={this.setDirty}
            // dirty={this.state.dirty}
            // isConfirmModalOpen={this.state.showModal}
          />
        // </UpdateBlocker>


        // <Modal
        //   data={{
        //     type: 'confirm',
        //     title: 'Unsaved changes',
        //     content: '<p>Are you sure you want to leave?</p><p class="warning-text"><strong>Warning:</strong> All changes will be discarded!</p>',
        //   }}
        //   isOpen={this.state.showModal}
        //   onModalClose={this.closeModal}
        //   onModalConfirm={this.confirm}
        // />
      // </div>
    );
  }
});
