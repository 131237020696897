import React from 'react';

import cx from './Keyboars.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

const symbols = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', '⬅'];

const Keyboard = (props) => {
  const { onClick, pressedKey } = props;
  return (
    <div className={cx['keyboard']}>
      {symbols.map((symbol, i) => {
        if (!symbol.length) return <div key={i} className={cx['button-wrapper']} />;

        const styles = cssClassesResolver([
          cx['button'],
          pressedKey === symbol && cx['button--pressed']
        ])

        return (
          <div key={i} className={cx['button-wrapper']}>
            <button
              type="button"
              className={styles}
              onClick={() => onClick(symbol)}
            >
              {(i === symbols.length - 1 && (
                <img
                  className={cx['arrow']}
                  src={require('../../../../../../images/arrow_left.png')}
                  alt=""
                />
              )) || symbol}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Keyboard;
