import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import SubelementsList from 'components/SubelementsList';
import Modal from 'components/Modal';

import cx from './VariableButton.module.scss';

export const VariableButton = (props) => {
  const {
    children,
    step,
    chartId,
    activeSubelement,
    onCreateSubelement,
    disableSubelementCreation,
    social = false,
    systemId,
    patientId,
    elementId,
    element,
    index,
    setNumber,
    isDisabledSubElement,
    isChartSaving,
    exactMatch = {},
    broadMatch = {},
    indexToAutoSet,
    onSelect,
    resetIndexToAutoSet,
    selectAfterFirstSubElementCallback,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onSubelementSelect = useCallback(async (subelement, activeSubelement, elementId, index) => {
    await onSelect(subelement, activeSubelement, elementId, index);
    closeMenu();
    resetIndexToAutoSet(index, elementId);
    selectAfterFirstSubElementCallback(element, subelement);
  }, [onSelect, resetIndexToAutoSet, selectAfterFirstSubElementCallback, element]);

  const isDisplaySubelementsModal = () => {
    return indexToAutoSet === index;
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const subElementSelectWrapper = useCallback((subElement) => {
    onSubelementSelect(subElement, activeSubelement, elementId, index);
  }, []);

  return (
    <Fragment>
      <button
        type="button"
        className={cx.variable}
        disabled={isDisabledSubElement}
        onClick={isMenuOpen ? closeMenu : openMenu}
      >
        {children}
      </button>
      {(isMenuOpen || isDisplaySubelementsModal()) && (
        <Modal
          isOpen={isMenuOpen || isDisplaySubelementsModal()}
          data={{ type: 'subelements', title: 'Sub-elements', content: '' }}
          onModalClose={closeMenu}
          onModalConfirm={closeMenu}
        >
          <div className={cx['subelements-wrapper']}>
            <SubelementsList
              element={element}
              elementId={elementId}
              position={index + 1 || 1}
              step={step}
              chartId={chartId}
              isChartSaving={isChartSaving}
              onInitialized={() => {
                // this.tetherRef?.getTether()?.position();
              }}
              onSubelementSelect={subElementSelectWrapper}
              onClose={closeMenu}
              createSubelement={onCreateSubelement}
              disableCreation={disableSubelementCreation}
              setNumber={setNumber}
              social={social}
              systemId={systemId}
              patientId={patientId}
              exactMatch={exactMatch}
              broadMatch={broadMatch}
            />
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

VariableButton.propTypes = {
  children: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,

  // step and chartId are only present if we are in encounter note
  step: PropTypes.number,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  elementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  activeSubelement: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onCreateSubelement: PropTypes.func.isRequired,
  disableSubelementCreation: PropTypes.bool,
  systemId: PropTypes.number,
  patientId: PropTypes.number.isRequired,
  social: PropTypes.bool,
  indexToAutoSet: PropTypes.number,
  isChartSaving: PropTypes.bool,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

