import DefaultPatientImage from 'helpers/images/defaults/patient';

export default class BackgroundImageStyle {
  static define(imageUrl, defaultImage = DefaultPatientImage.get()) {
    const definedImageUrl = imageUrl || defaultImage;
    return {
      backgroundImage: `url(${definedImageUrl})`,
    };
  }
}
