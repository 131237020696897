import React from 'react';
import PropTypes from 'prop-types';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './CancelRoundButton.module.scss';

const styles = ({ disabled, className }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['cancel', disabled && 'cancel--disabled'],
  globalStyles: className
})

const CancelRoundButton = ({
  onClick,
  style,
  className,
  disabled,
}) => (
  <button
    type="button"
    className={styles({ disabled, className })}
    onClick={onClick}
    style={style}
  />
);

CancelRoundButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};


export default CancelRoundButton;
