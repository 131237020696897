import React, { useEffect, useRef, useState } from 'react';
import { bool, func, shape, string, number } from 'prop-types';

import Loader from 'components/Loader';
import SaveRoundButton from 'components/SaveRoundButton';
import CancelRoundButton from 'components/CancelRoundButton';

import cx from './NoteInput.module.scss';

const NoteInput = props => {
  const [noteInput, setNoteInput] = useState({ originalValue: '', value: '' });
  const textInput = useRef('');

  const setInitialValue = (value = '', optionalState = {}) => {
    setNoteInput({
      originalValue: value,
      value,
      ...optionalState,
    });
    if (!textInput) return;
    textInput.current = value;
  };

  const updateNote = (rProps) => {
    const { systemNote } = rProps;
    const newSystemNote = { ...systemNote };

    setInitialValue(newSystemNote.freeText);

    const isNew = typeof newSystemNote.id === 'undefined';

    setNoteInput(prev => ({ ...prev, isNew }));
  };

  useEffect(() => {
    updateNote(props);
  }, []);

  useEffect(() => {
    updateNote(props);
  }, [props?.systemNote?.freeText]);

  const setValue = (e) => {
    const newValue = e.target.value;
    setNoteInput(prev => ({ ...prev, value: newValue }));
  };

  const submit = () => {
    const {
      systemId,
      chartId,
      createSystemNote,
      updateSystemNote,
      systemNote = {},
    } = props;

    const {
      value,
      originalValue,
    } = noteInput;

    if (value === originalValue) return;

    const noteData = {
      systemId,
      chartId,
      freeText: value,
      id: systemNote.id,
    };

    if (noteInput.isNew) {
      createSystemNote(noteData);
    } else {
      noteData.id = systemNote.id;
      updateSystemNote(noteData);
    }
  };

  const onEnterClick = (event) => {
    const content = event.target.value;
      const isEnterKey = (event.which === 13);
      const isLongEnough = content.length > 0;

    if (isEnterKey && isLongEnough) {
      submit();
    }
  };

  const cancel = () => {
    const { originalValue } = noteInput;

    setNoteInput(prev => ({ ...prev, value: originalValue }));
    textInput.current = originalValue;
  };

  const {
    title,
    isSystemNoteSaving = false,
    isSystemNoteFetching = false,
    elementsCount,
  } = props;

  const {
    value,
    originalValue,
  } = noteInput;

  const changed = value !== originalValue;

  const inputsDisabled = !changed || !elementsCount;

  const isLoading = isSystemNoteSaving || isSystemNoteFetching;

  return (
    <div className={cx.wrapper}>
      <span className={cx.title}>{title} - Free Text / Dictation</span>
      <div className={cx['input-wrapper']}>
        {isLoading && (
          <div className={cx['loader-wrapper']}>
            <Loader />
          </div>
        )}
        <textarea
          value={value}
          ref={textInput}
          className={cx.input}
          onChange={setValue}
          disabled={!elementsCount}
          onKeyDown={onEnterClick}
        />
      </div>
      <div className={cx.footer}>
        <SaveRoundButton onClick={submit} disabled={inputsDisabled} className="button-large" />
        <CancelRoundButton onClick={cancel} disabled={inputsDisabled} className="button-large" />
      </div>
    </div>
  );
};

NoteInput.propTypes = {
  isSystemNoteSaving: bool,// on social is null
  isSystemNoteFetching: bool,// on social is null
  createSystemNote: func.isRequired,
  updateSystemNote: func.isRequired,
  title: string.isRequired,
  systemNote: shape({

  }),
  systemId: number.isRequired,
  chartId: number,// on social is null
  elementsCount: number.isRequired
};

export default NoteInput;
