import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addConsents, getListConsents } from 'modules/consent';
import { listConsents } from 'api/consent';

export const useConsents = () => {
  const dispatch = useDispatch();
  const consents = useSelector(getListConsents);

  useEffect(() => {
    const listConsentsWrapper = async () => {
      const response = await listConsents();
      const list = await response.json();
      dispatch(addConsents(list));
    };

    listConsentsWrapper();
  }, []);

  return { consents };
};