import * as api from 'api/financial';
import * as constants from './constants';

export const fetchPaymentsPlans = () => ({
  types: [
    constants.PAYMENTS_PLANS_FETCH,
    constants.PAYMENTS_PLANS_FETCH_SUCCESS,
    constants.PAYMENTS_PLANS_FETCH_FAIL,
  ],
  promise: () => api.fetchPaymentsPlans(),
});

export const setCurrentPlanToPurchaseSeat = plan => ({
  type: constants.SET_CURRENT_PLAN_TO_PURCHASE_SEAT,
  plan,
});

