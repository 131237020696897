import * as constants from '../constants';

export const actionsToHandle = [
  constants.HISTORY_ITEMS_FETCH,
  constants.HISTORY_ITEMS_FETCH_SUCCESS,
  constants.HISTORY_ITEMS_FETCH_FAIL,
  constants.HISTORY_ITEM_CREATE_SUCCESS,
  constants.HISTORY_ITEM_UPDATE_SUCCESS,
  constants.HISTORY_ITEM_DELETE_FROM_LIST,
  constants.HISTORY_ITEM_DELETE,
  constants.HISTORY_ITEM_DELETE_SUCCESS,
  constants.HISTORY_ITEM_DELETE_FAIL,
];

export const createReducerWithSingleHandler = (handler) => {
  const result = {};

  actionsToHandle.forEach((actionType) => {
    result[actionType] = handler;
  });

  return result;
};
