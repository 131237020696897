import React from 'react';
import UserAgreement from 'components/Documents/UserAgreement';
import DocumentHeader from 'components/DocumentHeader';

const UserAgreementPage = () => (
  <DocumentHeader>
    <UserAgreement />
  </DocumentHeader>
);

export default UserAgreementPage;
