import React from 'react';
import PropTypes from 'prop-types'

import cx from './Tooltip.module.scss';

const Tooltip = React.forwardRef(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className={cx['popup-wrapper']}
    >
      <div
        className={cx.popup}
      >
        {children}
      </div>
    </div>
  );
});

Tooltip.propTypes = {
  children: PropTypes.node.isRequired
}

export default Tooltip;
