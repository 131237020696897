export const SEATS_FETCH = 'SEATS_FETCH';
export const SEATS_FETCH_SUCCESS = 'SEATS_FETCH_SUCCESS';
export const SEATS_FETCH_FAIL = 'SEATS_FETCH_FAIL';

export const SEAT_USER_SET = 'SEAT_USER_SET';
export const SEAT_USER_SET_SUCCESS = 'SEAT_USER_SET_SUCCESS';
export const SEAT_USER_SET_FAIL = 'SEAT_USER_SET_FAIL';

export const SEAT_USER_REMOVE = 'SEAT_USER_REMOVE';
export const SEAT_USER_REMOVE_SUCCESS = 'SEAT_USER_REMOVE_SUCCESS';
export const SEAT_USER_REMOVE_FAIL = 'SEAT_USER_REMOVE_FAIL';

export const SEAT_CREATE = 'SEAT_CREATE';
export const SEAT_CREATE_SUCCESS = 'SEAT_CREATE_SUCCESS';
export const SEAT_CREATE_FAIL = 'SEAT_CREATE_FAIL';

export const SEAT_PRICE_FETCH = 'SEAT_PRICE_FETCH';
export const SEAT_PRICE_FETCH_SUCCESS = 'SEAT_PRICE_FETCH_SUCCESS';
export const SEAT_PRICE_FETCH_FAIL = 'SEAT_PRICE_FETCH_FAIL';

export const SEAT_PURCHASE = 'SEAT_PURCHASE';
export const SEAT_PURCHASE_SUCCESS = 'SEAT_PURCHASE_SUCCESS';
export const SEAT_PURCHASE_FAIL = 'SEAT_PURCHASE_FAIL';

export const SEAT_SET_STATUS = 'SEAT_SET_STATUS';

export const SEATS_UPDATE = 'SEATS_UPDATE';
export const SEATS_UPDATE_SUCCESS = 'SEATS_UPDATE_SUCCESS';
export const SEATS_UPDATE_FAIL = 'SEATS_UPDATE_FAIL';

export const SET_USER_INACTIVE = 'SET_USER_INACTIVE';
