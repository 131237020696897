import { connect } from 'react-redux';
import React from 'react';

import * as actions from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';

import FetchLayer, { getMapStateToProps } from '../../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const familyItemContainer = Component => props => (
  <FetchLayer
    itemId={props.familyItemId}
    fetchItem={props.fetchFamilyItem}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.FAMILY, 'familyItem', 'familyItemId');

const mapActionCreators = {
  fetchFamilyItem: actions.fetchFamilyItem,
  deleteFamilyItem: actions.deleteFamilyItem,
  createFamilyItem: actions.createFamilyItem,
  updateFamilyItem: actions.updateFamilyItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(familyItemContainer(component));
