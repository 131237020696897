import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import isEmpty from 'lodash/isEmpty';

import PastDueLayerConnector from 'containers/PastDueLayer/PastDueLayerConnector';


class PastDueLayer extends React.Component {
  componentDidMount() {
    this.tryToPerformPastDuePayment(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.tryToPerformPastDuePayment(nextProps);
  }

  tryToPerformPastDuePayment = (props) => {
    const {
      seats,
      pastDue,
    } = props;


    const seatsArray = Object.values(seats);

    if (!pastDue.didPaymentOccur && !isEmpty(seats)) {
      const {
        isReadOnly,
        isNeedToPay,
      } = this.updatePastDueParams(seatsArray, false);

      this.sendPayment(isNeedToPay, isReadOnly, seatsArray);
    }
  };

  updatePastDueParams = (seats, isPaymentOccur) => {
    const {
      billingService,
    } = this.context;

    const severity = billingService.getSeverity(seats);
    const isBeforeReadOnlyStatus = billingService.isBeforeReadOnly(seats);
    const isReadOnly = billingService.isReadOnly(seats);
    const isNeedToPay = billingService.getNoPaymentSeats(seats).length !== 0 && !isReadOnly;

    this.props.setPastDueParams(
      billingService.shouldShowWarning(seats),
      isReadOnly,
      billingService.getIsBeforeReadOnly(isBeforeReadOnlyStatus),
      severity,
      billingService.getDaysUntilReadOnly(seats),
      billingService.isOrangeIcon(severity),
      isNeedToPay,
      isPaymentOccur
    );

    return { isReadOnly, isNeedToPay };
  };

  sendPayment = async (isNeedToPay, isReadOnly, seats) => {
    if (isNeedToPay) {
      const {
        billingService,
      } = this.context;

      const { doPayment } = this.props;

      const seatIds = billingService.getNeedToPaySeatIds(seats);
      try {
        doPayment(seatIds);
        this.props.setDidPayment(true);
      } catch (err) {
        this.props.setDidPayment(true);
      }
    } else {
      this.updatePastDueParams(seats, true);
    }
  };

  render = () => <></>;
}

PastDueLayer.propTypes = {
  seats: PropTypes.object.isRequired,
  setPastDueParams: PropTypes.func.isRequired,
  pastDue: PropTypes.object.isRequired,
  paymentAllSeat: PropTypes.func.isRequired,
  setDidPayment: PropTypes.func.isRequired,
};

PastDueLayer.contextType = AppContext;

export default compose(
  PastDueLayerConnector,
)(PastDueLayer);
