import { handleActions } from 'redux-actions';
import * as constants from '../../constants';
import keyBy from 'lodash/keyBy';
import * as chartsConstants from '../../../charts/constants';

const initialState = {};

const handlers = {
  [constants.ELEMENT_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.id]: {
      ...state[action.payload.result.id],
      ...action.payload.elementToCreate,
      id: action.payload.result.id,
      favoriteId: action.payload.result.favoriteId,
      locked: false,
    },
  }),
  [constants.ELEMENT_UPDATE]: (state, action) => ({
    ...state,
    [action.payload.newElement.id]: {
      ...state[action.payload.newElement.id],
      isUpdating: true,
    },
  }),
  [constants.ELEMENTS_DELETE_SUCCESS]: (state, action) => {
    const nextState = { ...state };

    action.payload.dataForLocalStorage.forEach((elementId) => {
      delete nextState[elementId];
    });

    return nextState;
  },
  [constants.ELEMENT_DELETE_SUCCESS]: (state, action) => {
    const nextState = { ...state };

    delete nextState[action.payload.id];

    return nextState;
  },
  [constants.ELEMENT_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.id]: {
      name: action.payload.newElement.name,
      id: action.payload.result.id,
      favoriteId: action.payload.result.favoriteId,
      systemId: action.payload.systemId,
      chartingId: action.payload.chartingId,
    },
    [action.payload.oldElement.id]: {
      ...action.payload.oldElement,
      deleted: true,
      isUpdating: false,
    },
  }),
  [constants.ELEMENT_UPDATE_FAIL]: (state, action) => ({
    ...state,
    [action.payload.newElement.id]: {
      ...state[action.payload.newElement.id],
      isUpdating: false,
    },
  }),
  [chartsConstants.COPY_PREVIOUS_NOTE_SUCCESS]: (state, action) => {
    let nextState = { ...state };

    action.payload.result.forEach((chartingStep) => {
      chartingStep.systems.forEach((system) => {
        if (system.type === 1 || system.type === 2) {
          return;
        }

        nextState = {
          ...nextState,
          ...keyBy(system.elements.map((element) => {
            const _element = {
              ...nextState[element.id],
              ...element,
            };

            delete _element.ids;
            delete _element.type;
            delete _element.subIds;
            // _element.systemId = system.id;

            return _element;
          }), 'id'),
        };
      });
    });

    return nextState;
  },
};

export const handledActions = Object.keys(handlers);

export default handleActions(handlers, initialState);
