import { connect } from 'react-redux';
import * as chartsSelectors from 'modules/charts/selectors';
import * as chartingSessionsSelectors from 'modules/chartingSessions/selectors';
import * as resourceSelectors from 'modules/resources/selectors';
import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import { getActivePatient } from 'modules/patients/selectors';
import { resetBroadOrExactFavorites } from 'modules/resetFavorites/selectors';

import * as chartingSessionsActions from 'modules/chartingSessions/actions';
import { createElement, updateElementWrapper } from 'modules/chartingAssets/actions';
import { addIndexToAutoSet, resetIndexToAutoSet } from 'modules/autoOpenSubElementsModal/actions';
import { fetchResourcesNoCaching } from 'modules/resources/actions';
import { newMedication } from 'modules/patientsHx/actions';

import { isNullOrUndefined } from 'helpers';
import FilterElementsBySystems from 'helpers/elements/filters/elements-by-system';
import { hpChartTabs } from 'helpers/chart';
import { getElementIdsByStep } from 'modules/chartingSessions/selectors';

const mapStateToProps = (state, ownProps) => {
  const {
    chartId,
    system,
    activeSystem,
    broadMatch,
    exactMatch,
  } = ownProps;

  const section = chartingAssetsSelectors.getSectionById(state.chartingAssets, system.chartingId);

  const filterElementsBySystems = new FilterElementsBySystems(state.chartingAssets, section.appId, system);
  let allElements = filterElementsBySystems.getElements();

  const assignedDiagnoses = chartingAssetsSelectors.getResourceEnclosedElements(state, hpChartTabs.A.systems.ICD_TEN.type, chartId)
    .map(diagnosisElement => ({
      ...resourceSelectors.getResourceById(state.resources, 'diagnoses', diagnosisElement.id),
      ...diagnosisElement,
    }));

  // if there is chartId present we should return enclosed elements
  // else all elements for this system

  let elements = allElements;
  if (!isNullOrUndefined(chartId)) {
    switch (system.type) {
      // diagnoses
      case 1:
        elements = assignedDiagnoses;
        allElements = chartingAssetsSelectors.getElementsArrayBySystem(state.chartingAssets, system.id)
          .map(diagnosisId => resourceSelectors.getResourceById(state.resources, 'diagnoses', diagnosisId));
        break;
      // medications
      case 2:
        elements = chartingSessionsSelectors.getEnclosedElementsForSystem(state.chartingSessions, chartId, system.id)
          .map(medicationElement => ({
            ...resourceSelectors.getResourceById(state.resources, 'medications', medicationElement.id),
            ...medicationElement,
            ids: medicationElement.ids && medicationElement.ids.map((diagnosisId) => {
              if (typeof diagnosisId === 'object') {
                return diagnosisId;
              }
              return resourceSelectors.getResourceById(state.resources, 'diagnoses', diagnosisId);
            }),
          }));
        allElements = chartingAssetsSelectors.getElementsArrayBySystem(state.chartingAssets, system.id)
          .map(medicationId => resourceSelectors.getResourceById(state.resources, 'medications', medicationId));
        break;
      default:
        elements = chartingSessionsSelectors.getEnclosedElementsForSystem(state.chartingSessions, chartId, system.id)
          .map(element => ({
            // we get element model from redux store
            // also we have ids and type (pos/neg) in element,
            // so we have to merge these objects
            ...chartingAssetsSelectors.getElementById(state.chartingAssets, element.id),
            ...element,
            ids: element.ids && element.ids.map((diagnosisId) => {
              if (typeof diagnosisId === 'object') {
                return diagnosisId;
              }
              return resourceSelectors.getResourceById(state.resources, 'diagnoses', diagnosisId);
            }),
          }));
        break;
    }
  }

  // we filter out enclosed elements for search
  // NOTE: we cannot have {items} already filtered in container
  // because we have to hide them slowly using timeout
  // if we will filter out enclosed elements from items directly in container
  // and pass this component already filtered items
  // when we add enclosed element it will disappear from right panel immediately

  const activeElementsIds = chartingAssetsSelectors.getActiveElements(system.chartingId, state, system.id, chartId);
  const searchableItems = allElements.filter((item) => {
    if (!item) return false;

    return !activeElementsIds.includes(item.id);
  });

  const isChartSaving = chartsSelectors.getIsChartSaving(state.charts, chartId);

  const broadFavoritesAfterReset = resetBroadOrExactFavorites(state.resetFavorites, broadMatch, chartId);
  const exactFavoritesAfterReset = resetBroadOrExactFavorites(state.resetFavorites, exactMatch, chartId);
  const patient = getActivePatient(state.patients);
  const isFetching = !!activeSystem
    && (chartingAssetsSelectors.getStatus(state.chartingAssets).isFetching
    || chartingAssetsSelectors.getStatus(state.chartingAssets, chartId).isFetching);

  return {
    system,
    chartId,
    elements,
    isROS: system.chartingId === 3,
    allElements,
    searchableItems,
    isFetching,
    isChartSaving,
    assignedDiagnoses,
    // we need patient name to transform <<Patient>> to patient name
    patient,
    listOfAutoOpenSubelementsIndexes: state.autoOpenSubElementsModal,
    broadMatch: broadFavoritesAfterReset,
    exactMatch: exactFavoritesAfterReset,
    rvElementIds: getElementIdsByStep(state.chartingSessions, chartId)
  };
};

const mapActionCreators = {
  ...chartingSessionsActions,
  fetchResources: fetchResourcesNoCaching,
  createElement,
  newMedication,
  updateElement: updateElementWrapper,
  addIndexToAutoSet,
  resetIndexToAutoSet,
  removeElement: chartingSessionsActions.removeElementWithCallback,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
