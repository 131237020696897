import { connect } from 'react-redux';

import AbstractPropsFactory from 'helpers/fmss/additional-checkbox/factory/props/AbstractPropsFactory';
import AbstractToggleCheckboxFactory from 'helpers/fmss/additional-checkbox/factory/actions/AbstractToggleCheckboxFactory';

export default (Component, hxType) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      patientId,
    } = ownProps;

    const FactoryToFetchProps = AbstractPropsFactory.getHxFactory(hxType);
    const objectFactory = new FactoryToFetchProps({
      patientId,
      state,
    });

    return {
      ...objectFactory.getData(),
    };
  };

  const mapActionCreators = () => {
    const toggleCheckboxAction = AbstractToggleCheckboxFactory.getHxFactory(hxType);

    return {
      toggleCheckbox: toggleCheckboxAction,
    };
  };

  const actions = mapActionCreators();

  return connect(mapStateToProps, actions)(Component);
};
