import React, { useState } from 'react';
import { func } from 'prop-types';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import UserOption from './UserOption';

import { findClinics, findUsers } from 'api/administrator';
import { isNullOrUndefined } from 'helpers';

const SearchUser = ({ onSelect }) => {
  const [rawSelectedUser, setRawSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const [clinics, setClinics] = useState();

  const setSelectedClinicWrapper = selectedOption => {
    setSelectedClinic(selectedOption);

    onSelect({ internalSelectedUser: rawSelectedUser, internalSelectedClinic: selectedOption });
  };

  const userOptionsMapper = usersForSelect =>
    usersForSelect.map(user => ({ value: user.id, label: user.login, firstName: user.firstName, lastName: user.lastName }));

  const loadOptions = async (template) => {
    const { body } = await findUsers(template);
    setUsers(body);

    return userOptionsMapper(body);
  };

  const clinicOptionsMapper = clinicsForSelect =>
    clinicsForSelect.map(clinic => ({ value: clinic.id, label: clinic.legalname, suspend: clinic.suspend, verifyEmail: clinic.verifyemail }));

  const setSelectedUserWrapper = async (selectedOption) => {
    const { value } = selectedOption;
    const { body } = await findClinics(value);

    setClinics(clinicOptionsMapper(body));
    setSelectedUser(selectedOption);
    const rawSelectedUsersToSet = users.filter(iteratedUser => iteratedUser.id === value);
    setRawSelectedUser(rawSelectedUsersToSet[0]);
  };

  return (
    <>
      <AsyncSelect
        id="users"
        components={{ Option: UserOption }}
        loadOptions={loadOptions}
        value={selectedUser}
        placeholder="Choose user"
        onChange={setSelectedUserWrapper}
      />
      {!isNullOrUndefined(selectedUser) && (
        <Select
          id="clinics"
          value={selectedClinic}
          placeholder="Choose clinic"
          onChange={setSelectedClinicWrapper}
          options={clinics}
        />
      )}
    </>
  );
};

SearchUser.propTypes = {
  onSelect: func.isRequired
};

export default SearchUser;
