import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Tether from 'react-tether';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import EventListenerLayer from 'components/EventListenerLayer';
import { DATE_FORMATS } from 'helpers/date/constants';

import cx from './ReportLine.module.scss';

import ShowMoreIcon from './show-more-icon.png';


const ReportLine = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldShowFullMessage, setShouldShowFullMessage] = useState(false);
  const fullWidthNode = useRef();
  const shortenedNode = useRef();

  const PADDING = 25;

  useEffect(() => {
    const handleWindowResize = () => setShouldShowFullMessage(
      fullWidthNode.current?.offsetWidth > shortenedNode.current?.offsetWidth - PADDING
    );

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  const toggleRv = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const {
    log,
    fields,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const formattedActionDate = timeZoneDateConverter?.getFormattedDateWithTimeZone(log.actionTime, DATE_FORMATS.DEFAULT_WITH_TIME);

  return (
    <tr className={cx.wrapper}>
      <td style={{ width: fields[0].width }}>{log.actionType}</td>
      <td style={{ width: fields[1].width }}>{log.action}</td>
      <td
        style={{ width: fields[2].width }}
      >
         <Tether
          attachment="bottom right"
          targetAttachment="top left"
          constraints={[{
            to: 'scrollParent',
            attachment: 'together',
          }]}
          classPrefix="log-message"
          renderTarget={targetRef => (
            <span ref={targetRef}>
              <span
                className={cx['short-message']}
                onClick={shouldShowFullMessage ? toggleRv : undefined}
                ref={shortenedNode}
              >
                <span
                  ref={fullWidthNode}
                >
                  {log.message}
                </span>
                {shouldShowFullMessage && (
                  <img
                    alt="More"
                    src={ShowMoreIcon}
                    className={cx['show-more-icon']}
                  />
                )}
              </span>
            </span>
          )}
          renderElement={elementRef => log.message && isOpen && shouldShowFullMessage && (
            <EventListenerLayer onClickOutSide={close}>
              <span ref={elementRef}
                  className={cx['full-message']}
              >
                {log.message}
              </span>
            </EventListenerLayer>
          )}
         />

      </td>
      <td style={{ width: fields[3].width }}>{log.patient && log.patient.name}</td>
      <td style={{ width: fields[4].width }}>{log.user && log.user.name}</td>
      <td style={{ width: fields[5].width }}>{formattedActionDate}</td>
    </tr>
  );
};

ReportLine.propTypes = {
  log: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
};

export default ReportLine;
