const request = require('superagent-promise')(require('superagent'), Promise);

export const create = data =>
  request
    .put('/api/rest/patient/consent/')
    .send(data)
    .end();

export const getAll = patientId =>
  request
    .get(`/api/rest/patient/consent/${patientId}`)
    .end();

export const deleteConsentQuery = consentId =>
  request
    .del(`/api/rest/patient/consent/${consentId}`)
    .end();