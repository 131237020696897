import { handleActions } from 'redux-actions';

import { PATIENT_SOCIAL_FETCH_SUCCESS } from 'modules/socialSessions/constants';

import { createDetails, updateDetails, deleteDetails } from 'api/social';
import deleteObjectProperty from 'helpers/common/object/delete-property';

export const FREE_TEXT_CREATE = 'FREE_TEXT_CREATE';
export const FREE_TEXT_CREATE_SUCCESS = 'FREE_TEXT_CREATE_SUCCESS';
export const FREE_TEXT_CREATE_FAIL = 'FREE_TEXT_CREATE_FAIL';

export const FREE_TEXT_UPDATE = 'FREE_TEXT_UPDATE';
export const FREE_TEXT_UPDATE_SUCCESS = 'FREE_TEXT_UPDATE_SUCCESS';
export const FREE_TEXT_UPDATE_FAIL = 'FREE_TEXT_UPDATE_FAIL';

export const FREE_TEXT_DELETE = 'FREE_TEXT_DELETE';
export const FREE_TEXT_DELETE_SUCCESS = 'FREE_TEXT_DELETE_SUCCESS';
export const FREE_TEXT_DELETE_FAIL = 'FREE_TEXT_DELETE_FAIL';

export const createFreeText = data => ({
  types: [
    FREE_TEXT_CREATE,
    FREE_TEXT_CREATE_SUCCESS,
    FREE_TEXT_CREATE_FAIL
  ],
  promise: () => createDetails(data),
  data
})

export const updateFreeText = data => ({
  types: [
    FREE_TEXT_UPDATE,
    FREE_TEXT_UPDATE_SUCCESS,
    FREE_TEXT_UPDATE_FAIL
  ],
  promise: () => updateDetails(data),
  data
})

export const deleteFreeText = data => ({
  types: [
    FREE_TEXT_DELETE,
    FREE_TEXT_DELETE_SUCCESS,
    FREE_TEXT_DELETE_FAIL
  ],
  promise: () => deleteDetails(data),
  data
})

export default handleActions({
  [PATIENT_SOCIAL_FETCH_SUCCESS]: (state, action) => {
    const {
      result,
    } = action.payload;

    const notesObj = {};

    if (result.social === null) {
      return state
    }

    result.social
      .filter(system => system?.details != null)
      .forEach(({ details: { id: { patientId, socialId }, freeText } }) => {
        const freeTextId = `p${patientId}s${socialId}`

        notesObj[freeTextId] = {
          freeText,
          id: {
            patientId,
            socialId
          }
        }
      })

    return {
      ...state,
      ...notesObj
    }
  },
  [FREE_TEXT_CREATE_SUCCESS]: (state, action) => {
    const {
      data: {patientId, socialId, freeText}
    } = action.payload

    const freeTextId = `p${patientId}s${socialId}`

    return {
      ...state,
    [freeTextId]: {
      id: {
        patientId,
        socialId
      },
      freeText
    }}

  },
  [FREE_TEXT_UPDATE_SUCCESS]: (state, action) => {
    const {
      data: {patientId, socialId, freeText}
    } = action.payload
    const newState = {...state};

    const freeTextId = `p${patientId}s${socialId}`
    newState[freeTextId] = {
      id: {
        ...newState[freeTextId].id
      },
      freeText
    }

    return {
      ...newState
    }
  },
  [FREE_TEXT_DELETE_SUCCESS]: (state, action) => {
    const {
      data
    } = action.payload

    const newState = {...state}
    const freeTextId = `p${data['patient_id']}s${data['social_id']}`

    delete newState[freeTextId]

    return newState
  }
}, {})
