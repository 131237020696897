import React from 'react';
import PropTypes from 'prop-types';

import ClinicsList from './ClinicsList';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './ClinicsPanel.module.scss';

const visibleClass = cx['clinic-list--visible'];

const styles = resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['clinic-list']
});

const ClinicsPanel = props => {
  const {
    isVisible,
    clinics,
    onClinicSelect,
    areClinicsFetching,
    hideClinicList
  } = props;

  if (isVisible) {
    return (
      <div className={`${styles} ${isVisible ? visibleClass : ''}`}>
        <ClinicsList
          hideClinicList={hideClinicList}
          areClinicsFetching={areClinicsFetching}
          clinics={clinics}
          onClinicSelect={onClinicSelect}
        />
      </div>
    );
  }

  return <></>;
};

ClinicsPanel.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default ClinicsPanel;
