import React, { useCallback, useContext, useState } from 'react';
import Select from 'react-select';

import { usePatientConsents } from './usePatientConsents';
import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { Action } from './Action';

import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import { isMobileApp } from 'helpers';
import printjs from 'vendor/print';
import { SingleValue } from 'components/Consents/SingleValue';
import { DeleteModal } from 'components/DeleteModal';

const actions = [
  {
    label: 'Save as PDF',
    value: 'pdf',
  },
  {
    label: 'Print',
    value: 'print'
  },
  {
    label: 'Delete',
    value: 'delete'
  },
];

const selectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 240,
  heightControl: 28,
  fontSize: 12,
});

export const PatientConsentItem = ({ consentId }) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { timeZoneDateConverter } = useContext(AppContext);
  const { getConsentById, deleteConsent } = usePatientConsents();
  const consent = getConsentById(consentId);
  const deleteConsentWrapper = useCallback(() => {
    deleteConsent(consentId);
  }, [consentId]);
  if (!consent) {
    return <></>;
  }
  const dateWithTimeZone = timeZoneDateConverter.getFormattedDateWithTimeZone(consent?.date);

  const onChange = option => {
    if (option.value === 'delete') {
      setIsOpenDeleteModal(true);
    }
  };

  const buildAction = selectProps => {
    const pdfUrl = `/api/rest/patient/consent/show/${consentId}`;
    return (
      <Action
        {...selectProps}
        componentsMap={{
          'pdf': {
            render: () => {
              return (
                <a
                  href={pdfUrl}
                  className='no-link'
                  target={isMobileApp() ? '_self' : '_blanc'}
                >
                  Save as PDF
                </a>
              );
            }
          },
          'print': {
            render: () => {
              if (!isMobileApp()) {
                return (
                  <button
                    type="button"
                    className='no-button'
                    onClick={() => printjs(pdfUrl)}
                  >
                    Print
                  </button>
                );
              }
            }
          },
          'delete': {
            render: () => {
              return (
                'Delete'
              );
            }
          }
        }}
      />
    );
  };

  const buildSingleValue = innerProps => {
    return (
      <SingleValue
        {...innerProps}
        placeholder='Select' />
    );
  };

  return (
    <tr>
      <td>{consent.name}</td>
      <td>{consent.provider}</td>
      <td>{dateWithTimeZone}</td>
      <td>
        <Select
          onChange={onChange}
          options={actions}
          menuPortalTarget={document.body}
          styles={selectStyles}
          components={{ Option: buildAction, SingleValue: buildSingleValue }}
        />
        {isOpenDeleteModal && (
          <DeleteModal
            onCloseModal={() => setIsOpenDeleteModal(false)}
            onConfirm={deleteConsentWrapper}
          />
        )}
      </td>
    </tr>
  );
};