import React from 'react';

import MailToLinkIosApp from 'components/MailToLink';

import styles from 'routes/components/AuthForm/components/AuthFormSidebar/AuthFormSidebar.module.scss';
import loginStyles from 'styles/AuthFormAssets.module.scss';
import cx from './Contacts.module.scss';

const phoneImage = require('images/Phone.svg');
const emailImage = require('images/Email.svg');

const Contacts = () => {
  return (
    <div className={cx['contacts--background']}>
      <div className={styles['wrapper--padding']}>
        <div className={cx.contacts}>
          <div className={cx['contacts__header-label']}>
            Let us show you the EHRsynergy difference
          </div>
          <div className={cx.contacts__containers}>
            <div className={[cx['contacts__containers-item']]}>
              <img src={phoneImage} alt="" />
              <span
                className={loginStyles.label}
              >
                833-347-3627
              </span>
            </div>
            <div className={`${cx['contacts__containers-item']} ${cx['contacts__mail-wrapper']}`}>
              <MailToLinkIosApp
                className={cx['link-with-children']}
                linkTo="info@EHRsynergy.com"
              >
                <img src={emailImage} alt="" />
              </MailToLinkIosApp>
              <MailToLinkIosApp
                className={cx.label}
                linkTo="info@EHRsynergy.com"
              >
                info@EHRsynergy.com
              </MailToLinkIosApp>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
