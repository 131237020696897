export default class UpdateSubElementsAfterUpdatingOne {
  static update = (oldSubElements = [], subElementToUpdate = {}, idNewSubElement) => {
    const {
      id: oldSubElementId,
    } = subElementToUpdate;

    const indexOldSubElement = oldSubElements.findIndex(oldSubElement => oldSubElement.id === subElementToUpdate.id);
    if (indexOldSubElement === -1) {
      return oldSubElements;
    }

    const newSubElements = [...oldSubElements];
    newSubElements[indexOldSubElement] = {
      ...newSubElements[indexOldSubElement],
      oldSubElementId,
      ...subElementToUpdate,
      id: idNewSubElement,
      isSaving: false,
    };

    return newSubElements;
  };
}
