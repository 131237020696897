import React from 'react';
import { node, func } from 'prop-types';

import { components } from 'react-select';

import cx from './Sig.module.scss';

export const CustomOption = ({ children, onRemove, ...props }) => {
  const { data: { value, __isNew__ } } = props;

  const onRemoveWrapper = (e) => {
    e.preventDefault();
    onRemove(value);
  };

  return (
    <>
      <components.Option {...props} >
        <div>
          {children}
        </div>
      </components.Option>
      {!__isNew__ && (
        <button
          className={cx.remove}
          onClick={onRemoveWrapper}
          type="button"
        >
          X
        </button>
      )}
    </>
  );
};

CustomOption.propTypes = {
  children: node.isRequired,
  onRemove: func.isRequired,
};
