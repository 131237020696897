import React from 'react';
import AddButton from 'components/AddButton';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import cx from './Title.module.scss';

const titleStyles = ({visibleInReport}) => ({
  toggle: cssClassesResolver([
    cx['toggle-button'], !visibleInReport && 'toggle-button--disabled'
  ])
})

const Title = ({
  title,
  onClick,
  toggleHistory,
  interactive,
  visibleInReport,
}) => (
  <div className={cx.content}>
    <h3 className={cx.wrapper}>
      <span className={cx.title}>{title}</span>
    </h3>
    {' '}
    {interactive && (
      <div className={titleStyles({visibleInReport}).toggle} onClick={toggleHistory}>
        <svg viewBox="0 0 50 50" preserveAspectRatio="none">
          <path className={cx['check-icon']} d="M47.869,15.876c-11.742,7.199-20.261,16.282-24.089,20.839l-9.375-7.345l-4.14,3.337l16.199,16.475c2.788-7.141,11.618-21.098,22.399-31.014L47.869,15.876z" />}
        </svg>
      </div>
    )}
    {onClick && <AddButton onClick={onClick} />}
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  visibleInReport: PropTypes.bool,
  interactive: PropTypes.bool,
  toggleHistory: PropTypes.func,
};

export default Title;
