import React, { Component } from 'react';

import BillingInfoModalContainer from '../BillingInfoModalContainer';

import Loader from 'components/Loader';
import RenderCard from 'components/RenderCard';

import { withRouter } from 'containers/RouterParams';

import cx from './PaymentsPlansCard.module.scss';

class PaymentsPlans extends Component {
  state = {
    showSaveModal: false,
  };

  componentDidMount = () => {
    if (this.props.location.query.subscribe === '1') {
      this.setState({ showSaveModal: true });
    }
  };

  closeModal = () => {
    this.props.router.replace(this.props.location.pathname);
    this.setState({ showSaveModal: false });
  };

  isDisplayAllPlans = (isShowAllPlans, isVip) => isShowAllPlans || (!isShowAllPlans && !isVip);

  render() {
    const {
      paymentsPlans, financialInfo, isShowAllPlans, hidePlansModal,
    } = this.props;

    if (!paymentsPlans.isFetched || paymentsPlans.isFetching) {
      return <div className={cx['loader-wrapper']}><Loader /></div>;
    }

    return (
      <div className={cx['tab-content']}>
        {financialInfo.vip && !isShowAllPlans && (
          <RenderCard
            card={{
              name: 'VIP Plan',
              image: import('./PlanVIP.svg'),
              description: 'VIP Plan',
            }}
          />
        )}
        {this.isDisplayAllPlans(isShowAllPlans, financialInfo.vip) && paymentsPlans.listPlans.map((value, index) =>
          (<RenderCard
            hidePlansModal={hidePlansModal}
            card={value}
            key={index}
          />)
        )}
        {this.state.showSaveModal && (
          <BillingInfoModalContainer
            closeModal={this.closeModal}
            location={this.props.location.query.subscribe === '1' && 'buy'}
          />
        )}
      </div>
    );
  }
}

export default withRouter(PaymentsPlans);
