import * as fromByFilter from './byFilter/selectors';

export const getIsFetchingList = (state, patientId, filter) => {
  return fromByFilter.getIsFetchingList(state[patientId], filter);
};

export const getItems = (state, patientId, filter) => {
  return fromByFilter.getItems(state[patientId], filter);
};

export const getIsInitializedList = (state, patientId, filter) => fromByFilter.getIsInitializedList(state[patientId], filter);

export const getListError = (state, patientId, filter) => fromByFilter.getListError(state[patientId], filter);

export const getActiveAndInactiveItems = (state, patientId) => ([
  ...getItems(state, patientId, 'active'),
  ...getItems(state, patientId, 'inactive'),
]);
