import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import RolesContainer, { fetchLayerHOC } from 'containers/RolesContainer';
import { withRouter } from 'containers/RouterParams';

import Loader from 'components/Loader';
import UserEditForm from 'components/UserEditForm';
import RoleForm from '../RoleForm';
import RolesTable from '../RolesTable';

const RoleFormComponent = RolesContainer(RoleForm);

class RolesList extends React.Component {
  static propTypes = {
    removeRole: PropTypes.func.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isFetching: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.shape({
        roleId: PropTypes.string,
      }),
    }),
    isNeedHideErrors: PropTypes.bool.isRequired,
    setIsNeedHideErrors: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFetching: false,
    location: {
      query: {
        roleId: null,
      },
    },
  };

  navigateToRole = (roleId) => {
    this.props.router.push({
      pathname: this.props.location.pathname,
      query: {
        roleId,
      },
    });
  };

  navigateToRolesTab = () => {
    this.props.router.push({
      pathname: this.props.location.pathname,
      query: {},
    });
  };

  renderList() {
    const {
      location,
      removeRole,
      setIsNeedHideErrors,
    } = this.props;
    const { navigateToRole, navigateToRolesTab } = this;
    const selectedRole = parseInt(location.query.roleId);

    const Table = props => (
      <RolesTable
        {...props}
        selectedRole={selectedRole}
        navigateToRole={navigateToRole}
        removeRole={removeRole}
        navigateToRolesTab={navigateToRolesTab}
        setIsNeedHideErrors={setIsNeedHideErrors}
      />
    );

    const Component = RolesContainer(Table);

    return <Component />;
  }

  renderForm() {
    const { roleId } = this.props.location.query;

    return (
      <RoleFormComponent
        roleId={roleId && parseInt(roleId)}
        navigateToRolesTab={this.navigateToRolesTab}
        isNeedHideErrors={this.props.isNeedHideErrors}
      />
    );
  }

  render() {
    const { isFetching } = this.props;

    if (isFetching) return <Loader />;

    return (
      <UserEditForm.Wrapper>
        <UserEditForm.PlanCard>
          {this.renderList()}
        </UserEditForm.PlanCard>
        <UserEditForm.PlanDescription>
          {this.renderForm()}
        </UserEditForm.PlanDescription>
      </UserEditForm.Wrapper>
    );
  }
}

export const RolesListContainer = compose(
  RolesContainer,
  fetchLayerHOC,
  withRouter
)(RolesList);
