import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';

import ImportModal from 'components/DrChronoService/ImportModal';

import DrChronoService from 'components/DrChronoService';
import DrChronoContainer, { fetchLayerHOC } from 'containers/DrChrono/DrChronoContainer';
import ImportModalContainer from 'containers/DrChrono/ImportModalContainer';

import cx from './DrChronoTab.module.scss';

const DrChronoServiceComponent = compose(
  withRouter,
  DrChronoContainer,
  fetchLayerHOC
)(DrChronoService);

const ImportModalWrapper = compose(
  ImportModalContainer,
)(ImportModal);

export const DrChronoTab = () => (
  <div className={cx['tab-content']}>
    <DrChronoServiceComponent />
    <ImportModalWrapper />
  </div>
);
