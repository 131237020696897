const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchChartsByPage = (page, isSigned, sortParam, isAsc, providerId) => {
  const url =
    `/api/rest/encounter/pagination?${ 
    [
      `isSigned=${isSigned}`,
      `page=${page}`,
      'limit=20',
      `param=${sortParam}`,
      `isAsc=${isAsc}`,
      `providerId=${providerId}`,
    ].join('&')}`;

  return request.get(url).end();
};

export const fetchLastChartsWithSorting = (sortParam, isAsc, providerId, isSigned = true) =>
  request
    .get('/api/rest/encounter/last')
    .query({ param: sortParam, isAsc, providerId, isSigned })
    .end();

export const fetchPatientCharts = patientId =>
  request.get(`/api/rest/encounter/patient/${patientId}`)
    .end();

export const fetchPatientChartsByPage = (patientId, page, sortParam, isAsc, providerId) => {
  const url =
    `/api/rest/encounter/patient/${patientId}/pagination?${ 
    [
      `page=${page}`,
      'limit=20',
      `param=${sortParam}`,
      `isAsc=${isAsc}`,
      `providerId=${providerId}`,
    ].join('&')}`;

  return request.get(url).end();
};

export const fetchChart = chartId =>
  request.get(`/api/rest/chartings/${chartId}`)
    .end();

export const createChart = chart =>
  request.post('/api/rest/chartings/create')
    .send(chart)
    .end();

// chart update extra data (showFMSS, showMedAllergy, comment)
export const updateChartExtraData = data =>
  request.post('/api/rest/chartings/update/extra')
    .send(data)
    .end();

export const fetchVitals = patientId =>
  request.get(`/api/rest/vitals/patient/${patientId}`)
    .end();

export const saveVitals = vitals =>
  request.post('/api/rest/vitals/update')
    .send(vitals)
    .end();

// sign/unsign chart
export const changeSignedStatus = data =>
  request.post('/api/rest/chartings/sign')
    .send(data)
    .end();

// copy previous note
export const copyPreviousNote = (chartId, copyFrom) =>
  request.post('/api/rest/chartings/copy')
    .send({ id: chartId, copyFrom })
    .end();

export const deleteChart = id =>
  request.post('/api/rest/chartings/delete')
    .send({ id })
    .end();

export const fetchCptCodes = () =>
  request
    .get('/api/rest/cpt/')
    .query({ page: 0, limit: 50 })
    .end();

export const fetchHcpcCodes = () =>
  request
    .get('/api/rest/hcpcbilling/')
    .query({ page: 0, limit: 50 })
    .end();

export const searchCptCodes = description =>
  request
    .post('/api/rest/cpt/search')
    .query({ page: 0, limit: 50 })
    .send({ description })
    .end();

export const searchHcpcCodes = search =>
  request
    .post('/api/rest/hcpcbilling/search')
    .query({ page: 0, limit: 50 })
    .send({ search })
    .end();

export const updateChartCpt = data =>
  request
    .post('/api/rest/cpt/update/chart')
    .send(data)
    .end();

export const setChartProvider = (providerId, chartId) =>
  request.post('/api/rest/chartings/set/provider')
    .send({ id: chartId, providerId })
    .end();
