import * as fromIsFetching from './isFetching/selectors';
import * as fromIsCreating from './isCreating/selectors';
import * as fromById from './byId/selectors';
import * as fromByPatient from './byPatient/selectors';
import * as fromDashboardError from './dashboardError';
import * as fromSignedIdsByPages from './signedIdsByPages/selectors';
import * as fromUnsignedIdsByPages from './unsignedIdsByPages/selectors';
import * as fromFavoritesFetchedByStep from './byId/favoritesFetchedByStep/selectors';
import * as fromCopyPreviousNotesCharts from './copyPreviousNotes/selectors';

const filterNotDeletedCharts = chart =>
  !chart.deleted && chart.createdDate;

export const getIsFetching = state =>
  fromIsFetching.getIsFetching(state);

export const getIsCreating = state =>
  fromIsCreating.getIsCreating(state);

export const getAllCharts = state =>
  fromById.getChartsById(state);

const getSignedChartIdsByPage = state =>
  fromSignedIdsByPages.getChartsByPage(state.signedIdsByPages);

const getUnsignedChartIdsByPage = (state, page) =>
  fromUnsignedIdsByPages.getChartsByPage(state.unsignedIdsByPages, page);

export const getSignedChartsByPage = (state) => {
  const charts = getAllCharts(state);
  const chartIdsByPage = getSignedChartIdsByPage(state);

  return (chartIdsByPage || [])
    .map(id => charts[id])
    .filter(filterNotDeletedCharts);
};

export const getUnsignedChartsByPage = (state, page) => {
  const charts = getAllCharts(state);
  const chartIdsByPage = getUnsignedChartIdsByPage(state, page);

  return (chartIdsByPage || [])
    .map(id => charts[id])
    .filter(filterNotDeletedCharts);
};

export const getSignedChartsPageCount = state =>
  fromSignedIdsByPages.getPagesCount(state.signedIdsByPages);

export const getUnsignedChartsPageCount = state =>
  fromUnsignedIdsByPages.getPagesCount(state.unsignedIdsByPages);

export const getSignedChartsIsFetching = state =>
  fromSignedIdsByPages.getIsFetching(state.signedIdsByPages);

export const getUnsignedChartsIsFetching = state =>
  fromUnsignedIdsByPages.getIsFetching(state.unsignedIdsByPages);

export const getChartById = (state, chartId) =>
  fromById.getChartById(state.byId, chartId);

export const getCopyPreviousNotesCharts = state =>
  fromCopyPreviousNotesCharts.getCopyPreviousNotesCharts(state.copyPreviousNotes);

export const getChartsByPatientByPage = (state, patientId, page) =>
  fromByPatient
    .getChartsByPatientByPage(state.byPatient, patientId, page)
    .map(chartId => getChartById(state, chartId))
    .filter(filterNotDeletedCharts);

export const getChartsbyPatientPagesCount = (state, patientId) =>
  fromByPatient.getChartsByPatientPagesCount(state.byPatient, patientId);

export const getIsFetchingForPatient = (state, patientId) =>
  fromByPatient.getIsFetching(state.byPatient, patientId);

export const isVitalsFetching = (state, chartId) =>
  fromById.isVitalsFetching(state.byId, chartId);

export const getVitals = (state, patientId) =>
  fromByPatient
    .getChartsByPatient(state.byPatient, patientId)
    .filter(chartId => !fromById.getChartById(state.byId, chartId).deleted)
    .map(chartId => fromById.getVitals(state.byId, chartId));

export const getIsCopyingPreviousNote = (state, chartId) =>
  fromById.getIsCopyingPreviousNote(state.byId, chartId);

export const getDashboardError = state =>
  fromDashboardError.getDashboardError(state.dashboardError);

export const getIsChartSaving = (state, chartId) =>
  fromById.getIsChartSaving(state.byId, chartId);

export const getChartSignDate = (state, chartId) =>
  fromById.getChartSignDate(state.byId, chartId);

export const getChartFavoritesFetchedByStep = (state, chartId) => {
  const chart = getChartById(state, chartId);

  return fromFavoritesFetchedByStep.getFavoritesFetchedByStep(chart);
};

export const getChartDetails = (state, chartId) =>
  fromById.getChartDetails(state.byId, chartId);
