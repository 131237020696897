import * as constants from './constants';
import FetchCardResponseProducer from 'response/card/fetch/handle-action/producer';

import { savePaymentInfo as savePaymentInfoAPI, fetchPaymentInfo as fetchPaymentInfoAPI } from 'api/financial';
import { getAllSeatCoast } from 'api/seats';
import {paymentAllSeat} from 'modules/seats/actions';

export const savePaymentInfo = creditCard => ({
  types: [
    constants.PAYMENT_INFO_SAVE,
    constants.PAYMENT_INFO_SAVE_SUCCESS,
    constants.PAYMENT_INFO_SAVE_FAIL,
  ],
  promise: () => savePaymentInfoAPI(creditCard),
});

export const fetchPaymentInfo = () => ({
  types: [
    constants.PAYMENT_INFO_FETCH,
    constants.PAYMENT_INFO_FETCH_SUCCESS,
    constants.PAYMENT_INFO_FETCH_FAIL,
  ],
  promise: async () => {
    const res = await fetchPaymentInfoAPI();

    return FetchCardResponseProducer.getCard(res);
  },
});

export const doPayment = (seatIds) => ({
  types: [
    constants.DO_PAYMENT,
    constants.DO_PAYMENT_SUCCESS,
    constants.DO_PAYMENT_FAIL
  ],
  promise: async (dispatch) => {
    const { body } = await getAllSeatCoast(seatIds)

    dispatch(paymentAllSeat(seatIds, body))
  }
})
