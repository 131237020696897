export default [
  {
    title: 'family',
    subtitle: 'Person',
    sortable: true,
  },
  {
    title: 'disease',
    subtitle: 'Name',
    sortable: true,
  },
  {
    title: 'comment',
    subtitle: '',
    sortable: true,
  },
];
