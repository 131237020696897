import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';

const statusPropType = PropTypes.shape({
  isFetching: PropTypes.bool.isRequired,
  isFetched: PropTypes.bool.isRequired,
}).isRequired;

export default class ChartReportFetchLayer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    ready: PropTypes.bool.isRequired,
    patientId: PropTypes.number,
    chartId: PropTypes.number,

    assetsStatus: statusPropType,
    fetchChartingAssets: PropTypes.func.isRequired,

    medicationsStatus: statusPropType,
    fetchMedications: PropTypes.func.isRequired,

    diagnosesStatus: statusPropType,
    fetchDiagnoses: PropTypes.func.isRequired,

    allergiesStatus: statusPropType,
    fetchAllergies: PropTypes.func.isRequired,

    familyStatus: statusPropType,
    fetchFamily: PropTypes.func.isRequired,

    medicalStatus: statusPropType,
    fetchMedical: PropTypes.func.isRequired,

    surgicalStatus: statusPropType,
    fetchSurgical: PropTypes.func.isRequired,

    fetchScreening: PropTypes.func.isRequired,

    vitalsStatus: statusPropType,
    fetchVitals: PropTypes.func.isRequired,

    socialStatus: statusPropType,
    fetchPatientSocial: PropTypes.func.isRequired,

    socialAssetsStatus: statusPropType,
    fetchSystems: PropTypes.func.isRequired,

    clinicStatus: statusPropType,
    fetchClinicInfo: PropTypes.func.isRequired,

    systemNotesStatus: statusPropType,
    fetchChartSystemNotes: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.statusToFunctionHash = {
      assetsStatus: this.fetchChartingAssets,
      medicationsStatus: this.fetchMedications,
      diagnosesStatus: this.fetchDiagnoses,
      allergiesStatus: this.fetchAllergies,
      familyStatus: this.fetchFamily,
      medicalStatus: this.fetchMedical,
      surgicalStatus: this.fetchSurgical,
      screeningStatus: this.fetchScreening,
      vitalsStatus: this.fetchVitals,
      socialStatus: this.fetchSocial,
      socialAssetsStatus: this.fetchSocialSystems,
      clinicStatus: this.fetchClinicInfo,
      systemNotesStatus: this.fetchChartSystemNotes,
    };
  }

  componentDidMount() {
    this.fetchReport();
  }

  fetchSection = (status, fetchFunction) => {
    if (status.isFetched || status.isFetching) return;
    fetchFunction();
  }

  fetchReport = () => {
    Object.keys(this.statusToFunctionHash).forEach((statusPropName) => {
      this.fetchSection(this.props[statusPropName], this.statusToFunctionHash[statusPropName]);
    });
  }

  fetchChartingAssets = this.props.fetchChartingAssets;
  fetchMedications = () => this.props.fetchMedications(this.props.patientId, 'active');
  fetchDiagnoses = () => this.props.fetchDiagnoses(this.props.patientId, 'active');
  fetchAllergies = () => this.props.fetchAllergies(this.props.patientId, 'active');
  fetchFamily = () => this.props.fetchFamily(this.props.patientId);
  fetchMedical = () => this.props.fetchMedical(this.props.patientId);
  fetchSurgical = () => this.props.fetchSurgical(this.props.patientId);
  fetchScreening = () => this.props.fetchScreening(this.props.patientId);
  fetchVitals = () => this.props.fetchVitals(this.props.patientId, this.props.chartId);
  fetchSocial = () => this.props.fetchPatientSocial(this.props.patientId);
  fetchSocialSystems = this.props.fetchSystems;
  fetchClinicInfo = this.props.fetchClinicInfo;
  fetchChartSystemNotes = () => this.props.fetchChartSystemNotes(this.props.patientId, this.props.chartId);

  render() {
    const { children, ready } = this.props;

    if (!ready) return <Loader />;

    return children;
  }
}
