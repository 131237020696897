import { handleActions } from 'redux-actions';
import * as constants from '../../constants';
import { COPY_PREVIOUS_NOTE } from 'modules/charts/constants';
import keyBy from 'lodash/keyBy';

const initialState = {};

const handlers = {
  [constants.SYSTEM_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.id]: action.payload.result,
  }),
  [constants.SYSTEM_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.id]: action.payload.result,
  }),
  [constants.SYSTEM_DELETE_SUCCESS]: (state, action) => {
    const nextState = { ...state };

    delete nextState[action.payload.systemId];

    return nextState;
  },
  [COPY_PREVIOUS_NOTE]: (state, action) => {
    // when copying previous note we should remove all current chart related systems
    const newElementsArray = Object.values(state).filter(system => system.chartId !== parseInt(action.payload.chartId));
    return keyBy(newElementsArray, 'id');
  },
};

export const handledActions = Object.keys(handlers);

export default handleActions(handlers, initialState);
