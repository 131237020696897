import React, { useState } from 'react';
import { func, number } from 'prop-types';

import CreatableSelect from 'react-select/creatable';

import { useMedicationSig } from 'components/NoteSystems/Systems/SigSeach/useMedicationSig';
import { CustomOption } from 'components/NoteSystems/Systems/SigSeach/CustomOption';

import { create, deleteSig } from 'api/medicationSig';

const SIG_INPUT_STYLES = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menuList: base => ({
    ...base,
    display: 'grid',
    gridTemplateColumns: '1fr auto'
  })
};

export const SigSearch = ({ changeSig, medMedicationId }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const { defaultOptions, setDefaultOptions } = useMedicationSig({ medMedicationId });

  const onChangeWrapper = async(item) => {
    if (item?.__isNew__) {
      const { body } = await create({ id: medMedicationId, payload: { description: item.label } });

      const createdItem = { ...item, id: body };
      setSelectedValue(createdItem);
      changeSig(createdItem);

      return;
    }
    setSelectedValue(item);
    changeSig(item);
  };

  const deleteSigWrapper = (value) => {
    const defaultOptionsFiltered = defaultOptions
      .filter(option => option.value !== value);

    if (defaultOptionsFiltered.length !== defaultOptions.length) {
      setDefaultOptions(defaultOptionsFiltered);

      deleteSig({ id: medMedicationId, template: value });
    }
  };

  const CustomOptionWrapper = (inner) =>
    <CustomOption {...inner} onRemove={deleteSigWrapper} />;

  return (
    <CreatableSelect
      components={{ Option: CustomOptionWrapper }}
      onChange={onChangeWrapper}
      value={selectedValue}
      menuPortalTarget={document.body}
      styles={SIG_INPUT_STYLES}
      menuPlacement='top'
      isClearable
      options={defaultOptions}
    />
  );
};

SigSearch.propTypes = {
  changeSig: func.isRequired,
  medMedicationId: number.isRequired,
};
