import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import verge from 'verge';

import PageFooter from 'routes/routes/App/components/PageFooter';
import EmbeddedLayout from 'components/EmbeddedLayout';

import cx from './Root.module.scss';
import 'normalize.css';
import 'styles/basic.scss';
import 'styles/react-select.css';

const isIpad = navigator.userAgent.match(/iPad/i) != null;

const footerLocations = [
  'login',
  'signup',
  'recovery',
];

const initialSize = {
  width: 0,
  height: 0,
};

const Root = () => {
  const { pathname } = useLocation();
  const [size, setSize] = useState(initialSize);

  useEffect(() => {
    removeLoader();

    if (isIpad) {
      window.addEventListener('resize', handleWindowResize);
      handleWindowResize();
    }

    return () => {
      if (isIpad) {
        window.removeEventListener('resize', handleWindowResize);
      }
    };
  }, []);

  const handleWindowResize = () => {
    setSize({
      width: verge.viewportW(),
      height: verge.viewportH(),
    });
  };

  const removeLoader = () => {
    let loader = document.getElementById('loader');
    if (loader) {
      document.body.removeChild(loader);
    }
    loader = null;
  };

  const style = isIpad ? { ...size, minHeight: size.height } : null;

  const footerVisible = !!footerLocations.filter(path => `/app/${path}` === pathname).length;

  return (
    <div className={cx.wrapper} style={style}>
      <Outlet />
      <EmbeddedLayout>
        <PageFooter visibility={footerVisible} />
      </EmbeddedLayout>
    </div>
  );
};

export default Root;
