import { lazy, Suspense } from 'react';

/**
 * @param {Promise} load Promise from import('...')
 */
export const LazyLoading = ({ load }) => {
  const Component = lazy(() => load);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component />
    </Suspense>
  );
};