import React from 'react';
import PropTypes from 'prop-types';

import * as elementsConstants from 'helpers/elements/constants';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './ElementsViewAddButton.module.scss';

const SpanComponent = props => <span className={props.className} onClick={props.onClick}>{props.children}</span>;

const ButtonComponent = props => (
  <button type="button" onClick={props.onClick} className={props.className}>
    {props.children}
  </button>
);

const components = {
  span: SpanComponent,
  button: ButtonComponent,
};

const styles = ({ disabled, isIconTypePlus, className }) => ({
  wrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['wrapper', disabled && 'wrapper--disabled', isIconTypePlus && 'wrapper--plus'],
    globalStyles: className
  })
});

class AddButton extends React.Component {
  static propTypes = {
    component: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconType: PropTypes.string,
    circleButtonText: PropTypes.string,
  }

  static defaultProps = {
    component: 'button',
    onClick: undefined,
    className: undefined,
    disabled: false,
    iconType: undefined,
    circleButtonText: elementsConstants.ELEMENTS_TYPES.add.text,
  }

  state = {
    blocked: false,
  }

  handleClick = () => {
    if (this.props.disabled) return;

    // we should not pass anything in onClick callback!
    this.props.onClick && this.props.onClick();

    this.timeout && clearTimeout(this.timeout);

    this.setState({ blocked: true });
    this.timeout = setTimeout(() => {
      this.setState({ blocked: false });
    }, 2000);
  };

  render() {
    const {
      component,
      className,
      disabled,
      iconType,
      circleButtonText,
    } = this.props;

    const { blocked } = this.state;

    const Component = components[component];

    const resolvedStyles = styles({ className, disabled, isIconTypePlus: iconType === 'plus' })

    return (
      <Component
        onClick={this.handleClick}
        className={resolvedStyles.wrapper}
      >
        {blocked && <div
          className={cx.overlay}
          onClick={(e) => {
            e.stopPropagation();
            return false;
          }}
        />}
        {!iconType && circleButtonText}
      </Component>
    );
  }
}

export default AddButton;
