import { combineReducers } from 'redux';

import paymentsPlans from './paymentsPlans';
import paymentInfo from './paymentInfo';
import financialTab from './financialTab';
import payments from './payments';

export default combineReducers({
  paymentsPlans,
  paymentInfo,
  financialTab,
  payments,
});
