import { Diagnoses } from './components/Diagnoses';
import { DiagnosesWrapper } from './components/DiagnosesList';

export const DiagnosesRoute = {
  path: 'diagnoses',
  element: <Diagnoses />,
  children: [
    {
      index: true,
      element: <DiagnosesWrapper />
    },
    {
      path: ':filter',
      element: <DiagnosesWrapper />
    }
  ]
};
