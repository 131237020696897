import { handleActions } from 'redux-actions';
import keyBy from 'lodash/keyBy';

import * as constants from '../constants';

const initialState = {};

export default handleActions({
  [constants.PROVIDERS_FETCH_SUCCESS]: (state, action) => ({
      ...keyBy(action.payload.result, 'id'),
  }),
}, initialState);
