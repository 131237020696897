import React, { Component } from 'react';

import ChartReportElementWithTooltip from '../../../../../ChartReportElementWithTooltip';
import RemoveButton from '../../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../../ChartReportElementWithTooltip/DoorButton';

const MENU_BUTTON_DIAMETER = 40;

class ChartReportMedicalItem extends Component {
  state = {
    isMenuOpen: false,
  }

  openMenu = () => this.setState({ isMenuOpen: true })

  closeMenu = () => this.setState({ isMenuOpen: false })

  render() {
    const { medicalItem, toggleChartReport, chartId, onRemove, viewMode } = this.props;

    const { isMenuOpen } = this.state;
    const { closeMenu, openMenu } = this;

    return (
      <ChartReportElementWithTooltip
        isOpen={isMenuOpen}
        onOpen={openMenu}
        onClose={closeMenu}
        disabled={viewMode}
      >
        <span dangerouslySetInnerHTML={{ __html: medicalItem.illness.name }} />
        <div className="flex-container">
          <DoorButton
            style={{
              width: MENU_BUTTON_DIAMETER,
              height: MENU_BUTTON_DIAMETER,
              marginRight: 10,
            }}
            link={{ pathname: `/app/doctor/charts/${chartId}/fmss/medical`, query: { medical: medicalItem.id } }}
            onClick={() => {
              closeMenu();
              toggleChartReport();
            }}
          />
          <RemoveButton
            onClick={onRemove}
            style={{
              width: MENU_BUTTON_DIAMETER,
              height: MENU_BUTTON_DIAMETER,
            }}
          />
        </div>

      </ChartReportElementWithTooltip>
    );
  }
}

export default ChartReportMedicalItem
;
