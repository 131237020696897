import * as constants from './constants';

export const setPastDueParams =
  (isShouldShowWarning, isReadOnly, isBeforeReadOnly, severity, daysToReadOnly, isOrangeIcon, isNeedToPay, didPaymentOccur) => ({
    type: constants.SET_PAST_DUE_PARAMS,
    payload: {
      isShouldShowWarning,
      isReadOnly,
      isBeforeReadOnly,
      severity,
      daysToReadOnly,
      isOrangeIcon,
      isNeedToPay,
      didPaymentOccur,
    },
  });

export const setDidPayment = didPaymentOccur => ({
  type: constants.SET_DID_PAYMENT_OCCUR,
  payload: { didPaymentOccur },
});

