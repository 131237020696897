import React from 'react';
import { compose } from 'redux';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';

import medicationsContainer, { fetchLayerHOC } from 'containers/Medications/MedicationsContainer';
import MedicationsPage from 'containers/Medications/MedicationsPage';

const MedicationsContainer = compose(
  medicationsContainer,
  fetchLayerHOC
)(MedicationsPage);

const MedicationsWrapper = () => {
  const [patientId] = useOutletContext();
  const [searchParams] = useSearchParams();
  return (
    <MedicationsContainer
      filter="active"
      patientId={patientId}
      medicationId={searchParams.get('medication')}
      linkBase={`/app/doctor/patients/${patientId}/medications`}
    >
      <Outlet/>
    </MedicationsContainer>
  );
};

export default MedicationsWrapper;
