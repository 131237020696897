import { connect } from 'react-redux';

import { doPayment, savePaymentInfo } from 'modules/financial/paymentInfo/actions';
import { setDidPayment } from 'modules/pastdue/actions';
import { getSeats } from 'modules/seats/selectors';

const mapStateToProps = (state) => {
  return {
    seats: Object.values(getSeats(state.seats)),
  };
};

const mapActionCreators = {
  savePaymentInfo,
  setDidPayment,
  doPayment,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
