import React from 'react';
import cx from 'components/PDFEditor/PDFViewer.module.scss';

/**
 * @param {number} currentPage
 * @param {number} totalPages
 * @returns {Element}
 * @constructor
 */
export const Navigation = ({ navigate, currentPage = 0, totalPages = 1 }) => {

  const prevClickWrapper = () => {
    if (currentPage !== 1) {
      navigate({ navigateTo, prev: true });
    }
  };

  const nextClickWrapper = () => {
    if (currentPage !== totalPages) {
      navigate({ navigateTo, prev: false });
    }
  };

  /**
   * @param {HTMLElement} element
   */
  const navigateTo = element => {
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav>
      <button
        className={cx['nav-button']}
        onClick={prevClickWrapper}
      >
        <svg width="22px" height="22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
          <path fill='white' d="M7.5,2l-4,4,4,4,1-1-3-3,3-3Z"/>
        </svg>
      </button>
      <span className={cx['page-num']}>{currentPage}/{totalPages}</span>
      <button
        className={cx['nav-button']}
        onClick={nextClickWrapper}
      >
        <svg width="22px" height="22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
          <path fill='white' d="M4.5,10l4-4-4-4-1,1,3,3-3,3Z"/>
        </svg>
      </button>
    </nav>
  );
};