export const whiteListProps = [
  'sessionTimeout',
  'practiceLogo',
  'practiceAddress',
  'practicePhone',
  'practiceFax',
  'practicePatientPhoto',
  'patientPhoneEmail',
  'removeEhrsynergyLogo',
  'unlockSignedProgressNote',
  'adjustClock',
  'timeZone',
  'deleteEncounters',
];
