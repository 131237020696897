import { connect } from 'react-redux';
import * as actions from 'modules/clinic/actions';

import * as selectors from 'modules/clinic/selectors';

import React from 'react';

export const fetchLayerHOC = Component => {
  return class ClinicFetchLayer extends React.Component {
    componentDidMount() {
      if (!this.props.isFetched && !this.props.isFetching) {
        this.props.fetchClinicInfo();
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = state => ({
  ...selectors.getStatus(state.clinic),
  clinic: selectors.getClinicInfo(state.clinic),
});

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
