import { handleActions } from 'redux-actions';

import * as settingsConstants from './constants';

import * as userConstants from '../user/constants';

const initialState = {
  isFetching: false,
  isFetched: false,
  isSaving: false,
};

export default handleActions({
  [settingsConstants.SETTINGS_FETCH]: (state, action) => ({
    ...state,
    isFetching: true,
  }),

  [settingsConstants.SETTINGS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result,
    shouldAddDiagnosisToMedical: false,
    isFetching: false,
    isFetched: true,
  }),

  [settingsConstants.SETTINGS_FETCH_FAIL]: (state, action) => ({
    ...state,
    isFetching: false,
  }),

  [settingsConstants.SETTINGS_SAVE]: (state, action) => ({
    ...state,
    isSaving: true,
  }),

  [settingsConstants.SETTINGS_SAVE_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload.settings,
      shouldAddDiagnosisToMedical: false,
      isSaving: false,
    };
  },
  [settingsConstants.SETTINGS_SAVE_LOCAL]: (state, action) => {
    return {
      ...state,
      ...action.payload.customsSettings,
      isSaving: false,
    };
  },
  [settingsConstants.SETTINGS_SAVE_FAIL]: (state, action) => ({
    ...state,
    isSaving: false,
  }),

  [userConstants.USER_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result.settings,
    shouldAddDiagnosisToMedical: false,
  }),
}, initialState);
