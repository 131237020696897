import React from 'react';

import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';
import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import RadioButtons from 'components/RadioButtons';
import CommentBox from 'components/CommentBox';
import ValidatedField from 'components/Form/ValidatedField';
import SimpleSelect from 'components/MedSelect/SimpleSelect/SimpleSelect';

import { familyItemWhiteList } from 'helpers/fmss/family/family';
import duplicateFamilyItemComparator from 'helpers/fmss/comparators/duplicate-items/family';
import { formatMedOption } from 'helpers/selectHelpers';
import { buildSchema } from 'helpers/validationRules';

import {
  createFamilyPerson,
  createIllness,
  searchFamilyPerson,
  searchIllnesses,
  removeIllnesses,
  removeFamilyPerson
} from 'api/family';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptyFamilyItem = {
  description: '',
  illnesses: [],
  id: null,
};

const mapProps = (familyPerson) => ({
  id: familyPerson.id,
  familyPerson: formatMedOption(familyPerson.familyPerson),
  alive: familyPerson.alive,
  illnesses: familyPerson?.illnesses?.map(formatMedOption),
  description: familyPerson.description,
})

const validationSchema = () => buildSchema({
  familyPerson: 'requiredSelectOption',
})

const FamilyItemForm = props => (
  <FormHOC
    emptyItem={emptyFamilyItem}
    item={props.familyItem}
    itemId={props.familyItemId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={props.updateFamilyItem}
    onCreate={props.createFamilyItem}
    onDelete={props.deleteFamilyItem}
    router={props.router}
    location={props.location}
    patientId={props.patientId}
    whiteList={familyItemWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    itemsInTable={props.itemsInTable}
    duplicateItemComparator={duplicateFamilyItemComparator}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({
 itemId: familyItemId, item: familyItem, fetching, clean, update, del, submit,
}) => {
      return (
        <form
          onSubmit={submit}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={familyItemId ? 'Editing history' : 'Adding history'}
                onClick={familyItemId ? clean : () => null}
              />

              <div className={cx.row}>
                <h3 className="section-title">Family member</h3>
                <ValidatedField>
                  <SimpleSelect
                    name="familyPerson"
                    currentValue={familyItem.familyPerson}
                    onCreate={createFamilyPerson}
                    onChange={value => update('familyPerson', value)}
                    loadOptions={searchFamilyPerson}
                    onRemoveOption={removeFamilyPerson}
                    checkAllowingToRemoveOption={() => true}
                  />
                </ValidatedField>
              </div>

              <div className={cx.row}>
                <RadioButtons
                  title="Alive"
                  active={familyItem.alive}
                  items={[{
                    name: 'Yes',
                    value: true,
                  }, {
                    name: 'No',
                    value: false,
                  }]}
                  onChange={value => update('alive', value)}
                />
              </div>

              <div className={cx.row}>
                <h3 className="section-title">History</h3>
                <SimpleSelect
                  name="illness"
                  currentValue={familyItem.illnesses}
                  onCreate={createIllness}
                  onChange={value => update('illnesses', value)}
                  loadOptions={searchIllnesses}
                  onRemoveOption={removeIllnesses}
                  isMulti
                />
              </div>

              <div className={cx.row}>
                <CommentBox
                  title="history comment"
                  name="comment"
                  placeholder="Enter a comment"
                  value={familyItem.description}
                  onChange={e => update('description', e.target.value)}
                />
              </div>
            </div>

            <EditFormActions
              deleteButton={!!familyItemId}
              cancelButton
              submitButton
              disabledSubmit={fetching}
              disabledCancel={fetching}
              onDelete={del}
              onCancel={clean}
            />

          </EditForm>
        </form>
      );
    }}
  </FormHOC>
);

FamilyItemForm.propTypes = generateFormPropTypes({
  itemName: 'familyItem',
  itemIdName: 'familyItemId',
  onUpdate: 'updateFamilyItem',
  onCreate: 'createFamilyItem',
  onDelete: 'deleteFamilyItem',
});

const DirtyCheckedWrapper = dirtyCheckLayerHOC(FamilyItemForm);

export default props => (
  <LoaderLayer
    disabled={props.familyItemId === undefined}
    status={props.status}
    reload={() => props.fetchFamilyItem(props.familyItemId)}
  >
    <DirtyCheckedWrapper
      {...props}
      dirtyCheckIdentity="familyItemId"
    />
  </LoaderLayer>
);
