import compareObjectsByParams from 'helpers/common/object/compare';

const checkLength = (firstList, secondList) => firstList.length !== secondList.length;

const compare = (firstList, secondList, comparasionFields) =>
  firstList.every(firstListObject =>
    secondList.some(secondListObject =>
      compareObjectsByParams(firstListObject, secondListObject, comparasionFields)));

const compareListsOfObjectsByParams = (firstList, secondList, comparasionFields) =>
  (checkLength(firstList, secondList)
    ? false
    : compare(firstList, secondList, comparasionFields));

export default compareListsOfObjectsByParams;
