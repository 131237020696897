import React, { createContext } from 'react';
import { compose } from 'redux';

import AppContextContainer from 'containers/App/AppContextContainer';

import TimeZoneDateConverter from 'helpers/date/time-zone-date-converter';
import TimeZone from 'helpers/date/TimeZone';

import { isCurrentUserProvider } from 'helpers/providers/providers';
import ElementNameConverter from '../../../helpers/elements/name-converter';
import BillingService from 'helpers/billing/billing-service';

export const AppContext = createContext({
  permissions: {},
  isCurrentUserProvider: false,
  timeZone: null,
  infoModalData: {},
  setInfoModalData: () => {},
  timeZoneDateConverter: null,
  billingService: null,
  currentUserId: 0,
  elementNameConverter: null,
});

const AppContextProvider = (props) => {
  const {
    permissions,
    settings,
    infoModalData,
    setInfoModalData,
    currentUserId,
    purchaseSeatModalsView,
    setPurchaseSeatModalsView,

    children
  } = props;

  const timeZone = {
    timeZone: settings.timeZone,
    adjustClock: settings.adjustClock,
  };

  const timeZoneDateConverter = new TimeZoneDateConverter(new TimeZone(timeZone));
  const billingService = new BillingService(timeZoneDateConverter);
  const elementNameConverter = new ElementNameConverter(timeZoneDateConverter);

  const context = {
    permissions,
    isCurrentUserProvider: isCurrentUserProvider(permissions),
    timeZone: new TimeZone({
      timeZone: settings.timeZone,
      adjustClock: settings.adjustClock,
    }),
    infoModalData,
    setInfoModalData,
    timeZoneDateConverter,
    billingService,
    currentUserId,
    elementNameConverter
  };

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  );
};

export const AppContexProvidertWrapper = compose(
  AppContextContainer,
)(AppContextProvider);
