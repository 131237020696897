import React, { useContext } from 'react';
import { func, bool, string, shape, arrayOf } from 'prop-types';

import { EditModeContext } from 'components/ElementsViewItem/context';

import Modal from 'components/Modal';

import { elementsViewItemActionsModalData } from 'components/ElementsViewItemActions/data';

const EditModeModal = (props) => {
  const { currentEditModeAction } = useContext(EditModeContext);
  const {
    passwordError,
    currentElementName = '',
    isOpen,
    onModalClose,
    onModalConfirm,
    itemsForMerge,
  } = props;

  const getMergeModalContentForSingleItemsForMerge = () => {
    return `${itemsForMerge[0].name  } &#8594; ${  currentElementName}`;
  };

  const getMergeModalContentForNoSingleItemsForMerge = () => {
    return `${itemsForMerge.length  } Elements &#8594; ${  currentElementName}`;
  };

  const getMergeModalContent = () => {
    if (itemsForMerge.length === 1) {
      return getMergeModalContentForSingleItemsForMerge();
    }

    return getMergeModalContentForNoSingleItemsForMerge();
  };

  const getElementNameForMerge = () => {
    if (currentEditModeAction === elementsViewItemActionsModalData.mergeElements.id) {
      return getMergeModalContent();
    }

    return currentElementName;
  };

  return (
    <Modal
      data={{
        type: 'password-confirm',
        title: elementsViewItemActionsModalData[currentEditModeAction].title,
        content: `<p>${elementsViewItemActionsModalData[currentEditModeAction].content}</p><p style="color: red; font-size: 18px">${getElementNameForMerge()}</p><p><strong>Type your password to confirm</strong></p>`,
        passwordError,
        logo: `${elementsViewItemActionsModalData[currentEditModeAction].logo}`,
      }}
      size="medium"
      isOpen={isOpen}
      onModalClose={onModalClose}
      onModalConfirm={onModalConfirm}
      cancelDisabled={false}
    />
  );
};

EditModeModal.propTypes = {
  passwordError: bool.isRequired,
  currentElementName: string,
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  onModalConfirm: func.isRequired,
  itemsForMerge: arrayOf(shape({
    name: string
  })).isRequired,
};

export default EditModeModal;
