import React, { useState } from 'react'
import { shape, number, string, func, bool } from 'prop-types'

import Modal from 'components/Modal';

import cx from './SimpleSelect.module.scss'

const Option = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const confirmDelete = () => {
    const {
      onRemove
    } = props

    onRemove()
    closeModal()
  }

  const openModal = () => setIsOpenModal(true)
  const closeModal = () => setIsOpenModal(false)

  const {
    option,
    onSelect,
    isAllowToRemove
  } = props

  return (
    <div
      className={cx['option-container']}
    >
      <button
        className={cx['no-button']}
        onClick={onSelect}
        type="button"
      >
        {option.name}
      </button>
      {(!option?.isNew && isAllowToRemove) && (
        <span className={cx['remove-option']}>
          <button
            type="button"
            className={cx['no-button']}
            onClick={openModal}
          >
            X
          </button>
        </span>
      )}
      <Modal
        data={{
          type: 'confirm',
          title: '',
          content: `<p>Do you want to delete "${option.name}"</p>`,
        }}
        size="medium"
        isOpen={isOpenModal}
        onModalClose={closeModal}
        onModalConfirm={confirmDelete}
        cancelDisabled={false}
        clickOutsideDisabled
      />
    </div>
  )
}

Option.propTypes = {
  onSelect: func.isRequired,
  isAllowToRemove: bool.isRequired,
  onRemove: func.isRequired,
  option: shape({
    id: number,
    name: string
  }).isRequired
}

export default Option
