import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';
import { compose } from 'redux';

import ChartReportItem from '../../../ChartReportItem';
import ChartReportFamilyItem from './ChartReportFamilyItem';

import { FMSS_TYPES } from 'helpers/fmss/constants';
import * as familyHelpers from 'helpers/fmss/family/family';
import ProducerEmptyMessageFactory from 'helpers/fmss/additional-empty-message/factory/producer-factory';
import ProgressNoteBase from 'helpers/fmss/additional-empty-message/factory/empty-message-entity/ProgressNoteBase';

export const ChartReportFamily = (props) => {
  const {
    familyItems,
    chartId,
    toggleChartReport,
    deleteFamilyItem,
    location,
    viewMode,
    hasNoPertinentFamily,
  } = props;

  const currentFamilyItemId = location.query.family && parseInt(location.query.family);

  const sortedFamilyItems = familyHelpers.sortFamilyItems(familyItems);

  const conditionalData = new ProgressNoteBase(hasNoPertinentFamily, familyItems.length);
  const emptyMessage = ProducerEmptyMessageFactory.getMessage(FMSS_TYPES.PROGRESS_NOTE_FAMILY, conditionalData);

  return (
    <ChartReportItem
      title="Family hx"
      tooltip={!viewMode}
      link={(!viewMode && `/app/doctor/charts/${chartId}/fmss`) || null}
      toggleChartReport={!viewMode && toggleChartReport}
    >
      {familyItems.length === 0 && emptyMessage}
      {sortedFamilyItems.map(familyItem => (
        <ChartReportFamilyItem
          key={familyItem.id}
          familyItem={familyItem}
          onRemove={() => deleteFamilyItem(
            familyItem,
            currentFamilyItemId === familyItem.id && location.pathname
          )}
          chartId={chartId}
          toggleChartReport={toggleChartReport}
          viewMode={viewMode}
        />
      ))}
    </ChartReportItem>
  );
};

const familyItemPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  familyPerson: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  illnesses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
});

ChartReportFamily.propTypes = {
  familyItems: PropTypes.arrayOf(familyItemPropType).isRequired,
  chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  viewMode: PropTypes.bool,
  toggleChartReport: PropTypes.func.isRequired,
  deleteFamilyItem: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  hasNoPertinentFamily: PropTypes.bool,
};

const ChartReportFamilyComposer = compose(
  withRouter
)(ChartReportFamily)

export default ChartReportFamilyComposer;
