export const ELEMENTS_TYPES = {
  add: {
    text: 'Add',
  },
  new: {
    text: 'New',
  },
};

export const ELEMENTS_MAX_LENGTH = 50;
