import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import * as constants from 'modules/patientsHx/constants';
import PropTypes from 'prop-types';

export const fetchLayerHOC = Component => {
  const MedicationsFetchLayer = props => {
    const {
      filter,
      patientId,
      isFetching,
      fetchMedications
    } = props;
    const fetchMedicationsWrapper = useCallback((patientId, filter) => {
      fetchMedications(patientId, filter);
    }, [patientId, filter]);

    useEffect(() => {
      if(isFetching === undefined && patientId) {
        fetchMedicationsWrapper(patientId, filter);
      }
    }, [patientId, filter]);
    return <Component {...props} />;
  };

  MedicationsFetchLayer.propTypes = {
    isFetching: PropTypes.bool,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    filter: PropTypes.string.isRequired,
    fetchMedications: PropTypes.func.isRequired,
  };

  return MedicationsFetchLayer;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    medications: selectors.getMedications(state.patientsHx, ownProps.patientId, ownProps.filter),
    isFetching: selectors.getIsFetchingList(state.patientsHx, constants.MEDICATIONS, ownProps.patientId, ownProps.filter),
    hasActiveMedications: !!selectors.getMedications(state.patientsHx, ownProps.patientId, 'active').length,
    isInitialized: selectors.getIsInitializedList(state.patientsHx, constants.MEDICATIONS, ownProps.patientId, 'active'),
    error: selectors.getListError(state.patientsHx, constants.MEDICATIONS, ownProps.patientId, ownProps.filter),
  };
};

const mapActionCreators = {
  fetchMedications: actions.fetchMedications,
  createMedication: actions.createMedication,
  updateMedication: actions.updateMedication,
  deleteMedication: actions.deleteMedication,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
