import React, { useEffect, useState } from 'react';
import { func, shape, string, bool } from 'prop-types';

import InfoPopup from 'components/InfoPopup';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Loader from 'components/Loader';

import { useRoute } from 'hooks/useRoute';

import { isNullOrUndefined } from 'helpers';

import cx from './BoxService.module.scss';

import BoxLogo from './box-logo.png';

const BoxService = props => {
  const [boxService, setBoxService] = useState(props.boxService);
  const { fromSearchParams, setSearchParams } = useRoute();

  const {
    saveBoxCode,
    removeBoxCode
  } = props;

  useEffect(() => {
    const code = fromSearchParams('code');
    console.log(code, 's');
    if (!isNullOrUndefined()) {
      saveBoxCode({ code });
      setSearchParams({ code: undefined, state: undefined });
    }
  }, []);

  const onSyncStatusChange = (value) => {
    updateConnectionStatus(value);

    if (value === true) {
      window.location.replace(composeUrl());
    } else {
      removeBoxCode();
    }
  };

  const updateConnectionStatus = (value) => {
    setBoxService(prev => ({
      ...prev,
      boxService: {
        ...prev.boxService,
        connected: value
      },
    }));
  };

  const composeUrl = () => {
    const { boxService: { clientId } } = props;
    const baseUrl = 'https://account.box.com/api/oauth2/authorize';
    const params = {
      'client_id': clientId,
      'response_type': 'code',
      'redirect_uri': window.location.href,
      'state': '',
    };

    const paramsArr = [];

    Object.keys(params).forEach(key => {
      const value = params[key];

      paramsArr.push(`${key  }=${  value}`);
    });

    return `${baseUrl  }?${  paramsArr.join('&')}`;
  };

  return (
    <div className={cx.wrapper}>
      <div className={cx['help-icon-container']}>
        <InfoPopup position='right'>
          Link to your Box.com account to save a copy of your signed encounters
        </InfoPopup>
      </div>
      <img className={cx['box-logo']} src={BoxLogo} alt="" />
      <div className={cx['element-wrapper']}>
        <span className={cx['label__file-sync']}><b>BOX File Sync</b> status:</span>
        <RadioButtonGroup
          items={[{
            name: 'Disconnected',
            value: false,
          }, {
            name: 'Connected',
            value: true,
          }]}
          checked={boxService.connected}
          onChange={onSyncStatusChange}
        />
      </div>
    </div>
  );
};

BoxService.propTypes = {
  saveBoxCode: func.isRequired,
  removeBoxCode: func.isRequired,
  boxService: shape({
    clientId: string,
    connected: bool
  })
};

BoxService.defaultProps = {
  boxService: {
    client_id: 'test',
    connected: false
  }
};

const Wrapper = (props) => {
  const { isFetching = false } = props;
  if (isFetching) {
    return <div className={cx['loader-wrapper']}><Loader /></div>;
  }

  return <BoxService {...props} />;
};

Wrapper.propTypes = {
  isFetching: bool.isRequired
};

export default Wrapper;
