const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchSystems = () =>
  request.get('/api/rest/socials/')
    .end();

export const fetchPatientSocial = patientId =>
  request.get(`/api/rest/social/patient/${patientId}`)
    .end();

export const fetchElementsBySystem = systemId =>
  request
    .get(`/api/rest/socials/element/social/${systemId}`)
    .end();

export const createElement = element =>
  request.post('/api/rest/socials/element/create')
    .send(element)
    .end();

export const deleteRightSideElement = (id) =>
  request.post(`/api/rest/socials/element/${id}`)
    .end();

export const updateElement = data =>
  request.post('/api/rest/social/element/update')
    .send(data)
    .end();

export const fetchSocialSubElements = (elementId, systemId, numberGroup = 0) =>
  request
    .get(`/api/rest/social/subElement/${elementId}`)
    .query({ numberGroup })
    .query({ socialId: systemId })
    .end();

export const updateSocialSubElement = subelement =>
  request.post('/api/rest/social/subElement/update')
    .send(subelement)
    .end();

export const updateSocialSubElementForElement = data =>
  request.post('/api/rest/social/element/update/subElement')
    .send(data)
    .end();

export const deleteElement = body =>
  request
    .del('/api/rest/social/system/elements/delete')
    .send(body)
    .end();

export const createSocialSubElement = body =>
  request.post('/api/rest/social/subElement/create')
    .send(body)
    .end();

export const addElementToSystem = body =>
  request.post('/api/rest/social/add/to/system')
    .send(body)
    .end();

export const createDetails = body =>
  request.put('/api/rest/social/details')
    .send(body)
    .end();

export const updateDetails = body =>
  request.patch('/api/rest/social/details')
    .send(body)
    .end();

export const deleteDetails = body =>
  request.del('/api/rest/social/details')
    .send(body)
    .end();
