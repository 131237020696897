import Medications from './components/Medications';
import { MedicationsListWrapper } from './components/MedicationsList';

export const MedicationsRoute = {
  path: 'medications',
  element: <Medications />,
  children: [
    {
      index: true,
      element: <MedicationsListWrapper />
    },
    {
      path: ':filter',
      element: <MedicationsListWrapper />
    }
  ],
};