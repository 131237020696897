import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import * as constants from 'modules/patientsHx/constants';

export const withDiagnosesFetchLayer = Component => props => {
  const {
    isFetching,
    patientId,
    filter,
    fetchDiagnoses
  } = props;

  useEffect(() => {
    if (isFetching === undefined) {
      fetchDiagnosesWrapper(patientId, filter);
    }
  }, [patientId, filter]);

  const fetchDiagnosesWrapper = (patientIdCandidate, filterCandidate) => {
    if (!patientIdCandidate) return;
    fetchDiagnoses(patientIdCandidate, filterCandidate);
  };

  return <Component {...props} />;
};

withDiagnosesFetchLayer.propTypes = {
  isFetching: PropTypes.bool,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  filter: PropTypes.string.isRequired,
  fetchDiagnoses: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    diagnoses:
      selectors.getDiagnoses(state.patientsHx, ownProps.patientId, ownProps.filter),
    isFetching: selectors.getIsFetchingList(state.patientsHx, constants.DIAGNOSES, ownProps.patientId, ownProps.filter),
    hasActiveDiagnoses: !!selectors.getDiagnoses(state.patientsHx, ownProps.patientId, 'active').length,
    isInitialized: selectors.getIsInitializedList(state.patientsHx, constants.DIAGNOSES, ownProps.patientId, 'active'),
    error: selectors.getListError(state.patientsHx, constants.DIAGNOSES, ownProps.patientId, ownProps.filter),
  };
};

const mapActionCreators = {
  fetchDiagnoses: actions.fetchDiagnoses,
  createDiagnosis: actions.createDiagnosis,
  updateDiagnosis: actions.updateDiagnosis,
  deleteDiagnosis: actions.deleteDiagnosis,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
