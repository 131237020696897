export const BOX_CLIENT_ID_FETCH = 'BOX_CLIENT_ID_FETCH';
export const BOX_CLIENT_ID_FETCH_SUCCESS = 'BOX_CLIENT_ID_FETCH_SUCCESS';
export const BOX_CLIENT_ID_FETCH_FAIL = 'BOX_CLIENT_ID_FETCH_FAIL';

export const BOX_CODE_SAVE = 'BOX_CODE_SAVE';
export const BOX_CODE_SAVE_SUCCESS = 'BOX_CODE_SAVE_SUCCESS';
export const BOX_CODE_SAVE_FAIL = 'BOX_CODE_SAVE_FAIL';

export const BOX_CODE_REMOVE = 'BOX_CODE_REMOVE';
export const BOX_CODE_REMOVE_SUCCESS = 'BOX_CODE_REMOVE_SUCCESS';
export const BOX_CODE_REMOVE_FAIL = 'BOX_CODE_REMOVE_FAIL';
