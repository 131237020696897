export default class RightSideCpt {
  constructor({
    id, description, favoriteId, cptCode, ids,
  }) {
    this._id = id;
    this._description = description;
    this._favoriteId = favoriteId;
    this._ids = ids;
    this._cptCode = cptCode;
  }

  getId = () => this._id

  getFavoriteId = () => this._favoriteId

  getDescription = () => this._description

  getCptCode = () => this._cptCode

  getIds = () => this._ids

  getFullName = () =>
    `${this._cptCode} ${this._description}`
}
