import { GeneralTab } from './routes/General/components/GeneralTab';
import { SettingsPage } from './components/SettingsPage';
import { ClinicTab } from './routes/General/components/ClinicTab';
import { PreferencesTab } from './routes/General/components/Preferences/PreferencesTab';
import { UsersTab } from './routes/Users/components/Users';
import { UsersList } from './routes/Users/components/UsersList';
import { RolesListContainer } from './routes/Users/components/Roles/RolesList';
import { SeatsListWrapper } from './routes/Users/components/Seats/SeatsList';
import { FinancialTab } from './routes/Financial/components/FinancialTab';
import { PaymentsTab } from './routes/Financial/components/Payments/PaymentsTab';
import { PaymentsPlansTab } from './routes/Financial/components/Plans/PaymentsPlansTab';
import { AddonsTab } from './routes/Addons/components/AddonsTab';
import { BoxComTab } from './routes/Addons/components/BoxComTab';
import { DrChronoTab } from './routes/Addons/components/DrChronoTab';
import { ConsentTab } from './routes/General/components/Consent';

export const SettingsRoute = {
  path: 'settings',
  element: <SettingsPage />,
  children: [
    {
      element: <GeneralTab />,
      children: [
        { index: true, element: <ClinicTab /> },
        { path: 'preferences', element: <PreferencesTab /> },
        { path: 'consent-templates', element: <ConsentTab /> },
      ]
    },
    {
      path: 'users',
      element: <UsersTab />,
      children: [
        { index: true, element: <UsersList /> },
        { path: 'inactive', element: <UsersList active={false} /> },
        { path: 'roles', element: <RolesListContainer /> },
        { path: 'seats', element: <SeatsListWrapper /> },
      ]
    },
    {
      path: 'financial',
      element: <FinancialTab />,
      children: [
        { index: true, element: <PaymentsTab /> },
        { path: 'payments_plans', element: <PaymentsPlansTab /> }
      ]
    },
    {
      path: 'addons',
      element: <AddonsTab />,
      children: [
        { index: true, element: <BoxComTab /> },
        { path: 'drchrono', element: <DrChronoTab /> }
      ]
    }
  ],
};
