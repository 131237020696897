import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import * as actions from 'modules/permissions/actions';
import * as selectors from 'modules/permissions/selectors';

export const fetchLayerHOC = (Component) => {
  return class PermissionsFetchLayer extends React.Component {
    static propTypes = {
      isFetched: PropTypes.bool,
      fetchPermissions: PropTypes.func.isRequired,
      onPermissionsFetched: PropTypes.func,
      isFetching: PropTypes.bool,
    }

    static defaultProps = {
      isFetched: undefined,
      isFetching: undefined,
      onPermissionsFetched: undefined,
    }


    componentDidMount() {
      if (this.props.isFetched && this.props.onPermissionsFetched) {
        this.props.onPermissionsFetched();
      }

      if (this.props.isFetched || this.props.isFetching) return;

      this.fetchPermissions();
    }

    componentDidUpdate(prevProps) {
      const { onPermissionsFetched, isFetched } = this.props;

      if (isFetched && (prevProps.isFetched !== isFetched) && onPermissionsFetched) {
        onPermissionsFetched();
      }
    }


    fetchPermissions = async () => {
      await this.props.fetchPermissions();
      this.props.onPermissionsFetched && this.props.onPermissionsFetched();
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => ({
  permissions: selectors.getPermissions(state.permissions),
  isFetched: selectors.getIsFetched(state.permissions),
  isFetching: selectors.getIsFetching(state.permissions),
  ...ownProps,
});

const mapActionCreators = { ...actions };

export default component => connect(mapStateToProps, mapActionCreators)(component);
