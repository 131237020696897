export const formatScreeningForServer = (screeningItem) => {
  const result = { ...screeningItem };

  result.medScreeningDetailsId = result.screeningDetail.id;
  // result.diagnosisIds = result.diagnosis && result.diagnosis.map(o => o.id);
  delete result.screening;
  delete result.diagnosis;
  delete result.screeningDetail;

  return result;
};

export const screeningItemWhiteList = [
  'id',
  'patientId',
  'screeningDetail',
  'diagnosis',
  'description',
  'diagnosis',
  // 'chartId',
  'startDate',
];
