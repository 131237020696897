import React from 'react';
import App from './containers/App';
import { DashboardRoute } from './routes/Dashboard';

import { ChartsRoute } from './routes/Charts';
import { PatientsRoute } from './routes/Patients';
import { ReportRoute } from './routes/Reports';
import { SettingsRoute } from './routes/Settings';
// const notFoundRoutes = require('./routes/NotFound');
// const swaggerRoutes = require('./routes/Swagger');
// const voiceRoutes = require('./routes/Voice');

export const AppRoute = {
  path: 'doctor',
  element: <App />,
  children: [
    DashboardRoute,
    ChartsRoute,
    PatientsRoute,
    ReportRoute,
    SettingsRoute
  ],
};
