export default [
  {
    title: 'date',
    subtitle: 'MM-DD-YY',
    sortable: true,
  },
  {
    title: 'transaction',
    subtitle: 'Numbers',
    sortable: true,
  },
  {
    title: 'period',
    subtitle: 'MM-DD-YY',
    sortable: true,
  },
  {
    title: 'cc',
    subtitle: 'Last 4-digits',
    sortable: true,
  },
  {
    title: 'accounts',
    subtitle: 'Active',
    sortable: true,
  },
  {
    title: 'amount',
    subtitle: 'Total',
    sortable: true,
  },
  {
    title: 'status',
    subtitle: 'Payment',
    sortable: true,
  },
];
