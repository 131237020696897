import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Formik } from 'formik';

import { isEmpty, stripWhiteListProps } from 'helpers';
import stringParser from 'helpers/common/string/string-parser';
import compareItemsBasicDuplicateStrategy from 'helpers/fmss/comparators/strategies/basic';

// helper function to generate propTypes
export const generateFormPropTypes = ({
                                        itemName, itemIdName, onUpdate, onCreate, onDelete,
                                      }) => {
  return {
    [itemName]: PropTypes.object,
    [itemIdName]: PropTypes.string,
    setDirty: PropTypes.func.isRequired,
    [onUpdate || 'onUpdate']: PropTypes.func.isRequired,
    [onCreate || 'onCreate']: PropTypes.func.isRequired,
    [onDelete || 'onDelete']: PropTypes.func.isRequired,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    status: PropTypes.shape({
      isUpdating: PropTypes.bool.isRequired,
    }).isRequired,
    isCreating: PropTypes.bool,
  };
};

const PatientHistoryItemForm = (props) => {
  const formikRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { filter, chartId } = useParams();

  const {
    item,
    itemId,
    mapItem,
    emptyItem,
  } = props;

  const resolveInitialValues = useCallback(() => {
    return itemId
      ? mapItem(item)
      : emptyItem;
  }, [itemId, item, emptyItem, mapItem]);


  useEffect(() => {
    formikRef.current.resetForm({ values: resolveInitialValues() });
  }, [itemId, resolveInitialValues]);

  useEffect(() => {
    if (item && filter) {
      const itemFilter = item?.active;
      const currentFilter = !filter;

      if (itemFilter !== currentFilter) {
        formikRef.current.resetForm({ values: emptyItem });
      }
    }
  }, [filter, item, emptyItem]);

  const clean = ({ resetForm }) => {
    resetForm({ values: emptyItem });
    navigate(pathname);
  };

  const submit = (values, { resetForm }) => {
    const {
      itemsInTable,
      duplicateItemComparator,
      patientId,
      onUpdate,
      onCreate,
      whiteList,
      pathToItemBuilder
    } = props;

    const isSubmittedItemPresent = compareItemsBasicDuplicateStrategy(itemsInTable, values, duplicateItemComparator);

    if (isSubmittedItemPresent) {
      return;
    }

    const parsedChartId = stringParser(chartId);
    const parsedPatientId = stringParser(patientId);

    let data = {
      ...item,
      ...values,
      patientId: parsedPatientId,
      chartId: itemId ? item.chartId : parsedChartId,
    };

    data = stripWhiteListProps(data, whiteList);

    if (itemId) {
      onUpdate(data);
    } else {
      onCreate(data)
        .then((res) => {
          if(isEmpty(res.body?.errorDTO)) {
            clean({ resetForm });
          } else {
            const path = pathToItemBuilder({ itemId: res.body?.medicationDTO.id });
            navigate(path);
          }

          return res;
        });
    }
  };

  const del = ({ resetForm }) => {
    const {
      onDelete,
    } = props;

    const parsedChartId = parseInt(chartId, 10);

    onDelete(item, pathname, parsedChartId);
    clean({ resetForm });
  };

  const update = (prop, value, { setFieldValue }) =>
    setFieldValue(prop, value);

  const {
    status,
    isCreating,
    children,
    resolveValidationSchema,
  } = props;

  const fetching = (itemId && status.isUpdating) || isCreating;

  const initialValues = resolveInitialValues();
  const validationSchema = resolveValidationSchema();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
          values,
          setFieldValue,
          handleSubmit,
          resetForm,
        }) => (
        children({
          itemId,
          item: values,
          fetching,
          clean: () => clean({ resetForm }), update: (prop, value) => update(prop, value, { setFieldValue }),
          del: () => del({ resetForm }),
          submit: handleSubmit,
        })
      )}
    </Formik>
  );
};

PatientHistoryItemForm.propTypes = {
  pathToItemBuilder: PropTypes.func,
  emptyItem: PropTypes.object.isRequired,
  item: PropTypes.object,
  itemId: PropTypes.string,
  setDirty: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  whiteList: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCreating: PropTypes.bool,
  children: PropTypes.func.isRequired,
  status: PropTypes.shape({
    isUpdating: PropTypes.bool.isRequired,
  }).isRequired,
  itemsInTable: PropTypes.array,
  duplicateItemComparator: PropTypes.func,
  mapItem: PropTypes.func.isRequired,
  resolveValidationSchema: PropTypes.func.isRequired,
};

PatientHistoryItemForm.defaultProps = {
  itemsInTable: [],
  duplicateItemComparator: () => {},
  pathToItemBuilder: () => {}
};

export default PatientHistoryItemForm;
