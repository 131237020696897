import React from 'react';
import { compose } from 'redux';
import DrChronoOfficesFetchLayer from 'containers/DrChrono/DrChronoOfficesFetchLayer';
import Offices from 'components/DrChronoOffices/Offices';
import DrChronoOfficesContainer from 'containers/DrChrono/DrChronoOfficesContainer';

const DrChronoOfficesWrapper = compose(
  DrChronoOfficesContainer,
  DrChronoOfficesFetchLayer,
)(Offices)

const DrChronoOffices = () => {
  return (
    <>
      <h3>PROVIDERS</h3>

      <DrChronoOfficesWrapper />
    </>
  )
}

export default DrChronoOffices;
