import { handleActions } from 'redux-actions';

import * as patientsConstants from '../constants';

const initialState = [];

export default handleActions({
  [patientsConstants.PATIENTS_PAGE_FETCH]: state => ({
    ...state,
    isFetching: true,
  }),

  [patientsConstants.PATIENTS_PAGE_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.pageNum]: [
      ...action.payload.result.patientDTOS,
    ],
    totalPages: action.payload.result.totalPages,
    isFetching: false,
  }),
}, initialState);
