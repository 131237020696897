import React from 'react';
import PropTypes from 'prop-types';

import sortBy from 'lodash/sortBy';

import ChartReportItem from '../../../ChartReportItem';
import ChartReportSocialItem from "./ChartReportSocialItem";

import PayloadDataToRemoveElements from 'payload/social/elements/remove';

const ChartReportSocial = (props) => {
  const {
    chartId,
    toggleChartReport,
    enclosedElements,
    flattenedSession,
    items,
    viewMode,
    patient,
    removeElements,
    freeTextForAllSystem
  } = props;

  const hasSocial = !!flattenedSession.length;

  const removeElementsWrapper = (elementsToDelete, systemId) => {
    const data = {
      patientId: patient.id,
      systemId,
      elements: elementsToDelete,
    };
    const payloadDataToRemoveElements = new PayloadDataToRemoveElements(data);

    removeElements(payloadDataToRemoveElements.getLocalData(), payloadDataToRemoveElements.getRequestData(), chartId);
  };

  return (
    <ChartReportItem
      title="Social hx"
      tooltip={!viewMode}
      link={(!viewMode && `/app/doctor/charts/${chartId}/fmss/social`) || null}
      toggleChartReport={!viewMode && toggleChartReport}
    >
      {hasSocial && sortBy(items, [item => item.name.toLowerCase()]).map(system => (
        <div style={{ 'marginBottom': '5px' }}>
          <ChartReportSocialItem
            freeTextForAllSystem={freeTextForAllSystem}
            key={system.id}
            system={system}
            elements={enclosedElements[system.id]}
            toggleChartReport={toggleChartReport}
            chartId={chartId}
            removeElements={elementsToDelete => removeElementsWrapper(elementsToDelete, system.id)}
            viewMode={viewMode}
            patient={patient}
          />
          {!!freeTextForAllSystem[`p${patient.id}s${system.id}`] && (
            <span>{freeTextForAllSystem[`p${patient.id}s${system.id}`].freeText}</span>
          )}
        </div>
      ))}
    </ChartReportItem>
  );
};


ChartReportSocial.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  flattenedSession: PropTypes.array,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  toggleChartReport: PropTypes.func,
  enclosedElements: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  removeElements: PropTypes.func,
  viewMode: PropTypes.bool,
  patient: PropTypes.object.isRequired,
};

export default ChartReportSocial;

