import React from 'react';
import propTypes from 'prop-types';

import ChartReportTable from 'components/ChartReport/ChartReportBody/ChartReportTable';
import ChartReportBillingContentRow from 'components/ChartReport/ChartReportBody/HPNote/ChartReportBillingContent/ChartReportBillingContentRow';

const BILLING_TABLE_HEADERS = [
  { title: 'Type', width: '10%' },
  { title: 'Code', width: '30%' },
  { title: 'Description', width: '60%' },
];

const ChartReportBillingContent = ({
  cptToDisplay, chartCptCode = { cptCode: '', description: '' }, isShowChartCPT, hcpcToDisplay,
}) => {
  return (
    <ChartReportTable
      headers={BILLING_TABLE_HEADERS}
    >
      {isShowChartCPT && (
        <ChartReportBillingContentRow
          code={chartCptCode.cptCode}
          description={chartCptCode.description}
          type="CPT"
        />
      )}
      {cptToDisplay
        .map(cpt =>
          ChartReportBillingContentRow({
            code: cpt.cptCode, description: cpt.description, type: 'CPT'
          })
        )
      }
      {hcpcToDisplay
        .map(hcpc =>
          ChartReportBillingContentRow({
            code: hcpc.hcpcMod, description: hcpc.description, type: 'HCPC'
          })
        )
      }
    </ChartReportTable>
  );
};

ChartReportBillingContent.propTypes = {
  cptToDisplay: propTypes.array.isRequired,
  hcpcToDisplay: propTypes.array.isRequired,
  isShowChartCPT: propTypes.bool.isRequired,
  chartCptCode: propTypes.shape({
    cptCode: propTypes.oneOfType([propTypes.string, propTypes.number]),
    description: propTypes.string,
  }),
};

export default ChartReportBillingContent;
