import React, { useContext } from 'react';
import { func, number, bool, shape } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useCreateChart } from 'hooks/useCreateChart';
import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import Encounter from './Encounter';
import AddButton from 'components/AddButton';
import Loader from 'components/Loader';
import NotAllowedStartNewNoteButton from 'components/NotAllowedStartNewNote';

import { isNullOrUndefined } from 'helpers';

import cx from './RecentVisits.module.scss';

const headers = [
  { title: 'encounter', subtitle: 'Date' },
  { title: 'reason', subtitle: '' },
  { title: 'details', subtitle: 'Description' },
];

const RecentVisits = (props) => {
  const navigate = useNavigate();
  const { createChart } = useCreateChart();
  const {
    charts,
    isFetching = false,
    isCreating = false,
    patient,
    permissions,
    isBlockedForReadOnly,
    patientId,
    setPatientForChart
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const isDeniedForOpenEncounter = () => {
    return !permissions.viewEditEncounterNote || isBlockedForReadOnly;
  };

  const openEncounter = async (encounter) => {
    if (encounter.signed) {
      window.location.href = `/api/rest/pdf/show/chart/${encounter.id}`;
      return;
    }

    if (isDeniedForOpenEncounter()) return;

    await setPatientForChart(patient);
    navigate(`/app/doctor/charts/${encounter.id}?step=1`);
  };

  const collectDataToCreateChartRequest = () => {

    return {
      chartingType: 1,
      patientId,
      dosDate: timeZoneDateConverter.getCurrentTimeZoneDateMs(),
    };
  };

  const createChartWrapper = () => {
    if (isCreating) return;

    const data = collectDataToCreateChartRequest();

    createChart(data);
  };

  const fetching = isNullOrUndefined(isFetching) || isFetching;

  const readOnly = !permissions.viewEditEncounterNote || isBlockedForReadOnly;

  if (fetching) return <Loader style={{ position: 'relative', top: 100 }} />;

  if (!charts || !charts.length) {
    return (
      <div className={cx.wrapper}>
        <div className={cx['title-wrapper']}>
          <h3 className={cx.title}>No recent visits</h3>
          {permissions.viewEditEncounterNote && (readOnly ?
            <NotAllowedStartNewNoteButton /> :
            (
              <AddButton
                loading={isCreating}
                onClick={createChartWrapper}
              >
                  Start New Note
              </AddButton>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className={cx.wrapper}>
      <div className={cx['title-wrapper']}>
        <h3 className={cx.title}>Recent visits</h3>
        {permissions.viewEditEncounterNote && (readOnly ?
          <NotAllowedStartNewNoteButton /> :
            (
              <div className={cx['button-wrapper']}>
                <AddButton
                  loading={isCreating}
                  onClick={createChartWrapper}
                >
                    Start New Note
                </AddButton>
              </div>
            )
        )}
      </div>
      <div className={cx.container}>
        <Table color="blue">
          <thead>
            <tr>
              {headers.map(
                header => <TableHead {...header} key={header.title} />
              )}
            </tr>
          </thead>
          <tbody>
            {charts.map(
              encounter => (
                <Encounter
                  chart={encounter}
                  key={encounter.id}
                  patient={patient}
                  onRowClick={() => openEncounter(encounter)}
                />
              )
            ).slice(0, 20)}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

RecentVisits.propTypes = {
  createChart: func.isRequired,
  setPatientForChart: func.isRequired,
  patientId: number.isRequired,
  isCreating: bool,
  isFetching: bool,
  permissions: shape({
    viewEditEncounterNote: bool.isRequired
  }).isRequired
};

export default RecentVisits;
