import { handleActions } from 'redux-actions';
import createList from './createList';
import { createReducerWithSingleHandler } from '../helpers';

import * as constants from '../../constants';

const filteredItems = new Set([constants.ALLERGIES, constants.DIAGNOSES, constants.MEDICATIONS]);

export const initialState = {
  active: createList('active')(),
  inactive: createList('inactive')(),
};

const filteredHandler = (state, action) => ({
  ...state,
  [action.payload.filter]: createList(action.payload.filter)(state[action.payload.filter], action),
});

const filteredReducer = (state = initialState, action) => {
  if (!action.payload.filter) return state;
  return handleActions(createReducerWithSingleHandler(filteredHandler), initialState)(state, action);
};

const reducer = (state, action) => createList()(state, action);

// for action to get to this reducer it has to meet requirements of parent reducer
// and also patientId should match with id used to create this reducer
export default patientId => (state, action) => {
  if (action.payload.patientId !== patientId) return state;

  // if reducer has filters
  if (filteredItems.has(action.payload.hxType)) {
    return filteredReducer(state, action);
  }
  // if reducer doesn't have filters
  return reducer(state, action);
};
