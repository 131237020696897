import * as permissionsHelpers from 'helpers/permissions';

export const getCurrentRole = (activePermissions, roles) => {
  let currentRole = null;

  Object.values(roles).forEach((role) => {
    if (permissionsHelpers.isEqualTwoSequencesOfPermissions(role.permissions, activePermissions)) {
      currentRole = role;
    }
  });

  return currentRole;
};

const chooseUserRoleId = (userRole) => {
  if (userRole) {
    return userRole.id;
  }

  return permissionsHelpers.defaultRoles.custom.id;
};

export const getUserRoleId = (userPermissions, userRoles) => {
  const roleForUser = getCurrentRole(userPermissions, userRoles);

  return chooseUserRoleId(roleForUser);
};
