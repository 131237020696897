import React from 'react';
import PropTypes from 'prop-types';

import {
  putTheFirstPositionForSubElement,
  getAllIndexesToAutoSetSubElement,
} from 'helpers';

import Modal from 'components/Modal';
import SubelementsList from 'components/SubelementsList';

const SubelementsModal = React.forwardRef(({
  item,
  step,
  chartId,
  patientId,
  disableSubelements,
  saveChart,
  createSubelement,
  addIndexToAutoSet,
  addElement,
  disableSubelementCreation,
  social,
  systemId,
  subelementsOpened,
  typeToAdd,
  closeSubelements,
  setNumber,
  selectSubElementCallback,
  exactMatch,
  broadMatch,
}, ref) => {
  const handleSubelementSelect = async (subelement) => {
    const type = typeToAdd !== undefined ? typeToAdd : null;

    const newSubElement = putTheFirstPositionForSubElement(subelement, social);
    await addElement({
      id: item.id,
      // if type is undefined then regular element was clicked
      // and for such elements we should set type to null
      // else it is either true or false
      // which means pos/neg element was clicked
      type,
      subIds: [newSubElement],
      name: item.name,
      favoriteId: item.favoriteId,
    });
    const indexesToAutoSet = getAllIndexesToAutoSetSubElement(item.name);
    addIndexToAutoSet(indexesToAutoSet, item.id);
    selectSubElementCallback(item, subelement);
  };

  return (
    <Modal
      isOpen={!disableSubelements && subelementsOpened === item.id}
      data={{
            type: 'subelements',
            title: 'Sub-elements',
            content: '',
        }}
      onModalClose={closeSubelements}
      onModalConfirm={closeSubelements}
    >
      <div ref={ref}>
        <SubelementsList
          element={item}
          position={1}
          elementId={item.id}
          step={step}
          chartId={chartId}
          saveChart={saveChart}
          onInitialized={() => {
          }}
          onSubelementSelect={handleSubelementSelect}
          onClose={closeSubelements}
          setNumber={setNumber}
          preventCloseOnSubmit
          createSubelement={(subelement) => {
            return createSubelement(subelement);
          }}
          disableCreation={disableSubelementCreation}
          social={social}
          systemId={systemId}
          patientId={patientId}
          currentItem={item}
          exactMatch={exactMatch}
          broadMatch={broadMatch}
        />
      </div>
    </Modal>
)});

SubelementsModal.propTypes = {
  item: PropTypes.object.isRequired,
  step: PropTypes.number,
  chartId: PropTypes.number,
  patientId: PropTypes.number.isRequired,
  disableSubelements: PropTypes.bool.isRequired,
  saveChart: PropTypes.func,
  createSubelement: PropTypes.func.isRequired,
  addIndexToAutoSet: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  disableSubelementCreation: PropTypes.bool.isRequired,
  social: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  systemId: PropTypes.number.isRequired,
  subelementsOpened: PropTypes.number,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

SubelementsModal.defaultProps = {
  exactMatch: {},
  broadMatch: {},
};

export default SubelementsModal;
