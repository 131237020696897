import GENDER_SELECT_OPTIONS from 'helpers/patient/gender/constants/select-options';

export const isGenderPresentInOptions = (genderValue) => {
  return GENDER_SELECT_OPTIONS.has(genderValue);
};

export const getGenderOption = gender =>
  GENDER_SELECT_OPTIONS.get(gender);

export const getGenderOptionValue = (gender) =>
  getGenderOption(gender).value;

export const getGenderOptionLabel = (gender) =>
  getGenderOption(gender).label;
