const request = require('superagent-promise')(require('superagent'), Promise);

// save
export const saveChartingSession = data =>
  request.post('/api/rest/chartings/save')
    .send(data)
    .end();

export const partialUpdateChart = ({ chartId, ...rest }) =>
  request
    .patch(`/api/rest/chartings/${chartId}`)
    .send(rest)
    .end();

export const removeRightSideElement = data =>
  request
    .del('/api/rest/element/delete')
    .send(data)
    .end();

// get favorites
export const fetchFavorites = data =>
  request
    .post('/api/rest/favoritesj/find')
    .timeout({ deadline: 19000 })
    .send(data)
    .end();

export const resetFavorites = (elementId, step, isPrescription) => {
  request
    .get(`/api/rest/favoritesj/hardReset/${elementId}`)
    .query({ step })
    .query({ isPrescription })
    .end();
};

export const mergeElements = data =>
  request.post('/api/rest/favoritesj/merge')
    .send(data)
    .end();


export const checkExistingChart = appointmentId =>
  request.get(`/api/rest/chartings/drchrono/${appointmentId}`);

export const chartingsDetailsUpdateSystem = data =>
  request
    .post('/api/rest/chartings/details/update/system')
    .send(data)
    .end();
