import React from 'react';

import { useFormik } from 'formik';

import RadioButtonGroup from 'components/RadioButtonGroup';

import { buildSchema } from 'helpers/validationRules';

import { register } from 'api/administrator';

import cx from './Clinic.module.scss';

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  userEmail: '',
  clinicName: null,
  clinicEmail: '',
  seatId: 6,
  billingPlanCost: 100
};

export const Clinic = () => {
  const submit = (info) => {
    const data = {
      login: info.userEmail,
      password: info.password,
      firstName: info.firstName,
      lastName: info.lastName,
      email: info.clinicEmail,
      roleId: 2,
      billingPlanId: info.seatId,
      permissions: [1,2,3,4,5,6,7,8,9,10],
      clinicName: info.clinicName,
      billingPlanCost: Number(info.billingPlanCost)
    };
    register(data);
  };

  const resolveSeatIdForServer = {
    0: null,
    6: 6,
    7: 7,
  };

  const formik = useFormik({
    initialValues: {
        ...initialValues
    },
    validationSchema: buildSchema({
      firstName: 'required',
      lastName: 'required',
      password: 'passwordValidation',
      userEmail: 'requiredEmail',
      clinicEmail: 'requiredEmail',
    }),
    onSubmit: async (values, { resetForm }) => {
      const toServer = { ...values, seatId: resolveSeatIdForServer[values.seatId] };
      await submit(toServer);
      resetForm({ values: { ...initialValues } });
    }
  });

  return (
    <form
      className={cx.form}
      onSubmit={formik.handleSubmit}
    >
      <div className={cx.container}>
        <div className={cx.row}>
          <h3>User info</h3>
          <span>first&nbsp;name</span>
          <div className="validated-field">
            <input
              value={formik.values.firstName}
              name="firstName"
              className={cx.input}
              onChange={formik.handleChange}
            />
          {formik.errors.firstName && (
            <span className="validation-error">{formik.errors.firstName}</span>
          )}
          </div>

          <span>last&nbsp;name</span>
          <div className="validated-field">
            <input
              value={formik.values.lastName}
              name="lastName"
              className={cx.input}
              onChange={formik.handleChange}
            />
            {formik.errors.lastName && (
              <span className="validation-error">{formik.errors.lastName}</span>
            )}
          </div>

          <span>email</span>
          <div className="validated-field">
            <input
              name="userEmail"
              className={cx.input}
              onChange={formik.handleChange}
            />
            {formik.errors.userEmail && (
              <span className="validation-error">{formik.errors.userEmail}</span>
            )}
          </div>

          <span>password</span>
          <div className="validated-field">
            <input
              value={formik.values.password}
              type="password"
              name="password"
              className={cx.input}
              onChange={formik.handleChange}
            />
            {formik.errors.password && (
              <span className="validation-error">{formik.errors.password}</span>
            )}
          </div>
        </div>

        <div className={cx.row}>
          <h3>Clinic info</h3>
          <span>email</span>
          <div className="validated-field">
            <input
              value={formik.values.clinicEmail}
              name="clinicEmail"
              className={cx.input}
              onChange={formik.handleChange}
            />
            {formik.errors.clinicEmail && (
              <span className="validation-error">{formik.errors.clinicEmail}</span>
            )}
          </div>

          <span>clinic name</span>
          <input
            value={formik.values.clinicName || ''}
            placeholder='EHRsynergy, LLC'
            name="clinicName"
            className={cx.input}
            onChange={formik.handleChange}
          />
        </div>

        <div className={cx.row}>
          <h3>Seat</h3>
          <RadioButtonGroup
            name="seatId"
            onChange={value => formik.setFieldValue('seatId', value)}
            items={[{
              name: 'Vip',
              value: 6,
            },{
              name: 'User is buy self',
              value: 0,
            }, {
              name: 'Pro 1',
              value: 7,
            }]}
            checked={formik.values.seatId}
          />
          <span>Cost per seat</span>
          <input
            value={formik.values.billingPlanCost} 
            name='billingPlanCost'
            className={cx.input}
            onChange={formik.handleChange}
          />
        </div>
        </div>

        <div className={cx['button-container']} >
          <button
            type="submit"
            className="generic-button"
          >
            Save
          </button>
        </div>
    </form>
  );
};
