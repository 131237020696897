export const chartingElementServerPropsWhiteList = [
  'id',
  'ids',
  'type',
  'subIds',
  'favoriteId'
];

export const chartingElementForSaveChart = [
  ...chartingElementServerPropsWhiteList,
  'subElementData',
  'subElementFavoriteData'
];

export const chartingElementForDrChronoServerPropsWhiteList = [
  ...chartingElementForSaveChart,
  'name',
];

export const whiteListPropsForContainersParam = [
  'id',
  'charts',
];

export const rvSystemUpdateServerPropsWhiteList = [
  'id',
  'name',
  'chartingId',
  'order',
  'chartId',
];

export const whiteListPropsForContainersParamForSaveChart = [
  'id',
  'charts',
  'chartingId',
  'name'
];

export const whiteListPropsForUpdateChartSubElementRequest = [
  'id',
  'name',
  'description',
  'elementId',
];

export const whiteListPropsForUpdateSocialSubElementRequest = [
  'id',
  'name',
  'description',
  'elementId',
  'numberGroup',
  'position',
];

export const whitePropsForCretingElementRequest = [
  'chartingId',
  'name',
  'systemId',
  'chartId',
  'elementIdOld',
];

export const updateSocialSubElementParams = [
  'id',
  'name',
  'patientId',
  'elementId',
];

export const chartSubElementCreate = [
  'chartId',
  'chartingId',
  'elementId',
  'name',
  'setNumber',
  'systemId',
];
