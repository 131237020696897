import React from 'react';
import PropTypes from 'prop-types';

import cx from './DatePickerTitle.module.scss';

const DatePickerTitle = (props) => {
  const {
    title,
  } = props;
  return (
    <div>
      {title && (
        <h3 className={cx['title']}>{title}</h3>
      )}
    </div>
  );
};

DatePickerTitle.propTypes = {
  title: PropTypes.string,
};

export default DatePickerTitle;
