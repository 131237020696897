import * as constants from './constants';

import * as userApi from 'api/user';

export const fetchClinics = userId => ({
  types: [
    constants.CLINICS_FETCH,
    constants.CLINICS_FETCH_SUCCESS,
    constants.CLINICS_FETCH_FAIL,
  ],
  promise: () => userApi.getUserClinics(userId),
});
