import { connect } from 'react-redux';
import { getDeletedIllness } from 'modules/med-select/selectors';
import { addToDeleted } from 'modules/med-select/actions';

const mapStateToProps = state => ({
  deletedItems: getDeletedIllness(state)
})

const mapDispatchToProps = {
  addToDeleted
}

export default component => connect(mapStateToProps, mapDispatchToProps)(component)
