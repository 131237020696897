import { handleActions } from 'redux-actions';
import * as constants from '../constants';

import keyBy from 'lodash/keyBy';

const initialState = {};

export default handleActions({

  [constants.ROLES_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result, 'id'),
  }),

  [constants.ROLE_REMOVE_SUCCESS]: (state, action) => {
    const newState = { ...state };

    delete newState[action.payload.id];

    return newState;
  },

  [constants.ROLE_CREATE_SUCCESS]: (state, action) => {
    const { id } = action.payload.result;

    return {
      ...state,
      [id]: {
        id,
        ...action.payload.role,
      },
    };
  },

  [constants.ROLE_UPDATE_SUCCESS]: (state, action) => {
    const { id } = action.payload.result;

    return {
      ...state,
      [id]: {
        id,
        ...action.payload.role,
      },
    };
  },

}, initialState);
