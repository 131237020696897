import React from 'react';

import { withRouter } from 'containers/RouterParams';

import ChartReportTable from '../../ChartReportTable';
import ChartReportAllergy from './ChartReportAllergy';

import { sortAllergiesForProgressNote } from 'helpers/fmss/allergies/allergies.js';

import cx from './ChartReportAllergy/ChartReportAllergy.module.scss';

const headers = [
  {
    title: 'Allergy',
  },
  {
    title: 'Reaction',
  },
];

const ChartReportAllergies = (props) => {
  const {
    allergies = [],
    toggleChartReport,
    chartId,
    deleteAllergy,
    location,
    viewMode,
    hasNoDrugAllergies,
  } = props;

  if (!allergies.length && hasNoDrugAllergies) return <div className={cx['no-known-drug-allergies']}>No known drug allergies</div>;

  if (!allergies.length) return <div>No active allergies</div>;

  const currentAllergy = location.query.allergy && parseInt(location.query.allergy);

  const sortedAllergies = sortAllergiesForProgressNote(allergies);

  return (
    <ChartReportTable
      headers={headers}
    >
      {sortedAllergies.map(allergy => (
        <ChartReportAllergy
          key={allergy.id}
          allergy={allergy}
          toggleChartReport={toggleChartReport}
          onRemove={() => deleteAllergy(
              allergy,
              currentAllergy === allergy.id && location.pathname
            )}
          chartId={chartId}
          viewMode={viewMode}
        />
        ))}
    </ChartReportTable>
  );
};

export default withRouter(ChartReportAllergies);
