import { handleActions } from 'redux-actions';

import * as constants from '../constants';

const initialState = {
  isFetching: false,
  isFetched: false,
};

export const reducer = handleActions({
  [constants.CHARTING_ASSETS_FETCH]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [constants.CHARTING_ASSETS_FETCH_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
    isFetched: true,
  }),
  [constants.CHARTING_ASSETS_FETCH_FAIL]: (state) => ({
    ...state,
    isFetching: false,
  }),
}, initialState);

export default (state = initialState, action) => {
  if (action.payload && action.payload.chartId) {
    return state;
  }

  return reducer(state, action);
};
