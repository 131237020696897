import React from 'react';
import PropTypes from 'prop-types';

import CardInfo from 'components/CardInfo';

import * as cardHelpers from 'helpers/card/card.js';

import cx from './RenderBillingInfo.module.scss';

import attachCardIcon from 'images/edit.svg';

const RenderBillingInfo = (props) => {
  const {
    openCardModal,
    creditCard,
  } = props;

  const isDisplayCard = cardHelpers.isCardPresent((creditCard));
  const formattedExpireDate = cardHelpers.getExpirationCardDate(creditCard.expireMonth, creditCard.expireYear);

  return (
    <div className={cx['plan-wrapper']}>
      <div className={cx['plan-header']}>Billing info</div>
      <img className={cx['edit-card']} onClick={openCardModal} src={attachCardIcon} alt="" />

      <CardInfo
        isDisplayCard={isDisplayCard}
      >
        <div className={cx['billing-info']}>
          <p>{creditCard.cardBrand} **** {creditCard.last4}</p>
          <p>EXP:
            <span className={cx['plan-seatSet']}>
              {formattedExpireDate}
            </span>
          </p>
        </div>
      </CardInfo>
    </div>
  );
};

RenderBillingInfo.propTypes = {
  openCardModal: PropTypes.func.isRequired,
};

export default RenderBillingInfo;
