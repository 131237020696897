import isEmpty from 'lodash/isEmpty';

import * as usersConstants from './constants';

export default class UsersRouteParamsManager {
  constructor({ userId, formType, section }) {
    this.formType = formType;
    this.section = section;
    this.userId = userId;
  }

  get getUserId() {
    return this.userId;
  }

  checkLocationParamsForDisplayUser = () => {
    const isUserIdPresent = this.getIsUserPresent();
    const isPermissionsStep = this.isActualSection(usersConstants.USERS_ROUTE_PARAMS.section.permissions);
    const isActualFormType = this.isActualFormType(usersConstants.USERS_ROUTE_PARAMS.formType.add);

    return isActualFormType === true || isUserIdPresent === true || isPermissionsStep === true;
  };

  getIsDisplayUserOnRightSide = () => {
    if (this.isEmptyLocationParams()) {
      return false;
    }

    return this.checkLocationParamsForDisplayUser();
  };

  isEmptyLocationParams = () => {
    const locationParams = this.getLocationParamsObject();

    return isEmpty(locationParams);
  };

  getIsUserPresent = () => {
    return this.userId && this.userId.length > 0;
  };

  isActualSection = (templateSection) => {
    return this.section && this.section === templateSection;
  };

  isActualFormType = (templateFormType) => {
    return this.formType === templateFormType;
  };

  getLocationParamsObject = () => {
    return {
      userId: this.userId,
      formType: this.formType,
      section: this.section,
    };
  }
}
