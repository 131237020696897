import { handleActions } from 'redux-actions';

import { keyBy, omit } from 'lodash';

import elements, { handledActions as elementsHandledActions } from './elements';
import systems, { handledActions as systemsHandledActions } from './systems';

import checkIsSystemFixed from 'helpers/systems/fixedSystems';
import { fixedSystemCheckMapper } from 'helpers/systems/fixedSystems/mappers';
import { CHARTING_ASSETS_FETCH_SUCCESS, ADD_ASSET_SYSTEM } from 'modules/chartingAssets/constants';

const entityReducers = {
  elements,
  systems,
};

// create reducer for elements, systems or sections
const createEntityReducer = (entityName) => {
  return (state, action) => {
    return {
      ...state,
      [entityName]: entityReducers[entityName](state[entityName], action),
    };
  };
};

// create handlers for elements, systems or sections
const createEntityActionsHandlers = (entityName, actionsToHandleArray) => {
  const reducer = createEntityReducer(entityName);

  return actionsToHandleArray.reduce((acc, val) => {
    const result = { ...acc };
    result[val] = reducer;
    return result;
  }, {});
};


const initialState = {
  sections: {},
  systems: {},
  elements: {},
};

const systemsParser = (systemsFromServer) => {
  return keyBy(systemsFromServer.map((system) => {
    const mapFixedSystem = fixedSystemCheckMapper(system);
    const newSystem = {
      ...system,
      fixed: checkIsSystemFixed(mapFixedSystem),
    };
    return omit(newSystem, 'elements');
  }), 'id');
};

export default handleActions({
  [CHARTING_ASSETS_FETCH_SUCCESS]: (state, { payload }) => ({
    ...state,
    sections: {
      ...state.sections,
      ...keyBy(payload.result.sections, 'id'),
    },
    systems: {
      ...state.systems,
      ...systemsParser(payload.result.systems),
    },
    elements: {
      ...state.elements,
      ...keyBy(payload.result.elements, 'id'),
    },
  }),
  [ADD_ASSET_SYSTEM]: (state, { payload }) => ({
    ...state,
    systems: {
      ...state.systems,
      [payload.id]: { ...payload }
    }
  }),
  ...createEntityActionsHandlers('elements', elementsHandledActions),
  ...createEntityActionsHandlers('systems', systemsHandledActions),
}, initialState);
