import React from 'react';
import { shape, number, string, node } from 'prop-types';

import { components } from 'react-select';

import cx from './Input.module.scss';

export const Option = (props) => {
   const { children, data: { value, dob } } = props;
   return (
      <components.Option {...props}>
         <div className={cx.optionContainer}>
            <div>ID: {value}</div>
            <div>{children}</div>
            <div className={cx.dob}>DOB: {dob}</div>
         </div>
      </components.Option>
   );
};

Option.propTypes = {
   children: node.isRequired,
   data: shape({
      value: number,
      label: string
   }).isRequired
};
