import { NavLink } from 'react-router-dom';

export const MenuLink = ({ children, to = '', baseStyles = '', activeLinkStyles = '', indexLink = false }) => {
  return (
    <NavLink
      to={to}
      end={indexLink}
      className={({ isActive }) => `${baseStyles} ${isActive ? activeLinkStyles : ''}`}
    >
      {children}
    </NavLink>
  );
};