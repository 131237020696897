import React from 'react';
import PropTypes from 'prop-types';

import { MenuLink } from 'components/MenuLink';
import TabTitle from 'components/TabTitle';

import cx from './Header.module.scss';

const Header = ({
                  links = [],
                  children
                }) => (
  <div className={cx.wrapper}>
    <div className={cx.links}>
      {links.filter(link => !link.disabled).map(
        (link) =>
          <MenuLink
            key={link.name}
            baseStyles={cx.link}
            activeLinkStyles={cx['link--active']}
            to={link.url}
            indexLink={link.indexLink}
          >
            <TabTitle>
              {link.name}
            </TabTitle>
          </MenuLink>
      )}
    </div>
    {' '}
    {children}
  </div>
);

Header.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  children: PropTypes.node,
};

export default Header;
