import { connect } from 'react-redux';

import flatten from 'lodash/flatten';

import * as socialSystemsActions from 'modules/socialSystems/actions';
import * as socialSessionsActions from 'modules/socialSessions/actions';
import * as socialElementsActions from 'modules/socialElements/actions';
import { addIndexToAutoSet, resetIndexToAutoSet } from 'modules/autoOpenSubElementsModal/actions';
import { createFreeText, deleteFreeText, updateFreeText } from 'modules/socialDetails';

import * as socialSystemsSelectors from 'modules/socialSystems/selectors';
import * as socialSessionsSelectors from 'modules/socialSessions/selectors';
import { getElementsBySystem } from 'modules/socialElements/selectors';
import { getPatientById } from 'modules/patients/selectors';
import { freeTextForAllSystem } from 'modules/socialDetails/selectors';

import stringParser from 'helpers/common/string/string-parser';

const mapStateToProps = (state, ownProps) => {
  // if active is true we should pass only active systems further
  // else se should pass all systems
  const {
    active,
    activeSystem,
    patientId
  } = ownProps;

  const allSystems = socialSystemsSelectors.getSystemsList(state.socialSystems);

  let items = allSystems;
  const enclosedElements = {};

  const activeSystemsIds = socialSessionsSelectors.getSocialSessionSystems(state.socialSessions, patientId).map(o => stringParser(o));

  // we need session to send changes to server
  const session = socialSessionsSelectors.getSocialSession(state.socialSessions, patientId);

  const flattenedSession = flatten(Object.values(session));

  // if we show active system we need enclosedElements
  if (active) {
    items = activeSystemsIds.map(systemId => allSystems.find(o => o.id === systemId));
    Object.keys(session).forEach((systemId) => {
      enclosedElements[systemId] = session[systemId];
    });
  }

  let activeItems = [];

  // if we show all systems we have to filter out active systems
  if (!active) {
    activeItems = activeSystemsIds;
  }

  const isSessionFetching = socialSessionsSelectors.getIsSessionFetching(state.socialSessions, patientId);

  let searchableItems = [];
  let allElements = [];

  if (active && activeSystem) {
    allElements = getElementsBySystem(state.socialElements, activeSystem);
    searchableItems = allElements.filter(item => enclosedElements[activeSystem] && !enclosedElements[activeSystem].map(o => o.id).includes(item.id));
  }

  return {
    isFetching: socialSystemsSelectors.getIsFetching(state.socialSystems),
    isUpdating: socialSystemsSelectors.getIsUpdating(state.socialSystems),
    isSessionFetching,
    items,
    activeSystem,
    patientId,
    enclosedElements,
    searchableItems,
    allElements,
    flattenedSession,
    activeItems,
    patient: getPatientById(state.patients, patientId),
    systems: state.socialSystems,
    listOfAutoOpenSubelementsIndexes: state.autoOpenSubElementsModal,
    freeTextForAllSystem: freeTextForAllSystem(state)
  };
};

const mapActionCreators = {
  ...socialSystemsActions,
  ...socialSessionsActions,
  ...socialElementsActions,
  addIndexToAutoSet,
  resetIndexToAutoSet,
  removeElements: socialSessionsActions.removeElementsWrapper,
  createFreeText,
  updateFreeText,
  deleteFreeText
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
