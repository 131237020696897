export default [
  {
    title: 'patient id',
    subtitle: 'Number',
    sortable: true,
    width: '14%',
  },
  {
    title: 'first',
    subtitle: 'Name',
    sortable: true,
    width: '16%',
  },
  {
    title: 'last',
    subtitle: 'Name',
    sortable: true,
    width: '16%',
  },
  {
    title: 'dob',
    subtitle: 'Mm/dd/yyyy',
    sortable: true,
    width: '15%',
  },
  {
    title: 'phone',
    subtitle: 'Number',
    sortable: true,
    width: '17%',
  },
  {
    title: 'last encounter',
    subtitle: 'Date',
    sortable: true,
    width: '22%',
  },
];
