import { connect } from 'react-redux';

import * as selectors from 'modules/pastdue/selectors.js';
import { getUserId } from 'modules/user/selectors.js';
import * as seatsSelectors from 'modules/seats/selectors.js';

const mapStateToProps = (state) => {
  return {
    pastdue: selectors.getPastDue(state),
    currentUserId: getUserId(state.user),
    seats: seatsSelectors.getSeats(state.seats),
  };
};

export default component => connect(mapStateToProps, null)(component);
