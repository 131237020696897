import { formatAllergyForServer } from 'helpers/fmss/allergies/allergies';

const request = require('superagent-promise')(require('superagent'), Promise);

// get allergies by patient (active or inactive)
export const fetchAllergies = (patientId, filter) => {
  return request.get(`/api/rest/allergy/patient/${filter}/${patientId}`)
    .end();
};

export const fetchAllergy = allergyId =>
  request.get(`/api/rest/allergy/${allergyId}`)
    .end();

// allergies search (for select)
export const searchAllergy = searchRequest =>
  request.post('/api/rest/allergies/search')
    .send({ searchRequest })
    .end();

// allergies create (for select)
export const newAllergy = allergy =>
  request.post('/api/rest/allergies/create')
    .send(allergy)
    .end();

// drug allergies search
export const searchDrug = searchRequest =>
  request.post('/api/rest/allergies/drug/search')
    .send({ searchRequest })
    .end();


export const findOrCreateAllergyByName = searchRequest =>
  request.post('/api/rest/allergies/drug/findOrCreate')
    .send({ searchRequest })
    .end();

// environment allergies search
export const searchEnvironment = searchRequest =>
  request.post('/api/rest/allergies/environment/search')
    .send({ searchRequest })
    .end();

export const deleteMedAllergy = (id) =>
  request
    .del(`/api/rest/allergies/${id}`)
    .end();

// reactions search
export const searchReactions = searchRequest =>
  request.post('/api/rest/reaction/search')
    .send({ searchRequest })
    .end();

export const deleteMedReaction = (id) =>
  request
    .del(`/api/rest/reaction/${id}`)
    .end();

export const createAllergy = allergy =>
  request.post('/api/rest/allergy/create')
    .send(formatAllergyForServer(allergy))
    .end();

export const updateAllergy = allergy =>
  request.post('/api/rest/allergy/update')
    .send(formatAllergyForServer(allergy))
    .end();

export const deleteAllergy = allergyId =>
  request.post('/api/rest/allergy/delete')
    .send({ id: allergyId })
    .end();

export const createReaction = name =>
  request.post('/api/rest/reaction/create')
    .send({ name })
    .end();
