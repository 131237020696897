import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import PageHeader from '../PageHeaderContainer';
import SidebarContainer from '../SidebarContainer';
import SessionTimer from '../SessionTimer';
import PastDueLayer from 'containers/PastDueLayer/PastDueLayer';
import DrChronoFetchToken from 'containers/DrChrono/DrChronoFetchToken';
import AppContainer from 'containers/App/AppContainer';
import { AppContexProvidertWrapper } from 'containers/App/AppContext/AppContextProvider';

import MainMenu from '../../components/MainMenu';
import PageContainer from '../../components/PageContainer';
import PaymentNotification from 'components/PaymentNotification';
import DropShading from 'components/DropShading';
import AgreementModal from 'components/AgreementModal';
import Loader from 'components/Loader';
import PinPage from '../../components/PinPage';
import SeatsPresenceValidator from 'components/SeatsPresenceValidator';
import EmbeddedLayout from 'components/EmbeddedLayout';
import InfoModal from 'components/InfoModal';
import SeatPaymentConfirm from 'components/SeatPaymentConfirm';
import SockJsClient from 'components/SockJsClient';

import cx from './App.module.scss';

const isIpad = RegExp(/iPad/i).exec(navigator.userAgent) != null;

const App = (props) => {
  const {
    isSidebarActive = false,
    toggleSidebar,
    locked = false,
    unlock,
    lockProcessing = false,
    unlockError = false,
    userInfo = {},
    pastdue,
    isAuthenticated = false,
    isBlankClinic
  } = props;

  const { showPaymentWarning } = pastdue;

  // if we use desktop then root class will have height : 100vh
  // if we use ipad parent will have calculated height, so we should use 100%
  const style = isIpad ? { height: '100%' } : null;

  if (!isAuthenticated || isBlankClinic) {
    return (
      <div className={cx.wrapper}>
        <Outlet />
      </div>
    );
  }

  if (!userInfo.id) {
    return <Loader />;
  }

  return (
    <AppContexProvidertWrapper>
      <SockJsClient />
      <div
        className={cx.root}
        style={style}
      >
        <PastDueLayer />
        <SeatsPresenceValidator
          isAgreementAccepted={userInfo.agreementAccepted}
        />
        <SessionTimer />
        {locked && (
        <PinPage
          onUnlock={unlock}
          lockProcessing={lockProcessing}
          unlockError={unlockError}
        />
      )}
        {!locked &&
        <EmbeddedLayout>
          <PageHeader />
        </EmbeddedLayout>
      }
        {!locked && (
        <div className={cx.page}>
          <EmbeddedLayout>
            <SidebarContainer>
              <MainMenu
                toggleSidebar={toggleSidebar}
              />
            </SidebarContainer>
          </EmbeddedLayout>
          <PageContainer
            isSidebarActive={isSidebarActive}
          >
            <DropShading
              pastdue={pastdue}
            />
            <div
              style={{
                height: (showPaymentWarning && 'calc(100% - 62px)') || '100%',
              }}
            >
              <Outlet />
            </div>
          </PageContainer>
          {showPaymentWarning && (
            <PaymentNotification
              pastdue={pastdue}
            />
          )}
        </div>
      )}
        <AgreementModal />
        <DrChronoFetchToken />
        <InfoModal />
        <SeatPaymentConfirm />
        <ToastContainer />
      </div>
    </AppContexProvidertWrapper>
  );
};

App.propTypes = {
  isBlankClinic: PropTypes.bool.isRequired,
  isSidebarActive: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    providerId: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    agreementAccepted: PropTypes.bool,
  }),
  locked: PropTypes.bool,
  unlock: PropTypes.func.isRequired,
  lockProcessing: PropTypes.bool,
  unlockError: PropTypes.bool,
  pastdue: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
};

const Wrapper = compose(
  AppContainer,
)(App);

export default Wrapper;
