import { handleActions } from 'redux-actions';

import keyBy from 'lodash/keyBy';

import { isNullOrUndefined } from 'helpers';

import {
  PATIENTS_SEARCH,
  PATIENTS_SEARCH_SUCCESS,
  PATIENTS_SEARCH_FAIL,
  PATIENTS_SEARCH_QUERY_CLEAR,
  SEARCH_TAB_PATIENTS_FETCH_SUCCESS,
  FETCH_PATIENT_IMAGE_SUCCESS,
  PATIENT_FETCH_SUCCESS
} from '../constants';
import { CHART_FETCH_SUCCESS } from 'modules/charts/constants';

const initialState = {
  q: null,
  patientsIds: [],
  patientsWithTrueOrder: [],
  patients: [],
  default: [],
};

/**
 *
 * @param state
 * @param patientId : number
 * @param newPatientState : object
 */
const insertPatient = ({ state, patientId, newPatientState }) => ({
  ...state.patients,
  [patientId]: newPatientState
})

/**
 *
 * @param state
 * @param patientId : number
 * @param newPatientState : object
 */
const updatePatientById = ({ state, patientId, newPatientState }) => ({
  ...state.patients,
  [patientId]: {
    ...state.patients[patientId],
    ...newPatientState
  }
})

const updatePatients = ({ state, patientsForAdding }) => ({
  ...state.patients,
  ...patientsForAdding
})

export default handleActions({
  [PATIENTS_SEARCH]: (state, action) => ({
    ...state,
    q: action.payload.q,
  }),
  [PATIENTS_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    q: action.payload.q,
    patients: updatePatients({ state, patientsForAdding: keyBy(action.payload.result, 'id') }),
    patientsWithTrueOrder: [
      ...action.payload.result,
    ],
  }),
  [PATIENTS_SEARCH_FAIL]: (state, action) => ({
    q: action.payload.q,
  }),
  [PATIENTS_SEARCH_QUERY_CLEAR]: (state) => ({
    ...state,
    q: '',
  }),
  [SEARCH_TAB_PATIENTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    patients: updatePatients({ state, patientsForAdding: keyBy(action.payload.result, 'id') }),
    default: [
      ...action.payload.result,
    ],
  }),
  [FETCH_PATIENT_IMAGE_SUCCESS]: (state, action) => {
    const {
      result,
      patientId,
    } = action.payload;

    let imageUrl = null;
    if (!isNullOrUndefined(result)) {
      imageUrl = URL.createObjectURL(result);
    }

    const changePatientImageURL = patient => patient.id === patientId ? { ...patient, imageUrl } : patient

    const newDefaults = state.default.map(changePatientImageURL)
    const newPatientsWithTrueOrder = state.patientsWithTrueOrder.map(changePatientImageURL)

    return {
      ...state,
      default: newDefaults,
      patients: updatePatientById({
        state,
        patientId,
        newPatientState: { imageUrl }
      }),
      patientsWithTrueOrder: newPatientsWithTrueOrder
    };
  },
  [PATIENT_FETCH_SUCCESS]: (state, action)  => {
    const { [action.payload.result.id]: existedPatient } = state.patients
    return isNullOrUndefined(existedPatient)
      ? {
        ...state,
        patients: insertPatient({
          state,
          patientId: action.payload.result.id,
          newPatientState: action.payload.result
        }),
      } : state
  },
  [CHART_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      patients: updatePatients({ state, patientsForAdding: keyBy(action.payload.result, 'id') }),
    }
  }
}, initialState);
