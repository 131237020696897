import isEmpty from 'helpers/common/array/is-empty';
import { addNewProperty, isNullOrUndefined } from 'helpers';

export default class NoNullSubElementsFilter {
  static getElements = (elements) => {
    const elementsWithNoNullSubElements = [];

    elements.forEach((element) => {
      const { subIds } = element;

      if (isEmpty(subIds)) {
        elementsWithNoNullSubElements.push(element);
      } else {
        const noNullSubElements = subIds.filter(subElement => !isNullOrUndefined(subElement));
        const newElement = addNewProperty('subIds', noNullSubElements, element);

        elementsWithNoNullSubElements.push(newElement);
      }
    });
    return elementsWithNoNullSubElements;
  }
}
