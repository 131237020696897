import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { DATE_FORMATS } from 'helpers/date/constants';

import cx from './Info.module.scss';

const Info = (props) => {
  const {
    patient,
    email,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const formattedDate = timeZoneDateConverter.formattedDob(patient.dob, DATE_FORMATS.DOB);

  return (
    <div className={cx.wrapper}>
      <div className={cx['main-info']}>
        {patient.firstName && patient.lastName &&
        <h3 className={cx.name}>{`${patient.firstName} ${patient.lastName}`}</h3>}
        {patient.id && <p className={cx.id}>(id {patient.id})</p>}
        {patient.dob && <p className={cx.dob}>dob: {formattedDate}</p>}
      </div>

      <div className={cx['secondary-info']}>
        {email && (
          <p className={cx.email}>
            <a className={cx['email-link']} href={`mailto:${email}`}>{email}</a>
          </p>
        )}
      </div>
    </div>
  );
};

Info.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    dob: PropTypes.number,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default Info;
