import React from 'react';
import cx from './Table.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

const Table = ({
  children,
  color = 'blue',
  bodyColor,
  border,
  className,
  style,
  onTableMount,
}) => (
  <div className={cssClassesResolver([
    cx['viewport'],
    cx[className],
  ])} style={style} ref={ref => ref && onTableMount ? onTableMount(ref) : null}>
    <table
      className={cssClassesResolver([
        color ? cx[`table-${color}`] : cx['table'],
        border && cx['table-with-border'],
        bodyColor && cx[`table-body-${bodyColor}`]
      ])}
    >
      {children}
    </table>
  </div>
);

Table.propTypes = {
  // children: PropTypes.node,
  // color: PropTypes.string,
  // bodyColor: PropTypes.string,
  // border: PropTypes.bool,
  // className: PropTypes.string,
};

export default Table;
