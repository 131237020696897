import React from 'react';

import StaticOptions from 'components/Select/StaticOptions';

const logoutTimeOptions = [
  {
    label: '10 minutes',
    value: 10,
  },
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '20 minutes',
    value: 20,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '45 minutes',
    value: 45,
  },
  {
    label: '1 hour',
    value: 60,
  },
  {
    label: '3 hours',
    value: 180,
  },
  {
    label: '6 hours',
    value: 360,
  },
  {
    label: '8 hours',
    value: 480,
  },
  {
    label: '24 hours',
    value: 1440,
  },
];

const LogoutTimeSelect = (props) => {
  const { value, onChange, styles } = props
  return (
    <StaticOptions
      value={value}
      options={logoutTimeOptions}
      onChange={onChange}
      styles={styles}
    />
  );
}

export default LogoutTimeSelect;
