const fieldsArr = [
  {
    title: 'encounter',
    subtitle: 'Date',
    sortable: true,
    width: '12%',
  },

  {
    title: 'reason',
    subtitle: 'Description',
    width: '25%',
    sortable: true,
  },
  {
    title: 'patient',
    subtitle: 'Name/DOB',
    sortable: false,
    width: '17%',
  },

  {
    title: 'provider',
    subtitle: 'Initials',
    sortable: true,
    width: '6%',
  },
  {
    title: 'note type',
    subtitle: 'Description',
    sortable: true,
    width: '19%',

  },
  {
    title: 'status',
    subtitle: 'Completion',
    sortable: true,
    width: '10%',
    height: '70px',
  },

  {
    title: 'actions',
    subtitle: 'Type',
    width: '15%',
  },
];

// temporarily disable sorting ability
// fieldsArr.map(field => field.sortable = false);

export default fieldsArr;
