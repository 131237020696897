import React from 'react';

import cx from './SimpleSelect/SimpleSelect.module.scss'

const SearchIcon = ({ onClick }) => {
  return (
      <div
        onClick={onClick}
        className={cx['search-icon__container']}>
        <span
          className={cx['search-icon']}
        />
      </div>
  )
}

export default SearchIcon
