import isEmpty from 'helpers/common/array/is-empty';
import deleteObjectProperty from 'helpers/common/object/delete-property';

const deleteMultipleProperties = (object, properties) => {
  if (isEmpty(properties)) {
    return object;
  }

  return properties.reduce((oldObject, propertyToDelete) => {
    return deleteObjectProperty(oldObject, propertyToDelete);
  }, object);
};

export default deleteMultipleProperties;
