import { compose } from 'redux';
import socialSystemsContainer from 'containers/FMSS/Social/SocialSystemConnect';
import { fetchLayerHOC } from 'containers/FMSS/Social/SocialSystemsContainer';
import SocialActiveSystems from 'containers/FMSS/Social/SocialActiveSystems';
import { useOutletContext } from 'react-router-dom';
import UserEditForm from 'components/UserEditForm';
import {
  FMSSBaseWrapper
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/components/FMSSBaseWrapper';

import SocialItems from './components/SocialItems';

const SocialSystemsContainer = compose(
  FMSSBaseWrapper,
  socialSystemsContainer,
  fetchLayerHOC
)(SocialActiveSystems);

export const SocialComponent = () => {
  const { linkBase } = useOutletContext();

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Family Hx', indexLink: true },
        { url: { pathname: `${linkBase}/medical` }, name: 'Medical Hx' },
        { url: { pathname: `${linkBase}/social` }, name: 'Social Hx' },
        { url: { pathname: `${linkBase}/surgical` }, name: 'Surgical Hx' },
        { url: { pathname: `${linkBase}/screening` }, name: 'Screening Hx' },
      ]}
      formComponent={<SocialItems />}
      customLabelRenderer={null}
    >
      <SocialSystemsContainer active searchParamKey={'system'} propName={'activeSystem'} />
    </UserEditForm.Root>
  );
};

export const SocialRoute = {
  path: 'social',
  element: <SocialComponent />,
};
