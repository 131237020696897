import React from 'react';
import { Outlet } from 'react-router-dom';

import TabsHeader from 'components/UserEditForm/Header';

const tabsHead = [
  {
    name: 'BoxCom',
    url: '/app/doctor/settings/addons',
    indexLink: true,
  },
  {
    name: 'DrChrono',
    url: '/app/doctor/settings/addons/drchrono',
  }
];

export const AddonsTab = () => (
  <div className='child-tabs-wrapper'>
    <TabsHeader links={tabsHead} />
    <Outlet />
  </div>
);
