import React from 'react';
import { string, number, func, bool, oneOfType, shape, arrayOf } from 'prop-types';

import orderBy from 'lodash/orderBy';

import Loader from 'components/Loader';
import Chart from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNoteHeader/Charts/Chart';

import { isNullOrUndefined } from 'helpers';

import cx from './Charts.module.scss';

const Charts = ({
                  charts, isFetching, chartId, onCopyPreviousNote, chartPatient
                }) => {
  const availableCharts = orderBy(charts.filter(chart => chart.id !== chartId), ['selectUserDate'], 'desc');

  const fetching = isNullOrUndefined(isFetching) || isFetching;

  return (
    <div className={cx.wrapper}>
      <div className={cx['inner-wrapper']}>
        <div className={cx['scroll-container']}>
          {fetching && <Loader />}
          {!fetching && !!availableCharts.length && (
            <table className={cx.table}>
              <tbody>
              {availableCharts.map(chart => (
                <Chart
                  key={chart.id}
                  chart={chart}
                  patient={chartPatient}
                  onClick={() => onCopyPreviousNote(chart.id)}
                />
              ))}
              </tbody>
            </table>
          )}

          {!fetching && !availableCharts.length && (
            <div className={cx['no-charts']}>No previous notes</div>
          )}
        </div>
      </div>
    </div>
  );
};

Charts.propTypes = {
  charts: arrayOf(
    shape({
      id: number,
    })
  ).isRequired,
  isFetching: bool,
  chartId: oneOfType([string, number]),
  onCopyPreviousNote: func.isRequired,
  chartPatient: shape({
    firstName: string,
    lastName: string,
    gender: string,
    dob: number,
  }).isRequired,
};

export default Charts;
