import React from 'react';

import SettingsItem from './SettingsItem';

import cx from './ProgressNotesSettings.module.scss';

const ProgressNotesSettings = (props) => {
  const getSettingsList = (settingLabels)=> {
    const elementsArr = [];

    const { onClick, settings } = props;

    settingLabels.forEach(({ name, label }) => {
      elementsArr.push(<SettingsItem
        onClick={onClick}
        checked={settings[name]}
        key={name}
        name={name}
        label={label}
      />);
    });

    return elementsArr;
  };

  const { settingLabels, title, titleAlign } = props;

  return (
    <>
      <header className={cx.title} style={{ width: titleAlign === 'right' ? 'auto' : undefined }}>
        {title}
      </header>
      <ul className={cx.list}>
        {getSettingsList(settingLabels)}
      </ul>
    </>
  );
};

ProgressNotesSettings.defaultProps = {
  title: '',
  titleAlign: '',
};

export default ProgressNotesSettings;
