import Container from './Container';
import Header from './Header';
import Wrapper from './Wrapper';
import MedData from './MedData';
import MedDataHeader from './MedDataHeader';
import PatientData from './PatientData';
import EmptyMessage from './EmptyMessage';
import Root from './Root';
import PlanCard from './PlanCard';
import PlanDescription from './PlanDescription';

const UserEditForm = {
  Container,
  Header,
  Wrapper,
  MedData,
  PatientData,
  MedDataHeader,
  EmptyMessage,
  Root,
  PlanCard,
  PlanDescription,
};

export default UserEditForm;
