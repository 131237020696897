import moment from 'moment';

export default class DateTimeSetter {
  static formatTime = (dateInMs, time) => {
    return moment
      .utc(dateInMs)
      .hours(time.hours)
      .minutes(time.min)
      .seconds(time.sec)
      .milliseconds(time.ms);
  };
}
