import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';

import { isNullOrUndefined } from 'helpers';

const UploadImageWrapper = (props) => {
  const {
    fetchImage,
    imageName,
    entityId,
    onChange,
  } = props;

  useEffect(() => {
    let mounted = true
    const loadImage = async (changedImageName) => {
      const response = await fetchImage(changedImageName, entityId);
      if (response.status === 200) {
        const { body } = response;
        if (!isNullOrUndefined(body)) {
          return URL.createObjectURL(body);
        }
      }

      return null;
    };

    const asyncLoadImage = async (changedImageName) => {
      const imageUrl = await loadImage(changedImageName);

      if (mounted && !isNullOrUndefined(imageUrl)) {
        onChange(imageUrl);
      }
    }

    if (!isNullOrUndefined(imageName) && entityId !== 0) {
      asyncLoadImage(imageName)
    }

    return function cleanup() {
      mounted = false
    };
  }, [entityId]);

  const {
    children,
    isDownloadingImage,
  } = props;

  if (isDownloadingImage === true) {
    return <Loader />
  }

  return children;
}

UploadImageWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  fetchImage: PropTypes.func.isRequired,
  imageName: PropTypes.string,
  children: PropTypes.element.isRequired,
  entityId: PropTypes.number,
};

UploadImageWrapper.defaultProps = {
  imageName: null,
  entityId: 0,
};

export default UploadImageWrapper;
