import React from 'react';
import PropTypes from 'prop-types'

import { withRouter } from 'containers/RouterParams';

import ChartReportHeader from './ChartReportHeader';
import ChartReportBody from './ChartReportBody';
import ChartReportFooter from './ChartReportFooter';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './ChartReport.module.scss';

const wrapperStyles = ({ viewMode }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: viewMode && 'inner-wrapper--view'
})

const ChartReport = (props) => {
  const {
    toggleChartReport,
    chart,
    updateChart,
    updateChartExtraData,
    viewMode,
    patient,
    changeSignedStatus,
    settings,
    clinic,
    router,
    showWarning,
  } = props;

  const { unlockSignedProgressNote, removeEhrsynergyLogo } = settings;
  const resolvedStyles = wrapperStyles({ viewMode })

  return (
    <div
      className={resolvedStyles}
      style={{
          paddingTop: viewMode && showWarning && 62,
        }}
    >
      <ChartReportHeader
        chart={chart}
        unlockSignedProgressNote={unlockSignedProgressNote}
        patient={patient}
        settings={settings}
        clinic={clinic}
        toggleChartReport={toggleChartReport}
        updateChartExtraData={updateChartExtraData}
        updateChart={updateChart}
        onBack={router.goBack}
        changeSignedStatus={changeSignedStatus}
      />
      <ChartReportBody
        chart={chart}
        toggleChartReport={toggleChartReport}
        updateChartExtraData={updateChartExtraData}
        updateChart={updateChart}
        patient={patient}
      />
      <ChartReportFooter
        removeEhrsynergyLogo={removeEhrsynergyLogo}
      />
    </div>
  );
};

ChartReport.propTypes = {
  settings: PropTypes.shape({
    unlockSignedProgressNote: PropTypes.bool,
    removeEhrsynergyLogo: PropTypes.bool
  }).isRequired
}

export default withRouter(ChartReport);
