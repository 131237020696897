import { isNullOrUndefined } from 'helpers';

export const urlParams = (search = '') => new URLSearchParams(search);

const formatLinkByType = {
  'string': url => url,
  'object': template => build(template)
};

export const formatLink = (link) => {
  const linkType = typeof link;
  return formatLinkByType[linkType](link);
};

export const build = ({ pathname = '', query = {} }) => {
  if (Object.values(query).length === 0) {
    return pathname;
  }

  let url = `${pathname}?`;
  
  for(const [key, value] of Object.entries(query)) {
    if (isNullOrUndefined(value)) {
      continue;
    }
    url += `${key}=${value}&`;
  }

  return url.slice(0, -1);
};
