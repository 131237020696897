import { formatScreeningForServer } from 'helpers/screening';

const request = require('superagent-promise')(require('superagent'), Promise);

// get screening list by patient
export const fetchScreening = patientId =>
  request.get(`/api/rest/screening/patient/${patientId}`)
    .end();

export const fetchScreeningItem = screeningItemId =>
  request.get(`/api/rest/screening/${screeningItemId}`)
    .end();

export const createScreeningItem = screeningItem =>
  request.post('/api/rest/screening/create')
    .send(formatScreeningForServer(screeningItem))
    .end();

export const updateScreeningItem = screeningItem =>
  request.post('/api/rest/screening/update')
    .send(formatScreeningForServer(screeningItem))
    .end();

export const deleteScreeningItem = screeningItemId =>
  request.del('/api/rest/screening/delete')
    .send({ id: screeningItemId })
    .end();

export const searchScreenings = searchRequest =>
  request.post('/api/rest/screeningDetails/search')
    .send({ searchRequest })
    .end();

export const createScreening = name =>
  request.post('/api/rest/screeningDetails/create')
    .send({ name })
    .end();

export const deleteScreening = id =>
  request
    .post(`/api/rest/screeningDetails/${id}`)
    .end();
