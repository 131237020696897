import React from 'react';

import { useFormik } from 'formik';

import useChooseUser from 'routes/routes/Admin/routes/User/routes/Manager/components/useChooseUser';

import Checkbox from 'components/Checkbox';
import AccessAccountWrapper from 'routes/routes/Admin/routes/User/routes/Manager/components/AccessAccount';
import ChooseUser from 'routes/routes/Admin/routes/User/routes/Manager/components/ChooseUser';

import { isNullOrUndefined } from 'helpers';
import { buildSchema } from 'helpers/validationRules';

import { changePassword, updateUser } from 'api/administrator';

import cx from 'routes/routes/Admin/routes/User/routes/Manager/components/Manager.module.scss';

export const UserManager = () => {
  const {
    selectedUser,
    setSelectedUser,
    setSelectedClinic,
    isNotEmptyUserAndClinic,
    selectedClinic
  } = useChooseUser({});

  const initialValues = {
    password: ''
  };

  const handleChangePasswordResponseByStatus = {
    200: ({ resetForm }) => {
      resetForm({ values: { ...initialValues } });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validationSchema: buildSchema(
      {
        password: 'passwordValidation',
      }
    ),
    onSubmit: async ({ password }, { resetForm }) => {
      const { id } = selectedUser;
      const { status } = await changePassword({ id, password });

      const responseCallBack = handleChangePasswordResponseByStatus[status];
      if (!isNullOrUndefined(responseCallBack)) {
        responseCallBack({ resetForm });
      }
    }
  });

  const updateUserWrapper = () => {
    const { id, agreementAccepted } = selectedUser;
    const { suspend, verifyEmail, value: clinicId } = selectedClinic;

    const data = {
      id,
      clinicId,
      verifyEmail,
      suspend,
      agreementAccepted,
    };

    updateUser(data);
  };

  const setUsersWrapper = (property, value) => {
    const toUpdate = {
      ...selectedUser,
      [property]: value
    };

    setSelectedUser(toUpdate);
  };

  const setClinicWrapper = (property, value) => {
    const toUpdate = {
      ...selectedClinic,
      [property]: value
    };

    setSelectedClinic(toUpdate);
  };

  const onSelectWrapper = ({ internalSelectedUser, internalSelectedClinic }) => {
    setSelectedUser(internalSelectedUser);
    setSelectedClinic(internalSelectedClinic);
  };

  return (
    <div className={cx.container}>
      <ChooseUser
        onSelectUser={onSelectWrapper}
      />
      {isNotEmptyUserAndClinic() === true && (
        <>
          <div className={cx.row}>
            <Checkbox
              styles={{
                padding: '12px'
              }}
              checked={selectedClinic.verifyEmail}
              onClick={() => setClinicWrapper('verifyEmail', !selectedClinic.verifyEmail)}
            >
              Is verify email
            </Checkbox>
            <Checkbox
              styles={{
                padding: '12px'
              }}
              checked={selectedClinic.suspend}
              onClick={() => setClinicWrapper('suspend', !selectedClinic.suspend)}
            >
              Is Suspend
            </Checkbox>
            <Checkbox
              styles={{
                padding: '12px'
              }}
              checked={selectedUser.agreementAccepted}
              onClick={() => setUsersWrapper('agreementAccepted', !selectedUser.agreementAccepted)}
            >
              Is Agreement Accepted
            </Checkbox>
            <button
              type="button"
              className="generic-button"
              onClick={updateUserWrapper}
            >
              Save
            </button>
          </div>
          <form
            className={cx['form-password']}
            onSubmit={formik.handleSubmit}
          >
            <div
              className={cx['password-container']}
            >
              <input
                value={formik.values.password}
                onChange={formik.handleChange}
                type="password"
                name="password"
                className={cx.password}
                placeholder="Change password"
              />
              {formik?.errors?.password && (
                <span className="validation-error">{formik.errors.password}</span>
              )}
            </div>
            <button
              type="submit"
              className={cx['submit-password']}
            >
              Change Password
            </button>
          </form>
          <div >
            <AccessAccountWrapper
              id={selectedUser.id}
              clinicId={selectedClinic.value}
            />
          </div>
        </>
      )}
    </div>
  );
};
