import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import ChartsContainer from 'containers/ChartsContainer';
import PatientsConnect from 'containers/Patients/PatientsConnect';

import ComponentSection from 'components/ComponentSection';
import EncountersTable from 'containers/Encounters/EncountersTable';
import PageEmpty from 'components/PageEmpty';
import ProvidersSelectDashboard from 'components/ProvidersSelect/ProvidersSelectDashboard';

import cx from './Dashboard.module.scss';

class Dashboard extends PureComponent {
  fetchDashboardChartPages = async () => {
    const {
      activeUnsignedPage,
      fetchChartsByPage,
      sortUnsignedBy,
      sortSignedBy,
      isUnsignedAsc,
      isSignedAsc,
      activeProviderId,
      fetchLastChartsWithSorting,
    } = this.props;

    return Promise.all([
      fetchChartsByPage(activeUnsignedPage, sortUnsignedBy, isUnsignedAsc, activeProviderId),
      fetchLastChartsWithSorting(sortSignedBy, isSignedAsc, activeProviderId),
    ]);
  };

  render() {
    const {
      signedCharts,
      unsignedCharts,
      signedPagesCount,
      unsignedPagesCount,
      changeSignedStatus,
      unlockSignedProgressNote,
      deleteEncounters,
      dashboardError,
      deleteChart,
      userInfo,
      fetchChartsByPage,
      areSignedChartsFetching,
      areUnsignedChartsFetching,
      onPageChange,
      encountersType,
      activeSignedPage,
      activeUnsignedPage,
      onSortChange,
      sortUnsignedBy,
      sortSignedBy,
      isUnsignedAsc,
      isSignedAsc,
      activeProviderId,
      fetchLastChartsWithSorting,
      permissions
    } = this.props;

    const {
      viewEditPatientClinicalInformation,
      viewEditEncounterNote,
    } = permissions;

    const isNotGrantDisplayEncounters = !viewEditEncounterNote || !viewEditPatientClinicalInformation;

    const noData = (!signedCharts.length && !unsignedCharts.length) || isNotGrantDisplayEncounters;

    return (
      <div className={cx.wrapper}>
        <div className={cx['select-wrapper']}>
          <ProvidersSelectDashboard
            currentUserProviderId={userInfo.id}
          />
        </div>

        {!noData && !dashboardError && (
          <div className={cx['section-wrapper']}>
            <ComponentSection title="unsigned encounters">
              <EncountersTable
                charts={unsignedCharts}
                isFetching={areUnsignedChartsFetching}
                type="unsigned encounters"
                changeSignedStatus={changeSignedStatus}
                unlockSignedProgressNote={unlockSignedProgressNote}
                deleteEncounters={deleteEncounters}
                deleteChart={deleteChart}
                userInfo={userInfo}
                paginated
                permissions={permissions}
                activeProviderId={activeProviderId}
                pageCount={unsignedPagesCount}
                fetchChartsByPage={fetchChartsByPage}
                signedStatus="unsigned"
                onPageChange={onPageChange}
                onSortToggle={onSortChange}
                encountersType={encountersType}
                activePage={activeUnsignedPage}
                fetchDashboardChartPages={this.fetchDashboardChartPages}
                sortBy={sortUnsignedBy}
                isAsc={isUnsignedAsc}
                fetchLastChartsWithSorting={fetchLastChartsWithSorting}
              />
            </ComponentSection>
          </div>
        )}
        {!noData && !dashboardError && (
          <div className={cx['section-wrapper']}>
            <ComponentSection title="recently signed encounters">
              <EncountersTable
                charts={signedCharts}
                isFetching={areSignedChartsFetching}
                type="signed encounters"
                changeSignedStatus={changeSignedStatus}
                unlockSignedProgressNote={unlockSignedProgressNote}
                deleteEncounters={deleteEncounters}
                deleteChart={deleteChart}
                userInfo={userInfo}
                paginated
                permissions={permissions}
                activeProviderId={activeProviderId}
                pageCount={signedPagesCount}
                fetchChartsByPage={fetchChartsByPage}
                signedStatus="signed"
                onPageChange={onPageChange}
                onSortToggle={onSortChange}
                encountersType={encountersType}
                activePage={activeSignedPage}
                fetchDashboardChartPages={this.fetchDashboardChartPages}
                sortBy={sortSignedBy}
                isAsc={isSignedAsc}
                fetchLastChartsWithSorting={fetchLastChartsWithSorting}
              />
            </ComponentSection>
          </div>
        )}
        {noData && !dashboardError && (
          <PageEmpty
            message="You have no encounters"
          />
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  activeProviderId: PropTypes.number,
};

const DashboardContainer = compose(
  ChartsContainer,
  PatientsConnect,
)(Dashboard);

export default DashboardContainer;
