import { handleActions } from 'redux-actions';

import * as userConstants from '../constants';
import * as authConstants from './constants';

const initialState = {
  isSuperAdmin: false,
  isAuthenticated: false,
  isAuthenticating: false,
  isSigningUp: false,
  loginError: null,
  loginMessage: null,
  signupError: null,
  signupMessage: null,
  confirmError: null,
  confirmMessage: null,
  recoverError: null,
  recoverMessage: null,
  recoveryTokenValidationError: null,
  recoveryTokenValidationMessage: null,
};

const clearErrors = state => ({
  ...state,
  loginError: null,
  loginMessage: null,
  signupError: null,
  signupMessage: null,
  confirmError: null,
  confirmMessage: null,
  recoverError: null,
  recoverMessage: null,
  recoveryTokenValidationError: null,
  recoveryTokenValidationMessage: null,
});

export default handleActions({
  [userConstants.USER_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticated: true,
    isSuperAdmin: action.payload.result.superAdmin
  }),

  [userConstants.USER_FETCH_FAIL]: (state, action) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: false,
  }),

  [authConstants.USER_LOGIN]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticated: false,
    isAuthenticating: true,
  }),

  [authConstants.USER_LOGIN_SUCCESS]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    // isAuthenticated: true,
    isAuthenticating: false,
  }),

  [authConstants.SET_USER_CLINIC]: (state, action) => ({
    ...state,
    isAuthenticating: true,
  }),

  [authConstants.SET_USER_CLINIC_SUCCESS]: (state, action) => ({
    ...state,
    isAuthenticated: true,
    isAuthenticating: false,
  }),

  [authConstants.SET_USER_CLINIC_FAIL]: (state, action) => ({
    ...state,
    isAuthenticating: false,
  }),

  [authConstants.USER_LOGIN_FAIL]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticated: false,
    isAuthenticating: false,
    loginError: !!action.error,
    loginMessage: 'Incorrect username or password',
  }),
  [authConstants.USER_LOGOUT]: (state, action) => ({
    ...state,
    isAuthenticated: false,
  }),

  [authConstants.USER_LOGOUT_SUCCESS]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticated: false,
    isAuthenticating: false,
  }),

  [authConstants.USER_SIGNUP]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticated: false,
    isAuthenticating: true,
  }),

  [authConstants.USER_SIGNUP_SUCCESS]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticating: false,
    isAuthenticated: false,
    loginMessage: 'Signed up successfully! Check your inbox for confirmation message.',
  }),

  [authConstants.USER_SIGNUP_FAIL]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    isAuthenticated: false,
    isAuthenticating: false,
    signupError: !!action.error,
    signupMessage: action.error.status === 409 ? 'User exists' : 'Registration failed',
  }),

  [authConstants.USER_EMAIL_CONFIRM]: (state, action) => state,
  [authConstants.USER_EMAIL_CONFIRM_SUCCESS]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    confirmError: false,
    confirmMessage: 'Email confirmed',
  }),

  [authConstants.USER_EMAIL_CONFIRM_FAIL]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    confirmError: true,
    confirmMessage: 'Confirmation failed',
  }),

  [authConstants.USER_PASSWORD_RECOVER_SUCCESS]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    recoverError: false,
    recoverMessage: 'Password changed!',
  }),

  [authConstants.PASSWORD_RECOVERY_TOKEN_VALIDATE]: (state, action) => ({
    ...state,
    ...clearErrors(state),
  }),

  [authConstants.PASSWORD_RECOVERY_TOKEN_VALIDATE_FAIL]: (state, action) => ({
    ...state,
    ...clearErrors(state),
    recoveryTokenValidationError: true,
    recoveryTokenValidationMessage: 'Your token is expired or invalid',
  }),
}, initialState);
