import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ChartContext } from 'routes/routes/App/routes/Charts/components/Chart/context';

import ElementsViewItems from 'components/ElementsViewItems';
import { elementsViewItemActionsModalData } from 'components/ElementsViewItemActions/data';

const RightItemsWrapper = (props) => {
  const {
    addSystem,
    chartId,
    addElement,
    saveChart,
    addRightSideElementCallback,
  } = props;

  const {
    createSubelementProcess,
  } = useContext(ChartContext);

  const addSystemWrapper = systemId => addSystem(chartId, systemId);

  const addElementWrapper = (systemId, element) =>
    addElement(chartId, systemId, element);

  return (
    <ElementsViewItems
      {...props}
      addSystem={addSystemWrapper}
      addElement={addElementWrapper}
      saveChart={saveChart}
      createSubelement={createSubelementProcess}
      selectSubElementCallback={addRightSideElementCallback}
      editMode={{
        actions: [
          elementsViewItemActionsModalData.mergeElements.id,
          elementsViewItemActionsModalData.removeElement.id,
          elementsViewItemActionsModalData.resetFavorite.id,
        ]
      }}
    />
  );
};

RightItemsWrapper.propTypes = {
  addSystem: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  addRightSideElementCallback: PropTypes.func.isRequired,
  chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  saveChart: PropTypes.func.isRequired,
};

export default RightItemsWrapper;
