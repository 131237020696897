export const FETCH_CLINIC_INFO = 'FETCH_CLINIC_INFO';
export const FETCH_CLINIC_INFO_SUCCESS = 'FETCH_CLINIC_INFO_SUCCESS';
export const FETCH_CLINIC_INFO_FAIL = 'FETCH_CLINIC_INFO_FAIL';

export const UPDATE_CLINIC_INFO = 'UPDATE_CLINIC_INFO';
export const UPDATE_CLINIC_INFO_SUCCESS = 'UPDATE_CLINIC_INFO_SUCCESS';
export const UPDATE_CLINIC_INFO_FAIL = 'UPDATE_CLINIC_INFO_FAIL';

export const FETCH_CLINIC_IMAGE = 'FETCH_CLINIC_IMAGE';
export const FETCH_CLINIC_IMAGE_SUCCESS = 'FETCH_CLINIC_IMAGE_SUCCESS';
export const FETCH_CLINIC_IMAGE_FAIL = 'FETCH_CLINIC_IMAGE_FAIL';
