import React from 'react';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './RadioButtons.module.scss';

const radioButtonsStyles = ({buttonClassName, active, item, title}) => ({
  title: cssClassesResolver([
    'section-title', cx['radiobuttons-title']
  ]),
  item: cssClassesResolver([
    cx.item, cx[buttonClassName],
    active === item?.value && cx['item--active'],
    !title && 'item--notitle',
  ])
})

const RadioButtons = ({
  title,
  items,
  active,
  buttonClassName,
  required,
  onChange,
}) => {
  const handleClick = option => {
    const selectedOption = active === option && !required ? null : option
    onChange(selectedOption)
  }

  return (
    <div className='wrapper'>

      {title && <h3 className={radioButtonsStyles({}).title}>{title}</h3>}
      <div>
        {items.map((item) => (
          <button
            type="button"
            key={item.value}
            className={radioButtonsStyles({title, active, buttonClassName, item}).item}
            onClick={() => handleClick(item.value)}
          >

            {item.name}
          </button>
        ))}
      </div>
    </div>
  )
}

RadioButtons.propTypes = {
  title: PropTypes.string,
  active: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  })),
  buttonClassName: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtons;
