import HasNoPertinentFmssItem from 'helpers/fmss/additional-empty-message/factory/has-no-pertintent-fmss-item';
import HasNoPertinentFmssPNItem from 'helpers/fmss/additional-empty-message/factory/has-no-pertintent-fmss-progress-note-item';

import { FMSS_TYPES } from 'helpers/fmss/constants.js';

export default class EmptyMessageConditionAbstractFactory {
  static getFactory = (conditionType) => {
    switch (conditionType) {
      case FMSS_TYPES.FAMILY:
      case FMSS_TYPES.MEDICAL:
      case FMSS_TYPES.SURGICAL:
        return HasNoPertinentFmssItem;
      case FMSS_TYPES.PROGRESS_NOTE_FAMILY:
      case FMSS_TYPES.PROGRESS_NOTE_MEDICAL:
      case FMSS_TYPES.PROGRESS_NOTE_SURGICAL:
        return HasNoPertinentFmssPNItem;
    }
  }
}
