import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { build } from 'helpers/URLUtils';

import cx from './UserFormLinks.module.scss';

const linkStyles = ({ isActive }) => resolveStylesV2({
  objectStyles:cx,
  moduleStyles: [
    'link',
    isActive && 'link--active'
  ]
});

const defineQueryParams = (userId) => {
  if (userId) {
    return {
      userId,
    };
  }

  return {
    formType: 'add',
  };
};

const UserFormLinks = ({ activeStep, userId }) => {
  const { pathname } = useLocation();
  return (
    <div className={cx.wrapper}>
      <div className={cx['link-container']}>
        <Link
          className={linkStyles({ isActive: activeStep === 'info' })}
          to={build({
            pathname,
            query: defineQueryParams(userId),
          })}
        >
          Info
        </Link>
      </div>
      <div className={cx['link-container']}>
        <Link
          className={linkStyles({ isActive: activeStep === 'permissions' })}
          to={build({
            pathname: pathname,
            query: {
              userId,
              section: 'permissions',
            },
          })}
        >
          Permissions
        </Link>
      </div>
    </div>
  );
};

export default UserFormLinks;
