const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchClinicSeats = () =>
  request
    .get('/api/rest/seat/getAll')
    .end();

export const lockSeat = id =>
  request
    .post('/api/rest/seat/lockSeat')
    .send({ id, status: 'LOCK_FREE' })
    .end();


export const setSeatUser = ({
  userId, seatId, clinicId,
}) =>
  request
    .post('/api/rest/seat/assignedUser')
    .send({
      userId, id: seatId, clinicId,
    })
    .end();

export const setNextSeatUser = ({
  userId, seatId, clinicId,
}) =>
  request
    .post('/api/rest/seat/assignedNextUser')
    .send({
      nextUserId: userId, id: seatId, clinicId,
    })
    .end();

export const removeSeatUser = ({ seatId, clinicId }) =>
  request
    .post('/api/rest/seat/unAssignedUser')
    .send({ id: seatId, clinicId })
    .end();

export const purchaseSeat = (data) => {
  return request
    .post('/api/rest/seat/payment')
    .send({
      ...data,
    })
    .end();
};

export const createSeat = billingPlanId =>
  request
    .post('/api/rest/seat/create')
    .send({ billingPlanId })
    .end();

export const fetchPrice = id =>
  request
    .get(`/api/rest/seat/${id}/cost`)
    .end();

export const deleteSeat = (id) => {
  return request
    .del('/api/rest/seat/delete')
    .send({ id })
    .end();
};

export const paymentAllSeat = (seatIds, amount) =>
  request
    .post('/api/rest/seat/payment/allSeat')
    .send({
      seatIds,
      amount,
    })
    .end();

export const getAllSeatCoast = seatIds =>
  request
    .post('/api/rest/seat/cost/allSeats')
    .send({ seatIds })
    .end();
