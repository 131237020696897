import React from 'react';
import { number } from 'prop-types';

import { useImpersonate } from 'hooks/useImpersonate';

import cx from 'routes/routes/Admin/routes/User/routes/Manager/components/Manager.module.scss';

const AccessAccount = ({ id, clinicId }) => {
  const { login } = useImpersonate();
  const accessAccountWrapper = () => login({ id, clinicId });

  return (
    <button
      type="button"
      className={cx['access-account']}
      onClick={accessAccountWrapper}
    >
      Access Account
    </button>
  );
};

AccessAccount.propTypes = {
  id: number.isRequired,
  clinicId: number.isRequired,
};

export default AccessAccount;
