import { handleActions } from 'redux-actions';
import keyBy from 'lodash/keyBy';

import { isNullOrUndefined } from 'helpers';
import deleteObjectProperty from 'helpers/common/object/delete-property';
import removeItemByValue from 'helpers/common/array/remove-element/by-value';

import * as patientsConstants from '../constants';
import * as chartsConstants from 'modules/charts/constants';


const initialState = {};

export default handleActions({
  [patientsConstants.PATIENTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.map((patient) => {
      const currentPatient = state[patient.id];
      return {
        ...currentPatient,
        ...patient,
      };
    }), 'id'),
  }),

  [patientsConstants.PATIENT_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      ...action.payload.result,
      hasNoPreviousSurgeries: action.payload.result.hasNoPertinentSurgical,
      isFetching: false,
      isUpdating: false,
    },
  }),

  [patientsConstants.PATIENT_FETCH_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
    },
  }),

  [chartsConstants.CHARTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.map((chart) => {
      const currentPatient = state[chart.patientId];
      return {
        ...currentPatient,
        id: chart.patientId,
        firstName: chart.firstName,
        middleName: chart.middleName,
        lastName: chart.lastName,
        dob: chart.patientDob,
      };
    }), 'id'),
  }),

  [chartsConstants.CHART_FETCH_SUCCESS]: (state, action) => {
    if (action.payload.result.status === 204) return state;

    if (Object.hasOwn(state, action.payload.result.patient.id)) {
      return state;
    }

    return {
      ...state,
      [action.payload.result.patient.id]: {
        ...state[action.payload.result.patient.id],
        ...action.payload.result.patient,
        hasNoPreviousSurgeries: action.payload.result.patient.hasNoPertinentSurgical,
      },
    };
  },

  [patientsConstants.FETCH_PATIENT_IMAGE_SUCCESS]: (state, action) => {
    const {
      result,
      patientId,
    } = action.payload;

    let imageUrl = null;
    if (!isNullOrUndefined(result)) {
      imageUrl = URL.createObjectURL(result);
    }

    return {
      ...state,
      [patientId]: {
        ...state[patientId],
        imageUrl,
      },
    };
  },

  [patientsConstants.PATIENT_UPDATE]: (state, action) => ({
    ...state,
    [action.payload.localPatient.id]: {
      ...state[action.payload.localPatient.id],
      isUpdating: true,
      isFetching: false,
    },
  }),

  [patientsConstants.PATIENT_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.patientDTO.id]: {
      ...state[action.payload.localPatient.id],
      ...action.payload.localPatient,
      ...action.payload.result.patientDTO,
      isUpdating: false,
      isFetching: false,
    },
  }),

  [patientsConstants.PATIENT_UPDATE_FAIL]: (state, action) => ({
    ...state,
    [action.payload.localPatient.id]: {
      ...state[action.payload.localPatient.id],
      isUpdating: false,
      isFetching: false,
    },
  }),

  [patientsConstants.PATIENT_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.patientDTO.id]: {
      ...state[action.payload.result],
      ...action.payload.localPatient,
      ...action.payload.result.patientDTO,
      isFetching: false,
      isUpdating: false,
    },
  }),

  [patientsConstants.TOGGLE_DRUG_ALLERGIES]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoDrugAllergies: action.payload.hasNoDrugAllergies,
    },
  }),

  [patientsConstants.TOGGLE_DRUG_ALLERGIES_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoDrugAllergies: !action.payload.hasNoDrugAllergies,
    },
  }),

  [patientsConstants.TOGGLE_PERTINENT_FAMILY_MEDICAL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoPertinentFamily: action.payload.hasNoPertinentFamily,
    },
  }),

  [patientsConstants.TOGGLE_PERTINENT_FAMILY_MEDICAL_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoPertinentFamily: !action.payload.hasNoPertinentFamily,
    },
  }),

  [patientsConstants.TOGGLE_PERTINENT_MEDICAL_HX]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoPertinentMedical: action.payload.hasNoPertinentMedical,
    },
  }),

  [patientsConstants.TOGGLE_PERTINENT_MEDICAL_HX_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoPertinentMedical: !action.payload.hasNoPertinentMedical,
    },
  }),

  [patientsConstants.TOGGLE_PREVIOUS_SURGERIES]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoPreviousSurgeries: action.payload.hasNoPreviousSurgeries,
    },
  }),

  [patientsConstants.TOGGLE_PREVIOUS_SURGERIES_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      hasNoPreviousSurgeries: !action.payload.hasNoPreviousSurgeries,
    },
  }),

  [chartsConstants.CHART_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.localChart.patientId]: {
      ...state[action.payload.localChart.patientId],
      lastEncounter: action.payload.localChart.createdDate,
    },
  }),
  [patientsConstants.PATIENTS_LIST_CONSENTS]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      consents: keyBy(action.payload.consents, 'id'),
      consentIds: action.payload.ids
    }
  }),
  [patientsConstants.ADD_PATIENT_CONSENT]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      consents: {
        ...state[action.payload.patientId]?.consents || {},
        [action.payload.consentId]: action.payload.consent
      },
      consentIds: [
        ...state[action.payload.patientId]?.consentIds || [],
        action.payload.consentId
      ]
    }
  }),
  [patientsConstants.DELETE_PATIENT_CONSENT]: (state, action) => {
    return {
      ...state,
      [action.payload.patientId]: {
        ...state[action.payload.patientId],
        consents: deleteObjectProperty(state[action.payload.patientId]?.consents, action.payload.id),
        consentIds: removeItemByValue(state[action.payload.patientId]?.consentIds, action.payload.id)
      }
    };

  }
}, initialState);
