import React, { useEffect, useState } from 'react';
import { func } from 'prop-types'
import Select from 'react-select';

import UserOption from 'routes/routes/Admin/routes/User/routes/Manager/components/UserOption';

import { isEmpty, isNullOrUndefined } from 'helpers';

import { getClinicsNames, getUsers } from 'api/administrator';

const SearchUserByClinic = ({ onSelect }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState()
  const [clinics, setClinics] = useState()
  const [selectedClinic, setSelectedClinic] = useState()

  const clinicOptionsMapper = clinicsForSelect =>
    clinicsForSelect.map(clinic => ({ value: clinic.id, label: clinic.legalName }))

  useEffect(() => {
    const getClinicsWrapper = async () => {
      const { body } = await getClinicsNames()
      if (!isEmpty(body)) {
        setClinics(clinicOptionsMapper(body))
      }
    }

    getClinicsWrapper()
  }, [])

  const userOptionsMapper = usersForSelect =>
    usersForSelect.map(user => ({ value: user.id, label: user.login, firstName: user.firstName, lastName: user.lastName }))

  const onSelectWrapper = (internalSelectedUser) =>
    onSelect({
      internalSelectedUser,
      internalSelectedClinic: selectedClinic
    })

  const setSelectedClinicWrapper = async selectedOption => {
    const { value } = selectedOption
    setSelectedClinic(selectedOption)
    setSelectedUser(null)
    onSelectWrapper(null)

    const { body } = await getUsers(value)
    setUsers(body)
  }

  const setSelectedUserWrapper = selectedOption => {
    setSelectedUser(selectedOption)

    const { value } = selectedOption
    const rawSelectedUsersToSet = users.filter(iteratedUser => iteratedUser?.id === value)

    onSelectWrapper(rawSelectedUsersToSet[0])
  }

  return (
    <>
      <Select
        id="clinics"
        value={selectedClinic}
        placeholder="Choose clinic"
        onChange={setSelectedClinicWrapper}
        options={clinics}
      />
      {!isNullOrUndefined(selectedClinic) && (
        <Select
          id="users"
          components={{ Option: UserOption }}
          value={selectedUser}
          placeholder="Choose user"
          onChange={setSelectedUserWrapper}
          options={userOptionsMapper(users)}
        />
      )}
    </>
  )
}

SearchUserByClinic.propTypes = {
  onSelect: func.isRequired
}

export default SearchUserByClinic
