import Base from './Base';

export default class ProgressNoteBase extends Base {
  constructor(conditionalValue = false, itemsLength = 0) {
    super(conditionalValue);
    this._itemsLength = itemsLength;
  }

  get itemsLength() {
    return this._itemsLength;
  }
}
