import React from 'react';
import { compose } from 'redux';
import { useParams, useSearchParams } from 'react-router-dom';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import diagnosesContainer, { withDiagnosesFetchLayer } from 'containers/Diagnoses/DiagnosesContainer';
import { DiagnosesList } from 'containers/Diagnoses/DiagnosesList';

const DiagnosesWrapper = compose(
  chartToPatientContainer,
  diagnosesContainer,
  withDiagnosesFetchLayer
)(DiagnosesList);

export const ChartDiagnosesListWrapper = () => {
  const { chartId, filter } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <DiagnosesWrapper
      chartId={chartId}
      filter={filter === 'inactive' ? 'inactive' : 'active'}
      diagnosisId={searchParams.get('diagnosis')}
    />
  );
};
