import { connect } from 'react-redux';

import {
  closeImportModal,
  turnSync,
} from 'modules/drChronoAuth/actions';

import { getImportStatus } from 'modules/drChronoAuth/selectors';

const mapStateToProps = state => ({
  importStatus: getImportStatus(state),
});

const mapDispatchToProps = {
  closeImportModal,
  turnSync,
};

export default component => connect(mapStateToProps, mapDispatchToProps)(component);
