import { Outlet } from 'react-router-dom';
import { useRoute } from 'hooks/useRoute';

import TabsHeader from 'components/UserEditForm/Header';

export const DemographicsTabs = () => {
  const { fromParams } = useRoute();
  const patientId = fromParams('patientId');
  const demographicsTab = {
    name: 'Demographics',
    url: `/app/doctor/patients/${patientId}`,
    indexLink: true
  };
  const formsTab = {
    name: 'Forms',
    url: `/app/doctor/patients/${patientId}/forms`,
    indexLink: true
  };
  const tabsHead = patientId !== 'new'
    ? Array.of(demographicsTab, formsTab)
    : [demographicsTab];

  return (
    <div className='child-tabs-wrapper'>
      <TabsHeader links={tabsHead} />
      <Outlet />
    </div>
  );
};