import DrChronoConnectionInfo from 'helpers/drChrono/DrChronoConnectionInfo';

const notNullError = (response) => {
  const drChronoConnectionInfo = new DrChronoConnectionInfo(response);

  return !drChronoConnectionInfo.isNullErrorDTO();
};

const noShowWarning = () => false;

export default status =>
  (status === 201 ? notNullError : noShowWarning);
