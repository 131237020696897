import { isNullOrUndefined } from 'helpers';
import isEmpty from 'helpers/common/array/is-empty';

export const STRATEGIES = {
  PLAIN: 'plain',
  FAVORITE: 'favorite'
}

const SUB_ELEMENT_TEMPLATE = {
  field: {
    [STRATEGIES.PLAIN]: 'subElementId',
    [STRATEGIES.FAVORITE]: 'subElementFavoriteId'
  },
  id: {
    [STRATEGIES.PLAIN]: 'id',
    [STRATEGIES.FAVORITE]: 'favoriteId'
  }
}

const DEFAULT_STRATEGY = [STRATEGIES.PLAIN]

const getField = (strategy = DEFAULT_STRATEGY) => SUB_ELEMENT_TEMPLATE.field[strategy]

const getId = (strategy = DEFAULT_STRATEGY) => SUB_ELEMENT_TEMPLATE.id[strategy]

export const completeSubElementProps = (strategy) => ({
  subElementField: getField(strategy),
  idFieldName: getId(strategy)
})

/*
* It is used to present plain sub element and sub element as favorite
* */
const collectSubElementData = (subElements, { subElementField, idFieldName } = completeSubElementProps(DEFAULT_STRATEGY)) =>
  (isNullOrUndefined(subElements) || isEmpty(subElements)
    ? []
    : subElements.map((subElement, index) => {
      return {
        position: subElement.position || index + 1,
        [subElementField]: subElement[idFieldName],
      };
    }));

export default collectSubElementData;
