import StrMask from 'string-mask';

export const maskStr = (str, pattern) => {
  const newPattern = pattern.replace(/1/g, '0');
  const formatter = new StrMask(newPattern);
  const result = formatter.apply(str);

  return result;
};

export const unMaskStr = (str) => {
  const res = str.replace(/\(|\)| |-/g, '');

  return res;
};
