import React, { Component } from 'react';
import cx from './Value.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

class Value extends Component {

  calculateStyles = (i) => {
    const {
      error,
      valueLength,
    } = this.props;

    return cssClassesResolver([
      cx['dot'],
      i <= valueLength - 1 && cx['dot--filled'],
      error && cx['dot--error']
    ])
  }

  render() {
    const { maxLength } = this.props;

    const stub = new Array(maxLength).fill(undefined);

    return (
      <div className={cx['wrapper']}>
        {stub.map((o, i) => (
          <div
            key={i}
            className={this.calculateStyles(i)}
          />
        ))}
      </div>
    );
  }
}

export default Value
;
