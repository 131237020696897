import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import cx from './PageFooter.module.scss';

const PageFooter = ({ visibility }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={cx[
      visibility ? 'wrapper' : 'wrapper--hidden'
    ]}
    >
      <div className={cx.copyrights}>&copy; {currentYear} EHRsynergy, LLC</div>
      <div className={cx.footerLinks}>
        <Link to="/app/privacy-policy">Privacy policy</Link>
        <Link to="/app/user-agreement">User Agreement</Link>
        <Link to="/app/terms-of-use">Terms of Use</Link>
        <a href="mailto:info@ehrsynergy.com">Contact Us</a>
      </div>
    </div>
  );
};

PageFooter.propTypes = {
  visibility: PropTypes.bool,
};

export default PageFooter;
