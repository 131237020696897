import React, { useCallback, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import Select from 'react-select';

import { useRoute } from 'hooks/useRoute';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import { ReplaceTemplateOption } from './ReplaceTemplateOption';
import { DeleteModal } from 'components/DeleteModal';
import { SingleValue } from 'components/Consents/SingleValue';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import { DropdownIndicator } from 'components/Consents/DropdownIndicator';
import EventListenerLayer from 'components/EventListenerLayer';

import { deleteConsent, getConsentById, updateConsent } from 'modules/consent';
import stringParser from 'helpers/common/string/string-parser';

import { deleteConsentById, updateConsentById, uploadTemplate } from 'api/consent';

const actions = [
  {
    label: 'Replace',
    value: 'replace'
  },
  {
    label: 'Delete',
    value: 'delete'
  }
];

const selectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 28,
  fontSize: 12,
});

export const ConsentRow = ({ id, onClick }) => {
  const selectRef = useRef(null);
  const replaceOptionRef = useRef(null);
  const consent = useSelector(state => getConsentById(state, id));
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { fromSearchParams } = useRoute();
  const {
    timeZoneDateConverter
  } = useContext(AppContext);
  const onDrop = useCallback((e) => {
    replaceTemplateWrapper(e.target.files);
  }, []);

  const selectedConsentId = stringParser(fromSearchParams('consentId'));

  const closeActions = () => {
    setIsOpen(false);
  };

  if (!consent) {
    return <></>;
  }

  const { name, date } = consent;

  const replaceTemplateWrapper = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    const { text: newTemplateName } = await uploadTemplate(formData);

    const newConsent = { fileName: newTemplateName };
    await updateConsentById({ id, data: newConsent });
    await dispatch(updateConsent({ id, ...consent, fileName: newTemplateName }));
    closeActions();
  };

  const actionDeleteWrapper = async () => {
    await deleteConsentById(id);
    dispatch(deleteConsent(id));
    navigate(pathname);
    closeActions();
  };

  const actionHandler = {
    'delete': () => {
      setIsOpen(false);
      setIsOpenDeleteModal(true);
    },
    'replace': () => {
      replaceOptionRef.current.click();
    }
  };

  const onChange = option => {
    actionHandler[option.value]();
  };

  const dateWithTimeZone = timeZoneDateConverter.getFormattedDateWithTimeZone(date);

  const onClickWrapper = () => onClick(id);

  const buildSingleValue = innerProps => {
    return (
      <SingleValue
        {...innerProps}
        placeholder='Select' />
    );
  };

  const buildDropdownIndicator = innerProps => {
    return (
      <DropdownIndicator
        {...innerProps}
        cancel={closeActions}
      />
    );
  };

  return (
    <tr
      className={selectedConsentId === id ? 'selected-row' : ''}
    >
      <td onClick={onClickWrapper}>{name}</td>
      <td onClick={onClickWrapper}>{dateWithTimeZone}</td>
      <td ref={selectRef}>
        <EventListenerLayer onClickOutSide={closeActions} connectedNodes={[selectRef]}>
          <Select
            onChange={onChange}
            isSearchable={false}
            styles={selectStyles}
            options={actions}
            closeMenuOnSelect={false}
            onMenuOpen={() => setIsOpen(true)}
            components={{ Option: ReplaceTemplateOption, SingleValue: buildSingleValue, DropdownIndicator: buildDropdownIndicator }}
            menuIsOpen={isOpen}
            menuPortalTarget={document.body}
          />
        </EventListenerLayer>
        {isOpenDeleteModal && (
          <DeleteModal
            onCloseModal={() => setIsOpenDeleteModal(false)}
            onConfirm={actionDeleteWrapper}
          />
        )}
        <input
          style={{ 'display': 'none' }}
          ref={replaceOptionRef}
          accept='application/pdf'
          type='file'
          onChange={onDrop}
        />
      </td>
    </tr>
  );
};