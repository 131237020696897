import React from 'react';
import PropTypes from 'prop-types'

import Checkbox from 'components/Checkbox';

import cx from './DrChronoOffices.module.scss'

const DrChronoOfficeItem = ({ name, select, deSelect, checked }) => {
  return (
    <div className={cx['checked-container']}>
      <Checkbox
        checked={checked}
        onClick={checked ? deSelect : select}
        checkboxSize={Checkbox.SIZE.SMALL}
        checkedColor={Checkbox.CHECKED_COLOR.GREEN}
        shape={Checkbox.SHAPE.square}
      />
      <span
        className={cx['provider-name']}
      >
        {name}
      </span>
    </div>
  )
}

DrChronoOfficeItem.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  deSelect: PropTypes.func.isRequired
}

export default DrChronoOfficeItem
