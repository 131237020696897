import * as api from 'api/user';
import * as constants from './constants';

export const acceptAgreement = () => ({
  types: [
    constants.AGREEMENT_STATUS_UPDATE,
    constants.AGREEMENT_STATUS_UPDATE_SUCCESS,
    constants.AGREEMENT_STATUS_UPDATE_FAIL,
  ],
  promise: () => api.acceptAgreement(),
});
