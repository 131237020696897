import React, { useEffect } from 'react';
import { node, bool } from 'prop-types';

import Portal from 'react-portal';
import Overlay from 'components/Overlay';
import Paper from 'components/Paper';

import cx from 'components/Dialog/Dialog.module.scss';

const Dialog = props => {
  const { isOpened, children } = props;

  useEffect(() => {
    const bodyClasses = document.body.className;
    if (isOpened) {
      document.body.className = `${bodyClasses} has-modal`;
    } else {
      document.body.className = '';
    }
  }, [isOpened]);

  return (
    <Portal isOpened={isOpened} className={cx.wrapper}>
      <div>
        <Overlay onClick={() => {}} />
        <Paper>
          {children}
        </Paper>
      </div>
    </Portal>
  );
};

Dialog.propTypes = {
  isOpened: bool.isRequired,
  children: node.isRequired,
};

export default Dialog;
