import { connect } from 'react-redux';
import React from 'react';
import * as actions from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';

import FetchLayer, { getMapStateToProps } from '../../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const screeningItemContainer = Component => props => (
  <FetchLayer
    itemId={props.itemId}
    fetchItem={props.fetchScreeningItem}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.SCREENING, 'screeningItem', 'screeningItemId');


const mapActionCreators = {
  fetchScreeningItem: actions.fetchScreeningItem,
  deleteScreeningItem: actions.deleteScreeningItem,
  createScreeningItem: actions.createScreeningItem,
  updateScreeningItem: actions.updateScreeningItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(screeningItemContainer(component));
