import { hpChartTabs } from 'helpers/chart';
import { isNullOrUndefined } from 'helpers';

const getFavoriteIdBySystem = (systemId, currentItem) => {
  const {
    id,
    favoriteId,
  } = currentItem;

  if (isNullOrUndefined(systemId)) {
    return id;
  }

  return systemId === hpChartTabs.A.systems.ICD_TEN.id ||
  systemId === hpChartTabs.Plan.systems.PRESCRIPTIONS.id ||
    systemId === hpChartTabs.BILLING.systems.CPT.id ||
    systemId === hpChartTabs.BILLING.systems.HCPC.id
    ? id
    : favoriteId;
};

export default getFavoriteIdBySystem;
