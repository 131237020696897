import React from 'react';
import { func, bool } from 'prop-types';

import { useImpersonate } from 'hooks/useImpersonate';

import Logo from './components/Logo';
import BurgerMenu from 'components/BurgerMenu';
import BrowserBackButton from 'components/BrowserBackButton';
import MobileOnly from 'components/MobileOnly';
import Banner from 'components/Banner';

import cx from './PageHeader.module.scss';

const PageHeader = (props) => {
  const { logout } = useImpersonate();
  const {
    toggleSidebar,
    sidebarActive,
    lockButtonEnabled,
    lock,
    lockProcessing,
  } = props;

  return (
    <div className={cx.wrapper}>
      <div className={cx['burger-menu-wrapper']}>
        <BurgerMenu
          toggleSidebar={toggleSidebar}
          active={sidebarActive}
        />
      </div>
      <MobileOnly>
        <BrowserBackButton />
      </MobileOnly>
      <Logo />
      <div className={cx.linkWrapper}>
        {lockButtonEnabled && !lockProcessing && (
          <button
            type="button"
            className={cx['lock-button']}
            onClick={lock}
            title="Lock"
          >
            <span className={cx['lock-icon']} />
          </button>
        )}
        {lockButtonEnabled && lockProcessing && (
          <span className={cx.loader} />
        )}
        {lockButtonEnabled && ' '}
        <button type="button" onClick={logout}>Logout</button>
      </div>
      <div>
        <Banner />
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  toggleSidebar: func.isRequired,
  lock: func.isRequired,
  sidebarActive: bool.isRequired,
  lockButtonEnabled: bool.isRequired,
  lockProcessing: bool.isRequired,
};

export default PageHeader;
