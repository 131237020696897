export const FETCH_SOCIAL_ELEMENTS_BY_SYSTEM = 'FETCH_SOCIAL_ELEMENTS_BY_SYSTEM';
export const FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_SUCCESS = 'FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_SUCCESS';
export const FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_FAIL = 'FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_FAIL';

export const CREATE_SOCIAL_ELEMENT = 'CREATE_SOCIAL_ELEMENT';
export const CREATE_SOCIAL_ELEMENT_SUCCESS = 'CREATE_SOCIAL_ELEMENT_SUCCESS';
export const CREATE_SOCIAL_ELEMENT_FAIL = 'CREATE_SOCIAL_ELEMENT_FAIL';

export const UPDATE_SOCIAL_ELEMENT = 'UPDATE_SOCIAL_ELEMENT';
export const UPDATE_SOCIAL_ELEMENT_SUCCESS = 'UPDATE_SOCIAL_ELEMENT_SUCCESS';
export const UPDATE_SOCIAL_ELEMENT_FAIL = 'UPDATE_SOCIAL_ELEMENT_FAIL';

export const UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY = 'UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY';
export const UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_SUCCESS = 'UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_SUCCESS';
export const UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_FAIL = 'UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_FAIL';

export const DELETE_RIGHT_SIDE_ELEMENT = 'DELETE_RIGHT_SIDE_ELEMENT';
export const DELETE_RIGHT_SIDE_ELEMENT_SUCCESS = 'DELETE_RIGHT_SIDE_ELEMENT_SUCCESS';
export const DELETE_RIGHT_SIDE_ELEMENT_FAIL = 'DELETE_RIGHT_SIDE_ELEMENT_FAIL';
