export const ALLERGY_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const EMPTY_MESSAGE = {
  noDrugAllergies: 'no known drug allergies',
  activeAllergies: 'no active allergies',
  inactiveAllergies: 'no inactive allergies',
};
