import React from 'react';
import { compose } from 'redux';

import settingsContainer, { fetchLayerHOC } from 'containers/SettingsContainer';
import PreferencesForm from '../ClinicForm';

import cx from './ClinicTab.module.scss';

const ClinicFormContainer = compose(
  settingsContainer,
  fetchLayerHOC
)(PreferencesForm);

export const ClinicTab = () => {
  return (
    <div className={cx['tab-content']}>
      <ClinicFormContainer />
    </div>
  );
};
