import React from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik';

const ValidatedField = ({ children }) => {
  const { errors } = useFormikContext();

  const errorMessage = errors[children?.props?.name]

  return (
    <>
      {children}
      {errorMessage && <span className='validation-error'>{errorMessage}</span>}
    </>
  )
}

ValidatedField.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ValidatedField
