import * as constants from './constants';
import * as api from 'api/providers';

export const fetchProviders = () => ({
  types: [
    constants.PROVIDERS_FETCH,
    constants.PROVIDERS_FETCH_SUCCESS,
    constants.PROVIDERS_FETCH_FAIL,
  ],
  promise: async () => {
    const { body, status } = await api.fetchProviders()

    return status === 200 ? body : null
  },
});

export const setFilterProvider = providerId => ({
  type: constants.FILTER_PROVIDER_SET,
  providerId,
});
