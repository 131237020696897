import { handleActions } from 'redux-actions';
import * as constants from './constants';
import PlansService from '../../../helpers/financial/plans/PlansService';

const initialState = {
  listPlans: [],
  currentPlan: {},
  isFetched: false,
  isFetching: false,
  isUpdated: false,
  isUpdating: false,
};

export default handleActions({
  [constants.PAYMENTS_PLANS_FETCH]: state => ({
    ...state,
    isFetching: true,
  }),

  [constants.PAYMENTS_PLANS_FETCH_SUCCESS]: (state, action) => {
    const plansService = new PlansService(action.payload.result);

    return {
      ...state,
      listPlans: plansService.filterPlansToDisplayAmount(),
      isFetching: false,
      isFetched: true,
    };
  },

  [constants.PAYMENTS_PLANS_FETCH_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),

  [constants.SET_CURRENT_PLAN_TO_PURCHASE_SEAT]: (state, action) => ({
    ...state,
    currentPlan: action.plan,
  }),

}, initialState);
