import React from 'react';
import { shape, number, string, func } from 'prop-types'

import cx from './SimpleSelect.module.scss'

const MultiSelectOption = (props) => {
  const {
    value,
    removeMultiValue
  } = props

  return (
    <div
      className={cx['multi-option__container']}
    >
      <div
        className={cx.label}
      >
        {value.name}
      </div>
      <div
        className={cx['clear-icon__container']}
        onClick={removeMultiValue}
      >
        <svg
          height="14"
          width="14"
          viewBox="0 0 20 20"
          className={cx['clear-icon']}
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </div>
    </div>
  )
}

MultiSelectOption.propTypes = {
  removeMultiValue: func.isRequired,
  value: shape({
    id: number,
    name: string
  })
}

export default MultiSelectOption
