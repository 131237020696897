import { connect } from 'react-redux';

import { createUser, updateUser, fetchUserImage } from 'modules/users/actions';
import { setUserInactive } from 'modules/seats/actions';
import * as selectors from 'modules/users/selectors';
import * as userSelectors from 'modules/user/selectors';
import * as rolesSelectors from 'modules/roles/selectors';

import { updatePermissions, updatePermissionsForCurrentUserLocally } from 'modules/user/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectors.getUser(state.users, ownProps.userId),
    isCreating: selectors.getIsCreating(state.users),
    isUpdating: selectors.getIsUpdating(state.users),
    isCurrentUser: userSelectors.getUserInfo(state.user).id === parseInt(ownProps.userId, 10),
    currentUserIsProvider: userSelectors.getUserInfo(state.user).id === userSelectors.getUserInfo(state.user).providerId,
    userId: ownProps.userId && parseInt(ownProps.userId, 10),
    roles: rolesSelectors.getRoles(state.roles),
    seatId: selectors.getSeatUserAssigned(state, ownProps.userId),
    isDownloadingSignature: userSelectors.isDownloadingSignature(state.user),
  };
};

const mapActionCreators = {
  createUser,
  updateUser,
  updatePermissions,
  updatePermissionsForCurrentUserLocally,
  setUserInactive,
  fetchUserImage,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
