import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import resolveStyles from 'helpers/common/styles/resolveStyles';
import { build } from 'helpers/URLUtils';

import cx from './ReportPageNav.module.scss';

const prevBtnStyles = ({ page }) => resolveStyles([
  cx['nav-button'], cx['nav-button--prev'],
  page === 0 && cx['nav-button--disabled']
]);

const nextBtnStyles = ({ lastPage }) => resolveStyles([
  cx['nav-button'], cx['nav-button--next'],
  lastPage && cx['nav-button--disabled']
]);

const ReportPageNav = ({ page, isFetching, lastPage, location }) => {
  return (
    <div className={cx['page-nav']}>
      <Link
        className={prevBtnStyles({ page })}
        to={build({
          pathname: location.pathname,
          query: {
            ...location.query,
            page: page - 1,
          },
        })}
        onClick={e => {
          if (page === 0 || isFetching) {
            e.preventDefault();
          }
        }}
      >
        Previous
      </Link>
      {' '}
      <span className={cx['page-num']}>{page + 1}</span>
      {' '}
      <Link
        className={nextBtnStyles({ lastPage })}
        to={build({
          pathname: location.pathname,
          query: {
            ...location.query,
            page: page + 1,
          },
        })}
        onClick={e => {
          if (isFetching || lastPage) {
            e.preventDefault();
          }
        }}
      >
        Next
      </Link>
    </div>
  );
};

ReportPageNav.propTypes = {
  page: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  lastPage: PropTypes.bool,
};

export default ReportPageNav;
