import React from 'react';
import PropTypes from 'prop-types';

import { ProgressBarStyles } from 'components/RadialProgressBar/constants';

import { isNullOrUndefined } from 'helpers';

import cx from './RadialProgressBar.module.scss';

const RadialProgressBar = (props) => {
  const isCircleFullFilledInDashboard = () => {
    const {
      progress,
      useCheck,
    } = props;

    return useCheck === false && progress === 100;
  };

  const defineStylesForFilledCircle = () => {
    const circleFullFilledInDashboard = isCircleFullFilledInDashboard();

    if (circleFullFilledInDashboard) {
      return ProgressBarStyles.filledCircle;
    }

    return ProgressBarStyles.initial;
  };

  const {
    progress,
    useCheck,
    isReady,
    label,
    height = 45,
  } = props;

  const basicSize = 144;
  let finalProgress;

  let style = {
    progressCircleColor: '#30c6a1'
  };
  if (isNullOrUndefined(progress)) {
    style.progressCircleColor = '#828282';
  }
  style.strokeDasharray = basicSize;

  if (useCheck) {
    style.strokeDashoffset = isReady ? 0 : basicSize;
    style.arrowColor = isReady ? '#30c6a1' : '#727272';
  } else {
    if (progress <= 100) {
      finalProgress = progress;
    } else {
      finalProgress = 100;
    }
    style.strokeDashoffset = ((100 - finalProgress) / 100) * basicSize;
  }

  const newStyles = defineStylesForFilledCircle();

  style = { ...style, ...newStyles };

  const isNotUseCheck = !isNullOrUndefined(useCheck) && !useCheck;

  return (
    <div
      className={cx['svg-container']}
      style={{
        height: `${height}px`
      }}
    >
      <svg className={cx.svg} viewBox="0 0 50 50">
        <circle className={cx.border} r="24" cx="25" cy="25" />
        <circle className={cx.base} r="22" cx="25" cy="25" />
        <circle
          className={cx.progress}
          r="22"
          cx="25"
          cy="25"
          transform="rotate(-90,25,25)"
          style={{
            ...style, stroke: style.progressCircleColor
          }}
        />
        {useCheck && (
          <path
            style={{ fill: style.arrowColor }}
            className={cx.check}
            fill="#727272"
            d="M47.869,15.876c-11.742,7.199-20.261,16.282-24.089,20.839l-9.375-7.345l-4.14,3.337l16.199,16.475c2.788-7.141,11.618-21.098,22.399-31.014L47.869,15.876z"
          />
        )}
        {isNotUseCheck && (
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            x="50%"
            y="50%"
            fill={style.color}
            style={{
              fontSize: '13px',
            }}
          >
            {progress}%
          </text>
        )}
      </svg>

      <span className={cx.label}>
        {label}
      </span>
    </div>
  );
};

RadialProgressBar.propTypes = {
  progress: PropTypes.number,
  useCheck: PropTypes.bool,
  isReady: PropTypes.bool,
  label: PropTypes.string,
  height: PropTypes.number
};

export default RadialProgressBar;