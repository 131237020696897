import React, { useContext } from 'react';
import { string, shape, func } from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import cx from './Encounter.module.scss';

const lockImage = require('images/lock.png');

const Encounter = (props) => {
  const {
    chart,
    onRowClick,
    patient,
  } = props;

  const {
    timeZoneDateConverter,
    elementNameConverter,
  } = useContext(AppContext);

  const formattedTimeZoneDate = timeZoneDateConverter.getFormattedDateWithTimeZone(chart.selectUserDate);

  return (
    <tr className={cx['encounter']} onClick={onRowClick}>
      <td>
        {chart.signed && (
          <img
            src={lockImage}
            alt="Locked"
            title="Encounter is locked"
            width="20"
            className={cx['locked-icon']}
          />
        )}
        {chart.signed && ' '}
        <span className={chart.signed ? cx['select-user-date'] : ''}>
          {formattedTimeZoneDate}
        </span>
      </td>
      <td>
        <span
          className={cx['code']}
        >
          {chart.patientCc && elementNameConverter.transformElementNameWithSubElements({ name: chart.patientCc.split(', ')[0] }, patient)}
        </span>
      </td>
      <td>{`${chart.chartingType}`}</td>
    </tr>
  );
};

Encounter.propTypes = {
  chart: shape({
    patientCc: string,
    chartingType: string.isRequired,
  }).isRequired,
  onRowClick: func.isRequired,
};

export default Encounter;
