import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { Formik } from 'formik';

import isEqual from 'lodash/isEqual';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ClinicContainer, { fetchLayerHOC } from 'containers/ClinicContainer';

import Loader from 'components/Loader';
import ButtonSpinner from 'components/ButtonSpinner';
import UserImageLoader from 'components/UserImageLoader';
import ValidateMaskInput from 'components/ValidateMaskInput';
import AddressBlock from 'components/AddressBlock';
import Modal from 'components/Modal';
import UploadImageWrapper from 'components/UploadImageWrapper';
import StaticOptions from 'components/Select/StaticOptions';
import ValidatedField from 'components/Form/ValidatedField';

import { stripWhiteListProps } from 'helpers';
import DefaultClinicImage from 'helpers/images/defaults/clinic';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import { buildSchema } from 'helpers/validationRules';

import cx from './ClinicForm.module.scss';

const whiteListProps = [
  'phoneNumber',
  'phoneNumberType',
  'secondPhoneNumber',
  'secondPhoneNumberType',
  'address1',
  'address2',
  'city',
  'state',
  'zip',
  'webSite',
  'taxId',
  'legalName',
  'logo',
  'email',
  'clinicOwnerName',
];

const masks = {
  phoneNumber: '(999) 999-9999',
  npi: '9999999999',
  taxId: '99-9999999',
};

const selectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 210,
  heightControl: 28,
  fontSize: 12,
});

const phoneNumberTypes = [
  {
    label: 'Main',
    value: 'main',
  },
  {
    label: 'Office',
    value: 'office',
  },
  {
    label: 'Mobile',
    value: 'mobile',
  },
  {
    label: 'Pager',
    value: 'pager',
  },
  {
    label: 'Fax',
    value: 'fax',
  },
];

class ClinicForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.formikRef = React.createRef();
  }

  componentDidMount() {
    // this.hook = this.props.router.setRouteLeaveHook(this.props.routes[this.props.routes.length - 1], this.routerWillLeave);
  }

  componentWillUnmount() {
    if (this.hook) {
      this.hook();
    }
  }

  getClinicForRequest = (clinic) =>
    stripWhiteListProps(clinic, whiteListProps);

  onSubmit = (values) => {
    const dataToRequest = this.getClinicForRequest(values);

    const { updateClinicInfo } = this.props;
    updateClinicInfo(values, dataToRequest);

    this.setState({
      changed: false,
    });
  };

  onCancel = (e) => {
    this.setState({
      changed: false,
    });

    e.preventDefault();
  };

  getFormattedClinic = (clinic) => {
    const d = { ...clinic };

    Object.keys(d).forEach((key) => {
      if (d[key] === null && key !== 'logo' && key !== 'signature') {
        d[key] = '';
      }
    });

    return d;
  };

  routerWillLeave = (route) => {
    if (this.state.changed) {
      this.setState({
        next: route.pathname + route.search,
        showModal: true,
      });
    }

    return !this.state.changed;
  };

  updateClinic = (key, value) => {
    this.setState({
      changed: true,
    });

    this.formikRef.current.setFieldValue([key], value);
  };

  updatePhoneNumberType = (type, option) =>
    this.updateClinic(type, option === null ? '' : option.value);

  updateState = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.updateClinic(name, value);
  };

  updateImageUrl = (imageUrl) => {
    this.formikRef.current.setFieldValue('imageUrl', imageUrl);
  };

  updateImage = ({ imageName, imageUrl }) => {
    this.updateClinic('logo', imageName);
    this.updateDirtyImageUrl(imageUrl);
  };

  updateDirtyImageUrl = (imageUrl) => {
    this.setState({ changed: true });
    this.updateImageUrl(imageUrl);
  };

  closeModal = () => this.setState({ showModal: false });

  confirm = () => {
    this.setState({ changed: false, showModal: false }, () => {
      if (this.state.next) {
        this.props.router.push(this.state.next);
      }
    });
  };

  getPhoneTypeByValue = value =>
    phoneNumberTypes.filter(o => o.value === value)[0];

  getValidationSchema = () => buildSchema({
    clinicOwnerName: 'required',
    legalName: 'required',
    taxId: 'required',
    email: 'requiredEmail',
    phoneNumber: 'requiredPhone',
    secondPhoneNumber: 'phone'
  });

  defineIsDisabledSubmitButton = formErrors => {
    const { changed } = this.state;
    const { isSaving } = this.props;

    return !changed || !isEqual(formErrors, {}) || isSaving;
  };

  renderPhones(values) {
    const { updateState } = this;
    const {
      permissions: { accessEditOrganizationDemo }
    } = this.context;

    return (
      <div className={cx['element-group']}>
        <div className={cx['phone-wrapper']}>
          <span className={cx['label-required']}>
            phone 1
          </span>
          <div className={cx['phone-input-container']}>
            <ValidatedField>
              <ValidateMaskInput
                type="tel"
                mask={masks.phoneNumber}
                placeholder="(123) 456-7890"
                name="phoneNumber"
                value={values.phoneNumber}
                className={cx.input}
                onChange={updateState}
                disabled={!accessEditOrganizationDemo}
              />
            </ValidatedField>
          </div>
          <StaticOptions
            isClearable
            value={this.getPhoneTypeByValue(values.phoneNumberType)}
            className={cx['phone-number-type']}
            options={phoneNumberTypes}
            onChange={option => this.updatePhoneNumberType('phoneNumberType', option)}
            styles={selectStyles}
            disabled={!accessEditOrganizationDemo}
          />

        </div>
        <div className={cx['phone-wrapper']}>
          <span className={cx.label}>
            phone 2
          </span>
          <div className={cx['phone-input-container']}>
            <ValidatedField>
              <ValidateMaskInput
                type="tel"
                mask={masks.phoneNumber}
                placeholder="(123) 456-7890"
                id="secondPhoneNumber"
                name="secondPhoneNumber"
                value={values.secondPhoneNumber}
                className={cx.input}
                onChange={updateState}
                disabled={!accessEditOrganizationDemo}
              />
            </ValidatedField>
          </div>
          <StaticOptions
            isClearable
            value={this.getPhoneTypeByValue(values.secondPhoneNumberType)}
            className={cx['phone-number-type']}
            options={phoneNumberTypes}
            onChange={option => this.updatePhoneNumberType('secondPhoneNumberType', option)}
            disabled={!accessEditOrganizationDemo}
            styles={selectStyles}
          />

        </div>
      </div>
    );
  }

  render() {
    const {
      updateState,
    } = this;

    const {
      isSaving,
      fetchClinicImage,
      clinic,
    } = this.props;

    const { showModal } = this.state;

    const { permissions } =  this.context;

    const { accessEditOrganizationDemo } = permissions;

    return (
      <Formik
        initialValues={this.getFormattedClinic(clinic)}
        innerRef={this.formikRef}
        onSubmit={this.onSubmit}
        validationSchema={this.getValidationSchema()}
      >
        {({
        values,
        errors,
        handleSubmit,
        handleReset
      }) => (
        <form
          className={cx.form}
          onSubmit={handleSubmit}
      >
        <div className={cx['image-loader-group']}>
          <UploadImageWrapper
            imageName={values.logo}
            entityId={values.id}
            fetchImage={fetchClinicImage}
            imageUrl={values.imageUrl}
            onChange={this.updateImageUrl}
          >
            <UserImageLoader
              isDownloadingImage={false}
              defaultImage={DefaultClinicImage.get()}
              wrapperClass="demographics-wrapper"
              onChange={this.updateImage}
              label="Logo"
              type="avatar"
              disabled={!accessEditOrganizationDemo}
              imageUrl={values.imageUrl}
            />
          </UploadImageWrapper>
        </div>

        <div className={cx['element-group']}>
          <div className={cx['element-wrapper']}>
            <span className={cx['label-required']}>
              Account Owner Name
            </span>
            <ValidatedField>
              <input
                name="clinicOwnerName"
                onChange={updateState}
                className={cx.input}
                value={values.clinicOwnerName}
                disabled={!accessEditOrganizationDemo}
                placeholder="Company or Individual"
              />
            </ValidatedField>
          </div>
          <div className={cx['element-wrapper']}>
            <span className={cx['label-required']}>
              Legal name
            </span>
            <ValidatedField>
              <input
                name="legalName"
                onChange={updateState}
                className={cx.input}
                value={values.legalName}
                disabled={!accessEditOrganizationDemo}
              />
            </ValidatedField>
          </div>

          <div className={cx['tax-wrapper']}>
            <span className={cx['label-required']}>
              Tax ID
            </span>
            <ValidatedField>
              <ValidateMaskInput
                name="taxId"
                mask={masks.taxId}
                onChange={updateState}
                className={cx.input}
                value={values.taxId}
                disabled={!accessEditOrganizationDemo}
              />
            </ValidatedField>
          </div>

        </div>
        <AddressBlock
          address1={values.address1}
          address2={values.address2}
          city={values.city}
          state={values.state}
          zip={values.zip}
          onChange={this.updateClinic}
          disabled={!accessEditOrganizationDemo}
        />

        {this.renderPhones(values)}

        <div className={cx['element-group']}>
          <div className={cx['element-wrapper']}>
            <span className={cx.label}>
              web site
            </span>
            <input
              type="text"
              name="webSite"
              onChange={updateState}
              className={cx.input}
              value={values.webSite}
              disabled={!accessEditOrganizationDemo}
            />
          </div>
          <div className={cx['element-wrapper']}>
            <span className={cx['label-required']}>
              email
            </span>
            <ValidatedField>
              <input
                name="email"
                onChange={updateState}
                className={cx.input}
                value={values.email}
                disabled={!accessEditOrganizationDemo}
              />
            </ValidatedField>
          </div>
        </div>

        <div className={cx['controls-group']}>
          <button
            type="reset"
            className={cx.button}
            onClick={handleReset}
          >
            cancel
          </button>
          <button
            type="submit"
            value="Save"
            className={cx.save}
            disabled={this.defineIsDisabledSubmitButton(errors)}
          >
            save
            {isSaving &&
              <ButtonSpinner />
            }
          </button>
        </div>
        <Modal
          data={{
            type: 'confirm',
            title: 'Unsaved changes',
            content: '<p>Are you sure you want to leave?</p><p class="warning-text"><strong>Warning:</strong> All changes will be discarded!</p>',
          }}
          isOpen={showModal}
          onModalClose={this.closeModal}
          onModalConfirm={this.confirm}
        />
      </form>
      )}
      </Formik>
    );
  }
}

ClinicForm.contextType = AppContext;

ClinicForm.propTypes = {
  updateClinicInfo: PropTypes.object.isRequired,
};

const Wrapper = (props) => {
  const { isFetching, isFetched } = props;

  if (!isFetched || isFetching) {
    return <div className={cx['loader-wrapper']}><Loader /></div>;
  }

  return <ClinicForm {...props} />;
};

const WrapperContainer = compose(
  ClinicContainer,
  fetchLayerHOC
);

export default WrapperContainer(Wrapper);
