export default class SocialSubElementsResponse {
  static parse = (subElements = {}) => {
    const formatSubElements = [];

    Object.keys(subElements).forEach((subElementId) => {
      const formattedSubElement = {
        id: subElementId,
        name: subElements[subElementId],
      };

      formatSubElements.push(formattedSubElement);
    });

    return formatSubElements;
  }
}
