import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import FlatButton from 'components/FlatButton';

import BackgroundImageStyle from 'helpers/images/styles/background-image';

import cx from './PatientImage.module.scss';

const PatientImage = (props) => {
  const {
    patientId,
    imageUrl,
  } = props;

  return (
    <Link to={`/app/doctor/patients/${patientId}`} className={cx.wrapper}>
      <span
        className={cx.image}
        style={{
          ...BackgroundImageStyle.define(imageUrl),
        }}
      />
      <div className={cx['button-wrapper']}>
        <FlatButton
          label="View info"
        />
      </div>
    </Link>
  );
};

PatientImage.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageUrl: PropTypes.string,
};

PatientImage.defaultProps = {
  imageUrl: null,
};

export default PatientImage;
