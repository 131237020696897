import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDrChronoSSO } from './useDrChronoSSO';

import DrChronoSSOService from 'helpers/drChrono/DrChronoSSOService';
import { isNullOrUndefined } from 'helpers';

import cx from './DrChronoSSO.module.scss';

const DrChronoSSO = (props) => {
  const { ssoLogin } = useDrChronoSSO();
  const {
    code = null,
  } = props;

  useEffect(() => {
    if (!isNullOrUndefined(code)) {
      ssoLogin(code);
    }
  }, []);

  const handleClick = () => {
    const drChronoSSOService = new DrChronoSSOService(DrChronoSSO.REDIRECT_URI);
    window.location = drChronoSSOService.drChronoAuthUrl();
  };

  return (
    <div className={cx.sso}>
      <button
        type='button'
        onClick={handleClick}
        className={cx.sso__btn}
      >
        DrChrono SSO
      </button>
    </div>
  );
};

DrChronoSSO.propTypes = {
  code: PropTypes.string,
};

DrChronoSSO.REDIRECT_URI = `https://${window.location.hostname}/app/login`;

export default DrChronoSSO;
