import React, { useContext } from 'react';

import { useSearchTab } from 'routes/routes/App/components/MainMenu/useSearchTab';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import MenuItem from './MenuItem';

import cx from './MainMenu.module.scss';

const MainMenu = ({ toggleSidebar }) => {
  const { searchTabUrl } = useSearchTab();

  const {
    permissions,
  } = useContext(AppContext);

  return (
    <ul className={cx.menu}>
      <MenuItem
        className="menu-item__search___autotest"
        onClick={toggleSidebar}
        url={searchTabUrl}
        title="search"
      />
      <MenuItem
        className="menu-item__dashboard___autotest"
        onClick={toggleSidebar}
        url={'/app/doctor'}
        title="dashboard"
        indexLink
      />
      <MenuItem
        className="menu-item__patients___autotest"
        onClick={toggleSidebar}
        url={'/app/doctor/patients'}
        title="patients"
      />
      {
        permissions.accessReportsAnalytics &&
        <MenuItem
          className="menu-item__reports___autotest"
          onClick={toggleSidebar}
          url={'/app/doctor/reports'}
          title="reports"
        />
      }
      <MenuItem
        className="menu-item__settings___autotest"
        onClick={toggleSidebar}
        url={'/app/doctor/settings'}
        title="settings"
      />
    </ul>
  );
};

export default MainMenu;
