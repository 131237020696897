import { handleActions } from 'redux-actions';

import * as constants from '../constants';

const initialState = {
  isFetching: false,
  isFetched: false,
  isSaving: false,
};

export default handleActions({
  [constants.FETCH_CLINIC_INFO]: state => ({
    ...state,
    isFetching: true,
  }),
  [constants.FETCH_CLINIC_INFO_SUCCESS]: state => ({
    ...state,
    isFetching: false,
    isFetched: true,
  }),
  [constants.FETCH_CLINIC_INFO_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),
}, initialState);
