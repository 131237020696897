export const MESSAGE_TYPES = {
  INFO: 'info',
  DANGER: 'danger'
}

const MESSAGE_TYPE_STYLES = {
  [MESSAGE_TYPES.INFO]: {
    titleColor: '#3c8cbb',
    clearIconColor: '#3c8cbb'
  },
  [MESSAGE_TYPES.DANGER]: {
    titleColor: 'red',
    clearIconColor: 'red'
  }
}

export const getTitleColor = type =>
  MESSAGE_TYPE_STYLES[type]?.titleColor

export const getClearIconColor = type =>
  MESSAGE_TYPE_STYLES[type].clearIconColor
