import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = false;

export default handleActions({
  [constants.SEAT_PRICE_FETCH]: () => true,
  [constants.SEAT_PRICE_FETCH_SUCCESS]: () => false,
  [constants.SEAT_PRICE_FETCH_FAIL]: () => false,
}, initialState);
