import * as settingsConstants from './constants';
import * as settingsApi from 'api/settings';

export const fetchSettings = () => ({
  types: [
    settingsConstants.SETTINGS_FETCH,
    settingsConstants.SETTINGS_FETCH_SUCCESS,
    settingsConstants.SETTINGS_FETCH_FAIL,
  ],
  promise: () => settingsApi.fetchSettings(),
});

export const saveSettings = settings => ({
  types: [
    settingsConstants.SETTINGS_SAVE,
    settingsConstants.SETTINGS_SAVE_SUCCESS,
    settingsConstants.SETTINGS_SAVE_FAIL,
  ],
  promise: () => settingsApi.saveSettings(settings),
  settings,
});

export const updateSettingsLocal = customsSettings => ({
  type: settingsConstants.SETTINGS_SAVE_LOCAL,
  payload: {
    customsSettings,
  },
});
