import React from 'react';
import cx from './PlanCard.module.scss';
import PropTypes from 'prop-types';

const PlanCard = ({
  children,
  showModal,
}) => {
  return (
    <div className={cx['plan-data']}>
      {children}
    </div>
  );
};

PlanCard.propTypes = {
  children: PropTypes.node,
};

export default PlanCard;
