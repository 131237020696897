export const getChartById = (state, id) => state[id] || {};

export const getChartsById = state => state.byId;

export const isVitalsFetching = (state, chartId) =>
  state[chartId] && state[chartId].vitals && state[chartId].vitals.isFetching;

export const getVitals = (state, chartId) =>
  state[chartId] && !state[chartId].deleted && state[chartId].vitals;

export const getIsCopyingPreviousNote = (state, chartId) =>
  state[chartId] && state[chartId].isCopyingPreviousNote;

export const getIsChartSaving = (state, chartId) =>
  state[chartId].isSaving;

export const getChartSignDate = (state, chartId) =>
  state[chartId].selectUserDate;

export const getChartDetails = (state, chartId) =>
  ((state[chartId] && state[chartId].details && state[chartId].details.specialSystems) || []);
