export const getChartsByPatient = (state, patientId) =>
  (state[patientId] ? state[patientId].items : []);

export const getChartsByPatientByPage = (state, patientId, page) =>
  ((state[patientId] && state[patientId][page] && state[patientId][page]) || []);

export const getIsFetching = (state, patientId) =>
  (state[patientId] ? state[patientId].isFetching : false);

export const getChartsByPatientPagesCount = (state, patientId) =>
  state && state[patientId] && state[patientId].pagesCount;
