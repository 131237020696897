import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePatient } from 'hooks/usePatient';

import { addConsentAction, consentsListAction, deletePatientConsentAction } from 'modules/patients/actions';
import { selectConsentById, selectConsentIds } from 'modules/patients/selectors';

import { deleteConsentQuery, getAll } from 'api/patient-consent';
import { getProviderInitials } from 'modules/user/selectors';

const formatPatientConsent = consent => ({
  id: consent[0],
  name: consent[1],
  provider: consent[2],
  date: consent[3],
  fileName: consent[4]
});

export const useFetchPatientConsents = () => {
  const { patientId } = usePatient();
  const dispatch = useDispatch();
  const consentIds = useSelector(state => selectConsentIds(state, patientId)) || [];

  useEffect(() => {
    const patientConsentsList = async () => {
      const { body } = await getAll(patientId);
      const formatted = body.map(formatPatientConsent);
      dispatch(consentsListAction({
        patientId,
        consents: formatted,
        ids: formatted.map(consent => consent.id)
      }));
    };
    patientConsentsList();
  }, []);

  return { consentIds };
};

export const usePatientConsents = () => {
  const { patientId } = usePatient();
  const dispatch = useDispatch();

  const getConsentById = consentId => useSelector(state => selectConsentById(state, patientId, consentId));
  const providerInitials = useSelector(state => getProviderInitials(state));
  const addConsent = ({ consentId, consent }) => dispatch(addConsentAction({
    patientId,
    consentId,
    consent
  }));

  const deleteConsent = async consentId => {
    await deleteConsentQuery(consentId);
    dispatch(deletePatientConsentAction({ id: consentId, patientId }));
  };

  return { getConsentById, addConsent, deleteConsent, providerInitials };
};