import React from 'react';
import { compose } from 'redux';

import settingsContainer, { fetchLayerHOC } from 'containers/SettingsContainer';
import PreferencesForm from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/PreferencesForm';

import cx from './PreferencesTab.module.scss';

const PreferencesContainer = compose(
  settingsContainer,
  fetchLayerHOC
)(PreferencesForm);

export const PreferencesTab = () => {
  return (
    <div className={cx['tab-content']}>
      <PreferencesContainer />
    </div>
  );
};
