import { handleActions } from 'redux-actions';

import * as chartsConstants from '../constants';

const initialState = false;

export default handleActions({
  [chartsConstants.CHART_CREATE]: () => true,
  [chartsConstants.CHART_CREATE_SUCCESS]: () => false,
  [chartsConstants.CHART_CREATE_FAIL]: () => false,
}, initialState);

