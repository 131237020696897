import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { DATE_FORMATS } from 'helpers/date/constants';

const PaymentRow = (props) => {
  const {
    value,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const formattedDate = (date) => {
    return timeZoneDateConverter.getFormattedDateWithTimeZone(date, DATE_FORMATS.DOB);
  };

  return (
    <tr>
      <td>{formattedDate(value.date)}</td>
      <td>•••• {value.transaction.substr(-5)}</td>
      <td>{formattedDate(value.startDate)}<br />
        {formattedDate(value.expireDate)}
      </td>
      <td>•••• {value.cc}</td>
      <td>1</td>
      <td>${value.amount}</td>
      <td>{value.status}</td>
    </tr>
  );
};

PaymentRow.propTypes = {
  value: PropTypes.shape({
    date: PropTypes.number.isRequired,
    startDate: PropTypes.number.isRequired,
    expireDate: PropTypes.number.isRequired,
    cc: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default PaymentRow;
