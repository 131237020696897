import { handleActions } from 'redux-actions';
import * as constants from '../constants';

import keyBy from 'lodash/keyBy';

const initialState = {};

export default handleActions({
  [constants.SEATS_FETCH_SUCCESS]: (state, action) => ({
    ...keyBy(action.payload.result, 'id'),
  }),

  [constants.SEATS_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.seatDTOS, 'id'),
  }),

  [constants.SEAT_USER_SET_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.seatId]: {
      ...action.payload.result,
    },
  }),

  [constants.SEAT_USER_REMOVE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.seatId]: {
      ...action.payload.result,
    },
  }),

  [constants.SEAT_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.seat.id]: {
      ...action.payload.seat,
    },
  }),

  [constants.SEAT_PURCHASE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result.id]: {
      ...action.payload.result,
    },
  }),

  [constants.SEAT_SET_STATUS]: (state, action) => ({
    ...state,
    [action.payload.seat.id]: {
      ...action.payload.seat,
    },
  }),
}, initialState);
