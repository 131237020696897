import React from 'react';
import PropTypes from 'prop-types'

import cx from './RemoveButton.module.scss';

const RemoveButton = ({ onClick, style }) => {
  return (
    <button
      type="button"
      className={cx['remove-button']}
      onClick={onClick}
      style={style}
    />
  );
};

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default RemoveButton;