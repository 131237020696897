import { connect } from 'react-redux';
import SidebarComponent from '../components/Sidebar';

const mapStateToProps = (state) => {
  return {
    isActiveOnMobile: state.sidebar,
  };
};

const Sidebar = connect(
  mapStateToProps,
)(SidebarComponent);

export default Sidebar;
