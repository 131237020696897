const NULL_SAFE_REG_EXP = /null/g

const stripNull = (rawString, replacement = '') =>
  rawString.replace(NULL_SAFE_REG_EXP, replacement)

const nullSafeString = (candidate = '', replacement) => {
  if (typeof candidate === 'string') {
    return stripNull(candidate, replacement)
  }

  return candidate
}

export default nullSafeString
