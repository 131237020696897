import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';
import { compose } from 'redux';

import ChartReportItem from '../../../ChartReportItem';
import ChartReportSurgicalItem from './ChartReportSurgicalItem';

import ProgressNoteBase
  from 'helpers/fmss/additional-empty-message/factory/empty-message-entity/ProgressNoteBase';
import ProducerEmptyMessageFactory
  from 'helpers/fmss/additional-empty-message/factory/producer-factory';
import { FMSS_TYPES } from 'helpers/fmss/constants';
import stringParser from 'helpers/common/string/string-parser';

import { sortElementsByDateAndName } from 'helpers/fmss';

export const ChartReportSurgical = (props) => {
  const {
    chartId,
    toggleChartReport,
    surgicalItems,
    deleteSurgicalItem,
    location,
    viewMode,
    hasNoPreviousSurgeries,
  } = props;

  const currentSurgicalItemId = location.query.surgical && stringParser(location.query.surgical);

  const sortedSurgicalItems = sortElementsByDateAndName(surgicalItems, { getItemName: item => item.surgery.name });

  const conditionalData = new ProgressNoteBase(hasNoPreviousSurgeries, surgicalItems.length);
  const emptyMessage = ProducerEmptyMessageFactory.getMessage(FMSS_TYPES.PROGRESS_NOTE_SURGICAL, conditionalData);

  return (
    <ChartReportItem
      title="Surgical hx"
      tooltip={!viewMode}
      link={(!viewMode && `/app/doctor/charts/${chartId}/fmss/surgical`) || null}
      toggleChartReport={!viewMode && toggleChartReport}
    >
      {surgicalItems.length === 0 && emptyMessage}
      {!!surgicalItems.length && (
        <ul style={{ padding: 0 }}>
          {sortedSurgicalItems.map(surgicalItem => (
            <li key={surgicalItem.id}>
              <ChartReportSurgicalItem
                surgicalItem={surgicalItem}
                toggleChartReport={toggleChartReport}
                chartId={chartId}
                onRemove={() => deleteSurgicalItem(
                  surgicalItem,
                  currentSurgicalItemId === surgicalItem.id && location.pathname
                )}
                viewMode={viewMode}
              />
            </li>
          ))}
        </ul>
      )}
    </ChartReportItem>
  );
};

ChartReportSurgical.propTypes = {
  chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  toggleChartReport: PropTypes.func,
  surgicalItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  deleteSurgicalItem: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      surgical: PropTypes.string,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  viewMode: PropTypes.bool,
  hasNoPreviousSurgeries: PropTypes.bool,
};

const ChartReportSurgicalComposer = compose(
  withRouter
)(ChartReportSurgical)


export default ChartReportSurgicalComposer;
