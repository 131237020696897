const request = require('superagent-promise')(require('superagent'), Promise);

const BASE_URL = '/api/impersonate/';

const buildUrl = url => BASE_URL + url;

export const switchUserLogin = (data) =>
  request
    .post(buildUrl('login'))
    .send(data)
    .end();

export const switchUserLogout = (data) =>
  request
    .post(buildUrl('logout'))
    .send(data)
    .end();
