import { connect } from 'react-redux';

import { fetchClinics } from 'modules/clinics/actions';
import { getClinics, getIsClinicsFetching, getIsClinicsFetched } from 'modules/clinics/selectors';
import { getUserInfo, getIsUserFetched } from 'modules/user/selectors';

const mapStateToProps = (state) => {
  const { id } = getUserInfo(state.user);
  return {
    userId: id,
    isUserFetched: getIsUserFetched(state.user),
    areClinicsFetched: getIsClinicsFetched(state.clinics),
    areClinicsFetching: getIsClinicsFetching(state.clinics),
    clinics: getClinics(state.clinics),
  };
};

const mapActionCreators = {
  fetchClinics,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);