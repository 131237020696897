import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import TetherComponent from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import CalendarIcon from 'components/CalendarIcon';
import { InputMask } from 'components/InputMask';

import { DATE_FORMATS } from 'helpers/date/constants';
import Time from 'helpers/date/Time';
import MOMENT_TO_MS_STRATEGIES_TYPES from 'helpers/date-picker/types-strategies';
import datePickerStrategyFactory from 'helpers/date-picker/strategies/factory';

import cx from './Datepicker.module.scss';

const DatePicker = (props) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const { timeZoneDateConverter, timeZone } = useContext(AppContext);
  const inputRef = useRef(null);
  const calendarRef = useClickOutSide({ onOutsideClick: () => setIsOpenDatePicker(prev => !prev), exclude: [inputRef] });

  const {
    onChange,
    isDisabledFutureDates = false
  } = props;

  const handleChange = (e) => {
    if (e instanceof Event) {
      const { value } = e.target;

      onChange(value);
    }
    onChange(e);
  };

  const closeDatePicker = () => setIsOpenDatePicker(false);

  const convertDateFromPickerToMs = (dateAsMoment) => {
    const {
      momentToMsStrategyType = MOMENT_TO_MS_STRATEGIES_TYPES.BASE,
    } = props;

    const momentToMsStrategy = datePickerStrategyFactory(momentToMsStrategyType, timeZoneDateConverter);

    return momentToMsStrategy(dateAsMoment);
  };

  const onDayClick = (dateAsMoment) => {
    const convertedValueToMs = convertDateFromPickerToMs(dateAsMoment);

    handleChange(convertedValueToMs);

    closeDatePicker();
  };

  const checkFutureDate = (current) => {
    const today = timeZoneDateConverter.transformCurrentDate();
    return !current.isAfter(today);
  };

  const isValidDate = (current) =>
    isDisabledFutureDates === false
      ? true
      : checkFutureDate(current);

  const getDateFromValue = () =>
    timeZoneDateConverter.transformByDate(props.value);

  const onBlurAfterEditing = () => {
    const date = getDateFromValue();

    const isInvalidDateAfterEditing = !date.isValid();
    const isDateInFuture = !isValidDate(date);

    if (isInvalidDateAfterEditing || isDateInFuture) {
      onChange('');
    } if (!isInvalidDateAfterEditing && typeof props.value === 'string') {
      const startDayTime = new Time({
        hours: 0, min: 0, sec: 1, ms: 0,
      });
      const convertedValidStringDateToMs = timeZoneDateConverter.getDateWithUTCAndTimeZoneOffset(props.value, startDayTime).valueOf();
      onChange(convertedValidStringDateToMs);
    }
  };

  const toggleDatePicker = () =>
    setIsOpenDatePicker(prev => !prev);

  const formatMsToString = () => {
    const {
      value = undefined,
    } = props;

    if (typeof value === 'number') {
      return value && timeZoneDateConverter.getFormattedDateWithTimeZone(value);
    }

    return value;
  };

  const handleMaskChange = (e) =>
    onChange(e.target.value);

  const {
    isDisabledEditing = false,
  } = props;

  const formattedDate = formatMsToString();

  return (
    <TetherComponent
      classPrefix="datepicker"
      attachment="top left"
      targetAttachment="bottom left"
      renderTarget={ref => (
        <div className={cx['input-wrapper']} ref={ref}>
          <div ref={inputRef}>
            <InputMask
              mask="99/99/9999"
              value={formattedDate}
              onChange={handleMaskChange}
              placeholder={DATE_FORMATS.DEFAULT}
              onClick={toggleDatePicker}
              className={`${cx.input} datepicker__custom-input`}
              onBlur={onBlurAfterEditing}
              disabled={isDisabledEditing}
            />
            <CalendarIcon onClick={toggleDatePicker} />
          </div>

        </div>
      )}
      renderElement={ref => isOpenDatePicker && (
        <span ref={calendarRef}>
            <Datetime
              ref={ref}
              onChange={onDayClick}
              displayTimeZone={timeZone.getTimeZone()}
              dateFormat={DATE_FORMATS.DEFAULT}
              timeFormat={false}
              value={formattedDate}
              className={cx.datepicker}
              open={isOpenDatePicker}
              input={false}
              isValidDate={isValidDate}
            />
          </span>
      )}
    />
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDisabledFutureDates: PropTypes.bool,
  isDisabledEditing: PropTypes.bool,
  momentToMsStrategyType: PropTypes.string,
};

DatePicker.strategies = MOMENT_TO_MS_STRATEGIES_TYPES;

export default DatePicker;
