const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchSettings = () =>
  request.get('/api/rest/clinic/preferences')
    .end();


export const saveSettings = data =>
  request.post('/api/rest/clinic/preferences/update').send(data)
    .end();
