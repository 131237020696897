import * as fromById from './byId/selectors';
import * as fromByPage from './byPage/selectors';
import * as fromSearch from './fromSearch/selectors';
import * as fromIsFetching from './isFetching/selectors';
import * as fromIsFetched from './isFetched/selectors';
import * as fromIsSearching from './isSearching';
import * as fromError from './error';
import * as fromRecent from './recent/selectors';
import * as fromByIsDownloadingImage from 'modules/patients/isDownloadingImage/selectors';
import { createSelector } from 'reselect';

export const getActivePatient = state => state.active;

export const getAllPatients = state => fromById.getAllPatients(state.byId);

export const getPatientById = (state, patientId) => fromById.getPatientById(state.byId, patientId);

export const getPatientsFromSearch = state => fromSearch.getPatientsFromSearch(state.fromSearch);
export const getPatientsFromSearchWithTrueOrder = state => fromSearch.getPatientsFromSearchWithTrueOrder(state.fromSearch);

export const getIsFetching = state => fromIsFetching.getIsFetching(state.isFetching);

export const getIsFetched = state => fromIsFetched.getIsFetched(state.isFetched);

export const getDefaultPatientsForSearchTab = state => fromSearch.getDefault(state.fromSearch);

export const getIsSearching = state => fromIsSearching.getIsSearching(state.isSearching);

export const getSearchQuery = state => fromSearch.getSearchQuery(state.fromSearch);

export const getError = state => fromError.getError(state.error);

export const getRecentPatients = state =>
  fromRecent.getRecentPatientsIds(state.recent)
    .map(patientId => fromById.getPatientById(state.byId, patientId))
    .filter(o => o.id && o.active);

export const getPatientsByPage = (state, page) =>
  fromByPage.getPatientsIdsByPage(state.byPage, page);

export const getTotalPages = state => fromByPage.getTotalPages(state.byPage);

export const isDownloadingImage = state => fromByIsDownloadingImage.fetchIsDownloadingImage(state.isDownloadingImage);

export const selectConsentIds = createSelector(
  [state => state.patients.byId, (state, patientId) => patientId],
  (byId, patientId) => byId[patientId].consentIds
);

export const selectConsentById = createSelector(
  [state => state.patients.byId, (state, patientId) => patientId, (state, patientId, consentId) => consentId],
  (byId, patientId, consentId) => byId[patientId].consents[consentId]

);
