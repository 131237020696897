import React from 'react';
import { func, number, arrayOf, shape } from 'prop-types';

import PatientPlate from '../PatientPlate';

const RecentPatients = (props) => {
  const {
    patients,
    onSelectPatient,
    activePatient,
  } = props;

  return (
    <div>
      {patients.map(patient => (
        <PatientPlate
          key={patient.id}
          active={patient.id === activePatient}
          onClick={onSelectPatient}
          patient={patient}
        />
      ))}

      {!patients.length && (
        <span style={{ padding: '0 16px' }}>No recent patients</span>
      )}
    </div>
  );
};

RecentPatients.propTypes = {
  onSelectPatient: func.isRequired,
  activePatient: number,
  patients: arrayOf(shape({
    id: number.isRequired
  })).isRequired
};

RecentPatients.defaultProps = {
  activePatient: -1
};

export default RecentPatients;
