import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import EmptyMessage from 'components/UserEditForm/EmptyMessage';
import RoleRow from '../RoleRow';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';

import cx from './RolesTable.module.scss';

const tableColumns = [
  {
    title: '',
    subtitle: '',
    sortable: false,
    width: '15%',
  },
  {
    title: 'role',
    subtitle: 'Name',
    sortable: false,
    width: '55%',
  },
  {
    title: '',
    subtitle: '',
    sortable: false,
    width: '30%',
  },
];

const RolesTable = (props) => {
  const roleKeys = Object.keys(props.roles);
  const headerColumns = tableColumns.slice(1);
  const firstColumn = tableColumns[0];

  if (!roleKeys.length) {
    return (
      <div>
        <EmptyMessage>No roles found</EmptyMessage>
      </div>
    );
  }

  const removeRoleWithUsersPermissions = async (role) => {
    await props.removeRole(role.id);

    await props.clearUserPermissionsForRemovedRole(role);
    await props.setIsNeedHideErrors(true);

    props.navigateToRolesTab();
  };

  return (
    <div className="relative">
      <Table color="blue">
        <thead>
          <tr>
            <TableHead {...firstColumn}>
              {<Link className={cx['add-role-button']} to="/app/doctor/settings/users/roles">Add</Link>}
            </TableHead>

            {headerColumns.map(column => (
              <TableHead
                {...column}
                key={column.title}
                textAlign="left"
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {roleKeys.map(id => (
            <RoleRow
              key={id}
              tableColumns={tableColumns}
              role={props.roles[id]}
              navigateToRole={props.navigateToRole}
              removeRole={removeRoleWithUsersPermissions}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

RolesTable.propTypes = {
  roles: PropTypes.object.isRequired,
  removeRole: PropTypes.func.isRequired,
  navigateToRole: PropTypes.func.isRequired,
  clearUserPermissionsForRemovedRole: PropTypes.func.isRequired,
  navigateToRolesTab: PropTypes.func.isRequired,
  setIsNeedHideErrors: PropTypes.func.isRequired,
};

export default RolesTable;
