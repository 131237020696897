import * as api from 'api/social';
import * as constants from './constants';

export const fetchSystems = () => ({
  types: [
    constants.SOCIAL_SYSTEMS_FETCH,
    constants.SOCIAL_SYSTEMS_FETCH_SUCCESS,
    constants.SOCIAL_SYSTEMS_FETCH_FAIL,
  ],
  promise: api.fetchSystems,
});
