import { useOutletContext } from 'react-router';

import { FamilyFormWrapper } from './components/FamilyForm';
import UserEditForm from 'components/UserEditForm';
import AdditionalCheckboxConnector from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxConnector';
import AdditionalEmptyMessageCheckbox from 'components/FMSSPage/AdditionalEmptyMessageCheckbox';
import { FMSS_TYPES } from 'helpers/fmss/constants';
import {
  FamilyListWrapper
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/routes/Family/components/FamilyList';

const CheckboxWrapper = AdditionalCheckboxConnector(
  AdditionalEmptyMessageCheckbox, FMSS_TYPES.FAMILY
);

 export const FamilyComponent = () => {
  const { patientId, linkBase } = useOutletContext();

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Family Hx', indexLink: true },
        { url: { pathname: `${linkBase}/medical` }, name: 'Medical Hx' },
        { url: { pathname: `${linkBase}/social` }, name: 'Social Hx' },
        { url: { pathname: `${linkBase}/surgical` }, name: 'Surgical Hx' },
        { url: { pathname: `${linkBase}/screening` }, name: 'Screening Hx' },
      ]}
      formComponent={<FamilyFormWrapper />}
      customLabelRenderer={<CheckboxWrapper label="No Pertinent Family Medical Hx" patientId={patientId} />}
    >
      <FamilyListWrapper  />
    </UserEditForm.Root>
  );
};

export const FamilyRoute = {
  index: true,
  element: <FamilyComponent />
};
