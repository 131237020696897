import { handleActions } from 'redux-actions';

export const REGISTRATION_STEP_SET = 'REGISTRATION_STEP_SET';

export const setRegistrationStep = step => ({
  type: REGISTRATION_STEP_SET,
  payload: step,
});

export default handleActions({
  [REGISTRATION_STEP_SET]: (state, action) => action.payload,
}, 0);

export const getRegistrationStep = state => state;
