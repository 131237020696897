import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Switch = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      navigate('/app');
    }
  }, []);

  return (
    <>
      <Outlet/>
    </>
  );
};

export default Switch;
