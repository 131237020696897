import React from 'react';
import PropTypes from 'prop-types';

import { defineUserVerifiedEmailStatus } from 'helpers/users/users';

const VerifyEmailStatus = (props) => {
  const {
    verifyEmail = true,
  } = props;

  const verifyEmailStatus = defineUserVerifiedEmailStatus(verifyEmail);

  return (
    <div>
      <span>{verifyEmailStatus}</span>
    </div>
  );
};

VerifyEmailStatus.propTypes = {
  verifyEmail: PropTypes.bool,
};

export default VerifyEmailStatus;
