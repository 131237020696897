import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import cx from './ImportModal.module.scss';

export const ImportModal = (props) => {
  const ON_SYNC_MESSAGE = 'We are ready to synchronize your DrChrono patients and their information to EHRsynergy, this process may take up to 5-min.'
    + 'To ensure the integrity of the synchronization, please make sure that no one uses DrChrono or EHRsynergy for 5-min after clicking on "proceed".';

  const MODAL_SETTINGS = {
    type: '',
    title: 'Sync',
    content: ON_SYNC_MESSAGE,
  };

  const turnSyncProcess = async (turnDirection) => {
    const {
      turnSync,
      closeImportModal
    } = props;

    turnSync(turnDirection);
    closeImportModal()
  };

  const {
    importStatus,
  } = props;

  const {
    isShowModal,
  } = importStatus;

  return (
    <Modal
      isOpen={isShowModal}
      data={MODAL_SETTINGS}
      onModalClose={() => {}}
      onModalConfirm={() => {}}
    >
      <div className={cx.content}>
        <p>
          {ON_SYNC_MESSAGE}
        </p>
        <button
          type="button"
          className={cx['proceed-button']}
          onClick={() => turnSyncProcess(true)}
        >
          Proceed
        </button>
        <button
          type="button"
          className="generic-button"
          onClick={() => turnSyncProcess(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

ImportModal.propTypes = {
  closeImportModal: PropTypes.func.isRequired,
  turnSync: PropTypes.func.isRequired,
  importStatus: PropTypes.shape({
    isShowModal: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ImportModal;
