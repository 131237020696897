import compareObjectsByParams from 'helpers/common/object/compare';
import compareDatesToDuplicateByYear from 'helpers/date/compare/by-year';

const duplicateMedicalItemComparator = (medicalItemOne, medicalItemTwo) => {
  const {
    illness: illnessOne,
    startDate: startDateOne,
  } = medicalItemOne;

  const {
    illness: illnessTwo,
    startDate: startDateTwo,
  } = medicalItemTwo;

  return !compareObjectsByParams(medicalItemOne, medicalItemTwo, ['id']) &&
    compareObjectsByParams(illnessOne, illnessTwo, ['id']) &&
    compareDatesToDuplicateByYear(startDateOne, startDateTwo);
};

export default duplicateMedicalItemComparator;
