import { ALLERGY_STATUS, EMPTY_MESSAGE } from 'helpers/fmss/allergies/constants.js';

export default class EmptyMessageByFilter {
  static getMessage = (filter) => {
    switch (filter) {
      case ALLERGY_STATUS.active:
        return EMPTY_MESSAGE.activeAllergies;
      case ALLERGY_STATUS.inactive:
        return EMPTY_MESSAGE.inactiveAllergies;
      default:
        return EMPTY_MESSAGE.activeAllergies;
    }
  }
}
