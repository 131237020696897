import React from 'react';
import PropTypes from 'prop-types';

export const patientTabFetchLayerHOC = (Component) => {
  return class PatientsTabFetchLayer extends React.Component {
    static propTypes = {
      fetchPatientsPage: PropTypes.func.isRequired,
      fetchPatientImage: PropTypes.func.isRequired,
    };

    componentDidMount = async () => {
      const { isFetched, isFetching } = this.props;

      if (isFetched || isFetching) return;
      await this.props.fetchPatientsPage(0);
    };

    render() {
      const {
        props,
      } = this;

      return <Component {...props} />;
    }
  };
};
