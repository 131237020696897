import React from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import DrChronoFetchTokenProps from 'containers/DrChrono/DrChronoFetchTokenProps';

class DrChronoFetchToken extends React.Component {
  componentDidMount = async () => {
    if (this.isNeedForFetchingToken()) {
      await this.props.fetchDrChronoToken();

      this.handleFetchTokenResponseByStatus();
    }
  };

  handleFetchTokenResponseByStatus = () => {
    if (this.props.isShouldShowWarning) {
      this.showNoConnectionWarning();
    }
  }

  showNoConnectionWarning = () =>
    this.context.setInfoModalData({
      message: 'You have no logged DrChrono account',
      title: 'Warning',
    });

  isNeedForFetchingToken = () => {
    const {
      isDrChronoTokenFetching,
      isDrChronoTokenFetched,
    } = this.props;

    return !isDrChronoTokenFetching && !isDrChronoTokenFetched;
  };

  render = () => <></>;
}

DrChronoFetchToken.propTypes = {
  fetchDrChronoToken: PropTypes.func.isRequired,
  isDrChronoTokenFetching: PropTypes.bool,
  isDrChronoTokenFetched: PropTypes.bool,
  isShouldShowWarning: PropTypes.bool.isRequired,
};

DrChronoFetchToken.defaultProps = {
  isDrChronoTokenFetching: false,
  isDrChronoTokenFetched: false,
};

DrChronoFetchToken.contextType = AppContext;


export default DrChronoFetchTokenProps(DrChronoFetchToken);

