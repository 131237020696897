import compareObjectsByParams from 'helpers/common/object/compare';
import compareDatesToDuplicateByYear from 'helpers/date/compare/by-year';

const duplicateScreeningItemComparator = (screeningItemOne, screeningItemTwo) => {
  const {
    screeningDetail: screeningDetailOne,
    startDate: startDateOne,
  } = screeningItemOne;

  const {
    screeningDetail: screeningDetailTwo,
    startDate: startDateTwo,
  } = screeningItemTwo;

  return !compareObjectsByParams(screeningItemOne, screeningItemTwo, ['id']) &&
    compareDatesToDuplicateByYear(startDateOne, startDateTwo) &&
    compareObjectsByParams(screeningDetailOne, screeningDetailTwo, ['id']);
};

export default duplicateScreeningItemComparator;
