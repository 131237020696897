import React from 'react';
import { compose } from 'redux';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import allergiesContainer, { withAllergiesFetchLayer } from 'containers/Allergies/AllergiesContainer';
import { AllergiesList } from 'containers/Allergies/AllergiesList';
import { useParams, useSearchParams } from 'react-router-dom';

const AllergiesWrapper = compose(
  chartToPatientContainer,
  allergiesContainer,
  withAllergiesFetchLayer
)(AllergiesList);

export const ChartAllergiesListWrapper = () => {
  const { chartId, filter } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <AllergiesWrapper
      chartId={chartId}
      filter={filter === 'inactive' ? 'inactive' : 'active'}
      allergyId={searchParams.get('allergy')}
    />
  );
};
