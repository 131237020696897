export const getIsAuthenticated = state => state.isAuthenticated;

export const getIsSuperAdmin = state => state.isSuperAdmin;

export const getIsAuthenticating = state => state.isAuthenticating;

export const getLoginError = state => state.loginError;

export const getLoginMessage = state => state.loginMessage;

export const getSignupError = state => state.signupError;

export const getSignupMessage = state => state.signupMessage;

export const getConfirmError = state => state.confirmError;

export const getConfirmMessage = state => state.confirmMessage;

export const getRecoverError = state => state.recoverError;

export const getRecoverMessage = state => state.recoverMessage;

export const getRecoveryTokenValidationError = state => state.recoveryTokenValidationError;

export const getRecoveryTokenValidationMessage = state => state.recoveryTokenValidationMessage;
