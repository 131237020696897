import React, { useEffect } from 'react'

const withDrChronoOffices = Component => props => {
  const { fetchOffices, fetchActiveOffice } = props

  useEffect(() => {
    const fetchOfficesWrapper = async () => {
      await fetchOffices()
      await fetchActiveOffice()
    }

    fetchOfficesWrapper()
  }, [])

  return <Component {...props} />
}

export default withDrChronoOffices
