import { handleActions } from 'redux-actions';

const CHART_REPORT_TOGGLE = 'CHART_REPORT_TOGGLE';

export const toggleChartReport = () => ({
  type: CHART_REPORT_TOGGLE,
});

export default handleActions({
  [CHART_REPORT_TOGGLE]: state => !state,
}, false);

export const isChartReportVisible = state => state;
