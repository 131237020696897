export default class DisableEditingElement {
  constructor(elementUserId, currentLoggedUserId, social) {
    this.elementUserId = elementUserId;
    this.currentLoggedUserId = currentLoggedUserId;
    this.social = social;
  }

  isDisabled = () =>
    this.social && this.elementUserId !== this.currentLoggedUserId;
}
