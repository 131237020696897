import React from 'react';

import ShortPatientInfo from 'routes/routes/App/components/PatientsSearch/PatientInfo/components/ShortPatientInfo';
import CrossButton from 'components/CrossButton';

import cx from './PatientInfo.module.scss';

const PatientInfo = ({
  patient,
  closePatientInfo,
  permissions,
}) => {
  if (!patient) return null;

  return (
      <div className={cx.wrapper}>
        <div className={cx.cross}>
          <CrossButton onClick={closePatientInfo} />
        </div>
        <ShortPatientInfo patient={patient} permissions={permissions} />
      </div>
  );
};

export default PatientInfo;
