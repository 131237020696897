import * as actions from 'modules/pastdue/actions.js';
import { paymentAllSeat } from 'modules/seats/actions.js';

import * as selectors from 'modules/pastdue/selectors.js';
import * as seatsSelectors from 'modules/seats/selectors.js';
import { connect } from 'react-redux';
import { doPayment } from 'modules/financial/paymentInfo/actions';

const mapStateToProps = (state) => {
  return {
    pastDue: selectors.getPastDue(state),
    seats: seatsSelectors.getSeats(state.seats),
  };
};

const mapDispatchToProps = {
  ...actions,
  paymentAllSeat,
  doPayment
};

export default component => connect(mapStateToProps, mapDispatchToProps)(component);
