import React from 'react';
import PropTypes from 'prop-types';

import { difference, isEmpty, isEqual, orderBy, union, uniqBy, without } from 'lodash';

import { withRouter } from 'containers/RouterParams';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import { EditModeContext } from 'components/ElementsViewItem/context';

import Loader from 'components/Loader';
import Item from 'components/ElementsViewItem';
import SubelementsList from 'components/SubelementsList';
import Modal from 'components/Modal';
import EditModeDoneButton from 'components/EditModeDoneButton';
import SearchForm from 'components/NoteSystems/SearchForm';
import PlainItem from '../ElementsViewItem/PlainItem';
import PlainRegularElement from '../ElementsViewItem/PlainRegularElement';
import NewItemForm from './NewItemForm';

import {
  isTheSameSystem,
  hpChartTabs,
  isTheSameTab,
  isROS,
  isRv
} from 'helpers/chart';
import {
  displayFavoritesOnly,
  getFavoritesArray,
  getItemMatchCurried,
  sortItems
} from 'helpers/favorites/favoritesHelpers';
import { getDataForMergeElementsRequest, getDataForLocalUpdate } from 'helpers/charting/charting';
import HiddenItemsFormatter from 'helpers/chart/rightSideComponent/hiddenItemsFormatter';
import SearchableItemsForRightSideSearch from 'helpers/chart/rightSideComponent/searchableItemsForRightSideSearch';
import DisableCreationElementAndSubElement from 'helpers/elements/disable-creation';
import getFavoriteIdBySystem from 'helpers/favorites/get-favorite-id-by-system';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { elementsViewItemActionsModalData } from 'components/ElementsViewItemActions/data';
import {
  isNullOrUndefined,
  putTheFirstPositionForSubElement,
  getAllIndexesToAutoSetSubElement,
  deleteElementsFromArrayByIds,
  parseElementNameWithVariables,
  getObjectsIds
} from 'helpers';

import { resetFavorites as resetFavoritesAPI } from 'api/charting';

import cx from './ElementsViewItems.module.scss';

const buttonStyles = ({ isActiveFilter }) => resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['filter-button', isActiveFilter && 'filter-button--active']
});

const itemStyles = ({ isSelected, isHidden }) => resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['item', isSelected && 'item--selected', isHidden && 'item--hidden']
});

const { keys } = Object;

const ItemWithRouter = withRouter(Item);

const styles = ({ className }) => ({
  wrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['wrapper'],
    globalStyles: className
  })
});

const displayAddFavoritesButtonConditions = {
  [hpChartTabs.HXRV.step]: systemId => Number.isInteger(systemId),
  [hpChartTabs.A.step]: systemId => Number.isInteger(systemId),
  [hpChartTabs.RV.step]: () => false
};

class ElementsViewItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenItems: props.activeItems,
      filter: undefined,
      isEditMode: false,
      currentEditModeAction: elementsViewItemActionsModalData.resetFavorite.id,
      isEditModeDoneBtnDisabled: true,
      currentItemForEditMode: {},
      itemsForMerge: [],
      isOpenModalForMergeElements: false,
      elementsAddedFromSearch: [],
      // blocked: false,
    };

    this.subelementsRef = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const systemChanged = this.props.systemId !== nextProps.systemId;
    const stepChanged = this.props.stepId !== nextProps.stepId;
    const addedElements = difference(this.props.activeItems, nextProps.activeItems);
    const removedElements = difference(nextProps.activeItems, this.props.activeItems);

    // if item should appear we just remove it from hiddenItems
    if (addedElements.length) {
      this.setState(state => ({
        hiddenItems: without(state.hiddenItems, ...addedElements),
      }));
    }

    // if active system is changed or step changed we should just hide all active elements
    if ((stepChanged || systemChanged) && removedElements.length) {
      this.setState({
        hiddenItems: nextProps.activeItems,
      });
    }

    if (systemChanged) {
      this.setState({ filter: undefined });
    }

    if (systemChanged || stepChanged) {
      this.scrollable_wrapper.scrollTop = 0;
    }

    if (!nextProps.systemId || systemChanged) {
      this.setEditModeByDefault();
    }

    // reset timer
    this.flush();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEditMode } = this.state;
    
    if (isEditMode !== prevState.isEditMode && isEditMode === false) {
      this.setEditModeByDefault();
    }

    if (this.isItemsForMergeChanged(prevState) === true) {
      this.scrollable_wrapper.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  onSubelementSelect = async (subelement, item) => {
    const {
      social,
      systemId,
      addElement,
      selectSubElementCallback,
    } = this.props;

    const newSubElement = putTheFirstPositionForSubElement(subelement, social);
    await addElement(systemId, {
      id: item.id,
      // if type is undefined then regular element was clicked
      // and for such elements we should set type to null
      // else it is either true or false
      // which means pos/neg element was clicked
      type: this.state.typeToAdd !== undefined ? this.state.typeToAdd : null,
      subIds: [newSubElement],
      name: item.name,
      userId: this.context.currentUserId,
      favoriteId: item.favoriteId,
    });
    this.closeSubelements();
    const indexesToAutoSet = getAllIndexesToAutoSetSubElement(item.name);
    this.props.addIndexToAutoSet(indexesToAutoSet, item.id);
    selectSubElementCallback(item, subelement);
  };

  setElementsAddedFromSearch = (elements) => {
    this.setState({
      elementsAddedFromSearch: [...elements],
    });
  };

  getComponentForRenderAsItem = (currentItem, getItemMatch, isSelected) => {
    const {
      systemId,
      stepId,
      updateItem,
      location,
    } = this.props;

    const isDisplayCheckboxForEditMode = this.isDisplayCheckboxForEditMode();

    if (isDisplayCheckboxForEditMode === false) {
      const favoriteId = getFavoriteIdBySystem(systemId, currentItem);
      const itemMatch = getItemMatch(favoriteId);

      if (systemId) {
        return (
          <PlainItem
            isROS={isROS(stepId)}
            activeSystem={systemId}
            onClick={type => this.handleOnClickByElement(isSelected, currentItem, type)}
            elementViewItem={currentItem}
            match={itemMatch}
          />
        );
      }

      const {
        isEditMode
      } = this.state;

      return (
        <ItemWithRouter
          item={currentItem}
          activeSystem={systemId}
          onClick={type => this.handleItemClick(currentItem, type)}
          match={itemMatch}
          location={location}
          isROS={isROS(stepId)}
          isEditMode={isEditMode}
          updateItem={updateItem}
        />
      );
    }

    const minimalConditionsForEditModeState = this.minimalConditionsForEditModeState();

    return (
      <EditModeContext.Provider value={{    
        actions: this.props.editMode.actions,
        currentEditModeAction: this.state.currentEditModeAction,
        editModeRemoveElementAction: this.editModeRemoveElementAction,
        editModeMergeElementsAction: this.editModeMergeElementsAction,
      }}>
        <PlainRegularElement
          element={currentItem}
          currentItemForMerge={this.state.currentItemForEditMode}
          setItemsForMerge={this.handleChangingInItemsForMerge}
          isCheckboxToMergeItemsClicked={this.isCheckboxToMergeItemsClicked(currentItem)}
          minimalConditionsForEditModeState={minimalConditionsForEditModeState}
          setCurrentEditModeAction={this.setCurrentEditModeAction}
          updateItemsForMergeInState={this.updateItemsForMergeInState}
          setIsEditModeDoneBtnDisabled={this.setIsEditModeDoneBtnDisabled}
          closeEditModeModalForMergeElements={this.closeEditModeModal}
          setCurrentItemForEditMode={this.setCurrentItemForEditMode}
          isOpenModalForMergeElements={this.state.isOpenModalForMergeElements}
          editModeResetFavoritesAction={this.editModeResetFavoritesAction}
          isEditModeDoneBtnDisabled={this.state.isEditModeDoneBtnDisabled}
          handleMergeElements={this.handleMergeElements}
          itemsForMerge={this.state.itemsForMerge}
        />
      </EditModeContext.Provider>
    );
  };

  setCurrentEditModeAction = (currentEditModeAction) => {
    this.setState({
      currentEditModeAction,
    });
  };

  setCurrentItemForEditMode = (currentItemForMerge) => {
    let currentItemForEditMode = currentItemForMerge;

    if (!isEmpty(currentItemForMerge) && currentItemForMerge.id === this.state.currentItemForEditMode.id) {
      currentItemForEditMode = {};
    }

    this.setCurrentItemForEditModeInState(currentItemForEditMode);
  };

  setCurrentItemForEditModeInState = (currentItemForEditMode) => {
    this.setState({
      currentItemForEditMode,
    });
  };

  getNameForEditModeButton = () => {
    return this.state.isEditMode === true ? 'Cancel' : 'Edit';
  };

  getSortedItems = (filteredItems, exactMatch, broadMatch, systemId, getItemMatch) => {
    if (!systemId === true && isEmpty(broadMatch) && isEmpty(exactMatch)) {
      return orderBy(filteredItems, ['order']);
    }

    return sortItems(
      filteredItems,
      exactMatch,
      broadMatch,
      systemId,
      getItemMatch
    );
  };

  setEditModeByDefault = () => {
    this.setState({
      isEditMode: false,
    });

    this.setCurrentEditModeAction(elementsViewItemActionsModalData.resetFavorite.id);

    this.setCurrentItemForEditMode({});

    this.resetMergeElementsActionMode();
  };

  setIsEditModeDoneBtnDisabled = (isTheSameOrigin) => {
    this.setState({
      isEditModeDoneBtnDisabled: !isTheSameOrigin,
    });
  };

  getIsModalOpen = (item) => {
    const {
      isChartSaving,
      disableSubelements,
    } = this.props;

    return (
        (!disableSubelements
          && this.state.subelementsOpened === item.id)
        || this.state.subelementsModalOpened === item.id
      )
      && isChartSaving === false;
  };

  handleOnClickByElement = (isSelected, currentItem, type) =>
    !isSelected && this.handleItemClick(currentItem, type);

  isItemsForMergeChanged = (prevState) => {
    const {
      currentItemForEditMode,
      itemsForMerge,
    } = prevState;

    const isCurrentItemForEditModeChanged = !isEqual(this.state.currentItemForEditMode, currentItemForEditMode);

    const isItemsForMergeChanged = !isEqual(this.state.itemsForMerge, itemsForMerge);

    return isCurrentItemForEditModeChanged === true || isItemsForMergeChanged === true;
  };

  updateElementsAddedFromSearch = (elements) => {
    this.setState(prevState => ({
      elementsAddedFromSearch: [...prevState.elementsAddedFromSearch, ...elements],
    }));
  };

  clearElementsAddedFromSearch = (elementsIds) => {
    const {
      elementsAddedFromSearch,
    } = this.state;

    const updatedElementsAddedFromSearch = deleteElementsFromArrayByIds(elementsAddedFromSearch, elementsIds);

    this.setElementsAddedFromSearch(updatedElementsAddedFromSearch);
  };

  updateEditModeDoneButtonStatus = (newItemsForMerge) => {
    const isEnoughItemsForMerge = this.isEnoughLengthItemsForMerge(newItemsForMerge);

    this.setIsEditModeDoneBtnDisabled(isEnoughItemsForMerge);
  };

  isEnoughLengthItemsForMerge = itemsForMerge =>
    !(itemsForMerge && itemsForMerge.length === 0);

  handleChangingInItemsForMerge = (item) => {
    if (this.state.currentEditModeAction !== elementsViewItemActionsModalData.mergeElements.id) {
      return;
    }

    const newItemsForMerge = this.handleItemsForMergeWithNewItem(item);

    this.updateItemsForMergeInState(newItemsForMerge);

    this.updateEditModeDoneButtonStatus(newItemsForMerge);
  };

  addNewItemToItemsForMerge = (newItemsForMerge, newItem) => {
    newItemsForMerge.push(newItem);
  };

  deleteItemsFromItemsToMerge = (newItemsForMerge, newItem) => {
    return newItemsForMerge.filter(item => item.id !== newItem.id);
  };

  isExistedItem = (newItem) => {
    return this.state.itemsForMerge.filter(currentItem => currentItem.id === newItem.id).length;
  };

  updateItemsForMergeInState = (newItemsForMerge) => {
    this.setState({
      itemsForMerge: newItemsForMerge,
    });
  };

  handleItemsForMergeWithNewItem = (item) => {
    const newItemsForMerge = [...this.state.itemsForMerge];

    if (!this.isExistedItem(item)) {
      this.addNewItemToItemsForMerge(newItemsForMerge, item);

      return newItemsForMerge;
    }

    return this.deleteItemsFromItemsToMerge(newItemsForMerge, item);
  };

  resetMergeElementsActionMode = () => {
    if (this.state.currentEditModeAction === elementsViewItemActionsModalData.mergeElements.id) {
      this.updateItemsForMergeInState([]);

      this.updateEditModeDoneButtonStatus([]);
    }
  };

  clearTimer = () => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  };

  flush = (isItemSelected) => {
    if (!isItemSelected) {
      this.clearTimer();
      this.timer = setTimeout(() => {
        this.setState({ hiddenItems: this.props.activeItems });
      }, 3000);
    }
  };

  toggleFilter = (filter) => {
    this.setState(prevState => ({
      filter: prevState.filter === filter ? undefined : filter,
    }));
  };

  closeSubelements = () => {
    this.setState({
      subelementsOpened: undefined,
      setNumber: undefined,
    });
  };

  handleItemClick = async (item, type) => {
    const {
      addSystem,
      addElement,
      systemId,
      disableSubelements,
      addRightSideElementCallback,
    } = this.props;

    // systemId determines if item is system or element
    // we handle click accordingly
    if (!systemId) {
      addSystem(item.id);
      return;
    }

    this.addItemToSocialSystem(item);

    if (!disableSubelements && item.name.includes('??')) {
      const { setNumber } = parseElementNameWithVariables(item.name).variables[0];

      this.setState({
        subelementsOpened: item.id,
        setNumber,
        typeToAdd: type,
      });
      return;
    }

    await addElement(systemId, { ...item, type });

    addRightSideElementCallback(item.id);
  };

  addItemToSocialSystem = (item) => {
    const {
      social,
    } = this.props;

    if (social) {
      const {
        systemId,
        patientId,
        selectSocialElement,
        chartId
      } = this.props;

      const data = {
        patientId,
        socialId: systemId,
        elementId: item.id,
        chartId
      };
      selectSocialElement(data);
    }
  };

  resolveElementType = stepId => isROS(stepId) ? false : null;

  handleAddFavorites = async (items, getItemMatch) => {
    const {
      systemId,
      addSystem,
      addElement,
      disableSubElements,
      chartId,
      stepId,
      addRightSideElementCallback
    } = this.props;

    let isNeedSave = false;

    await items.forEach((item) => {
      const match = getItemMatch(getFavoriteIdBySystem(systemId, item));

      if (match !== 'exact') return;

      if (!systemId) {
        addSystem(item.id);
        return;
      }

      if (!disableSubElements && item.name.includes('??') && !!chartId) {
        return;
      }

      addElement(systemId, {
        ...item,
        type: this.resolveElementType(stepId),
      });

      isNeedSave = true;
    });

    if (isNeedSave) {
      addRightSideElementCallback();
    }
  };

  changeEditMode = () => {
    this.setState(prevState => ({
      isEditMode: !prevState.isEditMode,
    }));
  };

  isDisplayCheckboxForEditMode = () => {
    return this.minimalConditionsForEditModeState() === true;
  };

  isGrantAccessForEditMode = () => {
    const {
      systemId,
      social,
    } = this.props;

    if (social === true && systemId) {
      return true;
    }

    const grantForSystems = this.isGrantForSystems();
    const checkPermissions = this.checkPermissionsForEditMode();
    const { isCurrentUserProvider } = this.context;

    return grantForSystems && systemId && isCurrentUserProvider && checkPermissions;
  };

  isGrantForSystems = () => {
    const {
      stepId,
      systemId,
      system,
    } = this.props;

    const isIcdTen = systemId && system && isTheSameSystem(system.name, hpChartTabs.A.systems.ICD_TEN.name);
    const isPlanPrescription = systemId && isTheSameSystem(system.name, hpChartTabs.Plan.systems.PRESCRIPTIONS.name);
    const isCpt = systemId && isEqual(system.type, hpChartTabs.BILLING.systems.CPT.type);
    const isPqrs = stepId && isTheSameTab(stepId, hpChartTabs.BILLING.step);

    return !(isIcdTen || isPlanPrescription || isPqrs || isCpt);
  };

  checkPermissionsForEditMode = () => {
    const { addEditElements } = this.context.permissions;

    return addEditElements === true;
  };

  editModeResetFavoritesAction = ({ id, favoriteId }) => {
    const {
      system,
      resetFavorites,
      chartId,
      favoritesToReset,
    } = this.props;

    const isPlanPrescription = false;

    resetFavoritesAPI(id, system.chartingId, isPlanPrescription);

    const combinedFavoritesToReset = union(favoritesToReset, [favoriteId]);
    resetFavorites({ chartId, favoritesToReset: combinedFavoritesToReset });
  };

  editModeRemoveElementAction = (elementId) => {
    this.sendRequestToRemoveElement(elementId);
    this.clearElementsAddedFromSearch([elementId]);
  };

  sendRequestToRemoveElement = (elementId) => {
    const {
      deleteRightSideElement,
    } = this.props;

    const dataToRequest = this.collectDataToRequestToRemoveRightSideElement(elementId);

    return deleteRightSideElement(dataToRequest);
  };

  collectDataToRequestToRemoveRightSideElement = (elementId) => {
    const {
      chartId,
    } = this.props;

    return {
      chartId,
      id: elementId,
    };
  };

  editModeMergeElementsAction = () => {
    this.deleteElementsInChartingAsset();
  };

  deleteElementsInChartingAsset = () => {
    const {
      step,
      deleteSeveralRightSideElements,
    } = this.props;

    const {
      currentItemForEditMode,
      itemsForMerge,
    } = this.state;

    const dataForRequest = getDataForMergeElementsRequest(
      step, currentItemForEditMode.favoriteId, itemsForMerge
    );
    const dataForLocalUpdate = getDataForLocalUpdate(itemsForMerge);

    deleteSeveralRightSideElements(dataForRequest, dataForLocalUpdate);
    const elementsIdsToDeleteFromSearchableItems = getObjectsIds(itemsForMerge);
    this.clearElementsAddedFromSearch(elementsIdsToDeleteFromSearchableItems);
  };

  isDisplayEditModeDoneBtn = () => {
    const {
      currentEditModeAction,
    } = this.state;

    return elementsViewItemActionsModalData.mergeElements.id === currentEditModeAction && this.minimalConditionsForEditModeState() === true;
  };

  minimalConditionsForEditModeState = () => {
    return this.state.isEditMode === true && this.isGrantAccessForEditMode() === true;
  };

  isCheckboxToMergeItemsClicked = (itemToRender) => {
    return this.state.itemsForMerge.filter(item => item.id === itemToRender.id).length > 0;
  };

  openModalForMergeElements = () => {
    this.setState({
      isOpenModalForMergeElements: !this.state.isOpenModalForMergeElements,
    });
  };

  handleMergeElements = () => {
    this.openModalForMergeElements();
  };

  closeEditModeModal = () => {
    this.setState({
      isOpenModalForMergeElements: false,
    });
  };

  addElementFromRightSideSearch = (element) => {
    this.updateElementsAddedFromSearch([element]);

    this.handleChangingInItemsForMerge(element);

    this.addToCurrentItemForEditMode(element);
  };

  addToCurrentItemForEditMode = (element) => {
    if (this.state.currentEditModeAction !== elementsViewItemActionsModalData.mergeElements.id) {
      this.setCurrentItemForEditMode(element);
    }
  };

  mergeItemsWithAddedFromSearch = (sortedItems) => {
    const {
      currentItemForEditMode,
      elementsAddedFromSearch,
      itemsForMerge,
      isEditMode,
    } = this.state;

    if (isEditMode === false) {
      return sortedItems;
    }

    const currentItemForEditModeInArray = this.putCurrentItemForEditModeToArray(currentItemForEditMode);

    const combinedItems = [...currentItemForEditModeInArray, ...itemsForMerge, ...elementsAddedFromSearch, ...sortedItems];

    return uniqBy(combinedItems, 'id');
  };

  putCurrentItemForEditModeToArray = (currentItemForEditMode) => {
    const currentItemForEditModeInArray = [];

    if (!isEmpty(currentItemForEditMode)) {
      currentItemForEditModeInArray.push(currentItemForEditMode);
    }

    return currentItemForEditModeInArray;
  };

  isFavoritesFilterPresent = ({ filter }) => filter === 'favorites';

  isDisplayAddFavoritesButtonWrapper = ({ social, stepId, systemId, isEditMode }) => {
    if (isEditMode || social) {
      return false;
    }
    const condition = displayAddFavoritesButtonConditions[stepId];

    return isNullOrUndefined(condition) ? true : condition(systemId);
  };

  render() {
    const {
      items,
      isFetching,
      systemId,
      activeItems,
      stepId,
      className,
      createSubelement,
      chartId,
      creatable,
      filterable,
      createdItem,
      createItem,
      systemType,
      social,
      patientId,
      updateSocialSubElementsHistory,
      saveChart,
      broadMatch,
      exactMatch,
    } = this.props;

    const isSystems = !systemId;

    const {
      toggleFilter,
      handleAddFavorites,
      getNameForEditModeButton,
      isGrantAccessForEditMode,
      isDisplayEditModeDoneBtn,
      handleMergeElements,
      getIsModalOpen,
    } = this;

    const fetching = isFetching || isFetching === undefined;

    const {
      hiddenItems,
      filter,
      isEditModeDoneBtnDisabled,
      isEditMode,
      itemsForMerge,
      currentItemForEditMode,
    } = this.state;

    const broadItems = getFavoritesArray(broadMatch);
    const exactItems = getFavoritesArray(exactMatch);

    let filteredItems = (filter && items.filter((item) => {
      return item[filter];
    })) || items;

    if (this.isFavoritesFilterPresent({ filter })) {
      filteredItems = displayFavoritesOnly(items, exactMatch, broadMatch, systemId);
    }

    const isRV = isRv(stepId);

    const getItemMatch = getItemMatchCurried(broadItems, exactItems, isRV);
    let sortedItems = this.getSortedItems(filteredItems, exactMatch, broadMatch, systemId, getItemMatch);

    const actualItems = sortedItems.filter(o => !activeItems.includes(o.id));

    let elementsLimit = 20;
    let maxLimit = 100;

    if (isRV) {
      maxLimit = 50;
    }

    if (!isSystems && exactMatch && broadMatch) {
      const exactLength = keys(exactMatch).length;
      const broadLength = keys(broadMatch).length > 20 ? 20 : keys(broadMatch).length;

      elementsLimit += exactLength + broadLength;

      elementsLimit = elementsLimit > maxLimit ? maxLimit : elementsLimit;

      sortedItems = sortedItems.slice(0, elementsLimit);
    }

    if (systemId) {
      sortedItems = this.mergeItemsWithAddedFromSearch(sortedItems);
    }

    const defaultLimit = 50;
    sortedItems = sortedItems.slice(0, defaultLimit);

    const hiddenItemsFormatter = new HiddenItemsFormatter(filteredItems, hiddenItems);
    const formattedHiddenItems = hiddenItemsFormatter.formatHiddenItemsAsPlainElements();
    const searchableItemsForRightSideSearch = new SearchableItemsForRightSideSearch(
      filteredItems, itemsForMerge, currentItemForEditMode, formattedHiddenItems
    );
    const searchableItems = searchableItemsForRightSideSearch.getSearchableItems();

    const disableSubelementCreation = DisableCreationElementAndSubElement.isDisabled(social, this.context.permissions);

    const resolvedStyles = styles({ className });

    const isDisplayAddFavoritesButton = this.isDisplayAddFavoritesButtonWrapper({ social, stepId, systemId, isEditMode });

    return (
        <div className={resolvedStyles.wrapper}>
          <div className={cx['list-wrapper']}>
            <div
                className={cx['scrollable-wrapper']}
                ref={(node) => {
                  this.scrollable_wrapper = node;
                }}
            >
              <div
                  className={cx['filter-button-wrapper']}
              >
                {isDisplayAddFavoritesButton &&
                    <button
                        type="button"
                        style={{
                          marginLeft: 10,
                        }}
                        onClick={() => handleAddFavorites(actualItems, getItemMatch)}
                        className={cx['filter-button']}
                    >
                      Add Favorites
                    </button>
                }

                {isGrantAccessForEditMode() && (
                    <button
                        type="button"
                        style={{
                          marginLeft: 10,
                        }}
                        onClick={this.changeEditMode}
                        className={cx['filter-button']}
                    >
                      {getNameForEditModeButton()}
                    </button>
                )}

                {isDisplayEditModeDoneBtn() && (
                    <EditModeDoneButton
                        isEditModeDoneBtnDisabled={isEditModeDoneBtnDisabled}
                        handleMergeElements={handleMergeElements}
                    />
                )}

                {isDisplayEditModeDoneBtn() && (
                    <p>
                      Select Additional Elements to Merge
                    </p>
                )}

                {isEditMode && (
                    <div className={cx['search-form__wrapper']}>
                      <SearchForm
                          activeElements={[]}
                          activeSystem={systemId}
                          ref={null}
                          searchableItems={searchableItems}
                          isROS={false}
                          addElement={this.addElementFromRightSideSearch}
                          systemType={systemType}
                          allElements={filteredItems}
                          createElement={() => {
                          }}
                          allowCreation={false}
                          fetchResources={() => {
                          }}
                          systemId={systemId}
                          isFetching={isFetching}
                          exactMatch={exactMatch}
                          broadMatch={broadMatch}
                          step={stepId}
                          chartId={chartId}
                          createSubelement={() => {
                          }}
                          social={social}
                          patientId={patientId}
                          useCheck
                      />
                    </div>
                )}

                {filterable &&
                    <button
                        type="button"
                        onClick={() => toggleFilter('active')}
                        className={buttonStyles({ isActiveFilter: filter === 'active' })}
                    >
                      {(systemType === 1 && 'Pt Dx History') || 'History'}
                    </button>
                }

                {filterable && systemType === 1 && (
                    <button
                        type="button"
                        style={{
                          marginLeft: 10,
                        }}
                        onClick={() => toggleFilter('favorites')}
                        className={buttonStyles({ isActiveFilter: filter === 'favorites' })}
                    >
                      Favorites
                    </button>
                )}
              </div>

              {creatable && (
                  <NewItemForm
                      item={createdItem}
                      onSubmit={createItem}
                  />
              )}

              {sortedItems.map(item => (
                  <div
                      className={itemStyles({
                        isSelected: activeItems.includes(item.id),
                        isHidden: hiddenItems.includes(item.id)
                      })}
                      // onClick we should reset timer
                      onClick={this.flush}
                      key={`${(item.systemId && 'e') || 's'}${item.id}`}
                  >
                    <div>
                      {this.getComponentForRenderAsItem(item, getItemMatch, activeItems.includes(item.id))}
                    </div>
                    {getIsModalOpen(item) && (
                        <Modal
                            isOpen={getIsModalOpen(item)}
                            data={{
                              type: 'subelements',
                              title: 'Sub-elements',
                              content: '',
                            }}
                            onModalClose={this.closeSubelements}
                            onModalConfirm={this.closeSubelements}
                        >
                          <div>
                            <SubelementsList
                                updateSocialSubElementsHistory={updateSocialSubElementsHistory}
                                position={1}
                                elementId={item.id}
                                element={item}
                                step={stepId}
                                chartId={chartId}
                                saveChart={saveChart}
                                onInitialized={() => {
                                  if (this.subelementsRef[item.id] && this.subelementsRef[item.id].getTether()) {
                                    this.subelementsRef[item.id].getTether().position();
                                  }
                                }}
                                onSubelementSelect={subelement => this.onSubelementSelect(subelement, item)}
                                onClose={this.closeSubelements}
                                createSubelement={createSubelement}
                                disableCreation={disableSubelementCreation}
                                social={social || false}
                                systemId={systemId}
                                patientId={patientId}
                                currentItem={item}
                                setNumber={this.state.setNumber}
                                exactMatch={exactMatch}
                                broadMatch={broadMatch}
                            />
                          </div>
                        </Modal>
                    )}
                  </div>
              ))}
              {fetching && (
                  <div className="text-center">
                    <Loader isStatic/>
                  </div>
              )}
              {!actualItems.length && systemId && !fetching && 'There are no items'}
              {!actualItems.length && !systemId && !fetching && !creatable && 'Select system'}
            </div>
          </div>
        </div>
    );
  }
}

ElementsViewItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    filter: PropTypes.string,
  })).isRequired,
  activeItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  systemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFetching: PropTypes.bool,
  className: PropTypes.string,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
  location: PropTypes.object,
  filterable: PropTypes.bool,
  addSystem: PropTypes.func,
  addElement: PropTypes.func,
  createSubelement: PropTypes.func,
  disableSubelements: PropTypes.bool,
  social: PropTypes.bool,
  system: PropTypes.object,
  deleteRightSideElement: PropTypes.func,
  deleteSeveralRightSideElements: PropTypes.func,
  step: PropTypes.number,
  updateItem: PropTypes.func,
  addIndexToAutoSet: PropTypes.func.isRequired,
  isChartSaving: PropTypes.bool,
  saveChart: PropTypes.func,
  resetFavorites: PropTypes.func,
  favoritesToReset: PropTypes.array,
  addRightSideElementCallback: PropTypes.func,
  patientId: PropTypes.number,
  editMode: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

ElementsViewItems.defaultProps = {
  social: false,
  system: undefined,
  deleteRightSideElement: undefined,
  deleteSeveralRightSideElements: undefined,
  updateItem: undefined,
  step: undefined,
  resetFavorites: null,
  favoritesToReset: [],
  addRightSideElementCallback: () => {
  },
  isChartSaving: false,
  saveChart: () => {
  },
  patientId: 0,
  exactMatch: {},
  broadMatch: {},
};

ElementsViewItems.contextType = AppContext;

export default ElementsViewItems;
