import { connect } from 'react-redux';

import * as selectors from 'modules/patientsHx/selectors';
import { getFlattedSocialSession } from 'modules/socialSessions/selectors';
import * as patientsSelectors from 'modules/patients/selectors';

const mapStateToProps = (state, ownProps) => {
  const {
    patientsHx,
    socialSessions,
    patients,
  } = state;

  const {
    patientId,
  } = ownProps;

  const {
    hasNoPertinentFamily,
    hasNoPertinentMedical,
    hasNoPreviousSurgeries,
  } = patientsSelectors.getPatientById(patients, patientId);

  return {
    familyItems: selectors.getFamilyItems(patientsHx, patientId),
    medicalItems: selectors.getMedicalItems(patientsHx, patientId),
    surgicalItems: selectors.getSurgicalItems(patientsHx, patientId),
    screeningItems: selectors.getScreeningItems(patientsHx, patientId),
    socialItems: getFlattedSocialSession(socialSessions, patientId),
    hasNoPertinentFamily,
    hasNoPertinentMedical,
    hasNoPreviousSurgeries,
  };
};

export default component => connect(mapStateToProps, null)(component);
