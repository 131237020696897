import React from 'react';
import PropTypes from 'prop-types';
import cx from './GreenCheckbox.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

const GreenCheckbox = ({
  value, onChange, children, isDisabledCheckbox,
}) => (
  <button
    type="button"
    onClick={() => onChange(!value)}
    className={
      cssClassesResolver([
        cx['wrapper'], cx[value && 'wrapper--active']
      ])
    }
    disabled={isDisabledCheckbox}
  >
    <div className={cx['checkbox']} />
    {' '}
    {children}
  </button>
);

GreenCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  isDisabledCheckbox: PropTypes.bool.isRequired,
};

export default GreenCheckbox;
