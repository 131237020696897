export default [
  {
    title: 'code',
    subtitle: 'ICD-10',
    sortable: true,
  },
  {
    title: 'diagnosis',
    subtitle: 'Name',
    sortable: true,
  },
  {
    title: 'comment',
    sortable: true,
  },
  {
    title: 'status',
    sortable: true,
  },
];
