import { connect } from 'react-redux';
import React from 'react';

import * as actions from 'modules/patients/actions';

import { getPatientById, isDownloadingImage } from 'modules/patients/selectors';

import stringParser from 'helpers/common/string/string-parser';

export const fetchLayerHOC = (Component) => {
  return class PatientFetchLayer extends React.Component {
    componentDidMount() {
      if (this.props.patient && this.props.patient?.isFetching !== undefined) return;
      this.fetchPatient(this.props.patientId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        nextProps.patientId
          && (this.props.patientId !== nextProps.patientId)
          && nextProps.patient?.isFetching === undefined
      ) {
        this.fetchPatient(nextProps.patientId);
      }
    }

    fetchPatient = (patientId) => {
      if (!patientId || patientId === 'new') return;
      this.props.fetchPatient(patientId);
    };

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  let patient;
  let patientId;
  if (ownProps.patientId === 'new') {
    patient = {};
    patientId = ownProps.patientId;
  } else {
    patientId = stringParser(ownProps.patientId);
    patient = getPatientById(state.patients, patientId);
  }

  return {
    patient,
    patientId,
    isDownloadingImage: isDownloadingImage(state.patients),
  };
};

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
