import React from 'react';
import PropTypes from 'prop-types';

import cx from './EditForm.module.scss';

const EditForm = ({
  children,
  responsiveHeight,
}) => (
  <div className={
    cx[responsiveHeight ? 'wrapper-responsive-height' : 'wrapper']
   }>
    {children}
  </div>
);


EditForm.propTypes = {
  children: PropTypes.node.isRequired,
  responsiveHeight: PropTypes.bool,
};

EditForm.defaultProps = {
  responsiveHeight: false,
};

export default EditForm;
