import ChartComponent from './components/Chart';
import EncountersHX from './components/EncountersHX';
import ProgressNote from './components/ProgressNote/ProgressNote';
import React from 'react';
import { useParams } from 'react-router';
import Demographics from './components/Demographics';
import { ChartMedicationList } from './components/Medications/MedicationList';
import { ChartMedicationsListWrapper } from './components/Medications/Medications';
import { ChartAllergiesPage } from './components/Allergy/AllergyList';
import { ChartAllergiesListWrapper } from './components/Allergy/Allergies';
import { ChartDiagnosesPage } from './components/Diagnoses/DiagnosisList';
import { ChartDiagnosesListWrapper } from './components/Diagnoses/Diagnoses';
import { ChartFMSSRoot } from './components/FmsHX';
import { FamilyComponent } from '../Patients/routes/Patient/routes/FMS/routes/Family';
import { ScreeningComponent } from '../Patients/routes/Patient/routes/FMS/routes/Screening';
import { MedicalComponent } from '../Patients/routes/Patient/routes/FMS/routes/Medical';
import { SurgicalComponent } from '../Patients/routes/Patient/routes/FMS/routes/Surgical';
import { SocialComponent } from '../Patients/routes/Patient/routes/FMS/routes/Social';
import { DemographicsForms } from 'routes/routes/App/routes/Patients/routes/Patient/routes/Demographics/Forms';
import { DemographicsTabs } from 'routes/routes/App/routes/Charts/components/Demographics/DemographicsTabs';

const ProgressNoteWrapper = () => {
  const { chartId } = useParams();
  return <ProgressNote chartId={chartId} />;
};

export const ChartsRoute = {
  path: 'charts/:chartId',
  element: <ChartComponent />,
  children: [
    { index: true, element: <ProgressNoteWrapper /> },
    { path: 'encounters', element: <EncountersHX /> },
    {
      path: 'demographics',
      element: <DemographicsTabs />,
      children: [
        { index: true, element: <Demographics /> },
        { path: 'forms', element: <DemographicsForms /> },
      ],
    },
    {
      path: 'medications',
      element: <ChartMedicationList />,
      children: [
        { index: true, element: <ChartMedicationsListWrapper /> },
        { path: ':filter', element: <ChartMedicationsListWrapper /> }
      ],
    },
    {
      path: 'allergies',
      element: <ChartAllergiesPage />,
      children: [
        { index: true, element: <ChartAllergiesListWrapper /> },
        { path: ':filter', element: <ChartAllergiesListWrapper /> }
      ],
    },
    {
      path: 'diagnoses',
      element: <ChartDiagnosesPage />,
      children: [
        { index: true, element: <ChartDiagnosesListWrapper /> },
        { path: ':filter', element: <ChartDiagnosesListWrapper /> }
      ],
    },
    {
      path: 'fmss',
      element: <ChartFMSSRoot />,
      children: [
        { index: true, element: <FamilyComponent /> },
        { path: 'medical', element: <MedicalComponent /> },
        { path: 'screening', element: <ScreeningComponent /> },
        { path: 'surgical', element: <SurgicalComponent /> },
        { path: 'social', element: <SocialComponent /> },
      ],
    },
  ],
};
