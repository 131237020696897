import { handleActions } from 'redux-actions';
import * as constants from './constants';
import { MESSAGE_TYPES } from 'helpers/notice/BannerService';

const initialState = {
  message: '',
  title: 'Info',
  messageType: MESSAGE_TYPES.INFO
};

export default handleActions({
  [constants.SET_MESSAGE]: (state, action) => ({
    ...initialState,
    ...action.payload.infoModalData,
  }),
}, initialState);
