import React, { useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';

import { useRoute } from 'hooks/useRoute';

import { downloadTemplate } from 'api/consent';
import { isNullOrUndefined } from 'helpers';
import Loader from 'components/Loader';
import EditForm from 'components/EditForm';

export const Preview = () => {
  const { fromSearchParams } = useRoute();
  const consentId = fromSearchParams('consentId');
  const previewTemplate = useRef(null);
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    function renderPage({ page, scale }) {
      const canvas = document.createElement('canvas');
      const viewport = page.getViewport({ scale });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext =  {
        canvasContext: canvas.getContext('2d'),
        viewport: viewport
      };
      page.render(renderContext).promise.then(function() {
        previewTemplate.current.appendChild(canvas);
      });
    }
    function renderPdf({ pdf, scale = 1 }) {
      previewTemplate.current.innerHTML = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then(page => renderPage({ page, scale }));
      }
    }
    const downloadTemplateWrapper = async () => {
      setFileUrl(null);

      const { body } = await downloadTemplate(consentId);
      const uint8Array = new Uint8Array(body );

      const blob = new Blob([uint8Array], { type: 'application/pdf' });

      setFileUrl(URL.createObjectURL(blob));
      pdfjs.getDocument(body).promise.then(pdf => {

        pdf.getPage(1).then(page => {
          const v = page.getViewport({ scale: 1 });
          const i = Math.min(
            previewTemplate.current.offsetWidth / v.width,
            previewTemplate.current.parentElement.offsetHeight / v.height
          );
          renderPdf({ pdf, scale: i });
        });


        window.addEventListener('resize', function() {
          pdf.getPage(1).then(page => {
            const v = page.getViewport({ scale: 1 });
            const i = Math.min(
              previewTemplate.current.offsetWidth / v.width,
              previewTemplate.current.parentElement.offsetHeight / v.height
            );
            renderPdf({ pdf, scale: i });
          });
        });
      });
    };
    if (!isNullOrUndefined(consentId)) {
      downloadTemplateWrapper();
    }
  }, [consentId]);
  if (consentId && !fileUrl) {
    return <Loader />;
  }
  if (consentId) {
    return (
      <EditForm responsiveHeight>
        <div id="viewer" ref={previewTemplate}></div>
      </EditForm>
    );
  }
};