import React, { useState } from 'react';
import PropTypes from 'prop-types'

import ButtonSpinner from 'components/ButtonSpinner';

import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

import cx from './BillingInfoModal.module.scss';

const StripeEndpoint = (props) => {
  const {
    isLoading,
    buttonTitle,
    onSubmit,
    onModalClose,
  } = props

  const CheckoutForm = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [errors, setError] = useState({});
    const stripe = useStripe();
    const elements = useElements();

    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    };

    const handleChange = (event, field) => {
      if (event.error) {
        setError({ ...errors, [field]: event.error.message });
      } else {
        setError(null);
      }
    }

    // Handle form submission.
    const handleSubmit = async (event) => {
      event.preventDefault();
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card, { name: `${firstName} ${lastName}` })
      if (result.error) {
        // Inform the user if there was an error.
        setError(result.error.message);
      } else {
        setError(null);
        onSubmit({ token: result.token.id })
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className={cx.billing}>
          <div className={cx['form-container']}>
            <div className={cx.row}>
            <div className={cx['billing-user-info']}>
              <span>First Name</span>
              <input
                name="firstName"
                onChange={e => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
            <div className={cx['billing-user-info']}>
              <span>Last Name</span>
              <input
                name="lastName"
                onChange={e => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
            </div>

            <CardElement
              id="card-element"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
             />

            <div className={cx['modal-window-controls']}>
              <button type="button" onClick={onModalClose} className={cx.button}>Cancel</button>
              <button
                type="submit"
                className={cx.button}
              >
                {buttonTitle}
                {isLoading && <ButtonSpinner />}
              </button>
            </div>
          </div>

        </div>
      </form>
    )
  }

  const stripePromise = loadStripe('pk_live_51HBTKnLimzCpSLOz2IhjUfQpiRQZnfJp5xVPXFAWCjTo7HJUADOSKDxJAiE2sQm89pc8IfQZn22mPbf29CkQReDW00uPK40eYn');

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
}

StripeEndpoint.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default StripeEndpoint
