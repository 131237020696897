import removeItemByValue from 'helpers/common/array/remove-element/by-value';
import isEmpty from 'helpers/common/array/is-empty';

export default class UpdateSubElementsHistoryOnSelect {
  static getNewHistory = (subElementHistoryJson, selectedSubElement = {}, setNumber = 0) => {
    const {
      numberGroupMap = {},
    } = subElementHistoryJson;

    const newNumberGroupMap = { ...numberGroupMap };

    const {
      id,
    } = selectedSubElement;

    let historyByGroup = newNumberGroupMap[setNumber];

    const isHistoryByGroupPresent = !isEmpty(historyByGroup);
    if (isHistoryByGroupPresent) {
      const isNotPresentSelectedSubElementIdInHistory = !historyByGroup.includes(id);
      if (isNotPresentSelectedSubElementIdInHistory) {
        historyByGroup.push(id);

        const {
          oldSubElementId,
        } = selectedSubElement;
        if (oldSubElementId > 0) {
          historyByGroup = removeItemByValue(historyByGroup, oldSubElementId);
        }
      }
    } else {
      historyByGroup = [id];
    }

    newNumberGroupMap[setNumber] = historyByGroup;

    return {
      subElementHistoryJson: {
        numberGroupMap: newNumberGroupMap,
      },
    };
  }
}
