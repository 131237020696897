import { addResources } from 'modules/resources/actions';
import { createSystem, updateSystem, deleteSystem } from 'modules/chartingAssets/actions';
import { createMedication, createDiagnosis, createMedicalItem } from 'modules/patientsHx/actions';
import { getDiagnoses } from 'modules/patientsHx/selectors';
import {
  fetchRightSideSystemsByStepAndChartId,
  getActiveElementsForSystem,
  getChartDataForSave
} from '../rootSelectors';
import {
  getDataForUpdateSystemAfterCreatingSubElement,
  isPresentElementInSystem,
} from 'modules/chartingSessions/selectors';

import * as constants from './constants';

import { isNullOrUndefined, replaceSequenceRegex, variableButtonPattern } from 'helpers';
import { formatParamsForUpdateRvSystem } from 'helpers/charting/charting';
import { hpChartTabs, RESOURCE_TYPES } from 'helpers/chart';

import {
  searchIllnesses,
  createIllness
} from 'api/family';
import {
  saveChartingSession as saveChartingSessionAPI,
  fetchFavorites as fetchFavoritesAPI,
  chartingsDetailsUpdateSystem as chartingsDetailsUpdateSystemAPI
} from 'api/charting';
import {
  sendCompletionStatusToServer,
  sendCompletionStatusToServerWrapper,
  updateCptCodeInStore,
  updatePatientCc
} from 'modules/charts/actions';
import { updateChartCpt } from 'api/charts';
import { getChartById } from 'modules/charts/selectors';
import { getSystemsByChartingStep, getSystemById, getElementById } from 'modules/chartingAssets/selectors';
import { assembleNoteId } from '../systemNotes/helpers';
import { getSystemNote } from '../systemNotes/selectors';
import { assembleAndDeleteSystemNote } from '../systemNotes/actions';

const HX_RV_SECTION_ID = 2;

export const addSystem = (chartId, systemId) => ({
  type: constants.ADD_SYSTEM,
  payload: {
    chartId,
    systemId,
  },
});

export const removeSystem = (chartId, system) => async (dispatch, getState) => {
  const state = getState();

  // нужно удалять hxrv систему если соответствующая rv система удалена
  const isRvSystem = system.chartingId === 1;

  if (isRvSystem) {
    const hxRvSystems = getSystemsByChartingStep(state.chartingAssets, HX_RV_SECTION_ID, chartId);

    const hxRvSystem = hxRvSystems.find(o =>
      o.chartId === chartId &&
      o.name.toLowerCase().includes(`${system.name.toLowerCase()  }:`)
    );

    if (hxRvSystem) {
      await dispatch(deleteSystem(chartId, hxRvSystem));
    }
  }
  return dispatch({
    type: constants.REMOVE_SYSTEM,
    payload: {
      chartId,
      systemId: system.id,
    },
  });
};

export const createOrUpdateHxRvSystem = ({
  dispatch, getState, actionType, systemId, chartId, element,
}) => {
  const state = getState();

  // rv и hxrv системы никак не связаны
  // плюс нет никакой возможности понять что мы добавили элемент именно в rv систему
  // придется чекать, что имя содержит "Rv #" и создавать соответствующую систему в hxrv
  // const search = 'rv #';
  const { name, chartingId, order } = getSystemById(state.chartingAssets, systemId);

  const isRvSystem = chartingId === 1;

  if (isRvSystem) {
    // придется искать в hxrv систему по названию чтобы проверить есть она уже или нет
    const hxRvSystems = fetchRightSideSystemsByStepAndChartId(state, { chartId, stepId: hpChartTabs.HXRV.step });

    const hxRvSystem = hxRvSystems.find(system => system.name.toLowerCase().includes(`${name.toLowerCase()  }:`));

    let elements = [...getActiveElementsForSystem(state, chartId, systemId)];

    const existingElement = getElementById(state.chartingAssets, element.id);

    const updatedElement = {
      ...element,
      name: (existingElement && existingElement.name) || element.name,
    };

    if (actionType === constants.ADD_ELEMENT) {
      const existingElementIndex = elements.findIndex(iteratedActiveElement => updatedElement.id === iteratedActiveElement.id);
      if (existingElementIndex !== -1) {
        elements[existingElementIndex] = {
          ...elements[existingElementIndex],
          ...element,
        };
      } else {
        elements.push(updatedElement);
      }
    }

    if (actionType === constants.REMOVE_ELEMENT) {
      elements = elements.filter(o => o.name !== updatedElement.name);
    }

    let elementNames = elements.map((iteratedElement) => {
      const containsVariable = iteratedElement.name.includes('??');

      if (containsVariable && iteratedElement.subIds && iteratedElement.subIds.length) {
        const replacementArray = iteratedElement.subIds.map(subElement => subElement && subElement.name);

        return replaceSequenceRegex(iteratedElement.name, variableButtonPattern, replacementArray);
      }

      return iteratedElement.name;
    });

    elementNames = elementNames.join(', ');

    if (!hxRvSystem) {
      return dispatch(createSystem({
        order,
        name: `Hx${name}: ${elementNames}`,
        chartId,
        chartingId: HX_RV_SECTION_ID,
      }));
    }

    const hxRvSystemWhiteListProps = formatParamsForUpdateRvSystem(hxRvSystem);

    return dispatch(updateSystem({
      ...hxRvSystemWhiteListProps,
      name: `Hx${name}: ${elementNames}`,
    }));
  }
};

export const addElementWithoutHxrvEffect = ({ chartId, systemId, element }) => ({
  type: constants.ADD_ELEMENT,
  payload: {
    chartId,
    systemId,
    element
  }
})

const SYSTEMS_TO_ADD_RESOURCES = [
  hpChartTabs.Plan.systems.PRESCRIPTIONS.id,
  hpChartTabs.A.systems.ICD_TEN.id
];
export const addElement = (chartId, systemId, element) => async (dispatch, getState) => {
  await createOrUpdateHxRvSystem({
    chartId,
    systemId,
    element,
    actionType: constants.ADD_ELEMENT,
    dispatch,
    getState,
  });

  if (SYSTEMS_TO_ADD_RESOURCES.includes(systemId)) {
    dispatch(addResources(RESOURCE_TYPES[element.systemType], [{ ...element, match: null }]));
  }

  return dispatch(addElementWithoutHxrvEffect({
      chartId,
      systemId,
      element,
  }))
};

export const removeElementWithoutHxrvEffect = ({ chartId, systemId, element }) => ({
  type: constants.REMOVE_ELEMENT,
  payload: {
    chartId,
    systemId,
    element,
  },
})

export const removeElement = (chartId, systemId, element) => async (dispatch, getState) => {
  await createOrUpdateHxRvSystem({
    chartId,
    systemId,
    element,
    actionType: constants.REMOVE_ELEMENT,
    dispatch,
    getState,
  });

  return dispatch(removeElementWithoutHxrvEffect({
      chartId,
      systemId,
      element,
  }));
};

export const removeElementInAllSystems = elementIds => ({
  type: constants.REMOVE_ELEMENT_IN_ALL_SYSTEMS,
  payload: {
    elementIds,
  },
});

export const createMedicationFromElement = (medication, chartId, patientId) => (dispatch) => {
  const diagnosis = medication.ids;

  const newMedication = {
    chartId,
    patientId,
    diagnosis,
    active: true,
    description: medication.description || '',
    prescribed: false,
    printInChart: medication.printInChart,
    medication: {
      id: medication.id,
      name: medication.name,
    },
  };

  return dispatch(createMedication(newMedication));
};

export const createDiagnosisFromElement = (diagnosis, chartId, patientId) => (dispatch, getState) => {
  const state = getState();

  const activePatientDiagnosesIds = getDiagnoses(state.patientsHx, patientId, 'active').map(o => o.diagnosis.id);

  if (activePatientDiagnosesIds.includes(diagnosis.id)) return Promise.resolve();

  const hasChronic = diagnosis.name.toLowerCase().includes(' chronic ');
  const hasAcute = diagnosis.name.toLowerCase().includes(' acute ');
  const hasSubacute = diagnosis.name.toLowerCase().includes(' subacute ');

  // if diagnosis name contains chronic or acute
  // we should set illness status accordingly
  // NOTE: if diagnosis name contains more than one illness status
  // (eg 'Subacute and chronic melioidosis ')
  // we should not set illness status

  const chronic = hasChronic && !hasAcute && !hasSubacute;
  const acute = hasAcute && !hasChronic && !hasSubacute;

  let chronicResult = null;

  if (chronic) {
    chronicResult = true;
  }

  if (acute) {
    chronicResult = false;
  }

  const diagnoseToCreate = {
    active: true,
    chartId,
    chronic: chronicResult,
    description: '',
    patientId,
    diagnosis: {
      id: diagnosis.id,
      name: diagnosis.name,
    },
  };

  return dispatch(createDiagnosis(diagnoseToCreate));
};

export const createMedicalItemFromDiagnosis = (diagnosis, chartId, patientId) => async (dispatch) => {
  const illnessName = diagnosis.name.substring(diagnosis.name.indexOf(' ')).trim();

  // надо понять, есть ли уже такой illness в базе, придется искать по названию
  const { body: existingIllness } = await searchIllnesses(illnessName);

  let illnessId = existingIllness.length && existingIllness[0].id;

  if (!existingIllness.length) {
    const res = await createIllness(illnessName);
    illnessId = res?.body;
  }

  const medicalItem = {
    description: '',
    diagnosis: [],
    illness: {
      id: illnessId,
      name: illnessName,
    },
    patientId,
    startDate: diagnosis.diagnosedDate || null,
  };

  return dispatch(createMedicalItem(medicalItem));
};

export const saveChartingSession = (data, step) => ({
  types: [
    constants.SAVE_CHARTING_SESSION,
    constants.SAVE_CHARTING_SESSION_SUCCESS,
    constants.SAVE_CHARTING_SESSION_FAIL,
  ],
  promise: () => saveChartingSessionAPI(data),
  completion: data.status,
  chartId: data.id,
  patientCc: data.patientCc,
  cptCode: data.cptCode,
  selectUserDate: data.selectUserDate,
  step,
});

const deleteCount = (item) => {
  const { count, ...rest } = item;

  return rest;
};

export const fetchFavorites = (chart, step, note, order, system) => ({
  types: [
    constants.FAVORITES_FETCH,
    constants.FAVORITES_FETCH_SUCCESS,
    constants.FAVORITES_FETCH_FAIL,
  ],
  promise: dispatch => fetchFavoritesAPI(chart, step, note, order, system)
    .then((res) => {
      const diagnoses = Object.values(res.body.diagnosisBroadMatch).concat(Object.values(res.body.diagnosisExactMatch)).map(deleteCount);
      const medications = Object.values(res.body.medicationsBroadMatch).concat(Object.values(res.body.medicationsExactMatch)).map(deleteCount);
      const cpt = Object.values(res.body.cptCodesBroadMatch).concat(Object.values(res.body.cptCodesExactMatch)).map(deleteCount);
      const hcpc = Object.values(res.body.hcpcCodesBroadMatch).concat(Object.values(res.body.hcpcCodesExactMatch)).map(deleteCount);

      dispatch(addResources('diagnoses', diagnoses));
      dispatch(addResources('medications', medications));
      dispatch(addResources('cpt', cpt));
      dispatch(addResources('hcpc', hcpc));
      return res;
    }),
  chart,
});

export const saveChart = (chartId, step) => (dispatch, getState) => {
  const state = getState();
  const chartData = getChartDataForSave({ state, chartId });

  return dispatch(saveChartingSession(chartData, step));
};

export const saveChartingSessionWrapper = (chartId, step) => ({
  types: [
    constants.SAVE_CHARTING_SESSION_WRAPPER,
    constants.SAVE_CHARTING_SESSION_WRAPPER_SUCCESS,
    constants.SAVE_CHARTING_SESSION_WRAPPER_FAIL,
  ],
  promise: async (dispatch) => {
    await dispatch(saveChart(chartId, step));
    if (step === hpChartTabs.RV.step) {
      dispatch(updatePatientCc(chartId, step));
    } else {
      dispatch(sendCompletionStatusToServerWrapper(chartId));
    }
  },
});

export const removeElementWithCallback = ({ chartId, systemId, element, step }) => async (dispatch, getState) => {
  await dispatch(removeElement(chartId, systemId, element));

  const { charts } = getState()

  if (systemId === hpChartTabs.BILLING.systems.CPT.id) {
    const { cptCode } = getChartById(charts, chartId)

    if (cptCode?.id === element?.id) {
      await dispatch(updateCptCodeInStore({ chartId, cptCode: null }))
      await updateChartCpt({ chartId, medCptCodeId: null })
      dispatch(sendCompletionStatusToServer(chartId))
    }
  }

  dispatch(saveChartingSessionWrapper(chartId, step));
};

export const removeSystemWithCallback = (chartId, system) => async (dispatch, getState) => {
  if (system.id === hpChartTabs.BILLING.systems.CPT.id) {
    const { charts, chartingSessions } = getState()
    const { cptCode } = getChartById(charts, chartId)

    const isPresentCptElement = isPresentElementInSystem(chartingSessions, {
      chartId,
      systemId: system.id,
      elementId: cptCode?.id
    })
    if (!isNullOrUndefined(cptCode) && isPresentCptElement) {
      await dispatch(updateCptCodeInStore({ chartId, cptCode: null }))
      await updateChartCpt({ chartId, medCptCodeId: null })
      dispatch(sendCompletionStatusToServer(chartId))
    }
  }
  await dispatch(removeSystem(chartId, system));
  dispatch(assembleAndDeleteSystemNote({ chartId, systemId: system.id }));
  dispatch(saveChartingSessionWrapper(chartId, system.chartingId));
};

export const chartingsDetailsUpdateSystemAfterCreatingSubElement = (createdSubelement, chartId, systemId, patientId) => (dispatch, getState) => {
  const dataForUpdateSystem = getDataForUpdateSystemAfterCreatingSubElement(getState(), chartId, systemId, patientId, createdSubelement);

  chartingsDetailsUpdateSystemAPI(dataForUpdateSystem);
};
