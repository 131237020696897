import React from 'react';

import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';
import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import ValidatedDatepicker from 'components/ValidatedDatepicker';
import CommentBox from 'components/CommentBox';
import SimpleSelect from 'components/MedSelect/SimpleSelect/SimpleSelect';
import ValidatedField from 'components/Form/ValidatedField';

import { screeningItemWhiteList } from 'helpers/screening';
import duplicateScreeningItemComparator from 'helpers/fmss/comparators/duplicate-items/screening';

import { formatMedOption } from 'helpers/selectHelpers';
import { buildSchema } from 'helpers/validationRules';
import { createScreening, searchScreenings, deleteScreening } from 'api/screening';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptyScreeningItem = {
  description: '',
  diagnosis: [],
};

const mapProps = (screening) => ({
  id: screening.id,
  screeningDetail: formatMedOption(screening?.screeningDetail),
  startDate: screening.startDate,
  description: screening.description,
})

const validationSchema = () => buildSchema({
  screeningDetail: 'requiredSelectOption',
})

const ScreeningItemForm = props => (
  <FormHOC
    emptyItem={emptyScreeningItem}
    item={props.screeningItem}
    itemId={props.screeningItemId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={props.updateScreeningItem}
    onCreate={props.createScreeningItem}
    onDelete={props.deleteScreeningItem}
    router={props.router}
    location={props.location}
    patientId={props.patientId}
    whiteList={screeningItemWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    itemsInTable={props.itemsInTable}
    duplicateItemComparator={duplicateScreeningItemComparator}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({
 itemId: screeningItemId, item: screeningItem, fetching, clean, update, del, submit, mountForm,
}) => {
      return (
        <form
          autoComplete="off"
          onSubmit={submit}
          ref={mountForm}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={screeningItemId ? 'Editing screening' : 'Adding screening'}
                onClick={clean}
              />

              <div className={cx.row}>
                <h3 className="section-title">Screening</h3>
                <ValidatedField>
                  <SimpleSelect
                    name="screeningDetail"
                    currentValue={screeningItem.screeningDetail}
                    onCreate={createScreening}
                    onChange={value => update('screeningDetail', value)}
                    loadOptions={searchScreenings}
                    onRemoveOption={deleteScreening}
                    checkAllowingToRemoveOption={() => true}
                  />
                </ValidatedField>
              </div>

              <div className={cx.row}>
                <ValidatedDatepicker
                  title="start date"
                  value={screeningItem.startDate}
                  onChange={value => update('startDate', value)}
                />
              </div>

              <div className={cx.row}>
                <CommentBox
                  title="screening comment"
                  placeholder="Enter a comment"
                  value={screeningItem.description}
                  onChange={e => update('description', e.target.value)}
                />
              </div>
            </div>

            <EditFormActions
              deleteButton={!!screeningItemId}
              cancelButton
              submitButton
              disabledSubmit={fetching}
              disabledCancel={fetching}
              onDelete={del}
              onCancel={clean}
            />

          </EditForm>
        </form>
      );
    }}
  </FormHOC>
);

ScreeningItemForm.propTypes = generateFormPropTypes({
  itemName: 'screeningItem',
  itemIdName: 'screeningItemId',
  onUpdate: 'updateScreeningItem',
  onCreate: 'createScreeningItem',
  onDelete: 'deleteScreeningItem',
});

const DirtyCheckedWrapper = dirtyCheckLayerHOC(ScreeningItemForm);

export default props => (
  <LoaderLayer
    disabled={props.screeningItemId === undefined}
    status={props.status}
    reload={() => props.fetchScreeningItem(props.screeningItemId)}
  >
    <DirtyCheckedWrapper
      {...props}
      dirtyCheckIdentity="screeningItemId"
    />
  </LoaderLayer>
);
