const request = require('superagent-promise')(require('superagent'), Promise);

export const savePaymentInfo = data =>
  request
    .post('/api/rest/billing/save')
    .send(data)
    .end();

export const fetchPaymentsPlans = () =>
  request.get('/api/rest/billing/payments/plan')
    .end();

export const fetchPaymentInfo = () =>
  request.get('/api/rest/billing/card')
    .end();

export const fetchLastBillingPlan = () =>
  request.get('/api/rest/billing/')
    .end();

export const fetchListPayments = () =>
  request.get('/api/rest/billing/payments')
    .end();

export const setAutoUpdate = autoPayment =>
  request
    .post('/api/rest/billing/auto/set')
    .send(autoPayment)
    .end();
