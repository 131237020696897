import { handleActions } from 'redux-actions';

import * as chartsConstants from '../constants';

const initialState = [];

export default handleActions({

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH]: state => ({
    ...state,
    isFetching: true,
  }),

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    chartsIds: [...action.payload.result.map(item => item.id)],
    pagesCount: 0,
  }),
}, initialState);
