import { isNullOrUndefined } from 'helpers';

export default class Element {
  constructor({
    id, ids, type, subIds, name, deleted, favoriteId, prescribed
  }) {
    this.id = id;
    this.ids = ids || null;
    this.type = isNullOrUndefined(type) ? null : type;
    this.subIds = subIds || [];
    this.name = name;
    this.deleted = deleted;
    this.favoriteId = favoriteId;
    this.prescribed = prescribed;
  }

  getId = () => this.id

  getIds = () => this.ids

  getType = () => this.type

  getSubIds = () => this.subIds

  getName = () => this.name

  getDeleted = () => this.deleted

  getFavoriteId = () => this.favoriteId

  getPrescribed = () => this.prescribed
}
