import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';

import PermissionsContainer, { fetchLayerHOC as PermissionsFL } from 'containers/PermissionsContainer';
import RolesContainer from 'containers/RolesContainer';

import UserFormLinks from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm/UserFormLinks';
import RoleSelect from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm/Permissions/components/RoleSelect';
import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import PermissionsList from 'components/PermissionsList';

import * as rolesHelpers from 'helpers/roles';
import * as helpers from 'helpers';
import deleteObjectProperty from 'helpers/common/object/delete-property';

import cx from './Permissions.module.scss';

const PermissionsListComponent = compose(
  PermissionsContainer,
  PermissionsFL
)(PermissionsList);

const RoleSelectComponent = compose(
  RolesContainer,
)(RoleSelect);

const currentCustomRoleId = -1;
const customRoleId = 0;

const SIGN_ENCOUNTER_PERMISSION = 'SIGN_ENCOUNTER_NOTE';

class PermissionsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role: {},
      currentCustomRole: undefined,
      roles: {},
    };
  }

  componentDidMount() {
    if (!this.props.isProvider) return;

    const {
      router: { replace },
      location: { pathname, query: { userId } }
    } = this.props;
    replace({ pathname, query: { userId } });
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.isProvider;
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathName }
    } = this.props;
    const isComeToPermissionsTabFromInfoTab = pathName === prevProps.location.pathName;
    const isPermissionsWasNotChanged = isEqual(this.props.user.permissions.map(o => o.id), prevProps.user.permissions.map(o => o.id)) && isComeToPermissionsTabFromInfoTab;
    if (isPermissionsWasNotChanged || !this.props.permissionsDirty) return;

    let currentRole = rolesHelpers.getCurrentRole(this.props.user.permissions, this.state.roles);

    if (!currentRole) {
      currentRole = {
        id: customRoleId,
        name: 'Custom',
        permissions: this.props.user.permissions,
      };

      this.setState({
        customRole: this.formatCurrentRole(currentRole),
      });
    }

    this.setState({
      role: this.formatCurrentRole(currentRole),
    });
  }

  onRolesFetch = (roles) => {
    if (!this.props.userId) {
      this.setState({
        role: null,
        roles,
      });

      return;
    }

    let currentRole = rolesHelpers.getCurrentRole(this.props.user.permissions, roles);

    if (!currentRole) {
      currentRole = {
        id: currentCustomRoleId,
        name: 'Current',
        permissions: this.props.user.permissions,
      };
    }

    const newRoles = { ...roles };
    newRoles[currentCustomRoleId] = currentRole;

    this.setState({
      role: this.formatCurrentRole(currentRole),
      roles: newRoles,
      currentCustomRole: ((currentRole.name === 'Current') && currentRole) || undefined,
    });
  };

  onRoleSelect = (newRole) => {
    const {
      user,
      showModal,
      updatePermissions
    } = this.props;

    const isEmptyRole = newRole === null;
    const abscentSignPermission = this.haveUncheckedSignPermission(
      isEmptyRole ? [] : newRole.permissions
    );

    if (user.setToSeat && abscentSignPermission) {
      showModal();
    } else {
      this.setState({
        role: isEmptyRole ? {} : this.formatCurrentRole(newRole),
      });
      updatePermissions(isEmptyRole ? [] : newRole.permissions);
    }
  };

  onPermissionChange = (permissions) => {
    const {
      user,
      showModal,
      updatePermissions
    } = this.props;

    const abscentSignPermission = this.haveUncheckedSignPermission(permissions);

    if (user.setToSeat && abscentSignPermission) {
      showModal();
    } else {
      updatePermissions(permissions);
    }
  };

  getDefaultRole = () => {
    const { user } = this.props;
    const { role, roles } = this.state;

    if (this.isUserPermissionsEmpty()) {
      return role;
    }

    return rolesHelpers.getCurrentRole(user.permissions, roles);
  };

  submit = (e) => {
    e.preventDefault();

    const { onSubmit } = this.props;
    onSubmit();
  };

  isUserPermissionsEmpty = () => {
    const { user } = this.props;

    const { permissions } = user;

    if (helpers.isNullOrUndefined(permissions)) {
      return true;
    }

    return permissions.length === 0;
  };

  formatCurrentRole = (role) => {
    if (!role) return undefined;

    return deleteObjectProperty(role, 'original');
  };

  haveUncheckedSignPermission(nextPermissions) {
    const { user } = this.props;

    const criteria = o => (o.name === SIGN_ENCOUNTER_PERMISSION);

    const prevSignPermission = user.permissions.filter(criteria);
    const currentSignPermission = nextPermissions.filter(criteria);

    return !!prevSignPermission.length && !currentSignPermission.length;
  }

  render() {
    const {
      userId,
      onCancel,
      user,
    } = this.props;

    const { currentCustomRole, customRole } = this.state;

    const { submit } = this;

    const fetching = false;
    const isEditForm = !!userId;

    const activePermissions = user.permissions.map(o => o.id);
    const currentRole = this.getDefaultRole();

    return (
      <div>
        <form
          onSubmit={submit}
          className={cx.form}
        >
          <EditForm responsiveHeight>
            <div className={cx['form-body']}>
              <EditFormTitle
                title={userId ? 'Edit user' : 'Add user'}
              />

              <UserFormLinks userId={userId} activeStep="permissions" />

              <div className={cx.row}>
                <RoleSelectComponent
                  onRolesFetch={this.onRolesFetch}
                  currentRole={currentRole}
                  currentCustomRole={currentCustomRole}
                  customRole={customRole}
                  onSelect={this.onRoleSelect}
                />
              </div>

              <div className={cx['permissions-container']}>
                <PermissionsListComponent
                  activePermissions={activePermissions}
                  onChange={this.onPermissionChange}
                />
              </div>
            </div>
            <div className="form-footer">
              <EditFormActions
                deleteButton={false}
                cancelButton
                submitButton
                disabledSubmit={fetching}
                disabledCancel={fetching}
                onCancel={onCancel}
                cancelButtonText={isEditForm ? 'Cancel' : 'Back'}
              />
            </div>

          </EditForm>
        </form>
      </div>
    );
  }
}

PermissionsForm.propTypes = {
  userId: PropTypes.number,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isProvider: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PermissionsForm.defaultProps = {
  userId: undefined,
  isProvider: false,
};

export default PermissionsForm;
