import stringParser from 'helpers/common/string/string-parser';

export const assembleNoteId = ({ chartId, systemId }) =>
  !chartId || !systemId ? undefined : `c${chartId}s${systemId}`;

export const disassembleNoteId = (noteId) => {
  const arr = noteId.split('c');

  return {
    systemId: stringParser(arr[0].substr(1)),
    chartId: stringParser(arr[1]),
  };
};

export const formatNote = (noteData) => {
  const noteObj = {};
  const {
    chartId,
    systemId,
  } = noteData;

  const id = assembleNoteId({ chartId, systemId });

  noteObj[id] = noteData;

  return noteObj;
};
