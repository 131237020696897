import { FMSS_TYPES } from 'helpers/fmss/constants.js';

import EmptyMessageFactory from 'helpers/fmss/additional-empty-message/factory/empty-message-factory';
import EmptyMessagePnFactory from 'helpers/fmss/additional-empty-message/factory/empty-message-pn-factory';

export default class EmptyMessageAbstractFactory {
  static getFactory = (fmssType) => {
    switch (fmssType) {
      case FMSS_TYPES.FAMILY:
      case FMSS_TYPES.MEDICAL:
      case FMSS_TYPES.SURGICAL:
        return new EmptyMessageFactory();
      case FMSS_TYPES.PROGRESS_NOTE_FAMILY:
      case FMSS_TYPES.PROGRESS_NOTE_MEDICAL:
      case FMSS_TYPES.PROGRESS_NOTE_SURGICAL:
        return new EmptyMessagePnFactory();
    }
  } ;
}
