import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = false;

export default handleActions({
  [constants.SEATS_FETCH]: () => true,
  [constants.SEATS_FETCH_SUCCESS]: () => false,
  [constants.SEATS_FETCH_FAIL]: () => false,
}, initialState);
