export default class SubElementsHistoryJson {
  static findGroupBySubElementId = (subElementsHistoryJson = {}, subElementId) => {
    const {
      numberGroupMap = {},
    } = subElementsHistoryJson;

    let findingNumberGroup = 0;

    Object.keys(numberGroupMap).forEach((numberGroup) => {
      const subIds = numberGroupMap[numberGroup];

      if (subIds.includes(subElementId)) {
        findingNumberGroup = numberGroup;
      }
    });

    return findingNumberGroup;
  };
}
