import { combineReducers } from 'redux';

import sidebar from './sidebar';
import registrationStep from './registrationStep';

import user from './user';
import charts from './charts';
import patients from './patients';
import financial from './financial';
import settings from './settings';
import chartingSessions from './chartingSessions';
import chartReportVisible from './chartReportVisible';
import socialSystems from './socialSystems';
import socialElements from './socialElements';
import socialSessions from './socialSessions';
import boxService from './boxService';
import locked from './locked';
import permissions from './permissions';
import roles from './roles';
import users from './users';
import clinic from './clinic';
import resources from './resources';
import patientsHx from './patientsHx';
import chartingAssets from './chartingAssets';
import systemNotes from './systemNotes';
import seats from './seats';
import clinics from './clinics';
import pastdue from './pastdue';
import providers from './providers';
import drChronoAuth from './drChronoAuth';
import autoOpenSubElementsModal from './autoOpenSubElementsModal';
import infoModal from './infoModal';
import favoritesForceFetch from './favoritesForceFetch';
import purchaseSeat from './purchaseSeat';
import presenceValidator from './presenceValidator';
import roleForm from './roleForm';
import resetFavorites from './resetFavorites';
import medSelect from './med-select';
import banner from './banner';
import impersonate from './impersonate';
import socialDetails from './socialDetails';
import consent from './consent';


import { USER_LOGOUT } from './user/auth/constants';
import { ACCESS_ACCOUNT } from 'modules/impersonate/constants';

const appReducer = combineReducers({
  chartingAssets,
  user,
  clinic,
  clinics,
  charts,
  patients,
  patientsHx,
  resources,
  financial,
  chartingSessions,
  sidebar,
  chartReportVisible,
  registrationStep,
  settings,
  socialSystems,
  socialElements,
  socialSessions,
  systemNotes,
  boxService,
  locked,
  seats,
  permissions,
  roles,
  users,
  pastdue,
  providers,
  drChronoAuth,
  autoOpenSubElementsModal,
  infoModal,
  favoritesForceFetch,
  purchaseSeat,
  presenceValidator,
  roleForm,
  resetFavorites,
  medSelect,
  banner,
  impersonate,
  socialDetails,

  consent
});

export default (state, action) => {
  // reset all state
  if (action.type === USER_LOGOUT) {
    return appReducer({}, action);
  }

  if (action.type === ACCESS_ACCOUNT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
