import AdditionalCheckboxDataTemplate from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxDataTemplate.js';

import { getMedicalItems } from 'modules/patientsHx/selectors.js';

export default class AdditionalMedicalCheckboxDataFactory {
  constructor({ patientId, state }) {
    this.patientId = patientId;
    this.state = state;
  }

  getData = () => {
    const additionalEmptyMessageCheckboxTemplateConnect = new AdditionalCheckboxDataTemplate({
      patientId: this.patientId,
      state: this.state,
      checkboxParamName: 'hasNoPertinentMedical',
      items: this.getItems(),
    });

    return additionalEmptyMessageCheckboxTemplateConnect.getMappedProps();
  };

  getItems = () => {
    return getMedicalItems(this.state.patientsHx, this.patientId);
  }
}
