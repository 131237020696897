import * as chartHelpers from 'helpers/chart';

export const THROUGH_FETCHING_ELEMENTS_TABS = [
  chartHelpers.hpChartTabs.RV.appId,
  chartHelpers.hpChartTabs.HXRV.appId,
];

const isNeedThroughFetchingElements = (sectionAppId) => {
  return THROUGH_FETCHING_ELEMENTS_TABS.includes(sectionAppId);
};

export default isNeedThroughFetchingElements;
