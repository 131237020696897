import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import COLORS from './colors';
import AGE_LABELS from './ageLabels';

import cx from './PatientAge.module.scss';

const PatientAge = (props) => {
  const {
    dob = 0,
    color = COLORS.default,
    ageLabel = AGE_LABELS.yo,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const age = timeZoneDateConverter.calculatePatientAge(dob);

  return (
    <span
      className={cx['about-item']}
      style={{
        color,
      }}
    >
      {age} {ageLabel}
    </span>
  );
};

PatientAge.colors = {
  ...COLORS,
};

PatientAge.labels = {
  ...AGE_LABELS,
};

PatientAge.propTypes = {
  dob: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  ageLabel: PropTypes.string,
};


export default PatientAge;
