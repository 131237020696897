import React from 'react';
import PropTypes from 'prop-types';
import EmptyMessage from 'components/UserEditForm/EmptyMessage';
import Loader from 'components/Loader';
import without from 'lodash/without';
import union from 'lodash/union';
import PermissionItem from './PermissionCheckableItem';


import cx from './PermissionsList.module.scss';


const connectedPermissionsMap = {
  2: [3, 4, 5],
  3: [4, 5],
};

class PermissionsList extends React.Component {
  static propTypes = {
    // permissions: hashMap.isRequired,
    activePermissions: PropTypes.arrayOf(PropTypes.number),
    isFetching: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activePermissions: [],
    isFetching: undefined,
    disabled: false,
  };

  handleItemClick = (permissionId) => {
    const { activePermissions, onChange, permissions } = this.props;
    const active = !activePermissions.includes(permissionId);

    let result = [...activePermissions];

    if (active) {
      result = union(result, [permissionId]);
    } else {
      result = without(result, permissionId);
    }

    const connectedPermissionsIds = connectedPermissionsMap[permissionId];

    // set connected permissions to false
    if (connectedPermissionsIds && !active) {
      result = without(result, ...connectedPermissionsIds);
    }

    // set connected permissions to true
    if (active) {
      Object.keys(connectedPermissionsMap).forEach((connectedPermissionId) => {
        const ids = connectedPermissionsMap[connectedPermissionId];
        if (ids.includes(permissionId)) {
          result = union(result, [parseInt(connectedPermissionId)]);
        }
      });
    }

    onChange(result.map(id => permissions[id]));
  };

  render() {
    const {
      permissions, disabled, isFetching, activePermissions,
    } = this.props;

    if (isFetching) {
      return <Loader />;
    }

    return (
      <div className={cx.list}>
        {Object.keys(permissions).map(permissionId => (
          <PermissionItem
            key={permissionId}
            name={permissions[permissionId].description}
            active={activePermissions.includes(parseInt(permissionId))}
            disabled={disabled}
            onClick={() => this.handleItemClick(parseInt(permissionId))}
          />
        ))}
        {(!permissions || !Object.keys(permissions).length) && (
          <EmptyMessage>
            No permissions found
          </EmptyMessage>
        )}
      </div>
    );
  }
}

export default PermissionsList;
