import { getStatus, hideBanner } from 'modules/banner';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    status: getStatus(state)
  }
}

const mapDispatchToProps = {
  hideBanner
}

export default component => connect(mapStateToProps, mapDispatchToProps)(component)
