import * as api from 'api/financial';
import * as constants from './constants';

export const fetchListPayments = () => ({
  types: [
    constants.LIST_PAYMENTS_FETCH,
    constants.LIST_PAYMENTS_FETCH_SUCCESS,
    constants.LIST_PAYMENTS_FETCH_FAIL,
  ],
  promise: () => api.fetchListPayments(),
});

