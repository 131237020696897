import { handleActions } from 'redux-actions';

import union from 'lodash/union';

import * as chartingHelpers from 'helpers/charting/charting';

import * as chartsConstants from '../constants';

const initialState = {};

const singleItemInitialState = {
  items: [],
  isFetching: null,
  error: false,
};

export default handleActions({
  [chartsConstants.PATIENT_CHARTS_FETCH]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...singleItemInitialState,
      ...state[action.payload.patientId],
      isFetching: true,
      error: false,
    },
  }),

  [chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      isFetching: true,
    },
  }),

  [chartsConstants.PATIENT_CHARTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...singleItemInitialState,
      ...state[action.payload.patientId],
      isFetching: false,
      items: action.payload.result.map(chart => chart.id),
      error: false,
    },
  }),

  [chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH_SUCCESS]: (state, action) => {
    const {
      result,
      page,
      patientId,
    } = action.payload;

    const {
      encounterUserDTOS,
      countPages,
    } = result;

    const resultChartIds = encounterUserDTOS.map(chart => chart.id);
    const items = state[patientId].items ?
      union([...state[patientId].items], [...resultChartIds]) :
      [...resultChartIds];

    return {
      ...state,
      [patientId]: {
        ...state[patientId],
        [page]: resultChartIds,
        items,
        pagesCount: countPages,
        isFetching: false,
      },
    };
  },

  [chartsConstants.PATIENT_CHARTS_FETCH_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...singleItemInitialState,
      ...state[action.payload.patientId],
      isFetching: false,
      error: true,
    },
  }),

  [chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH_FAIL]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...state[action.payload.patientId],
      isFetching: false,
    },
  }),

  [chartsConstants.CHART_CREATE_SUCCESS]: (state, action) => {
    const { patientId } = action.payload.localChart;

    return {
      ...state,
      [patientId]: {
        ...singleItemInitialState,
        ...state[patientId],
        items: [
          ...state[patientId].items,
          chartingHelpers.fetchChartIdFromCreatingResponse(action.payload.result),
        ],
      },
    };
  },

  [chartsConstants.VITALS_FETCH]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      ...singleItemInitialState,
      ...state[action.payload.patientId],
      items: union(state[action.payload.patientId] ? state[action.payload.patientId].items : [], [action.payload.chartId]),
    },
  }),
  [chartsConstants.VITALS_FETCH_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const { result } = action.payload;

    result.forEach((vitalsItem) => {
      if (!nextState[vitalsItem.patientId]) {
        nextState[vitalsItem.patientId] = { ...singleItemInitialState };
      }
      nextState[vitalsItem.patientId].items = union(nextState[vitalsItem.patientId].items, [vitalsItem.chartId]);
    });

    return nextState;
  },

}, initialState);
