import React from 'react';
import { compose } from 'redux';
import { useOutletContext } from 'react-router';

import { useRoute } from 'hooks/useRoute';

import medicalContainer, { medicalFetchLayer } from 'containers/FMSS/Medical/MedicalContainer';
import MedicalTable from 'containers/FMSS/Medical/MedicalTable';

const MedicalList = compose(
  medicalContainer,
  medicalFetchLayer
)(MedicalTable);

export const MedicalTableWrapper = () => {
  const { fromSearchParams } = useRoute();
  const { patientId } = useOutletContext();
  return (
    <MedicalList
      patientId={patientId}
      medicalItemId={parseInt(fromSearchParams('medical'))}
    />
  );
};
