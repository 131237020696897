import stringParser from 'helpers/common/string/string-parser';
import isEmpty from 'helpers/common/array/is-empty';
import { isNullOrUndefined } from 'helpers';

import SubElementsHistoryJson from 'response/social/sub-elements/history';
import SocialInitialElement from 'payload/social/elements/initializing';

export default class SocialActiveElementsResponse {
  static parse = (elements = []) => {
    const elementsWithNumberGroupsForSubElements = [];

    // TODO Optimize this with reduce()
    elements.forEach((element) => {
      const {
        subIds = [],
        subElementHistoryJson,
      } = element;

      const isSubIdsEmpty = isEmpty(subIds);
      const isSubElementsHistoryEmpty = isNullOrUndefined(subElementHistoryJson);
      if (isSubIdsEmpty || isSubElementsHistoryEmpty) {
        const initializedElement = SocialInitialElement.initializeElement(element);
        elementsWithNumberGroupsForSubElements.push(initializedElement);
        return;
      }

      const subElementsWithNumberGroup = [];

      subIds.forEach((subElement) => {
        const {
          id,
        } = subElement;

        const numberGroup = SubElementsHistoryJson.findGroupBySubElementId(subElementHistoryJson, id);
        const newSubElement = {
          ...subElement,
          numberGroup: stringParser(numberGroup),
        };

        subElementsWithNumberGroup.push(newSubElement);
      });
      const newElement = {
        ...element,
        subIds: subElementsWithNumberGroup,
      };

      elementsWithNumberGroupsForSubElements.push(newElement);
    });

    return elementsWithNumberGroupsForSubElements;
  }
}
