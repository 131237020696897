import { connect } from 'react-redux';

import ChartReportComposer from './ChartReportComposer';

import { isChartReportVisible, toggleChartReport } from 'modules/chartReportVisible';


import * as chartsSelectors from 'modules/charts/selectors';
import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import * as patientsHxSelectors from 'modules/patientsHx/selectors';
import * as patientsHxConstants from 'modules/patientsHx/constants';
import * as socialSessionsSelectors from 'modules/socialSessions/selectors';
import * as socialSystemsSelectors from 'modules/socialSystems/selectors';
import * as settingsSelectors from 'modules/settings/selectors';
import * as patientsSelectors from 'modules/patients/selectors';
import * as clinicSelectors from 'modules/clinic/selectors';
import * as systemNotesSelectors from 'modules/systemNotes/selectors';

import * as chartingAssetsActions from 'modules/chartingAssets/actions';
import { fetchChartSystemNotes } from 'modules/systemNotes/actions';
import { updateChartExtraData, updateChart, fetchVitals, changeSignedStatus } from 'modules/charts/actions';
import {
  fetchMedications,
  fetchDiagnoses,
  fetchAllergies,
  fetchFamily,
  fetchMedical,
  fetchSurgical,
  fetchScreening
} from 'modules/patientsHx/actions';
import { fetchPatientSocial } from 'modules/socialSessions/actions';
import { fetchSystems } from 'modules/socialSystems/actions';
import { fetchClinicInfo } from 'modules/clinic/actions';

import { isNullOrUndefined } from 'helpers';

const mapStateToProps = (state, ownProps) => {
  const chart = chartsSelectors.getChartById(state.charts, ownProps.chartId);
  const activePatient = patientsSelectors.getActivePatient(state.patients);

  if (!chart) return {};

  const chartFetched = chart && chart.isFetching !== undefined && !chart.isFetching;

  const assetsStatus = chartingAssetsSelectors.getStatus(state.chartingAssets);
  const areAssetsFetched = assetsStatus.isFetched;

  const chartingSteps = chartingAssetsSelectors.getSections(state.chartingAssets);

  const areMedicationsFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.MEDICATIONS, activePatient.id, 'active');
  const medicationsFetched = !isNullOrUndefined(areMedicationsFetching);

  const medicationsStatus = {
    isFetching: !!areMedicationsFetching,
    isFetched: medicationsFetched,
  };

  const areDiagnosesFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.DIAGNOSES, activePatient.id, 'active');
  const diagnosesFetched = !isNullOrUndefined(areDiagnosesFetching);

  const diagnosesStatus = {
    isFetching: !!areDiagnosesFetching,
    isFetched: diagnosesFetched,
  };

  const areAllergiesFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.ALLERGIES, activePatient.id, 'active');
  const allergiesFetched = !isNullOrUndefined(areAllergiesFetching);

  const allergiesStatus = {
    isFetching: !!areAllergiesFetching,
    isFetched: allergiesFetched,
  };

  const isFamilyFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.FAMILY, activePatient.id);
  const familyFetched = !isNullOrUndefined(isFamilyFetching);

  const familyStatus = {
    isFetching: !!isFamilyFetching,
    isFetched: familyFetched,
  };

  const isMedicalFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.MEDICAL, activePatient.id);
  const medicalFetched = !isNullOrUndefined(isMedicalFetching);

  const medicalStatus = {
    isFetching: !!isMedicalFetching,
    isFetched: medicalFetched,
  };

  const isSurgicalFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.SURGICAL, activePatient.id);
  const surgicalFetched = !isNullOrUndefined(isSurgicalFetching);

  const surgicalStatus = {
    isFetching: !!isSurgicalFetching,
    isFetched: surgicalFetched,
  };

  const isScreeningFetching = patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.SCREENING, activePatient.id);
  const screeningFetched = !isNullOrUndefined(isScreeningFetching);

  const screeningStatus = {
    isFetching: !!isScreeningFetching,
    isFetched: screeningFetched,
  };

  const isVitalsFetching = chartsSelectors.isVitalsFetching(state.charts, chart.id);
  const vitalsFetched = !isNullOrUndefined(isVitalsFetching);

  const vitalsStatus = {
    isFetching: !!isVitalsFetching,
    isFetched: vitalsFetched,
  };

  const isSocialFetching = socialSessionsSelectors.getIsSessionFetching(state.socialSessions, activePatient.id);
  const socialSessionFetched = !isNullOrUndefined(isSocialFetching);

  const socialStatus = {
    isFetching: !!isSocialFetching,
    isFetched: socialSessionFetched,
  };

  const areSocialSystemsFetching = socialSystemsSelectors.getIsFetching(state.socialSystems);
  const socialSystemsFetched = !isNullOrUndefined(areSocialSystemsFetching);

  const socialAssetsStatus = {
    isFetching: !!areSocialSystemsFetching,
    isFetched: socialSystemsFetched,
  };

  const areSystemNotesFetching = systemNotesSelectors.getIsFetchingForPatient(state.systemNotes, activePatient.id);
  const systemNotesFetched = systemNotesSelectors.getIsFetchedForPatient(state.systemNotes, activePatient.id);

  const systemNotesStatus = {
    isFetching: areSystemNotesFetching,
    isFetched: systemNotesFetched,
  };

  const clinic = clinicSelectors.getClinicInfo(state.clinic);

  const clinicStatus = clinicSelectors.getStatus(state.clinic);

  const isClinicFetched = clinicStatus.isFetched;

  const ready =
    chartFetched
    && areAssetsFetched
    && medicationsFetched
    && diagnosesFetched
    && allergiesFetched
    && familyFetched
    && medicalFetched
    && surgicalFetched
    && screeningFetched
    && vitalsFetched
    && socialSessionFetched
    && socialSystemsFetched
    && isClinicFetched
    && systemNotesFetched;

  return {
    chartingSteps,
    assetsStatus,
    medicationsStatus,
    diagnosesStatus,
    allergiesStatus,
    familyStatus,
    medicalStatus,
    surgicalStatus,
    screeningStatus,
    vitalsStatus,
    socialStatus,
    socialAssetsStatus,
    clinicStatus,
    systemNotesStatus,

    isReportVisible: !!ownProps.chartId && isChartReportVisible(state.chartReportVisible),
    chart,
    ready,
    settings: settingsSelectors.getSettings(state),
    patient: activePatient,
    clinic,
  };
};

const mapActionCreators = {
  updateChartExtraData,
  updateChart,
  toggleChartReport,
  fetchChartingAssets: chartingAssetsActions.fetchChartingAssets,
  fetchMedications,
  fetchDiagnoses,
  fetchAllergies,
  fetchFamily,
  fetchMedical,
  fetchSurgical,
  fetchScreening,
  fetchVitals,
  fetchPatientSocial,
  fetchClinicInfo,
  fetchSystems,
  changeSignedStatus,
  fetchChartSystemNotes,
};

export default connect(mapStateToProps, mapActionCreators)(ChartReportComposer);
