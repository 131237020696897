import React from 'react';
import PropTypes from 'prop-types';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './PosNegButton.module.scss';

const styles = ({ neg, className }) => ({
  button: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['wrapper', neg && 'wrapper--neg'],
    globalStyles: className
  })
})

const PosNegButton = (props) => {
  const { neg, onClick, diameter, className } = props;
  const resolvedStyles = styles({ neg, className })

  return (
    <button
      type="button"
      className={resolvedStyles.button}
      onClick={onClick}
      style={{
        width: diameter,
        height: diameter,
      }}
    >
      <span>{neg ? 'neg' : 'pos'}</span>
    </button>
  );
}

PosNegButton.propTypes = {
  neg: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  diameter: PropTypes.number,
  className: PropTypes.string,
}

export default PosNegButton;
