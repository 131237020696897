import * as api from 'api/systemNotes';
import * as constants from './constants';
import { assembleNoteId } from 'modules/systemNotes/helpers';
import { getSystemNote } from 'modules/systemNotes/selectors';

export const fetchChartSystemNotes = (patientId, chartId) => ({
  types: [
    constants.CHART_SYSTEM_NOTES_FETCH,
    constants.CHART_SYSTEM_NOTES_FETCH_SUCCESS,
    constants.CHART_SYSTEM_NOTES_FETCH_FAIL,
  ],
  promise: () => api.fetchByChart(chartId),
  patientId,
  chartId,
});

export const fetchSystemNote = data => ({
  types: [
    constants.SYSTEM_NOTE_FETCH,
    constants.SYSTEM_NOTE_FETCH_SUCCESS,
    constants.SYSTEM_NOTE_FETCH_FAIL,
  ],
  promise: () => api.fetchSystemNote(data),
  chartId: data.chartId,
  systemIds: data.systemIds,
});

export const createSystemNote = data => ({
  types: [
    constants.SYSTEM_NOTE_CREATE,
    constants.SYSTEM_NOTE_CREATE_SUCCESS,
    constants.SYSTEM_NOTE_CREATE_FAIL,
  ],
  promise: () => api.createSystemNote(data),
  ...data,
});

export const updateSystemNote = data => ({
  types: [
    constants.SYSTEM_NOTE_UPDATE,
    constants.SYSTEM_NOTE_UPDATE_SUCCESS,
    constants.SYSTEM_NOTE_UPDATE_FAIL,
  ],
  promise: () => api.updateSystemNote(data),
  ...data,
});

export const deleteSystemNote = data => ({
  types: [
    constants.SYSTEM_NOTE_DELETE,
    constants.SYSTEM_NOTE_DELETE_SUCCESS,
    constants.SYSTEM_NOTE_DELETE_FAIL,
  ],
  promise: () => api.deleteSystemNote({ id: data.id }),
  ...data,
});

/**
 * Is not used now. Use it to assemble notes
 *
 * @param chartId
 * @param systemId
 * @returns {function(*, *): void}
 */
export const assembleAndDeleteSystemNote = ({ chartId, systemId }) => (dispatch, getState) => {
  const systemNoteId = assembleNoteId({ chartId, systemId });
  const { systemNotes } = getState();
  const systemNote = getSystemNote(systemNotes, systemNoteId);

  if (systemNote?.id) {
    dispatch(deleteSystemNote({ id: systemNote.id, systemNoteId }));
  }
};
