const request = require('superagent-promise')(require('superagent'), Promise);

// confirm email hash
export const confirmEmail = hash =>
  request.get(`/api/confirm/${hash}`)
    .end();

// send recovery email
export const sendRecoveryEmail = userdata =>
  request.post('/api/forgot')
    .send(userdata)
    .end();

// validate password recovery token
export const validatePasswordRecoveryToken = token =>
  request.post(`/api/token/${token}`)
    .end();

// recover password
export const recoverPassword = userdata =>
  request.post('/api/pass/change')
    .send(userdata)
    .end();

export const signup = userdata =>
  request.post('/api/signup')
    .send(userdata)
    .end();

export const login = userdata =>
  request.post('/api/login')
    .type('form')
    .send(userdata)
    .end();

export const logout = () => {
  return request.post('/api/logout')
    .end();
};

export const lock = () =>
  request.get('/api/rest/users/lock')
    .end();

export const unlock = pin =>
  request.post('/api/rest/users/unlock')
    .send({ pin })
    .end();

export const authGetHeally = ({ email, token, clinicId }) => {
  const decodedEmail = window.atob(email);

  return request.post('/api/getheally/authenticate')
    .send({
      email: decodedEmail,
      token,
      clinicId,
    })
    .end();
};

export const authDrChrono = userId =>
  request
    .post('/api/rest/drChrono/authenticate')
    .send({ drChronoUserId: userId });
