import compareObjectsByParams from 'helpers/common/object/compare';
import compareListsOfObjectsByParams from 'helpers/common/array/compare/by-params';

const duplicateFamilyItemComparator = (familyItemOne, familyItemTwo) => {
  const {
    familyPerson: familyPersonOne,
    illnesses: illnessesOne,
  } = familyItemOne;

  const {
    familyPerson: familyPersonTwo,
    illnesses: illnessesTwo,
  } = familyItemTwo;

  return !compareObjectsByParams(familyItemOne, familyItemTwo, ['id']) &&
    compareObjectsByParams(familyPersonOne, familyPersonTwo, ['id']) &&
    compareListsOfObjectsByParams(illnessesOne, illnessesTwo, ['id']);
};

export default duplicateFamilyItemComparator;
