const request = require('superagent-promise')(require('superagent'), Promise);

const apiUri = '/api/rest/patient';

export const fetchPatients = () =>
  request.get(`${apiUri}/get`)
    .end();

export const searchTabFetchPatients = () =>
  request.get(`${apiUri}/search/tab`)
    .end();

export const fetchPagePatients = (pageNumber, limit = 50) => {
  return request.get(`${apiUri}/`)
    .query({ page: pageNumber })
    .query({ limit })
    .end();
};

export const fetchPatient = patientId =>
  request.get(`${apiUri}/get/${patientId}`)
    .end();

export const searchPatientsByName = searchRequest =>
  request.post(`${apiUri}/search/by/name`)
    .send({ searchRequest })
    .end();

export const findOnPatientsTab = searchRequest =>
  request.post(`${apiUri}/search`)
    .send({ searchRequest })
    .end();

export const findOnSearchTab = searchRequest =>
  request.post(`${apiUri}/find/on/search`)
    .send({ searchRequest })
    .end();

export const updatePatient = patient =>
  request.post(`${apiUri}/update`)
    .send(patient)
    .end();

export const createPatient = patient => request
  .post(`${apiUri}/create`)
  .send(patient)
  .end();

export const toggleDrugAllergies = (patientId, value) =>
  request.post(`${apiUri}/hasallergy`)
    .send({ id: patientId, hasNoDrugAllergies: value })
    .end();

export const togglePertinentFamilyHx = (patientId, value) =>
  request.post(`${apiUri}/haspertinentmedical`)
    .send({ id: patientId, hasNoPertinentFamily: value })
    .end();

export const togglePertinentMedicalHx = (patientId, value) =>
  request.post(`${apiUri}/haspertinentgeneralmedical`)
    .send({ id: patientId, hasNoPertinentMedical: value })
    .end();

export const togglePreviousSurgeries = (patientId, value) =>
  request.post(`${apiUri}/hasprevioussurgeries`)
    .send({ id: patientId, hasNoPreviousSurgery: value })
    .end();

export const uploadPatientPhoto = (file, angle) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('angle', angle);

  return request.post('/api/rest/image/upload')
    .send(formData)
    .end();
};

export const uploadCSV = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return request.post('/api/rest/patient/csv/upload')
    .send(formData)
    .end();
};

export const fetchPatientId = drChronoPatientId =>
  request.get(`/api/rest/patient/drChrono/${drChronoPatientId}`);
