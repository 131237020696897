import { hpChartTabs } from 'helpers/chart';

export const fixedSystemsByStepAndName = [
  { name: 'RV #1', step: hpChartTabs.RV.step },
];

export const fixedSystemsByStep = [
  hpChartTabs.A.step,
];

const checkByStep = step =>
  fixedSystemsByStep.includes(step);

const checkBySystemNameAndStep = (systemName, step) =>
  fixedSystemsByStepAndName
    .some(row => row.name === systemName && row.step === step);

const checkIsSystemFixed = ({ systemName, step }) =>
  checkByStep(step) || checkBySystemNameAndStep(systemName, step) || false;


export default checkIsSystemFixed;
