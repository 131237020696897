import { connect } from 'react-redux';
import React from 'react';
import * as actions from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';

import FetchLayer, { getMapStateToProps } from '../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const diagnosisContainer = Component => props => (
  <FetchLayer
    itemId={props.diagnosisId}
    fetchItem={props.fetchDiagnosis}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.DIAGNOSES, 'diagnosis', 'diagnosisId');

const mapActionCreators = {
  fetchDiagnosis: actions.fetchDiagnosis,
};

export default component => connect(mapStateToProps, mapActionCreators)(diagnosisContainer(component));
