import plan1Icon from 'images/Plan1.svg';
import plan2Icon from 'images/Plan2.svg';

export const formatOption = (user) => {
  // const user = { ...stateUser };

  return {
    name: `${user.firstName} ${user.lastName}`,
    email: user.login,
    signature: user.signature,
    value: user.id,
    label: user.login,
  };
};

export const formatOptions = (users) => {
  const keys = Object.keys(users);

  if (!keys.length) return [];

  const options = keys.map((key) => {
    const user = { ...users[key] };

    return {
      name: `${user.firstName} ${user.lastName}`,
      email: user.login,
      signature: user.signature,
      value: user.id,
      label: user.login,
      imageUrl: user.imageUrl,
    };
  });

  return options;
};

export const isUserPresentInSeat = (user, seat) =>
  seat.userId === user.id || seat.nextUserId === user.id;

export const filterUnassignedUsers = (users, seats) => {
  if (typeof users === 'undefined' || typeof seats === 'undefined') return [];

  return users.filter((user) => {
    let absent = true;

    Object.keys(seats)
      .forEach((key) => {
        const seat = seats[key];

        if (isUserPresentInSeat(user, seat)) {
          absent = false;
        }
      });

    return absent;
  });
};

export const SEAT_PLANS = {
  pro1: {
    name: 'Pro I',
    class: 'pro1',
    isLocked: false,
  },
  pro2: {
    name: 'Pro II (coming soon)',
    class: 'pro2',
    isLocked: true,
  },
  vipPlan: {
    name: 'VIP',
    class: 'vipPlan',
    isLocked: false,
  },
};

export const NOT_DISPLAYED_AMOUNT_PLANS = [
  SEAT_PLANS.pro2.name,
];

export const isLockedPlan = checkedPlanName => Object.values(SEAT_PLANS).filter(plan =>
  plan.name === checkedPlanName && plan.isLocked === true
).length > 0;


export const getSeatPlan = (seat) => {
  switch (seat.billingPlanSeatName) {
    case SEAT_PLANS.pro1.name:
      return SEAT_PLANS.pro1.class;
    case SEAT_PLANS.pro2.name:
      return SEAT_PLANS.pro2.class;
    case SEAT_PLANS.vipPlan.name:
      return SEAT_PLANS.vipPlan.class;
    default:
      return SEAT_PLANS.pro1.class;
  }
};

export const getPlanImageName = (cardName) => {
  switch (cardName) {
    case SEAT_PLANS.pro1.name:
      return plan1Icon;
    case SEAT_PLANS.pro2.name:
      return plan2Icon;
    default:
      return plan2Icon;
  }
};

export const SEATS_STATUS = {
  OPEN_SEAT: 'UNLOCK',
  LOCK_FREE: 'LOCK_FREE',
  USED: 'USED',
  NEED_TO_PAY: 'NEED_TO_PAY',
};

export const isOpenSeat = seat => seat.status === SEATS_STATUS.OPEN_SEAT;

export const isUsedSeat = seat => seat.status === SEATS_STATUS.USED;

export const isLockFreeSeat = seat => seat.status === SEATS_STATUS.LOCK_FREE;

export const seatAgreementModalData = {
  changeProvider: {
    title: 'Remove Provider',
    content: 'After confirming, this seat will be locked and unusable for 12-hours!',
  },
  addProvider: {
    title: 'Add Provider to seat',
    content: 'Please confirm, you are about to fill this seat with the selected provider.',
  },
};

export const seatStatusDescription = {
  lock: 'This seat is currently locked with the remaining time displayed.  Once the time expires, the seat will be available for use.',
  used: 'This seat is currently in-use by a Provider. ',
  open: 'This seat is open and you can assign a Provider.',
};

export const seatStatusIconClasses = {
  image_open: 'image-open',
  image_used: 'image-used',
  image_lock: 'image-lock',
};

export const getStatusClass = (seat) => {
  let className = '';

  if (isOpenSeat(seat)) {
    className = seatStatusIconClasses.image_open;
  } else if (isUsedSeat(seat)) {
    className = seatStatusIconClasses.image_used;
  } else {
    className = seatStatusIconClasses.image_lock;
  }
  return className;
};

export const getStatus = (seat) => {
  const classStatus = getStatusClass(seat);
  if (classStatus === seatStatusIconClasses.image_open) {
    return seatStatusDescription.open;
  } else if (classStatus === seatStatusIconClasses.image_used) {
    return seatStatusDescription.used;
  }

  return '';
};

export const DISABLED_SEAT_STATUS = [
  SEATS_STATUS.USED,
  SEATS_STATUS.NEED_TO_PAY,
];

export const SEAT_CLEAR_USER_ICON_DISPLAYED = [
  SEATS_STATUS.USED,
];

export const getSeatsByPlan = (seats, plan) => Object.values(seats).filter(seat => seat.billingPlanSeatName === plan);

export const getSeatsCounter = (seats, plan) => getSeatsByPlan(seats, plan).length;

