import { serializeObjectToQuery } from 'helpers/queryManagement';

const request = require('superagent-promise')(require('superagent'), Promise);


export const fetchReports = params =>
  request.get(`/api/rest/report/logs${serializeObjectToQuery(params)}`)
    .end();

export const downloadZip = data =>
  request.post('/api/rest/pdf/download/zip')
    .responseType('blob')
    .send(data)
    .end();

export const fetchActions = () =>
  request.get('/api/rest/report/logs/actions')
    .end();

export const fetchActionTypes = () =>
  request.get('/api/rest/report/logs/actionTypes')
    .end();
