import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors.js';

import isNeedThroughFetchingElements from 'helpers/elements/fetching/is-need-through-fetching';

export default class FilterElementsBySystems {
  constructor(chartingAssets, appId, system) {
    this.isNeedThroughFetchingElements = isNeedThroughFetchingElements(appId);
    this.chartingAssets = chartingAssets;
    this.system = system;
  }

  getElementsByStep = () => {
    const params = this.createParamsToFetchElements();

    return chartingAssetsSelectors.getElements(this.chartingAssets, params);
  };

  createParamsToFetchElements = () => {
    const isNeed = this.isNeedThroughFetchingElements;

    return {
      sectionId: isNeed && this.system.chartingId,
      systemId: !isNeed && this.system.id,
    };
  };

  getElements = () => {
    return this.getElementsByStep();
  }
}
