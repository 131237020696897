import React from 'react'
import PropTypes from 'prop-types'

import { isNullOrUndefined } from 'helpers';

import InputIcon from 'components/MedSelect/InputIcon';

import cx from './SimpleSelect.module.scss'
import SearchIcon from 'components/MedSelect/SearchIcon';

const Input = (props) => {
  const {
    value,
    seachableValue,
    resetValue,
    onChange,
    onFocus
  } = props

  const isEmptyValue = () =>
    value === '' || isNullOrUndefined(value)

  const handleChange = (event) => {
    if (isEmptyValue(value)) {
      onChange(event.target.value)
    } else {
      resetValue()
      const newValue = event.target.value
      onChange(newValue[newValue.length - 1])
    }
  }

  const resolveDisplayedValue = () =>
    isEmptyValue(value) ? seachableValue : value

  return (
    <div className={cx.input__container}>
      <InputIcon />
      <input
        onFocus={onFocus}
        placeholder="Type to search"
        className={cx.input}
        value={resolveDisplayedValue()}
        onChange={handleChange}
      />
      <SearchIcon
        onClick={onFocus}
      />
    </div>
  )
}

Input.propTypes = {
  value: PropTypes.string,
  seachableValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  resetValue: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}

Input.defaultProps = {
  value: ''
}

export default Input
