import { isNullOrUndefined } from 'helpers';

export default class DateDisplaying {
  static display = (startDate, endDate) => {
    const isStartDateEmpty = isNullOrUndefined(startDate);
    const isEndDateEmpty = isNullOrUndefined(endDate);

    if (isStartDateEmpty && isEndDateEmpty) {
      return '';
    } else if (isStartDateEmpty || isEndDateEmpty) {
      if (isEndDateEmpty) {
        return `${startDate} /`;
      }

      return `/ ${endDate}`;
    }

    return `${startDate} / ${endDate}`;
  }
}
