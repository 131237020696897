import { handleActions } from 'redux-actions';

import * as chartsConstants from '../constants';

export default handleActions({
  [chartsConstants.CHARTS_FETCH]: () => true,

  [chartsConstants.CHARTS_FETCH_SUCCESS]: () => false,

  [chartsConstants.CHARTS_FETCH_FAIL]: () => false,

  [chartsConstants.CHARTS_BY_PAGE_FETCH]: () => true,

  [chartsConstants.CHARTS_BY_PAGE_FETCH_SUCCESS]: () => false,

  [chartsConstants.CHARTS_BY_PAGE_FETCH_FAIL]: () => false,

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH]: () => true,

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS]: () => false,

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_FAIL]: () => false,
}, null);
