import { combineReducers } from 'redux';

import byId from './byId';
import ids from './ids';
import isFetching from './isFetching';
import byPatient from './byPatient';
import isCreating from './isCreating';
import dashboardError from './dashboardError';
import signedIdsByPages from './signedIdsByPages';
import unsignedIdsByPages from './unsignedIdsByPages';
import copyPreviousNotes from './copyPreviousNotes';

export default combineReducers({
  byId,
  ids,
  byPatient,
  isFetching,
  isCreating,
  dashboardError,
  signedIdsByPages,
  unsignedIdsByPages,
  copyPreviousNotes,
});
