export default (array = [], value) => {
  const indexValue = array.indexOf(value);

  if (array.length === 0 || indexValue === -1) {
    return array;
  }

  array.splice(indexValue, 1);

  return array;
}
