import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = {};

const singleSystemInitialState = {
  isFetching: undefined,
  items: [],
};

const singleSystem = handleActions({
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM]: state => ({
    ...state,
    isFetching: true,
  }),
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload.result.map(system => system.id),
  }),
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => ({
    ...state,
    items: [...state.items, action.payload.result],
  }),
}, singleSystemInitialState);

export default handleActions({
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM]: (state, action) => ({
    ...state,
    [action.payload.systemId]: singleSystem(state[action.payload.systemId], action),
  }),
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.systemId]: singleSystem(state[action.payload.systemId], action),
  }),
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_FAIL]: (state, action) => ({
    ...state,
    [action.payload.systemId]: singleSystem(state[action.payload.systemId], action),
  }),
  [constants.CREATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => {
    const { systemId } = action.payload.element;

    return {
      ...state,
      [systemId]: singleSystem(state[systemId], action),
    };
  },
  [constants.UPDATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      result,
      oldElement,
      socialId,
    } = action.payload;

    const nextItems = nextState[socialId].items;

    delete nextItems[oldElement.id];
    nextItems.push(result);

    nextState[socialId] = {
      ...nextState[socialId],
      items: nextItems,
    };

    return nextState;
  },
	[constants.DELETE_RIGHT_SIDE_ELEMENT_SUCCESS]: (state, action) => {
    const { id, socialId } = action.payload;
    const nextState = { ...state };

    const nextItems = nextState[socialId].items
      .filter(item => item !== id);

    nextState[socialId] = {
      ...nextState[socialId],
      items: nextItems,
    };


    return nextState;
	}
}, initialState);
