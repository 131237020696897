import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { restartTomcat } from 'api/administrator';

export const AdminService = () => {
  const [status, setStatus] = useState('');
  const navigate = useNavigate();

  const handleSuccessfullyRestart = async (e) => {
    if (e?.status === 502) {
      await setStatus('Success. Your session was unmounted. You will be redirected to login page in 5 seconds');

      setTimeout(() => {
        navigate('/app/login');
        window.location.reload();
      }, 5000);
    } else {
      setStatus('We can not restart Tomcat');
    }
  };

  const handleClick = () => {
    restartTomcat()
      .catch(handleSuccessfullyRestart);
  };

  return (
    <>
      <button
        type='button'
        className='generic-button'
        onClick={handleClick}
      >
        Restart Tomcat
      </button>

      <p>
        Status: {status}
      </p>
    </>
  );
};
