import React, { useState } from 'react'
import PropTypes from 'prop-types'

import cx from './ArrowButton.module.scss'

const arrowStyles = ({ isShowContent }) => `${isShowContent ? cx["arrow-down"] : cx.arrow}`

const ArrowButton = ({ renderTrigger, renderHideContent }) => {
	const [isShowContent, setIsShowContent] = useState(false)

	const changeIsShowState = () => setIsShowContent(!isShowContent)

	return (
		<>
			{React.cloneElement(renderTrigger(), {
				onClick: changeIsShowState
			})}
			<button
				type='button'
				className={arrowStyles({ isShowContent })}
				onClick={changeIsShowState}
			>
					&gt;
			</button>
			{isShowContent && (
				renderHideContent()
			)}
		</>
	)
} 

ArrowButton.propTypes = {
	renderTrigger: PropTypes.func.isRequired,
	renderHideContent: PropTypes.func.isRequired
}

export default ArrowButton