import React from 'react';
import { Outlet } from 'react-router';

import Tabs from 'components/Tabs';

const tabsHead = [
  {
    title: 'Create',
    url: '/app/admin/clinic',
    indexLink: true,
    disabled: false,
  },
  {
    title: 'Manager',
    url: '/app/admin/clinic/manager',
    disabled: false,
  }
];

export const AdminClinicTab = () => {
  return (
    <Tabs headerItems={tabsHead}>
      <Outlet />
    </Tabs>
  );
};
