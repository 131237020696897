import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isUserImpersonate } from 'modules/impersonate';
import { impersonateLogin, impersonateLogout } from 'modules/impersonate/actions';
import { logout } from 'modules/user/auth/actions';
import { fetchUser } from 'modules/user/actions';

import { isNullOrUndefined } from 'helpers';

export const useImpersonate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isImpersonate = useSelector(state => isUserImpersonate(state));

  const logoutWrapper = () => {
    if (isImpersonate) {
      dispatch(impersonateLogout());
      navigate('/app/admin');
    } else {
      dispatch(logout());
      navigate('/app/login');
    }
  };

  const switchUserCallbackByStatus = {
    200: dispatch => {
      navigate('/app/doctor');
      dispatch(fetchUser());
    }
  };

  const loginWrapper = async (data) => {
    const { status } = await dispatch(impersonateLogin(data));
    const callback = switchUserCallbackByStatus[status];
    if (!isNullOrUndefined(callback)) {
      callback(dispatch);
    }

  };

  return { logout: logoutWrapper, login: loginWrapper };
};