import { handleActions } from 'redux-actions';

import * as selectors from 'modules/roleForm/constants';

const initialState = {
  isNeedHideErrors: false,
};

export default handleActions({
  [selectors.SET_IS_NEED_HIDE_ERRORS]: (state, action) => ({
    ...state,
    isNeedHideErrors: action.payload.isNeedHideErrors,
  }),
}, initialState);
