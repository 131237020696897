import React from 'react';

import ChartingStepsContainer from 'containers/ChartingStepsContainer';

import ChartReportComment from './ChartReportComment';
import HPNote from './HPNote';

const chartTypes = {
  1: ChartingStepsContainer(HPNote),
};

const defaultReportComponent = ({ chart }) => <div>Unknown charting type: {chart.chartingType.name}</div>;


const ChartReportBody = (props) => {
  const {
    chart,
    toggleChartReport,
    updateChartExtraData,
    updateChart,
    patient,
  } = props;

  const ReportComponent = chartTypes[chart.chartingType.id] || defaultReportComponent;

  return (
    <div>
      <ChartReportComment
        chart={chart}
        updateChart={updateChart}
        updateChartExtraData={updateChartExtraData}
        disabled={chart.signed}
      />
      <ReportComponent
        chart={chart}
        toggleChartReport={toggleChartReport}
        viewMode={chart.signed}
        patient={patient}
      />
    </div>
  );
};

export default ChartReportBody;

