import React from 'react';
import PropTypes from 'prop-types';
import cx from './SeatStatusContentItem.module.scss';

const SeatStatusContentItem = ({ status, color }) => {
  return (
    <div className={cx['seat-container']}>
      <div
        className={cx['seat-container__color']}
        style={{ backgroundColor: color }}
      />
      <div
        className={cx['seat-container__description']}
        dangerouslySetInnerHTML={{ __html: status }}
      />
    </div>
  );
};

SeatStatusContentItem.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default SeatStatusContentItem;
