import { HEADERS } from 'helpers/promise/header/constants';

export default class ContentDispositionFileName {
  constructor(response) {
    this.response = response;
  }

  getFileName = () => {
    return this.response.header[HEADERS.CONTENT_DISPOSITION].split('filename=')[1];
  }
}
