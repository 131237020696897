import { formatMedicalForServer } from 'helpers/fmss/medical/medical';

const request = require('superagent-promise')(require('superagent'), Promise);

// get medical list by patient
export const fetchMedical = patientId =>
  request.get(`/api/rest/medical/patient/${patientId}`)
    .end();

export const fetchMedicalItem = medicalItemId =>
  request.get(`/api/rest/medical/${medicalItemId}`)
    .end();

export const createMedicalItem = medicalItem =>
  request.post('/api/rest/medical/create')
    .send(formatMedicalForServer(medicalItem))
    .end();

export const updateMedicalItem = medicalItem =>
  request.post('/api/rest/medical/update')
    .send(formatMedicalForServer(medicalItem))
    .end();

export const deleteMedicalItem = medicalItemId =>
  request.post('/api/rest/medical/delete')
    .send({ id: medicalItemId })
    .end();
