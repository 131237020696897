import { handleActions } from 'redux-actions';
import isEmpty from 'helpers/common/array/is-empty'

import {
  FETCH_ACTIVE_OFFICE_SUCCESS,
  FETCH_OFFICES_SUCCESS,
  SELECT_OFFICE_SUCCESS,
  SELECT_PROVIDER,
} from 'modules/drChronoAuth/constants';

import { CHART_CREATE_FAIL, CHART_CREATE_SUCCESS } from 'modules/charts/constants';
import { officesMap } from 'modules/drChronoAuth/selectors';

const initialState = {
  providersOffices: [],
  selectedProviderId: null,
  selectedOffice: null,
  selectedOfficeRoom: null
}

export default handleActions({
  [FETCH_OFFICES_SUCCESS]: (state, action) => ({
    ...state,
    providersOffices: action.payload.result.map(officesMap)
  }),
  [FETCH_ACTIVE_OFFICE_SUCCESS]: (state, action) => {
    if (isEmpty(action.payload.result)) {
      return {
        ...state,
        selectedOffice: null
      }
    }
    return {
     ...state,
     selectedOffice: {
       ...action.payload.result.reduce((acc, next) => {
         return {
           ...acc,
           [next.officeProviderId]: {
             ...next.offices.reduce((accOffices, nextOffice) => {
               return {
                 ...accOffices,
                 [nextOffice.officeId]: [...nextOffice.roomIndexes]
               }
             }, {})
           }
         }
       }, {})
     }
    }
  },
  [SELECT_PROVIDER]: (state, action) => ({
    ...state,
    selectedProviderId: action.payload.provider
  }),
  [SELECT_OFFICE_SUCCESS]: (state, action) => ({
      ...state,
    selectedOffice: action.payload.offices
  }),
  [CHART_CREATE_SUCCESS]: (state) => ({
    ...state,
    selectedOffice: null,
    selectedOfficeRoom: null
  }),
  [CHART_CREATE_FAIL]: (state) => ({
    ...state,
    selectedOffice: null,
    selectedOfficeRoom: null
  })
}, initialState)
