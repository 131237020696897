import React from 'react';
import { useOutletContext } from 'react-router';

import socialSystemsContainer from 'containers/FMSS/Social/SocialSystemConnect';
import SocialItems from 'containers/FMSS/Social/SocialItems';

import { fetchLayerHOC as elementsFetchLayerHOC } from 'containers/FMSS/Social/SocialElementsContainer';
import SocialElementsContainer from 'containers/FMSS/Social/SocialElementsConnect';
import { useRoute } from 'hooks/useRoute';

const SocialSystems = socialSystemsContainer(SocialItems);
const SocialElements = SocialElementsContainer(elementsFetchLayerHOC(SocialItems));

const SocialPatient = () => {
  const { fromSearchParams } = useRoute();
  const { patientId } = useOutletContext();
  const system = fromSearchParams('system');

  if (system) {
    return <SocialElements patientId={patientId} systemId={parseInt(system)} />;
  }

  return <SocialSystems patientId={patientId} />;
};

export default SocialPatient;
