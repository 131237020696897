export const DRCHRONO_TOKEN_SET = 'DRCHRONO_TOKEN_SET';
export const DRCHRONO_TOKEN_SET_SUCCESS = 'DRCHRONO_TOKEN_SET_SUCCESS';
export const DRCHRONO_TOKEN_SET_FAIL = 'DRCHRONO_TOKEN_SET_FAIL';

export const DRCHRONO_TOKEN_REMOVE = 'DRCHRONO_TOKEN_REMOVE';
export const DRCHRONO_TOKEN_REMOVE_SUCCESS = 'DRCHRONO_TOKEN_REMOVE_SUCCESS';
export const DRCHRONO_TOKEN_REMOVE_FAIL = 'DRCHRONO_TOKEN_REMOVE_FAIL';

export const DRCHRONO_TOKEN_FETCH = 'DRCHRONO_TOKEN_FETCH';
export const DRCHRONO_TOKEN_FETCH_SUCCESS = 'DRCHRONO_TOKEN_FETCH_SUCCESS';
export const DRCHRONO_TOKEN_FETCH_FAIL = 'DRCHRONO_TOKEN_FETCH_FAIL';

export const SET_IS_SHOW_IMPORT_MODAL = 'SET_IS_SHOW_IMPORT_MODAL';

export const SET_TURN_SYNC = 'SET_TURN_SYNC';
export const SET_TURN_SYNC_SUCCESS = 'SET_TURN_SYNC_SUCCESS';
export const SET_TURN_SYNC_FAIL = 'SET_TURN_SYNC_FAIL';

export const FETCH_OFFICES = 'FETCH_OFFICES'
export const FETCH_OFFICES_SUCCESS = 'FETCH_OFFICES_SUCCESS'
export const FETCH_OFFICES_FAIL = 'FETCH_OFFICES_FAIL'

export const FETCH_ACTIVE_OFFICE = 'FETCH_ACTIVE_OFFICE'
export const FETCH_ACTIVE_OFFICE_SUCCESS = 'FETCH_ACTIVE_OFFICE_SUCCESS'
export const FETCH_ACTIVE_OFFICE_FAIL = 'FETCH_ACTIVE_OFFICE_FAIL'

export const SELECT_PROVIDER = 'SELECT_PROVIDER'
export const SELECT_OFFICE = 'SELECT_OFFICE'
export const SELECT_OFFICE_SUCCESS = 'SELECT_OFFICE_SUCCESS'
export const SELECT_OFFICE_FAIL = 'SELECT_OFFICE_FAIL'
