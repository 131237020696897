const updateArrayItemByIndex = (arrayToUpdate, itemIndex, updatedItem) => {
  if (itemIndex === 0) {
    return [
      updatedItem,
      ...arrayToUpdate.slice(1, arrayToUpdate.length),
    ];
  } else if (itemIndex === arrayToUpdate.length - 1) {
    return [
      ...arrayToUpdate.slice(0, arrayToUpdate.length - 1),
      updatedItem,
    ];
  }

  return [
    ...arrayToUpdate.slice(0, itemIndex),
    updatedItem,
    ...arrayToUpdate.slice(itemIndex + 1, arrayToUpdate.length),
  ];
};

export default updateArrayItemByIndex;
