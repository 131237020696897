import { useOutletContext } from 'react-router-dom';

import UserEditForm from 'components/UserEditForm';
import AdditionalCheckboxConnector from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxConnector';
import AdditionalEmptyMessageCheckbox from 'components/FMSSPage/AdditionalEmptyMessageCheckbox';
import { FMSS_TYPES } from 'helpers/fmss/constants';
import {
  MedicalFormWrapper
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/routes/Medical/components/MedicalForm';
import {
  MedicalTableWrapper
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/routes/Medical/components/MedicalList';

const CheckboxWrapper = AdditionalCheckboxConnector(
  AdditionalEmptyMessageCheckbox, FMSS_TYPES.MEDICAL
);

export const MedicalComponent = () => {
  const { patientId, linkBase } = useOutletContext();

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Family Hx', indexLink: true },
        { url: { pathname: `${linkBase}/medical` }, name: 'Medical Hx' },
        { url: { pathname: `${linkBase}/social` }, name: 'Social Hx' },
        { url: { pathname: `${linkBase}/surgical` }, name: 'Surgical Hx' },
        { url: { pathname: `${linkBase}/screening` }, name: 'Screening Hx' },
      ]}
      formComponent={<MedicalFormWrapper />}
      customLabelRenderer={<CheckboxWrapper label="No Pertinent Medical Hx" patientId={patientId} />}
    >
      <MedicalTableWrapper />
    </UserEditForm.Root>
  );
};

export const MedicalRoute = {
  path: 'medical',
  element: <MedicalComponent />,
};
