import * as constants from './constants';

export const addIndexToAutoSet = (indexToAutoSet, elementId) => ({
  type: constants.ADD_INDEX_TO_AUTO_SET,
  payload: {
    indexToAutoSet,
    elementId,
  },
});

export const resetIndexToAutoSet = (indexToReset, elementId) => ({
  type: constants.RESET_INDEX_TO_AUTO_SET,
  payload: {
    indexToReset,
    elementId,
  },
});
