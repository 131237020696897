import { components } from 'react-select';

export const DropdownIndicator = (props) => {
  return (
    <button
      className='no-button'
      onClick={props.cancel}
    >
      <components.DropdownIndicator {...props} />
    </button>
  );
};