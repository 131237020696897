import React from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import allergiesContainer, { withAllergiesFetchLayer } from 'containers/Allergies/AllergiesContainer';
import { AllergiesList } from 'containers/Allergies/AllergiesList';

const AllergiesContainer = compose(
  allergiesContainer,
  withAllergiesFetchLayer
)(AllergiesList);

export const AllergiesWrapper = () => {
  const { filter, patientId } = useParams();
  return (
    <AllergiesContainer
      patientId={patientId}
      filter={filter === 'inactive' ? 'inactive' : 'active'}
    />
  );
};
