import React from 'react';
import PropTypes from 'prop-types';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './EditModeDoneButton.module.scss';

const styles = ({ isDisabled }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['done-button', isDisabled && 'disabled-button']
})

const EditModeDoneButton = (props) => {
  const {
    isEditModeDoneBtnDisabled,
    handleMergeElements,
  } = props;

  return (
    <button
      type="button"
      className={styles({ isDisabled: isEditModeDoneBtnDisabled === true })}
      disabled={isEditModeDoneBtnDisabled}
      onClick={handleMergeElements}
    >
      Done
    </button>
  );
};

EditModeDoneButton.propTypes = {
  isEditModeDoneBtnDisabled: PropTypes.bool.isRequired,
  handleMergeElements: PropTypes.func.isRequired,
};

export default EditModeDoneButton;
