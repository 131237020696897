import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ChartReportItem from '../../../ChartReportItem';
import ChartReportElement from '../ReportElement';

import { systemNameTransformerFactory } from 'helpers/chart';

import cx from './ReportSystem.module.scss';

const ElementWrapper = ({
                          element,
                          removeElement,
                          onElementUpdate,
                          isROS,
                          isPLAN,
                          viewMode,
                          toggleChartReport,
                          chartId,
                          step,
                          system,
                          elementsCount,
                          index,
                          patient,
                          isNeedDisplayAsList,
                        }) => {
  const hasNewlines = (/\n/g).test(element.name);

  const onUpdateWrapper = useCallback(() => onElementUpdate({
    id: element.id,
    type: !element.type,
    ids: element.ids,
    subIds: element.subIds,
  }), [element.type]);

  const renderedElement = (
    <span>
      <ChartReportElement
        element={element}
        removeElement={() => removeElement(element)}
        onUpdate={onUpdateWrapper}
        isROS={isROS}
        isPLAN={isPLAN}
        viewMode={viewMode}
        patient={patient}
        elementsCount={elementsCount}
        index={index}

        // these props are needed for navigation to progress note
        toggleChartReport={toggleChartReport}
        chartId={chartId}
        step={step}
        systemId={system.id}
      />{!hasNewlines && ((isNeedDisplayAsList && '.') || (index !== elementsCount - 1 && ', '))}
    </span>
  );

  return (isNeedDisplayAsList && (
    <li>
      {renderedElement}
    </li>
  )) || renderedElement;
};

const ReportSystem = (props) => {
  const {
    system,
    elements,
    removeElement,
    toggleChartReport,
    chartId,
    step,
    onElementUpdate = () => {},
    viewMode,
    patient,
    systemId,
    onSystemRemove,
    systemNote
  } = props;

  const {
    elementNameConverter,
  } = useContext(AppContext);

  const renderElements = (isNeedDisplayAsList) => {
    const isROS = step === 3;
    const isPLAN = step === 6;

    return elements?.map((element, i) => (
      <ElementWrapper
        key={element.id}
        element={element}
        removeElement={removeElement}
        onElementUpdate={onElementUpdate}
        isROS={isROS}
        isPLAN={isPLAN}
        viewMode={viewMode}
        toggleChartReport={toggleChartReport}
        chartId={chartId}
        step={step}
        system={system}
        elementsCount={elements.length}
        index={i}
        patient={patient}
        isNeedDisplayAsList={isNeedDisplayAsList}
      />
    ));
  };

  if (!elements || !elements.length) return null;

  const isPLAN = step === 6;
  const isNeedDisplayAsListInPE = ((step === 4 && systemId === 88) || systemId === 89) || system.type === 4;
  const isNeedDisplayAsList = isNeedDisplayAsListInPE === true || isPLAN === true;

  let elementsComponent = renderElements(isNeedDisplayAsList);

  if (isNeedDisplayAsList) {
    elementsComponent = (
      <ul className={cx.list}>
        {elementsComponent}
      </ul>
    );
  }

  const formattedSystemName = systemNameTransformerFactory(step)(system.name);

  const transformed = elementNameConverter.transformedSeveralNames(formattedSystemName, patient);

  return (
    <ChartReportItem
      step={step}
      title={transformed}
      tooltip={!viewMode}
      link={(!viewMode && `/app/doctor/charts/${chartId}?step=${step}&system=${system.id}`) || null}
      toggleChartReport={toggleChartReport}
      onSystemRemove={() => onSystemRemove(system)}
    >
      <div className={cx['wrapper-container']}>
        {elementsComponent}
        {
          (!!systemNote && !isNeedDisplayAsList) &&
          <div className={cx['system-details']}>
            {systemNote.freeText}
          </div>
        }
        {
          (!!systemNote && isNeedDisplayAsList) &&
          <ul className={cx['no-list']}>
            <li>{systemNote.freeText}</li>
          </ul>
        }
      </div>
    </ChartReportItem>
  );
};

ReportSystem.propTypes = {
  onSystemRemove: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  onElementUpdate: PropTypes.func,
};

export default ReportSystem;
