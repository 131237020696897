import React from 'react';
import { node, shape, string } from 'prop-types'

import { components } from 'react-select';

import cx from './UserOption.module.scss'

const UserOption = ({ children, ...props }) => {
  const {
    data
  } = props

  const {
    firstName,
    lastName,
  } = data

  return (
    <components.Option {...props}>
      <span className={cx.container}>
        <div className={cx.name}>{`${lastName}, ${firstName}`}</div>
        <div className={cx.email}>{children}</div>
      </span>
    </components.Option>
  )
}

UserOption.propTypes = {
  children: node.isRequired,
  data: shape({
    firstName: string.isRequired,
    lastName: string.isRequired
  }).isRequired
}

export default UserOption
