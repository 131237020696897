import React from 'react';
import cx from './MedData.module.scss';
import PropTypes from 'prop-types';

const MedData = ({
  children,
}) => (
  <div className={cx['edit-form']}>
    <div className={cx['edit-form-wrapper']}>
      {children}
    </div>
  </div>
);

MedData.propTypes = {
  children: PropTypes.node,
};

export default MedData;
