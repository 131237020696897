import React from 'react';
import { useOutletContext } from 'react-router';

import { useRoute } from 'hooks/useRoute';

import familyItemContainer from 'containers/FMSS/Family/FamilyItemContainer';
import FamilyForm from 'containers/FMSS/Family/FamilyForm';

const Component = familyItemContainer(FamilyForm);

export const FamilyFormWrapper = () => {
  const { fromSearchParams } = useRoute();
  const { patientId } = useOutletContext();

  return (
    <Component
      patientId={patientId}
      familyItemId={fromSearchParams('family')}
    />
  );
};
