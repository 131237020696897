import React, { Component } from 'react';

import ChartReportElementWithTooltip from '../../../../../../ChartReportElementWithTooltip';
import RemoveButton from '../../../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../../../ChartReportElementWithTooltip/DoorButton';

const MENU_BUTTON_DIAMETER = 40;

class ChartReportSocialSystemName extends Component {
  state = {
    isMenuOpen: false,
  };

  openMenu = () => this.setState({ isMenuOpen: true });

  closeMenu = () => this.setState({ isMenuOpen: false });

  render() {
    const {
      children,
      chartId,
      toggleChartReport,
      systemId,
      viewMode,
      removeElements,
    } = this.props;

    const { isMenuOpen } = this.state;
    const { openMenu, closeMenu } = this;

    return (
      <ChartReportElementWithTooltip
        isOpen={isMenuOpen}
        onOpen={openMenu}
        onClose={closeMenu}
        disabled={viewMode}
      >

        <span>
          {children}
        </span>

        <div className="flex-container">
          <DoorButton
            style={{
              width: MENU_BUTTON_DIAMETER,
              height: MENU_BUTTON_DIAMETER,
              marginRight: 10,
            }}
            link={{ pathname: `/app/doctor/charts/${chartId}/fmss/social`, query: { system: systemId } }}
            onClick={() => {
              closeMenu();
              toggleChartReport();
            }}
          />
          <RemoveButton
            onClick={removeElements}
            style={{
              width: MENU_BUTTON_DIAMETER,
              height: MENU_BUTTON_DIAMETER,
            }}
          />
        </div>

      </ChartReportElementWithTooltip>
    );
  }
}

export default ChartReportSocialSystemName;
