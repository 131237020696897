import PayloadDataToUpdateSubElementsForElement from 'payload/social/elements/sub-elements/update';

export default class PayloadDataToUpdateElementName {
  constructor({ patientId, element }) {
    this.element = element;
    this.payloadDataToUpdateSubElementsForElement = new PayloadDataToUpdateSubElementsForElement({ patientId, element });
  }

  getRequestData = () => {
    const basicRequestData = this.payloadDataToUpdateSubElementsForElement.getRequestData();

    return {
      ...basicRequestData,
      name: this.element.name,
    };
  };
}
