import memoize from 'lodash/memoize';

import { permissionsDescriptionMap, permissionsInitialState, permissionsMap } from 'helpers/permissions';

const formatPermissions = (state) => {
  return state.reduce((acc, val) => {
    const obj = { ...acc };
    const permissionValue = permissionsMap[val.name] || permissionsDescriptionMap[val.name];

    obj[permissionValue] = true;

    return obj;
  }, permissionsInitialState);
};

export const getPermissions = memoize(formatPermissions, permissions => JSON.stringify(permissions.map(o => o.id)));
