export default class UpdateActiveSubElementOnSelect {
  static getActiveSubElementsForElement = (selectedSubelement, oldSubElements = []) => {
    const newSubElements = [...oldSubElements];

    const indexSubElement = newSubElements.findIndex(subElement => subElement.position === selectedSubelement.position);
    if (indexSubElement === -1) {
      newSubElements.push(selectedSubelement);
      return newSubElements;
    }

    newSubElements[indexSubElement] = selectedSubelement;
    return newSubElements;
  }
}
