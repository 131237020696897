import React from 'react';
import TermsOfUse from 'components/Documents/TermsOfUse';
import DocumentHeader from 'components/DocumentHeader';

const TermsOfUsePage = () => (
  <DocumentHeader>
    <TermsOfUse />
  </DocumentHeader>
);


export default TermsOfUsePage;
