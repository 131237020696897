import React from 'react';
import PropTypes from 'prop-types';
import { InputMask } from '../InputMask';

const ValidateMaskInput = (props) => {
  const { value, className = '', name, onChange, mask, placeholder = '', disabled = false } = props;

  return (
    <InputMask
      name={name}
      mask={mask}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      value={value}
    />
  );
};

ValidateMaskInput.propTypes = {
  disabled: PropTypes.bool,
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ValidateMaskInput;
