import React from 'react';
import DiagnosesTable from '../DiagnosesTable';
import Loader from 'components/Loader';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { build } from 'helpers/URLUtils';


export const DiagnosesList = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const selectDiagnosis = diagnosisId =>
    navigate(
      build({
        pathname: pathname,
        query: { diagnosis: diagnosisId }
      })
    );

  const {
    diagnoses,
    isFetching,
    error,
    filter,
    fetchDiagnoses,
    patientId
  } = props;

  if (isFetching) return <Loader />;

  return (
    <DiagnosesTable
      items={diagnoses}
      showDiagnosisById={selectDiagnosis}
      selectedId={parseInt(searchParams.get('diagnosis'))}
      filter={filter}
      error={error}
      onReload={() => fetchDiagnoses(patientId, filter)}
    />
  );
};
