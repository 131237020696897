import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ArrowButton from 'components/ArrowButton';
import DrChronoOfficeItem from 'components/DrChronoOffices/DrChronoOfficeItem';

import isEmpty from 'helpers/common/array/is-empty';
import removeItemByValue from 'helpers/common/array/remove-element/by-value';
import { isNullOrUndefined } from 'helpers';

import cx from 'components/DrChronoOffices/DrChronoOffices.module.scss';

const selectRoom = ({prevSelected, next}) => {
  const { officeProviderId, officeId, roomIndex } = next

  let prevOffices
  let prevRooms
  try {
    prevOffices = prevSelected[officeProviderId] || {}
    prevRooms = prevSelected[officeProviderId][officeId] || []
  } catch (e) {
    prevOffices = {}
    prevRooms = []
  }

  return {
    ...prevSelected,
    [officeProviderId]: {
      ...prevOffices,
      [officeId]: [...prevRooms, roomIndex]
    }
  }
}

const deSelectRoom = ({prevSelected, next}) => {
  const { officeProviderId, officeId, roomIndex } = next
  const newState = {...prevSelected}

  const isLastRoom = newState[officeProviderId][officeId].length === 1
  if (isLastRoom) {
    const isLastOffice = Object.keys(newState[officeProviderId]).length === 1
    const isLastProvider = Object.keys(newState).length === 1

    if (isLastProvider && isLastOffice) {
      return newState
    }
    if (isLastOffice) {
      delete newState[officeProviderId]
      return newState
    }

    delete newState[officeProviderId][officeId]
    return newState
  }

  return {
    ...newState,
    [officeProviderId]: {
      ...newState[officeProviderId],
      [officeId]: removeItemByValue(newState[officeProviderId][officeId], roomIndex)
    }
  }
}

const Offices = ({ officesInfo, selectOffice, selectedOffice }) => {

  const selectWrapper = (selectedState, officeProviderId) => {
    const forServer = isNullOrUndefined(selectedState) ? [] : Object.keys(selectedState).reduce((acc, nextOfficeProviderId) => {
      const offices = Object.keys(selectedState[nextOfficeProviderId]).map(officeIdKey => ({ officeId: officeIdKey, roomIndexes: selectedState[officeProviderId][officeIdKey]  }))
      return [
        ...acc,
        {
          officeProviderId: nextOfficeProviderId,
          offices: [
            ...offices
          ]
        }
      ]
    }, [])

    selectOffice(selectedState, forServer)
  }

  const select = ({ providerId, officeId, roomIndex }) => {
    const selectedState = selectRoom({
      prevSelected: selectedOffice,
      next: {
        officeProviderId: providerId,
        officeId: officeId,
        roomIndex
      }
    })
    selectWrapper(selectedState, providerId)
  }

  const deSelect = ({ providerId, officeId, roomIndex }) => {
    const selectedState = deSelectRoom({prevSelected: selectedOffice, next: {officeProviderId: providerId, officeId: officeId, roomIndex} })
    selectWrapper(selectedState, providerId)
  }

  const renderProviderName = (name) => {
    return (
      <span
        className={cx['provider-name']}
      >
        {name}
      </span>
    )
  }

  const checkRooms = (providerId, officeId, roomIndex) => {
    try {
      return selectedOffice[providerId][officeId].includes(roomIndex)
    } catch (e) {
      return false
    }
  }

  const renderOffice = (offices, providerId) => {
    return (
      <ul>
        {offices.map(office =>
          <li key={office.id}>
            <ArrowButton
              renderTrigger={() => {
                return (
                  renderProviderName(office.name)
                )
              }}
              renderHideContent={() => {
                return (
                  office.rooms.map(room =>
                    <DrChronoOfficeItem
                      key={room.index}
                      id={room.index}
                      name={room.name}
                      select={() => select({providerId, officeId: office.id, roomIndex: room.index} )}
                      deSelect={() => deSelect({providerId, officeId: office.id, roomIndex: room.index} )}
                      checked={isNullOrUndefined(selectedOffice) ? false : checkRooms(providerId, office.id, room.index)}
                    />
                  )
                )
              }}
            />
          </li>
        )}
      </ul>
    )
  }

  if (isEmpty(officesInfo)) {
    return 'No offices'
  }

  return (
    <div className={cx['modal-content']}>
      {officesInfo.map(officeInfo =>
        <Fragment key={officeInfo.providerId}>
          <div>
            <ArrowButton
              renderTrigger={() => renderProviderName(officeInfo.providerName)}
              renderHideContent={() => renderOffice(officeInfo.offices, officeInfo.providerId)}
            />
          </div>
        </Fragment>
      )}
    </div>
  )
};

Offices.propTypes = {
  selectedOffice: PropTypes.shape({
    officeId: PropTypes.number,
    roomIndex: PropTypes.number,
    officeProviderId: PropTypes.number
  }),
  officesInfo: PropTypes.arrayOf(PropTypes.shape({
    providerId: PropTypes.number,
    providerName: PropTypes.string,
    offices: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string
    }))
  })).isRequired,
  selectOffice: PropTypes.func.isRequired,
}

Offices.defaultProps = {
  selectedOffice: null
}

export default Offices
