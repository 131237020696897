export const getRoles = state =>
  state.byId;

export const getIsFetching = state =>
  state.isFetching;

export const getIsFetched = state =>
  state.isFetched;

export const getRole = (state, roleId) =>
  state.byId[roleId];

export const getIsCreating = state =>
  state.isCreating;

export const getIsUpdating = state =>
  state.isUpdating;
