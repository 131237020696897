import React from 'react';

import icon from './input-icon.png';

import cx from './InputIcon.module.css';

const InputIcon = () => {
  return (
    <img
      alt='Input icon'
      src={icon}
      className={cx['container-icon']}
    />
  )
}

export default InputIcon
