import * as fromList from './createList/selectors';

const getState = (state, filter) => {
  if (!state) return {};
  if (!filter) return state;
  return state[filter];
};

export const getIsFetchingList = (state, filter) => {
  return fromList.getIsFetching(getState(state, filter));
};

export const getListError = (state, filter) => fromList.getError(getState(state, filter));

export const getItems = (state, filter) => {
  return fromList.getItems(getState(state, filter));
};

export const getIsInitializedList = (state, filter) => fromList.getIsInitialized(getState(state, filter));
