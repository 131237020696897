import { combineReducers } from 'redux';

import byId from './byId';
import isFetched from './isFetched';
import isFetching from './isFetching';
import isSaving from './isSaving';
import isPriceFetching from './isPriceFetching';

export default combineReducers({
  byId,
  isFetched,
  isFetching,
  isSaving,
  isPriceFetching,
});
