import getStrategyToFetchSystems from 'helpers/right-side-items/fetch/strategies/factory';
import isNeedFilteringSystemsByStepAndChartId from 'helpers/right-side-items/fetch/need-for-filtering';

const fetchRightSideItemsProducer = ({
  stepId, systemId, state, chartId,
}) => {
  const isNeedFilteringSystemsByStepAndChart = isNeedFilteringSystemsByStepAndChartId(stepId, systemId);
  const fetchItems = getStrategyToFetchSystems(isNeedFilteringSystemsByStepAndChart);

  return fetchItems(state, { chartId, stepId, systemId });
};

export default fetchRightSideItemsProducer;
