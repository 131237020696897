import React from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ProvidersContainer, { withProvidersFetchLayer } from 'containers/ProvidersContainer';

import Loader from 'components/Loader';
import StaticOptions from 'components/Select/StaticOptions';

import { getDashboardActiveProviderOption, getProviderOptions, getDashboardProvidersOptions } from 'helpers/providers/providers';
import { ALL_PROVIDERS_ITEM } from 'helpers/providers/constants';
import { isNullOrUndefined } from 'helpers';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import cx from './ProviderSelect.module.scss';

const providersSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 30,
  widthControl: 163,
  selectOption: {
    textAlign: 'left',
    color: '#2f9dc6',
    cursor: 'pointer',
    padding: '7px 12px',

    ':hover': {
      backgroundColor: '#60b8d9',
      color: 'white',
    }
  },
})

class ProvidersSelectDashboard extends React.Component {
  componentDidMount = () => {
    const {
      setFilterProvider,
      currentUserProviderId,
    } = this.props;

    const { isCurrentUserProvider } = this.context;

    if (isNullOrUndefined(currentUserProviderId) || !isCurrentUserProvider) {
      setFilterProvider(ALL_PROVIDERS_ITEM.value);

      return;
    }

    if (!isNullOrUndefined(currentUserProviderId) && isCurrentUserProvider) {
      setFilterProvider(currentUserProviderId);

      return;
    }

    setFilterProvider(null);
  };

  onChange(value) {
    const {
      setFilterProvider,
    } = this.props;

    setFilterProvider(value);
  }

  render() {
    const {
      providers,
      isFetching,
      isFetched,
      activeProviderId,
      currentUserProviderId,
    } = this.props;

    const options = getProviderOptions(providers, isFetched);

    const optionsWithExtraItems = getDashboardProvidersOptions(options);

    const activeOption = getDashboardActiveProviderOption(optionsWithExtraItems, activeProviderId, currentUserProviderId);

    if (isFetching) {
      return (
        <div className={cx['loader-wrapper']}>
          <Loader />
        </div>
      );
    }

    return (
      <StaticOptions
        options={optionsWithExtraItems}
        onChange={option => this.onChange(option.value)}
        value={activeOption}
        isClearable={false}
        isSearchable={false}
        placeholder="Select provider"
        styles={providersSelectStyles}
      />
    );
  }
}

ProvidersSelectDashboard.propTypes = {
  setFilterProvider: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isFetched: PropTypes.bool,
  providers: PropTypes.array,
  activeProviderId: PropTypes.number,
  currentUserProviderId: PropTypes.number,
};

ProvidersSelectDashboard.contextType = AppContext;

export default ProvidersContainer(withProvidersFetchLayer(ProvidersSelectDashboard));
