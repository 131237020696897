import React, { useState } from 'react';
import ComposedBlockedForReadOnlyFetchLayer from 'containers/BlockedForReadOnly/BlockedForReadOnlyFetchLayer';

const PatientEncountersPaginationWrapper = (props) => {
  const [activePage, setActivePage] = useState(0);
  const [sortBy, setSortBy] = useState('DOS');
  const [isAsc, setIsAsc] = useState(false);

  const onPageChange = (page) => setActivePage(page)

  const onSortChange = (sortByToUpdate, isAscToUpdate) => {
    setSortBy(sortByToUpdate)
    setIsAsc(isAscToUpdate)
  };

  const { patientId } = props;

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      activePage,
      onPageChange,
      onSortToggle: onSortChange,
      patientId,
      sortBy,
      isAsc,
    });
  });

  return React.Children.only(
    <ComposedBlockedForReadOnlyFetchLayer>
      {children[0]}
    </ComposedBlockedForReadOnlyFetchLayer>
  );
}
export default PatientEncountersPaginationWrapper;
