import React from 'react';
import PropTypes from 'prop-types';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './SaveRoundButton.module.scss';

const styles = ({ loading, disabled, className }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [
    'save',
    loading && 'save--loading',
    disabled && 'save--disabled'
  ],
  globalStyles: className
})

const SaveRoundButton = ({
  loading,
  onClick,
  className,
  disabled,
}) => (
  <button
    type="submit"
    onClick={onClick}
    className={styles({ className, disabled, loading })}
  />
);

SaveRoundButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SaveRoundButton;
