import { connect } from 'react-redux';

import * as socialElementsSelectors from 'modules/socialElements/selectors';
import * as socialSessionsSelectors from 'modules/socialSessions/selectors';
import * as socialElementsActions from 'modules/socialElements/actions';
import * as socialSessionsActions from 'modules/socialSessions/actions';
import { addIndexToAutoSet } from 'modules/autoOpenSubElementsModal/actions';
import { sendCompletionStatusToServerWrapper } from 'modules/charts/actions';

const getCentralActiveElementsIds = (state, ownProps) => {
  const { systemId, patientId } = ownProps;

  return socialSessionsSelectors
    .getEnclosedElementsForSystem(state.socialSessions, patientId, systemId)
    .map(item => item.id);
};

const mapStateToProps = (state, ownProps) => {
  const { systemId, patientId } = ownProps;

  const isFetching = socialElementsSelectors.getIsElementsFetching(state.socialElements, systemId);

  const items = socialElementsSelectors.getElementsBySystem(state.socialElements, systemId);

  const activeItems = getCentralActiveElementsIds(state, ownProps);

  return {
    items,
    isSessionFetching: socialSessionsSelectors.getIsSessionFetching(state.socialSessions, patientId),
    isFetching,
    isChartSaving: false,
    activeSystem: systemId,
    activeItems,
    patientId,
  };
};

const mapActionCreators = {
  ...socialElementsActions,
  ...socialSessionsActions,
  addIndexToAutoSet,
  sendCompletionStatusToServerWrapper,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
