import React from 'react';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import TabsHeaderItem from '../TabsHeaderItem';

import cx from './TabsHeader.module.scss';

const styles = ({type, actualItems}) => ({
  wrapper: type === 'inner' ? cx['wrapper-inner'] : cx.wrapper,
  list: cssClassesResolver([
    cx.list, actualItems.length === 1 && cx["list--single"]
  ])
})


const TabsHeader = ({
  type,
  items,
  activeTab,
  onClick,
}) => {
  const actualItems = items.filter(o => !o.disabled);

  const computedStyles = styles({type, actualItems})

  return (
    <div className={computedStyles.wrapper}>
      <div
        className={computedStyles.list}
      >
        {actualItems.map(
          (item, index) =>
            <TabsHeaderItem
              isSingle={actualItems.length === 1}
              type={type}
              {...item}
              key={item.title}
              active={activeTab === index}
              onClick={() => onClick(index)} />)
        }
      </div>
    </div>
  );
};

TabsHeader.propTypes = {
  type: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    mark: PropTypes.bool,
  })).isRequired,
  activeTab: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TabsHeader;
