import React from 'react';
import { arrayOf, shape, number, string, func } from 'prop-types'

import MultiSelectOption from 'components/MedSelect/SimpleSelect/MultiSelectOption';

import InputIcon from 'components/MedSelect/InputIcon';

import cx from './SimpleSelect.module.scss'

const MultiInput = (props) => {
  const {
    values,
    onChange,
    seachableValue,
    removeMultiValue,
    onFocus
  } = props

  return (
    <div
      className={cx['multi-input__container']}
    >
      <InputIcon />
      <div
        className={cx.options}
      >
        {values.map(value =>
          <MultiSelectOption
            key={value.id}
            value={value}
            removeMultiValue={() => removeMultiValue(value.id)}
          />
        )}
        <div className={cx['input-wrapper']}>
          <input
            onFocus={onFocus}
            placeholder="Type to search"
            value={seachableValue}
            className={cx['multi-input']}
            onChange={event => onChange(event.target.value)}
          />
        </div>
      </div>
      <span
        className={cx['search-icon__container']}
        onClick={onFocus}
      >
        <span
          className={cx['search-icon']}
        />
      </span>
    </div>
  )
};

MultiInput.propTypes = {
  removeMultiValue: func.isRequired,
  onFocus: func.isRequired,
  onChange: func.isRequired,
  values: arrayOf(shape({
    id: number,
    name: string
  }))
}

MultiInput.defaultProps = {
  values: []
}

export default MultiInput
