import { handleActions } from 'redux-actions';
import * as constants from '../constants';

import drChronoShouldShowWarningStrategyManager from 'helpers/drChrono/drChronoShouldShowWarningStrategyManager';

const initialState = false;

export default handleActions({
  [constants.DRCHRONO_TOKEN_FETCH_SUCCESS]: (state, action) => {
    const { result, status } = action.payload;

    return drChronoShouldShowWarningStrategyManager(status)(result);
  },
}, initialState);
