export const CHARTS_FETCH = 'CHARTS_FETCH';
export const CHARTS_FETCH_SUCCESS = 'CHARTS_FETCH_SUCCESS';
export const CHARTS_FETCH_FAIL = 'CHARTS_FETCH_FAIL';

export const CHARTS_BY_PAGE_FETCH = 'CHARTS_BY_PAGE_FETCH';
export const CHARTS_BY_PAGE_FETCH_SUCCESS = 'CHARTS_BY_PAGE_FETCH_SUCCESS';
export const CHARTS_BY_PAGE_FETCH_FAIL = 'CHARTS_BY_PAGE_FETCH_FAIL';

export const CHARTS_LAST_WITH_SORTING_FETCH = 'CHARTS_LAST_WITH_SORTING_FETCH';
export const CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS = 'CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS';
export const CHARTS_LAST_WITH_SORTING_FETCH_FAIL = 'CHARTS_LAST_WITH_SORTING_FETCH_FAIL';

export const PATIENT_CHARTS_FETCH = 'PATIENT_CHARTS_FETCH';
export const PATIENT_CHARTS_FETCH_SUCCESS = 'PATIENT_CHARTS_FETCH_SUCCESS';
export const PATIENT_CHARTS_FETCH_FAIL = 'PATIENT_CHARTS_FETCH_FAIL';

export const PATIENT_CHARTS_BY_PAGE_FETCH = 'PATIENT_CHARTS_BY_PAGE_FETCH';
export const PATIENT_CHARTS_BY_PAGE_FETCH_SUCCESS = 'PATIENT_CHARTS_BY_PAGE_FETCH_SUCCESS';
export const PATIENT_CHARTS_BY_PAGE_FETCH_FAIL = 'PATIENT_CHARTS_BY_PAGE_FETCH_FAIL';

export const CHART_FETCH = 'CHART_FETCH';
export const CHART_FETCH_SUCCESS = 'CHART_FETCH_SUCCESS';
export const CHART_FETCH_FAIL = 'CHART_FETCH_FAIL';

export const CHART_CREATE = 'CHART_CREATE';
export const CHART_CREATE_SUCCESS = 'CHART_CREATE_SUCCESS';
export const CHART_CREATE_FAIL = 'CHART_CREATE_FAIL';

export const CHART_PROVIDER_SET = 'CHART_PROVIDER_SET';
export const CHART_PROVIDER_SET_SUCCESS = 'CHART_PROVIDER_SET_SUCCESS';
export const CHART_PROVIDER_SET_FAIL = 'CHART_PROVIDER_SET_FAIL';

export const CHART_UPDATE = 'CHART_UPDATE';
export const CHART_UPDATE_SUCCESS = 'CHART_UPDATE_SUCCESS';
export const CHART_UPDATE_FAIL = 'CHART_UPDATE_FAIL';

export const CHART_CHANGE_SIGNED_STATUS = 'CHART_CHANGE_SIGNED_STATUS';
export const CHART_CHANGE_SIGNED_STATUS_SUCCESS = 'CHART_CHANGE_SIGNED_STATUS_SUCCESS';
export const CHART_CHANGE_SIGNED_STATUS_FAIL = 'CHART_CHANGE_SIGNED_STATUS_FAIL';

export const CHART_UPDATE_LOCAL = 'CHART_UPDATE_LOCAL';

export const VITALS_FETCH = 'VITALS_FETCH';
export const VITALS_FETCH_SUCCESS = 'VITALS_FETCH_SUCCESS';
export const VITALS_FETCH_FAIL = 'VITALS_FETCH_FAIL';

export const VITALS_SAVE = 'VITALS_SAVE';
export const VITALS_SAVE_SUCCESS = 'VITALS_SAVE_SUCCESS';
export const VITALS_SAVE_FAIL = 'VITALS_SAVE_FAIL';

export const COPY_PREVIOUS_NOTE = 'COPY_PREVIOUS_NOTE';
export const COPY_PREVIOUS_NOTE_SUCCESS = 'COPY_PREVIOUS_NOTE_SUCCESS';
export const COPY_PREVIOUS_NOTE_FAIL = 'COPY_PREVIOUS_NOTE_FAIL';

export const CHART_DELETE = 'CHART_DELETE';
export const CHART_DELETE_SUCCESS = 'CHART_DELETE_SUCCESS';
export const CHART_DELETE_FAIL = 'CHART_DELETE_FAIL';

export const UPDATE_CPT_CODE = 'UPDATE_CPT_CODE';
export const UPDATE_SELECT_USER_DATE = 'UPDATE_SELECT_USER_DATE';
export const UPDATE_SELECT_USER_DATE_SUCCESS = 'UPDATE_SELECT_USER_DATE_SUCCESS';
export const UPDATE_SELECT_USER_DATE_FAIL = 'UPDATE_SELECT_USER_DATE_FAIL';

export const UPDATE_PATIENT_CC = 'UPDATE_PATIENT_CC';
export const UPDATE_PATIENT_CC_SUCCESS = 'UPDATE_PATIENT_CC_SUCCESS';
export const UPDATE_PATIENT_CC_FAIL = 'UPDATE_PATIENT_CC_FAIL';

export const SAVE_CHARTS_DETAILS_SUCCESS = 'SAVE_CHARTS_DETAILS_SUCCESS';

export const UPDATE_CHART_COMPLETION_STATUS = 'UPDATE_CHART_COMPLETION_STATUS';
export const UPDATE_CHART_COMPLETION_STATUS_SUCCESS = 'UPDATE_CHART_COMPLETION_STATUS_SUCCESS';
export const UPDATE_CHART_COMPLETION_STATUS_FAIL = 'UPDATE_CHART_COMPLETION_STATUS_FAIL';
