import React, { Component } from 'react';
import PropTypes from 'prop-types';

import orderBy from 'lodash/orderBy';

import EmptyMessage from 'components/UserEditForm/EmptyMessage';
import PatientHxItemsTable from 'components/PatientHxItemsTable';

class PatientHistoryItemsTable extends Component {
  static propTypes = {
    defaultSortBy: PropTypes.string.isRequired,
    defaultDesc: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    mapItemToField: PropTypes.func.isRequired,
    error: PropTypes.bool,
    onReload: PropTypes.func.isRequired,
    itemRenderer: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      sortable: PropTypes.bool,
    })).isRequired,
    emptyMessage: PropTypes.string.isRequired,
  };

  static defaultProps = {
    error: undefined,
    defaultDesc: false,
  };

  state = {
    sortBy: this.props.defaultSortBy,
    desc: this.props.defaultDesc,
  };

  getSortedItem = () => {
    const { sortBy, desc } = this.state;

    const { items, mapItemToField } = this.props;

    const formattedItems = items.map(mapItemToField);
    const direction = desc ? 'desc' : 'asc';

    if (sortBy === 'illness') {
      return orderBy(formattedItems, [item => item.illness.toLowerCase()], [direction]);
    }

    return orderBy(
      formattedItems,
      sortBy,
      direction
    );
  };

  sort = (column) => {
    if (this.state.sortBy === column) {
      this.setState({ desc: !this.state.desc });
      return;
    }
    this.setState({
      sortBy: column,
      desc: false,
    });
  };

  render() {
    const {
      fields,
      error,
      onReload,
      itemRenderer,
      emptyMessage,
    } = this.props;

    const { sortBy, desc } = this.state;

    const { sort } = this;

    const renderedItems = this.getSortedItem();

    if (error) {
      return (
        <EmptyMessage>
          An error occured<br />
          <button
            className="generic-button"
            type="button"
            onClick={onReload}
          >
            Reload
          </button>
        </EmptyMessage>
      );
    }

    if (renderedItems.length) {
      return (
        <PatientHxItemsTable
          fields={fields}
          onSort={fieldTitle => sort(fieldTitle)}
          sortBy={sortBy}
          desc={desc}
          items={renderedItems}
          itemRenderer={itemRenderer}
        />
      );
    }

    return (
      <div>
        <EmptyMessage>{emptyMessage}</EmptyMessage>
      </div>
    );
  }
}

export default PatientHistoryItemsTable;
