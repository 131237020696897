import React, { useCallback, useEffect } from 'react';

import { isNullOrUndefined } from 'helpers';
import deleteMultipleProperties from 'helpers/common/object/deleteMultipleProperties';

export const withClinicsFetchLayer = Component => props => {
  const {
    fetchClinics,
    userId,
    isUserFetched,
    areClinicsFetched,
    areClinicsFetching,
  } = props;

  const fetchClinicsWrapper = useCallback((id) => {
    if (!isNullOrUndefined(id)) {

      fetchClinics(id);
    }
  }, [fetchClinics]);

  useEffect(() => {
      if (!isUserFetched) return;

      fetchClinicsWrapper(userId);
  }, []);

  useEffect(() => {
    if (!isUserFetched || areClinicsFetched || areClinicsFetching) return;

    fetchClinicsWrapper(userId);
  }, [userId, isUserFetched, areClinicsFetched, areClinicsFetching]);

  const wrapper = innerProps => {
    return <Component {...innerProps} />;
  };

  return (
    wrapper(deleteMultipleProperties(props, ['fetchClinics', 'areClinicsFetched', 'isUserFetched', 'userId']))
  );
};