import { handleActions } from 'redux-actions';

import * as constants from '../constants';

export default handleActions({
  [constants.PATIENTS_FETCH]: () => true,

  [constants.PATIENTS_FETCH_SUCCESS]: () => false,

  [constants.PATIENTS_FETCH_FAIL]: () => false,

  [constants.PATIENTS_PAGE_FETCH]: () => true,

  [constants.PATIENTS_PAGE_FETCH_SUCCESS]: () => false,

  [constants.PATIENTS_PAGE_FETCH_FAIL]: () => false,
}, false);
