export const formatMedicationForServer = medication => {
  const result = { ...medication };
  result.diagnosisIds = result.diagnosis && result.diagnosis.map(o => o.id);
  result.medicationId = result.medication.id;
  delete result.diagnosis;
  delete result.medication;

  return result;
};

export const medicationWhiteList = [
  'active',
  'description',
  'diagnosis',
  'endDate',
  'id',
  'medication',
  'patientId',
  'prescribed',
  'startDate',
  'printInChart'
];
