const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchUsers = () =>
  request.get('/api/rest/users/')
    .end();

export const createUser = user =>
  request
    .post('/api/rest/users/provider/create')
    .send(user)
    .end();

export const updateUser = user =>
  request
    .post('/api/rest/users/provider/update')
    .send(user)
    .end();

export const updateUserPermissions = (userId, permissionsArr, roleId) => {
  const permissions = permissionsArr.map(permission => (
    permission.id
  ));

  return request
    .post('/api/rest/users/permission/update')
    .send({ id: userId, permissions, roleId })
    .end();
};
