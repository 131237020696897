export default class BillingCodeMapper {
  constructor(activeBillingCode) {
    this._activeBillingCode = activeBillingCode;
  }

  basicFormat = () => ({
    id: this._activeBillingCode.getId(),
    favoriteId: this._activeBillingCode.getFavoriteId(),
    description: this._activeBillingCode.getDescription(),
    cptCode: this._activeBillingCode.getCptCode(),
    ids: this._activeBillingCode.getIds(),
    name: this._activeBillingCode.getFullName(),
  })

  formatCptCodesForFetchingFavorites = () =>
    this._activeBillingCode.getFavoriteId() || this._activeBillingCode.getId()
}
