import { reducer } from '../status/';

const initialState = {};

export default (state = initialState, action) => {
  if (!action.payload || !action.payload.chartId) {
    return state;
  }

  return {
    ...state,
    [action.payload.chartId]: reducer(state[action.payload.chartId], action),
  };
};
