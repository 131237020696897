import React from 'react';
import PropTypes from 'prop-types';

import UserEditForm from 'components/UserEditForm';
import ListLabel from 'components/ListLabel';

import cx from './Root.module.scss';

const Root = ({
  children,
  tabLinks,
  labelVisible,
  labelText,
  formComponent,
  customLabelRenderer,
}) => {
  const hasForm = React.isValidElement(formComponent);

  return (
    <div className={cx.wrapper}>
      <UserEditForm.Header
        links={tabLinks}
      >
        {customLabelRenderer}
        {!customLabelRenderer && (
          <ListLabel visible={labelVisible}>
            {labelText}
          </ListLabel>
        )}

      </UserEditForm.Header>
      <UserEditForm.Wrapper>
        <div
          className={
            cx[hasForm ? 'patient-data' : 'patient-data--wide']
          }
        >
          {children}
        </div>
        {formComponent ?
          (
            <UserEditForm.MedData>
              {formComponent}
            </UserEditForm.MedData>
          ) :
          null
        }
      </UserEditForm.Wrapper>
    </div>
  );
};

Root.propTypes = {
  children: PropTypes.node,
  tabLinks: PropTypes.array.isRequired,
  labelVisible: PropTypes.bool,
  labelText: PropTypes.string,
  formComponent: PropTypes.node,
};

Root.defaultProps = {
  children: null,
}

export default Root;
