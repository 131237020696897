import React from 'react';
import PropTypes from 'prop-types';

import UserEditForm from 'components/UserEditForm';
import Loader from 'components/Loader';

import DiagnosisForm from '../DiagnosisForm';
import diagnosisContainer from '../DiagnosisContainer';

const Diagnosis = diagnosisContainer(DiagnosisForm);

const DiagnosesPage = ({
  children,
  patientId,
  hasActiveDiagnoses,
  isInitialized,
  diagnosisId,
  createDiagnosis,
  updateDiagnosis,
  deleteDiagnosis,
  linkBase,
}) => {
  if (!isInitialized) return <Loader />;

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Active diagnoses', indexLink: true },
        { url: { pathname: `${linkBase}/inactive` }, name: 'Inactive diagnoses' },
      ]}
      labelVisible={!hasActiveDiagnoses}
      labelText="No active diagnoses"
      formComponent={
        <Diagnosis
          diagnosisId={diagnosisId}
          patientId={patientId}
          onCreate={createDiagnosis}
          onUpdate={updateDiagnosis}
          onDelete={deleteDiagnosis}
        />
      }
    >
      {children}
    </UserEditForm.Root>
  );
};

DiagnosesPage.propTypes = {
  children: PropTypes.node.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasActiveDiagnoses: PropTypes.bool.isRequired,
  isInitialized: PropTypes.bool.isRequired,
  diagnosisId: PropTypes.string,
  createDiagnosis: PropTypes.func.isRequired,
  updateDiagnosis: PropTypes.func.isRequired,
  deleteDiagnosis: PropTypes.func.isRequired,
  linkBase: PropTypes.string.isRequired,
};

export default DiagnosesPage;
