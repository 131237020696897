import { handleActions } from 'redux-actions';

import {
  SET_IS_SHOW_IMPORT_MODAL,
} from '../constants';

const initialState = {
  isShowModal: false,
};

const handleSetIsShowImportModal = (state, action) => ({
  ...state,
  isShowModal: action.payload.isShowModal,
});

export default handleActions({
  [SET_IS_SHOW_IMPORT_MODAL]: handleSetIsShowImportModal,
}, initialState);
