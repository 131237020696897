const validator = {
  user: {
    error: 'is required',
    valid: true,
  },
  patient: {
    error: 'is required',
    valid: true,
  },
  from: {
    error: 'is required',
    valid: true,
  },
  to: {
    error: 'is required',
    valid: true,
  },
};

export default validator;
