export default [
  {
    title: 'surgery',
    subtitle: 'Name',
    sortable: true,
  },
  {
    title: 'date',
    subtitle: 'Year',
    sortable: true,
  },
  {
    title: 'comment',
    sortable: true,
  },
];
