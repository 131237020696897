import React from 'react';
import PropTypes from 'prop-types';

import cx from './ListLabel.module.scss';

const ListLabel = ({
  visible,
  children,
}) => {
  if (visible) {
    return (
      <div className={cx.wrapper}>
        <span className={cx.label}>{children}</span>
      </div>
    );
  }

  return null;
};

ListLabel.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ListLabel.defaultProps = {
  visible: false,
};

export default ListLabel;
