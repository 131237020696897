import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PatientHistoryItemsTableContainer from 'containers/PatientHistoryItemsTable';

import Loader from 'components/Loader';

import { DATE_FORMATS } from 'helpers/date/constants';
import fields from './fields';
import Base from 'helpers/fmss/additional-empty-message/factory/empty-message-entity/Base';
import ProducerEmptyMessageFactory from 'helpers/fmss/additional-empty-message/factory/producer-factory';
import { FMSS_TYPES } from 'helpers/fmss/constants';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './MedicalHXTable.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { build } from 'helpers/URLUtils';

const rowStyle = ({ medicalItem, selectedId }) => cssClassesResolver([
  cx.item, medicalItem.id === selectedId && cx['item--active']
]);

const MedicalHXTable = props => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    medicalItems,
    isFetching,
    selectedId,
    error,
    fetchMedical,
    hasNoPertinentMedical = false,
    patientId
  } = props;

  const { timeZoneDateConverter } = useContext(AppContext);

  const showMedicalById = (id) =>
    navigate(
      build({
        pathname,
        query: {
          medical: id,
        },
      })
    );

  const formattedDateField = date => timeZoneDateConverter.getFormattedDateWithTimeZone(date, DATE_FORMATS.YEAR);

  if (isFetching) {
    return <Loader />;
  }

  const conditionalData = new Base(hasNoPertinentMedical);
  const emptyMessage = ProducerEmptyMessageFactory.getMessage(FMSS_TYPES.MEDICAL, conditionalData);

  return (
    <PatientHistoryItemsTableContainer
      items={medicalItems}
      defaultDesc={false}
      defaultSortBy="illness"
      mapItemToField={item => ({
        id: item.id,
        illness: item.illness.name,
        date: formattedDateField(item.startDate),
        comment: item.description,
      })}
      error={error}
      onReload={() => fetchMedical(patientId)}
      fields={fields}
      itemRenderer={medicalItem => (
        <tr
          className={rowStyle({ selectedId, medicalItem })}
          key={medicalItem.id}
          onClick={() => showMedicalById(medicalItem.id)}
        >
          <td>
            <span className={cx.name}>{medicalItem.illness}</span>
          </td>
          <td>{medicalItem.date}</td>
          <td>{medicalItem.comment}</td>
        </tr>
      )}
      emptyMessage={emptyMessage}
    />
  );
};

MedicalHXTable.propTypes = {
  medicalItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    illness: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    startDate: PropTypes.number,
    description: PropTypes.string,
  })).isRequired,
  selectedId: PropTypes.number,
  fetchMedical: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasNoPertinentMedical: PropTypes.bool,
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default MedicalHXTable;
