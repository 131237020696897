import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = false;

export default handleActions({
  [constants.ROLE_UPDATE]: () => true,

  [constants.ROLE_UPDATE_SUCCESS]: () => false,

  [constants.ROLE_UPDATE_FAIL]: () => false,

  [constants.ROLE_REMOVE]: () => true,

  [constants.ROLE_REMOVE_SUCCESS]: () => false,

  [constants.ROLE_REMOVE_FAIL]: () => false,
}, initialState);
