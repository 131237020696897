import React from 'react';
import { compose } from 'redux';
import { useParams, useSearchParams } from 'react-router-dom';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import medicationsContainer, { fetchLayerHOC } from 'containers/Medications/MedicationsContainer';
import { MedicationsList } from 'containers/Medications/MedicationsList';

const MedicationsWrapper = compose(
  chartToPatientContainer,
  medicationsContainer,
  fetchLayerHOC,
)(MedicationsList);

export const ChartMedicationsListWrapper = () => {
  const { chartId, filter } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <MedicationsWrapper
      chartId={chartId}
      medicationId={searchParams.get('medication')}
      filter={filter === 'inactive' ? 'inactive' : 'active'}
    />
  );
};
