import { BILLING_SYSTEMS } from 'helpers/systems/ids';

import cptParser from './cptParser';
import hcpcParser from 'helpers/resourses/fetchResoursesParsers/hcpcParser';

const defaultParser = response => response;

export default (resourceType) => {
  switch (resourceType) {
    case BILLING_SYSTEMS.CPT.resourceType:
      return cptParser;
    case BILLING_SYSTEMS.HCPC.resourceType:
      return hcpcParser;
    default:
      return defaultParser;
  }
};
