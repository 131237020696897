import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';
import { compose } from 'redux';

import SystemNoteContainer, { fetchLayerHOC as systemNoteFetchLayer } from 'containers/SystemNoteContainer';

import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import Controls from './Controls';
import SystemsList from 'components/SystemsList';
import { SigSearch } from 'components/NoteSystems/Systems/SigSeach';

import { getMedicalNameFromDiagnosis } from 'helpers';
import deleteObjectProperty from 'helpers/common/object/delete-property';
import stringParser from 'helpers/common/string/string-parser';

import cx from './Systems.module.scss';

const ConnectedSystemsList = compose(
  SystemNoteContainer,
  systemNoteFetchLayer,
)(SystemsList);

const modalData = {
  type: 'confirm',
  title: 'Remove system',
  content: '<p>Are you sure you want to remove this system?</p><p class="warning-text"><strong>Warning:</strong> All enclosed elements will be removed!</p>',
};

const modalDataFixed = {
  type: 'confirm',
  title: 'Clear contents',
  content: '<p><strong style="color: orange">Warning:</strong> Are you sure you want to remove all enclosed elements?</p>',
};

const DX_HX_FIELD = 'addToDxHx';
const MEDICAL_HX_FIELD = 'addToMedicalHx';
const MEDICATION_HX_FIELD = 'addToMedicationHx';
const ENCOUNTER_NOTE_FIELD = 'addToEncounterNote';

const tableColumns = {
  diagnoses: [
    {
      title: 'Diagnosis',
      subtitle: '',
      sortable: false,
      width: '60%',
    },
    {
      title: 'Add to',
      subtitle: 'Dx Hx',
      sortable: false,
      width: '20%',
    },
    {
      title: 'Add to',
      subtitle: 'Medical Hx',
      sortable: false,
      width: '20%',
    },
  ],
  medications: [
    {
      title: 'Medication',
      subtitle: 'Name',
      sortable: false,
      width: '20%',
    },
    {
      title: 'Sig',
      sortable: false,
      width: '50%',
    },
    {
      title: 'Add to',
      subtitle: 'Medication Hx',
      sortable: false,
      width: '15%',
    },
    {
      title: 'Add to',
      subtitle: 'Encounter Note',
      sortable: false,
      width: '15%',
    },
  ],
};

const stepWithSystemDetailsIds = [1, 2, 4, 5, 6];

class Systems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      hxModal: {
        isOpen: false,
        item: null,
        next: null,
      },
      proceed: false,
      systemToDelete: null,
      medicationsSig: {}
    };
  }

  componentDidMount() {
    const { props } = this;
    const {
      // routes,
      router,
    } = props;

    // const prevRoute = routes[routes.length - 1];

    // this.hook = router.setRouteLeaveHook(prevRoute, this.routerWillLeave);
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    const systemTypes = {
      1: {
        itemsToAdd: nextProps.diagnosesToAdd,
        type: 'diagnoses',
      },
      2: {
        itemsToAdd: nextProps.medicationsToAdd,
        type: 'medications',
      },
    };

    // open popup if we have added diagnoses/medications to progress note
    // which are missing from diagnoses/medications history
    if (!prevProps.activeSystem) return;

    if (
      // check if we navigated outside of system
      prevProps.activeSystem !== nextProps.activeSystem ||
      // check if we opened chart report
      (prevProps.isChartReportVisible !== nextProps.isChartReportVisible && nextProps.isChartReportVisible)
    ) {
      const activeSystemObj = prevProps.systems.find(o => o.id === prevProps.activeSystem);

      if (!activeSystemObj) return;

      const systemType = systemTypes[activeSystemObj.type];

      if (!systemType?.itemsToAdd?.length) return;

      const defaultItemsToAdd = this.setDefaultHxValue(systemType.itemsToAdd);

      this.setState({
        hxModal: {
          isOpen: true,
          ...systemType,
          itemsToAdd: defaultItemsToAdd
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.hook) {
      this.hook();
    }
  }

  setDefaultHxValue = (itemsToAdd) =>
    itemsToAdd.reduce((prev, iteratedItem) => {
      return [
        ...prev,
        {
          ...iteratedItem,
          [DX_HX_FIELD]: true,
          [MEDICAL_HX_FIELD]: false,
          [MEDICATION_HX_FIELD]: true,
          [ENCOUNTER_NOTE_FIELD]: false
        }
      ];
    }, []);

  getMedicationDescription = (medication) => {
    const { medicationsSig } = this.state;
    return medicationsSig[medication.id]?.label || '';
  };

  getElementsCount() {
    const {
      chartingSessions,
      activeSystem,
      chartId,
    } = this.props;

    const systemElements = chartingSessions[chartId][activeSystem];

    return systemElements?.length;
  }

  updateMedicationsSig = ({ medicationId, sig }) => {
    this.setState(prevState => {
      return {
        medicationsSig: {
          ...prevState.medicationsSig,
          [medicationId]: sig
        }
      };
    });
  };

  removeMedicationsSig = (medicationId) => {
    this.setState(prevState => {
      return {
        medicationsSig: {
          ...deleteObjectProperty(prevState.medicationsSig, `${medicationId}`),
        }
      };
    });
  };

  getIsDetailsInputActive = () => {
    const {
      activeSystem,
      stepId,
    } = this.props;

    return !!activeSystem && stepWithSystemDetailsIds.indexOf(stepId) >= 0;
  };

  handleCancel = () => {
    this.setState({
      proceed: true,
    }, () => {
      if (this.state.hxModal.next) {
        this.props.router.push(this.state.hxModal.next);
      }
      this.setState({
        hxModal: {
          isOpen: false,
          item: null,
          next: null,
        },
      });
    });
  };

  handleCreate = () => {
    const { hxModal: { itemsToAdd, type } } = this.state;
    const {
      chartId,
      patientId,
      createDiagnosisFromElement,
      createMedicationFromElement,
      createMedicalItemFromDiagnosis,
    } = this.props;

    if (type === 'diagnoses') {
      itemsToAdd.forEach((diagnosis) => {
        if (diagnosis[DX_HX_FIELD]) {
          createDiagnosisFromElement(diagnosis, chartId, patientId);
        }

        if (diagnosis[MEDICAL_HX_FIELD]) {
          createMedicalItemFromDiagnosis(diagnosis, chartId, patientId);
        }
      });
    }

    if (type === 'medications') {
      itemsToAdd.forEach((medication) => {
        if (medication[MEDICATION_HX_FIELD]) {
          const newMedication = { ...medication };

          newMedication.description = this.getMedicationDescription(newMedication);
          newMedication.printInChart = medication[ENCOUNTER_NOTE_FIELD];

          createMedicationFromElement(newMedication, chartId, patientId);
        }
      });
    }

    this.handleCancel();
  };

  /**
   * Modal component is shit and doesn't update if its props
   * are changed when it is already rendered, so we have
   * to update system first and then show modal
   * @param system
   */
  openModal = (system) => {
    this.setState({
      systemToDelete: system,
    }, () => this.setState({ isModalOpen: true }));
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      systemToDelete: null,
    });
  };

  modalConfirm = async () => {
    const { systemToDelete } = this.state;
    const {
      removeSystem,
      chartId,
    } = this.props;

    await removeSystem(chartId, systemToDelete);

    this.transitionToSystem(undefined, undefined, true, true);
    this.closeModal();
  };

  transitionToSystem = (systemId) => {
    const { router: { push }, location } = this.props;

    if (stringParser(location.query.system, 10) === systemId) return;

    push({
      pathname: location.pathname,
      query: {
        ...location.query,
        system: systemId,
      },
    });
  };

  toggleHxCreation = (item, fieldName) => {
    const { hxModal } = this.state;

    const itemsToAdd = hxModal.itemsToAdd.map((currentItem) => {
      if (currentItem.id === item.id) {
        currentItem[fieldName] = !currentItem[fieldName];
      }

      return currentItem;
    });

    this.setState({
      hxModal: {
        ...hxModal,
        itemsToAdd,
      },
    });
  };

  routerWillLeave = (location) => {
    const { activeSystem, systems } = this.props;
    const activeSystemObj = systems.find(o => o.id === activeSystem);

    const itemsToAdd = [];

    const systemTypes = {
      1: {
        itemsToAdd: this.props.diagnosesToAdd,
        type: 'diagnoses',
      },
      2: {
        itemsToAdd: this.props.medicationsToAdd,
        type: 'medications',
      },
    };

    if (activeSystem) {
      const systemType = systemTypes[activeSystemObj.type];

      if (!systemType || !systemType.itemsToAdd.length) return;

      this.setDefaultHxValue(systemType.itemsToAdd);

      this.setState({
        hxModal: {
          isOpen: true,
          next: location.pathname + location.search,
          ...systemType,
        },
      });
    }

    return !(activeSystem && (activeSystemObj.type === 1 || activeSystemObj.type === 2)) || this.state.proceed || !itemsToAdd.length;
  };

  canAddToMedical = (currentItem) => {
    const { medicalItems } = this.props;

    const medicalName = getMedicalNameFromDiagnosis(currentItem.name);
    const addedDiagnoses = medicalItems.filter(item => (
      item.illness.name === medicalName
    ));

    return !addedDiagnoses.length;
  };

  changeSigWrapper = ({ sig, medicationId }) =>
    sig === null
      ? this.removeMedicationsSig(medicationId)
      : this.updateMedicationsSig({ medicationId, sig });

  renderDiagnosesRow(index, columns, item) {
    const { name } = item;

    const splitterPos = name.indexOf(' ');

    return (
      <tr className={cx['table-row']} key={index}>
        {/* fixing tables styling flaw */}
        <td style={{ width: columns[0].width, textAlign: 'left' }}>
          <b>{name.slice(0, splitterPos)}</b>{name.slice(splitterPos)}
        </td>
        <td style={{ width: columns[1].width }}>
          <div className={cx['checkbox-wrapper']}>
            {this.renderDxHxCheckbox(item)}
          </div>
        </td>
        <td style={{ width: columns[2].width }}>
          <div className={cx['checkbox-wrapper']}>
            {this.renderMedicalHxCheckbox(item)}
          </div>
        </td>
      </tr>
    );
  }

  renderDxHxCheckbox(item) {
    return (
      <Checkbox
        checked={item[DX_HX_FIELD]}
        onClick={() => this.toggleHxCreation(item, DX_HX_FIELD)}
      />
    );
  }

  renderMedicalHxCheckbox(item) {
    return (
      <Checkbox
        checked={item[MEDICAL_HX_FIELD]}
        onClick={() => this.toggleHxCreation(item, MEDICAL_HX_FIELD)}
        enabled={!this.canAddToMedical(item)}
      />
    );
  }

  renderMedicationsRow(index, columns, item) {
    return (
      <tr className={cx['table-row']} key={index}>
        <td style={{ width: columns[0].width, textAlign: 'left' }}>
          {item.name}
        </td>
        <td style={{ width: columns[1].width }}>
          <SigSearch
            medMedicationId={item.id}
            changeSig={sig => this.changeSigWrapper({ medicationId: item.id, sig })}
          />
        </td>
        <td style={{ width: columns[2].width }}>
          <Checkbox
            checked={item[MEDICATION_HX_FIELD]}
            onClick={() => this.toggleHxCreation(item, MEDICATION_HX_FIELD)}
          />
        </td>
        <td style={{ width: columns[3].width }}>
          <Checkbox
            checked={item[ENCOUNTER_NOTE_FIELD]}
            onClick={() => this.toggleHxCreation(item, ENCOUNTER_NOTE_FIELD)}
          />
        </td>
      </tr>
    );
  }

  renderTableRow = (item, type, columns, index) => {
    switch (type) {
      case 'diagnoses':
        return this.renderDiagnosesRow(index, columns, item);

      case 'medications':
        return this.renderMedicationsRow(index, columns, item);
      default:
        break;
    }
  };

  render() {
    const {
      systems,
      nextSystem,
      activeSystem,
      chartId,
      patientId,
      exactMatch,
      broadMatch,
      stepId,
      isChartSaving,
      saveChart,
      saveChartingSessionWrapper,
    } = this.props;

    const { hxModal } = this.state;

    const {
      renderTableRow,
      onNoteChange,
      transitionToSystem,
      openModal,
      closeModal,
      modalConfirm,
      getIsDetailsInputActive,
    } = this;

    const itemsToAdd = hxModal.itemsToAdd || [];

    const {
      isModalOpen,
      systemToDelete,
    } = this.state;

    const columns = tableColumns[hxModal.type] || [];

    const isNoteInputActive = getIsDetailsInputActive();

    return (
      <div className={cx['wrapper']}>
        <div className={cx['wrapper-inner']}>
          {activeSystem && (
            <Controls
              onBack={() => transitionToSystem(undefined)}
              onForward={systemId => transitionToSystem(systemId)}
              nextSystem={nextSystem}
            />
          )}
          <ConnectedSystemsList
            transitionToSystem={transitionToSystem}
            openModal={openModal}
            systems={systems}
            chartId={chartId}
            activeSystem={activeSystem}
            patientId={patientId}
            exactMatch={exactMatch}
            broadMatch={broadMatch}
            isNoteInputActive={isNoteInputActive}
            onNoteChange={onNoteChange}
            elementsCount={this.getElementsCount()}
            stepId={stepId}
            isChartSaving={isChartSaving}
            saveChart={saveChart}
            saveChartingSessionWrapper={saveChartingSessionWrapper}
          />
          <Modal
            isOpen={hxModal.isOpen}
            data={{
              title: `Update ${hxModal.type} history`,
            }}
            size="large"
            onModalClose={this.handleCancel}
          >
            <div>
              <p>
                Do you want to add following
                {' '}
                {hxModal.type}
                {' '}
                to {hxModal.type === 'diagnoses' ? 'Diagnosis' : 'Medication'} List?
              </p>

              <Table
                color="blue"
                style={{
                  height: '100%',
                  marginBottom: '40px',
                }}
              >
                <thead className={cx['table-head']}>
                  <tr>
                    {columns.map((field, i) => (
                      <TableHead
                        {...field}
                        key={i}
                        upperCase={false}
                      />
                    ))}
                  </tr>
                </thead>
                <tbody className={cx['table-body']}>
                  {itemsToAdd.map((item, i) => (
                    renderTableRow(item, hxModal.type, columns, i)
                  ))}
                </tbody>
              </Table>


              <button type="button" className={cx['button']} onClick={this.handleCancel}>Cancel</button>
              <button
                type="button"
                className={cx['button']}
                onClick={this.handleCreate}
              >
                Add
              </button>
            </div>

          </Modal>

          <Modal
            data={systemToDelete?.fixed ? modalDataFixed : modalData}
            isOpen={isModalOpen}
            onModalClose={closeModal}
            onModalConfirm={modalConfirm}
          />
        </div>
      </div>
    );
  }
}

Systems.propTypes = {
  systems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    fixed: PropTypes.bool.isRequired,
  })).isRequired,
  medicalItems: PropTypes.array,
  router: PropTypes.object.isRequired,
  chartingStepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeSystem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isChartReportVisible: PropTypes.bool,
  // creatable: PropTypes.bool,
  diagnosesToAdd: PropTypes.array,
  medicationsToAdd: PropTypes.array,
  createDiagnosisFromElement: PropTypes.func,
  createMedicationFromElement: PropTypes.func,
  createMedicalItemFromDiagnosis: PropTypes.func,
  removeSystem: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  nextSystem: PropTypes.object,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
  stepId: PropTypes.number,
  deleteSystemNote: PropTypes.func,
  systemNotes: PropTypes.object,
  saveChart: PropTypes.func.isRequired,
  saveChartingSessionWrapper: PropTypes.func,
};

Systems.defaultProps = {
  saveChartingSessionWrapper: null,
};

export default withRouter(Systems);
