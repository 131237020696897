import React from 'react';

import cx from './StepIndicator.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

const Checkmark = () => (
  <div className={cx.checkmark} />
);

const circleStyles = (step) => `cx.circle ${step} === 0 ? cx['circle--active'] : cx['circle--passed']`
const circleStepTwoStyles = (step) => `cx.circle ${step} === 1 ? cx['circle--active'] : ''`

export default ({ step }) => (
  <div className={cx.wrapper}>
    <div
      className={circleStyles(step)}
    >
      {step === 0 ? '1' : <Checkmark />}
    </div>
    <div className={cssClassesResolver([
      cx.line,
      step === 1 && cx['line--active']
    ])} />
    <div
      className={circleStepTwoStyles(step)}
    >
      2
    </div>
  </div>
);
