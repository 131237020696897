import React from 'react';
import PropTypes from 'prop-types';

import RenderCard from 'components/RenderCard';

const SeatPlansModal = ({ closePlansModal, paymentsPlans, setCurrentPlanToPurchaseSeat }) => {
  return (
    <div>
      {paymentsPlans.listPlans.map(plan => (
        <RenderCard
          hidePlansModal={closePlansModal}
          card={plan}
          key={plan.id}
          setCurrentPlanToPurchaseSeat={setCurrentPlanToPurchaseSeat}
        />
      ))}
    </div>
  );
};

SeatPlansModal.propTypes = {
  closePlansModal: PropTypes.func.isRequired,
  paymentsPlans: PropTypes.shape({
    listPlans: PropTypes.array,
  }).isRequired,
  setCurrentPlanToPurchaseSeat: PropTypes.func,
};

SeatPlansModal.defaultProps = {
  setCurrentPlanToPurchaseSeat: null,
};

export default SeatPlansModal;
