import { handleActions } from 'redux-actions';
import * as constants from '../constants';

import keyBy from 'lodash/keyBy';

export default handleActions({
  [constants.SOCIAL_SYSTEMS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result, 'id'),
  }),
}, {});
