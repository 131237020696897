import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import SeatsContainer, { fetchLayerHOC as seatsFetchLayer } from 'containers/SeatsContainer';
import ClinicContainer, { fetchLayerHOC as clinicFetchLayer } from 'containers/ClinicContainer';
import PaymentInfoContainer from 'containers/UserPaymentInfoContainer';

import SeatRow from 'routes/routes/App/routes/Settings/routes/Users/components/Seats/SeatRow';
import Table from 'components/Table';
import FixedHeader from 'components/Table/FixedHeader';
import TableHead from 'components/Table/TableHead';
import Loader from 'components/Loader';
import BuySeatButton from 'components/BuySeatButton';

import { isLockFreeSeat } from 'components/SeatUsersDropdown/helpers';
import { addNewProperty, isMobileApp } from 'helpers';
import { isUserPermissionsContainPermission, purePermissions } from 'helpers/permissions';

import cx from './SeatsList.module.scss';

const tableColumns = [
  {
    title: 'seat',
    subtitle: 'number',
    sortable: false,
    width: '13%',
  },
  {
    title: 'status',
    subtitle: 'indicator',
    sortable: false,
    width: '13%',
  },
  {
    title: 'user',
    subtitle: 'name',
    sortable: false,
    width: '34%',
  },
  {
    title: 'user',
    subtitle: 'suffix',
    sortable: false,
    width: '30%',
  },
];

const SeatsList = props => {
  const {
    seats,
    areSeatsFetched,
    areSeatsFetching,
    areSeatsSaving,
    paymentsPlans,
    seatSetStatus,
    removeUserFromSeatLocally,
    clinicUsers,
    setSeatUser,
    setNextSeatUser,
    removeSeatUser,
    clinicInfo,
    setPurchaseSeatModalsView
  } = props;

  const getUsersForSeat = () => {
    return clinicUsers.filter((user) => {
      return isUserPermissionsContainPermission(user.permissions, purePermissions.SIGN_ENCOUNTER_NOTE);
    });
  };

  const handleSeatUserChange = (seatData, seat) => {
    const newSeatData = collectUserDataForAssignToSeat(seatData);

    const { userId } = newSeatData;
    const prevUserId = seat.userId;

    if (userId === null || typeof userId === 'undefined') {
      return removeSeatUser(newSeatData, prevUserId);
    }

    if (isLockFreeSeat(seat)) {
      return setNextSeatUser(newSeatData);
    }

    return setSeatUser(newSeatData);
  };

  const collectUserDataForAssignToSeat = (seatData) => {
    return {
      ...seatData,
      ...addNewProperty('clinicId', clinicInfo.id, seatData),
    };
  };

  const handleSeatBuy = () => {
    setPurchaseSeatModalsView({
      isModalOpen: false,
      isPlansModalOpen: true,
      isBillingInfoModalOpen: false,
    });
  };

  if (areSeatsFetching || !areSeatsFetched || paymentsPlans.isFetching) {
    return <Loader />;
  }

  const seatsArr = Object.values(seats);

  const usersForSeat = getUsersForSeat();

  return (
    <div className={cx.wrapper}>
      <FixedHeader
        fields={tableColumns}
        style={{
          height: '80px',
        }}
      >
        <TableHead
          width="10%"
          component="div"
          key="buySeat"
        >
          {!isMobileApp() &&
            <BuySeatButton
              buySeat={handleSeatBuy}
            />
          }
        </TableHead>
      </FixedHeader>
      <Table color="blue" style={{ display: 'initial' }}>
        <tbody>
          {seatsArr.map((seat, index) => (
            <SeatRow
              tableColumns={tableColumns}
              index={index}
              users={usersForSeat}
              handleSeatUserChange={handleSeatUserChange}
              seat={seat}
              seats={seats}
              areSeatsSaving={areSeatsSaving}
              handleSeatBuy={handleSeatBuy}
              seatSetStatus={seatSetStatus}
              key={seat.id}
              removeUserFromSeatLocally={removeUserFromSeatLocally}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

SeatsList.propTypes = {
  areSeatsFetched: PropTypes.bool.isRequired,
  areSeatsFetching: PropTypes.bool.isRequired,
  areSeatsSaving: PropTypes.bool.isRequired,
  clinicUsers: PropTypes.arrayOf(PropTypes.shape({
    enable: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lastName: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
    npi: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    providerId: PropTypes.number.isRequired,
    setToSeat: PropTypes.bool,
    signature: PropTypes.string,
    specialty: PropTypes.string,
    suffix: PropTypes.string,
    userClinicDetailsId: PropTypes.number.isRequired,
  })).isRequired,
  seats: PropTypes.object.isRequired,
  clinicInfo: PropTypes.object.isRequired,
  setSeatUser: PropTypes.func.isRequired,
  setNextSeatUser: PropTypes.func.isRequired,
  removeSeatUser: PropTypes.func.isRequired,
  seatSetStatus: PropTypes.func.isRequired,
  paymentsPlans: PropTypes.object.isRequired,
  setPurchaseSeatModalsView: PropTypes.func.isRequired
};

export const SeatsListWrapper = compose(
  PaymentInfoContainer,
  ClinicContainer,
  clinicFetchLayer,
  SeatsContainer,
  seatsFetchLayer
)(SeatsList);

