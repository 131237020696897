import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';

import NoteItemsContainer from 'containers/NoteItemsContainer';
import SystemsContainer from 'containers/SystemsContainer';
import MedicalContainer, { medicalFetchLayer } from 'containers/FMSS/Medical/MedicalContainer';

import Systems from 'components/NoteSystems/Systems';
import Loader from 'components/Loader';
import RightItemsWrapper from 'components/ElementsViewItems/RightItemsWrapper';

import * as helpers from 'helpers';
import isEqual from 'lodash/isEqual';

import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/ChartingStep/ChartingStep.module.scss';

const ConnectedSystems = compose(
  MedicalContainer,
  medicalFetchLayer,
  SystemsContainer,
)(Systems);

const ConnectedRightSideItems = compose(
  NoteItemsContainer,
)(RightItemsWrapper);

class ChartingStep extends Component {
  componentDidMount() {
    // if we have systemId in url but systems are empty
    // user probably refreshed page while being in empty system
    // we try to push this system to current charting session
    if (this.props.systemId && this.props.systems.length === 0) {
      this.props.addSystem(this.props.chartId, this.props.systemId);
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      chartingSessionElements,
      diagnosesSystemElements,
      medicationsSystemElements,
      activeItems,
      containers,
      items,
      systems,
      exactMatch,
      broadMatch,
      isChartSaving,
    } = this.props;

    return !(isEqual(chartingSessionElements, nextProps.chartingSessionElements, 'id')
      && isChartSaving === nextProps.isChartSaving
      && isEqual(items, nextProps.items, 'id')
      && isEqual(activeItems, nextProps.activeItems)
      && isEqual(diagnosesSystemElements, nextProps.diagnosesSystemElements)
      && isEqual(medicationsSystemElements, nextProps.medicationsSystemElements)
      && isEqual(containers, nextProps.containers, 'id')
      && isEqual(systems, nextProps.systems)
      && helpers.shallowEqual(broadMatch, nextProps.broadMatch)
      && helpers.shallowEqual(exactMatch, nextProps.exactMatch));


  }

  addRightSideElementCallback = () => {
    const {
      saveChartingSessionWrapper,
      step,
      chartId,
    } = this.props;

    saveChartingSessionWrapper(chartId, step);
  };

  render() {
    const {
      isChartSaving,
      step,
      areSystemsFetching,
      systemId,
      chartId,
      exactMatch,
      broadMatch,
      patientId,
    } = this.props;

    if (areSystemsFetching || areSystemsFetching === undefined) {
      return <Loader />;
    }

    return (
      <div className={cx.wrapper}>
        <ConnectedSystems
          stepId={step}
          chartId={chartId}
          chartingStepId={step}
          activeSystem={systemId}
          broadMatch={broadMatch}
          exactMatch={exactMatch}
          isChartSaving={isChartSaving}
          patientId={patientId}
        />
        <ConnectedRightSideItems
          addRightSideElementCallback={this.addRightSideElementCallback}
          chartId={chartId}
          stepId={step}
          systemId={systemId}
          exactMatch={exactMatch}
          broadMatch={broadMatch}
          isChartSaving={isChartSaving}
        />
      </div>
    );
  }
}

ChartingStep.propTypes = {
  systemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  systems: PropTypes.arrayOf(PropTypes.object),
  addSystem: PropTypes.func.isRequired,
  areSystemsFetching: PropTypes.bool,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
  isChartFetching: PropTypes.bool,
  isChartSaving: PropTypes.bool,
  stepSystems: PropTypes.array,
  patientId: PropTypes.number.isRequired,
  saveChartingSessionWrapper: PropTypes.func,
};

ChartingStep.defaultProps = {
  saveChartingSessionWrapper: null,
};

export default withRouter(ChartingStep);
