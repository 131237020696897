import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';

import rootReducer from 'modules/rootReducer';
import createMiddleware from 'store/clientMiddleware';

const clientMiddleware = createMiddleware();

const middlewares = [
  thunk,
  clientMiddleware,
  promise,
];

export const _createStore = (state) => createStore(
  rootReducer,
  state,
  compose(
    applyMiddleware(...middlewares),
  )
);

const store = _createStore();

export default store;
