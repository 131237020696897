import React from 'react';

import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';
import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';
import MedSelectContainer from 'containers/MedSelect/MedSelectContainer';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import ValidatedDatepicker from 'components/ValidatedDatepicker';
import CommentBox from 'components/CommentBox';
import SimpleSelect from 'components/MedSelect/SimpleSelect/SimpleSelect';
import ValidatedField from 'components/Form/ValidatedField';

import { medicalItemWhiteList } from 'helpers/fmss/medical/medical';
import duplicateMedicalItemComparator from 'helpers/fmss/comparators/duplicate-items/medical';

import { formatMedOption } from 'helpers/selectHelpers';
import { buildSchema } from 'helpers/validationRules';

import { createIllness, searchIllnesses, removeIllnesses } from 'api/family';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptyMedicalItem = {
  description: '',
  diagnosis: [],
};

const mapProps = (medical) => ({
  id: medical.id,
  illness: formatMedOption(medical?.illness),
  startDate: medical.startDate,
  description: medical.description,
})

const validationSchema = () => buildSchema({
  illness: 'requiredSelectOption',
})

const MedicalItemForm = props => (
  <FormHOC
    emptyItem={emptyMedicalItem}
    item={props.medicalItem}
    itemId={props.medicalItemId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={props.updateMedicalItem}
    onCreate={props.createMedicalItem}
    onDelete={props.deleteMedicalItem}
    patientId={props.patientId}
    whiteList={medicalItemWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    itemsInTable={props.itemsInTable}
    duplicateItemComparator={duplicateMedicalItemComparator}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({
 itemId: medicalItemId, item: medicalItem, fetching, clean, update, del, submit,
}) => {
      return (
        <form
          onSubmit={submit}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={medicalItemId ? 'Editing illness' : 'Adding illness'}
                onClick={medicalItemId ? clean : () => null}
              />

              <div className={cx.row}>
                <h3 className="section-title">Illness</h3>
                <ValidatedField>
                  <SimpleSelect
                    name="illness"
                    currentValue={medicalItem.illness}
                    loadOptions={searchIllnesses}
                    onChange={value => update('illness', value)}
                    onCreate={createIllness}
                    onRemoveOption={removeIllnesses}
                  />
                </ValidatedField>
              </div>

              <div className={cx.row}>
                <ValidatedDatepicker
                  title="start date"
                  value={medicalItem.startDate}
                  onChange={value => update('startDate', value)}
                />
              </div>

              <div className={cx.row}>
                <CommentBox
                  title="illness comment"
                  placeholder="Enter a comment"
                  value={medicalItem.description}
                  onChange={e => update('description', e.target.value)}
                />
              </div>
            </div>

            <EditFormActions
              deleteButton={!!medicalItemId}
              cancelButton
              submitButton
              disabledSubmit={fetching}
              disabledCancel={fetching}
              onDelete={del}
              onCancel={clean}
            />

          </EditForm>
        </form>
      );
    }}
  </FormHOC>
);

MedicalItemForm.propTypes = generateFormPropTypes({
  itemName: 'medicalItem',
  itemIdName: 'medicalItemId',
  onUpdate: 'updateMedicalItem',
  onCreate: 'createMedicalItem',
  onDelete: 'deleteMedicalItem',
});

const DirtyCheckedWrapper = dirtyCheckLayerHOC(MedSelectContainer(MedicalItemForm));

export default props => (
  <LoaderLayer
    disabled={props.medicalItemId === undefined}
    status={props.status}
    reload={() => props.fetchMedicalItem(props.medicalItemId)}
  >
    <DirtyCheckedWrapper
      {...props}
      dirtyCheckIdentity="medicalItemId"
    />
  </LoaderLayer>
);
