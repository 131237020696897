import React from 'react';
import cx from './Controls.module.scss';
import { transformOverflowName } from 'helpers/chart';

const Controls = props => {
  const {
    nextSystem,
    onBack,
    onForward,
    social,
  } = props;

  return (
    <div className={cx.wrapper}>
      <button
        type="button"
        className={cx.back}
        onClick={onBack}
      >
        <span>{(social && 'All Social Hx') || 'All systems'}</span>
      </button>
      {nextSystem && (
        <button
          type="button"
          className={cx.forward}
          onClick={() => onForward(nextSystem.id)}
        >
          <span>
            <span>
              {transformOverflowName(nextSystem.name, 25, 22)}
            </span>
          </span>
        </button>
      )}
    </div>
  );
}

export default Controls;
