import React from 'react';
import { connect } from 'react-redux';

import * as actions from 'modules/financial/financialTab/actions';

export const fetchLayerHOC = (Component) => {
  return class FinancialTabFetchLayer extends React.Component {
    componentDidMount() {
      this.props.fetchLastBillingPlan();
    }
    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapActionCreators = {
  ...actions,
};

export default component => connect(null, mapActionCreators)(component);
