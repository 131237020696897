import React from 'react';
import PropTypes from 'prop-types';

import cx from './PageError.module.scss';

const PageError = ({ message, reload }) => {
  return (
    <div className={cx['no-data']}>
      <span className="text-center">
        {message}<br />
        <button
          type="button"
          className="generic-button"
          onClick={reload}
        >
          Reload
        </button>
      </span>
    </div>
  );
};

PageError.propTypes = {
  message: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
};

export default PageError;
