import { handleActions } from 'redux-actions';
import * as constants from './constants';
import * as constantsPaymentInfo from '../paymentInfo/constants';

const initialState = {
  isFetching: false,
  isFetched: false,
  vip: false,
};

export default handleActions({
  [constants.LAST_BILLING_PLAN_FETCH]: (state, action) => ({
    ...state,
    isFetching: true,
  }),

  [constants.LAST_BILLING_PLAN_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result,
    isFetching: false,
    isFetched: true,
  }),

  [constants.LAST_BILLING_PLAN_FETCH_FAIL]: (state, action) => ({
    ...state,
    isFetching: false,
  }),

  [constantsPaymentInfo.PAYMENT_INFO_SAVE_SUCCESS]: (state, action) => ({
    ...state,
    savedCard: true,
  }),

  [constantsPaymentInfo.PAYMENT_INFO_SAVE_FAIL]: (state, action) => ({
    ...state,
    savedCard: false,
  }),

  [constants.SET_AUTO_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    autoPayment: action.payload.data.autoPayment,
  }),
}, initialState);
