import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PersonShortInfo from 'components/PersonShortInfo';
import Tabs from 'components/Tabs';
import Loader from 'components/Loader';

import { isMobileApp } from 'helpers';
import { getUserInfo } from 'modules/user/selectors';

import cx from './Settings.module.scss';

export const SettingsPage = () => {
  const user = useSelector(state => getUserInfo(state.user));

  const { permissions } = useContext(AppContext);

  if (!user) {
    return <Loader />;
  }

  const tabsHead = [
    {
      title: 'General',
      url: '/app/doctor/settings',
      indexLink: true,
      disabled: !permissions.accessEditOrganizationDemo,
    },
    {
      title: 'Users',
      url: '/app/doctor/settings/users',
      disabled: !permissions.accessEditUsers,
    },
    {
      title: 'Financial',
      url: '/app/doctor/settings/financial',
      disabled: !permissions.accessEditFinancial,
    },
    {
      title: 'Add-ons',
      url: '/app/doctor/settings/addons',
      disabled: !permissions.accessEditAddons,
    },
  ];

  if (isMobileApp()) {
    tabsHead.splice(2, 1);
  }

  return (
    <div className={cx['settings-page']}>
      <PersonShortInfo
        person={user}
      />
      <div className={cx['tabs-wrapper']}>
        <Tabs headerItems={tabsHead}>
          <Outlet />
        </Tabs>
      </div>
    </div>
  );
};

SettingsPage.prototype = {
  user: PropTypes.objectOf(PropTypes.shape({
    agreementAccepted: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lastName: PropTypes.string.isRequired,
    npi: PropTypes.string.isRequired,
    providerId: PropTypes.number.isRequired,
    quickPassword: PropTypes.bool.isRequired,
    signature: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired,
  })),
};
