import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import cx from './ChartReportItem.module.scss';

import ChartReportElementWithTooltip from '../../ChartReportElementWithTooltip';
import RemoveButton from '../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../ChartReportElementWithTooltip/DoorButton';

const MENU_BUTTON_DIAMETER = 40;

const RegularLink = ({ children, onClick, link }) => {
  return (
    <Link
      className="styleless-link"
      to={link}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const TooltipButton = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openMenu = () => setIsMenuOpen(true)

  const closeMenu = () => setIsMenuOpen(false)

  const {
    children, toggleChartReport, link, onSystemRemove,
  } = props;

  return (
    <ChartReportElementWithTooltip
      isOpen={isMenuOpen}
      onOpen={openMenu}
      onClose={closeMenu}
    >

      <span>{children}</span>

      <div className="flex-container">
        {link && (
          <DoorButton
            style={{
              width: MENU_BUTTON_DIAMETER,
              height: MENU_BUTTON_DIAMETER,
              marginRight: (onSystemRemove && 10) || 0,
            }}
            link={link}
            onClick={() => {
              closeMenu();
              toggleChartReport();
            }}
          />
        )}
        {onSystemRemove && (
          <RemoveButton
            onClick={onSystemRemove}
            style={{
              width: MENU_BUTTON_DIAMETER,
              height: MENU_BUTTON_DIAMETER,
            }}
          />
        )}

      </div>

    </ChartReportElementWithTooltip>
  );
};

const ChartReportItem = (props) => {
  const {
    title,
    children,
    link,
    toggleChartReport,
    tooltip,
    onSystemRemove,
  } = props;

  let value = title;

  let button;

  if (tooltip) {
    button = (
      <TooltipButton
        toggleChartReport={toggleChartReport}
        link={link}
        onSystemRemove={onSystemRemove}
      >
        <span>{value}</span>
      </TooltipButton>
    );
  } else {
    button = (
      <RegularLink
        onClick={link && toggleChartReport}
        link={link}
      >
        {value}
      </RegularLink>
    );
  }

  return (
    <div className={cx.wrapper}>
      {value && (
      <div className={cx.title}>
        {button}
      </div>
        )}
      <div className={cx.children}>
        {children}
      </div>
    </div>
  );
};

export default ChartReportItem;
