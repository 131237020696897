import React, { useEffect, useState } from 'react';
import { func, bool, shape, number, string } from 'prop-types';

import Select from 'react-select';

import { formatOption, formatOptions } from 'helpers/selectHelpers';

const RoleSelect = props => {
  const [allRoles, setAllRoles] = useState();

  const {
    onRolesFetch,
    roles,
    customRole,
    currentCustomRole,
    isFetched,
    onSelect,
    currentRole
  } = props;

  useEffect(() => {
    onRolesFetch(roles);
    setAllRoles(roles);
  }, [isFetched, roles]);

  useEffect(() => {
    if (customRole) {
      setAllRoles(prev => ({ ...prev, 0: customRole }));
    }
  }, [customRole]);

  useEffect(() => {
    if (currentCustomRole) {
      setAllRoles(prev => ({ ...prev, [-1]: currentCustomRole }));
    }
  }, [currentCustomRole]);

  const onChange = (option) => {
    if (option === null) {
      onSelect(null);
    } else {
      onSelect(allRoles[option.value]);
    }
  };

  const currentOption = formatOption(currentRole) || -1;
  const options = formatOptions(allRoles);

  return (
    <div className="wrapper">
      <Select
        onChange={onChange}
        options={options}
        value={currentOption}
        placeholder="Select Role..."
      />
    </div>
  );
};

RoleSelect.propTypes = {
  roles: shape({}).isRequired,
  isFetched: bool.isRequired,
  onRolesFetch: func.isRequired,
  currentCustomRole: shape({}),
  customRole: shape({}),
  currentRole: shape({
    id: number,
    name: string
  }),
  onSelect: func.isRequired,
};

export default RoleSelect;
