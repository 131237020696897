import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RegularElement from 'components/ElementsViewItem/RegularElement';
import ElementEditButton from 'components/ElementEditButton';
import CancelRoundButton from 'components/CancelRoundButton';
import SaveRoundButton from 'components/SaveRoundButton';

import { formatParamsWithWhiteList } from 'helpers/charting/charting';
import {
  whiteListPropsForUpdateSocialSubElementRequest,
  whiteListPropsForUpdateChartSubElementRequest
} from 'helpers/charting/whiteListProps';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './Subelement.module.scss';

const styles = ({ editMode }) => ({
  form: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['wrapper', editMode && 'wrapper--edit']
  }),
  editButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'control-button-wrapper',
    globalStyles: editMode && 'hidden'
  }),
  editButton: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['edit-button', 'control-button']
  }),
  saveButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['control-button-wrapper', 'save-button-wrapper'],
    globalStyles: !editMode && 'hidden'
  }),
  cancelButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'control-button-wrapper',
    globalStyles: !editMode && 'hidden'
  })
})

class Subelement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      value: props.subelement.name,
    };
  }

  toggleEdit = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      value: this.props.subelement.name,
    }), () => this.props.onToggleEdit(this.state.editMode));
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  disableEditMode = () => {
    if (this.props.social === true) {
      this.setState({ editMode: false });
    }
  };

  // when we edit subelement instead of updating old one we create new subelement
  // to prevent changing old encounters
  // so editMode is not set to false after successful submit
  // because old subelement is deleted and new is added to list, and component where editMode was true is removed
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.value === this.props.subelement.name) return;

    const whiteListToUpdateSubElement = (this.props.social && whiteListPropsForUpdateSocialSubElementRequest) || whiteListPropsForUpdateChartSubElementRequest;

    const formattedArgs = formatParamsWithWhiteList({ ...this.props.subelement, name: this.state.value }, whiteListToUpdateSubElement);

    this.props.updateSubelement(formattedArgs);

    this.disableEditMode();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  };

  render() {
    const { onClick, match, subelement } = this.props;
    const { value, editMode } = this.state;

    const resolvedStyles = styles({ editMode })

    return (
      <form
        className={resolvedStyles.form}
        onSubmit={this.handleSubmit}
        onKeyDown={this.handleKeyDown}
      >
        <RegularElement
          onClick={onClick}
          disabled={editMode}
          item={(editMode && { name: value }) || subelement}
          match={match}
          editMode={editMode}
          inputClassName={cx.input}
          onChange={this.handleChange}
        />
        <span className={resolvedStyles.editButtonWrapper}>
          <ElementEditButton
            className={resolvedStyles.editButton}
            onClick={this.toggleEdit}
          />
        </span>

        <span className={resolvedStyles.saveButtonWrapper}>
          <SaveRoundButton
            className={cx['control-button']}
            loading={subelement.isSaving}
          />
        </span>

        <span className={resolvedStyles.cancelButtonWrapper}>
          <CancelRoundButton
            className={cx['control-button']}
            onClick={this.toggleEdit}
          />
        </span>
      </form>
    );
  }
}

Subelement.propTypes = {
  subelement: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isSaving: PropTypes.bool,
  }).isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  updateSubelement: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  match: PropTypes.string,
  social: PropTypes.bool,
};

export default Subelement;
