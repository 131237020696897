import React, { Component } from 'react';

import cx from './Modal.module.scss';

class KeywordConfirm extends Component {

  state = {
    confirmText: '',
  };

  createMarkup(content) {
    return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    );
  }

  resetModal() {
    this.setState({
      confirmText: '',
    });

    this.keyWordInput.value = '';
  }

  handleModalConfirm = () => {
    this.props.onModalConfirm({ ...this.state });

    this.resetModal();
  };

  handleModalClose = () => {
    this.props.onModalClose();

    this.resetModal();
  };

  handleKeywordChange = (e) => {
    this.setState({ confirmText: e.target.value });
  };

  render() {
    const {
      data,
      cancelDisabled,
    } = this.props;

    const { confirmText } = this.state;

    return (
      <div>
        <div className={cx['content-container']}>
          {this.createMarkup(data.content)}
        </div>
        <div className={cx['modal-window-keyWord-wrapper']}>
          <span className={cx['keyword-label']}>Type {data.keyWord}</span>
          <input
            ref={ref => this.keyWordInput = ref}
            onChange={this.handleKeywordChange}
          />
        </div>
        <div className={cx['modal-window-controls']}>
          {!cancelDisabled &&
            <button type="button" className={cx['button']} onClick={this.handleModalClose}>
              Cancel
            </button>
          }
          <button type="button" className={cx['button']} onClick={this.handleModalConfirm} disabled={(confirmText !== data.keyWord)}>
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

export default KeywordConfirm;
