import React from 'react';
import PropTypes from 'prop-types';

import cx from './TextArea.module.scss';

const TextArea = (props) => {
  const { placeholder, onChange, value } = props;

  return (
    <div className={cx.wrapper}>
      <textarea
        onChange={onChange}
        value={value}
        className={cx.textarea}
        placeholder={placeholder}
      />
    </div>
  );
}

TextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TextArea;
