import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = false;

export default handleActions({
  [constants.SEAT_USER_SET]: () => true,
  [constants.SEAT_USER_SET_SUCCESS]: () => false,
  [constants.SEAT_USER_SET_FAIL]: () => false,

  [constants.SEAT_USER_REMOVE]: () => true,
  [constants.SEAT_USER_REMOVE_SUCCESS]: () => false,
  [constants.SEAT_USER_REMOVE_FAIL]: () => false,

  [constants.SEAT_CREATE]: () => true,
  [constants.SEAT_CREATE_SUCCESS]: () => false,
  [constants.SEAT_CREATE_FAIL]: () => false,
}, initialState);
