import invert from 'lodash/invert';

export default [
  {
    title: 'action type',
    width: '12%',
    sortable: true,
  },
  {
    title: 'action',
    width: '11%',
    sortable: true,
  },
  {
    title: 'message',
    width: '26.99999999%',
    sortable: true,
  },
  {
    title: 'patient name',
    width: '16.66666%',
    sortable: true,
  },
  {
    title: 'user name',
    width: '16.66666%',
    sortable: true,
  },
  {
    title: 'date',
    width: '16.66666%',
    sortable: true,
  },
];

export const sortMap = {
  date: 'date',
  userName: 'user name',
  patientName: 'patient name',
  message: 'message',
  action: 'action',
  actionType: 'action type',
};

export const invertedSortMap = invert(sortMap);

// title: 'encounter',
// subtitle: 'Date',
// sortable: true,
// width: '12%',
