import React from 'react';

import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';
import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import RadioButtons from 'components/RadioButtons';
import CommentBox from 'components/CommentBox';
import ValidatedField from 'components/Form/ValidatedField';
import SimpleSelect from 'components/MedSelect/SimpleSelect/SimpleSelect';

import { allergyWhiteList } from 'helpers/fmss/allergies/allergies';
import { drugItems, severityItems, statusItems, onsetItems } from 'containers/Allergies/helpers';
import { formatMedOption } from 'helpers/selectHelpers';
import { buildSchema } from 'helpers/validationRules';
import { selectOption } from 'helpers/propsGenerator';

import {
  searchAllergy,
  searchDrug,
  searchEnvironment,
  newAllergy,
  createReaction,
  searchReactions,
  deleteMedAllergy,
  deleteMedReaction,
} from 'api/allergies';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptyAllergy = {
  allergy: selectOption.default,
  active: true,
  drug: true,
  severity: null,
  onset: null,
  description: '',
  reactions: []
};

const mapProps = (allergy) => ({
  id: allergy.id,
  drug: allergy.drug,
  allergy: formatMedOption(allergy.allergy),
  severity: allergy.severity,
  reactions: allergy?.reactions?.map(formatMedOption),
  onset: allergy.onset,
  active: allergy.active,
  description: allergy.description,
})

const validationSchema = () => buildSchema({
  allergy: 'requiredSelectOption',
})

const getSearchAllergyFunc = (type) => {
  let func;
  switch (type) {
    case 'drug':
      func = searchDrug;
      break;
    case 'environment':
      func = searchEnvironment;
      break;
    default:
      func = searchAllergy;
      break;
  }

  return func;
};

const AllergyForm = props => (
  <FormHOC
    emptyItem={emptyAllergy}
    item={props.allergy}
    itemId={props.allergyId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={props.onUpdate}
    onCreate={props.onCreate}
    onDelete={props.onDelete}
    patientId={props.patientId}
    whiteList={allergyWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({ itemId: allergyId, item: allergy, fetching, clean, update, del, submit }) => {
      let allergyToSearch = 'drug';

      if (allergy.drug === true) {
        allergyToSearch = 'drug';
      }
      if (allergy.drug === false) {
        allergyToSearch = 'environment';
      }

      return (
        <form
          onSubmit={submit}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={allergyId ? 'Editing allergy' : 'Adding allergy'}
                onClick={allergyId ? clean : () => null}
              />

              <div className={cx.row}>
                <RadioButtons
                  active={allergy.drug}
                  items={drugItems}
                  onChange={value => update('drug', value)}
                />
              </div>

              <div className={cx.row}>
                  {allergyToSearch === 'drug' && (
                    <ValidatedField>
                      <SimpleSelect
                        name='allergy'
                        currentValue={allergy.allergy}
                        onCreate={option => newAllergy({ name: option, drug: allergyToSearch === 'drug' })}
                        onChange={value => update('allergy', value)}
                        loadOptions={(input) => getSearchAllergyFunc(allergyToSearch)(input)}
                        onRemoveOption={deleteMedAllergy}
                      />
                    </ValidatedField>
                  )}
                  {allergyToSearch === 'environment' && (
                    <ValidatedField>
                      <SimpleSelect
                        name='allergy'
                        currentValue={allergy.allergy}
                        onCreate={option => newAllergy({ name: option, drug: allergyToSearch === 'drug' })}
                        onChange={value => update('allergy', value)}
                        loadOptions={(input) => getSearchAllergyFunc(allergyToSearch)(input)}
                        onRemoveOption={deleteMedAllergy}
                      />
                    </ValidatedField>
                  )}
              </div>

              <div className={cx.row}>
                <RadioButtons
                  title="Severity"
                  buttonClassName={cx['severity-button']}
                  active={allergy.severity}
                  items={severityItems}
                  onChange={value => update('severity', value)}
                />
              </div>

              <div className={cx.row}>
                <h3 className="section-title">Reaction</h3>
                <SimpleSelect
                  currentValue={allergy.reactions}
                  onCreate={createReaction}
                  onChange={value => update('reactions', value)}
                  loadOptions={searchReactions}
                  onRemoveOption={deleteMedReaction}
                  isMulti
                />
              </div>

              <div className={cx.row}>
                <RadioButtons
                  title="Onset"
                  active={allergy.onset}
                  items={onsetItems}
                  onChange={value => update('onset', value)}
                />
              </div>

              <div className={cx.row}>
                <RadioButtons
                  title="Status"
                  active={allergy.active}
                  items={statusItems}
                  onChange={value => update('active', value)}
                  required
                />
              </div>

              <div className={cx.row}>
                <CommentBox
                  title="allergy comment"
                  placeholder="Enter an instruction for this allergy"
                  value={allergy.description}
                  onChange={e => update('description', e.target.value)}
                />
              </div>
            </div>

            <EditFormActions
              deleteButton={!!allergyId}
              cancelButton
              submitButton
              // disabledSubmit={!allergy.allergy || fetching}
              disabledSubmit={fetching}
              disabledCancel={fetching}
              // onSubmit={submit}
              onDelete={del}
              onCancel={clean}
            />

          </EditForm>
        </form>
      );
    }}
  </FormHOC>
);

AllergyForm.propTypes = generateFormPropTypes({ itemName: 'allergy', itemIdName: 'allergyId' });

const DirtyCheckedWrapper = dirtyCheckLayerHOC(AllergyForm);
export default AllergyForm;

// export default props => (
//   <LoaderLayer
//     disabled={props.allergyId === undefined}
//     status={props.status}
//     reload={() => props.fetchAllergy(props.allergyId)}
//   >
//     <DirtyCheckedWrapper
//       {...props}
//       dirtyCheckIdentity="allergyId"
//     />
//   </LoaderLayer>
// );
