import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import TabsHeader from 'components/UserEditForm/Header';

const getTabsHead = permissions => ([
  {
    name: 'Organization Demo',
    url: '/app/doctor/settings',
    disabled: !permissions.accessEditOrganizationDemo,
    indexLink: true,
  },
  {
    name: 'Preferences',
    url: '/app/doctor/settings/preferences',
    disabled: !permissions.accessEditOrganizationDemo,
  },
  {
    name: 'Consent Templates',
    url: '/app/doctor/settings/consent-templates',
    disabled: !permissions.accessEditOrganizationDemo,
  },
]);

export const GeneralTab = props => {
  const { permissions } = useContext(AppContext);

  const tabsHead = getTabsHead(permissions);

  return (
    <div className='child-tabs-wrapper'>
      <TabsHeader links={tabsHead} />
      <Outlet />
    </div>
  );
};
