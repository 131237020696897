import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useImpersonate } from 'hooks/useImpersonate';

import { getSettings } from 'modules/settings/selectors';

import Dialog from 'components/Dialog';
import FlatButton from 'components/FlatButton';

const DEFAULT_TIMEOUT_MINUTES = 20;

// TODO: add show up/fade up transitions
function SessionTimer() {
  const { sessionTimeout = DEFAULT_TIMEOUT_MINUTES } = useSelector(state => getSettings(state));
  const [sessionExpired, setSessionExpired] = useState(false);
  const timerRef = useRef(null);
  const { logout } = useImpersonate();

  const getSessionExpireTime = useCallback(() => {
    return sessionTimeout * 60 * 1000;
  }, [sessionTimeout]);

  const setTimer = useCallback(() => {
    timerRef.current = setTimeout(() => {
      showSessionExpireWindow();
    }, getSessionExpireTime());
  }, []);

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const updateTimer = useCallback(() => {
    clearTimer();
    setTimer();
  }, []);

  const handlePageRefresh = useCallback(() => {
    if (sessionExpired) {
      logout();
    }
  }, []);

  const handleDialogSubmit = useCallback(() => {
    logout();
    setSessionExpired(false);
  }, []);

  const showSessionExpireWindow = useCallback(() => {
    setSessionExpired(true);
  }, []);

  useEffect(() => {
    setTimer();

    window.addEventListener('mousemove', updateTimer);
    window.addEventListener('keydown', updateTimer);
    window.addEventListener('beforeunload', handlePageRefresh);

    return () => {
      clearTimer();
      window.removeEventListener('mousemove', updateTimer);
      window.removeEventListener('keydown', updateTimer);
      window.removeEventListener('beforeunload', handlePageRefresh);
    };
  }, []);


  if (!sessionExpired) {
    return null;
  }

  return (
    <Dialog isOpened={sessionExpired}>
      <h3>Your session has expired.</h3>
      <p>Please, sign in again</p>
      <div className="dialog-actions">
        <FlatButton label="Ok" onClick={handleDialogSubmit} />
      </div>
    </Dialog>
  );
}

export default SessionTimer;
