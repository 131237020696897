import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImageStyle from 'helpers/images/styles/background-image';

import cx from './UserItem.module.scss';

const UserItem = (props) => {
  const {
    imageUrl,
    name,
    email,
  } = props.data;

  console.log(props, 'p');

  return (
      <div className={props.seatImage ? cx['item-wrapper__no-image'] : cx['item-wrapper']}>
        <div className={cx['image-container']}>
          {!props.seatImage && (
            <div
              className={cx.signature}
              style={{ ...BackgroundImageStyle.define(imageUrl) }}
            />
          )}
        </div>

        <div className="data-wrapper">
          <div className={cx.name}>{name}</div>
          <div className={cx.email}>{email}</div>
        </div>
      </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired,
  seatImage: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string
};

export default UserItem;
