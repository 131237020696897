import { connect } from 'react-redux';
import { setRegistrationStep, getRegistrationStep } from 'modules/registrationStep';

const mapStateToProps = (state, ownProps) => ({
  step: getRegistrationStep(state.registrationStep),
  ...ownProps,
});

const mapActionCreators = {
  setRegistrationStep,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
