import { connect } from 'react-redux';

import { getSidebarState, toggleSidebar } from 'modules/sidebar';
import { getError, isLocked, isProcessing, unlock } from 'modules/locked';
import { getIsAuthenticated, getUserInfo, isBlankClinic } from 'modules/user/selectors';
import * as selectors from 'modules/pastdue/selectors';
import { fetchUser } from 'modules/user/actions';

const mapStateToProps = state => ({
  isSidebarActive: getSidebarState(state.sidebar),
  locked: isLocked(state.locked),
  lockProcessing: isProcessing(state.locked),
  unlockError: getError(state.locked),
  userInfo: getUserInfo(state.user),
  pastdue: selectors.getPastDue(state),
  isAuthenticated: getIsAuthenticated(state.user),
  isBlankClinic: isBlankClinic(state.user)
});

const mapActionCreators = {
  toggleSidebar,
  unlock,
  fetchUser,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
