import React from 'react';
import PropTypes from 'prop-types';

export default class UpdateBlocker extends React.Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shouldUpdate: PropTypes.func,
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.shouldUpdate) {
      return nextProps.shouldUpdate(nextProps.dirty, nextProps.identifier);
    }

    return !nextProps.dirty;
  }

  render() {
    return this.props.children;
  }
}
