import { transformBold, transformElementName } from 'helpers/chart';
import { convertToHtmlNewlines, replaceSequenceRegex, variableButtonPattern } from 'helpers';

export default class ElementNameConverter {
  constructor(timeZoneDateConverter) {
    this.timeZoneDateConverter = timeZoneDateConverter;
  }

  transformElementNameWrapper = (...args) => {
    return transformElementName(...args, this.timeZoneDateConverter);
  };

  transformElementNameWithSubElements = (...args) => {
    const newArgs = [];
    const elementName = args[0].name;
    newArgs.push(elementName, args[1]);
    let displayNameAsArray = this.transformElementNameWrapper(...newArgs);

    const element = args[0];

    const needRenderSubElements = displayNameAsArray.some(part =>
      typeof part === 'string' && part.includes('??')
    ) && element?.subIds?.length;

    if (needRenderSubElements) {
      const replacementArray = element.subIds.map(subelement => subelement?.name);

      displayNameAsArray = displayNameAsArray.map(partName => replaceSequenceRegex(partName, variableButtonPattern, replacementArray));
      displayNameAsArray = displayNameAsArray.map(transformBold);
    }

    displayNameAsArray = displayNameAsArray.map(partName => convertToHtmlNewlines(partName));

    return displayNameAsArray;
  };

  transformedSeveralNames = (name, patient) => {
    if (!name) {
      return [];
    }
    const parts = name.split(', ');
    const partsLength = parts.length;
    return parts.reduce((res, part, i) => {
      const isLast = i === partsLength - 1;

      return isLast
      ? [ ...res, ...this.transformElementNameWithSubElements({ name: part }, patient) ]
      : [
        ...res,...this.transformElementNameWithSubElements({ name: part }, patient),
        ', '
      ];
    }, []);
  };
}
