import { handleActions } from 'redux-actions';

import * as constants from './constants';

export default handleActions({
  [constants.HISTORY_ITEM_CREATE]: () => true,
  [constants.HISTORY_ITEM_CREATE_SUCCESS]: () => false,
  [constants.HISTORY_ITEM_CREATE_FAIL]: () => false,
}, false);

export const getIsCreating = state => state;
