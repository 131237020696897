import React from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';

import Select from 'react-select';

import fetchAllMapValues from 'helpers/common/map/values/fetch-all';
import GENDER_SELECT_OPTIONS from 'helpers/patient/gender/constants/select-options';
import * as PatientGenderService from 'helpers/patient/gender/PatientGenderService';

const GenderDropdown = (props) => {
  const { setFieldValue } = useFormikContext();

  const {
    value,
    disabled,
    className,
    styles
  } = props;

  const genderOptions = fetchAllMapValues(GENDER_SELECT_OPTIONS);
  const genderValue = PatientGenderService.validateValue(value);

  const onUpdate = genderEntry =>
    setFieldValue('gender', genderEntry.value)

  return (
    <Select
      className={className}
      name="gender"
      value={genderValue}
      searchable={false}
      options={genderOptions}
      onChange={onUpdate}
      disabled={disabled}
      clearable={false}
      styles={styles}
    />
  );
};

GenderDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

GenderDropdown.defaultProps = {
  disabled: false,
  className: '',
};

export default GenderDropdown;
