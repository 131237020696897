import RightSideCpt from 'helpers/resourses/RightSideCpt';
import BillingCodeMapper from 'helpers/resourses/BillingCodeMapper';

export const parseRightSideCpt = (rightSideBillingCode) => {
  const rightSideBillingCodeResource = new RightSideCpt(rightSideBillingCode);
  const rightSideBillingCodeResourceMapper = new BillingCodeMapper(rightSideBillingCodeResource);

  return rightSideBillingCodeResourceMapper.basicFormat();
};

export default cptList =>
  cptList.map(parseRightSideCpt);
