import React, { useEffect, useState } from 'react'
import { func, shape, bool, number, string } from 'prop-types'

import Input from 'components/MedSelect/SimpleSelect/Input';
import Options from 'components/MedSelect/SimpleSelect/Options';

import { isNullOrUndefined } from 'helpers';
import isEmpty from 'helpers/common/array/is-empty'
import MultiInput from 'components/MedSelect/SimpleSelect/MultiInput';

const SimpleSelect = (props) => {
  const {
    loadOptions,
    onChange,
    onCreate,
    onRemoveOption,
    isCreatable,
    currentValue,
    isMulti,
    checkAllowingToRemoveOption
  } = props

  const EMPTY_SEARCHABLE_QUERY = ''

  const [value, setValue] = useState(currentValue?.name)
  const [seachableValue, setSeachableValue] = useState(EMPTY_SEARCHABLE_QUERY)
  const [options, setOptions] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([])
  const [optionsIsOpen, setOptionsIsOpen] = useState(false)

  const CREATE_OPTION = { id: -1, name: `Create ${seachableValue}`, isNew: true }

  const openOptions = () => setOptionsIsOpen(true)

  const closeOptions = () => setOptionsIsOpen(false)

  const clearSeachableValue = () => setSeachableValue('')

  const closeOptionWrapper = () => {
    clearSeachableValue()
    closeOptions()
  }

  const onInputChange = (typedValue) => {
    if (typedValue === '') {
      closeOptions()
    }

    setSeachableValue(typedValue)
  }

  useEffect(() => {
    const isChanged = Number.isInteger(value?.id) && isNullOrUndefined(currentValue?.id)

    if (isChanged) {
      clearSeachableValue()
    }
    setValue(currentValue)
  }, [currentValue?.name])

  const parseResponseOptions = ({ loadedOptions, seachableValue } = { loadedOptions: [], seachableValue: '' }) => {
    const isNotExistSearchableValue = loadedOptions
      .filter(loadedOption => seachableValue.toUpperCase() === loadedOption?.name?.toUpperCase())
      .length === 0
    const isNeedCreate = isCreatable &&
      Array.isArray(loadedOptions) && isNotExistSearchableValue &&
      seachableValue !== EMPTY_SEARCHABLE_QUERY;

    return isNeedCreate ? [CREATE_OPTION, ...loadedOptions] : loadedOptions;
  }

  const excludeAdded = (searchedOptions) => {
    const addedOptions = Array.isArray(currentValue) ? currentValue : [currentValue]
    if (isEmpty(addedOptions)) {
      return searchedOptions
    }
    const addedOptionsIds = addedOptions.map(addedOption => addedOption.id)

    return searchedOptions
      .filter(searchedOption => !addedOptionsIds.includes(searchedOption.id))
  }

  const loadOptionsWrapper = async (saveOptions) => {
    const { body } = await loadOptions(seachableValue)
    const parsedResponseOptions = parseResponseOptions({ loadedOptions: body, seachableValue })
    const toDisplay = excludeAdded(parsedResponseOptions)
    saveOptions(toDisplay)
    openOptions()
  }

  const isEmptySearchableValue = () => seachableValue === ''

  useEffect(() => {
    if (!isEmptySearchableValue() && !isNullOrUndefined(seachableValue)) {
      loadOptionsWrapper(setOptions)
    }
  }, [seachableValue])

  const resolveOnChange = (selectedOption) => {
    const content = isMulti ? [...currentValue, selectedOption] : selectedOption

    onChange(content)
  }

  const handleCreateOption = async (createdName) => {
    const createResponse = await onCreate(createdName)
    if (Number.isInteger(createResponse?.body)) {
      const { body } = createResponse
      const createdOption = { id: body, name: createdName }
      resolveOnChange(createdOption)
    }
  }

  const onChangeWrapper = (option) => {
    if (option?.isNew) {
      handleCreateOption(seachableValue)
    } else {
      resolveOnChange(option)
    }

    clearSeachableValue()
    closeOptions()
  }

  const isNotDeleteOptionPredicate = (optionCandidateId, optionToDeleteId) =>
    optionCandidateId !== optionToDeleteId

  const handleRemove = optionToDeleteId => {
    const filteredOptions = options
      .filter(option => isNotDeleteOptionPredicate(option.id, optionToDeleteId))
    const filteredDefaultOptions = defaultOptions
      .filter(option => isNotDeleteOptionPredicate(option.id, optionToDeleteId))

    setOptions(filteredOptions)
    setDefaultOptions(filteredDefaultOptions)
    onRemoveOption(optionToDeleteId)
  }

  const removeMultiValueWrapper = id =>
    onChange(currentValue.filter(item => item.id !== id))

  const onFocusWrapper = () => {
    if (isEmpty(defaultOptions)) {
      loadOptionsWrapper(setDefaultOptions)
    }
    openOptions()
  }

  return (
    <>
      {isMulti
        ? <MultiInput
            onFocus={onFocusWrapper}
            removeMultiValue={removeMultiValueWrapper}
            seachableValue={seachableValue}
            values={currentValue}
            onChange={onInputChange}
          />
        : <Input
            onFocus={onFocusWrapper}
            value={value?.name}
            seachableValue={seachableValue}
            resetValue={() => setValue('')}
            onChange={onInputChange}
          />
      }
      {optionsIsOpen && (
        <Options
          checkAllowingToRemoveOption={checkAllowingToRemoveOption}
          closeOptions={closeOptionWrapper}
          onSelect={onChangeWrapper}
          onRemove={handleRemove}
          options={isEmptySearchableValue() ? excludeAdded(defaultOptions) : options}
        />
      )}
    </>
  )
};


SimpleSelect.propTypes = {
  loadOptions: func.isRequired,
  onChange: func.isRequired,
  onCreate: func.isRequired,
  onRemoveOption: func.isRequired,
  currentValue: shape({
    id: number,
    name: string
  }),
  isCreatable: bool,
  isMulti: bool,
  checkAllowingToRemoveOption: func
}

SimpleSelect.defaultProps = {
  currentValue: undefined,
  isCreatable: true,
  isMulti: false,
  checkAllowingToRemoveOption: option => !isNullOrUndefined(option?.userId)
}



export default SimpleSelect
