import Patients, { PatientsTab } from './components/Patients';

import PatientPage from './routes/Patient/components/PatientPage';
import { DiagnosesRoute } from './routes/Patient/routes/Diagnoses';
import DemographicsPage from './routes/Patient/routes/Demographics';
import Encounters from './routes/Patient/routes/Encounters/components/Encounters';
import { MedicationsRoute } from './routes/Patient/routes/Medications';
import { AllergiesRoute } from './routes/Patient/routes/Allergies';
import { FMSSRoute } from './routes/Patient/routes/FMS';
import { DemographicsTabs } from './routes/Patient/routes/Demographics/DemographicsTabs';
import { DemographicsForms } from 'routes/routes/App/routes/Patients/routes/Patient/routes/Demographics/Forms';

export const PatientsRoute = {
  path: 'patients',
  element: <Patients />,
  children: [
    {
      index: true,
      element: <PatientsTab />
    },
    {
      path: ':patientId',
      element: <PatientPage />,
      children: [
        {
          element: <DemographicsTabs />,
          children: [
            { path: '', element: <DemographicsPage /> },
            { path: 'forms', element: <DemographicsForms /> }
          ]
        },
        {
          path: 'encounters',
          element: <Encounters />
        },
        MedicationsRoute,
        AllergiesRoute,
        DiagnosesRoute,
        FMSSRoute
      ]
    }
  ],
};
