import { connect } from 'react-redux';
import React from 'react';
import { fetchMedication } from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';
import FetchLayer, { getMapStateToProps } from '../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const medicationContainer = Component => props => (
  <FetchLayer
    itemId={props.medicationId}
    fetchItem={props.fetchMedication}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.MEDICATIONS, 'medication', 'medicationId');

const mapActionCreators = {
  fetchMedication,
};

export default component => connect(mapStateToProps, mapActionCreators)(medicationContainer(component));
