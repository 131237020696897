import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Link, useLocation } from 'react-router-dom';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { TransitionMotion, spring, presets } from 'react-motion';

import SearchTabContainer from 'containers/Patients/PatientsSearchTabContainer';
import withPatientsSearchTab from 'containers/Patients/with-patients-search-tab';

import PatientsList from './PatientsList';
import PatientInfo from './PatientInfo';

import cx from './PatientsSearch.module.scss';

const PATIENT_INFO_LEFT_OFFSET = 310;
const PATIENT_INFO_WIDTH = 500;

const patientDefaultStyles = [];

const PatientsSearch = (props) => {
  const [activePatient, setActivePatient] = useState(null);
  const { pathname } = useLocation();

  const {
    clearPatientsSearch,
    visible,
    ...rest
  } = props;

  const { permissions } = useContext(AppContext);

  useEffect(() => {
    return () => {
      clearPatientsSearch()
    }
  }, [])

  const getPatient = (patientId, patients) => {
    return patients[patientId];
  };

  const willLeave = () => ({
    opacity: spring(0),
    left: spring(-100, presets.noWobble),
  });

  const willEnter = () => ({
    opacity: 0,
    left: -100,
  });

  const patientWillLeave = () => ({
    left: spring(-PATIENT_INFO_LEFT_OFFSET - PATIENT_INFO_WIDTH, presets.noWobble),
  });

  const patientWillEnter = () => ({
    left: -PATIENT_INFO_LEFT_OFFSET - PATIENT_INFO_WIDTH,
  });

  const selectPatient = id => setActivePatient(id);

  const closePatientInfo = () => setActivePatient(null);

  const styles = !visible ? [] : [{
    key: '0',
    style: {
      opacity: spring(0.3, presets.noWobble),
      left: spring(0, presets.noWobble),
    },
  }];

  const patientStyles = !activePatient || !visible ? [] : [{
    key: '1',
    data: activePatient,
    style: {
      left: spring(0, presets.noWobble),
    },
  }];

  const  { patients } =  rest;

  return (
    <TransitionMotion
      willLeave={willLeave}
      willEnter={willEnter}
      styles={styles}
    >
      {interpolatedStyles => (
        <div>
          {interpolatedStyles.map(({ key, style }) => (
            <div key={key} className={cx['patients-search']} style={props.style}>
              <div className={cx['inner-wrapper']}>
                <Link
                  to={pathname}
                  className={cx.link}
                  style={{
                    pointerEvents: !visible && style.opacity < 0.3 && 'none',
                  }}
                />

                <div
                  className={cx['patients-list-wrapper']}
                  style={{
                    transform: `translateX(${style.left}%)`,
                  }}
                >
                  <PatientsList
                    clearPatientsSearch={clearPatientsSearch}
                    onSelectPatient={selectPatient}
                    activePatient={activePatient}
                    permissions={permissions}
                    {...rest}
                  />
                </div>

                <TransitionMotion
                  willLeave={patientWillLeave}
                  willEnter={patientWillEnter}
                  styles={patientStyles}
                  defaultStyles={patientDefaultStyles}
                >
                  {patientInterpolatedStyles => (
                    <div
                      className={cx['patient-info-outer-wrapper']}
                      style={{
                        pointerEvents: !activePatient && 'none',
                      }}
                    >
                      {patientInterpolatedStyles.map(({ key, style, data }) => (
                        <div
                          key={key}
                          className={cx['patient-info-wrapper']}
                          style={{ transform: `translateX(${style.left}px)` }}
                        >
                          <PatientInfo
                            data={data}
                            patients={patients}
                            patient={getPatient(data, patients)}
                            closePatientInfo={closePatientInfo}
                            permissions={permissions}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </TransitionMotion>

              </div>
              <div
                className={cx.overlay}
                style={{
                  opacity: style.opacity,
                }}
              />
            </div>
          ))}
        </div>
      )}
    </TransitionMotion>
  );
}

PatientsSearch.propTypes = {
  clearPatientsSearch: PropTypes.func.isRequired,
  isPatientInfoOpened: PropTypes.string,
};

const SearchTabConnector = compose(
  SearchTabContainer,
  withPatientsSearchTab,
)(PatientsSearch);

export default SearchTabConnector;
