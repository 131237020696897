const request = require('superagent-promise')(require('superagent'), Promise);

export const register = data =>
  request
    .post('/api/administrator/register')
    .send(data)
    .end();

export const changePassword = data =>
  request
    .post('/api/administrator/password/change')
    .send(data)
    .end();

export const findUsers = (template) =>
  request
    .get('/api/administrator/find/users')
    .query({ template })
    .end();

export const getUsers = (clinicId) =>
  request
    .get('/api/administrator/users/')
    .query({ clinicId })
    .end();

export const updateUser = (data) =>
  request
    .post('/api/administrator/users/')
    .send(data)
    .end();

export const findClinics = userId =>
  request
    .get('/api/administrator/find/clinics')
    .query({ userId })
    .end();

export const getClinicsNames = () =>
  request
    .get('/api/administrator/clinics/names')
    .end();

export const getClinicsConfig = () =>
  request
    .get('/api/administrator/clinics/config')
    .end();

export const setClinicConfig = (data) =>
  request
    .post('/api/administrator/clinics')
    .send(data)
    .end();

export const patientsUpload = (clinicId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  return request
    .post('/api/administrator/patients/upload')
    .send(formData)
    .query({ clinicId })
    .end();
};

export const restartTomcat = () =>
  request
    .get('/api/tomcat/restart')
    .end();

