import { useDispatch } from 'react-redux';
import { createChart } from 'modules/charts/actions';
import { useNavigate } from 'react-router-dom';
import { fetchChartIdFromCreatingResponse } from 'helpers/charting/charting';

export const useCreateChart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createChartWrapper = async (data) => {
    console.log(data, 'data');
    const { body } = await dispatch(createChart(data));
    const chartId = fetchChartIdFromCreatingResponse(body);
    navigate(`/app/doctor/charts/${chartId}?step=1`);
  };

  return {
    createChart: createChartWrapper
  };
};