import * as chartHelpers from 'helpers/chart';

const TABS_TO_SENDING_ELEMENT_NAME_TO_SERVER = [
  chartHelpers.hpChartTabs.ROS.step,
  chartHelpers.hpChartTabs.Plan.step,
  chartHelpers.hpChartTabs.PE.step,
  chartHelpers.hpChartTabs.A.step,
];

const isNeedSendingElementNameToServer = step =>
  TABS_TO_SENDING_ELEMENT_NAME_TO_SERVER.includes(step);


export default isNeedSendingElementNameToServer;
