import isEqual from 'lodash/isEqual';

import { getObjectsIds } from 'helpers';

export const permissionsMap = {
  PATIENTS: 'createEditPatients',
  CLINICAL_INFO: 'viewEditPatientClinicalInformation',
  NOTE: 'viewEditEncounterNote',
  SIGN_ENCOUNTER_NOTE: 'signEncounterNote',
  ELEMENTS: 'addEditElements',
  REPORTS_ANALYTICS: 'accessReportsAnalytics',
  USERS_PERMISSIONS: 'accessEditUsers',
  FINANCIAL: 'accessEditFinancial',
  ADDONS: 'accessEditAddons',
  CLINIC: 'accessEditOrganizationDemo',
};

export const permissionsDescriptionMap = {
  'Create/Edit - Patients and Demographics': 'createEditPatients',
  'View/Edit - Patient Clinical Information (Med List, Allergy List, F/M/S/S Hx, Diagnosis List, Vitals)': 'viewEditPatientClinicalInformation',
  'View/Edit - Encounter Note': 'viewEditEncounterNote',
  'Sign encounter note': 'signEncounterNote',
  'Create/Edit - Encounter Note Elements': 'addEditElements',
  'Access Reports & Analytics': 'accessReportsAnalytics',
  'Access/Edit - Users & Permissions': 'accessEditUsers',
  'Access/Edit - Financial': 'accessEditFinancial',
  'Access/Edit - Add-ons': 'accessEditAddons',
  'Access/Edit - Organization Demo': 'accessEditOrganizationDemo',
};

export const purePermissions = {
  PATIENTS: 'Create/Edit - Patients and Demographics',
  CLINICAL_INFO: 'View/Edit - Patient Clinical Information (Med List, Allergy List, F/M/S/S Hx, Diagnosis List, Vitals)',
  NOTE: 'View/Edit - Encounter Note',
  SIGN_ENCOUNTER_NOTE: 'Sign encounter note',
  ELEMENTS: 'Create/Edit - Encounter Note Elements',
  REPORTS_ANALYTICS: 'Access Reports & Analytics',
  USERS_PERMISSIONS: 'Access/Edit - Users & Permissions',
  FINANCIAL: 'Access/Edit - Financial',
  ADDONS: 'Access/Edit - Add-ons',
  CLINIC: 'Access/Edit - Organization Demo',
};

export const defaultRoles = {
  provider: {
    name: 'Provider - Default Role',
  },
  administrativeStaff: {
    name: 'Administrative staff - Default Role',
  },
  clinicalStaff: {
    name: 'Clinical_staff - Default Role',
  },
  manager: {
    name: 'Manager - Default Role',
  },
  custom: {
    name: 'Custom - Default Role',
    id: 6,
  },
};

export const isUserPermissionsContainPermission = (userPermissions, purePermission) => {
  const filteredPermissions = userPermissions.filter(permission => permission.name === purePermission || permission.description === purePermission);

  return filteredPermissions.length > 0;
};

export const permissionsInitialState = Object.keys(permissionsMap).reduce((acc, val) => {
  const obj = { ...acc };

  obj[permissionsMap[val]] = false;
  return obj;
}, {});

export const isEqualTwoSequencesOfPermissions = (firstSequence, secondSequence) => {
  const permissionsIdsOfFirstSequence = getObjectsIds(firstSequence);
  const permissionsIdsOfSecondSequence = getObjectsIds(secondSequence);

  const setOfFirstPermissionsIds = new Set(permissionsIdsOfFirstSequence);
  const setOfSecondPermissionsIds = new Set(permissionsIdsOfSecondSequence);

  return isEqual(setOfFirstPermissionsIds, setOfSecondPermissionsIds);
};
