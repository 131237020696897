import { connect } from 'react-redux';
import React from 'react';
import * as actions from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';

import FetchLayer, { getMapStateToProps } from '../../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const medicalItemContainer = Component => props => (
  <FetchLayer
    itemId={props.medicalItemId}
    fetchItem={props.fetchMedicalItem}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.MEDICAL, 'medicalItem', 'medicalItemId');


const mapActionCreators = {
  fetchMedicalItem: actions.fetchMedicalItem,
  deleteMedicalItem: actions.deleteMedicalItem,
  createMedicalItem: actions.createMedicalItem,
  updateMedicalItem: actions.updateMedicalItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(medicalItemContainer(component));
