export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAIL = 'USER_FETCH_FAIL';

export const USER_ID_FETCH = 'USER_ID_FETCH';
export const USER_ID_FETCH_SUCCESS = 'USER_ID_FETCH_SUCCESS';
export const USER_ID_FETCH_FAIL = 'USER_ID_FETCH_FAIL';

export const PERMISSIONS_UPDATE = 'PERMISSIONS_UPDATE';
export const PERMISSIONS_UPDATE_SUCCESS = 'PERMISSIONS_UPDATE_SUCCESS';
export const PERMISSIONS_UPDATE_FAIL = 'PERMISSIONS_UPDATE_FAIL';

export const PERMISSIONS_UPDATE_FOR_CURRENT_USER_LOCALLY = 'PERMISSIONS_UPDATE_FOR_CURRENT_USER_LOCALLY';
