export const drugItems = [{
  name: 'Drug',
  value: true,
}, {
  name: 'Environment',
  value: false,
}];

export const severityItems = [{
  name: 'Very mild',
  value: 0,
}, {
  name: 'Mild',
  value: 1,
}, {
  name: 'Moderate',
  value: 2,
}, {
  name: 'Severe',
  value: 3,
}];

export const onsetItems = [{
  name: 'Childhood',
  value: 0,
}, {
  name: 'Adulthood',
  value: 1,
}, {
  name: 'Unknown',
  value: 2,
}];

export const statusItems = [{
  name: 'Active',
  value: true,
}, {
  name: 'Inactive',
  value: false,
}];
