import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const withPatientsSearchTab = Component => props => {
  const { searchTabFetchPatients, fetchPatientImage } = props

  useEffect(() => {
    const fetchPatients = async () => {
      const { body } = await searchTabFetchPatients();

      const promises = body.map((patient) => {
        const { imageName, id } = patient;
        if (imageName) {
          return Promise.resolve(fetchPatientImage(imageName, id));
        }
        return Promise.resolve();
      });

      await Promise.all(promises);
    }

    fetchPatients()
  }, [])

  return <Component {...props} />;
};

withPatientsSearchTab.propTypes = {
  searchTabFetchPatients: PropTypes.func.isRequired,
  fetchPatientImage: PropTypes.func.isRequired,
};

export default withPatientsSearchTab;
