import escapeRegExp from 'lodash/escapeRegExp';
import reactStringReplace from 'react-string-replace';

export const isRegistrationDisabled = () => true;

export const isNullOrUndefined = item => item === null || item === undefined;

export const stripWhiteListProps = (obj, propNamesArray) => {
  if (isNullOrUndefined(obj)) return null;
  if (!propNamesArray) return obj;

  const result = { ...obj };

  Object.keys(result).forEach((prop) => {
    if (propNamesArray.includes(prop)) return;

    delete result[prop];
  });

  return result;
};

export const capitalize = (string = '') => string.replace(/\b\w/g, l => l.toUpperCase());

export const getScrollBarWidth = () => {
  const inner = document.createElement('p');
  inner.style.width = '100%';
  inner.style.height = '200px';

  const outer = document.createElement('div');
  outer.style.position = 'absolute';
  outer.style.top = '0px';
  outer.style.left = '0px';
  outer.style.visibility = 'hidden';
  outer.style.width = '200px';
  outer.style.height = '150px';
  outer.style.overflow = 'hidden';
  outer.appendChild(inner);

  document.body.appendChild(outer);
  const w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  let w2 = inner.offsetWidth;
  if (w1 === w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return (w1 - w2);
};

export const replaceAll = (target = '', search = '', replacement = '') => {
  return target.replace(new RegExp(escapeRegExp(search), 'g'), replacement);
};

export const getAllIndexes = (arr, val) => {
  const indexes = [];
  let i = -1;

  while ((i = arr.indexOf(val, i + 1)) !== -1) {
    indexes.push(i);
  }
  return indexes;
};

// searchArray - items to searchThrough
// txt - what to search
// searchKey - what object prop to search in
export const search = (searchArray = [], txt = '', searchKey) => {
  if (!searchKey) return searchArray;

  const s = replaceAll(txt.toLowerCase(), ' ', '');

  return searchArray.filter((item) => {
    return replaceAll(item[searchKey].toLowerCase(), ' ', '').indexOf(s) >= 0;
  });
};

export const replaceSequenceRegex = (input = '', regex, replacementArray) => {
  if (!replacementArray || !replacementArray.length || !regex) return input;

  let result = input;
  const matchArray = result.match(regex);

  matchArray.forEach((match, i) => {
    result = result.replace(match, replacementArray[i] || match);
  });

  return result;
};

export const replaceSequence = (input = '', search, replacementArray) => {
  if (!replacementArray || !replacementArray.length || !search) return input;

  let result = input;

  const occurencesCount = getAllIndexes(result, search).length;

  let lastIndex = 0;

  for (let i = 0; i < occurencesCount; i++) {
    const index = result.indexOf(search, lastIndex);
    const replacement = replacementArray[i] || search;

    result = result.substr(0, index) + replacement + result.substr(index + search.length);
    lastIndex = index + 1;
  }

  return result;
};

export const variableButtonPattern = /(\?\?\d?)/g;
export const newLines = /(\n+)/g;

export const parseElementNameWithVariables = (elementName) => {
  const originalName = elementName;

  const matchArray = elementName.match(variableButtonPattern);

  return {
    originalName,
    variables: matchArray.map((value) => {
      let setNumber = parseInt(value.replace('??', ''));
      if (Number.isNaN(setNumber)) {
        setNumber = undefined;
      }

      return {
        originalText: value,
        setNumber,
      };
    }),
  };
};

export const isNotElementContainSubelements = (elementName) => {
  return !elementName.includes('??');
};

export const getSubElemntNumberGroupList = (elementName) => {
  if (isNotElementContainSubelements(elementName)) {
    return [];
  }

  const variablesForElement = parseElementNameWithVariables(elementName).variables;


  return variablesForElement.map((variable) => {
    const numberGroup = variable.setNumber;

    if (numberGroup === undefined) {
      return 0;
    }

    return numberGroup;
  });
};

export const getAllIndexesToAutoSetSubElement = (elementName) => {
  const numberGroupList = getSubElemntNumberGroupList(elementName);

  const indexesToAutoSet = [];

  if (numberGroupList.length > 0) {
    numberGroupList.forEach((element, index) => {
      if (index !== 0) {
        indexesToAutoSet.push(index);
      }
    });
  }

  return indexesToAutoSet;
};

const defineDifferenceBetweenArraysOfNumberGroups = (newNumberGroups, oldNumberGroups) => {
  const newNumberGroupLength = newNumberGroups.length;
  const oldNumberGroupLength = oldNumberGroups.length;

  if (newNumberGroupLength < oldNumberGroupLength) {
    return [];
  }

  const lessLength = newNumberGroupLength <= oldNumberGroupLength ? newNumberGroupLength : oldNumberGroupLength;
  const diff = [];

  for (let i = 0; i < lessLength; i += 1) {
    if (newNumberGroups[i] !== oldNumberGroups[i]) {
      diff.push(i);
    }
  }

  if (newNumberGroupLength > oldNumberGroupLength) {
    for (let i = oldNumberGroupLength; i < newNumberGroupLength; i += 1) {
      diff.push(i);
    }
  }

  return diff;
};

export const differencesInSubelementsNumberGroups = (newElementName, oldElementName) => {
  const subElementNumberGroupListForOldElement = getSubElemntNumberGroupList(oldElementName);
  const subElementNumberGroupListForNewElement = getSubElemntNumberGroupList(newElementName);

  return defineDifferenceBetweenArraysOfNumberGroups(subElementNumberGroupListForNewElement, subElementNumberGroupListForOldElement);
};

export const isEmpty = (obj) =>
  isNullOrUndefined(obj) || !Object.keys(obj).length;

export const convertToHtmlNewlines = (string) => {
  return reactStringReplace(string, newLines, () => (<span><br/><br/></span>));
};

export const containsAny = (string, substringsArr) => {
  if (!substringsArr || !string) {
    return;
  }

  let contains = false;

  substringsArr.forEach((item) => {
    if (string.indexOf(item) > -1) {
      contains = true;
    }
  });

  return contains;
};

export const getMedicalNameFromDiagnosis = name => (
  name.substring(name.indexOf(' ')).trim()
);

export const shallowEqual = (objA, objB) => {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null ||
    typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  const bHasOwnProperty = hasOwnProperty.bind(objB);
  for (let i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
};

export const isEmbedded = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isMobileApp = () => navigator.userAgent === 'mobile-app';

export const addNewProperty = (propertyName, propertyValue, oldObject) => {
  const newObject = {
    ...oldObject,
  };

  newObject[propertyName] = propertyValue;

  return newObject;
};

export const putTheFirstPositionForSubElement = (subElement, isSocial) => {
  if (isSocial === true) {
    const updatedSubElement = { ...subElement };

    return addNewProperty('position', 1, updatedSubElement);
  }

  return subElement;
};

export const getObjectsIds = arrayOfObjects => arrayOfObjects.map(object => 'id' in object && object.id);

export const deleteElementsFromArrayByIds = (arrayOfObjects, elementsIdsToDelete) => {
  let updatedElements = arrayOfObjects.slice();

  const elementsToDelete = [...elementsIdsToDelete];

  arrayOfObjects.forEach((element) => {
    if (elementsToDelete.includes(element.id)) {
      updatedElements = updatedElements.filter(el => el.id !== element.id);
    }
  });

  return updatedElements;
};

export const deleteElementsFromSessionByIds = (currentSessionState, elementIds) => {
  const nextState = { ...currentSessionState };

  Object.keys(nextState).forEach((systemId) => {
    Object.keys(nextState[systemId]).forEach((index) => {
      const arrayOfElements = [...nextState[systemId][index]];

      nextState[systemId][index] = deleteElementsFromArrayByIds(arrayOfElements, elementIds);
    });
  });

  return nextState;
};

export const isEnoughStringLength = stringName =>
  stringName.trim().length > 0;

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const checkSaveSearching = (query) =>
  query?.length >= 3;
