import React from 'react';
import { createPortal } from 'react-dom';
import { string, shape, func, bool } from 'prop-types';

import PasswordConfirmWithCard from 'components/Modal/PasswordConfiemWithCard';
import Confirm from './Confirm';
import PasswordConfirm from './PasswordConfirm';
import KeywordConfirm from './KeywordConfirm';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import { getTitleColor, MESSAGE_TYPES } from 'helpers/notice/BannerService';

import cx from './Modal.module.scss';

class Modal extends React.Component {
  getCustomModalContent(data) {
    const {
      size,
      children,
      ...restProps
    } = this.props;

    switch (data.type) {
      case 'keyword-confirm':
        return <KeywordConfirm {...restProps} />;

      case 'password-confirm':
        return <PasswordConfirm {...restProps} />;

      case 'password-confirm-with-card':
        return <PasswordConfirmWithCard {...restProps} />;

      case 'confirm':
        return <Confirm {...restProps} />;

      default:
        return null;
    }
  }

  handleOverlayClick = () => {
    const { data, clickOutsideDisabled } = this.props;

    if (typeof data.seatPayment !== 'undefined' || clickOutsideDisabled) return;

    this.props.onModalClose();
  };

  render () {
    const {
      data,
      size,
      children,
      isOpen
    } = this.props;

    const {
      messageType = MESSAGE_TYPES.INFO
    } = data;

    if (!isOpen) {
      return null;
    }

    let contentClass;

    switch (size) {
      case 'large':
        contentClass = 'modal-window-content--large';
        break;
      case 'medium':
        contentClass = 'modal-window-content--medium';
        break;

      default:
        contentClass = 'modal-window-content';
    }

    return createPortal(
        <div className={cssClassesResolver([cx['modal-window'], isOpen ? cx['modal-window--active'] : ''])}>
          <div className={cx['modal-window-container']}>
            <div className={cx['modal-window-overlay']} onClick={this.handleOverlayClick}/>
            <div className={cx[contentClass]}>
                {(children || data) && (
                    <div className={cssClassesResolver([
                      cx['modal-window-item'],
                      cx['modal-window-item--base']
                    ])}>
                      {data.title && (
                          <div
                              className={cx['modal-window-title']}
                              style={{
                                color: getTitleColor(messageType)
                              }}
                          >
                            {data.title}

                            {data.logo && (
                                <img
                                    alt="Header"
                                    src={data.logo}
                                    className={cx['modal-window-logo']}

                                />
                            )}
                          </div>
                      )}
                      <div className={cx['modal-window-data']}>
                        {this.getCustomModalContent(data)}
                        {children}
                      </div>
                    </div>
                )}
            </div>
          </div>
        </div>
    , document.getElementById('root'));
  };
}

Modal.propTypes = {
  data: shape({
    messageType: string,
    type: string,
    title: string,
    content: string,
  }),
  size: string,
  onModalClose: func,
  onModalConfirm: func,
  clickOutsideDisabled: bool,
};

Modal.defaultProps = {
  data: shape({
    messageType: MESSAGE_TYPES.INFO
  })
}

export default Modal;
