import { handleActions } from 'redux-actions';

import * as constants from './constants';
import * as seatConstants from '../seats/constants';

import { isEqualTwoSequencesOfPermissions } from 'helpers/permissions';
import { isNullOrUndefined } from 'helpers';

const initialState = {
  isFetching: undefined,
  isCreating: false,
  isUpdating: false,
  list: [],
  clinicUsers: [],
};

const getUserIndex = (userId, usersList) => (
  usersList.findIndex(user => user.id === userId)
);

const updateUserField = (userId, state, newProps) => {
  const nextList = [...state.list];

  const index = getUserIndex(userId, nextList);

  nextList[index] = {
    ...nextList[index],
    ...newProps,
  };

  return nextList;
};

export default handleActions({
  [constants.USERS_FETCH]: state => ({
    ...state,
    isFetching: true,
  }),
  [constants.USERS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    list: action.payload.result,
  }),
  [constants.USER_FETCH_IMAGE_SUCCESS]: (state, action) => {
    const {
      result,
      userId,
    } = action.payload;

    const nextList = [...state.list];
    const userIndex = getUserIndex(userId, nextList);

    let imageUrl = null;
    if (!isNullOrUndefined(result)) {
      imageUrl = URL.createObjectURL(result);
    }

    nextList[userIndex] = {
      ...nextList[userIndex],
      imageUrl,
    };

    return {
      ...state,
      list: nextList,
    };
  },
  [constants.USERS_FETCH_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),
  [constants.USER_CREATE]: state => ({
    ...state,
    isCreating: true,
  }),
  [constants.USER_CREATE_SUCCESS]: (state, action) => {
    const nextList = [...state.list];

    const newUser = {
      ...action.payload.result,
      ...action.payload.user,
    };

    nextList.push(newUser);

    return {
      ...state,
      isCreating: false,
      list: nextList,
    };
  },
  [constants.USER_CREATE_FAIL]: state => ({
    ...state,
    isCreating: false,
  }),
  [constants.USER_UPDATE]: state => ({
    ...state,
    isUpdating: true,
  }),
  [constants.USER_UPDATE_SUCCESS]: (state, action) => {
    const nextList = [...state.list];

    const index = getUserIndex(action.payload.user.id, nextList);

    nextList[index] = {
      ...nextList[index],
      ...action.payload.user,
    };

    return {
      ...state,
      isUpdating: false,
      list: nextList,
    };
  },
  [constants.USER_REMOVE_FROM_SEAT]: (state, action) => {
    const nextList = [...state.list];

    const index = getUserIndex(action.payload.userId, nextList);

    nextList[index] = {
      ...nextList[index],
      setToSeat: false,
    };

    return {
      ...state,
      isUpdating: false,
      list: nextList,
    };
  },
  [constants.USER_UPDATE_FAIL]: state => ({
    ...state,
    isUpdating: false,
  }),

  [constants.USER_PERMISSIONS_UPDATE]: (state, action) => {
    const { userId } = action.payload;

    const nextList = updateUserField(userId, state, { isUpdating: true });

    return {
      ...state,
      list: nextList,
    };
  },

  [constants.USER_PERMISSIONS_UPDATE_SUCCESS]: (state, action) => {
    const { userId } = action.payload;
    const nextProps = {
      isUpdating: false,
      permissions: action.payload.permissions,
    };

    const nextList = updateUserField(userId, state, nextProps);

    return {
      ...state,
      list: nextList,
    };
  },

  [constants.USER_PERMISSIONS_UPDATE_FAIL]: (state, action) => {
    const { userId } = action.payload;

    const nextList = updateUserField(userId, state, { isUpdating: false });

    return {
      ...state,
      list: nextList,
    };
  },

  [constants.USERS_CLINIC_FETCH]: (state, action) => ({
    ...state,
    clinicUsers: action.payload.clinicUsers,
  }),

  [constants.CLEAR_USER_PERMISSIONS_FOR_REMOVED_ROLE]: (state, action) => {
    const nextUsersList = [...state.list];

    nextUsersList.forEach((user) => {
      const hasUserRemovedRole = isEqualTwoSequencesOfPermissions(user.permissions, action.payload.role.permissions);

      if (hasUserRemovedRole === true) {
        const indexOfUser = nextUsersList.indexOf(user);

        nextUsersList[indexOfUser].permissions = [];
      }
    });

    return {
      ...state,
      list: nextUsersList,
    };
  },

  [seatConstants.SEAT_USER_SET_SUCCESS]: (state, action) => {
    const { userId } = action.payload;
    const nextUsersList = [...state.list];

    nextUsersList.forEach((user) => {
      if (user.id === userId) {
        user.setToSeat = true;
      }
    });

    return {
      ...state,
      list: [...nextUsersList],
    };
  },

  [seatConstants.SEAT_USER_REMOVE_SUCCESS]: (state, action) => {
    const nextUsersList = [...state.list];
    const { prevUserId } = action.payload;

    nextUsersList.forEach((user) => {
      if (user.id === prevUserId) {
        user.setToSeat = false;
      }
    });

    return {
      ...state,
      list: [...nextUsersList],
    };
  },
}, initialState);
