import { useRoute } from 'hooks/useRoute';
import { useSelector } from 'react-redux';
import { getActivePatient } from 'modules/patients/selectors';

export const usePatient = () => {
  const { fromParams, fromSearchParams } = useRoute();
  const chartPatient = useSelector(state => getActivePatient(state.patients));

  return {
    patientId: fromParams('patientId') || fromSearchParams('patientId') || chartPatient?.id
  };
};