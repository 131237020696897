import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as constants from './constants';

import keyBy from 'lodash/keyBy';

const addResources = (state, items) => ({
  ...state,
  ...keyBy(items, 'id'),
});

const reducer = handleActions({
  [constants.RESOURCE_ADD]: (state, action) => ({
    ...state,
    [action.payload.item.id]: action.payload.item,
  }),
  [constants.RESOURCE_REMOVE]: (state, action) => {
    const nextState = { ...state };

    delete nextState[action.payload.itemId];

    return nextState;
  },
  [constants.RESOURCES_ADD]: (state, action) => addResources(state, action.payload.items),
  [constants.RESOURCES_FETCH_SUCCESS]: (state, action) => addResources(state, action.payload.result),
}, {});

const createReducer = name => (state = {}, action = {}) => {
  const payload = action.payload || {};

  if (payload.resourceType !== name) return state;

  return reducer(state, action);
};

export default combineReducers({
  medications: createReducer('medications'),
  diagnoses: createReducer('diagnoses'),
  cpt: createReducer('cpt'),
  hcpc: createReducer('hcpc'),
});
