import { handleActions } from 'redux-actions';
import union from 'lodash/union';
import take from 'lodash/take';
import { PATIENT_ADD_TO_RECENT } from '../constants';

const initialState = [];

export default handleActions({
  [PATIENT_ADD_TO_RECENT]: (state, action) => take(union([action.payload.patientId, ...state]), 3),
}, initialState);
