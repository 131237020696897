import { LINK_TO_ACTIVE_USER, LINK_TO_INACTIVE_USER } from './constants';

export default class ChoiceLinkToNavigationToUser {
  static choose(isActiveUser = true) {
    if (isActiveUser) {
      return LINK_TO_ACTIVE_USER;
    }

    return LINK_TO_INACTIVE_USER;
  }
}
