import React from 'react';
import PropTypes from 'prop-types';

import NoDisplayedCreditCard from 'components/NoDisplayedCreditCard';

const CardInfo = (props) => {
  const {
    isDisplayCard,
    children,
  } = props;

  return isDisplayCard
    ? children
    : <NoDisplayedCreditCard />;
};

CardInfo.propTypes = {
  children: PropTypes.node.isRequired,
  isDisplayCard: PropTypes.bool.isRequired,
};

export default CardInfo;
