export default class HcpcResourceMapper {
  constructor(hcpcResource) {
    this._hcpcResource = hcpcResource;
  }

  basicFormat = () => ({
    id: this._hcpcResource.getId(),
    hcpcMod: this._hcpcResource.getHcpcMod(),
    description: this._hcpcResource.getDescription(),
    favoriteId: this._hcpcResource.getFavoriteId(),
    name: this._hcpcResource.getFullName(),
    ids: this._hcpcResource.getIds()
  })

  fetchingFavoritesFormat = () => this._hcpcResource?.getFavoriteId() || this._hcpcResource?.getId()
}
