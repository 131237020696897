import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';

import InfoPopup from 'components/InfoPopup';
import Loader from 'components/Loader';

import { isMobileApp } from 'helpers';
import BackgroundImageStyle from 'helpers/images/styles/background-image';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import isEmpty from 'helpers/common/array/is-empty';
import { disabled } from 'helpers/propsGenerator';
import { isImageTypeRejected, getFileType } from 'helpers/FileUtils';

import { uploadPatientPhoto } from 'api/patients';

import cx from './style.module.scss';

const bytesToMb = bytes => bytes / 1024 /1024;

// file size limit in mb
const FILE_SIZE_LIMIT_BYTES = 3145728;
const FILE_SIZE_LIMIT_MB = bytesToMb(FILE_SIZE_LIMIT_BYTES);

const UserImageLoader = (props) => {
  const {
    entityIdentifier,
    imageUrl,
    label,
    type,
    disabled,
    className,
    defaultImage,
    backgroundSize,
    wrapperClass,
    isDownloadingImage,
    onChange,
  } = props;

  const [uploadedImage, setUploadedImage] = useState(undefined);
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [rotation, setRotation] = useState(undefined);

  const resetImage = useCallback(() => {
    setRotation(undefined);
    const imageUrlOrNull = imageUrl || null;
    setUploadedImage(imageUrlOrNull);
  }, [imageUrl]);

  useEffect(() => {
    resetImage();
  }, [entityIdentifier, resetImage]);

  useEffect(() => {
    setUploadedImage(imageUrl);
  }, [imageUrl]);

  const onDrop = useCallback(acceptedFiles => {
    const isPresentAcceptedFile = !isEmpty(acceptedFiles);
    if (isPresentAcceptedFile) {
      const acceptedFile = acceptedFiles[0];

      if (acceptedFile.size > FILE_SIZE_LIMIT_BYTES) {
        setUploadError({ message: `File size exceeds ${FILE_SIZE_LIMIT_MB}mb limit` });
        return;
      }

      const imageType = getFileType(acceptedFile.name);

      if (isImageTypeRejected(imageType)) {
        setUploadError({ message: 'This image type is not supported', });
        resetImage();

        return;
      }

      setIsUploading(true);

      uploadPatientPhoto(acceptedFile, 0)
        .then((res) => {
          const buildImageUrl = URL.createObjectURL(acceptedFile);
          setUploadedImage(buildImageUrl);
          onChange({ imageUrl: buildImageUrl, imageName: res.body.name });

          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(false);
          setUploadError({ message: 'Something went wrong', });
          resetImage();
        });
    }
  }, [onChange, resetImage]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    multiple: false,
    accept: 'image/*',
    disabled: disabled || isUploading,
  });

  const isDisplayLoader = isUploading || isDownloadingImage;

  return (
    <div className={
      cssClassesResolver([
        className,
        cx[wrapperClass] || cx.wrapper
      ])
    }>
      {label && (
        <span className={cx.label}>
          {label}
        </span>
      )}

      <div
        className={cx.avatar}
      >
        <div
          className={cx[type === 'signature' ? 'signature-image' : 'avatar-image']}
          style={{
            ...BackgroundImageStyle.define(uploadedImage, defaultImage),
            transform: rotation && `rotate(${rotation}deg)`,
            backgroundColor: !uploadedImage && 'initial',
            backgroundSize,
          }}
        />
        {isDisplayLoader && (
          <div className={cx['loader-wrapper']}>
            <Loader />
          </div>
        )}
      </div>
      {!disabled && !isMobileApp() && (
        <>
        <div
          className={cx.controls}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <button
            type="button"
            className={cx['file-button']}
          >
            Add from file
          </button>
          {uploadError && (
            <div className={cx.error}>
              {uploadError.message}
            </div>
          )}
        </div>
        <div className={cx['info-popup-container']}>
          <InfoPopup small>
            Image file size should be less than {FILE_SIZE_LIMIT_MB}mb.
          </InfoPopup>
        </div>
        </>
      )}
    </div>
  );
};

UserImageLoader.propTypes = {
  onChange: PropTypes.func.isRequired,
  isDownloadingImage: PropTypes.bool.isRequired,
  backgroundSize: PropTypes.string,
  imageUrl: PropTypes.string,
  defaultImage: PropTypes.string.isRequired,
  disabled:  disabled.obj,
};

UserImageLoader.defaultProps = {
  backgroundSize: '100%',
  imageUrl: null,
  disabled: disabled.default,
};

export default UserImageLoader;
