import React from 'react';
import PropTypes from 'prop-types';

import GreenCheckbox from 'components/GreenCheckbox';

export default class AdditionalEmptyMessageCheckbox extends React.Component {
  componentDidMount() {
    this.resetCheckbox(this.props);
  }


  componentDidUpdate = () => {
    this.resetCheckbox(this.props);
  };

  resetCheckbox = (props) => {
    const {
      isPresentItems,
      checkboxValue,
      isDrChronoAttached,
    } = props;

    const isChangedCheckboxValue = isPresentItems && checkboxValue;

    if (isChangedCheckboxValue) {
      const {
        toggleCheckbox,
        patientId,
      } = props;

      toggleCheckbox(patientId, false, isDrChronoAttached);
    }
  };

  render() {
    const {
      checkboxValue,
      patientId,
      toggleCheckbox,
      label,
      isPresentItems,
      isDrChronoAttached,
    } = this.props;

    return (
      <GreenCheckbox
        value={!!checkboxValue}
        onChange={value => toggleCheckbox(patientId, value, isDrChronoAttached)}
        isDisabledCheckbox={isPresentItems}
      >
        {label}
      </GreenCheckbox>
    );
  }
}

AdditionalEmptyMessageCheckbox.propTypes = {
  isPresentItems: PropTypes.bool.isRequired,
  checkboxValue: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  isDrChronoAttached: PropTypes.bool.isRequired,
};
