import React from 'react';
import { compose } from 'redux';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';

import allergiesContainer, { withAllergiesFetchLayer } from 'containers/Allergies/AllergiesContainer';
import AllergiesPage from 'containers/Allergies/AllergiesPage';

const AllergiesContainer = compose(
  allergiesContainer,
  withAllergiesFetchLayer
)(AllergiesPage);

export const Allergies = () => {
  const [patientId] = useOutletContext();
  const [searchParams] = useSearchParams();
  return (
    <AllergiesContainer
      filter="active"
      patientId={patientId}
      allergyId={searchParams.get('allergy')}
      linkBase={`/app/doctor/patients/${patientId}/allergies`}
    >
      <Outlet/>
    </AllergiesContainer>
  );
};
