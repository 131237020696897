import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardInfo from 'components/CardInfo';

import * as cardHelpers from 'helpers/card/card';

import cx from './Modal.module.scss';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

const inputPasswordConfirm = ({ passwordError }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [ passwordError ? 'text-confirm-input--error' : 'text-confirm-input']
})

class PasswordConfirm extends Component {
  static propTypes = {
    data: PropTypes.shape({
      shouldRenderCard: PropTypes.bool,
    }),
    onModalConfirm: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {
      shouldRenderCard: false,
    },
  };

  resetModal() {
    this.setState({
      password: '',
    });

    this.passwordInput.value = '';
  }

  handleModalConfirm = () => {
    this.props.onModalConfirm({ ...this.state });

    this.resetModal();
  };

  handleModalClose = () => {
    this.props.onModalClose();

    this.resetModal();
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  }

  createMarkup(content) {
    return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    );
  }

  render() {
    const {
      cancelDisabled,
      openBillingInfoModal,
      data,
    } = this.props;

    const {
      cardData,
      passwordError,
      purchaseError,
      processingPurchase,
      content,
      shouldRenderCard,
    } = data;

    const isDisplayCard = cardHelpers.isCardPresent((cardData));

    return (
      <div>
        <div className={cx['content-container']}>
          {this.createMarkup(content)}
        </div>

        {shouldRenderCard && (
          <span>
            <CardInfo
              isDisplayCard={isDisplayCard}
            >
              <span>
                <span>
                  <strong>{`${cardData.cardBrand} ...${cardData.last4}`}</strong>
                </span>
              </span>
            </CardInfo>
            <button className={cx['edit-card']} onClick={openBillingInfoModal} />
          </span>
        )}

        <div className={cx['modal-window-controls']}>
          <div className={cx['text-confirm-input-wrapper']}>
            <div>
              <input
                ref={(ref) => { this.passwordInput = ref; }}
                style={{ padding: '2px 14px' }}
                type="password"
                className={inputPasswordConfirm({ passwordError })}
                onChange={this.handlePasswordChange}
              />
              {passwordError && <span className={cx['error-text']}>Invalid password</span>}
              {purchaseError && <span className={cx['error-text']}>{ purchaseError }</span>}
            </div>
          </div>
          {!cancelDisabled &&
            <button type="button" className={cx.button} onClick={this.handleModalClose} disabled={processingPurchase}>
              Cancel
            </button>
          }
          <button type="button" className={cx.button} onClick={this.handleModalConfirm} disabled={processingPurchase}>
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

export default PasswordConfirm;
