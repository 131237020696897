const request = require('superagent-promise')(require('superagent'), Promise);

export const saveBoxCode = data =>
  request.post('/api/rest/boxcom/save')
    .send(data)
    .end();

export const fetchBoxClientId = () =>
  request.get('/api/rest/boxcom/')
    .end();

export const removeBoxCode = () =>
  request.post('/api/rest/boxcom/disconnect')
    .end();
