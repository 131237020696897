import React, { useRef, useState } from 'react';

import Tether from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import Tooltip from '../../../../ChartReportElementWithTooltip/Tooltip';
import RemoveButton from '../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../ChartReportElementWithTooltip/DoorButton';

const MENU_BUTTON_DIAMETER = 40;

const ChartReportAssessmentElement = (props) => {
  const elementTooltipWrapperRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(prev => !prev);

  const dropdownRef = useClickOutSide({ onOutsideClick: toggleMenu, exclude: [elementTooltipWrapperRef] });

  const renderRow = ({ ref, code, name, clickHandler }) => {
    elementTooltipWrapperRef.current = ref.current;
    return (
      <tr
        ref={ref}
        onClick={clickHandler}
      >
        <td>ICD-10</td>
        <td>{code}</td>
        <td>{name}</td>
      </tr>
    );
  };

  const { element, toggleChartReport, onRemove, chartId, step, viewMode } = props;

  const firstSpaceIndex = element.name.indexOf(' ');

  const code = element.name.substring(0, firstSpaceIndex);
  const name = element.name.substring(firstSpaceIndex + 1, element.name.length);

  if (viewMode) {
    return renderRow({ code, name });
  }

  return (
    <Tether
      attachment="bottom center"
      targetAttachment="top center"
      constraints={[{
        to: 'scrollParent',
        attachment: 'together',
      }]}
      classPrefix="reportElement"
      renderTarget={targetRef => (
        renderRow({ ref: targetRef, code, name, clickHandler: toggleMenu })
      )}
      renderElement={elementRef => isOpen && (
        <span ref={dropdownRef}>
          <Tooltip ref={elementRef}>
            <DoorButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
                marginRight: 10,
              }}
              onClick={() => {
                toggleMenu();
                toggleChartReport();
              }}
              link={`/app/doctor/charts/${chartId}?step=${step}&system=${element.systemId}`}
            />
            <RemoveButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
              }}
              onClick={onRemove}
            />
          </Tooltip>
        </span>
      )}
    />
  );
};

export default ChartReportAssessmentElement;
