import colors from 'styles/colors.scss';

export const ProgressBarListSizes = {
  middle: 'middle',
  small: 'small',
};

export const ProgressBarStyles = {
  initial: {
    fill: 'transparent',
    color: 'initial',
  },
  filledCircle: {
    fill: colors.basicGreen,
    color: 'white',
  },
};
