import * as api from 'api/permissions';
import * as constants from './constants';

export const fetchPermissions = () => ({
  types: [
    constants.PERMISSIONS_FETCH,
    constants.PERMISSIONS_FETCH_SUCCESS,
    constants.PERMISSIONS_FETCH_FAIL,
  ],
  promise: api.fetchPermissions,
});
