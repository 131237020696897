import { useContext, useEffect } from 'react';
import { func, shape, number } from 'prop-types';
import { compose } from 'redux';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';

import SockJsClientContainer from 'containers/SockJsClientContainer';

import StompService, { buildSubscriptionUserUrl, ACTIONS } from 'helpers/notice/StompService';
import { isNullOrUndefined } from 'helpers';

const SockJsClient = (props) => {
  const {
    showBanner,
    user
  } = props;

  const {
    setInfoModalData
  } = useContext(AppContext);

  useEffect(() => {
    const stompClientCopy = Stomp.over(new SockJS('/api/notifications'), { protocols: ['v12.stomp'] });

    const successCallback = () => {
      const resolveShowMessageFunc = ({ outputType }) =>
        outputType === 'window' ? setInfoModalData : showBanner;

      const handleMessageByType = messageOutput => {
        const { message, type, outputType } = JSON.parse(messageOutput.body);
        const callback = StompService({ eventType: type });

        if (isNullOrUndefined(callback)) {
          return;
        }

        callback({
          message,
          showModal: resolveShowMessageFunc({
            outputType,
          })
        });

        messageOutput.ack();
      };

      const handleUserMessage = messageOutput => {
        const { message, title, outputType } = JSON.parse(messageOutput.body);

        const callback = StompService({
          eventType: ACTIONS.USER_MESSAGE,
        });

        if (isNullOrUndefined(callback)) {
          return;
        }

        callback({
          title,
          message,
          showModal: resolveShowMessageFunc({
            outputType,
          })
        });

        messageOutput.ack();
      };

      stompClientCopy.subscribe('/type', handleMessageByType);

      stompClientCopy.subscribe(
        buildSubscriptionUserUrl({ url: '/specific', ...user }),
        handleUserMessage
      );
    };

    const connect = () => {
      stompClientCopy.connect({}, successCallback, () => {});
    };

    connect();
    
    return () => {
      if (stompClientCopy !== null) {
        stompClientCopy.disconnect();
      }

    };

  }, []);

  return null;
};

SockJsClient.propTypes = {
  showBanner: func.isRequired,
  user: shape({
    userId: number,
    clinicId: number
  }).isRequired
};

const SockJsClientWrapper = compose(
  SockJsClientContainer
)(SockJsClient);

export default SockJsClientWrapper;
