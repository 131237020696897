export const PERMISSIONS_FOR_PROVIDER = [
  'SIGN_ENCOUNTER_NOTE',
];

export const PROVIDERS_DROPDOWN_EXTRA_FIELDS = {
  allProviders: 'All Providers',
};

export const ALL_PROVIDERS_ITEM = {
  value: 0,
  label: PROVIDERS_DROPDOWN_EXTRA_FIELDS.allProviders,
};
