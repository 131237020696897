import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChartReportElementWithTooltip from '../../../../../ChartReportElementWithTooltip';
import RemoveButton from '../../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../../ChartReportElementWithTooltip/DoorButton';

import * as familyHelpers from 'helpers/fmss/family/family';

const MENU_BUTTON_DIAMETER = 40;

class ChartReportFamilyItem extends Component {
  static propTypes = {
    familyItem: PropTypes.shape({
      id: PropTypes.number.isRequired,
      familyPerson: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      illnesses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
    onRemove: PropTypes.func.isRequired,
    chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    toggleChartReport: PropTypes.func.isRequired,
    viewMode: PropTypes.bool,
  };

  static defaultProps = {
    viewMode: undefined,
  };

  state = {
    isMenuOpen: false,
  };

  openMenu = () => this.setState({ isMenuOpen: true });

  closeMenu = () => this.setState({ isMenuOpen: false });

  render() {
    const {
      familyItem,
      onRemove,
      chartId,
      toggleChartReport,
      viewMode,
    } = this.props;

    const { isMenuOpen } = this.state;
    const { closeMenu, openMenu } = this;

    let illnessesList = [...familyItem.illnesses];

    illnessesList = familyHelpers.sortIllnesses(illnessesList);

    return (
      <div>
        <ChartReportElementWithTooltip
          isOpen={isMenuOpen}
          onOpen={openMenu}
          onClose={closeMenu}
          disabled={viewMode}
        >
          <span>
            <span className="text-bold">{familyItem.familyPerson.name}:</span>
            {' '}
            {illnessesList.map((illness, i) => (
              <span key={illness.id}>
                {illness.name}
                {i !== familyItem.illnesses.length - 1 && ', '}
              </span>
            ))}
          </span>

          <div className="flex-container">
            <DoorButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
                marginRight: 10,
              }}
              link={{ pathname: `/app/doctor/charts/${chartId}/fmss`, query: { family: familyItem.id } }}
              onClick={() => {
                closeMenu();
                toggleChartReport();
              }}
            />
            <RemoveButton
              onClick={onRemove}
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
              }}
            />
          </div>
        </ChartReportElementWithTooltip>
      </div>
    );
  }
}

export default ChartReportFamilyItem;
