import React from 'react';
import { node } from 'prop-types';

import cx from 'components/Paper/Paper.module.scss';

const Paper = ({
  children,
}) => (
  <div className={cx.wrapper}>
    <div className={cx.inner}>
      {children}
    </div>
  </div>
);

Paper.propTypes = {
  children: node.isRequired,
};

export default Paper;
