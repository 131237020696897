const resetFavoritesImage = require('images/remove.png');
const removeElementImage = require('images/trashbinBlue.png');
const mergeElementsImage = require('images/merge.png');

export const elementsViewItemActionsModalData = {
  resetFavorite: {
    id: 'resetFavorite',
    title: 'Remove Recommendation/Favorite',
    content: 'You are about to remove this element from being recommended in this visit type scenario. This cannot be undone! Please make sure  that you have an understanding of this advanced user option before proceeding.',
    logo: resetFavoritesImage,
  },
  removeElement: {
    id: 'removeElement',
    title: 'Delete an Element',
    content: 'You are about to permanently delete this element from EHRsynergy! This cannot be undone! Please make sure  that you have an understanding of this advanced user option before proceeding.',
    logo: removeElementImage,
  },
  mergeElements: {
    id: 'mergeElements',
    title: 'Merge Two Elements',
    content: 'You are about to merge multiple elements into one. This cannot be undone! Please make sure  that you have an understanding of this advanced user option before proceeding.',
    logo: mergeElementsImage,
  },
};
