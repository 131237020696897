import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { DATE_FORMATS } from 'helpers/date/constants';
import BackgroundImageStyle from 'helpers/images/styles/background-image';
import resolveStyles from 'helpers/common/styles/resolveStyles';

import cx from './Patient.module.scss';

const PatientPlate = props => {
  const {
    patient,
    active,
    onClick,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const formattedDate = timeZoneDateConverter.getFormattedDateWithTimeZone(patient.dob, DATE_FORMATS.DEFAULT);

  return (
    <div
      className={resolveStyles([active ? cx['result-item-active'] : cx['result-item']])}
      onClick={() => onClick(patient.id)}
    >
      <span
        className={cx.image}
        style={{
          ...BackgroundImageStyle.define(patient.imageUrl),
        }}
      />
      <div className={cx.info}>
        <p className={cx.fullname}>
          {`${patient.firstName} ${patient.lastName}`}
        </p>
        {patient.dob && (
          <p className={cx.dob}>
            dob: {formattedDate}
          </p>
        )}
      </div>
    </div>
  );
};

PatientPlate.propTypes = {
  patient: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PatientPlate;
