import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';

import { pdfjs } from 'react-pdf';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Datepicker from 'components/Datepicker';
import EditForm from 'components/EditForm';
import EditFormActions from 'components/EditForm/Actions';
import TextInput from 'components/TextInput';

import { buildSchema } from 'helpers/validationRules';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { addConsent } from 'modules/consent';
import { createConsent, uploadTemplate } from 'api/consent';

import cx from 'routes/routes/App/routes/Settings/routes/General/components/Consent/ConsentForm.module.scss';
import tcx from 'components/TextInput/TextInput.module.scss';
import icx from 'components/UserImageLoader/style.module.scss';

const textInputStyles = resolveStylesV2({
  objectStyles: tcx,
  moduleStyles: 'title',
  globalStyles: 'section-title'
});

pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.mjs';

export const ConsentForm = ({ confirm, reset }) => {
  const initialValues = {
    name: '',
    date: '',
    templateName: '',
    templateServerName: ''
  };
  const {
    timeZoneDateConverter
  } = useContext(AppContext);

  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      ...initialValues
    },
    validationSchema: buildSchema({
      name: 'required',
      templateName: 'required'
    }),
    onSubmit: async (values, { resetForm }) => {
      const requestInput = {
        name: values.name,
        date: values.date,
        fileName: values.templateServerName
      };
      const response = await createConsent(requestInput);
      const id = await response.json();
      dispatch(addConsent({ id, ...requestInput }));
      resetForm();
      confirm();
    }
  });
  const onDropHandler = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    const { text: newTemplateName } = await uploadTemplate(formData);
    form.setFieldValue('templateName', file.name);
    form.setFieldValue('templateServerName', newTemplateName);
    form.setFieldValue('date', timeZoneDateConverter.getCurrentTimeZoneDateMs());
    form.setFieldValue('name', file.name);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
    noDrag: true,
    multiple: false,
    accept: 'application/pdf',
  });

  return (
    <form
      onSubmit={form.handleSubmit}
    >
      <EditForm responsiveHeight>
        {/* <EditFormTitle title={consentId ? 'Edit consent' : 'Add consent'} /> */}
        <div className={cx.row}>
          <TextInput
            value={form.values.name}
            onChange={e => form.setFieldValue('name', e.target.value)}
            type='text'
            name='name'
            title='Name'
            placeholder='Name'
          />
          {form?.errors.name && (
            <span className="validation-error">{form.errors.name}</span>
          )}
        </div>

        <div className={cx.row}>
          <h3 className={textInputStyles}>Date added</h3>
          <Datepicker
            value={form.values.date}
            onChange={date => form.setFieldValue('date', date)}
          />
          {form?.errors.date && (
            <span className="validation-error">{form.errors.date}</span>
          )}
        </div>

        <div className={`${cx.row} ${cx['row__last']}`} {...getRootProps()}>
          <input {...getInputProps()} />
          <h3 className={textInputStyles}>Upload template</h3>
          <div className={cx['upload-wrapper']}>
            <button
              className={icx['file-button']}
              type="button"
            />
            {/* <span>{form.values.templateName}</span> */}
          </div>
          {form?.errors.templateName && (
            <span className="validation-error">{form.errors.name}</span>
          )}
        </div>

        <EditFormActions
          onCancel={reset}
          submitButton
          cancelButton
        />
      </EditForm>
    </form>
  );
};