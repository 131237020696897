import EmptyMessageConditionAbstractFactory from 'helpers/fmss/additional-empty-message/factory/condition-abstract-factory';
import EmptyMessageConstantFactory from 'helpers/fmss/additional-empty-message/factory/empty-message-constant-factory';
import EmptyMessageAbstractFactory from 'helpers/fmss/additional-empty-message/factory/empty-message-abstract-factory';

export default class ProducerEmptyMessageFactory {
  static getMessage(fmssType, conditionalData) {
    const emptyMessageConditionAbstractFactory = EmptyMessageConditionAbstractFactory.getFactory(fmssType);

    const conditionalValue = emptyMessageConditionAbstractFactory.isPresent(conditionalData);

    const emptyMessageConstant = EmptyMessageConstantFactory.getConstant(fmssType);

    const emptyMessageAbstractFactoryService = EmptyMessageAbstractFactory.getFactory(fmssType);

    return emptyMessageAbstractFactoryService.getMessage(conditionalValue, emptyMessageConstant);
  }
}
