export const CPT_CODES_LIST = [
  { id: 2, cptCode: 99212, description: 'Office outpatient visit 10 minutes' },
  { id: 3, cptCode: 99213, description: 'Office outpatient visit 15 minutes' },
  { id: 4, cptCode: 99214, description: 'Office outpatient visit 25 minutes' },
  { id: 5, cptCode: 99215, description: 'Office outpatient visit 40 minutes' },
  { id: 6, cptCode: 99202, description: 'Office outpatient new 20 minutes' },
  { id: 7, cptCode: 99203, description: 'Office outpatient new 30 minutes' },
  { id: 8, cptCode: 99204, description: 'Office outpatient new 45 minutes' },
  { id: 9, cptCode: 99205, description: 'Office outpatient new 60 minutes' },
  { id: 10, cptCode: 99241, description: 'Office/outpatient consultation' },
  { id: 11, cptCode: 99242, description: 'Office/outpatient consultation' },
  { id: 12, cptCode: 99243, description: 'Office/outpatient consultation' },
  { id: 13, cptCode: 99244, description: 'Office/outpatient consultation' },
  { id: 14, cptCode: 99245, description: 'Office/outpatient consultation' },
  { id: 15, cptCode: 99304, description: 'Initial nursing facility care/day 25 minutes' },
  { id: 16, cptCode: 99305, description: 'Initial nursing facility care/day 35 minutes' },
  { id: 17, cptCode: 99306, description: 'Initial nursing facility care/day 45 minutes' },
  { id: 18, cptCode: 99307, description: 'Sbsq nursing facility care/day e/m stable 10 min' },
  { id: 19, cptCode: 99308, description: 'Sbsq nursing facil care/day minor complj 15 min' },
  { id: 20, cptCode: 99309, description: 'Sbsq nursing facil care/day new problem 25 min' },
  { id: 21, cptCode: 99310, description: 'Sbsq nurs facil care/day unstabl/new prob 35 min' },
  { id: 22, cptCode: 99324, description: 'Domicil/rest home new pt visit low sever 20 min' },
  { id: 23, cptCode: 99325, description: 'Domicil/rest home new pt visit mod sever 30 min' },
  { id: 24, cptCode: 99326, description: 'Domicil/rest home new pt hi-mod sever 45 minutes' },
  { id: 25, cptCode: 99327, description: 'Domicil/rest home new pt visit hi sever 60 min' },
  { id: 26, cptCode: 99328, description: 'Dom/r-home e/m new pt signif new prob 75 minutes' },
  { id: 27, cptCode: 99334, description: 'Dom/r-home e/m est pt self-lmtd/minor 15 minutes' },
  { id: 28, cptCode: 99335, description: 'Dom/r-home e/m est pt lw mod severity 25 minutes' },
  { id: 29, cptCode: 99336, description: 'Dom/r-home e/m est pt mod hi severity 40 minutes' },
  { id: 30, cptCode: 99337, description: 'Dom/r-home e/m est pt signif new prob 60 minutes' },
  { id: 31, cptCode: 99341, description: 'Home visit new patient low severity 20 minutes' },
  { id: 32, cptCode: 99342, description: 'Home visit new patient mod severity 30 minutes' },
  { id: 33, cptCode: 99343, description: 'Home vst new patient mod-hi severity 45 minutes' },
  { id: 34, cptCode: 99344, description: 'Home visit new patient hi severity 60 minutes' },
  { id: 35, cptCode: 99345, description: 'Home visit new pt unstabl/signif new prob 75 min' },
  { id: 36, cptCode: 99347, description: 'Home visit est pt self limited/minor 15 minutes' },
  { id: 37, cptCode: 99348, description: 'Home visit est pt low-mod severity 25 minutes' },
  { id: 38, cptCode: 99349, description: 'Home visit est pt mod-hi severity 40 minutes' },
  { id: 39, cptCode: 99350, description: 'Home vst est pt unstable/signif new prob 60 mins' }];

export const SELECT_INPUT_CPT_FORMAT = { label: 'cptCode', value: 'id', description: 'description' };
