import isPlainObject from 'lodash/isPlainObject';

const validateObject = (entity) => {
  let result = true;

  if (!isPlainObject(entity)) {
    console.error('Argument should be an object.');

    result = false;
  }

  return result;
};

export const formatOption = (entity, format = { label: 'name', value: 'id' }) => {
  if (!validateObject(entity)) return;

  return {
    label: entity[format.label],
    value: entity[format.value],
  };
};

export const formatMedOption = (option) => ({
  name: option?.name,
  id: option?.id
})

export const formatOptions = (entities, format = { label: 'name', value: 'id' }) => {
  if (!validateObject(entities)) return;

  const entityIds = Object.keys(entities) || [];

  return entityIds.map(id => (
    {
      label: entities[id][format.label],
      value: entities[id][format.value],
      disabled: false,
    }
  ));
};

export const formatArrayItems = (listItems, format = { label: 'name', value: 'id' }) =>
  listItems.map((item) => {
    return Object.keys(format).reduce((previousValue, currentProp) => {
      return {
        ...previousValue,
        [currentProp]: item[format[currentProp]],
      };
    }, {});
  });
