import { useDispatch, useSelector } from 'react-redux';

import { acceptAgreement } from 'modules/user/info/actions';
import { isAgreementAccepted } from 'modules/user/selectors';
import { useImpersonate } from 'hooks/useImpersonate';

export const useAgreement = () => {
  const dispatch = useDispatch();
  const isAgreementAcceptedSelector = useSelector(isAgreementAccepted);
  const { logout } = useImpersonate();

  return {
    accept: () => dispatch(acceptAgreement()),
    decline: logout,
    isAccepted: isAgreementAcceptedSelector
  };
};