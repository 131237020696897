import React from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import diagnosesContainer, { withDiagnosesFetchLayer } from 'containers/Diagnoses/DiagnosesContainer';
import { DiagnosesList } from 'containers/Diagnoses/DiagnosesList';

const DiagnosesComponent = compose(
  diagnosesContainer,
  withDiagnosesFetchLayer
)(DiagnosesList);

export const DiagnosesWrapper = () => {
  const { filter, patientId } = useParams();
  return (
    <DiagnosesComponent
      patientId={patientId}
      filter={filter === 'inactive' ? 'inactive' : 'active'}
    />
  );
};
