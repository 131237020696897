import * as authApi from 'api/auth';
import * as userApi from 'api/user';
import { sso } from 'api/drchrono';

import * as constants from './constants';

import { fetchUser } from '../actions';
import { setRegistrationStep } from 'modules/registrationStep';
import * as userSelectors from 'modules/user/selectors';

export const login = userdata => ({
  types: [
    constants.USER_LOGIN,
    constants.USER_LOGIN_SUCCESS,
    constants.USER_LOGIN_FAIL,
  ],
  promise: async (dispatch) => {
    try {
      await authApi.login(userdata);

      dispatch(fetchUser());
    } catch (e) {
      return Promise.reject(e);
    }
  },
});

export const authGetHeally = query => ({
  types: [
    constants.GETHEALLY_LOGIN,
    constants.GETHEALLY_LOGIN_SUCCESS,
    constants.GETHEALLY_LOGIN_FAIL,
  ],
  promise: async (dispatch) => {
    try {
      await authApi.authGetHeally(query);
      await dispatch(fetchUser());

      // dispatch(push(`/app/doctor/charts/${query.chartId}`));
    } catch (e) {
      return Promise.reject(e);
    }
  },
});

export const setClinic = (clinicId) => ({
  types: [
    constants.SET_USER_CLINIC,
    constants.SET_USER_CLINIC_SUCCESS,
    constants.SET_USER_CLINIC_FAIL,
  ],
  promise: async (dispatch) => {
    await userApi.setUserClinic(clinicId);
    await dispatch(fetchUser());
  },
});

export const logout = () => ({
  types: [
    constants.USER_LOGOUT,
    constants.USER_LOGOUT_SUCCESS,
    constants.USER_LOGOUT_FAIL,
  ],
  promise: () => authApi.logout()
});

export const signup = userdata => ({
  types: [
    constants.USER_SIGNUP,
    constants.USER_SIGNUP_SUCCESS,
    constants.USER_SIGNUP_FAIL,
  ],
  promise: dispatch => authApi.signup(userdata)
    .then(
      (res) => {
        return res;
      },
      (err) => {
        dispatch(setRegistrationStep(0));
        return Promise.reject(err);
      }
    ),
});

export const confirmEmail = hash => async (dispatch, getState) => {
  const isAuthenticated = userSelectors.getIsAuthenticated(getState().user);

  if (isAuthenticated) {
    await dispatch(logout());
  }

  return dispatch({
    types: [
      constants.USER_EMAIL_CONFIRM,
      constants.USER_EMAIL_CONFIRM_SUCCESS,
      constants.USER_EMAIL_CONFIRM_FAIL,
    ],
    promise: nextDispatch => authApi.confirmEmail(hash)
      .then(
        (res) => {
          // nextDispatch(push('/app/login'));
          return res;
        },
        (err) => {
          // nextDispatch(push('/signup'));
          return Promise.reject(err);
        }
      ),
  });
};

export const validatePasswordRecoveryToken = token => ({
  types: [
    constants.PASSWORD_RECOVERY_TOKEN_VALIDATE,
    constants.PASSWORD_RECOVERY_TOKEN_VALIDATE_SUCCESS,
    constants.PASSWORD_RECOVERY_TOKEN_VALIDATE_FAIL,
  ],
  promise: () => authApi.validatePasswordRecoveryToken(token)
});

export const recoverPassword = userdata => ({
  types: [
    constants.USER_PASSWORD_RECOVER,
    constants.USER_PASSWORD_RECOVER_SUCCESS,
    constants.USER_PASSWORD_RECOVER_FAIL,
  ],
  promise: () => authApi.recoverPassword(userdata)
});

export const drChronoSSO = authorizationCode => ({
  types: [
    constants.USER_LOGIN,
    constants.USER_LOGIN_SUCCESS,
    constants.USER_LOGIN_FAIL,
  ],
  promise: () => sso({ authorizationCode }),
});

export const loginWithDrChrono = ({ api }) => ({
  types: [
    constants.USER_LOGIN,
    constants.USER_LOGIN_SUCCESS,
    constants.USER_LOGIN_FAIL,
  ],
  promise: async () => api(),
});
