import React from 'react';
import cx from './Logo.module.scss';

import image2x from './images/EHR-Logo-Name.png';

const Logo = () => (
  <div className={cx['wrapper']}>
    <img
      src={image2x}
      alt="logo"
      className={cx['image']}

      // BUG: when you sign in and log out in ie10
      // width and height appears, so we should explicitly set it
      width=""
      height=""
    />
  </div>
);

export default Logo;
