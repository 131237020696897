import stringParser from 'helpers/common/string/string-parser';

export default class FormatSubElementsByPositions {
  static format = (subElements = []) => {
    const allPositions = subElements.map((subElement) => {
      const { position } = subElement;

      return (position && position) || 1;
    });

    const uniquePositions = Array.from(new Set(allPositions));

    const formattedSubelements = new Map();

    uniquePositions.forEach((position) => {
      const subElementsWithPosition = subElements.filter(subElement => subElement.position === position);

      if (subElementsWithPosition.length) {
        const subElement = subElementsWithPosition[0];
        const { id } = subElement;
        formattedSubelements.set(position, stringParser(id));
      }
    });

    return formattedSubelements;
  }
}
