import React from 'react';
import PropTypes from 'prop-types';

import cx from './ChartReportLink.module.scss';

const styles = className => `${cx.wrapper} ${className}`

const ChartReportLink = ({
  onClick,
  className = '',
  children = undefined,
}) => (
  <div className={styles(className)} onClick={onClick}>
    <span>
      {children || 'Encounter Note Toggle'}
    </span>
    <i className={cx.icon} />
  </div>
);

ChartReportLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ChartReportLink;
