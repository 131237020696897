import { handleActions } from 'redux-actions';
import createPatientReducer from './byFilter';
import { createReducerWithSingleHandler, actionsToHandle } from './helpers';

const initialState = {};

const handler = (state, action) => ({
  ...state,
  [action.payload.patientId]: createPatientReducer(action.payload.patientId)(state[action.payload.patientId], action),
});

// for action to get to this reducer type has to be included in passThroughActions
// and it must have patientId in payload
export default (state = initialState, action) => {
  if (!actionsToHandle.includes(action.type) || !action.payload.patientId) return state;
  return handleActions(createReducerWithSingleHandler(handler), initialState)(state, action);
};
