import React, { useContext } from 'react';
import { func } from 'prop-types';

import printJS from 'vendor/print';

import { AppContext } from '../../containers/App/AppContext/AppContextProvider';

import Sign from 'components/ChartActionsButtons/Sign';

import { isMobileApp } from 'helpers';

import cx from './ChartActionsButtons.module.scss';

const ChartActionsButtons = (props) => {
  const handleShareClick = (pdfUrl) => {
    try {
      window.webkit.messageHandlers.PDFShare.postMessage(pdfUrl);
    } catch (err) {
      console.log('Can not reach native code');
    }
  };

  const {
    onChangeSignedStatus,
    signed,
    hideSignButton,
    chartId,
  } = props;

  const { permissions: { signEncounterNote } } = useContext(AppContext);

  const pdfShowUrl = `/api/rest/pdf/show/chart/${chartId}`;

  return (
    <div>
      {!hideSignButton && signEncounterNote && (
        <Sign
          signed={signed}
          onChangeSignedStatus={onChangeSignedStatus}
        />
      )}

      {!isMobileApp() &&
        <button
          type="button"
          className={cx['round-button']}
          onClick={() => printJS(pdfShowUrl)}
        >
          Print
        </button>
      }

      <a
        className={cx['action-button']}
        href={pdfShowUrl}
        target={isMobileApp() ? '_self' : '_blanc'}
      >
        Save as PDF
      </a>

      {isMobileApp() &&
        <button
          type="button"
          className={cx['round-button']}
          onClick={() => handleShareClick(pdfShowUrl)}
        >
          Share
        </button>
      }
    </div>
  );
}

ChartActionsButtons.propTypes = {
  onChangeSignedStatus: func.isRequired,
}

export default ChartActionsButtons;
