import { formatsurgicalForServer } from 'helpers/fmss/surgical/surgical';

const request = require('superagent-promise')(require('superagent'), Promise);

// get surgical list by patient
export const fetchSurgical = patientId =>
  request.get(`/api/rest/surgery/patient/${patientId}`)
    .end();

export const fetchSurgicalItem = surgicalItemId =>
  request.get(`/api/rest/surgery/${surgicalItemId}`)
    .end();

export const createSurgicalItem = surgicalItem =>
  request.post('/api/rest/surgery/create')
    .send(formatsurgicalForServer(surgicalItem))
    .end();

export const searchSurgery = searchRequest =>
  request.post('/api/rest/surgeries/search')
    .send({ searchRequest })
    .end();

export const updateSurgicalItem = surgicalItem =>
  request.post('/api/rest/surgery/update')
    .send(formatsurgicalForServer(surgicalItem))
    .end();

export const deleteSurgicalItem = surgicalItemId =>
  request.post('/api/rest/surgery/delete')
    .send({ id: surgicalItemId })
    .end();

export const createSurgery = name =>
  request.post('/api/rest/surgeries/create')
    .send({ name })
    .end();

export const deleteSurgery = id =>
  request
    .post(`/api/rest/surgeries/${id}`)
    .end();
