import MOMENT_TO_MS_STRATEGIES_TYPES from 'helpers/date-picker/types-strategies';

const datePickerStrategyFactory = (strategyType, timeZoneDateConverter) => {
  const {
    getDateInMs,
    getTimeZoneDateMs,
  } = timeZoneDateConverter;

  return strategyType === MOMENT_TO_MS_STRATEGIES_TYPES.WITH_TIME
    ? getTimeZoneDateMs
    : getDateInMs;
};


export default datePickerStrategyFactory;
