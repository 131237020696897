import * as constants from './constants';

import * as api from 'api/users';
import { getImage } from 'api/images';

export const fetchUserImage = (imageName, userId) => ({
  types: [
    constants.USER_FETCH_IMAGE,
    constants.USER_FETCH_IMAGE_SUCCESS,
    constants.USER_FETCH_IMAGE_FAIL,
  ],
  promise: () => getImage(imageName),
  userId,
});

export const fetchUsers = () => ({
  types: [
    constants.USERS_FETCH,
    constants.USERS_FETCH_SUCCESS,
    constants.USERS_FETCH_FAIL,
  ],
  promise: () => api.fetchUsers(),
});

export const createUser = (user, localUser) => {
  const userToSaveLocally = { ...localUser };
  delete userToSaveLocally.password;

  return {
    types: [
      constants.USER_CREATE,
      constants.USER_CREATE_SUCCESS,
      constants.USER_CREATE_FAIL,
    ],
    promise: () => api.createUser({
      ...user,
      permissions: user.permissions.map(o => o.id),
    }),
    user: userToSaveLocally,
  };
};

export const createUserLocally = user => ({
  type: constants.USER_CREATE_SUCCESS,
  payload: {
    result: user,
  },
});

export const updateUser = (user, localUser) => {
  const userToSaveLocally = { ...localUser };
  delete userToSaveLocally.password;

  return {
    types: [
      constants.USER_UPDATE,
      constants.USER_UPDATE_SUCCESS,
      constants.USER_UPDATE_FAIL,
    ],
    promise: () => api.updateUser({ ...user, permissions: user.permissions.map(o => o.id) }),
    user: userToSaveLocally,
  };
};

export const removeUserFromSeatLocally = userId => ({
  type: constants.USER_REMOVE_FROM_SEAT,
  payload: {
    userId,
  },
});

export const updateUserPermissions = (userId, permissions, roleId) => ({
  types: [
    constants.USER_PERMISSIONS_UPDATE,
    constants.USER_PERMISSIONS_UPDATE_SUCCESS,
    constants.USER_PERMISSIONS_UPDATE_FAIL,
  ],
  promise: () => api.updateUserPermissions(userId, permissions, roleId),
  userId,
  permissions,
});

export const fetchClinicUsers = clinicUsers => ({
  type: constants.USERS_CLINIC_FETCH,
  payload: {
    clinicUsers,
  },
});

export const updateUserPermissionsLocal = (userId, permissions) => ({
  type: constants.USER_PERMISSIONS_UPDATE_SUCCESS,
  payload: {
    userId,
    permissions,
  },
});

export const clearUserPermissionsForRemovedRole = role => ({
  type: constants.CLEAR_USER_PERMISSIONS_FOR_REMOVED_ROLE,
  payload: {
    role,
  },
});
