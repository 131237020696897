export const getIsFetchedForPatient = (state, patientId) => {
  if (state.byPatient && state.byPatient[patientId]) {
    return state.byPatient[patientId].isFetched;
  }

  return false
};

export const getIsFetchingForPatient = (state, patientId) => {
  if (state.byPatient && state.byPatient[patientId]) {
    return state.byPatient[patientId].isFetching;
  }

  return false
};

export const getIsFetching = state => state.isFetching;

export const getIsSaving = state => state.isSaving;

export const getIsFetched = state => state.isFetched;

export const getSystemNote = (state, noteId) => state.byId[noteId];

export const getSystemNotes = state => state.byId;

export const getNoteIsFetched = (state, noteId) => {
  if (!noteId) return;

  if (!state.byId[noteId]) return false;

  return state.byId[noteId].isFetched;
};
