import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = false;

export default handleActions({
  [constants.ROLES_FETCH]: () => true,

  [constants.ROLES_FETCH_SUCCESS]: () => false,

  [constants.ROLES_FETCH_FAIL]: () => false,
}, initialState)
