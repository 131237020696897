import cx from './Encounter.module.scss';

export const EmptyEncounter = () => {
  return (
    <tr
      style={{ height: '70px' }}
      className={cx['encounter--active']}
    >
      <td colSpan="7"></td>
    </tr>
  );
};