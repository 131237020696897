import { handleActions } from 'redux-actions';

import * as chartsConstants from 'modules/charts/constants';

const initialState = {
  charts: [],
};

export default handleActions({
  [chartsConstants.PATIENT_CHARTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    charts: [
      ...action.payload.result,
    ],
  }),
}, initialState);
