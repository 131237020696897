import MapConverterToObj from 'helpers/common/map/converter/to-object';
import FormatSubElementsByPositions from 'helpers/social/elements/sub-elements/format/by-position';
import stringParser from 'helpers/common/string/string-parser';

export default class PayloadDataToUpdateSubElementsForElement {
  constructor({
    patientId = 0, element = {}, socialId,
  }) {
    this.patientId = patientId;
    this.element = element;
    this.socialId = socialId;
  }

  getRequestData = () => {
    const {
      subIds: subElements,
      id,
      subElementHistoryJson,
    } = this.element;

    const {
      numberGroupMap,
    } = subElementHistoryJson;

    let subElementPositionViewMap = FormatSubElementsByPositions.format(subElements);
    subElementPositionViewMap = MapConverterToObj.convert(subElementPositionViewMap);

    return {
      elementId: id,
      patientId: stringParser(this.patientId),
      subElementHistoryMap: numberGroupMap,
      subElementPositionViewMap,
    };
  };

  getLocalData = () => {
    const {
      id,
    } = this.element;

    return {
      elementId: id,
      socialId: this.socialId,
      newElement: this.element,
    };
  }
}
