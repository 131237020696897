import * as api from 'api/seats';
import * as constants from './constants';

export const createSeat = seat => ({
  type:
    constants.SEAT_CREATE_SUCCESS,
  payload: {
    seat,
  },
});

export const fetchClinicSeats = () => ({
  types: [
    constants.SEATS_FETCH,
    constants.SEATS_FETCH_SUCCESS,
    constants.SEATS_FETCH_FAIL,
  ],
  promise: () => api.fetchClinicSeats(),
});

export const setSeatUser = seatData => ({
  types: [
    constants.SEAT_USER_SET,
    constants.SEAT_USER_SET_SUCCESS,
    constants.SEAT_USER_SET_FAIL,
  ],
  promise: () => api.setSeatUser(seatData),
  ...seatData,
});

export const setNextSeatUser = seatData => ({
  types: [
    constants.SEAT_USER_SET,
    constants.SEAT_USER_SET_SUCCESS,
    constants.SEAT_USER_SET_FAIL,
  ],
  promise: () => api.setNextSeatUser(seatData),
  ...seatData,
});

export const removeSeatUser = (seatData, prevUserId) => ({
  types: [
    constants.SEAT_USER_REMOVE,
    constants.SEAT_USER_REMOVE_SUCCESS,
    constants.SEAT_USER_REMOVE_FAIL,
  ],
  promise: async () => {
    await api.removeSeatUser(seatData);

    return api.lockSeat(seatData.seatId);
  },
  ...seatData,
  prevUserId,
});

export const fetchSeatPrice = seatId => ({
  types: [
    constants.SEAT_PRICE_FETCH,
    constants.SEAT_PRICE_FETCH_SUCCESS,
    constants.SEAT_PRICE_FETCH_FAIL,
  ],
  promise: () => api.fetchPrice(seatId),
});

export const purchaseSeat = data => ({
  types: [
    constants.SEAT_PURCHASE,
    constants.SEAT_PURCHASE_SUCCESS,
    constants.SEAT_PURCHASE_FAIL,
  ],
  promise: () => api.purchaseSeat(data),
});

export const paymentAllSeat = (seatIds, amount) => ({
  types: [
    constants.SEATS_UPDATE,
    constants.SEATS_UPDATE_SUCCESS,
    constants.SEATS_UPDATE_FAIL,
  ],
  promise: () => api.paymentAllSeat(seatIds, amount),
});

export const seatSetStatus = seat => ({
  type: constants.SEAT_SET_STATUS,
  payload: {
    seat,
  },
});

export const setUserInactive = () => ({
  type: constants.SET_USER_INACTIVE,
});
