import React from 'react';

import { isNullOrUndefined } from 'helpers';

export const fetchLayerHOC = Component => (
  class SocialSystemsFetchLayer extends React.Component {
    componentDidMount() {
      if (isNullOrUndefined(this.props.isFetching)) {
        this.props.fetchSystems();
      }
      if (isNullOrUndefined(this.props.isSessionFetching) && this.props.patientId) {
        this.props.fetchPatientSocial(this.props.patientId);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }
);
