import { connect } from 'react-redux';
import * as actions from 'modules/favoritesForceFetch';

export const mapStateToProps = state => ({
  favoritesForceFetch: state.favoritesForceFetch,
});

export const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
