import React from 'react';

import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';
import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import ValidatedDatepicker from 'components/ValidatedDatepicker';
import CommentBox from 'components/CommentBox';
import ValidatedField from 'components/Form/ValidatedField';
import SimpleSelect from 'components/MedSelect/SimpleSelect/SimpleSelect';

import { surgicalItemWhiteList } from 'helpers/fmss/surgical/surgical';
import duplicateSurgicalItemComparator from 'helpers/fmss/comparators/duplicate-items/surgery';
import { buildSchema } from 'helpers/validationRules';
import { formatMedOption } from 'helpers/selectHelpers';

import { createSurgery, searchSurgery, deleteSurgery } from 'api/surgical';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptySurgicalItem = {
  description: '',
};

const mapProps = (surgical) => ({
  id: surgical.id,
  surgery: formatMedOption(surgical?.surgery),
  startDate: surgical.startDate,
  description: surgical.description,
})

const validationSchema = () => buildSchema({
  surgery: 'requiredSelectOption',
})

const SurgicalItemForm = props => (
  <FormHOC
    emptyItem={emptySurgicalItem}
    item={props.surgicalItem}
    itemId={props.surgicalItemId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={props.updateSurgicalItem}
    onCreate={props.createSurgicalItem}
    onDelete={props.deleteSurgicalItem}
    router={props.router}
    location={props.location}
    patientId={props.patientId}
    whiteList={surgicalItemWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    itemsInTable={props.itemsInTable}
    duplicateItemComparator={duplicateSurgicalItemComparator}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({ itemId: surgicalItemId, item: surgicalItem, fetching, clean, update, del, submit }) => {
      return (
        <form
          onSubmit={submit}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={surgicalItemId ? 'Editing surgery' : 'Adding surgery'}
                onClick={surgicalItemId ? clean : () => null}
              />

              <div className={cx.row}>
                <h3 className="section-title">Surgery</h3>
                <ValidatedField>
                  <SimpleSelect
                    name="surgery"
                    currentValue={surgicalItem.surgery}
                    onCreate={createSurgery}
                    onChange={value => update('surgery', value)}
                    loadOptions={searchSurgery}
                    onRemoveOption={deleteSurgery}
                  />
                </ValidatedField>
              </div>
              <div className={cx.row}>
                <ValidatedDatepicker
                  title="start date"
                  value={surgicalItem.startDate}
                  onChange={value => update('startDate', value)}
                />
              </div>

              <div className={cx.row}>
                <CommentBox
                  title="surgery comment"
                  placeholder="Enter a comment"
                  value={surgicalItem.description}
                  onChange={e => update('description', e.target.value)}
                />
              </div>
            </div>

            <EditFormActions
              deleteButton={!!surgicalItemId}
              cancelButton
              submitButton
              disabledSubmit={fetching}
              disabledCancel={fetching}
              onDelete={del}
              onCancel={clean}
            />

          </EditForm>
        </form>
      );
    }}
  </FormHOC>
);

SurgicalItemForm.propTypes = generateFormPropTypes({
  itemName: 'surgicalItem',
  itemIdName: 'surgicalItemId',
  onUpdate: 'updateSurgicalItem',
  onCreate: 'createSurgicalItem',
  onDelete: 'deleteSurgicalItem',
});

const DirtyCheckedWrapper = dirtyCheckLayerHOC(SurgicalItemForm);

export default props => (
  <LoaderLayer
    disabled={props.surgicalItemId === undefined}
    status={props.status}
    reload={() => props.fetchSurgicalItem(props.surgicalItemId)}
  >
    <DirtyCheckedWrapper
      {...props}
      dirtyCheckIdentity="surgicalItemId"
    />
  </LoaderLayer>
);
