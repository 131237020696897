import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  showPaymentWarning: false,
  severity: 1,
  isReadOnly: false,
  isBeforeReadOnly: false,
  daysToReadOnly: 0,
  isOrangeIcon: false,
  isNeedToPay: false,
  didPaymentOccur: false,
};

export default handleActions({
  [constants.SET_PAST_DUE_PARAMS]: (state, action) => ({
    ...state,
    showPaymentWarning: action.payload.isShouldShowWarning,
    isReadOnly: action.payload.isReadOnly,
    isBeforeReadOnly: action.payload.isBeforeReadOnly,
    severity: action.payload.severity,
    daysToReadOnly: action.payload.daysToReadOnly,
    isOrangeIcon: action.payload.isOrangeIcon,
    isNeedToPay: action.payload.isNeedToPay,
    didPaymentOccur: action.payload.didPaymentOccur,
  }),
  [constants.SET_DID_PAYMENT_OCCUR]: (state, action) => ({
    ...state,
    didPaymentOccur: action.payload.didPaymentOccur,
  }),
}, initialState);
