import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import cx from './PinPage.module.scss';

import Logo from 'components/Logo';
import Value from './Value/';
import Keyboard from './Keyboard/';

const keyMap = {
  8: '⬅',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  96: '0',
  97: '1',
  98: '2',
  99: '3',
  100: '4',
  101: '5',
  102: '6',
  103: '7',
  104: '8',
  105: '9',
};

const pinLength = 6;

class PinPage extends Component {
  state = {
    value: '',
    pressed: null,
    error: false,
    failedAttempts: 0,
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this).focus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.failedAttempts === 3) {
      document.location.reload();
    }

    if (prevProps.unlockError !== this.props.unlockError && this.props.unlockError) {
      this.handleError();
    }
  }

  onBlur = () => {
    ReactDOM.findDOMNode(this).focus();
  };

  handleError = () => {
    if (this.errorTimer) {
      this.errorTimer = null;
    }

    this.setState({
      error: true,
      failedAttempts: this.state.failedAttempts + 1,
    }, () => {
      this.timer = setTimeout(() => {
        this.setState({
          error: false,
          value: '',
        });
      }, 500);
    });
  };

  handlePress = async (value) => {
    if (this.state.error || this.props.lockProcessing) return;
    if (!Number.isNaN(parseInt(value))) {
      if (this.state.value.length === pinLength) return;
      this.setState(state => ({ value: state.value + value }), () => {
        if (this.state.value.length === pinLength) {
          this.props.onUnlock(this.state.value);
        }
      });
    } else {
      this.setState(state => ({ value: state.value.slice(0, -1) }));
    }
  };

  handleKeyDown = (e) => {
    if (keyMap[e.keyCode]) {
      this.setState({ pressed: keyMap[e.keyCode] });
      this.handlePress(keyMap[e.keyCode]);
    }
  };

  handleKeyUp = (e) => {
    if (keyMap[e.keyCode]) {
      this.setState({ pressed: null });
    }
  };

  render() {
    const { value, pressed, error } = this.state;
    const {
      handlePress, onBlur, handleKeyDown, handleKeyUp,
    } = this;
    const { lockProcessing } = this.props;

    return (
      <div
        className={cx['wrapper']}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        tabIndex="1"
      >
        <button
          type="button"
          className={cx['reload-button']}
          onClick={() => document.location.reload()}
        />
        <div className={cx['inner-wrapper']}>
          <div className={cx['logo-wrapper']}>
            <Logo />
          </div>
          <div className={cx['form-wrapper']}>
            <Value
              maxLength={pinLength}
              valueLength={value.length}
              error={error}
            />
            <div className={cx['loader-wrapper']}>
              {lockProcessing && <div className={cx['loader']} />}
            </div>
            <Keyboard onClick={handlePress} pressedKey={pressed} />
          </div>
        </div>
      </div>
    );
  }
}

export default PinPage;
