import React from 'react';
import { compose } from 'redux';
import { func, shape, bool, string } from 'prop-types';

import BannerContainer from 'containers/BannerContainer';

import { getTitleColor, getClearIconColor } from 'helpers/notice/BannerService';

import cx from './Banner.module.scss';

const Banner = (props) => {
  const {
    status,
    hideBanner,
  } = props;

  const {
    isShow,
    title,
    message,
    messageType = 'info'
  } = status;

  if (!isShow) {
    return null;
  }

  return (
    <div className={cx.container}>
      <div
        className={cx.title}
        style={{
          color: getTitleColor(messageType)
        }}
      >{title}</div>
      <div>
        {message}
      </div>
      <button
        type="button"
        onClick={hideBanner}
        className={cx['clear-icon__container']}
      >
        <svg
          height="30"
          width="30"
          viewBox="0 0 30 30"
          className={cx['clear-icon']}
          fill={getClearIconColor(messageType)}
        >
          <path transform="scale(1.6)" d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </button>
    </div>
  );
};

Banner.propTypes = {
  messageType: string,
  hideBanner: func.isRequired,
  status: shape({
    isShow: bool,
    title: string,
    message: string
  }).isRequired
};

const BannerWrapper = compose(
  BannerContainer
)(Banner);

export default BannerWrapper;
