import { useEffect, useState } from 'react';
import { defaultSigs } from 'api/medicationSig';

export const useMedicationSig = ({ medMedicationId }) => {
  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    const defaultSigsAsync = async () => {
      const { body } = await defaultSigs({ id: medMedicationId });

      setDefaultOptions(body.map((description) => ({ value: description, label: description })));
    };

    defaultSigsAsync();

    return () => {
      setDefaultOptions([]);
    };
  }, []);

  return {
    defaultOptions,
    setDefaultOptions
  };
};
