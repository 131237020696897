import { Allergies } from './components/Allergies';
import { AllergiesWrapper } from './components/AllergiesList';

export const AllergiesRoute = {
  path: 'allergies',
  element: <Allergies />,
  children: [
    {
      index: true,
      element: <AllergiesWrapper />
    },
    {
      path: ':filter',
      element: <AllergiesWrapper />
    }
  ]
};
