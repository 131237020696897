import React from 'react';
import PropTypes from 'prop-types';

const TabTitle = ({ children: title }) => {
  const hxPos = title.indexOf('Hx');
  const hasHx = hxPos > -1;

  let slicedTitle;
  let hxAbbr;

  if (hasHx) {
    slicedTitle = title.slice(0, hxPos);
    hxAbbr = <span style={{ textTransform: 'none' }}>{title.slice(hxPos)}</span>;
  }

  return (
    <span>
      {hasHx && slicedTitle}
      {hasHx && hxAbbr}
      {!hasHx && title}
    </span>
  );
};

TabTitle.propTypes = {
  children: PropTypes.string.isRequired,
  cx: PropTypes.func,
}

export default TabTitle;
