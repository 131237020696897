import React from 'react';
import { useOutletContext } from 'react-router';

import { useRoute } from 'hooks/useRoute';

import medicalItemContainer from 'containers/FMSS/Medical/MedicalItemContainer';
import MedicalForm from 'containers/FMSS/Medical/MedicalForm';

const Component = medicalItemContainer(MedicalForm);

export const MedicalFormWrapper = () => {
  const { fromSearchParams } = useRoute();
  const { patientId } = useOutletContext();
  return (
    <Component
      patientId={patientId}
      medicalItemId={fromSearchParams('medical')} />
  );
};
