export default class Time {
  constructor({
    hours = 0, min = 0, sec = 0, ms = 0,
  }) {
    this._hours = hours;
    this._min = min;
    this._sec = sec;
    this._ms = ms;
  }

  get hours() {
    return this._hours;
  }

  get min() {
    return this._min;
  }

  get sec() {
    return this._sec;
  }

  get ms() {
    return this._ms;
  }
}
