import React from 'react';
import { compose } from 'redux';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import allergiesContainer, { withAllergiesFetchLayer } from 'containers/Allergies/AllergiesContainer';
import AllergiesPage from 'containers/Allergies/AllergiesPage';

const AllergiesContainer = compose(
  chartToPatientContainer,
  allergiesContainer,
  withAllergiesFetchLayer
)(AllergiesPage);

export const ChartAllergiesPage = () => {
  const [searchParams] = useSearchParams();
  const { chartId } = useParams();
  return (
    <AllergiesContainer
      filter="active"
      allergyId={searchParams.get('allergy')}
      linkBase={`/app/doctor/charts/${chartId}/allergies`}
    >
      <Outlet />
    </AllergiesContainer>
  );
};
