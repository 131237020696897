import { isNullOrUndefined } from 'helpers';

export default class DrChronoConnectionInfo {
  constructor(connectionInfo) {
    this.connectionInfo = connectionInfo;
  }

  isNullErrorDTO = () => {
    if (isNullOrUndefined(this.connectionInfo)) {
      return false;
    }

    const { errorDTO } = this.connectionInfo;

    return !isNullOrUndefined(errorDTO);
  }
}
