import React from 'react';

import useClinics from 'routes/routes/Admin/components/useClinics';
import ClinicSelect from 'routes/routes/Admin/components/ClinicSelect';
import Checkbox from 'components/Checkbox';
import PatientsImport from 'components/PatientsImport';

import { getClinicsConfig, patientsUpload, setClinicConfig } from 'api/administrator';

import cx from 'routes/routes/Admin/routes/User/routes/Manager/components/Manager.module.scss';

export const ClinicManager = () => {
  const {
    clinics,
    selectedClinic,
    onClinicChange,
    selectedClinicInfo,
    updateClinicInfo
  } = useClinics({ isNeedUsers: false, fetchClinics: getClinicsConfig });

  const saveChanges = () => {
    const { id, suspend } = selectedClinicInfo;
    setClinicConfig({ clinicId: id, suspend });
  };

  return (
    <div style={{ width: '100%' }}>
      <ClinicSelect
        clinics={clinics}
        onClinicChange={onClinicChange}
        selectedClinic={selectedClinic}
      />
      {selectedClinic && (
        <>
          <div
            className={cx.row}
          >
            <Checkbox
              styles={{
                padding: '12px'
              }}
              checked={selectedClinicInfo.suspend}
              onClick={() => updateClinicInfo('suspend', !selectedClinicInfo.suspend)}
            >
              Is Suspend
            </Checkbox>

            <button
              type="button"
              className="generic-button"
              onClick={saveChanges}
            >
              Save
            </button>
          </div>
          <PatientsImport
            uploadCSV={file => patientsUpload(selectedClinicInfo.id, file)}
            fetchPatients={() => {}}
          >
            <button type="button" className='generic-button'>Import from CSV</button>
          </PatientsImport>
        </>
      )}
    </div>
  );
};
