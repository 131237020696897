import React, { useCallback, useState } from 'react';

import Modal from 'components/Modal';

export const DeleteModal = props => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = useCallback(() => {
    props.onCloseModal();
    setIsOpen(false);
  }, []);

  const confirmModal = useCallback(async () => {
    await props.onConfirm();
    closeModal();
  }, []);

  const {
    title = 'Delete',
    content = '<p>Are you sure you want to remove this item? Its data will be lost!',
  } = props;

  const data = {
    type: 'keyword-confirm',
    keyWord: 'DELETE',
    title,
    content
  };
  return (
    <Modal
      data={data}
      isOpen={isOpen}
      onModalClose={closeModal}
      onModalConfirm={confirmModal}
    />
  );
};