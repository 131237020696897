import moment from 'moment';

import { SEATS_STATUS } from 'components/SeatUsersDropdown/helpers';
import { PAST_DUE_DAYS_VALUES } from '../constants';

export default class BillingService {
  constructor(timeZoneDateConverter) {
    this.timeZoneDateConverter = timeZoneDateConverter;
  }

  getNeedToPaySeatIds = seats => seats
    .filter(seat => seat.status === SEATS_STATUS.NEED_TO_PAY)
    .map(seat => seat.id);

  getNoPaymentSeats = seats => seats.filter(seat => seat.status === SEATS_STATUS.NEED_TO_PAY);

  getDiffDays = seat => moment(this.timeZoneDateConverter.transformCurrentDate()).diff(this.timeZoneDateConverter.transformByDate(seat.expireDate), 'days');

  pastDueSeats = seat => this.getDiffDays(seat) >= PAST_DUE_DAYS_VALUES.PASTDUE_DAYS_BEFORE_WARNING;

  shouldShowWarning = seats => seats.filter(this.pastDueSeats).length > 0;

  pastDueSeatOrangeSeverity = (seat) => {
    const pastdue = this.getDiffDays(seat);

    return pastdue >= PAST_DUE_DAYS_VALUES.PASTDUE_DAYS_BEFORE_WARNING && pastdue <= PAST_DUE_DAYS_VALUES.ERROR_TRESHOLD;
  };

  pastDueSeatRedSeverity = (seat) => {
    const pastdue = this.getDiffDays(seat);

    return pastdue >= (PAST_DUE_DAYS_VALUES.ERROR_TRESHOLD + 1) && pastdue <= (PAST_DUE_DAYS_VALUES.READ_ONLY_DAYS - 1);
  };

  pastDueReadOnly = seat => this.getDiffDays(seat) >= PAST_DUE_DAYS_VALUES.START_READ_ONLY_DAYS;

  isReadOnly = seats =>
    seats
      .filter(this.pastDueReadOnly).length !== 0;

  pastDueBeforeReadOnly = seat =>
    this.getDiffDays(seat) === PAST_DUE_DAYS_VALUES.READ_ONLY_DAYS

  isBeforeReadOnly = seats => seats.filter(this.pastDueBeforeReadOnly).length !== 0;

   getIsBeforeReadOnly = (isBeforeReadOnlyStatus) => {
     if (isBeforeReadOnlyStatus === true && this.isReadOnly === true) {
       return false;
     }

     return isBeforeReadOnlyStatus;
   };

  isOrangeIcon = severity => severity === 1 || severity === 3;

  getSeverity = (seats) => {
    // past due >= 16 days
    if (this.isReadOnly(seats)) {
      return 0;
    }

    // past due === 15 days
    if (this.isBeforeReadOnly(seats)) {
      return 3;
    }

    // past due 8-14 days
    if (seats.filter(this.pastDueSeatRedSeverity).length !== 0) {
      return 2;
    }

    // past due 4-7 days
    if (seats.filter(this.pastDueSeatOrangeSeverity).length !== 0) {
      return 1;
    }
  };

  getDaysUntilReadOnly = seats => PAST_DUE_DAYS_VALUES.READ_ONLY_DAYS - Math.max(...seats.map(seat => this.getDiffDays(seat)));
}
