import React from 'react';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './Loader.module.scss';

const loaderStyles = ({ size, isStatic, className }) =>
  cssClassesResolver([
    size === 'normal' && cx.loader_normal,
    size === 'small' && cx.loader_small,
    size === 'large' && cx.loader_large,
    isStatic && cx['loader--static'],
    className
  ])

const Loader = ({
                  style,
                  className,
                  isStatic,
                  primaryColor = '#2f9dc6',
                  secondaryColor = 'transparent',
                  size = 'large',
                }) => (
  <div
    className={loaderStyles({ size, className, isStatic })}
    style={{
      ...style,
      borderColor: primaryColor,
      borderTopColor: secondaryColor,
    }}
  />
);

Loader.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  isStatic: PropTypes.bool,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

export default Loader;
