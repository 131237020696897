import { checkExistingEmail } from 'api/user';

import * as rolesHelpers from '../roles';
import { addNewProperty } from '..';
import { VERIFIED_EMAIL_STATUS } from './constants';

export const isUserBelongToCurrentClinic = (userEmail, allUsers) => {
  return allUsers.filter(user => user.login === userEmail && user.verifyEmail === true).length > 0;
};

export const isUserPresent = (userId, allUsers) => {
  return allUsers.filter(user => user.id === userId).length > 0;
};

export const createEmailObjectToRequest = (email) => {
  return { login: email };
};

export const checkExistedEmailDecorator = fn => async (email, isShouldEmailExist) => {
  const emailData = createEmailObjectToRequest(email);
  const emailValidityRequest = await checkExistingEmail(emailData);

  const isEmailExist = typeof emailValidityRequest.body === 'number';

  if (isEmailExist === isShouldEmailExist) {
    return fn(emailValidityRequest.body);
  }

  return null;
};

export const setRoleIdForEachUser = (users, allRoles) => {
  const clinicUsersWithRoleId = [];

  users.forEach((user) => {
    const roleId = rolesHelpers.getUserRoleId(user.permissions, allRoles);

    const userWithRoleId = addNewProperty('roleId', roleId, user);

    clinicUsersWithRoleId.push(userWithRoleId);
  });

  return clinicUsersWithRoleId;
};

export const defineUserVerifiedEmailStatus = (isVerifyEmail) => {
  if (isVerifyEmail === false) {
    return VERIFIED_EMAIL_STATUS.pending;
  }
  return VERIFIED_EMAIL_STATUS.empty;
};
