import { combineReducers } from 'redux';

import data from './data';
import status from './status';
import statusByChart from './statusByChart';
import isSystemSaving from './isSystemSaving';

export default combineReducers({
  data,
  status,
  statusByChart,
  isSystemSaving,
});
