import { useEffect, useState } from 'react';

import isEmpty from 'helpers/common/array/is-empty';
import { isNullOrUndefined } from 'helpers';

import { getUsers } from 'api/administrator';

const useClinics = ({ fetchClinics, isNeedUsers }) => {
  const [clinics, setClinics] = useState([])
  const [users, setUsers] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedClinicInfo, setSelectedClinicInfo] = useState(null)

  useEffect(() => {
    const getClinicsWrapper = async () => {
      const { body } = await fetchClinics()
      if (!isEmpty(body)) {
        setClinics(body)
      }
    }

    getClinicsWrapper()
  }, [])

  const getClinicInfo = id => clinics.filter(iteratedClinic => iteratedClinic.id === id)[0]

  const handleResponseUsersByStatus = {
    200: (serverUsers) => {
      setUsers(serverUsers)
    }
  }

  const fetchUsersWrapper = async (clinicId) => {
    const { status, body } = await getUsers(clinicId)
    const callBack = handleResponseUsersByStatus[status]

    if (!isNullOrUndefined(callBack)) {
      callBack(body)
    }
  }

  const onClinicChangeWrapper = selectedClinicOption => {
    setSelectedClinic(selectedClinicOption)
    const clinicInfo = getClinicInfo(selectedClinicOption.value)
    setSelectedClinicInfo(clinicInfo)

    if (isNeedUsers) {
      fetchUsersWrapper(selectedClinicOption.value)
    }
  }

  const onUserChangeWrapper = (selectedUserOption) => {
    setSelectedUser(selectedUserOption)
    // const rawSelectedUsersToSet = users.filter(iteratedUser => iteratedUser.id === selectedUserOption.value)
    // setRawSelectedUser(rawSelectedUsersToSet[0])
  }

  const updateClinicInfo = (key, value) => setSelectedClinicInfo({
    ...selectedClinicInfo,
    [key]: value
  })

  const loadOptionsMapper = clinicsForSelect =>
    clinicsForSelect.map(clinic => ({ value: clinic.id, label: clinic.legalName }))

  const userOptionsMapper = usersForSelect =>
    usersForSelect.map(user => ({ value: user.id, label: user.login }))

  return {
    clinics: loadOptionsMapper(clinics),
    selectedClinic,
    onClinicChange: onClinicChangeWrapper,
    users: userOptionsMapper(users),
    selectedUser,
    selectedClinicInfo,
    updateClinicInfo,
    onUserChange: onUserChangeWrapper
  }
}

export default useClinics
