import { getPatientById } from 'modules/patients/selectors';

import isEmpty from 'helpers/common/array/is-empty';

export default class AdditionalCheckboxDataTemplate {
  constructor({
    checkboxParamName, items, patientId, state,
  }) {
    this.checkboxParamName = checkboxParamName;
    this.patientId = patientId;
    this.state = state;
    this.items = items;
  }

  getMappedProps = () => {
    return {
      checkboxValue: this.getCheckboxValue(),
      isPresentItems: this.getIsPresentItems(),
    };
  };

  getCheckboxValue = () => {
    const {
      patients,
    } = this.state;

    const patient = getPatientById(patients, this.patientId);
    return patient[this.checkboxParamName];
  };

  getIsPresentItems = () => {
    return !isEmpty(this.items);
  }
}
