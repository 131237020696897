import React from 'react';
import PropTypes from 'prop-types';

import { fetchStates } from 'api/states';
import DropdownHOC from 'components/DropdownHOC';

const StateInput = (props) => {
  const {
    className,
    value,
    disabled,
    onChange,
    styles
  } = props;

  const optionMapper = state => ({ value: state, label: state })
  const buildSelectOption = initialValue => initialValue?.length ? { value: initialValue, label: initialValue } : null
  const option = typeof value === 'object' ? value : buildSelectOption(value)

  return (
    <DropdownHOC
      isClearable
      loadOptions={fetchStates}
      optionMapper={optionMapper}
      value={option}
      disabled={disabled}
      selectStyles={styles}
      className={className}
      onChange={state => onChange(state?.value)}
    />
  );
}

StateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default StateInput;
