import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'containers/RouterParams';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Loader from 'components/Loader';
import System from 'components/NoteSystems/System';
import Controls from 'components/NoteSystems/Systems/Controls';
import Modal from 'components/Modal';
import NoteInput from 'components/NoteSystems/Systems/NoteInput';

import * as socialHelpers from 'helpers/social';
import NumberGroupsDifferenceAfterRemoving from 'helpers/subelements/difference/after-removing';
import ResetSubElementsHistoryByGroups from 'helpers/social/elements/sub-elements/reset-history-by-group';

import PayloadDataToRemoveElements from 'payload/social/elements/remove';
import PayloadDataToUpdateElementName from 'payload/social/elements/update/name';

import { createSocialSubElement } from 'api/social';

import cx from './SocialActiveSystems.module.scss';

const modalData = {
  type: 'confirm',
  title: 'Remove system',
  content: '<p>Are you sure you want to remove this system?</p><p class="warning-text"><strong>Warning:</strong> All enclosed elements will be removed!</p>',
};

const modalDataFixed = {
  type: 'confirm',
  title: 'Clear contents',
  content: '<p><strong style="color: orange">Warning:</strong> Are you sure you want to remove all enclosed elements?</p>',
};

class SocialActiveSystems extends React.Component {
  state = {
    isModalOpen: false,
    systemToDelete: null,
  };

  componentDidMount() {
    // if we have activeSystem but systems are empty
    // user probably refreshed page while being in empty system
    // we try to push this system to current social session
    const { activeSystem, addSystem, items, patientId } = this.props;
    if (activeSystem && items.length === 0) {
      addSystem(activeSystem, patientId);
    }
  }

  getElementToAdd = elementToAdd => elementToAdd || socialHelpers.getNeedToAddObject(false);

  openModal = (system) => {
    // Modal component is shit and doesn't update if its props
    // are changed when it is already rendered, so we have
    // to update system first and then show modal
    this.setState({
      systemToDelete: system,
    }, () => this.setState({ isModalOpen: true }));
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      systemToDelete: null,
    });
  };

  modalConfirm = async () => {
    const {
      patientId,
      removeElementsWrapper,
      activeSystem,
      enclosedElements,
      chartId,
      deleteFreeText,
      freeTextForAllSystem
    } = this.props;

    const {
      systemToDelete,
    } = this.state;

    const {
      id: systemId,
    } = systemToDelete;

    const data = {
      patientId,
      systemId,
      elements: enclosedElements[systemId],
    };
    const payloadDataToRemoveElements = new PayloadDataToRemoveElements(data);

    await removeElementsWrapper(payloadDataToRemoveElements.getLocalData(), payloadDataToRemoveElements.getRequestData(), chartId);
    if (freeTextForAllSystem[`p${patientId}s${systemId}`]) {
      deleteFreeText({ 'patient_id': patientId, 'social_id': systemId });
    }
    // if we have activeSystem
    // redirect
    if (activeSystem) {
      this.transitionToSystem(undefined);
    }
    this.closeModal();
  };

  transitionToSystem = (systemId) => {
    const {
      router,
      location
    } = this.props;

    router.push({
      pathname: location.pathname,
      query: {
        system: systemId,
      },
    });
  };

  transitionToAllSystems = () => {
    const {
      router,
      location
    } = this.props;

    router.push(location.pathname);
  };

  createElement = (chartId, element, elementToAdd) => {
    const { currentUserId } = this.context;
    const elem = {
      ...element,
      userId: currentUserId,
    };
    return this.props.createElement(this.props.patientId, elem, this.getElementToAdd(elementToAdd));
  };

  removeElements = ({ systemId, element }) => {
    const {
      patientId,
      removeElementsWrapper,
      chartId
    } = this.props;

    const data = {
      patientId,
      systemId,
      elements: [element],
    };
    const payloadDataToRemoveElements = new PayloadDataToRemoveElements(data);

    return removeElementsWrapper(
      payloadDataToRemoveElements.getLocalData(),
      payloadDataToRemoveElements.getRequestData(),
      chartId
    );
  };

  addElement = (chartId, systemId, element) => {
    const { currentUserId } = this.context;
    const elem = {
      ...element,
      userId: currentUserId,
    };
    delete elem.type;

    const { addElement, patientId } = this.props;

    return addElement(
      patientId, systemId, elem, { needToAdd: true }
    );
  };

  updateElementWrapper = (newElement, oldElement) => {
    const numberGroups = NumberGroupsDifferenceAfterRemoving.define(newElement.name, oldElement.name);

    const updatedOldElement = this.updateElement(oldElement, numberGroups);

    const {
      patientId,
      activeSystem,
      updateElement
    } = this.props;

    const data = {
      element: {
        ...updatedOldElement,
        ...newElement,
      },
      patientId,
      socialId: activeSystem,
    };

    const payloadDataToUpdateElement = new PayloadDataToUpdateElementName(data);
    const dataToRequest = payloadDataToUpdateElement.getRequestData();
    const dataToLocalSave = this.collectLocalDataToUpdateElement(newElement, updatedOldElement);

    return updateElement(dataToLocalSave, dataToRequest);
  };

  updateElement = (oldElement, numberGroups) => {
    const { subIds } = oldElement;
    const isPresentSubElements = subIds && subIds.length > 0;
    const isPresentNumberGroups = numberGroups.length > 0;

    if (isPresentSubElements && isPresentNumberGroups) {
      const updateSubElementsForOldElement = this.updateElementSubIdsAfterRemoving(oldElement, numberGroups);
      const updatedHistoryForOldElement = ResetSubElementsHistoryByGroups.updateHistory(updateSubElementsForOldElement, numberGroups);
      return this.updatePositionsAfterRemovingSubIds(updatedHistoryForOldElement);
    }

    return oldElement;
  };

  updateElementSubIdsAfterRemoving = (element, numberGroups) => {
    const { subIds } = element;
    let updatedSubIds = [...subIds];
    numberGroups.forEach((numberGroup) => {
      updatedSubIds = updatedSubIds.filter(subId => subId.numberGroup !== numberGroup);
    });

    return {
      ...element,
      subIds: updatedSubIds,
    };
  };

  updatePositionsAfterRemovingSubIds = (element) => {
    const { subIds } = element;

    const updatedSubIds = [...subIds];

    for (let i = 0; i < subIds.length; i += 1) {
      updatedSubIds[i].position = i + 1;
    }

    return {
      ...element,
      subIds: updatedSubIds,
    };
  };

  collectLocalDataToUpdateElement = (newElement, oldElement) => {
    return {
      newElement,
      oldElement,
      patientId: this.props.patientId,
      socialId: this.props.activeSystem,
    };
  };

  allowCreationBySystem = () => {
    const {
      permissions,
    } = this.context;

    const {
      viewEditPatientClinicalInformation,
    } = permissions;

    return viewEditPatientClinicalInformation;
  };

  selectElementCallbackWrapper = (selectedElementId) => {
    const {
      patientId,
      chartId,
      activeSystem,
      selectElement
    } = this.props;

    selectElement({
      patientId, elementId: selectedElementId, socialId: activeSystem, chartId,
    });
  };

  selectSubElementCallback = (element, subElement) => {
    const {
      patientId,
      chartId,
      activeSystem,
      selectSubElementWrapper,
    } = this.props;

    selectSubElementWrapper(element, subElement, patientId, activeSystem, chartId);
  };

  selectAfterFirstSubElement = (element, subElement) => {
    const {
      patientId,
      activeSystem,
      updateSocialSubElementsHistoryWrapper,
    } = this.props;

    updateSocialSubElementsHistoryWrapper(element, subElement, patientId, activeSystem);
  };

  render() {
    const {
      isFetching,
      isSessionFetching,
      items,
      activeSystem,
      searchableItems,
      enclosedElements,
      allElements,
      patientId,
      patient,
      isUpdating,
      freeTextForAllSystem,
      createFreeText,
      updateFreeText,
    } = this.props;

    if (isFetching !== false || isSessionFetching !== false || isUpdating === true) return <Loader />;

    const {
      transitionToSystem, createElement, addElement, removeElements, closeModal, openModal, modalConfirm,
    } = this;

    const { systemToDelete, isModalOpen } = this.state;

    let nextSystem;

    if (activeSystem) {
      // before we filter out inactive systems we should get next system
      nextSystem = items[items.findIndex(system => system.id === activeSystem) + 1];
    }

    return (
      <div className={cx.wrapper}>
        {activeSystem && (
          <Controls
            onBack={this.transitionToAllSystems}
            onForward={systemId => transitionToSystem(systemId)}
            nextSystem={nextSystem}
            social
          />
        )}
        {items.filter(system => (activeSystem ? system.id === activeSystem : true)).map(system => (
          <Fragment key={`fagment_${system.id}`}>
            <System
              isAllowAttachingIcdTenCodes={false}
              allowElementUpdate={this.allowCreationBySystem()}
              allowCreation={this.allowCreationBySystem()}
              system={system}
              onSystemClick={() => transitionToSystem(system.id)}
              onRemove={() => {
                openModal(system);
              }}
              createElement={createElement}
              addElement={addElement}
              removeElement={removeElements}
              allElements={allElements}
              searchableItems={searchableItems}
              elements={enclosedElements[system.id]}
              updateElement={this.updateElementWrapper}
              social
              patientId={patientId}
              isFetching={isFetching}
              patient={patient}
              activeSystem={activeSystem}
              listOfAutoOpenSubelementsIndexes={this.props.listOfAutoOpenSubelementsIndexes}
              addIndexToAutoSet={this.props.addIndexToAutoSet}
              resetIndexToAutoSet={this.props.resetIndexToAutoSet}
              createSubelement={createSocialSubElement}
              selectElementCallback={this.selectElementCallbackWrapper}
              selectSubElementCallback={this.selectSubElementCallback}
              selectAfterFirstSubElementCallback={this.selectAfterFirstSubElement}
            />
            {activeSystem && (
              <NoteInput
                title={system.name}
                systemId={system.id}
                elementsCount={enclosedElements[system.id].length}
                systemNote={freeTextForAllSystem[`p${patientId}s${system.id}`]}
                createSystemNote={({ freeText }) => createFreeText({ patientId, socialId: system.id, freeText })}
                updateSystemNote={({ freeText }) => updateFreeText({ patientId, socialId: system.id, freeText })}
              />
            )}
          </Fragment>
        ))}

        <Modal
          data={systemToDelete && systemToDelete.fixed ? modalDataFixed : modalData}
          isOpen={isModalOpen}
          onModalClose={closeModal}
          onModalConfirm={modalConfirm}
        />
      </div>
    );
  }
}

SocialActiveSystems.propTypes = {
  patientId: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  searchableItems: PropTypes.array.isRequired,
  allElements: PropTypes.array.isRequired,
  addSystem: PropTypes.func.isRequired,
  updateElement: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  removeElementsWrapper: PropTypes.func.isRequired,
  createElement: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSessionFetching: PropTypes.bool,
  enclosedElements: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  resetIndexToAutoSet: PropTypes.func.isRequired,
  addIndexToAutoSet: PropTypes.func.isRequired,
  activeSystem: PropTypes.number,
  updateSocialSubElementsHistory: PropTypes.func.isRequired,
  selectElement: PropTypes.func.isRequired,
  updateSocialSubElementsHistoryWrapper: PropTypes.func.isRequired,
  selectSubElementWrapper: PropTypes.func,
};

SocialActiveSystems.defaultProps = {
  activeSystem: null,
  selectSubElementWrapper: null,
};

SocialActiveSystems.contextType = AppContext;

export default withRouter(SocialActiveSystems);
