const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchUser = () =>
  request.get('/api/rest/users/get')
    .end();

export const fetchRaces = () =>
  request.get('/api/rest/races')
    .end();

export const fetchEthnicities = () =>
  request.get('/api/rest/ethnicity')
    .end();

export const acceptAgreement = () =>
  request.get('/api/rest/users/agreement')
    .end();

export const checkExistingEmail = email =>
  request.post('/api/rest/users/already/exist/email')
    .send(email)
    .end();

export const verifyPassword = data =>
  request.post('/api/rest/users/verifyPassword')
    .send(data)
    .end();

export const sendInvite = data =>
  request.post('/api/rest/userClinic/invite')
    .send(data)
    .end();

export const getUserClinics = userId =>
  request.get(`/api/rest/userClinic/get/${userId}`)
    .end();

export const setUserClinic = clinicId =>
  request.post('/api/rest/users/set/clinic')
    .send({ clinicId })
    .end();
