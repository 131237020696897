import React from 'react';
import PropTypes from 'prop-types';

import cx from './BuySeatButton.module.scss';

const BuySeatButton = ({ buySeat }) => (
  <div
    className={cx['buy-button']}
    onClick={buySeat}
  />
);

BuySeatButton.propTypes = {
  buySeat: PropTypes.func.isRequired,
};

export default BuySeatButton;
