const request = require('superagent-promise')(require('superagent'), Promise);

export const searchMostFrequent = ({ template, id }) =>
  request
    .get(`/api/rest/medication/${id}/sig/search`)
    .query({ template })
    .end();

export const defaultSigs = ({ id }) =>
  request
    .get(`/api/rest/medication/${id}/sig/`)
    .end();

export const create = ({ id, payload }) =>
  request
    .post(`/api/rest/medication/${id}/sig/`)
    .send(payload)
    .end();

export const deleteSig = ({ id, template }) =>
    request
    .del(`/api/rest/medication/${id}/sig/`)
    .query({ template })
    .end();
