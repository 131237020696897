import { combineReducers } from 'redux';

import byId from './byId';
import isFetching from './isFetching';
import isFetched from './isFetched';
import isCreating from './isCreating';
import isUpdating from './isUpdating';

export default combineReducers({
  byId,
  isFetching,
  isFetched,
  isCreating,
  isUpdating,
});
