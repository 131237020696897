import { combineReducers } from 'redux';

import byId from './byId/';
import bySystem from './bySystem/';


export default combineReducers({
  byId,
  bySystem,
});
