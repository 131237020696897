import TabsHeader from 'components/UserEditForm/Header';
import { Outlet, useParams } from 'react-router-dom';

export const DemographicsTabs = () => {
  const { chartId } = useParams();
  const tabsHead = [
    {
      name: 'Demographics',
      url: `/app/doctor/charts/${chartId}/demographics`,
      indexLink: true
    },
    {
      name: 'Forms',
      url: `/app/doctor/charts/${chartId}/demographics/forms`,
      indexLink: true
    },
  ];

  return (
    <div className='child-tabs-wrapper'>
      <TabsHeader links={tabsHead} />
      <Outlet />
    </div>
  );
};