import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { drChronoSSO } from 'modules/user/auth/actions';
import { fetchUser } from 'modules/user/actions';

export const useDrChronoSSO = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drChronoSSOWrapper = async (code) => {
    await dispatch(drChronoSSO(code));

    navigate('/app/doctor');
    await dispatch(fetchUser());
  };

  return {
    ssoLogin: drChronoSSOWrapper
  };
};