import filterSeatsByUserIdAndBillingPlan from 'helpers/seats/filters/by-user-id-and-billing-plan';
import isEmpty from 'helpers/common/array/is-empty';


const isUserAssignedToSeat = (seats, userId, billingPlan) => {
  const seatForCurrentUserAndPlan = filterSeatsByUserIdAndBillingPlan(seats, userId, billingPlan);

  return !isEmpty(seatForCurrentUserAndPlan);
};

export default isUserAssignedToSeat;
