import React from 'react';
import PropTypes from 'prop-types';

import cx from './ElementEditButton.module.scss';

const styles = className => `${cx.wrapper} ${className}`;

const ElementEditButton = props => {
  const { onClick, diameter = 32, className = '' } = props;

  const resolvedStyles = styles(className);

  return (
    <button
      type="button"
      className={resolvedStyles}
      onClick={onClick}
      style={{
        width: diameter,
        height: diameter,
      }}
    />
  );
};

ElementEditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  diameter: PropTypes.number,
};

export default ElementEditButton;
