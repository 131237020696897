import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import ChartingStepsContainer from 'containers/ChartingStepsContainer';
import medicationsContainer, { fetchLayerHOC as medicationsFetchLayer } from 'containers/Medications/MedicationsContainer';
import diagnosesContainer, { withDiagnosesFetchLayer } from 'containers/Diagnoses/DiagnosesContainer';
import vitalsContainer, { fetchLayerHOC } from 'containers/VitalsContainer';
import progressNoteHeaderContainer from 'containers/ProgressNoteHeaderContainer';

import VitalsComponent from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals';
import Loader from 'components/Loader';
import ProgressNoteHeader from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNoteHeader';
import Charting from 'routes/routes/App/routes/Charts/components/ProgressNote/Charting';

import { getChartById } from 'modules/charts/selectors';

import stringParser from 'helpers/common/string/string-parser';

import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNote/ProgressNote.module.scss';
import socialSystemsContainer from 'containers/FMSS/Social/SocialSystemConnect';
import { fetchLayerHOC as socialSystemsFetchLayer } from 'containers/FMSS/Social/SocialSystemsContainer';
import familyContainer, { familyFetchLayer } from 'containers/FMSS/Family/FamilyContainer';

const ProgressNoteHeaderContainer = compose(
  progressNoteHeaderContainer
)(ProgressNoteHeader);

const VitalsContainer = compose(
  vitalsContainer,
  fetchLayerHOC
)(VitalsComponent);

const MedicationsWrapper = compose(
  socialSystemsContainer,
  socialSystemsFetchLayer,
  familyContainer,
  familyFetchLayer,
  medicationsContainer,
  medicationsFetchLayer
)(Charting);

const MedicationsComponent = props => (
  <MedicationsWrapper
    {...props}
    filter="active"
  />
);

const DiagnosesWrapper = compose(
  diagnosesContainer,
  withDiagnosesFetchLayer
)(MedicationsComponent);
const DiagnosesComponent = props => (
  <DiagnosesWrapper
    {...props}
    filter="active"
  />
);

const ConnectedCharting = ChartingStepsContainer(DiagnosesComponent);

const ProgressNote = (props) => {
  const {
    chart,
    chartId
  } = props;
  const { permissions } = useContext(AppContext);

  if (chart.isFetching || !chart.isFetched) {
    return (
      <Loader />
    );
  }

  if (!permissions.viewEditEncounterNote && permissions.viewEditPatientClinicalInformation) {
    return (
      <VitalsContainer
        chartId={stringParser(chartId)}
        // if standalone is true then vitals is not a popup
        standalone
      />
    );
  }

  return (
    <div className={cx.wrapper}>
      <ProgressNoteHeaderContainer
        chartId={stringParser(chartId)}
        chart={chart}
      />
      <ConnectedCharting chartId={stringParser(chartId)} />
    </div>
  );
};

ProgressNote.propTypes = {
  chart: PropTypes.object.isRequired,
  chartId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  chart: getChartById(state.charts, ownProps.chartId),
  ...ownProps,
});

export default connect(mapStateToProps, null)(ProgressNote);
