import { useState } from 'react';
import { isNullOrUndefined } from 'helpers';

const useChooseUser = ({ defaultUser = null, defaultClinic = null }) => {
  const [selectedClinic, setSelectedClinic] = useState(defaultClinic)
  const [selectedUser, setSelectedUser] = useState(defaultUser)

  const isNotEmptyUserAndClinic = () =>
    !isNullOrUndefined(selectedUser) && !isNullOrUndefined(selectedClinic)

  return {
    selectedClinic,
    setSelectedClinic,
    selectedUser,
    setSelectedUser,
    isNotEmptyUserAndClinic
  }
}

export default useChooseUser
