import isEmpty from 'helpers/common/array/is-empty';

const mapObjectsByParam = (objectsArray, param) =>
  (isEmpty(objectsArray)
    ? []
    : objectsArray
      .filter(o => o[param])
      .map(o => o[param]));

export default mapObjectsByParam;
