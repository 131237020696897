import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';

import cx from './PatientLine.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';


const patientLineWrapperStyles = (patient) =>
    cssClassesResolver([
      cx['wrapper'], cx[!patient?.active && 'wrapper--inactive']
    ])

const PatientLine = ({
  patient,
  router,
  fields,
}) => (
  <tr className={patientLineWrapperStyles()} onClick={() => router.push(`/app/doctor/patients/${patient.id}`)}>
    <td style={{ width: fields[0].width }}>{patient['patient id']}{`${(!patient.active && ' (Inactive)') || ''}`}</td>
    <td style={{ width: fields[1].width }}>{patient.first}</td>
    <td style={{ width: fields[2].width }}>{patient.last}</td>
    <td style={{ width: fields[3].width }}>{patient.dob}</td>
    <td style={{ width: fields[4].width }}>{patient.phone}</td>
    <td style={{ width: fields[5].width }}>{patient['last encounter']}</td>
  </tr>
);

PatientLine.propTypes = {
  patient: PropTypes.shape({
    active: PropTypes.bool.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(PatientLine);
