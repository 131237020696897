import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Tether from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './Rv.module.scss';

import ShowMoreIcon from './show-more-icon.png';

const RV_PADDING = 25;

const fullRvStyles = resolveStylesV2({
  objectStyles: cx,
  moduleStyles: 'full',
  globalStyles: 'break-text'
});

const Rv = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const shortRv = useRef(null);
  const actualRv = useRef(null);
  const inputRef = useRef(null);

  const toggleRv = () => {
    setIsOpen(prev => !prev);
  };

  const dropdownRef = useClickOutSide({ onOutsideClick: toggleRv, exclude: [inputRef] });

  const checkIfHasMore = () =>
    setHasMore(actualRv?.current?.offsetWidth > shortRv?.current?.offsetWidth - RV_PADDING);

  useEffect(() => {
    checkIfHasMore();

    window.addEventListener('resize', checkIfHasMore);

    return function cleanUp() {
      window.removeEventListener('resize', checkIfHasMore);
    };
  });

  const { value, onClickText } = props;

  return (
    <span
      className={cx.wrapper}
    >
      <Tether
        attachment="bottom center"
        targetAttachment="top center"
        constraints={[{
          to: 'window',
          attachment: 'together',
        }]}
        classPrefix="fullRv"
        renderTarget={targetRef => {
          inputRef.current = targetRef.current;
          return (
            <div ref={shortRv}>
              <span
                className={cx['overflow-container']}
                ref={targetRef}
                onClick={hasMore ? toggleRv : undefined}
                style={{
                  paddingRight: RV_PADDING,
                }}
              >
                <span
                  ref={actualRv}
                  onClick={onClickText}
                >
                    {value}
                </span>
                {hasMore && (
                  <img
                    alt="More"
                    src={ShowMoreIcon}
                    className={cx['show-more-icon']}
                  />
                )}
              </span>
            </div>
          );
        }}
        renderElement={elementRef => isOpen && hasMore && (
          <span ref={dropdownRef}>
            <span
              ref={elementRef}
              className={fullRvStyles}
            >
              {value}
            </span>
          </span>
        )}
      />
    </span>
  );
};

Rv.propTypes = {
  value: PropTypes.string.isRequired,
  onClickText: PropTypes.func,
};

export default Rv;
