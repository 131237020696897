import { SYSTEM_GROUPS } from 'helpers/completion/combined-systems';

export default class SystemsGroupCompletionHandler {
  constructor(activeSystems, chartingStepId, currentSession) {
    this.activeSystems = activeSystems;
    this.chartingStepId = chartingStepId;
    this.currentSession = currentSession;
    this.combinedSystemsCompletion = (SYSTEM_GROUPS[chartingStepId] && SYSTEM_GROUPS[chartingStepId].completion) || 0;
    this._filteredActiveSystems = activeSystems;
  }

  calculateCompletion = () => {
    const groupForStep = SYSTEM_GROUPS[this.chartingStepId];

    if (!groupForStep || !this.activeSystems) {
      return this.getDefaultCompletion();
    }

    const { systems } = groupForStep;
    const activeSystemsIncludedInGroups = this.activeSystems.filter(activeSystem => systems.includes(activeSystem.id));
    if (activeSystemsIncludedInGroups.length) {
      const isPresentElementsInSystemsGroup = activeSystemsIncludedInGroups.some(activeSystem => !!this.currentSession[activeSystem.id].length);

      if (isPresentElementsInSystemsGroup) {
        this._filteredActiveSystems = this.activeSystems.filter(activeSystem => !systems.includes(activeSystem.id));
        return this.combinedSystemsCompletion;
      }

      return this.getDefaultCompletion();
    }

    return this.getDefaultCompletion();
  };

  get filteredActiveSystems() {
    return this._filteredActiveSystems;
  }

  getDefaultCompletion = () => {
    return 0;
  };
}
