import React from 'react';
import { connect } from 'react-redux';

import * as actions from 'modules/systemNotes/actions';
import { saveChart } from 'modules/chartingSessions/actions'
import * as selectors from 'modules/systemNotes/selectors';

import { assembleNoteId } from 'modules/systemNotes/helpers';

export const fetchLayerHOC = Component => (
  class SystemNoteFetchLayer extends React.Component {
    componentDidMount() {
      const {
        isSystemNoteFetching,
        isCurrentNoteFetched,
        fetchSystemNote,
        activeSystem,
        chartId,
      } = this.props;

      if (isSystemNoteFetching || isCurrentNoteFetched || !activeSystem) return;

      const systemIds = [activeSystem];

      fetchSystemNote({ systemIds, chartId });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        isCurrentNoteFetched,
        isSystemNoteFetching,
        fetchSystemNote,
        activeSystem,
        chartId,
      } = nextProps;

      if (isSystemNoteFetching || isCurrentNoteFetched || !activeSystem) return;

      const systemIds = [activeSystem];

      fetchSystemNote({ systemIds, chartId });
    }

    render() {
      return <Component {...this.props} />;
    }
  }
);

const mapStateToProps = (state, ownProps) => {
  const {
    activeSystem,
    systemId,
    chartId,
    system,
  } = ownProps;

  let id;

  if ('system' in ownProps) {
    id = system.id;
  }

  const _systemId = activeSystem || systemId || id;

  const systemNoteId = assembleNoteId({ chartId, systemId: _systemId });

  return {
    isSystemNotesFetched: selectors.getIsFetching(state.systemNotes),
    isCurrentNoteFetched: selectors.getNoteIsFetched(state.systemNotes, systemNoteId),
    isSystemNoteFetching: selectors.getIsFetching(state.systemNotes),
    isSystemNoteSaving: selectors.getIsSaving(state.systemNotes),
    systemNote: selectors.getSystemNote(state.systemNotes, systemNoteId),
    systemNotes: selectors.getSystemNotes(state.systemNotes),
  };
};

const mapActionCreators = {
  ...actions,
  saveChart
};

export default component => connect(mapStateToProps, mapActionCreators, null, { withRef: true })(component);
