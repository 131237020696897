import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import ChartReportFMSSWrapperContainer from 'containers/FMSS/Report';

import familyContainer, { familyFetchLayer } from 'containers/FMSS/Family/FamilyContainer';
import ChartReportFamilyComposer from './ChartReportFamily';

import medicalContainer, { medicalFetchLayer } from 'containers/FMSS/Medical/MedicalContainer';
import ChartReportMedical from './ChartReportMedical';

import surgicalContainer, { fetchLayerHOC as surgicalFetchLayerHOC } from 'containers/FMSS/Surgical/SurgicalContainer';
import ChartReportSurgicalComposer from './ChartReportSurgical';

import screeningContainer, { ScreeningFetchLayer } from 'containers/FMSS/Screening/ScreeningContainer';
import ChartReportScreeningComposer from './ChartReportScreening';

import { fetchLayerHOC as socialFetchLayerHOC } from 'containers/FMSS/Social/SocialSystemsContainer';
import socialSystemsContainer from 'containers/FMSS/Social/SocialSystemConnect';
import ChartReportSocial from './ChartReportSocial';
import isEmpty from 'helpers/common/array/is-empty';
import ChartReportHeadline from 'components/ChartReport/ChartReportBody/ChartReportHeadline';
import ChartReportSection from 'components/ChartReport/ChartReportBody/ChartReportSection';

const Family = compose(
  familyContainer,
  familyFetchLayer
)(ChartReportFamilyComposer);

const Medical = compose(
  medicalContainer,
  medicalFetchLayer,
)(ChartReportMedical);

const Surgical = compose(
  surgicalContainer,
  surgicalFetchLayerHOC,
)(ChartReportSurgicalComposer);

const Screening = compose(
  screeningContainer,
  ScreeningFetchLayer,
)(ChartReportScreeningComposer);

const Social = compose(
  socialSystemsContainer,
  socialFetchLayerHOC,
)(ChartReportSocial);

const ChartReportFMSS = (props) => {
  const {
    patientId,
    chartId,
    toggleChartReport,
    viewMode,
    chart,
    familyItems = [],
    medicalItems = [],
    socialItems = [],
    surgicalItems = [],
    screeningItems,
    hasNoPertinentFamily = false,
    hasNoPertinentMedical = false,
    hasNoPreviousSurgeries = false,
    showFMSS,
    showScreening,
    sectionName
  } = props;

  const isShowFamily = !isEmpty(familyItems) || hasNoPertinentFamily;
  const isShowMedical = !isEmpty(medicalItems) || hasNoPertinentMedical;
  const isShowSocial = !isEmpty(socialItems);
  const isShowSurgical = !isEmpty(surgicalItems) || hasNoPreviousSurgeries;
  const isShowScreening = showScreening && !isEmpty(screeningItems);

  const isShowFMSSSection = isShowFamily || isShowMedical || isShowSocial || isShowSurgical || isShowScreening;

  if (!isShowFMSSSection) {
    return <></>;
  }

  return (
    <ChartReportSection>
      <ChartReportHeadline>
        {sectionName}
      </ChartReportHeadline>

      <div>
        {showFMSS && (
          <span>
            {isShowFamily && (
              <Family
                patientId={patientId}
                chartId={chartId}
                toggleChartReport={toggleChartReport}
                viewMode={viewMode}
              />
            )}
            {isShowMedical && (
              <Medical
                patientId={patientId}
                chartId={chartId}
                toggleChartReport={toggleChartReport}
                viewMode={viewMode}
              />
            )}
            {isShowSocial && (
              <Social
                active
                chart={chart}
                patientId={patientId}
                chartId={chartId}
                toggleChartReport={toggleChartReport}
                viewMode={viewMode}
              />
            )}
            {isShowSurgical && (
              <Surgical
                patientId={patientId}
                chartId={chartId}
                toggleChartReport={toggleChartReport}
                viewMode={viewMode}
              />
            )}
          </span>
        )}
        {isShowScreening && (
          <Screening
            patientId={patientId}
            chartId={chartId}
            toggleChartReport={toggleChartReport}
            viewMode={viewMode}
          />
        )}
      </div>
    </ChartReportSection>
  );
};

ChartReportFMSS.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  toggleChartReport: PropTypes.func,
  viewMode: PropTypes.bool,
  chart: PropTypes.object,
  familyItems: PropTypes.array,
  medicalItems: PropTypes.array,
  socialItems: PropTypes.array,
  surgicalItems: PropTypes.array,
  screeningItems: PropTypes.array,
  hasNoPertinentFamily: PropTypes.bool,
  hasNoPertinentMedical: PropTypes.bool,
  hasNoPreviousSurgeries: PropTypes.bool,
  showScreening: PropTypes.bool.isRequired,
  showFMSS: PropTypes.bool.isRequired,
  sectionName: PropTypes.string.isRequired
};

const ChartReportFMSSWrapper = compose(
  ChartReportFMSSWrapperContainer,
)(ChartReportFMSS);

export default ChartReportFMSSWrapper;
