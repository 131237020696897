import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  listPayments: [],
  isFetching: false,
  isFetched: false,
};

export default handleActions({
  [constants.LIST_PAYMENTS_FETCH]: (state, action) => ({
    ...state,
    isFetching: true,
  }),
  [constants.LIST_PAYMENTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    listPayments: action.payload.result,
    isFetching: false,
    isFetched: true,
  }),
  [constants.LIST_PAYMENTS_FETCH_FAIL]: (state, action) => ({
    ...state,
    isFetching: false,
  }),
}, initialState);
