import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import PatientHistoryItemsTableContainer from 'containers/PatientHistoryItemsTable';
import Loader from 'components/Loader';

import { FMSS_TYPES } from 'helpers/fmss/constants';
import ProducerEmptyMessageFactory from 'helpers/fmss/additional-empty-message/factory/producer-factory';
import Base from 'helpers/fmss/additional-empty-message/factory/empty-message-entity/Base';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import { build } from 'helpers/URLUtils';

import fields from './fields';

import cx from './FamilyTable.module.scss';

const rowStyle = ({ familyPerson, selectedId, isAlive }) => cssClassesResolver([
  cx.item, isAlive && cx['item-no-padding'], familyPerson.id === selectedId && cx['item--active']
])

export const FamilyTable = (props) =>  {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const showFamilyPersonById = (id) =>
    navigate(
      build({
        pathname,
        query: { family: id },
      })
    );

  const {
    familyItems,
    isFetching,
    selectedId,
    error,
    fetchFamily,
    hasNoPertinentFamily,
    patientId
  } = props;

  if (isFetching) {
    return <Loader />;
  }

  const conditionalData = new Base(hasNoPertinentFamily);
  const emptyMessage = ProducerEmptyMessageFactory.getMessage(FMSS_TYPES.FAMILY, conditionalData);

  return (
    <PatientHistoryItemsTableContainer
      items={familyItems}
      defaultDesc={false}
      defaultSortBy="family"
      mapItemToField={item => ({
        id: item.id,
        family: item.familyPerson.name,
        disease: item.illnesses && item.illnesses.map(illness => illness.name),
        comment: item.description,
        alive: item.alive
      })}
      error={error}
      onReload={() => fetchFamily(patientId)}
      fields={fields}
      itemRenderer={familyPerson => (
        <tr
          key={familyPerson.id}
          className={rowStyle({ selectedId, familyPerson, isAlive: familyPerson?.alive })}
          onClick={() => showFamilyPersonById(familyPerson.id)}
        >
          <td className={cx['family-container']}>
            {familyPerson?.alive && (
              <div className={cx['family-alive']}>
                <span >A</span>
              </div>
            )}
                <span className={cx['family-label']} >{familyPerson.family}</span>
          </td>
          <td>{familyPerson.disease.map(illness => <div key={illness}>{illness}</div>)}</td>
          <td>{familyPerson.comment}</td>
        </tr>
      )}
      emptyMessage={emptyMessage}
    />
  );
};

FamilyTable.propTypes = {
  familyItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    familyPerson: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    illnesses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    alive: PropTypes.bool,
  })).isRequired,
  selectedId: PropTypes.number,
  fetchFamily: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasNoPertinentFamily: PropTypes.bool,
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

FamilyTable.defaultProps = {
  selectedId: undefined,
  error: undefined,
  isFetching: undefined,
  hasNoPertinentFamily: undefined,
};
