import sortBy from 'lodash/sortBy';

export const sortByDateAndAlphabetAbstract = (items, { getItemName }) =>
  sortBy(items, [item => item.startDate], [item => getItemName(item)]);


export const sortElementsByDateAndName = (elements, { getItemName }) => {
  let elementsWithNullDate = elements.filter(el => !el.startDate);
  let elementsWithNoNullDate = elements.filter(el => !!el.startDate);

  elementsWithNullDate = sortByDateAndAlphabetAbstract(elementsWithNullDate, { getItemName });
  elementsWithNoNullDate = sortByDateAndAlphabetAbstract(elementsWithNoNullDate, { getItemName });

  return [...elementsWithNullDate, ...elementsWithNoNullDate];
}
