import React, { useEffect } from 'react';
import { func } from 'prop-types';

const BoxClientFetchLayer = Component => {
  const useBoxClient = props => {
    const { fetchBoxClientId } = props;

    useEffect(() => {
      fetchBoxClientId();
    }, [fetchBoxClientId]);

    return <Component {...props} />;
  };

  useBoxClient.propTypes = {
    fetchBoxClientId: func.isRequired
  };

  return useBoxClient;
};

export default BoxClientFetchLayer;
