import { togglePertinentMedicalHx, togglePertinentFamilyHx, togglePreviousSurgeries, toggleDrugAllergies } from 'modules/patients/actions';

import { FMSS_TYPES } from 'helpers/fmss/constants';

export default class AbstractToggleCheckboxFactory {
  static getHxFactory = (hxType) => {
    switch (hxType) {
      case FMSS_TYPES.MEDICAL:
        return togglePertinentMedicalHx;
      case FMSS_TYPES.FAMILY:
        return togglePertinentFamilyHx;
      case FMSS_TYPES.SURGICAL:
        return togglePreviousSurgeries;
      case FMSS_TYPES.ALLERGY:
        return toggleDrugAllergies;
      default:
        throw Error('Additional checkbox is not present for that hx type');
    }
  }
}
