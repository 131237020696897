import * as fromOffices from './offices/selectors'

export const getDrChronoToken = state => state.drChronoAuth.token;

export const getIsAttached = state => state.drChronoAuth.isAttached;

export const getIsFetching = state => state.drChronoAuth.isFetching;

export const getIsFetched = state => state.drChronoAuth.isFetched;

export const isShouldShowWarning = state => state.shouldShowWarning;

export const getImportStatus = state => state.drChronoAuth.importData;

export const getOffices = state => fromOffices.getOffices(state.drChronoAuth.offices)

export const officeMap = office => ({ id: office.id, name: office.name, rooms: office.rooms })

export const officesMap = ({ providerNameDTO, drChronoOffices }) => ({
  offices: drChronoOffices.map(officeMap),
  providerId: providerNameDTO.id,
  providerName: providerNameDTO.name
})

export const getSelectedProviderId = state => fromOffices.getSelectedProvider(state.drChronoAuth.offices)

export const getSelectedOffice = state => fromOffices.getSelectedOffice(state.drChronoAuth.offices)

export const getSelectedOfficeRoom = state => fromOffices.getSelectedOfficeRoom(state.drChronoAuth.offices)

export const getRoomsByOffice = state => fromOffices.getRoomsBySelectedOffice(state.drChronoAuth.offices)
