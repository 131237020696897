import rightSideResourceFetchParserStrategyManager
  from 'helpers/resourses/fetchResoursesParsers/rightSideResourceFetchParserStrategyManager';

export default class ResourcesService {
  constructor(resourceType) {
    this.resourceType = resourceType;
  }

  fetchResourcesResponseParse = async resourceResponsePromise =>
    rightSideResourceFetchParserStrategyManager(this.resourceType)(resourceResponsePromise.body);
}
