import React, { useEffect, useState } from 'react';

import { stripWhiteListProps } from 'helpers';
import { whiteListProps } from 'helpers/preferences';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import ButtonSpinner from 'components/ButtonSpinner';
import MedSelect from 'components/MedSelect';
import { fetchTimezones } from 'api/timezones';
import LogoutTimeSelect from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/LogoutTimeSelect';
import DaylightSavingCheckbox from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/DaylightSavingCheckbox';
import ProgressNotesSettings from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/ProgressNotesSettings';

import cx from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/PreferencesForm/PreferencesForm.module.scss';

const logoutStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 160,
  heightControl: 28
});

const timeZoneStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 400,
  heightControl: 28
});

const PreferencesForm = props => {
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState(props.settings || { timeZone: '' });
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (!props.isSaving) {
      updateStateFromProps()
    }
  }, [props.settings, props.isSaving])

  const updateStateFromProps = () => {
    setSettings(props.settings)
  }

  const dirtyForm = () => {
    setFormChanged(true)
  }

  const formNotChanged = () => {
    setFormChanged(false)
  }

  const updateSettings = (key, value) => {
    setSettings({ 
      ...settings,
      [key]: value
    });
    dirtyForm()
  };

  const handleCheckboxChange = settingName => {
    updateSettings(settingName, !settings[settingName]);
  }

  const handleSelectChange = (settingName, option) => {
    if (!option) return;

    updateSettings(settingName, option.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    props.saveSettings(stripWhiteListProps(settings, whiteListProps));

    formNotChanged()
  };

  const onCancel = (e) => {
    e.preventDefault();

    updateStateFromProps();
  };

  const isDisabledSubmit = () => !formChanged;

  const closeModal = () => setShowModal(false);

  const confirm = () => {
    formNotChanged();
    closeModal()
  };

  const { timeZone, sessionTimeout } = settings;
  const { isSaving } = props;

  return (
    <form
      className={cx['preferences-form']}
      onSubmit={onSubmit}
    >
      <div className={cx['element-wrapper__logout-time']}>
        <span className={cx.label}>Auto logout time</span>
        <div className={cx['input-container']}>
          <LogoutTimeSelect
            value={sessionTimeout}
            onChange={option => handleSelectChange('sessionTimeout', option)}
            styles={logoutStyles}
          />
          <span className={cx['input-note']}>
              System automatically logs out after inactivity
          </span>
        </div>
      </div>

      <div className={cx.checkboxes}>
        <div>
          <ProgressNotesSettings
            onClick={handleCheckboxChange}
            settings={settings}
            settingLabels={[
                {
                  label: 'Unlock signed encounter note',
                  name: 'unlockSignedProgressNote',
                },
                {
                  label: 'Delete encounters',
                  name: 'deleteEncounters',
                },
              ]}
          />
        </div>

        <div>
          <ProgressNotesSettings
            onClick={handleCheckboxChange}
            settings={settings}
            title="Include in the Encounter Note"
            titleAlign="right"
            settingLabels={[
                {
                  label: 'Practice logo',
                  name: 'practiceLogo',
                },
                {
                  label: 'Practice address',
                  name: 'practiceAddress',
                },
                {
                  label: 'Practice phone number',
                  name: 'practicePhone',
                },
                {
                  label: 'Practice fax number',
                  name: 'practiceFax',
                },
                {
                  label: 'Patient\'s photo',
                  name: 'practicePatientPhoto',
                },
                {
                  label: 'Remove Ehrsynergy Logo',
                  name: 'removeEhrsynergyLogo',
                },
              ]}
          />
        </div>

        <div>
          <ProgressNotesSettings
              onClick={handleCheckboxChange}
              settings={settings}
              settingLabels={[
                  {
                    label: 'Show patient phone/email',
                    name: 'patientPhoneEmail',
                  }
                ]}
          />
        </div>
      </div>

      <section className={cx['element-group']}>
        <div className={cx['element-wrapper']}>
          <span className={cx.label}>Reset all passwords</span>
          <div className={cx['input-container']}>
            <button className={cx['reset-password-button']}>Reset</button>
          </div>
        </div>
        <div className={cx['element-wrapper']}>
          <span className={cx.label}>Time zone</span>
          <div className={cx['input-container']}>
            <MedSelect
              loadOptions={fetchTimezones}
              isCreatable={false}
              isClearable={false}
              optionMapper={fetchTimezones}
              value={timeZone}
              onChangeOptionMapper={timeZoneOption => timeZoneOption}
              onChange={option => handleSelectChange('timeZone', option)}
              styles={timeZoneStyles}
              valueMapper={value => ({ value, label: value })}
            />
          </div>
        </div>
        <div className={cx['element-wrapper']}>
          <div className={cx['input-container']}>
            <DaylightSavingCheckbox
              name="adjustClock"
              onClick={handleCheckboxChange}
              checked={settings.adjustClock}
            />
          </div>
        </div>
      </section>

      <div className={cx['controls-group']}>
        <button
          type="reset"
          className={cx.button}
          onClick={onCancel}
        >
          cancel
        </button>
        <button
          type="submit"
          value="Save"
          className={cx.save}
          disabled={isDisabledSubmit() || isSaving}
        >
          save
          {isSaving &&
            <ButtonSpinner />
          }
        </button>
      </div>
      <Modal
        data={{
          type: 'confirm',
          title: 'Unsaved changes',
          content: '<p>Are you sure you want to leave?</p><p class="warning-text"><strong>Warning:</strong> All changes will be discarded!</p>',
        }}
        isOpen={showModal}
        onModalClose={closeModal}
        onModalConfirm={confirm}
      />
    </form>
  );
}

const Wrapper = (props) => {
  const { isFetching, isFetched } = props;

  if (!isFetched || isFetching) {
    return <div className={cx['loader-wrapper']}><Loader /></div>;
  }

  return <PreferencesForm {...props} />;
};

export default Wrapper;
