import moment from 'moment';

import { DATE_FORMATS } from 'helpers/date/constants';
import { isNullOrUndefined } from 'helpers';

const compareDatesToDuplicateByYear = (firstDate, secondDate) => {
  if (isNullOrUndefined(firstDate) && isNullOrUndefined(secondDate)) {
    return true;
  }

  if (isNullOrUndefined(firstDate) || isNullOrUndefined(secondDate)) {
    return false;
  }

  const formattedFirstDateByYear = moment(firstDate).format(DATE_FORMATS.YEAR);
  const formattedSecondDateByYear = moment(secondDate).format(DATE_FORMATS.YEAR);

  return formattedFirstDateByYear === formattedSecondDateByYear;
};

export default compareDatesToDuplicateByYear;
