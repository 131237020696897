import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '../TableHead';
import cx from './FixedHeader.module.scss';

const FixedHeader = ({
  fields, style, sortBy, desc, onSort, hideSubtitleLine, children,
}) => {
  return (
    <div
      className={cx['fixed-header']}
      style={style}
    >
      {fields.map((field) => (
        <TableHead
          component="div"
          {...field}
          key={field.title}
          onClick={() => {
                if (!field.sortable || !onSort) return;
                onSort(field.title);
              }}
          desc={field.title === sortBy ? desc : undefined}
          hideSubtitleLine={hideSubtitleLine}
        />
      ))}
      {children}
    </div>
  );
};

FixedHeader.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    sortable: PropTypes.bool,
    width: PropTypes.string.isRequired,
  })),
  style: PropTypes.object,
  sortBy: PropTypes.string,
  desc: PropTypes.bool,
  onSort: PropTypes.func,
  hideSubtitleLine: PropTypes.bool,
};

export default FixedHeader;
