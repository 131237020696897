export default class ActiveBillingCode {
  constructor({
    id, description, favoriteId, name, ids = [],
  }) {
    this._id = id;
    this._description = description;
    this._favoriteId = favoriteId;
    this._name = name;
    this._ids = ids;
    this._cptCode = this._name;
  }

  getId = () => this._id

  getFavoriteId = () => this._favoriteId

  getDescription = () => this._description

  getCptCode = () => this._cptCode

  getIds = () => this._ids

  getName = () => this._name

  getFullName = () =>
    `${this._name} ${this._description}`
}
