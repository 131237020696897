import * as fromSingleSession from './singleSession/selectors';
import flatten from 'lodash/flatten';

export const getSocialSessionSystems = (state, patientId) =>
  ((state[patientId]
    && fromSingleSession.getSocialSessionSystems(state[patientId]))
    || []);

export const getSocialSession = (state, patientId) => {
  if (!state[patientId]) return {};
  const session = { ...fromSingleSession.getSocialSession(state[patientId]) };
  delete (session.isFetching);
  return session;
};

export const getEnclosedElementsForSystem = (state, patientId, systemId) =>
  ((state[patientId]
    && fromSingleSession.getEnclosedElementsForSystem(state[patientId], systemId))
    || []);

export const getIsSessionFetching = (state, patientId) => state[patientId] && fromSingleSession.getIsSessionFetching(state[patientId]);

export const getFlattedSocialSession = (socialSessions, patientId) => {
  const session = getSocialSession(socialSessions, patientId);
  return flatten(Object.values(session));
};
