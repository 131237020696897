import React, { useContext } from 'react';
import { func, shape, number, string } from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import MedSelect from 'components/MedSelect';
import { Option }  from './Option';

import { DATE_FORMATS } from 'helpers/date/constants';

import { searchPatientsByName } from 'api/patients';

const PatientSearchInput = (props) => {
  const getFullName = (firstName, lastName) => `${firstName  } ${  lastName}`;

  const { value = null, styles, onChange } = props;
  const { timeZoneDateConverter } = useContext(AppContext);

  const formatPatientFromOption = (option) => {
    if (!option) return undefined;

    return {
      id: option.value,
      fullName: option.label,
    };
  };

  const handleChange = (val) => {
    onChange(val);
  };

  const loadOptionsMapper = patients =>
    patients.map(patient => ({
      value: patient.id,
      label: getFullName(patient.firstName, patient.lastName),
      dob: timeZoneDateConverter.getFormattedDateWithTimeZone(patient.dob, DATE_FORMATS.DEFAULT),
    }));

  const valueMapper = valueOption => ({ value: valueOption.id, label: valueOption.fullName });

  return (
    <MedSelect
      replacementComponents={{ Option }}
      isFasterSearching
      placeholder="Type three chars"
      autoload={false}
      loadOptions={searchPatientsByName}
      onChange={handleChange}
      value={value}
      loadOptionsMapper={loadOptionsMapper}
      onChangeOptionMapper={formatPatientFromOption}
      valueMapper={valueMapper}
      styles={styles}
    />
  );
};

PatientSearchInput.propTypes = {
  onChange: func.isRequired,
  value: shape({
    id: number,
    fullName: string,
  }),
};

export default PatientSearchInput;
