import React, { useContext } from 'react';
import { bool, func, shape, number } from 'prop-types';

import { EditModeContext } from 'components/ElementsViewItem/context';

import ElementEditButton from 'components/ElementEditButton';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import { elementsViewItemActionsModalData } from './data';

import cx from './ElementsViewItemActions.module.scss';

const mergeButtonStyles = ({ isEditModeDoneBtnDisabled }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [
    'merge-element-button',
    'edit-mode-action--icon',
    isEditModeDoneBtnDisabled && 'edit-mode-action--green-selector'
  ]
})

const removeButtonStyles = resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['remove-element-button', 'edit-mode-action--icon']
})

const resetFavoritesButtonStyles = resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['reset-favorites-element-button', 'edit-mode-action--icon']
})

const ElementsViewItemActions = (props) => {
  const { currentEditModeAction, actions } = useContext(EditModeContext);
  const {
    openEditModeModal,
    setCurrentEditModeAction,
    handleMergeElements,
    isEditModeDoneBtnDisabled,
    changeEditButtonClickedStatus,
    setCurrentItemForEditMode,
    item,
    isElementsViewItemActionsButtonClicked
  } = props

  const handleResetFavorites = (e) => {
    e.preventDefault();

    openEditModeModal();
    setCurrentEditModeAction(elementsViewItemActionsModalData.resetFavorite.id);
  };

  const handleDeleteElement = (e) => {
    e.preventDefault();

    openEditModeModal();
    setCurrentEditModeAction(elementsViewItemActionsModalData.removeElement.id);
  };

  const handleMergeElementsWrapper = (e) => {
    e.preventDefault();

    if (isEditModeDoneBtnDisabled === false) {
      handleMergeElements();
    } else {
      setCurrentEditModeAction(elementsViewItemActionsModalData.mergeElements.id);
    }
  };

  const handleClick = () => {
    setCurrentItemForEditMode(item);
    changeEditButtonClickedStatus();
  };

  const isDisplayTooltip = () =>
    isElementsViewItemActionsButtonClicked === true;

  const isActiveMergeAction = () => {
    return currentEditModeAction === elementsViewItemActionsModalData.mergeElements.id;
  };

  const isDeleteActionDisabled = () => {
    return isActiveMergeAction();
  };

  const isResetFavoriteActionDisabled = () => {
    return isActiveMergeAction();
  };

  const resolvedStylesForMergeButton = mergeButtonStyles({ isEditModeDoneBtnDisabled: !isEditModeDoneBtnDisabled })

  return (
    <span>
      <span>
        <ElementEditButton
          onClick={handleClick}
        />
      </span>
      {isDisplayTooltip() && (
        <div className={cx.elementsViewItemActionsIcons}>
          {actions.includes(elementsViewItemActionsModalData.mergeElements.id) && (
            <button
              type="button"
              className={resolvedStylesForMergeButton}
              onClick={handleMergeElementsWrapper}
            />
          )}
          {actions.includes(elementsViewItemActionsModalData.removeElement.id) && (
            <button
              type="button"
              className={removeButtonStyles}
              onClick={handleDeleteElement}
              disabled={isDeleteActionDisabled()}
            />
          )}
          {actions.includes(elementsViewItemActionsModalData.resetFavorite.id) && (
            <button
              type="button"
              className={resetFavoritesButtonStyles}
              onClick={handleResetFavorites}
              disabled={isResetFavoriteActionDisabled()}
            />
          )}
        </div>
        )}
    </span>
  );
}

export default ElementsViewItemActions

ElementsViewItemActions.propTypes = {
  isElementsViewItemActionsButtonClicked: bool.isRequired,
  changeEditButtonClickedStatus: func.isRequired,
  openEditModeModal: func.isRequired,
  setCurrentEditModeAction: func.isRequired,
  setCurrentItemForEditMode: func.isRequired,
  item: shape({
    id: number
  }).isRequired,
  isEditModeDoneBtnDisabled: bool.isRequired,
  handleMergeElements: func.isRequired,
};
