import sortBy from 'lodash/sortBy';

export const formatFamilyItemForServer = (familyItem) => {
  const result = { ...familyItem };
  result.illnessIds = result.illnesses && result.illnesses.map(o => o.id);
  result.familyPersonId = result.familyPerson.id;
  delete result.illnesses;
  delete result.familyPerson;

  return result;
};

export const familyItemWhiteList = [
  // 'chartId',
  'id',
  'patientId',
  'familyPerson',
  'alive',
  'description',
  'illnesses',
];

export const sortFamilyItems = familyItems =>
  sortBy(familyItems, [item => item.familyPerson.name]);

export const sortIllnesses = illnesses =>
  sortBy(illnesses, [item => item.name]);
