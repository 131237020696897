import { useOutletContext } from 'react-router-dom';
import { compose } from 'redux';

import { ScreeningFormWrapper } from './components/ScreeningForm';
import UserEditForm from 'components/UserEditForm';
import {
  FMSSBaseWrapper
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/components/FMSSBaseWrapper';
import screeningContainer, { ScreeningFetchLayer } from 'containers/FMSS/Screening/ScreeningContainer';
import ScreeningTable from 'containers/FMSS/Screening/ScreeningTable';

const ScreeningTableWrapper = compose(
  FMSSBaseWrapper,
  screeningContainer,
  ScreeningFetchLayer,
)(ScreeningTable);

export const ScreeningComponent = () => {
  const { linkBase } = useOutletContext();

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Family Hx', indexLink: true },
        { url: { pathname: `${linkBase}/medical` }, name: 'Medical Hx' },
        { url: { pathname: `${linkBase}/social` }, name: 'Social Hx' },
        { url: { pathname: `${linkBase}/surgical` }, name: 'Surgical Hx' },
        { url: { pathname: `${linkBase}/screening` }, name: 'Screening Hx' },
      ]}
      formComponent={<ScreeningFormWrapper />}
      customLabelRenderer={null}
    >
      <ScreeningTableWrapper propName={'screeningItemId'} searchParamKey={'screening'} />
    </UserEditForm.Root>
  );
};

export const ScreeningRoute = {
  path: 'screening',
  element: <ScreeningComponent />
};
