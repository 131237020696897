import * as GENDERS from 'helpers/patient/gender/constants/list-constants';

export default new Map([
  [GENDERS.MALE, {
    value: GENDERS.MALE,
    label: 'Male',
  }],
  [GENDERS.FEMALE, {
    value: GENDERS.FEMALE,
    label: 'Female',
  }],
  [GENDERS.ASKU, {
    value: GENDERS.ASKU,
    label: 'Declined to Specify',
  }],
  [GENDERS.UNK, {
    value: GENDERS.UNK,
    label: 'Unknown',
  }],
  [GENDERS.OTHER, {
    value: GENDERS.OTHER,
    label: 'Other',
  }],
  [GENDERS.EMPTY, {
    value: 'EMPTY',
    label: GENDERS.EMPTY,
  }],
]);
