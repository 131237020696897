import React from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import medicationsContainer, { fetchLayerHOC } from 'containers/Medications/MedicationsContainer';
import { MedicationsList } from 'containers/Medications/MedicationsList';

const MedicationsContainer = compose(
  medicationsContainer,
  fetchLayerHOC
)(MedicationsList);

export const MedicationsListWrapper = () => {
  const { filter, patientId } = useParams();

  return (
    <MedicationsContainer
      patientId={patientId}
      filter={filter === 'inactive' ? 'inactive' : 'active'}
    />
  );
};
