import React from 'react';
import PropTypes from 'prop-types';

import useSearchPatient from 'hooks/useSearchPatient';

import cx from './SearchInput.module.scss';

const SearchInput = (props) => {
  const {
    searchQuery = '',
    isSafeSearching = false,
    onSearch,
    placeholder,
    clearSearchingResultsCallback,
  } = props;

  const {
    value,
    handleBlur,
    handleCancel,
    handleFocus,
    searchPatient
  } = useSearchPatient({ searchQuery, isSafeSearching, onSearch });

  const handleChange = (event) => {
    const { value:valueToSearch } = event.target;

    searchPatient(valueToSearch);
  };

  const handleClear = () => {
    handleCancel();
    clearSearchingResultsCallback();
  };

  return (
    <div className={cx['search-input']}>
      <input
        type="text"
        className={cx.input}
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {searchQuery && (
        <button
          type="button"
          className={cx.cancel}
          onClick={handleClear}
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  searchQuery: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  clearSearchingResultsCallback: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  isSafeSearching: PropTypes.bool,
};

export default SearchInput;
