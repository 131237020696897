import orderBy from 'lodash/orderBy';

export const formatMedicalForServer = (medicalItem) => {
  const result = { ...medicalItem };
  result.illnessId = result.illness.id;
  result.diagnosisIds = result.diagnosis && result.diagnosis.map(o => o.id);
  delete result.diagnosis;
  delete result.illness;
  delete result.diagnosis;

  return result;
};

export const medicalItemWhiteList = [
  'id',
  'patientId',
  'illness',
  'diagnosis',
  'description',
  'diagnosis',
  // 'chartId',
  'startDate',
];

export const sortMedicalItems = (medicalItems) => {
  return orderBy(medicalItems, [item => item.illness.name.toLowerCase()]);
};
