export const getIsFetching = state => state.boxService.isFetching;

export const getBoxService = state => {
  if (typeof state.boxService === 'object') {
    return Object.keys(state.boxService)
      .filter((key) => !['isFetching', 'isFetched', 'isSaving'].includes(key))
      .reduce((newObj, key) => {
        return {
          ...newObj,
          [key]: state.boxService[key]
        };
      }, {});
  }

  return { };
};
