import { handleActions } from 'redux-actions';

import * as userConstants from '../../constants';
import * as usersConstants from 'modules/users/constants';
import * as infoConstants from '../constants';

import { stripWhiteListProps } from 'helpers';

export const whiteListProps = [
  'id',
  'clinicId',
  'email',
  'providerId',
  'firstName',
  'lastName',
  'login',
  'npi',
  'signature',
  'suffix',
  'quickPassword',
  'agreementAccepted',
];

const initialState = {};

export default handleActions({
  [userConstants.USER_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...stripWhiteListProps(action.payload.result.user, whiteListProps),
  }),
  [usersConstants.USER_UPDATE_SUCCESS]: (state, action) => {
    if (state.id !== action.payload.user.id) return state;

    const { user } = stripWhiteListProps(action.payload, whiteListProps);

    return {
      ...state,
      ...user,
    };
  },

  [infoConstants.AGREEMENT_STATUS_UPDATE]: state => ({
    ...state,
    agreementAccepted: false,
  }),

  [infoConstants.AGREEMENT_STATUS_UPDATE_FAIL]: state => ({
    ...state,
    agreementAccepted: false,
  }),

  [infoConstants.AGREEMENT_STATUS_UPDATE_SUCCESS]: state => ({
    ...state,
    agreementAccepted: true,
  }),

}, initialState);
