import isPresentParamByKey from 'helpers/common/object/present-param-by-key';

const compareObjectsByParams = (firstObject, secondObject, comparasionParams) =>
  comparasionParams.every(param =>
    (isPresentParamByKey(firstObject, param) && isPresentParamByKey(secondObject, param)
      ? firstObject[param] === secondObject[param]
      : false)
  );

export default compareObjectsByParams;
