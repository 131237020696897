import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Textarea from 'react-textarea-autosize';
import { usePopper } from 'react-popper';

import debounce from 'lodash/debounce';
import striptags from 'vendor/striptags';

import { useClickOutSide } from 'hooks/useClickOutside';

import SearchResults from '../SearchResults';

import {
  search,
  isNullOrUndefined,
  parseElementNameWithVariables,
} from 'helpers';
import * as socialHelpers from 'helpers/social';
import { elementTemplateMap, hpChartTabs } from 'helpers/chart';
import orderByIterateesStrategy from 'helpers/common/sort/strategies/order-by-iteratees-strategies';
import isEmpty from 'helpers/common/array/is-empty';
import fetchCptResponseParser from 'helpers/resourses/fetchResoursesParsers/cptParser';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';
import hcpcParser from 'helpers/resourses/fetchResoursesParsers/hcpcParser';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { sortItems } from 'helpers/favorites/favoritesHelpers';
import stringParser from 'helpers/common/string/string-parser';
import { isCtrl, isEnter } from 'helpers/KeyboardUtils';

import { searchCptCodes, searchHcpcCodes, fetchHcpcCodes } from 'api/charts';
import {
  findElementBySystem,
  findElementByStep,
  rwSearch,
} from 'api/chartingAssets';

import cx from './SearchForm.module.scss';

const stylesss = ({ activeSystem }) => ({
  textArea: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: activeSystem ? 'input' : ['input', 'no-input'],
  }),
});

const SearchForm = (props) => {
  const [data, setData] = useState({
    value: '',
    isOpen: false,
    items: [],
    width: 0,
    stagedElement: undefined,
    setNumber: undefined,
    suggestions: [],
  });
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });
  const updateData = (newState) =>
    setData((prev) => ({ ...prev, ...newState }));
  const subelementsRef = React.createRef();
  let inputRef;
  const searchResultsRef = useClickOutSide({ onOutsideClick: () => closeSearchResults(true), exclude: [subelementsRef] });

  const debouncedDiagnosesSearch = useCallback(debounce(
    (systemId, input, favorites) => {
      return props
        .fetchResources({
          systemId,
          searchRequest: input,
          favorites,
          resourceType: 'diagnoses',
        })
        .then((res) => updateData({ items: res }));
    },
    400,
    { leading: true, trailing: true }
  ), []);

  const debouncedMedicationsSearch = useCallback(debounce(
    (systemId, input, favorites) => {
      return props
        .fetchResources({
          systemId,
          searchRequest: input,
          favorites,
          resourceType: 'medications',
        })
        .then((res) => setData((prev) => ({ ...prev, items: res })));
    },
    400,
    { leading: true, trailing: true }
  ), []);

  const debouncedCptSearch = useCallback(debounce(
    (systemId, input) => {
      return searchCptCodes(input).then((res) =>
        setData((prev) => ({
          ...prev,
          items: fetchCptResponseParser(res.body),
        }))
      );
    },
    400,
    { leading: true, trailing: true }
  ), []);

  const debouncedHcpcSearch = useCallback(debounce(
    (input) => {
      return searchHcpcStrategyManager(input)(input).then((res) => {
        setData((prev) => ({ ...prev, items: hcpcParser(res.body) }));
      });
    },
    400,
    { leading: true, trailing: true }
  ), []);

  const debouncedBaseSearchElements = useCallback(
    debounce(
      (value) => baseSearchElements(value),
      500,
      { leading: true, trailing: true }
    ),
[]);

  const baseSearchElements = (value) => {
    const { step, social } = props;

    if (social) {
      return [];
    }

    if (step === hpChartTabs.RV.step || step === hpChartTabs.HXRV.step) {
      return baseSearchElementsByStep(value);
    }

    return baseSearchElementsBySystem(value);
  };

  const baseSearchElementsBySystem = async (value) => {
    const { body } = await findElementBySystem({
      systemId: props.systemId,
      searchTerm: value,
    });

    updateData({ items: body });
  };

  const baseSearchElementsByStep = async (value) => {
    const { step, rvElementIds } = props;
    const payload = { step, searchTerm: value };
    if (isEmpty(rvElementIds) || step !== 1) {
      const { body } = await findElementByStep(payload);

      updateData({ items: body });
    } else {
      const { body } = await rwSearch({
        searchTerm: value,
        exclude: [0],
      });

      updateData({ items: body });
    }
  };

  const getNeedToAdd = (type) => {
    if (props.social === true) {
      return socialHelpers.getNeedToAddObject(true);
    }

    return { type };
  };

  const getFetching = ({ systemType, isFetching }) => {
    if (systemType === 1 || systemType === 2) {
      return isNullOrUndefined(isFetching) || isFetching;
    }

    return isNullOrUndefined(isFetching) || isFetching;
  };

  const searchHcpcStrategyManager = (input) =>
    input === '' ? fetchHcpcCodes : searchHcpcCodes;

  const handleChange = (event) => {
    const { value } = event.target;

    if (value?.length > 0) {
      handleSearch(value);
    }

    updateData({
      value,
      isOpen: true,
    });
  };

  const handleSearch = (value) => {
    const { systemType, broadMatch, exactMatch } = props;

    let favorites;

    // broadMatch and exactMatch are not present in social
    if (broadMatch && exactMatch) {
      favorites = Object.keys(broadMatch)
        .concat(Object.keys(exactMatch))
        .map((o) => stringParser(o));
    }

    switch (systemType) {
      case hpChartTabs.A.systems.ICD_TEN.type: {
        debouncedDiagnosesSearch(props.systemId, value, favorites);
        break;
      }
      case hpChartTabs.Plan.systems.PRESCRIPTIONS.type:
        debouncedMedicationsSearch(props.systemId, value, favorites);
        break;
      case BILLING_SYSTEMS.CPT.type:
        debouncedCptSearch(props.systemId, value);
        break;
      case BILLING_SYSTEMS.HCPC.type: {
        debouncedHcpcSearch(value, debouncedHcpcSearch);
        break;
      }
      default:
        debouncedBaseSearchElements(value);
        break;
    }
  };

  // if keepValue is true then input should not be cleared
  const closeSearchResults = (keepValue) => {
    updateData({
      isOpen: false,
      value: keepValue ? data.value : '',
      stagedElement: undefined,
      setNumber: undefined,
      stagedElementType: undefined,
    });
    inputRef.blur();
  };

  const createOrAddElement = async () => {
    const {
      allowCreation,
      isROS,
      addElement,
      createElement,
      step,
      systemType,
      items,
      exactMatch,
      broadMatch,
      systemId,
    } = props;

    const disableSubelements = systemType === 2;

    const { value } = data;

    // do not remove tags while searching
    let searchValue = value.trim();

    if (!searchValue.length) return;

    const results = sortItems(items, exactMatch, broadMatch, systemId);

    const type = isROS ? true : undefined;

    const firstFoundItem = results.length && {
      id: results[0].id,
      type,
      name: results[0].name,
    };

    if (
      !disableSubelements &&
      firstFoundItem?.name?.includes('??')
    ) {
      const { setNumber } = parseElementNameWithVariables(firstFoundItem.name)
        .variables[0];
      updateData({
        stagedElement: firstFoundItem.id,
        setNumber,
        stagedElementType: type,
      });
      return;
    }

    if (firstFoundItem) {
      closeSearchResults();
      addElement(firstFoundItem);
      return;
    }

    searchValue = striptags(searchValue, Object.keys(elementTemplateMap));

    const elementToCreate = {
      name: searchValue,
      chartingId: step && parseInt(step, 10),
    };

    const elementToAdd = getNeedToAdd(type);

    if (!allowCreation) return;

    if (elementToCreate.name.includes('??')) {
      createElement(elementToCreate).then((res) => {
        const { setNumber } = parseElementNameWithVariables(
          elementToCreate.name
        ).variables[0];

        updateData({
          stagedElement: res.body.id || res.body,
          setNumber,
          stagedElementType: type,
        });
        return res;
      });
      return;
    }
    closeSearchResults();
    const { body } = await createElement(elementToCreate, elementToAdd);
    const elementId = body?.id || body;
    props?.selectElementCallback(elementId);
  };

  const handleKeyUp = (e) => {
    switch (e.keyCode) {
      case 13:
        // this.createOrAddElement();
        break;
      case 27:
        closeSearchResults();
        break;
      default:
        break;
    }
  };

  const handleInputKeyDown = (e) => {
    let { value } = data;

    if (isEnter(e) && isCtrl(e)) {
      value += '\n\n';

      updateData({ value });
    } else if (isEnter(e) && !e.ctrlKey) {
      e.preventDefault();
      // createOrAddElement();
    }
  };

  const createElementHandler = (elementToCreate, elementToAdd) => {
    // we should prevent adding element if it contains variable
    let elementToAddChanged;
    if (!elementToCreate.name.includes('??')) {
      closeSearchResults();
      elementToAddChanged = elementToAdd;
    }
    return props.createElement(elementToCreate, elementToAddChanged);
  };

  const {
    searchableItems,
    isROS,
    addElement,
    systemType,
    systemId,
    allElements,
    allowCreation,
    exactMatch = {},
    broadMatch = {},
    chartId,
    step,
    createSubelement,
    activeSystem,
    social,
    patientId,
    addIndexToAutoSet,
    useCheck,
    system,
    activeElements,
    selectElementCallback,
    selectSubElementCallback,
  } = props;

  const { value, isOpen, items } = data;

  const disableSubelements = systemType === 2;

  const fetching = getFetching(props);

  const itemsToExcludeActiveElements = !isEmpty(items)
    ? items
    : searchableItems;
  const elementsAccessedToSearch = itemsToExcludeActiveElements.filter(
    (item) =>
      !activeElements.some((activeElement) => activeElement.id === item.id)
  );

  const result = search(elementsAccessedToSearch, value, 'name');

  const sortedResults = orderByIterateesStrategy(result, [
    (item) => item.name.toUpperCase().indexOf(value.toUpperCase()),
    (item) => item.name.toUpperCase(),
  ]);

  const resolvedStyles = stylesss({ activeSystem });

  return (
    <div ref={searchResultsRef} className='relative' onKeyUp={handleKeyUp}>
      <div className={cx.wrapper} ref={setReferenceElement}>
        <div className={cx['input-wrapper']}>
          <div className={cx.underline} />
          <Textarea
            value={value}
            onChange={handleChange}
            ref={ref => (inputRef = ref)}
            className={resolvedStyles.textArea}
            placeholder='Enter / Select'
            onFocus={handleChange}
            // we should add first found element on Enter key
            // and not go to the next line
            onKeyDown={handleInputKeyDown}
          />
          {fetching && <div className={cx.loader} />}
        </div>
      </div>
      {isOpen && (
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 7, width: '100%' }}
          {...attributes.popper}
        >
          {/*<EventListenerLayer*/}
          {/*  onClickOutSide={() => closeSearchResults(true)}*/}
          {/*  connectedNodes={[wrapper, subelementsRef]}*/}
          {/*>*/}
            <SearchResults
              systemType={systemType}
              selectElementCallback={selectElementCallback}
              selectSubElementCallback={selectSubElementCallback}
              system={system}
              items={sortedResults}
              style={{ width: 'auto' }}
              searchValue={value}
              isROS={isROS}
              disableSubelements={disableSubelements}
              addElement={(element) => {
                closeSearchResults();
                return addElement(element);
              }}
              allElements={allElements}
              createElement={createElementHandler}
              allowCreation={allowCreation}
              fetching={fetching}
              exactMatch={exactMatch}
              broadMatch={broadMatch}
              systemId={systemId}
              step={step}
              chartId={chartId}
              ref={subelementsRef}
              stagedElement={data.stagedElement}
              setNumber={data.setNumber}
              stagedElementType={data.stagedElementType}
              createSubelement={createSubelement}
              social={social}
              patientId={patientId}
              addIndexToAutoSet={addIndexToAutoSet}
              useCheck={useCheck}
            />
            <div ref={setArrowElement} style={styles.arrow} />
          {/*</EventListenerLayer>*/}
        </div>
      )}
    </div>
  );
};

SearchForm.propTypes = {
  createSubelement: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  allElements: PropTypes.array.isRequired,
  useCheck: PropTypes.bool,
  chartId: PropTypes.number,
  createElement: PropTypes.func.isRequired,
  selectElementCallback: PropTypes.func,
  selectSubElementCallback: PropTypes.func,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

export default SearchForm;
