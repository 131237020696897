import React from 'react';
import { createPortal } from 'react-dom';

import { useAgreement } from 'components/AgreementModal/useAgreement';

import UserAgreement from 'components/Documents/UserAgreement';
import PrivacyPolicy from 'components/Documents/PrivacyPolicy';
import TermsOfUse from 'components/Documents/TermsOfUse';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './AgreementModal.module.scss';
import modalCx from 'components/Modal/Modal.module.scss';

const WIDTH = '80%';

const AgreementModal = () => {
  const { isAccepted, accept, decline } = useAgreement();

  return createPortal(
    <div className={cssClassesResolver([
      modalCx['modal-window'],
      !isAccepted && modalCx['modal-window--active']
    ])}>
      <div className={modalCx['modal-window-container']}>
        <div className={modalCx['modal-window-overlay']}/>
        <div
          className={cx['modal-window-content']}
          style={{
            width: WIDTH,
          }}
        >
          <div className={cx['modal-window-item']}>
            <div className={cx['modal-window-data']}>
              <UserAgreement/>
              <PrivacyPolicy/>
              <TermsOfUse/>
            </div>

            <div className={cx['modal-window-controls']}>
              <button type='button' className={modalCx.button} onClick={accept}>Confirm</button>
              <button type='button' className={modalCx.button} onClick={decline}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.getElementById('root'));
};

export default AgreementModal;
