export const ROLES_FETCH = 'ROLES_FETCH';
export const ROLES_FETCH_SUCCESS = 'ROLES_FETCH_SUCCESS';
export const ROLES_FETCH_FAIL = 'ROLES_FETCH_FAIL';

export const ROLE_REMOVE = 'ROLE_REMOVE';
export const ROLE_REMOVE_SUCCESS = 'ROLE_REMOVE_SUCCESS';
export const ROLE_REMOVE_FAIL = 'ROLE_REMOVE_FAIL';

export const ROLE_CREATE = 'ROLE_CREATE';
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS';
export const ROLE_CREATE_FAIL = 'ROLE_CREATE_FAIL';

export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_FAIL = 'ROLE_UPDATE_FAIL';
