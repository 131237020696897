import { hpChartTabs } from 'helpers/chart';
import { ROS_SYSTEMS, PE_SYSTEMS } from 'helpers/systems/ids';

export const SYSTEM_GROUPS = {
  [hpChartTabs.ROS.step]: {
    systems: [
      ROS_SYSTEMS.EARS.id,
      ROS_SYSTEMS.NOSE.id,
      ROS_SYSTEMS.THROAT.id,
      ROS_SYSTEMS.ENT.id,
    ],
    completion: 1,
  },
  [hpChartTabs.PE.step]: {
    systems: [
      PE_SYSTEMS.EARS.id,
      PE_SYSTEMS.NOSE.id,
      PE_SYSTEMS.THROAT.id,
      PE_SYSTEMS.ENT.id,
    ],
    completion: 1,
  },
};
