import React from 'react';
import { compose } from 'redux';

import paymentsPlansDescriptionContainer from 'containers/PaymentsPlansDescriptionContainer';
import { fetchLayerHOC } from 'containers/UserPaymentInfoContainer';

import UserEditForm from 'components/UserEditForm';
import PaymentsPlansList from '../PaymentsPlansList';
import PaymentsPlansDescription from '../PaymentsPlansDescription';

import cx from './PaymentsPlansTab.module.scss';

const PaymentsPlansFormWrapper = compose(
  paymentsPlansDescriptionContainer,
  fetchLayerHOC
)(PaymentsPlansDescription);

export const PaymentsPlansTab = () => {
  return (
    <div className={cx.wrapper}>
      <UserEditForm.PlanCard>
        <PaymentsPlansList />
      </UserEditForm.PlanCard>
      <UserEditForm.PlanDescription>
        <PaymentsPlansFormWrapper />
      </UserEditForm.PlanDescription>
    </div>
  );
};
