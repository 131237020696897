import React from 'react';
import PropTypes from 'prop-types';

import orderBy from 'lodash/orderBy';

import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import EmptyMessage from 'components/UserEditForm/EmptyMessage';

import { severityItems, onsetItems } from '../helpers';
import { isNullOrUndefined } from 'helpers';
import EmptyMessageFactory from 'helpers/fmss/allergies/empty-message/factory';
import NoDrugAllergies from 'helpers/fmss/allergies/no-drug-allergies';

import fields from './fields';

import cx from './AllergiesTable.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

export default class AllergiesTable extends React.Component {
  static propTypes = {
    filter: PropTypes.string.isRequired,
    hasNoDrugAllergies: PropTypes.bool.isRequired,
  };

  state = {
    sortBy: 'allergy',
    desc: false,
  };

  sort = (column) => {
    if (this.state.sortBy === column) {
      this.setState({ desc: !this.state.desc });
      return;
    }
    this.setState({
      sortBy: column,
      desc: false,
    });
  };

  render() {
    const {
      items,
      selectedId,
      showAllergyById,
      error,
      filter,
      hasNoDrugAllergies,
      onReload,
    } = this.props;

    const { sortBy, desc } = this.state;

    const direction = desc ? 'desc' : 'asc';

    const { sort } = this;

    const noDrugAllergiesService = new NoDrugAllergies(filter, hasNoDrugAllergies);
    const isPresentNoDrugAllergies = noDrugAllergiesService.isPresent();
    const emptyMessageFactory = new EmptyMessageFactory(isPresentNoDrugAllergies, filter);
    const emptyMessage = emptyMessageFactory.getMessage();

    const renderedItems = orderBy(items.map(item => ({
      id: item.id,
      allergy: item.allergy.name,
      severity: !isNullOrUndefined(item.severity) && severityItems[item.severity].name,
      reaction: item.reactions && item.reactions.map(reaction => reaction.name).join(', '),
      onset: !isNullOrUndefined(item.onset) && onsetItems[item.onset].name,
    })), sortBy, direction);

    if (error) {
      return (
        <EmptyMessage>
          An error occured<br />
          <button
            className="generic-button"
            type="button"
            onClick={onReload}
          >
            Reload
          </button>
        </EmptyMessage>
      );
    }

    if (renderedItems.length) {
      return (
        <div className={cx['container']}>
          <Table>
            <thead>
              <tr>
                {fields.map(
                  field => (
                    <TableHead
                      {...field}
                      key={field.title}
                      onClick={() => sort(field.title)}
                      desc={field.title === sortBy ? desc : undefined}
                    />
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {renderedItems.map(
                item => (
                  <tr
                    className={
                      cssClassesResolver([
                        cx['item'], item.id === selectedId && cx['item--active']
                      ])
                    }
                    key={item.id}
                    onClick={() => showAllergyById(item.id)}>
                    <td>
                      <span className={cx['name']}>{item.allergy}</span>
                    </td>
                    <td>{item.severity}</td>
                    <td>{item.reaction}</td>
                    <td>{item.onset}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      );
    }

    return (
      <div>
        <EmptyMessage>{emptyMessage}</EmptyMessage>
      </div>
    );
  }
}
