import { connect } from 'react-redux';
import React from 'react';
import * as actions from 'modules/settings/actions';
import * as selectors from 'modules/settings/selectors';

export const fetchLayerHOC = (Component) => {
  return class SettingsFetchLayer extends React.Component {
    componentDidMount() {
      if (!this.props.isFetched) {
        this.props.fetchSettings();
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => ({
  settings: selectors.getSettings(state),
  isFetched: selectors.getIsFetched(state.settings),
  isFetching: selectors.getIsFetching(state.settings),
  isSaving: selectors.getIsSaving(state.settings),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
