import isEmpty from 'lodash/isEmpty';

import * as basicHelpers from 'helpers';

export const cardErrors = {
  number: 'Credit card number is not valid',
  cvv: 'CVV is not valid',
  expirationDate: 'Expiration date is not valid',
  postalCode: 'Zip code is not valid',
};

export const cardDataErrors = {
  number: null,
  expirationDate: null,
  cvv: null,
};

export const clearErrorsBeforeUpdate = (errors) => {
  if (basicHelpers.isNullOrUndefined(errors) || isEmpty(errors)) {
    return cardDataErrors;
  }

  const newCardDataErrors = { ...errors };

  Object.keys(cardDataErrors).forEach((errorField) => {
    newCardDataErrors[errorField].message = null;
  });

  return newCardDataErrors;
};

export const fillErrors = (listErrorFields) => {
  const newCardDataErrors = { ...cardDataErrors };

  clearErrorsBeforeUpdate(newCardDataErrors);

  listErrorFields.forEach((errorField) => {
    newCardDataErrors[errorField].message = cardErrors[errorField];
  });

  return newCardDataErrors;
};

export const iterateThroughBraintreeErrors = (invalidCardDataError) => {
  const detailsError = invalidCardDataError.details;

  if (detailsError === undefined) {
    const listErrorFields = Object.keys(cardErrors);
    return fillErrors(listErrorFields);
  }

  return fillErrors(detailsError.invalidFieldKeys);
};

export const isCardPresent = (card) => {
  if (card && card.last4) {
    return card.last4.length > 0;
  }

  return false;
};

export const getFormattedExpirationYear = (initialExpirationYear) => {
  if (basicHelpers.isNullOrUndefined(initialExpirationYear)) {
    return '';
  }

  return initialExpirationYear.toString().substr(-2);
};

export const getFormattedExpirationMonth = (initialExpirationMonth) => {
  if (basicHelpers.isNullOrUndefined(initialExpirationMonth)) {
    return '';
  }

  const initialExpirationMonthAsString = initialExpirationMonth.toString();

  if (initialExpirationMonthAsString.length === 1) {
    return 0 + '' + initialExpirationMonth;
  }

  return initialExpirationMonthAsString;
};

export const getExpirationCardDate = (month, year) => {
  const formattedExpirationMonth = getFormattedExpirationMonth(month);
  const formattedExpirationYear = getFormattedExpirationYear(year);

  if (formattedExpirationMonth === '' || formattedExpirationYear === '') {
    return '';
  }

  return formattedExpirationMonth + '/' + formattedExpirationYear;
};
