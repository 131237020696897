import React from 'react';
import { compose } from 'redux';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import diagnosesContainer, { withDiagnosesFetchLayer } from 'containers/Diagnoses/DiagnosesContainer';
import DiagnosesPage from 'containers/Diagnoses/DiagnosesPage';

const DiagnosesContainer = compose(
  chartToPatientContainer,
  diagnosesContainer,
  withDiagnosesFetchLayer
)(DiagnosesPage);

export const ChartDiagnosesPage = () => {
  const [searchParams] = useSearchParams();
  const { chartId } = useParams();

  return (
    <DiagnosesContainer
      filter="active"
      diagnosisId={searchParams.get('diagnosis')}
      linkBase={`/app/doctor/charts/${chartId}/diagnoses`}
    >
      <Outlet />
    </DiagnosesContainer>
  );
};
