import React from 'react';
import { compose } from 'redux';
import { Outlet } from 'react-router';

import PatientsContent from '../PatientsContent';

import { patientTabFetchLayerHOC } from 'containers/Patients/PatientsTabFetchLayer';
import PatientsConnect from 'containers/Patients/PatientsConnect';

import cx from './Patients.module.scss';

const PatientsContainer = compose(
  PatientsConnect,
  patientTabFetchLayerHOC
)(PatientsContent);

export const PatientsTab = () => {
  return (
      <div className={cx.wrapper}>
        <PatientsContainer/>
      </div>
  );
};

const Patients = () => (
  <div className={cx.wrapper}>
    <Outlet />
  </div>
);

export default Patients;
