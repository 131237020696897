import DefaultTimeZone from 'helpers/date/default-time-zone';

export default class TimeZone {
  constructor({ adjustClock = false, timeZone }) {
    this.adjustClock = adjustClock;
    this.timeZone = timeZone;
  }

  getTimeZone = () => {
    return (this.adjustClock && this.timeZone) || DefaultTimeZone.getDefaultTimeZone();
  }
}
