import React from 'react';
import cx from '../PageError/PageError.module.scss';

const PageEmpty = ({ message }) => {
  return (
    <div className={cx['no-data']}>
      <span>
          {message}
      </span>
    </div>
  );
};

export default PageEmpty;
