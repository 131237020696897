import { connect } from 'react-redux';
import React from 'react';
import * as actions from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';

import FetchLayer, { getMapStateToProps } from '../../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const surgicalItemContainer = Component => props => (
  <FetchLayer
    itemId={props.surgicalItemId}
    fetchItem={props.fetchSurgicalItem}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.SURGICAL, 'surgicalItem', 'surgicalItemId');

const mapActionCreators = {
  fetchSurgicalItem: actions.fetchSurgicalItem,
  deleteSurgicalItem: actions.deleteSurgicalItem,
  createSurgicalItem: actions.createSurgicalItem,
  updateSurgicalItem: actions.updateSurgicalItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(surgicalItemContainer(component));
