import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { components } from 'react-select';

import Loader from 'components/Loader';
import Modal from 'components/Modal';
import UserItem from 'components/SeatUsersDropdown/UserItem';

import { isNullOrUndefined } from 'helpers';
import SeatClearUserIconDisplayed from 'helpers/seats/SeatClearUserIconDisplayed';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import * as helpers from './helpers';

import cx from './SeatUsersDropdown.module.scss';

const seatStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  isDisplayDropdownIndicator: false,
  heightControl: 66,
  placeholder: {
    borderBottom: '1px solid #b3b3b3',
    width: '80%',
    textAlign: 'left'
  }
});

export default class SeatUsersDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSeatAgreementModalOpen: false,
      selected: null,
    };
  }

  componentDidMount() {
    const {
      seat,
      users,
    } = this.props;

    const currentUser = users.find(user => helpers.isUserPresentInSeat(user, seat));

    if (!currentUser) return;

    const userOption = helpers.formatOption(currentUser);

    this.setValue(userOption);
  }

  onChange = () => {
    const {
      handleSeatUserChange,
      seat,
    } = this.props;

    const {
      selected,
    } = this.state;

    const userId = selected !== null ? selected.value : null;

    const seatData = {
      userId,
      seatId: seat.id,
      name: selected.name,
    };

    handleSeatUserChange(seatData, seat)
      .then(() => {
        this.setValue(selected?.email ? selected : null);
      });

    this.removePreviousUserFromSeat();
    this.closeSeatAgreementModal();
  };

  setValue(value) {
    this.setState({ value });
  }

  getOptions = (userOptions) => {
    const { seat } = this.props;

    if (helpers.isOpenSeat(seat) || helpers.isLockFreeSeat(seat)) {
      return userOptions;
    }

    return [
      ...userOptions,
    ];
  };

  removePreviousUserFromSeat = () => {
    const { value } = this.state;

    if (!value) return;

    const { removeUserFromSeatLocally } = this.props;

    if (!isNullOrUndefined(value)) {
      removeUserFromSeatLocally(value);
    }
  };

  showSeatAgreementModal = (selected) => {
    this.setState({
      isSeatAgreementModalOpen: true,
      selected,
    });
  };

  closeSeatAgreementModal = () => {
    this.setState({
      isSeatAgreementModalOpen: false,
    });
  };

  buildSingleValue = (innerProps) => {
    return (
      <components.SingleValue {...innerProps}>
        <UserItem
          seatImage
          data={innerProps.data}
        />
      </components.SingleValue>
    );
  };

  isClearIconDisplayed = (seat) => {
    return SeatClearUserIconDisplayed.isDisplayed(seat.status);
  };

  SingleValue = (props) => this.buildSingleValue(props);

  render() {
    const {
      seat,
      users,
      seats,
      areSeatsSaving,
      disabled,
    } = this.props;

    const {
      value,
      isSeatAgreementModalOpen
    } = this.state;

    const filteredUsers = helpers.filterUnassignedUsers(users, seats);
    const userOptions = helpers.formatOptions(filteredUsers);

    const seatAgreementModalData =
      helpers.isOpenSeat(seat)
        ? helpers.seatAgreementModalData.addProvider
        : helpers.seatAgreementModalData.changeProvider;

    const isClearIconDisplayed = this.isClearIconDisplayed(seat);

    return (
      <div className={cx.wrapper}>
        {
          areSeatsSaving &&
            <div className={cx['loader-wrapper']}>
              <Loader />
            </div>
        }
        <Select
          components={{
            SingleValue: this.SingleValue,
            Option: (props) => (
              <components.Option {...props}>
                <UserItem data={props.data} seatImage={false} />
              </components.Option>
            ),
        }}
          className={cx['seat-user-select']}
          options={this.getOptions(userOptions)}
          value={value}
          onChange={this.showSeatAgreementModal}
          isDisabled={disabled}
          placeholder="Enter email..."
          isClearable={false}
          styles={seatStyles}
        />
        {isClearIconDisplayed && (
          <div className={cx['clear-container']}>
            <div
              className={cx['image-wrapper']}
              onClick={this.showSeatAgreementModal}
            />
          </div>
        )}

        <Modal
          data={{
                type: 'confirm',
                title: seatAgreementModalData.title,
                content: `${'<p><strong>Are you sure?</strong></p>' + '<p>'}${  seatAgreementModalData.content  }</p>`,
              }}
          size="medium"
          isOpen={isSeatAgreementModalOpen}
          onModalClose={this.closeSeatAgreementModal}
          onModalConfirm={this.onChange}
        />

      </div>
    );
  }
}

SeatUsersDropdown.propTypes = {
  users: PropTypes.array.isRequired,
  seat: PropTypes.object.isRequired,
  seats: PropTypes.object.isRequired,
  handleSeatUserChange: PropTypes.func.isRequired,
  areSeatsSaving: PropTypes.bool.isRequired,
  removeUserFromSeatLocally: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
