import AdditionalCheckboxDataTemplate from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxDataTemplate';

import { getFamilyItems } from 'modules/patientsHx/selectors';

export default class AdditionalFamilyCheckboxDataFactory {
  constructor({ patientId, state }) {
    this.patientId = patientId;
    this.state = state;
  }

  getData = () => {
    const additionalCheckboxDataTemplate = new AdditionalCheckboxDataTemplate({
      patientId: this.patientId,
      state: this.state,
      checkboxParamName: 'hasNoPertinentFamily',
      items: this.getItems(),
    });

    return additionalCheckboxDataTemplate.getMappedProps();
  };

  getItems = () => {
    return getFamilyItems(this.state.patientsHx, this.patientId);
  }
}
