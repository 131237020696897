import * as fromAuth from './auth/selectors';
import * as fromInfo from './info/selectors';
import * as fromPermissions from './permissions/selectors';
import * as fromByIsDownloadingSignature from './isDownloadingSignature/selectors';
import { createSelector } from 'reselect';

export const getIsAuthenticated = state => fromAuth.getIsAuthenticated(state.auth);

export const getIsSuperAdmin = state => fromAuth.getIsSuperAdmin(state.auth);

export const getIsAuthenticating = state => fromAuth.getIsAuthenticating(state.auth);

export const getLoginError = state => fromAuth.getLoginError(state.auth);

export const getLoginMessage = state => fromAuth.getLoginMessage(state.auth);

export const getSignupError = state => fromAuth.getSignupError(state.auth);

export const getSignupMessage = state => fromAuth.getSignupMessage(state.auth);

export const getConfirmError = state => fromAuth.getConfirmError(state.auth);

export const getConfirmMessage = state => fromAuth.getConfirmMessage(state.auth);

export const getRecoverError = state => fromAuth.getRecoverError(state.auth);

export const getRecoverMessage = state => fromAuth.getRecoverMessage(state.auth);

export const getRecoveryTokenValidationError = state => fromAuth.getRecoveryTokenValidationError(state.auth);

export const getRecoveryTokenValidationMessage = state => fromAuth.getRecoveryTokenValidationMessage(state.auth);

export const getUserInfo = createSelector(
  [state => state.info],
  info => info.data
);

export const isAgreementAccepted = createSelector(
  [state => state.user.info],
  info => info.data.agreementAccepted
);

export const getUserId = state => fromInfo.getUserId(state.info);

export const getPermissions = state => fromPermissions.getPermissions(state.permissions);

export const getIsUserFetched = state => state.isFetched;

export const getIsFetching = state => state.isFetching;

export const isDownloadingSignature = state => fromByIsDownloadingSignature.isDownloadingSignature(state.isDownloadingSignature);

export const isBlankClinic = createSelector(
  [state => state.info],
  info => {
    const { clinicId } = info.data;

    return !Number.isInteger(clinicId) || clinicId === 0;
  }
);

export const getProviderInitials = createSelector(
  [state => state.user.info.data],
  data => `${data.firstName[0]}. ${data.lastName[0]}.`.toUpperCase()
);