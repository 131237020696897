import React from 'react';

import DrChronoBtn from 'components/DrChronoBtn';

import modifyIFrameAccordingITP from 'helpers/drChrono/IFrameITPService';

export const checkCookieStorageAccess = (ChildComponent) => {
  return class CheckITP extends React.Component {
    state = {
      hasAccess: false,
      hasError: false,
    };

    componentDidMount() {
      this.checkStorageAccess();
    }

    setError = () =>
      this.setState({ hasError: true });

    denyAccess = () =>
      this.setState({ hasAccess: false });

    grantAccess = () =>
      this.setState({ hasAccess: true });

    checkStorageAccess = () => {
      try {
        this.tryToCheckStorageAccess();
      } catch (error) {
        this.denyAccess();
        this.setError();
      }
    };

    tryToCheckStorageAccess = () => {
      const promise = document.hasStorageAccess();
      promise.then(
        (hasAccess) => {
          !hasAccess && modifyIFrameAccordingITP();
          hasAccess && this.grantAccess();
        },
        () => this.denyAccess()
      );
    };

    requestAccess = () => {
      try {
        this.tryToRequestAccess();
      } catch (error) {
        this.denyAccess();
        this.setError();
      }
    };

    tryToRequestAccess = () => {
      const promise = document.requestStorageAccess();
      promise.then(
        () => {
          this.grantAccess();
          return Promise.resolve();
        },
        () => this.denyAccess()
      );
    };

    render() {
      const {
        hasAccess,
        hasError,
      } = this.state;

      const isErrorOccur = hasError && !hasAccess;
      const isGrantAccess = !hasError && hasAccess;
      const isAllowDisplayChild = isErrorOccur || isGrantAccess;

      if (isAllowDisplayChild) {
        return (
          <ChildComponent {...this.props} />
        );
      }

      return (
        <DrChronoBtn
          label="Allow cookie"
          onClick={this.requestAccess}
        />
      );
    }
  };
};
