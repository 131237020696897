import { isNullOrUndefined } from 'helpers';

export default class SocialInitialElement {
  static getInitialElement = () => {
    return {
      ...SocialInitialElement.getInitialSubElements(),
      ...SocialInitialElement.getInitialHistory(),
    };
  };

  static getInitialSubElements = () => {
    return {
      subIds: [],
    };
  };

  static getInitialHistory = () => {
    return {
      subElementHistoryJson: {
        numberGroupMap: {},
      },
    };
  };

  static initializeElement = (element) => {
    const {
      subElementHistoryJson,
      subIds,
    } = element;

    let newElement = { ...element };
    if (isNullOrUndefined(subIds)) {
      newElement = {
        ...newElement,
        ...SocialInitialElement.getInitialSubElements(),
      };
    }

    if (isNullOrUndefined(subElementHistoryJson)) {
      newElement = {
        ...newElement,
        ...SocialInitialElement.getInitialHistory(),
      };
    }

    return newElement;
  }
}
