import React from 'react';
import { node } from 'prop-types';

import WelcomeBanner from './components/WelcomeBanner';
import registrationStepContainer from '../../containers/RegistrationStepContainer';
import AuthFormSidebar from './components/AuthFormSidebar';

import cx from './AuthForm.module.scss';

const Sidebar = registrationStepContainer(AuthFormSidebar);

const AuthForm = ({ children }) => {
  return (
    <div className={cx['auth-form']} id="auth-form">
      <Sidebar>
        {children}
      </Sidebar>
      <WelcomeBanner />
    </div>
  );
};

AuthForm.propTypes = {
  children: node.isRequired,
};

export default AuthForm;
