import React from 'react';
import MedicationsTable from '../MedicationsTable';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { build } from 'helpers/URLUtils';


export const MedicationsList = props => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const selectMedication = medicationId =>
    navigate(
      build({
        pathname,
        query: { medication: medicationId }
      })
    );

  const {
    medications,
    isFetching,
    error,
    filter,
    fetchMedications,
    patientId
  } = props;

  if (isFetching) return <Loader />;
  return (
    <MedicationsTable
      items={medications}
      showMedicationById={selectMedication}
      selectedId={parseInt(searchParams.get('medication'))}
      filter={filter}
      error={error}
      onReload={() => fetchMedications(patientId, filter)}
    />
  );
};

MedicationsList.propTypes = {
  medications: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  filter: PropTypes.string.isRequired,
};
