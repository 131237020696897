import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from 'modules/seats/actions';
import * as seatsSelectors from 'modules/seats/selectors';
import * as usersSelectors from 'modules/users/selectors';
import * as clinicSelectors from 'modules/clinic/selectors';
import { fetchClinicUsers, removeUserFromSeatLocally } from 'modules/users/actions';
import * as rolesSelectors from 'modules/roles/selectors';
import { fetchRoles } from 'modules/roles/actions';

import { setRoleIdForEachUser } from 'helpers/users/users';

export const fetchLayerHOC = Component => (
  class SeatsFetchLayer extends React.Component {
    static propTypes = {
      fetchRoles: PropTypes.func.isRequired,
      areSeatsFetched: PropTypes.bool.isRequired,
      isRolesFetched: PropTypes.bool.isRequired,
      isRolesFetching: PropTypes.bool.isRequired,
      fetchClinicSeats: PropTypes.func.isRequired,
    };

    async componentDidMount() {
      const { areSeatsFetched, fetchClinicSeats } = this.props;

      if (areSeatsFetched) return;
      fetchClinicSeats();
    }

    render() {
      return <Component {...this.props} />;
    }
  }
);

const mapStateToProps = (state) => {
  const seatsState = state.seats;
  const clinicState = state.clinic;

  const clinicActiveUsers = usersSelectors.getUsersByStatus(state.users, true);
  const roles = rolesSelectors.getRoles(state.roles);

  const clinicUsersWithRoleId = setRoleIdForEachUser(clinicActiveUsers, roles);

  return {
    areSeatsFetched: seatsSelectors.getIsFetched(seatsState),
    areSeatsFetching: seatsSelectors.getIsFetching(seatsState),
    areSeatsSaving: seatsSelectors.getIsSaving(seatsState),
    seats: seatsSelectors.getSeats(seatsState),
    clinicInfo: clinicSelectors.getClinicInfo(clinicState),
    clinicUsers: clinicUsersWithRoleId,
    isRolesFetched: rolesSelectors.getIsFetched(state.roles),
    isRolesFetching: rolesSelectors.getIsFetching(state.roles),
    roles,
  };
};

const mapActionCreators = {
  ...actions,
  fetchRoles,
  fetchClinicUsers,
  removeUserFromSeatLocally,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
