import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Textarea from 'react-textarea-autosize';

import AddButton from 'components/ElementsViewAddButton';

import * as elementsConstants from 'helpers/elements/constants';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './ElementsViewItem.module.scss';
import { isEnter } from '../../helpers/KeyboardUtils';

const styles = ({ match }) => ({
  item: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'item',
    globalStyles: 'break-text'
  }),
  addButton: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: [match === 'broad' && 'broadMatch', match === 'exact' && 'exactMatch']
  })
})

const RegularElement = (props) => {
  const {
    onClick,
    item,
    match,
    editMode,
    disabled,
    iconType,
    circleButtonText = elementsConstants.ELEMENTS_TYPES.add.text,
  } = props;

  const resolvedStyles = styles({ match })

  const textareaKeyboardHandler = useCallback((e) => {
    if (isEnter(e)) {
      e.preventDefault();
    }
  }, []);

  return (
    <div className={cx.wrapper}>
      <div className={resolvedStyles.item}>
        <AddButton
          onClick={onClick}
          disabled={disabled}
          className={resolvedStyles.addButton}
          iconType={iconType}
          circleButtonText={circleButtonText}
        />
        {' '}
        {editMode && (
          <Textarea
            className={cx.edit}
            ref={ref => ref?.focus()}
            value={item.name}
            onChange={props?.onChange}
            onKeyDown={textareaKeyboardHandler}
          />
        )}
        {!editMode && (
          <span
            onClick={onClick}
            className={cx['item-name']}
          >
            {item.name}
          </span>
        )}
      </div>
    </div>
  );
};

RegularElement.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.oneOf(['broad', 'exact']),
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  iconType: PropTypes.string,
  circleButtonText: PropTypes.string,
};

export default RegularElement;
