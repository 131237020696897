import { connect } from 'react-redux';

import { getResourceEnclosedElements } from 'modules/chartingAssets/selectors.js';

import { isNullOrUndefined } from 'helpers';
import isEmpty from 'helpers/common/array/is-empty';
import stringParser from 'helpers/common/string/string-parser';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';

const mapStateToProps = (state, ownProps) => {
  const { chartId, chartCptCode, showChartCPT } = ownProps;

  const cptEnclosedElements = getResourceEnclosedElements(state, BILLING_SYSTEMS.CPT.type, chartId);
  const hcpcEnclosedElements = getResourceEnclosedElements(state, BILLING_SYSTEMS.HCPC.type, chartId);

  const isEmptyChartCptCode = isNullOrUndefined(chartCptCode);
  const isShowChartCPT = showChartCPT && !isEmptyChartCptCode;
  const cptToDisplay = !isShowChartCPT
    ? [...cptEnclosedElements]
    : cptEnclosedElements.filter(cpt => stringParser(cpt.cptCode) !== stringParser(chartCptCode.cptCode));

  const isDisplayBillingSection = isShowChartCPT || !isEmpty(cptEnclosedElements) || !isEmpty(hcpcEnclosedElements);

  return {
    isDisplayBillingSection,
    cptToDisplay,
    isShowChartCPT,
    hcpcToDisplay: hcpcEnclosedElements,
  };
};


export default component => connect(mapStateToProps, null)(component);
