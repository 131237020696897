import React from 'react';
import PropTypes from 'prop-types';

import cx from 'components/UserEditForm/Wrapper/Wrapper.module.scss';

const Wrapper = ({
  children,
}) => (
  <div
    className={cx.wrapper}
  >
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node,
  container: PropTypes.bool,
};

export default Wrapper;
