import React from 'react';
import PropTypes from 'prop-types';
import cx from './EnclosedElements.module.scss';

import Element from './Element';

const EnclosedElements = ({
  elements,
  removeElement,
  updateElement,
  isROS,
  systemType,
  patientId,
  allowElementUpdate,
  updateElementName,
  assignedDiagnoses,
  patient,
  createSubelement,
  step,
  chartId,
  systemId,
  elementsCount,
  sendUpdateSocialRequest,
  social,
  updateIndexToAutoSet,
  resetIndexToAutoSet,
  listOfAutoOpenSubelementsIndexes,
  allowCreation,
  isChartSaving,
  saveChart,
  isAllowAttachingIcdTenCodes,
  selectAfterFirstSubElementCallback,
  exactMatch = {},
  broadMatch = {},
}) => {
  if (!elements || !elements.length) return null;

  return (
    <div className={cx['wrapper']}>
      {elements.map(element => (
        <Element
          selectAfterFirstSubElementCallback={selectAfterFirstSubElementCallback}
          isAllowAttachingIcdTenCodes={isAllowAttachingIcdTenCodes}
          saveChart={saveChart}
          allowCreation={allowCreation}
          isChartSaving={isChartSaving}
          key={element.id}
          element={element}
          removeElement={() => removeElement(element)}
          updateElement={updateElement}
          // change pos to neg and vise versa
          updateElementName={updateElementName}
          isROS={isROS}
          systemType={systemType}
          disableSubelements={systemType === 2}
          systemId={systemId}
          patientId={patientId}
          editable={allowElementUpdate}
          assignedDiagnoses={assignedDiagnoses}
          patient={patient}
          createSubelement={createSubelement}
          step={step}
          chartId={chartId}
          elementsCount={elementsCount}
          sendUpdateSocialRequest={sendUpdateSocialRequest}
          social={social}
          updateIndexToAutoSet={updateIndexToAutoSet}
          resetIndexToAutoSet={resetIndexToAutoSet}
          listOfAutoOpenSubelementsIndexes={listOfAutoOpenSubelementsIndexes}
          exactMatch={exactMatch}
          broadMatch={broadMatch}
        />
      ))}
    </div>
  );
};

EnclosedElements.propTypes = {
  saveChart: PropTypes.func,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

export default EnclosedElements;
