import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import UserEditForm from 'components/UserEditForm';
import Loader from 'components/Loader';

import MedicationForm from '../MedicationForm';
import medicationContainer from '../MedicationContainer';

import { build } from 'helpers/URLUtils';
import { useLocation } from 'react-router-dom';

const Medication = compose(
  medicationContainer,
)(MedicationForm);

const MedicationsPage = ({
                           children,
                           patientId,
                           hasActiveMedications,
                           isInitialized = false,
                           medicationId,
                           createMedication,
                           updateMedication,
                           deleteMedication,
                           linkBase,
                         }) => {
  const { pathname } = useLocation();

  if (!isInitialized) return <Loader />;

  const pathToItemBuilder = ({ itemId }) =>
    build({ pathname, query: { medication: itemId } });

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Active medications', indexLink: true },
        { url: { pathname: `${linkBase}/inactive` }, name: 'Inactive medications' },
      ]}
      labelVisible={!hasActiveMedications}
      labelText="No active medications"
      formComponent={
        <Medication
          medicationId={medicationId}
          patientId={patientId}
          onCreate={createMedication}
          onUpdate={updateMedication}
          onDelete={deleteMedication}
          pathToItemBuilder={pathToItemBuilder}
        />
      }
    >
      {children}
    </UserEditForm.Root>
  );
};

MedicationsPage.propTypes = {
  children: PropTypes.node,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasActiveMedications: PropTypes.bool.isRequired,
  isInitialized: PropTypes.bool,
  medicationId: PropTypes.string,
  createMedication: PropTypes.func.isRequired,
  updateMedication: PropTypes.func.isRequired,
  deleteMedication: PropTypes.func.isRequired,
  linkBase: PropTypes.string.isRequired,
};

export default MedicationsPage;
