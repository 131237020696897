import React from 'react';
import { withRouter } from 'containers/RouterParams';
import PropTypes from 'prop-types';

import { saveAs } from 'file-saver';

import isEqual from 'lodash/isEqual';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ReportsListComponent from './ReportsList';

import DownloadZipAbility from 'helpers/reports/downloadZipAbility';
import ContentDispositionFileName from 'helpers/promise/header/contentDispositionFileName';
import stringParser from 'helpers/common/string/string-parser';
import Time from 'helpers/date/Time';
import { isNullOrUndefined } from 'helpers';

import { fetchReports, downloadZip } from 'api/reports';

class ReportsRootList extends React.Component {
  state = {
    isFetching: false,
    error: null,
    logs: [],
    lastPage: false,
  };

  componentDidMount() {
    if (DownloadZipAbility.isPresent()) {
      return this.sendRequestToDownloadZip();
    }

    return this.fetchReports();
  }

  componentDidUpdate(prevProps) {
    const prevParams = this.getParamsFromProps(prevProps);
    const updatedParams = this.getParamsFromProps(this.props);
    const isParamsChange = !isEqual(updatedParams, prevParams);
    const isPageChanged = prevParams.page !== updatedParams.page;

    if (isParamsChange) {
      if (this.isExportNotesToPdfAction() && !isPageChanged) {
        this.sendRequestToDownloadZip();
      } else {
        this.fetchReports();
      }
    }
  }

  getSortByFromProps = props => props.location.query.sortBy || 'date';

  getDescFromProps = (props) => {
    if (props.location.query.desc === undefined) {
      return true;
    }
    return props.location.query.desc === 'true' || false;
  };

  getParamsFromProps = (props) => {
    const {
      location,
    } = props;

    const { query } = location;

    const {
      from,
      to,
      action,
      actionType,
      page = 0,
      patientId,
      userId,
    } = query;

    const sortBy = this.getSortByFromProps(props);
    const desc = this.getDescFromProps(props);

    return {
      page: stringParser(page),
      from: stringParser(from),
      to: stringParser(to),
      patientId: stringParser(patientId),
      userId: stringParser(userId),
      action,
      actionType,
      sortBy,
      desc,
    };
  };

  getParams = () => this.getParamsFromProps(this.props);

  getDatesToReports = () => {
    const { location } = this.props;
    const { query } = location;
    const {
      from,
      to,
    } = query;

    if (isNullOrUndefined(from) && isNullOrUndefined(to)) {
      return {}
    }

    const {
      timeZoneDateConverter,
    } = this.context;

    const startDayTime = new Time({});
    const fromParsed = timeZoneDateConverter.getDateWithUTCAndTimeZoneOffset(stringParser(from), startDayTime);

    const endDayTime = new Time({
      hours: 23, min: 59, sec: 59, ms: 999,
    });
    const toParsed = timeZoneDateConverter.getDateWithUTCAndTimeZoneOffset(stringParser(to), endDayTime);

    return {
      from: timeZoneDateConverter.getDateInMs(fromParsed),
      to: timeZoneDateConverter.getDateInMs(toParsed),
    };
  };

  collectDataForDownloadZip = () => {
    const { location } = this.props;
    const { query } = location;
    const {
      patientId,
      userId,
    } = query;

    const { from, to } = this.getDatesToReports();

    return {
      startDate: from,
      endDate: to,
      patientId: stringParser(patientId),
      userId: stringParser(userId),
    };
  };

  triggerSpinner = () => {
    this.setState({ isFetching: true });
  };

  disableSpinner = () => {
    this.setState({ isFetching: false });
  };

  sendRequestToDownloadZip = async () => {
    this.triggerSpinner();

    const data = this.collectDataForDownloadZip();
    const response = await downloadZip(data);
    if (response.status === 204) {
      this.context.setInfoModalData({ message: 'No Encounter Notes Found' });
    } else if (response.status === 200) {
      const contentDispositionFileName = new ContentDispositionFileName(response);
      const filename = contentDispositionFileName.getFileName();

      saveAs(response.body, filename);
    }

    this.disableSpinner();
  };

  isExportNotesToPdfAction = () => {
    return DownloadZipAbility.isPresent(this.props.location.query.action);
  };

  fetchReports = () => this.fetchReportsWithParams(this.getParams());

  collectDataToFetchReports = (params) => {
    const dates = this.getDatesToReports();

    return {
      ...params,
      ...dates,
    };
  };

  fetchReportsWithParams = async (params) => {
    this.setState({ isFetching: true, error: null });

    const data = this.collectDataToFetchReports(params);

    try {
      const { body } = await fetchReports(data);
      this.setState({ logs: body.logs, lastPage: body.lastPage, isFetching: false });
    } catch (e) {
      this.setState({
        error: {
          status: e.status,
          message: e.message,
        },
        isFetching: false,
      });
    }
  };

  render() {
    const {
      logs, isFetching, error, lastPage,
    } = this.state;

    return (
      <ReportsListComponent
        logs={logs}
        lastPage={lastPage}
        params={this.getParams()}
        location={this.props.location}
        router={this.props.router}
        isFetching={isFetching}
        error={error?.message}
        onReload={this.fetchReports}
      />
    );
  }
}

ReportsRootList.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

ReportsRootList.contextType = AppContext;

export default withRouter(ReportsRootList);
