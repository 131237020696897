export const USERS_FETCH = 'USERS_FETCH';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAIL = 'USERS_FETCH_FAIL';

export const USERS_CLINIC_FETCH = 'USERS_CLINIC_FETCH';

export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';

export const USER_FETCH_IMAGE = 'USER_FETCH_IMAGE';
export const USER_FETCH_IMAGE_SUCCESS = 'USER_FETCH_IMAGE_SUCCESS';
export const USER_FETCH_IMAGE_FAIL = 'USER_FETCH_IMAGE_FAIL';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_REMOVE_FROM_SEAT = 'USER_UPDATE_LOCALLY';

export const USER_PERMISSIONS_UPDATE = 'USER_PERMISSIONS_UPDATE';
export const USER_PERMISSIONS_UPDATE_SUCCESS = 'USER_PERMISSIONS_UPDATE_SUCCESS';
export const USER_PERMISSIONS_UPDATE_FAIL = 'USER_PERMISSIONS_UPDATE_FAIL';

export const CLEAR_USER_PERMISSIONS_FOR_REMOVED_ROLE = 'CLEAR_USER_PERMISSIONS_FOR_REMOVED_ROLE';
