import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import * as actions from 'modules/charts/actions';

import * as selectors from 'modules/charts/selectors';
import * as seatsSelectors from 'modules/seats/selectors';
import * as settingsSelectors from 'modules/settings/selectors';
import { getUserInfo, getPermissions } from 'modules/user/selectors';
import * as providersSelectors from 'modules/providers/selectors';

import { isNullOrUndefined } from 'helpers';

const fetchLayerHOC = (Component) => {
  return class ChartsFetchLayer extends React.Component {
    static propTypes = {
      fetchChart: PropTypes.func.isRequired,
      fetchChartsByPage: PropTypes.func.isRequired,
      isFetching: PropTypes.bool,
      activeProviderId: PropTypes.number,
      permissions: PropTypes.object.isRequired,
      fetchLastChartsWithSorting: PropTypes.func.isRequired,
      onPageChange: PropTypes.func.isRequired,
      sortSignedBy: PropTypes.string.isRequired,
      sortUnsignedBy: PropTypes.string.isRequired,
      isUnsignedAsc: PropTypes.bool.isRequired,
      isSignedAsc: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      activeProviderId: null,
      isFetching: false,
    };

    componentDidMount() {
      const {
        isFetching,
        sortUnsignedBy,
        sortSignedBy,
        isUnsignedAsc,
        isSignedAsc,
        onPageChange,
        fetchChartsByPage,
        permissions,
        fetchLastChartsWithSorting,
        activeProviderId,
      } = this.props;

      const isFetchingCharts = !isNullOrUndefined(isFetching) && isFetching;
      const isEmptyProvider = isNullOrUndefined(activeProviderId);
      const isNotGrantPermissions = !permissions.viewEditPatientClinicalInformation || !permissions.viewEditEncounterNote;
      if (isFetchingCharts || isEmptyProvider || isNotGrantPermissions) {
        return;
      }

      onPageChange(0, 'unsigned', page =>
        fetchChartsByPage(page, sortUnsignedBy, isUnsignedAsc, activeProviderId)
      );

      onPageChange(0, 'signed', () =>
        fetchLastChartsWithSorting(sortSignedBy, isSignedAsc, activeProviderId)
      );
    }

    componentDidUpdate(prevProps, prevState) {
      const prevActiveProviderId = prevProps.activeProviderId;
      const nextActiveProviderId = this.props.activeProviderId;

      const {
        isFetching,
        sortUnsignedBy,
        sortSignedBy,
        isUnsignedAsc,
        isSignedAsc,
        onPageChange,
        fetchChartsByPage,
        permissions,
        fetchLastChartsWithSorting,
      } = prevProps;

      const isNotChangedProvider = nextActiveProviderId === prevActiveProviderId;
      const isFetchingCharts = !isNullOrUndefined(isFetching) && isFetching;
      const isEmptyProvider = isNullOrUndefined(nextActiveProviderId);
      const isNotGrantPermissions = !permissions.viewEditPatientClinicalInformation || !permissions.viewEditEncounterNote;
      if (isNotChangedProvider || isFetchingCharts || isEmptyProvider || isNotGrantPermissions) {
        return;
      }

      onPageChange(0, 'unsigned', page =>
        fetchChartsByPage(page, sortUnsignedBy, isUnsignedAsc, nextActiveProviderId)
      );

      onPageChange(0, 'signed', () =>
        fetchLastChartsWithSorting(sortSignedBy, isSignedAsc, nextActiveProviderId)
      );
    }


    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    activeSignedPage,
    activeUnsignedPage,
  } = ownProps;

  return {
    activeProviderId: providersSelectors.getActiveProviderId(state),
    isFetching: selectors.getIsFetching(state.charts),
    signedCharts: selectors.getSignedChartsByPage(state.charts),
    unsignedCharts: selectors.getUnsignedChartsByPage(state.charts, activeUnsignedPage),
    signedPagesCount: selectors.getSignedChartsPageCount(state.charts),
    unsignedPagesCount: selectors.getUnsignedChartsPageCount(state.charts),
    areSignedChartsFetching: selectors.getSignedChartsIsFetching(state.charts),
    areUnsignedChartsFetching: selectors.getUnsignedChartsIsFetching(state.charts),
    unlockSignedProgressNote: settingsSelectors.getSettings(state).unlockSignedProgressNote,
    deleteEncounters: settingsSelectors.getSettings(state).deleteEncounters,
    dashboardError: selectors.getDashboardError(state.charts),
    userInfo: getUserInfo(state.user),
    seats: seatsSelectors.getSeats(state.seats),
    permissions: getPermissions(state.user),
    encountersType: 'general-encounters',
    activeSignedPage,
    activeUnsignedPage,
  };
};

const mapActionCreators = {
  ...actions,
};

const ChartsContainer = component => connect(mapStateToProps, mapActionCreators)(fetchLayerHOC(component));

export default ChartsContainer;
