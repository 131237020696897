import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from './components/Dropdown';
import Loader from 'components/Loader';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './SelectInput.module.scss';

const SelectInput = (props) => {
  const {
    name,
    placeholder = 'Choose value',
    options,
    defaultOption = { title: '', value: '' },
    onChange,
    isLoading = false,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const [activeOption, setActiveOption] = useState(defaultOption);
  const [activeOptionIndex, setActiveOptionIndex] = useState(null);

  useEffect(() => {
    setActiveOption(defaultOption);
  }, [defaultOption]);

  const hideDropdown = () => setIsActive(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsActive(prev => !prev);
  };

  const select = (option, optionIndex) => {
    setActiveOption(option);
    setActiveOptionIndex(optionIndex);
    hideDropdown();
    if (onChange) onChange(option.value);
  };

  return (
    <div className={cx.select}>
        <select name={name} defaultValue={activeOption.value}>
          <option value="" />
          {options?.map((option) => (
            <option key={option.title} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
        <button
            type='button'
            className={cx['select-body']} onClick={toggleDropdown}
        >
          <div className={
            cssClassesResolver([
              !!activeOption.title && cx.label,
              !activeOption.title && cx['label-with-placeholder']
            ])}
          >
            {activeOption.title || placeholder}
          </div>
          {isLoading && (
              <div className={cx['loader-wrapper']}>
                <Loader size="small"/>
              </div>
          )}
          <div className={cx.button}/>
        </button>
        {isActive && (
          <Dropdown
              options={options}
              isActive={isActive}
              activeOptionIndex={activeOptionIndex}
              onOptionClick={(option, index) => select(option, index)}
              position={'top'}
          />
        )}
    </div>
  );
};

SelectInput.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })
    ),
    defaultOption: PropTypes.object,
    position: PropTypes.string,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
  };

export default SelectInput;
