import React from 'react';
import PropTypes from 'prop-types'

import { components } from 'react-select';

import InputIcon from 'components/MedSelect/InputIcon';

const SelectContainer = ({ children, ...props }) => {
  return (
    <components.SelectContainer {...props}>
      {!!children && (
        <InputIcon />
      )}
      {children}
    </components.SelectContainer>
  )
}

SelectContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SelectContainer
