import React, { Component } from 'react';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

import BillingInfoModalContainer from '../BillingInfoModalContainer';

import RenderBillingInfo from 'components/RenderBillingInfo';
import RenderPlanDescription from 'components/RenderPlanDescription';

class PaymentsPlans extends Component {
  static propTypes = {
    seats: PropTypes.object.isRequired,
    paymentsPlans: PropTypes.object.isRequired,
    creditCard: PropTypes.object.isRequired,
  };

  state = {
    showSaveModal: false,
  };

  openCardModal = () => {
    this.setState({ showSaveModal: true });
  };

  closeCardModal = () => {
    this.setState({ showSaveModal: false });
  };

  render() {
    const {
      paymentsPlans,
      seats,
      creditCard,
    } = this.props;

    if (!paymentsPlans.isFetched || paymentsPlans.isFetching) {
      return <div><Loader /></div>;
    }

    return (
      <div style={{ height: '100%', fontSize: '16px' }}>
        <RenderBillingInfo
          openCardModal={this.openCardModal}
          creditCard={creditCard}
        />

        <RenderPlanDescription
          paymentsPlans={paymentsPlans}
          seats={seats}
        />

        {this.state.showSaveModal && (
          <BillingInfoModalContainer
            closeModal={this.closeCardModal}
            location="buy"
          />
        )}
      </div>
    );
  }
}

export default PaymentsPlans;
