import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import union from 'lodash/union';
import uniq from 'lodash/uniq';

import * as socialSystemsSelectors from 'modules/socialSystems/selectors';
import * as chartsSelectors from 'modules/charts/selectors';
import * as resourceSelectors from 'modules/resources/selectors';
import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import * as chartingSessionsSelectors from 'modules/chartingSessions/selectors';
import * as socialSessionsSelectors from 'modules/socialSessions/selectors';
import * as patientsHxSelectors from 'modules/patientsHx/selectors';
import { getActivePatient } from 'modules/patients/selectors';

import { isNullOrUndefined, replaceSequenceRegex, variableButtonPattern } from 'helpers';
import * as chartHelpers from 'helpers/chart';
import SystemsGroupCompletionHandler from 'helpers/completion/system-groups';
import FilterElementsBySystems from 'helpers/elements/filters/elements-by-system';

import * as chartingHelpers from 'helpers/charting/charting';
import * as whiteList from 'helpers/charting/whiteListProps';
import {
  collectChartsForSavingChartRequest, formatElementToFavorite
} from 'helpers/charting/charting';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';
import isEmpty from 'helpers/common/array/is-empty';
import * as patientsSelectors from 'modules/patients/selectors';
import stringParser from 'helpers/common/string/string-parser';
import { hpChartTabs } from 'helpers/chart';

const diagnosesSystemType = 1;
const medicationsSystemType = 2;

// s = systems
// e = elements
// '2s' = 2 systems
// '1e' = 1 element

// CC, A, Plan
const defaultCpt = {
  [chartHelpers.hpChartTabs.RV.step]: '1e',
  [chartHelpers.hpChartTabs.A.step]: '1e',
  [chartHelpers.hpChartTabs.Plan.step]: '1e',
};

const cptMap = {
  99202: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '1s',
    [chartHelpers.hpChartTabs.PE.step]: '2s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99203: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99204: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99205: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99212: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '0e',
    [chartHelpers.hpChartTabs.PE.step]: '1s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99213: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '1s',
    [chartHelpers.hpChartTabs.PE.step]: '2s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99214: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99215: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '2e',
  },
  99241: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '0e',
    [chartHelpers.hpChartTabs.PE.step]: '1s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99242: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '1s',
    [chartHelpers.hpChartTabs.PE.step]: '2s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99243: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99244: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99245: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99304: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99305: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99306: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99307: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '0e',
    [chartHelpers.hpChartTabs.PE.step]: '1s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99308: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '1s',
    [chartHelpers.hpChartTabs.PE.step]: '2s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99309: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99310: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '2e',
  },
  99324: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99325: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99326: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99327: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99328: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99334: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '1s',
    [chartHelpers.hpChartTabs.PE.step]: '2s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99335: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99336: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99337: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '2e',
  },
  99341: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99342: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99343: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99344: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99345: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '3e',
  },
  99347: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '1e',
    [chartHelpers.hpChartTabs.ROS.step]: '1s',
    [chartHelpers.hpChartTabs.PE.step]: '2s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '0e',
  },
  99348: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99349: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '2s',
    [chartHelpers.hpChartTabs.PE.step]: '5s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '1e',
  },
  99350: {
    ...defaultCpt,
    [chartHelpers.hpChartTabs.HXRV.step]: '4e',
    [chartHelpers.hpChartTabs.ROS.step]: '10s',
    [chartHelpers.hpChartTabs.PE.step]: '8s',
    [chartHelpers.hpChartTabs.SOCIAL.step]: '2e',
  },
};

const skippedSystemIds = [88, 89];

export const getCompletionForStepsFromState = (state, chartId) => {
  const { cptCode } = chartsSelectors.getChartById(state.charts, chartId);
  const { id: patientId } = getActivePatient(state.patients);

  const chartingSession = chartingSessionsSelectors.getChartingSession(state.chartingSessions, chartId);
  const activeSystems = Object.keys(chartingSession).map(systemId => chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId)).filter(o => o);
  const activeSystemsByStep = groupBy(activeSystems, 'chartingId');


  const socialSession = { ...socialSessionsSelectors.getSocialSession(state.socialSessions, patientId) };

  Object.keys(socialSession).forEach((system) => {
    if (!socialSession[system].length) {
      delete socialSession[system];
    }
  });

  const familyItems = patientsHxSelectors.getFamilyItems(state.patientsHx, patientId).filter(o => o.illnesses.length);

  const activeSocialSystems = Object.keys(socialSession).map(systemId => socialSystemsSelectors.getSystemById(state.socialSystems, systemId)).filter(o => o);
  // const activePESystems = Object.keys(chartingSession).map(systemId => systemId);

  const fakeFamilySystem = { id: 'family', name: 'Family' };

  if (familyItems.length) {
    activeSocialSystems.push(fakeFamilySystem);

    socialSession[fakeFamilySystem.id] = familyItems.map(o => o.id);
  }

  const defaultStepCompletion = {
    progress: 0,
    useCheck: false,
    isReady: false,
  };

  const completion = {};

  const sectionsToCalculateCompletion = chartingAssetsSelectors.getSectionsExclude(
    state.chartingAssets,
    [hpChartTabs.BILLING.step]
  );

  sectionsToCalculateCompletion.forEach((step) => {
    completion[step.id] = defaultStepCompletion;
  });

  completion[0] = defaultStepCompletion;

  if (!cptCode) {
    return completion;
  }

  const requirementsMap = cptMap[cptCode.cptCode || cptCode];

  if (!requirementsMap) {
    return completion;
  }

  Object.keys(requirementsMap).forEach((chartingStepId) => {
    const requirement = requirementsMap[chartingStepId];
    const itemToCount = requirement[requirement.length - 1];
    const count = parseInt(requirement, 10);

    let systems = activeSystemsByStep[chartingStepId];

    let currentSession = chartingSession;

    const isSocial = parseInt(chartingStepId, 10) === chartHelpers.hpChartTabs.SOCIAL.step;
    const isPE = parseInt(chartingStepId, 10) === chartHelpers.hpChartTabs.PE.step;

    if (isSocial) {
      currentSession = socialSession;
      systems = activeSocialSystems;
    }

    if (isPE && systems) {
      systems = systems.filter(system => (
        skippedSystemIds.indexOf(system.id) < 0
      ));
    }

    switch (itemToCount) {
      // count elements
      case 'e': {
        const elementsCount =
          (systems && systems.reduce((acc, val) => currentSession[val.id].length + acc, 0)) || 0;

        completion[chartingStepId] = {
          progress: elementsCount <= count && count !== 0 ? elementsCount / count * 100 : 100,
          isReady: elementsCount >= count,
          useCheck: count <= 1,
        };
        break;
      }
      // count systems
      case 's': {
        const systemsGroupCompletionHandler = new SystemsGroupCompletionHandler(systems, chartingStepId, currentSession);
        const completionForSystemsGroup = systemsGroupCompletionHandler.calculateCompletion();
        const systemsWithoutSystemsGroup = systemsGroupCompletionHandler.filteredActiveSystems;

        const systemsWithElementsCount =
          (systemsWithoutSystemsGroup && systemsWithoutSystemsGroup.reduce((acc, val) => !!currentSession[val.id].length + acc, 0)) || 0;

        const reducedCompletion = completionForSystemsGroup + systemsWithElementsCount;

        completion[chartingStepId] = {
          progress: reducedCompletion <= count ? reducedCompletion / count * 100 : 100,
          isReady: reducedCompletion >= count,
          useCheck: count <= 1,
        };
        break;
      }
      default: {
        break;
      }
    }

    // round completion
    completion[chartingStepId].progress = Math.round(completion[chartingStepId].progress * 10) / 10;
  });

  return completion;
};

export const getChartCompletionPercentage = (state, chartId) => {
  const completionBySteps = getCompletionForStepsFromState(state, chartId);
  const totalCompletionSteps = { ...completionBySteps };

  const chart = chartsSelectors.getChartById(state.charts, chartId);

  let result = stringParser(
    Object.values(totalCompletionSteps)
      .reduce((acc, val) => val.progress + acc, 0)
    * 100 / (Object.keys(totalCompletionSteps).length * 100)
  );

  if (Number.isNaN(result)) {
    result = 0;
  }

  if (chart.cptCode === null) {
    result = null;
  }

  return result;
};

const processElementForSave = (element) => {
  return {
    ...element,
    subIds: element.subIds && element.subIds.map(subelement => subelement && subelement.id),
  };
};

export const getFlattenedElementsForSave = (state, chartId) => {
  const chartingSession = { ...chartingSessionsSelectors.getChartingSession(state.chartingSessions, chartId) };

  let diagnosesSystemElements = [];
  let medicationsSystemElements = [];
  let cptCodes = [];
  let medHcpc = [];

  const containers = [];
  const favoriteSections = [];

  Object.keys(chartingSession).forEach((systemId) => {
    const system = chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId);

    // diagnoses should be saved in separate field, so we have to remove them from chartingSession
    if (system && system.type === diagnosesSystemType) {
      diagnosesSystemElements = [...chartingSession[systemId]];
      delete (chartingSession[systemId]);
      return;
    }

    // same for medications
    if (system && system.type === medicationsSystemType) {
      medicationsSystemElements = [...chartingSession[systemId]];
      delete (chartingSession[systemId]);
      return;
    }

    if (system && system.type === BILLING_SYSTEMS.CPT.type) {
      cptCodes = [...chartingSession[systemId]];
      delete (chartingSession[systemId]);
      return;
    }

    if (system && system.type === BILLING_SYSTEMS.HCPC.type) {
      medHcpc = [...chartingSession[systemId]];
      delete (chartingSession[systemId]);
      return;
    }

    if (system) {
      const elementsToSave = [...chartingSession[systemId]].map(processElementForSave);
      const isNotAllowEmptySystems = system.chartingId !== chartHelpers.hpChartTabs.HXRV.step && isEmpty(elementsToSave);

      if (!isNotAllowEmptySystems) {
        containers.push({
          id: system.id,
          name: system.name,
          charts: elementsToSave,
          chartingId: system.chartingId,
          order: system.order,
        });

        favoriteSections.push({
          id: system.id,
          name: system.name,
          chartingId: system.chartingId,
          favoriteElements: [...chartingSession[systemId]].map(formatElementToFavorite)
        });
      }

      return;
    }

    chartingSession[systemId] = [...chartingSession[systemId]].map((element) => {
      return {
        ...element,
        ids: element.ids && element.ids.filter(o => o) && element.ids.filter(o => o).map((diagnosisId) => {
          if (typeof diagnosisId === 'object') {
            return diagnosisId.id;
          }
          return diagnosisId;
        }),
      };
    });
  });

  const chartingSessionElements = flatten(Object.values(chartingSession)).map(processElementForSave);

  return {
    chartingSessionElements,
    diagnosesSystemElements,
    medicationsSystemElements,
    containers,
    favoriteSections,
    cptCodes,
    medHcpc,
  };
};

// возвращает все элементы из rv секции для чарта в виде строки
export const getChartCCString = (state, chartId) => {
  const chartingSession = chartingSessionsSelectors.getChartingSession(state.chartingSessions, chartId);

  const rvSystemsIds = Object.keys(chartingSession).filter((systemId) => {
    const system = chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId);
    if (!system) return false;

    const { chartingId } = system;
    const section = chartingAssetsSelectors.getSectionById(state.chartingAssets, chartingId);
    if (!section) return false;

    return section.appId === chartHelpers.hpChartTabs.RV.appId;
  });

  const result = rvSystemsIds.reduce((acc, val) => {
    const elements = chartingSession[val];
    const elementNames = elements.map((el) => {
      let displayName = el.name;
      const containsVariable = el.name.includes('??');

      if (containsVariable && el.subIds && el.subIds.length) {
        const replacementArray = el.subIds.map(subelement => subelement && subelement.name);

        displayName = replaceSequenceRegex(displayName, variableButtonPattern, replacementArray);
      }

      return displayName;
    });
    return [...acc, ...elementNames];
  }, []);

  return uniq(result).join(', ');
};

export const getActiveSystemsByStepForChart = (state, step, chartId) => {
  // all systems for this step
  const systems = chartingAssetsSelectors.getSystemsByChartingStep(state.chartingAssets, step, chartId);

  const fixedSystems = systems.filter(system => system.fixed);

  let activeSystems = chartingSessionsSelectors.getChartingSessionSystems(state.chartingSessions, chartId)
    .map(systemId => chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId))
    .filter(system => system)
    .filter(system => system && (systems.map(o => o.id).includes(system.id)));

  activeSystems = union(activeSystems, fixedSystems);

  return activeSystems;
};

// itemType = diagnoses or medications
const getNoteSpecialItems = (state, itemType, patientId) => {
  const { getDiagnoses, getMedications } = patientsHxSelectors;

  const hxFunc = itemType === 'diagnoses' ? getDiagnoses : getMedications;
  const entityProp = itemType === 'diagnoses' ? 'diagnosis' : 'medication';

  const ids = hxFunc(state.patientsHx, patientId, 'active')
    .map(o => o[entityProp].id);

  return resourceSelectors.getResources(state.resources, itemType)
    .map(item => ({
      ...item,
      active: ids.includes(item.id),
    }));
};

// список элементов/систем для правой панели в чарте/social
export const getNoteItems = (state, { stepId, systemId, chartId }) => {
  // если система не передана, значит нужно вернуть все системы для этой секции
  if (!systemId) {
    const systems = chartingAssetsSelectors.getNoteSystemsByChartingStep(state.chartingAssets, stepId, chartId);
    return orderBy(systems, 'order');
  }
  // иначе возвращаем все элементы системы

  const { id:patientId } = patientsSelectors.getActivePatient(state.patients);

  const system = chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId);
  const section = chartingAssetsSelectors.getSectionById(state.chartingAssets, system.chartingId);

  if (!system) return [];

  switch (system.type) {
    case diagnosesSystemType:
      return getNoteSpecialItems(state, 'diagnoses', patientId);
    case medicationsSystemType:
      return getNoteSpecialItems(state, 'medications', patientId);
    case BILLING_SYSTEMS.CPT.type:
      return getNoteSpecialItems(state, BILLING_SYSTEMS.CPT.resourceType, patientId);
    case BILLING_SYSTEMS.HCPC.type:
      return getNoteSpecialItems(state, BILLING_SYSTEMS.HCPC.resourceType, patientId);
    // по дефолту это обычные элементы
    default: {
      const filterElementsBySystems = new FilterElementsBySystems(state.chartingAssets, section.appId, system);

      return filterElementsBySystems.getElements();
    }
  }
};

export const fetchRightSideSystemsByStepAndChartId = (state, { stepId, chartId }) => {
  return Object.values(state.chartingAssets.data.systems)
    .filter(sys => sys.chartingId === stepId && sys.chartId === chartId);
};

// список элементов/систем, считающихся активными
// должна возвращать массив айдишников систем/элементов
export const getActiveItems = (state, { stepId, systemId, chartId }) => {
  // если система не передана, нужно вернуть активные системы
  if (!systemId) {
    const systems = getActiveSystemsByStepForChart(state, stepId, chartId);

    return systems.map(o => o.id);
  }

  return chartingAssetsSelectors.getActiveElements(stepId, state, systemId, chartId);
};

export const getActiveElementsForSystem = (state, chartId, systemId) => {
  return chartingSessionsSelectors.getEnclosedElementsForSystem(state.chartingSessions, chartId, systemId)
    .map(el => ({
      ...chartingAssetsSelectors.getElementById(state.chartingAssets, el.id),
      ...el,
    }));
};

// вернет массив активных имен элементов для системы в чарте
export const getActiveElementNamesForSystem = (state, chartId, systemId) => {
  return getActiveElementsForSystem(state, chartId, systemId)
    .map(el => chartingAssetsSelectors.getElementById(state.chartingAssets, el.id).name);
};

const updateContainersBySpecialSystems = (specialSystemsToUpdate, containersToSave) => {
  const containersToAdd = specialSystemsToUpdate.map((specialSystem) => {
    return {
      ...chartingHelpers.createNewContainerParam(specialSystem, []),
    };
  });
  return [
    ...containersToSave,
    ...containersToAdd,
  ];
};

export const getSpecialSystemsForSaveChart = (state, chartId, notEmptySpecialSystemsIds = []) => {
  const chartDetails = chartsSelectors.getChartDetails(state.charts, chartId);

  return chartDetails.filter(specialSystem => !notEmptySpecialSystemsIds.includes(specialSystem.id));
};

const SPECIAL_SYSTEMS_STEP = [
  chartHelpers.hpChartTabs.HXRV.step,
];

const getNotEmptySpecialSystemsIds = (containersToSave) => {
  return containersToSave
    .filter(container => SPECIAL_SYSTEMS_STEP.includes(container.chartingId))
    .map(specialContainer => specialContainer.id);
};

const formatContainersBySpecialSystems = (state, chartId, containersToUpdate) => {
  const notEmptySystemsIds = getNotEmptySpecialSystemsIds(containersToUpdate);
  const specialSystems = getSpecialSystemsForSaveChart(state, chartId, notEmptySystemsIds);
  return updateContainersBySpecialSystems(specialSystems, containersToUpdate);
};

const formattedContainers = (state, chartId, containers) => {
  const whitePropsContainers = chartingHelpers.formatElementsForContainerParam(
    containers, whiteList.whiteListPropsForContainersParamForSaveChart, collectChartsForSavingChartRequest
  );

  return formatContainersBySpecialSystems(state, chartId, whitePropsContainers);
};

export const getChartDataForSave = ({ state, chartId }) => {
  const {
    containers,
    favoriteSections,
    diagnosesSystemElements,
    medicationsSystemElements,
    cptCodes,
    medHcpc,
  } = getFlattenedElementsForSave(state, chartId);

  return {
    id: stringParser(chartId),
    containers: formattedContainers(state, chartId, containers),
    favoriteSections,
    icdTenIds: chartingHelpers.formatElementsForIcdTen(diagnosesSystemElements),
    medications: chartingHelpers.formatElementsForChart(medicationsSystemElements),
    cptCodes: cptCodes.map((cpt) => ({ id: cpt.id, icdTenIds: isNullOrUndefined(cpt?.ids) ? [] : cpt.ids.map(icdTen => icdTen.id) })),
    medHcpc: medHcpc.map(hcpc => ({ id: hcpc.id, icdTenIds: isNullOrUndefined(hcpc.ids) ? [] : hcpc.ids.map(icdTen => icdTen.id) }))
  };
};
