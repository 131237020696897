import React, { useRef, useState } from 'react';

import Tether from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import Tooltip from '../../../../ChartReportElementWithTooltip/Tooltip';
import RemoveButton from '../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../ChartReportElementWithTooltip/DoorButton';

import cx from './ChartReportMedication.module.scss';

const MENU_BUTTON_DIAMETER = 40;

const ChartReportMedication = (props) => {
  const elementTetherRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(prev => !prev);

  const dropdownRef = useClickOutSide({ onOutsideClick: toggleMenu, exclude: [elementTetherRef] });

  const renderRow = ({ ref, medication, clickHandler }) => {
    elementTetherRef.current = ref.current;
    return (
      <tr
        ref={ref}
        className={cx.medication}
        onClick={clickHandler}
      >
        <td className={cx.medication__name}>
          {medication.medication.name}
        </td>
        <td className={cx.medication__description}>
          {medication.description}
        </td>
        <td className={cx.medication__diagnosis}>
          {medication.diagnosis && medication.diagnosis.map(diagnosis => (
            <div
              className={cx.diagnosis}
              key={diagnosis.id}
            >
              {diagnosis.name}
            </div>
          ))}
        </td>
      </tr>
    );
  };

  const {
    medication,
    toggleChartReport,
    onRemove,
    chartId,
    viewMode,
  } = props;

  if (viewMode) {
    return renderRow({ medication });
  }

  return (
    <Tether
      attachment="bottom center"
      targetAttachment="top center"
      constraints={[{
        to: 'scrollParent',
        attachment: 'together',
      }]}
      classPrefix="reportElement"
      renderTarget={targetRef => (
        renderRow({ ref: targetRef, medication, clickHandler: toggleMenu })
      )}
      renderElement={elementref => isOpen && (
        <span ref={dropdownRef}>
          <Tooltip
            ref={elementref}
          >
            <DoorButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
                marginRight: 10,
              }}
              onClick={() => {
                toggleMenu();
                toggleChartReport();
              }}
              link={`/app/doctor/charts/${chartId}/medications?medication=${medication.id}`}
            />
            <RemoveButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
              }}
              onClick={onRemove}
            />
          </Tooltip>
        </span>
      )}
    />
  );
};

export default ChartReportMedication;

