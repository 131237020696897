import * as constants from './constants';
import * as api from 'api/boxService';

export const removeBoxCode = () => ({
  types: [
    constants.BOX_CODE_REMOVE,
    constants.BOX_CODE_REMOVE_SUCCESS,
    constants.BOX_CODE_REMOVE_FAIL,
  ],
  promise: () => api.removeBoxCode(),
});

export const saveBoxCode = code => ({
  types: [
    constants.BOX_CODE_SAVE,
    constants.BOX_CODE_SAVE_SUCCESS,
    constants.BOX_CODE_SAVE_FAIL,
  ],
  promise: () => api.saveBoxCode(code),
});

export const fetchBoxClientId = () => ({
  types: [
    constants.BOX_CLIENT_ID_FETCH,
    constants.BOX_CLIENT_ID_FETCH_SUCCESS,
    constants.BOX_CLIENT_ID_FETCH_FAIL,
  ],
  promise: () => api.fetchBoxClientId(),
});
