import React from 'react';
import PropTypes from 'prop-types';

import DrChronoOffices from 'components/DrChronoService/DrChronoOffices';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Loader from 'components/Loader';

import DrChronoSSOService from 'helpers/drChrono/DrChronoSSOService';

import cx from './DrChronoService.module.scss';

const drChronoLogo = require('images/drchrono-logo.jpg');

const DrChronoService = (props) => {
  const {
    isDrChronoAttached,
    fetchDrChronoToken,
    removeDrChronoToken,
  } = props;

  const REDIRECT_URI = `https://${window.location.hostname}/app/doctor/settings/addons/drchrono`;

  const onSyncStatusChange = async (value) => {
    if (value) {
      const drChronoSSOService = new DrChronoSSOService(REDIRECT_URI);
      window.location = drChronoSSOService.drChronoAuthUrl();
    } else {
      await removeDrChronoToken();
      fetchDrChronoToken();
    }
  };

  return (
    <div className={cx.wrapper}>
        <img src={drChronoLogo} alt="" className={cx['drchrono-logo']} />
        <div className={cx['element-wrapper']}>
          <span className={cx['label__file-sync']}><b>DrChrono account</b> status:</span>
          <RadioButtonGroup
            items={[{
                name: 'Disconnected',
                value: false,
              }, {
                name: 'Connected',
                value: true,
              }]}
            checked={isDrChronoAttached}
            onChange={onSyncStatusChange}
          />
        </div>
        <DrChronoOffices />
    </div>
  );
};

DrChronoService.propTypes = {
  isDrChronoAttached: PropTypes.bool,
  removeDrChronoToken: PropTypes.func.isRequired,
  fetchDrChronoToken: PropTypes.func.isRequired,
};

DrChronoService.defaultProps = {
  isDrChronoAttached: false,
};

const Wrapper = (props) => {
  if (props.isFetching) {
    return <div className={cx['loader-wrapper']}><Loader /></div>;
  }

  return <DrChronoService {...props} />;
};

export default Wrapper;
