import { handleActions } from 'redux-actions';
import * as c from '../constants';

import keyBy from 'lodash/keyBy';

const initialState = {};

export default handleActions({
  [c.PERMISSIONS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result, 'id'),
  }),
}, initialState);
