import { fetchRightSideSystemsByStepAndChartId, getNoteItems } from 'modules/rootSelectors';

const getStrategyToFetchSystems = (isNeedFilterSystemsByStepAndChart) => {
  if (isNeedFilterSystemsByStepAndChart) {
    return fetchRightSideSystemsByStepAndChartId;
  }

  return getNoteItems;
};

export default getStrategyToFetchSystems;
