import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { useDrChronoLogin } from './useDrChronoLogin';

import LoginContainer from 'containers/LoginContainer';
import Loader from 'components/Loader';
import { checkCookieStorageAccess } from 'components/IFrameITP';

import cx from './DrChronoLogin.module.scss';

const DrChronoLogin = props => {
  const [searchParams] = useSearchParams();
  const { apiCallback } = useDrChronoLogin();
  const {
    isAuthenticated,
    isAuthenticating,
    loginWithDrChrono
  } = props;

  useEffect(() => {
    if (!isAuthenticated) {
      const appointmentId = searchParams.get('appointment_id');
      const sourceUrlParams = searchParams.get('source').split('?');

      const drChronoData = {
        patientId: searchParams.get('patient_id'),
        appointmentId,
        source: sourceUrlParams[0],
        userId: sourceUrlParams[1].split('=')[1],
      };

      loginWithDrChrono({ api: () => apiCallback({ drChronoData }) });
    }
  }, []);

  return (
    <div className={cx['form-wrapper']}>
      {isAuthenticating && <Loader />}
    </div>
  );
};

DrChronoLogin.propTypes = {
  loginWithDrChrono: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

const DrChronoLoginContainer = compose(
  LoginContainer,
  checkCookieStorageAccess,
)(DrChronoLogin);

export default DrChronoLoginContainer;
