import { handleActions } from 'redux-actions';

import * as constants from '../constants';

const initialState = [];

export default handleActions({
  [constants.USER_FETCH_SUCCESS]: (state, action) => action.payload.result.permissions || state,
  [constants.PERMISSIONS_UPDATE_SUCCESS]: (state, action) => action.payload.result.permissionsDTOS,
  [constants.PERMISSIONS_UPDATE_FOR_CURRENT_USER_LOCALLY]: (state, action) => action.payload.newPermissions,
}, initialState);
