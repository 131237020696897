import React from 'react';
import { connect } from 'react-redux';

import * as selectors from 'modules/financial/payments/selectors';
import * as actions from 'modules/financial/payments/actions';

export const fetchLayerHOC = (Component) => {
  return class UserPaymentsFetchLayer extends React.Component {
    componentDidMount() {
      this.props.fetchListPayments();
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => ({
  payments: selectors.getListPayments(state.financial),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
