const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchPermissions = () =>
  request.get('/api/rest/users/permissions/')
    .end();

export const setPermissions = (permissionIds, roleId) =>
  request
    .post('/api/rest/permissions/update/currentUser')
    .send({ permissionIds, roleId })
    .end();
