import { capitalize, getAllIndexes } from './index';

import orderBy from 'lodash/orderBy';

import { addNewProperty } from 'helpers';
import getGenderValue from 'helpers/elements/tags/converters/gender';
import heSheTagConverter from 'helpers/elements/tags/converters/he-she';
import hisHerTagConverter from 'helpers/elements/tags/converters/his-her';
import reactStringReplace from 'react-string-replace';

export const hpChartTabs = {
  RV: {
    id: 'rv',
    step: 1,
    systems: {
      RV1: {
        id: 356,
      },
    },
    appId: 'rv',
  },
  HXRV: {
    id: 'hxrv',
    step: 2,
    systems: {},
    appId: 'hrv',
  },
  ROS: {
    id: 'ros',
    step: 3,
    systems: {},
    appId: 'pe',
  },
  PE: {
    id: 'pe',
    step: 4,
    systems: {},
    appId: 'ros',
  },
  A: {
    id: 'a',
    step: 5,
    systems: {
      ICD_TEN: {
        name: 'ICD-10',
        id: 35,
        type: 1,
        resourceType: 'diagnoses',
      },
      ASSESSMENT: {
        id: 43,
      },
    },
  },
  Plan: {
    id: 'plan',
    step: 6,
    systems: {
      PRESCRIPTIONS: {
        name: 'Prescriptions',
        id: 36,
        type: 2,
        resourceType: 'medications',
      },
    },
    appId: 'plan',
  },
  BILLING: {
    id: 'pqrs',
    step: 7,
    systems: {
      CPT: {
        id: 149,
        type: 6,
        name: 'CPT',
        resourceType: 'cpt',
      },
      HCPC: {
        id: 31,
        type: 7,
        name: 'HCPC',
        resourceType: 'hcpc',
      }
    },
  },
  SOCIAL: {
    step: 0,
  },
};

export const isBillingSystemType = systemType => {
  return hpChartTabs.BILLING.systems.CPT.type === systemType || hpChartTabs.BILLING.systems.HCPC.type === systemType;
};

export const RESOURCE_TYPES = {
  1: 'diagnoses',
  2: 'medications'
};

export const isRv = stepId => stepId === hpChartTabs.RV.step;
export const isROS = stepId => stepId === hpChartTabs.ROS.step;
export const isBilling = stepId => stepId === hpChartTabs.BILLING.step;

export const elementTemplateMap = {
  '<<patient>>': {
    replacement: patient => `${patient.firstName  } ${  patient.lastName}`,
  },
  '<<firstname>>': {
    replacement: patient => patient.firstName,
  },
  '<<age>>': {
    replacement: (patient, timeZoneDateConverter) => timeZoneDateConverter.calculatePatientAge(patient.dob),
  },
  '<<dob>>': {
    replacement: (patient, timeZoneDateConverter) => timeZoneDateConverter.formattedDob(patient.dob),
  },
  '<<gender>>': {
    replacement: patient => getGenderValue(patient.gender),
    caseSensitive: true,
  },
  '<<he/she>>': {
    replacement: patient => heSheTagConverter(patient.gender),
    caseSensitive: true,
  },
  '<<his/her>>': {
    replacement: patient => hisHerTagConverter(patient.gender),
    caseSensitive: true,
  },
};

const getStringCase = (str) => {
  if (str === str.toUpperCase()) {
    return 'uppercase';
  }

  if (str === str.toLowerCase()) {
    return 'lowercase';
  }

  return 'capitalize';
};

export const transformBold = (input) => {
  return reactStringReplace(input, /\*(.+)\*/g, (match, index, offset) =>
    (<strong key={offset} >{match}</strong>));
};

export const transformOverflowName = (name, limit, need) => (name.length > limit ? `${name.slice(0, need)  }...` : name);

const systemNameTransformByStep = {
  1: systemName => systemName.toUpperCase().replace('RV', '').trim(),
  2: systemName => systemName.toUpperCase().replace('HXRV', '').trim(),
  6: systemName => 'Prescriptions'.toUpperCase() === systemName.toUpperCase()
    ? 'New Prescriptions'.toUpperCase().trim()
    : systemName.toUpperCase().trim()
};

const baseSystemNameTransformer = systemName => systemName.toUpperCase();

export const systemNameTransformerFactory = (stepId) => {
  return systemNameTransformByStep[stepId] || baseSystemNameTransformer;
};

export const transformElementName = (name, patient, timeZoneDateConverter) => {
  if (!patient) return name;

  const result = Object.keys(elementTemplateMap).reduce((acc, val) => {
    // find all template strings of this kind
    const count = getAllIndexes(acc.toLowerCase(), val).length;

    // if there are none, return string
    if (!count) return acc;

    let resReduce = acc;

    let lastIndex = 0;

    const templateObject = elementTemplateMap[val];

    const replacement = templateObject.replacement(patient, timeZoneDateConverter);

    for (let i = 0; i < count; i += 1) {
      const index = resReduce.toLowerCase().indexOf(val, lastIndex);

      let replacementReduce = replacement;

      if (templateObject.caseSensitive) {
        // if template string is case sensitive first we have to get actual template string
        const foundTemplateString = resReduce.substr(index, val.length);

        // get case
        const stringCase = getStringCase(foundTemplateString);

        switch (stringCase) {
          case 'lowercase':
            replacementReduce = replacementReduce.toLowerCase();
            break;
          case 'uppercase':
            replacementReduce = replacementReduce.toUpperCase();
            break;
          case 'capitalize':
            replacementReduce = capitalize(replacementReduce);
            break;
          default:
            replacementReduce = replacementReduce.toLowerCase();
            break;
        }
      }

      lastIndex = index + 1;
      resReduce = resReduce.substr(0, index) + replacementReduce + resReduce.substr(index + val.length);
    }

    return resReduce;
  }, name);


  return transformBold(result);
};

export const sortSubIdsByPosition = (element, social) => {
  if (!social) {
    return element;
  }
  let result = { ...element };

  const sortedSubIds = orderBy(element.subIds, ['position'], ['asc']);

  result = addNewProperty('subIds', sortedSubIds, result);

  return result;
};

export const isTheSameSystem = (systemName, originalSystemName) =>
  systemName === originalSystemName;

export const isTheSameSystemBySystemId = (systemId, originalSystemId) =>
  systemId === originalSystemId;

export const isTheSameTab = (step, originalStep) =>
  step === originalStep;
