import { formatFamilyItemForServer } from 'helpers/fmss/family/family';

const request = require('superagent-promise')(require('superagent'), Promise);

// get family list by patient
export const fetchFamily = patientId =>
  request.get(`/api/rest/family/patient/${patientId}`)
    .end();

// family person search
export const searchFamilyPerson = searchRequest =>
  request.post('/api/rest/fperson/search')
    .send({ searchRequest })
    .end();

export const removeFamilyPerson = id =>
  request
    .post(`/api/rest/fperson/${id}`)
    .end();

// illness search
export const searchIllnesses = searchRequest =>
  request.post('/api/rest/illness/search')
    .send({ searchRequest })
    .end();

export const removeIllnesses = id =>
  request
    .post(`/api/rest/illness/${id}`)
    .end();

export const fetchFamilyItem = familyItemId =>
  request.get(`/api/rest/family/${familyItemId}`)
    .end();

export const createFamilyItem = familyItem =>
  request.post('/api/rest/family/create')
    .send(formatFamilyItemForServer(familyItem))
    .end();

export const updateFamilyItem = familyItem =>
  request.post('/api/rest/family/update')
    .send(formatFamilyItemForServer(familyItem))
    .end();

export const deleteFamilyItem = familyItemId =>
  request.post('/api/rest/family/delete')
    .send({ id: familyItemId })
    .end();

export const createIllness = name =>
  request.post('/api/rest/illness/create')
    .send({ name })
    .end();

export const deleteIllness = id =>
  request
    .del(`/api/rest/illness/delete/${id}`);

export const createFamilyPerson = name =>
  request.post('/api/rest/family/member/create')
    .send({ name })
    .end();
