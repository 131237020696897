import React from 'react';
import PropTypes from 'prop-types';

import useSearchPatient from 'hooks/useSearchPatient';

import cx from './PatientsSearch.module.scss';

const PatientsSearch = (props) => {
  const {
    searchQuery = '',
    isSafeSearching = false,
    onSearch,
  } = props;

  const {
    value,
    handleBlur,
    handleFocus,
    searchPatient
  } = useSearchPatient({ searchQuery, isSafeSearching, onSearch });

  const handleChange = (event) => {
    const { value:valueToSearch } = event.target;

    searchPatient(valueToSearch);
  };

  return (
    <div className={cx.wrapper}>
      <input
        type="text"
        onChange={handleChange}
        value={value || ''}
        className={cx.input}
        placeholder="Type three chars"
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

PatientsSearch.propTypes = {
  searchQuery: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  isSafeSearching: PropTypes.bool,
};

export default PatientsSearch;
