export const formatsurgicalForServer = (surgicalItem) => {
  const result = { ...surgicalItem };
  result.surgeryId = result.surgery.id;
  delete result.surgery;

  return result;
};

export const surgicalItemWhiteList = [
  'id',
  'patientId',
  'surgery',
  'description',
  'startDate',
];
