import React from 'react';
import PropTypes from 'prop-types';
import SeatStatusContentItem from './SeatStatusContentItem';

const SeatStatusContent = ({ status }) => {
  return (
    <div>
      <SeatStatusContentItem status={status.lock} color="red" />
      <SeatStatusContentItem status={status.open} color="#30c6a1" />
      <SeatStatusContentItem status={status.used} color="rgb(27, 196, 251)" />
    </div>
  );
};

SeatStatusContent.propTypes = {
  status: PropTypes.object.isRequired,
};

export default SeatStatusContent;
