import { useEffect, useState } from 'react';
import { checkSaveSearching } from 'helpers';

const useSearchPatient = (props) => {
  const {
    searchQuery
  } = props

  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (!focused && searchQuery) {
      setValue(searchQuery)
    }
  }, [focused, searchQuery])

  const handleFocus = () => {
    setFocused(true)
    setValue('')
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const handleCancel = () => setValue('')

  const searchPatient = (valueToSearch) => {
    const { isSafeSearching, onSearch } = props;

    setValue(valueToSearch);

    if (isSafeSearching && !checkSaveSearching(valueToSearch)) {
      return
    }

    onSearch(valueToSearch);
  }

  return {
    value,
    handleFocus,
    handleBlur,
    handleCancel,
    setValue,
    searchPatient
  }
}

export default useSearchPatient;
