import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
  creditCard: {},
  isFetched: false,
  isFetching: false,
  isSaving: false,
};

export default handleActions({
  [constants.PAYMENT_INFO_SAVE]: state => ({
    ...state,
    isSaving: true,
  }),

  [constants.PAYMENT_INFO_SAVE_SUCCESS]: (state, action) => ({
    ...state,
    creditCard: action.payload.result,
    isSaving: false,
  }),

  [constants.PAYMENT_INFO_SAVE_FAIL]: state => ({
    ...state,
    isSaving: false,
  }),

  [constants.PAYMENT_INFO_FETCH]: state => ({
    ...state,
    isFetching: true,
  }),

  [constants.PAYMENT_INFO_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    creditCard: {
      ...action.payload.result,
    },
    isFetching: false,
    isFetched: true,
  }),

  [constants.PAYMENT_INFO_FETCH_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),

}, initialState);
