import { connect } from 'react-redux';

import { fetchDrChronoToken } from 'modules/drChronoAuth/actions';

import * as selectors from 'modules/drChronoAuth/selectors';

const mapStateToProps = state => ({
  isDrChronoTokenFetching: selectors.getIsFetching(state),
  isDrChronoTokenFetched: selectors.getIsFetched(state),
  isShouldShowWarning: selectors.isShouldShowWarning(state.drChronoAuth),
});

const mapActionCreators = {
  fetchDrChronoToken,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
