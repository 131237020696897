import { useEffect, useRef } from 'react';

export const useClickOutSide = ({ onOutsideClick, exclude = [] }) => {
  const ref = useRef();

  /**
   * Some ref initialized after has been opened and after rendering it can be null
   */
  useEffect(() => {
    function handleClick(event) {
      if (exclude.some(excludeRef => excludeRef?.current?.contains(event.target))) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    
    document.addEventListener('mousedown', handleClick);
    
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onOutsideClick]);

  return ref;
};