import colors from 'styles/colors.scss';

const CUSTOM_STYLES = {
  control: (styles, { widthControl, heightControl, isDisabled }) => ({
    ...styles,
    borderRadius: 0,
    boxShadow: 'none',
    border: '1px solid #b3b3b3',
    width: widthControl,
    height: heightControl,
    minHeight: heightControl,
    backgroundColor: isDisabled ? 'white' : 'white',
    ':hover': {
      borderColor: '#b3b3b3',
    },
  }),
  input: (styles, { fontSize }) => ({
    ...styles,
    fontSize: fontSize || 'inherit',
    // height: '26px',
    // lineHeight: '26px',
    // 'input': {
    //   transform: 'translateY(-50%)',
    //   fontSize: '12px',
    //   border: 'none',
    // },
  }),
  valueContainer: styles => ({
    ...styles,
    paddingBottom: 0,
    paddingTop: 0,
    height: '100%',
  }),
  singleValue: (styles, { fontSize }) => ({
    ...styles,
    maxWidth: '80%',
    fontSize: fontSize || 'inherit',
  }),
  placeholder: (styles, { fontSize }) => ({
    ...styles,
    fontSize: fontSize || 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? colors.basicEhr : 'white',
    color: isSelected ? 'white' : 'inherit',
    ':hover': {
      backgroundColor: colors.basicEhr,
      color: 'white',
    },
  }),
  dropdownIndicator: styles => ({
    ...styles,
  }),
  indicatorsContainer: styles => ({
    ...styles,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: styles => ({
    ...styles,
    width: '30px',
    ':hover': {
      color: 'red'
    }
  }),
  menu: styles => ({
    ...styles,
    zIndex: 10
  }),
  menuList: styles => ({
    ...styles,
    zIndex: 10,
    maxHeight: 200,

  }),
};

export const CUSTOM_WITH_STYLED_DROP_ZONE = ({
  widthControl,
  heightControl,
  fontSize,
  isDisplayDropdownIndicator = true,
  placeholder,
  dropdownIndicatorSize = 'inherit',
  selectOption = {},
  controlStyles = {},
  valueContainer = {},
  indicatorsContainer = () => {},
  dropdownIndicator = {},
  multiValueLabel = {},
}) => ({
  ...CUSTOM_STYLES,
  control: styles => ({
    ...CUSTOM_STYLES.control(styles, { widthControl, heightControl }),
    position: 'relative',
    ...controlStyles,
  }),
  valueContainer: styles => ({
    ...CUSTOM_STYLES.valueContainer(styles),
    ...valueContainer
  }),
  indicatorsContainer: (styles, state) => ({
    ...CUSTOM_STYLES.indicatorsContainer(styles),
    height: '100%',
    ...indicatorsContainer(state),
  }),
  singleValue: styles => ({
    ...CUSTOM_STYLES.singleValue(styles, { fontSize }),
  }),
  placeholder: styles => ({
    ...CUSTOM_STYLES.placeholder(styles, { fontSize }),
    ...placeholder,
  }),
  input: styles => ({
    ...CUSTOM_STYLES.input(styles, { fontSize }),
    // fontSize: fontSize || 'inherit',
  }),
  dropdownIndicator: (styles, { isFocused }) => ({
    ...styles,
    cursor: 'pointer',
    display: !isDisplayDropdownIndicator ? 'none' : 'flex',
    justifyContent: 'center',
    background: colors.basicEhr,
    height: heightControl - 2,
    maxHeight: heightControl - 2,
    width: dropdownIndicatorSize,
    color: 'white',
    ':hover': {
      color: isFocused ? 'white' : 'white',
    },
    '& > svg': {
      width: 15,
      alignSelf: 'center',
    },
    ...dropdownIndicator,
  }),
  option: (styles, { isSelected }) => ({
    ...CUSTOM_STYLES.option(styles, { isSelected }),
    ...selectOption,
    color: isSelected ? 'white' : 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  multiValueLabel: styles => ({
    ...styles,
    ...multiValueLabel
  }),
});

export const medSelectOptions = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 40,
  dropdownIndicatorSize: 40,
  controlStyles: {
    paddingLeft: 25
  }
})

export const multiSelectOptions = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 40,
  dropdownIndicatorSize: 40,
  controlStyles: {
    paddingLeft: 25,
    height: 'auto'
  },
  indicatorsContainer: ({ hasValue }) => ({
    height: 'auto',
    flexDirection: hasValue ? 'row' : 'row-reverse'
  }),
  dropdownIndicator: {
    height: '100%',
    maxHeight: '100%'
  },
  multiValueLabel: {
    whiteSpace: 'initial',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
})

export default CUSTOM_STYLES;
