export const illnessItems = [{
  name: 'Chronic',
  value: true,
}, {
  name: 'Acute',
  value: false,
}];

export const statusItems = [{
  name: 'Active',
  value: true,
}, {
  name: 'Inactive',
  value: false,
}];
