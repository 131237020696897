import { isEmbedded } from 'helpers';

const EmbeddedLayout = ({ children }) => {
  if (isEmbedded()) {
    return null;
  }

  return children;
};

export default EmbeddedLayout;
