export const DIAGNOSES = 'DIAGNOSES';
export const MEDICATIONS = 'MEDICATIONS';
export const ALLERGIES = 'ALLERGIES';
export const FAMILY = 'FAMILY';
export const MEDICAL = 'MEDICAL';
export const SURGICAL = 'SURGICAL';
export const SCREENING = 'SCREENING';

export const HISTORY_ITEMS_FETCH = 'HISTORY_ITEMS_FETCH';
export const HISTORY_ITEMS_FETCH_SUCCESS = 'HISTORY_ITEMS_FETCH_SUCCESS';
export const HISTORY_ITEMS_FETCH_FAIL = 'HISTORY_ITEMS_FETCH_FAIL';

export const HISTORY_ITEM_FETCH = 'HISTORY_ITEM_FETCH';
export const HISTORY_ITEM_FETCH_SUCCESS = 'HISTORY_ITEM_FETCH_SUCCESS';
export const HISTORY_ITEM_FETCH_FAIL = 'HISTORY_ITEM_FETCH_FAIL';

export const HISTORY_ITEM_CREATE = 'HISTORY_ITEM_CREATE';
export const HISTORY_ITEM_CREATE_SUCCESS = 'HISTORY_ITEM_CREATE_SUCCESS';
export const HISTORY_ITEM_CREATE_FAIL = 'HISTORY_ITEM_CREATE_FAIL';

export const HISTORY_ITEM_UPDATE = 'HISTORY_ITEM_UPDATE';
export const HISTORY_ITEM_UPDATE_SUCCESS = 'HISTORY_ITEM_UPDATE_SUCCESS';
export const HISTORY_ITEM_UPDATE_FAIL = 'HISTORY_ITEM_UPDATE_FAIL';

export const HISTORY_ITEM_NEW = 'HISTORY_ITEM_NEW';
export const HISTORY_ITEM_NEW_SUCCESS = 'HISTORY_ITEM_NEW_SUCCESS';
export const HISTORY_ITEM_NEW_FAIL = 'HISTORY_ITEM_NEW_FAIL';

export const HISTORY_ITEM_DELETE = 'HISTORY_ITEM_DELETE';
export const HISTORY_ITEM_DELETE_SUCCESS = 'HISTORY_ITEM_DELETE_SUCCESS';
export const HISTORY_ITEM_DELETE_FAIL = 'HISTORY_ITEM_DELETE_FAIL';

export const HISTORY_ITEM_DELETE_FROM_LIST = 'HISTORY_ITEM_DELETE_FROM_LIST';
