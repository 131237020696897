import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from './PaymentNotification.module.scss';
import { Link } from 'react-router-dom';

import pluralize from 'pluralize';
import { SEVERITY_MAP } from 'helpers/billing/constants';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

class PaymentNotification extends Component {
  state = {
    visible: true,
    seconds: 10,
  };

  componentDidMount() {
    const { visible } = this.state;
    const {
      pastdue,
    } = this.props;

    if (!pastdue.showPaymentWarning || !visible || pastdue.severity !== 2) return;

    this.setTimer();
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  setTimer() {
    const { close } = this;

    this.timer = setInterval(() => {
      const seconds = this.state.seconds - 1;
      this.setState({
        seconds: Math.ceil(seconds % (60 * 60) % 60),
      });
      if (seconds === 0) {
        clearInterval(this.timer);
        close();
      }
    }, 1000);
  }

  close = () => {
    this.timer && clearTimeout(this.timer);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, seconds } = this.state;

    const {
      pastdue,
    } = this.props;

    if (!pastdue.showPaymentWarning || !visible) return null;

    const {
      severity,
      isReadOnly,
      isBeforeReadOnly,
      daysToReadOnly,
      isOrangeIcon,
    } = pastdue;

    return (
      <div className={
        cssClassesResolver([
          cx['payment-notification--active'],
          cx['payment-notification']
        ])}
      >
        <div className={cx['payment-notification-container']}>
          <div className={cx['payment-notification-overlay']} />
          <div className={cx['payment-notification-content']}>
            <div className={cssClassesResolver([
              cx['payment-notification-item'],
              cx['payment-notification-item--base']
            ])
            }>
              <div className={cssClassesResolver([
                cx['payment-notification-title'],
                  cx[`payment-notification-title-${SEVERITY_MAP[severity]}`]
              ])
              }>
                {severity === 2 && <img alt="Red" src={require('images/warning-red.png')} className={cx['warning-triangle']} />}
                {isOrangeIcon && <img alt="Orange" src={require('images/warning-orange.png')} className={cx['warning-triangle']} />}
                Payment has not been received
              </div>
              {isBeforeReadOnly === true && isReadOnly === false && (
                <div className={cssClassesResolver([
                  cx['payment-notification-data'],
                  cx['message']
                ])}>
                    You have the one day !!!
                </div>
                )}
              {isReadOnly === false && isBeforeReadOnly === false && (
                <div className={cssClassesResolver([
                  cx['payment-notification-data'],
                  cx['message']
                ])}>
                  Your account will be switched to read-only mode in<br />
                  <span className={cx['days-to-read-only']}>
                    {daysToReadOnly} {pluralize('day', daysToReadOnly)}
                  </span>
                </div>
              )}
              {isReadOnly === true && isBeforeReadOnly === false && (
                <div className={cssClassesResolver([
                  cx['payment-notification-data'], cx['message']
                ])}>
                  Your account is in read-only mode
                </div>
              )}
              <div className={cx['payment-notification-data']}>
                <div className={cx['payment-notification-controls']}>
                  {severity !== 2 && <button type="button" className={cx['button']} onClick={this.close}>Close</button>}
                  <Link className={cssClassesResolver([
                    cx['button'], cx['button--main']
                  ])} to="/app/doctor/settings/financial/payments_plans?subscribe=1" onClick={this.close}>Update</Link>
                </div>
                {severity === 2 && (
                  <div className={cx['payment-notification-seconds']}>This window will be closed in {seconds} {pluralize('second', seconds)}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentNotification.propTypes = {
  pastdue: PropTypes.object.isRequired,
};

export default PaymentNotification;
