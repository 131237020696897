export default [
  {
    title: 'medication',
    subtitle: 'Name',
    sortable: true,
  },
  {
    title: 'instructions',
    subtitle: 'SIG',
    sortable: true,
  },
  {
    title: 'use time',
    subtitle: 'Start/Stop',
    sortable: true,
  },
];
