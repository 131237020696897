import { handleActions } from 'redux-actions';

import * as chartingSessionConstants from 'modules/chartingSessions/constants';

export const initialState = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

export default handleActions({
  [chartingSessionConstants.SAVE_CHARTING_SESSION_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const { step } = action.payload;

    if (typeof step === 'undefined') return nextState;

    return {
      ...nextState,
      [step]: false,
    };
  },
}, initialState);
