import { connect } from 'react-redux';

import ElementsFetchLayerHOC from 'containers/ElementsFetchLayerHOC';

import { fetchResources, removeResource } from 'modules/resources/actions';
import * as chartingSessionsActions from 'modules/chartingSessions/actions';
import {
  createSystem,
  createSubelement,
  updateSystem,
  deleteRightSideElement,
  deleteSeveralRightSideElements,
} from 'modules/chartingAssets/actions';
import { addIndexToAutoSet } from 'modules/autoOpenSubElementsModal/actions';
import { resetFavorites } from 'modules/resetFavorites/actions';
import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import { getActiveItems } from 'modules/rootSelectors';
import { getFavoritesToReset, resetBroadOrExactFavorites } from 'modules/resetFavorites/selectors';

import fetchRightSideItemsProducer from 'helpers/right-side-items/fetch/producer';

const mapStateToProps = (state, ownProps) => {
  const {
    chartId,
    stepId,
    systemId,
    isChartSaving,
    broadMatch,
    exactMatch,
  } = ownProps;

  const system = chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId);
  const items = fetchRightSideItemsProducer({
    state, chartId, stepId, systemId,
  });

  const activeItems = getActiveItems(state, { stepId, chartId, systemId });

  const favoritesToReset = getFavoritesToReset(state.resetFavorites, chartId);

  const broadFavoritesAfterReset = resetBroadOrExactFavorites(state.resetFavorites, broadMatch, chartId);
  const exactFavoritesAfterReset = resetBroadOrExactFavorites(state.resetFavorites, exactMatch, chartId);

  return {
    items,
    activeItems,
    filterable: system && (system.type === 1 || system.type === 2),
    disableSubelements: system && system.type === 2,
    systemType: system && system.type,
    system,
    systemId,
    stepId,
    step: stepId,
    chartId,
    isFetching: !!systemId && (chartingAssetsSelectors.getStatus(state.chartingAssets).isFetching || chartingAssetsSelectors.getStatus(state.chartingAssets, chartId).isFetching),
    isChartSaving,
    favoritesToReset,
    broadMatch: broadFavoritesAfterReset,
    exactMatch: exactFavoritesAfterReset,
  };
};

const mapActionCreators = {
  addSystem: chartingSessionsActions.addSystem,
  addElement: chartingSessionsActions.addElement,
  createSubelement,
  createItem: createSystem,
  updateItem: updateSystem,
  fetchResources,
  deleteRightSideElement,
  deleteSeveralRightSideElements,
  removeResource,
  deleteElementFromSession: chartingSessionsActions.removeElementInAllSystems,
  addIndexToAutoSet,
  saveChart: chartingSessionsActions.saveChart,
  resetFavorites,
};

export default component => connect(mapStateToProps, mapActionCreators)(ElementsFetchLayerHOC(component));
