import React, { useState, useCallback } from 'react';
import { node, func } from 'prop-types';
import { useDropzone } from 'react-dropzone'

import Loader from 'components/Loader';

import cx from './PatientsImport.module.scss';

const PatientsImport = (props) => {
  const [uploading, setUploading] = useState(false);
  const { children, fetchPatients, uploadCSV } = props;

  const onDrop = useCallback(acceptedFiles => {
    if (!acceptedFiles.length || uploading) return;

    setUploading(true);

    uploadCSV(acceptedFiles[0]).then(() => {
      setUploading(false);
      fetchPatients();
    }, () => setUploading(false));
  }, [fetchPatients, uploading])

  const DROPZONE_OPTIONS = {
    onDrop,
    accept: '.csv',
    disabled: uploading,
    maxSize: 500000,
    noDrag: true,
    multiple: false
  }

  const { getRootProps, getInputProps } = useDropzone(DROPZONE_OPTIONS)

  return (
    <div className={cx.dropzone} {...getRootProps()}>
      <input {...getInputProps()} />
       {children}
       {uploading && <Loader className={cx.loader} />}
    </div>
  );
}

PatientsImport.propTypes = {
  children: node.isRequired,
  fetchPatients: func.isRequired,
  uploadCSV: func.isRequired
}

export default PatientsImport;
