import React from 'react';
import PropTypes from 'prop-types';

import * as seatHelpers from 'components/SeatUsersDropdown/helpers';

import cx from './RenderPlanDescription.module.scss';
import renderBillingInfoStyles from 'components/RenderBillingInfo/RenderBillingInfo.module.scss';

const RenderPlanDescription = (props) => {
  const {
    paymentsPlans, seats,
  } = props;

  return (
    <div className={renderBillingInfoStyles['plan-wrapper']}>
      <div className={renderBillingInfoStyles['plan-header']}>Your current plans</div>
      <div className={renderBillingInfoStyles['plan-name']}>
        VIP:
        <span className={renderBillingInfoStyles['plan-seatSet']}>{seatHelpers.getSeatsCounter(seats, seatHelpers.SEAT_PLANS.vipPlan.name)}</span>
      </div>
      {
        paymentsPlans.listPlans.map(currentPlan => (
          <div key={currentPlan.name} className={cx['plan-name']}>
            {currentPlan.name}:
            <span className={renderBillingInfoStyles['plan-seatSet']}>
              {seatHelpers.getSeatsCounter(seats, currentPlan.name)}
            </span>
          </div>
        ))
      }
    </div>
  );
};

RenderPlanDescription.propTypes = {
  paymentsPlans: PropTypes.object.isRequired,
  seats: PropTypes.object.isRequired,
};

export default RenderPlanDescription;
