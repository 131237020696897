import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';

import DropdownIndicator from 'components/MedSelect/DropdownIndicator'
import SelectContainer from 'components/MedSelect/SelectContainer';

import { selectOption } from 'helpers/propsGenerator';
import getSearchingResultsOptionsBasicStrategy from 'helpers/fmss/searching-results/options/strategies/basic';
import isEmpty from 'helpers/common/array/is-empty'
import { isNullOrUndefined } from 'helpers';

const basicValueMapper = valueOption => ({ value: valueOption.id, label: valueOption.name })

const defaultOnChangeOptionMapper = option => ({ id: option.value, name: option.label })

const MedSelect  = (props) => {
  const [defaultOptions, setDefaultOptions] = useState([])

  const {
    placeholder,
    loadOptions,
    value = selectOption.medDefault,
    promptTextCreatorLabel = '',
    multi = false,
    isClearable = true,
    onChange,
    onCreate,
    name,
    isCreatable = false,
    loadOptionsMapper = getSearchingResultsOptionsBasicStrategy,
    onChangeOptionMapper,
    valueMapper = basicValueMapper,
    styles,
    replacementComponents = {},
    autoload = true,
  } = props;

  useEffect(() => {
    const asyncLoadDefaultOptions  = async () => {
      const emptyInput = ""
      const response = await loadOptions(emptyInput)
      if (!isEmpty(response?.body)) {
        setDefaultOptions(loadOptionsMapper(response.body, emptyInput))
      }
    }

    if (autoload) {
      asyncLoadDefaultOptions()
    }
  }, [])

  const debouncedGetOptions = debounce((input, callback) => {
    const isGrantSearching = !isNullOrUndefined(input) && input.length >= 3

    if (isGrantSearching) {
      loadOptions(input)
        .then(
          result => callback(loadOptionsMapper(result.body, input)),
          () => callback([])
        );
    }
  }, 1000);

  const handleCreateOption = async (createdName) => {
    const createResponse = await onCreate(createdName)
    if (Number.isInteger(createResponse?.body)) {
      const { body } = createResponse
      onChange({ id: body, name: createdName })
    }
  }

  const resolveOnChangeOptionMapper = () =>
    isNullOrUndefined(onChangeOptionMapper) ? defaultOnChangeOptionMapper : onChangeOptionMapper

  const handleChange = (selectedValue) => {
    const optionMapper = resolveOnChangeOptionMapper()
    if (multi) {
        onChange(
          isEmpty(selectedValue)
            ? []
            : selectedValue.map(optionMapper)
        )
    } else {
      onChange(selectedValue ? optionMapper(selectedValue) : null)
    }
  }

  let options

  if (multi) {
    options = isEmpty(value) ? [] : value.map(valueMapper);
  } else {
    options = value ? valueMapper(value) : null;
  }

  if (isCreatable) {
    return (
      <AsyncCreatableSelect
        autoload={autoload}
        components={{ SelectContainer, DropdownIndicator, ...replacementComponents }}
        name={name}
        defaultOptions={defaultOptions}
        onCreateOption={handleCreateOption}
        isMulti={multi}
        placeholder={placeholder}
        loadOptions={debouncedGetOptions}
        value={options}
        onChange={handleChange}
        cacheOptions
        formatCreateLabel={label => `Create ${promptTextCreatorLabel || 'item'}: '${label}'`}
        isClearable={isClearable}
        styles={styles}
      />
    );
  }

  return (
    <AsyncSelect
      placeholder={placeholder}
      autoload={autoload}
      components={{ ...replacementComponents }}
      defaultOptions={defaultOptions}
      loadOptions={debouncedGetOptions}
      value={options}
      onChange={handleChange}
      isClearable={isClearable}
      styles={styles}
    />
  )
}

MedSelect.propTypes = {
  autoload: PropTypes.bool,
  loadOptionsMapper: PropTypes.func,
  onChangeOptionMapper: PropTypes.func,
  valueMapper: PropTypes.func,
  isCreatable: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([selectOption.medObj, selectOption.medArr]),
  onCreate: PropTypes.func,
  promptTextCreatorLabel: PropTypes.string,
  multi: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  replacementComponents: PropTypes.object,
};

export default MedSelect;
