import React from 'react';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/ProgressNotesSettings/SettingsItem/SettingsItem.module.scss';

const checkBoxStyles = ({ checked }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [checked ? 'checkbox--active' : 'checkbox']
});

const SettingsItem = (props) => {

  const { name, checked, onClick, label } = props;

  const handleClick = () => onClick(name);

  return (
    <li className={cx['list-item']}>
      <div className={cx['element-wrapper']}>
        <span
          onClick={handleClick}
          className={cx.label}
        >
          {label}
        </span>
        <div className={cx['input-container']}>
          <i
            onClick={handleClick}
            className={checkBoxStyles({ checked })}
          />
        </div>
      </div>
    </li>
  );
};

export default SettingsItem;
