import AdditionalCheckboxDataTemplate from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxDataTemplate';

import { getAllergies } from 'modules/patientsHx/selectors';

import { ALLERGY_STATUS } from 'helpers/fmss/allergies/constants';
import { byNotPresentNoKnownAllergy } from 'helpers/fmss/allergies/noKnowAllergiesFilter';

export default class AdditionalAllergyCheckboxDataFactory {
  constructor({ patientId, state }) {
    this.patientId = patientId;
    this.state = state;
  }

  getData = () => {
    const additionalCheckboxDataTemplate = new AdditionalCheckboxDataTemplate({
      patientId: this.patientId,
      state: this.state,
      checkboxParamName: 'hasNoDrugAllergies',
      items: this.getItems().filter(byNotPresentNoKnownAllergy),
    });

    return additionalCheckboxDataTemplate.getMappedProps();
  };

  getItems = () => {
    return getAllergies(this.state.patientsHx, this.patientId, ALLERGY_STATUS.active);
  }
}
