import React from 'react';
import validator from 'validator';
import moment from 'moment';

import * as Yup from 'yup';
import { isNullOrUndefined } from 'helpers/index';

const phonePattern = /^(\([2-9][0-9]{2}\) |[2-9][0-9]{2}-)[2-9][0-9]{2}-[0-9]{4}$/;
const phoneAllEqualDigit = /^([0-9])\1{9}$/;
const needDigitOnly = /[^0-9]/g;
const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

export const isZip = value => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
const isPhoneNumber = value => phonePattern.test(value) && !phoneAllEqualDigit.test(value.replace(needDigitOnly, ''));

const isZipUnrequired = (value) => {
  if (!value) {
    return true;
  }

  return isZip(value);
};

export const renderError = (message, options = {}) => {
  let { customClass } = options;

  customClass = customClass || '';

  return <span className={`validation-error ${customClass}`}>{message}</span>;
};

const passwordRegEx = /((?=.*[A-Z])(?=.*[\W|_]).{8,})/;

const MESSAGES = {
  required: 'Please enter value',
  email: 'Please enter valid email',
};

export const validators = {
  required: Yup.string().required(MESSAGES.required),
  requiredSelectOption: Yup
    .object()
    .nullable(true)
    .test(
      'notEmptySelectOption',
      MESSAGES.required,
      option => isNullOrUndefined(option) ? false : option?.id && option?.name
    ),
  dates: {
    rule: (value, components) => {
      const startDate = components.startdate.state.value;
      const endDate = components.enddate.state.value;

      if (startDate && endDate) {
        const diffInDays = moment(startDate).diff(moment(endDate), 'days');
        return diffInDays <= 0;
      }

      return true;
    },
    hint: () => 'Wrong dates',
  },
  emailUnrequired: {
    rule: (value) => {
      if (!value) {
        return true;
      }

      return validator.isEmail(value);
    },
    hint: () => renderError('Please enter valid email'),
  },
  /**
   * It is used in ClinicForm
   */
  requiredPhone:
    Yup.string()
      .required(MESSAGES.required)
      .test(
        'requiredPhone',
        'Please enter valid phone number',
        function isValidPhone (value) { return value?.toString().trim() ? isPhoneNumber(value) : true;}
      ),
  phone:
    Yup.string()
      .test(
        'phone',
        'Please enter valid phone number',
        function isValidPhone (value) { return value?.toString().trim() ? isPhoneNumber(value) : true;}
      ),
  zip: {
    rule: value => isZip(value),
    hint: () => renderError('Please enter valid zip code'),
  },
  zipUnrequired:
    Yup.string()
      .test(
        'zipUnrequired',
        'Please enter valid zip code',
        value => isZipUnrequired(value)
      ),
  passwordValidation:
    Yup.string()
      .test(
        'password',
        'Password must be at least 8 characters and contain a capital letter and a symbol',
        value => (value?.toString()?.trim().length >= 8) && (passwordRegEx.test(value))
      ),
  passwordsMatch:
    Yup.string()
      .test(
        'passwordsMatch',
        'Passwords should be equal',
        function isPasswordsMatch (value) { return this?.parent?.password === value}
      ),
  requiredEmail: Yup.string().email(MESSAGES.email).required(MESSAGES.required),
  email: Yup.string().email(MESSAGES.email),
  passwordLength: {
    rule: value => value.toString().trim().length >= 8,
    hint: () => renderError('Please enter at least 8 characters'),
  },
  globalPasswordValidation: {
    rule: (value) => {
      return (value.toString().trim().length >= 8) && (passwordRegEx.test(value));
    },
    hint: () => renderError('Password must be at least 8 characters and contain a capital letter and a symbol'),
  },
  ssn:
    Yup.string()
      .test(
        'ssn',
        'Please enter valid ssn',
        value => isNullOrUndefined(value) ? true : ssnPattern.test(value)
      ),
  pin: {
    rule: value => /\d{6}/.test(value.toString().trim()),
    hint: () => renderError('Please enter 6 digits'),
  },
};

export const buildSchema = (fieldToValidatorName = {}) => {
  const validations = Object.keys(fieldToValidatorName)
    .reduce((previousValidations, nextField) => {
      return {
        ...previousValidations,
        [nextField]: validators[fieldToValidatorName[nextField]]
      }
    }, {})

  return Yup.object({
    ...validations,
  })
}
