import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const toDisplay = ownProps.medications
    .filter(patientMedication => patientMedication.active &&
      !(patientMedication.printInChart === false && ownProps.chartId === patientMedication.chartId));

  return {
    medications: toDisplay
  };
};

export const MedicationsProgressNoteContainer = component => connect(mapStateToProps, null)(component);
