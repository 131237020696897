import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import { selectOption } from 'helpers/propsGenerator';

const StaticOptions = (props) => {
  const {
    value = null,
    isClearable = true,
    className = '',
    options,
    styles = CUSTOM_WITH_STYLED_DROP_ZONE,
    disabled = false,
    onChange,
    placeholder = 'Select...',
    isSearchable = true,
  } = props;

  const handleChange = (option) =>
    onChange(option);

  const findInOptionsByValue = (searchableValue) => {
    return  options.filter(option => option?.value === searchableValue)[0];
  };

  const currentOption = typeof value === 'object' ? value : findInOptionsByValue(value);

  return (
    <Select
      placeholder={placeholder}
      value={currentOption}
      isClearable={isClearable}
      isSearchable={isSearchable}
      className={className}
      options={options}
      onChange={handleChange}
      disabled={disabled}
      styles={styles}
      menuPortalTarget={document.body}
    />
  );
};

StaticOptions.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: selectOption.arr.isRequired,
  value: selectOption.obj,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  styles: PropTypes.object,
  menuPortalTarget: PropTypes.node,
};

export default StaticOptions;
