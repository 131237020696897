import * as fromBySystem from './bySystem/selectors';
import * as fromById from './byId/selectors';

export const getIsElementsFetching = (state, systemId) =>
  fromBySystem.getIsElementsFetching(state.bySystem, systemId);

export const getElementById = (state, elementId) => fromById.getElementById(state.byId, elementId);

export const getElementsBySystem = (state, systemId) =>
  fromBySystem.getElementsBySystem(state.bySystem, systemId)
    .map(elementId => getElementById(state, elementId))
    .filter(element => element && !element.deleted);
