import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import SeatsContainer, { fetchLayerHOC as seatsFetchLayer } from 'containers/SeatsContainer';

import { setHasPerformedPresenceValidator } from 'modules/presenceValidator/actions';
import { setPurchaseSeatModalsView } from 'modules/purchaseSeat/actions';

import isEmpty from 'helpers/common/array/is-empty';

class SeatsPresenceValidator extends Component {
  static propTypes = {
    areSeatsFetched: PropTypes.bool.isRequired,
    seats: PropTypes.object.isRequired,
    isAgreementAccepted: PropTypes.bool.isRequired,
    setHasPerformedPresenceValidator: PropTypes.func.isRequired,
    presenceValidator: PropTypes.shape({
      hasPerformedPresenceValidator: PropTypes.bool,
    }).isRequired,
    setPurchaseSeatModalsView: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      areSeatsFetched,
      seats,
      setHasPerformedPresenceValidator,
      presenceValidator,
      setPurchaseSeatModalsView,
      isAgreementAccepted,
    } = nextProps;

    const {
      hasPerformedPresenceValidator,
    } = presenceValidator;

    const areSeatsEmpty = isEmpty(Object.keys(seats));
    if (!hasPerformedPresenceValidator && areSeatsFetched && areSeatsEmpty && isAgreementAccepted === true) {
      setPurchaseSeatModalsView({
        isPlansModalOpen: true,
      });
      setHasPerformedPresenceValidator(true);
    }
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    presenceValidator: state.presenceValidator,
  };
};

const mapDispatchToProps = {
  setHasPerformedPresenceValidator,
  setPurchaseSeatModalsView,
};

const ComposedSeatsPresenceValidator = compose(
  SeatsContainer,
  seatsFetchLayer,
)(SeatsPresenceValidator);

export default connect(mapStateToProps, mapDispatchToProps)(ComposedSeatsPresenceValidator);
