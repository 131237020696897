import { connect } from 'react-redux';

import * as actions from 'modules/financial/paymentInfo/actions';

import * as selectors from 'modules/financial/paymentsPlans/selectors';
import { getSeats } from 'modules/seats/selectors';
import { getPaymentInfo } from 'modules/financial/paymentInfo/selectors';

const mapStateToProps = (state, ownProps) => {
  const {
    isFetched,
    creditCard,
  } = getPaymentInfo(state.financial);

  return {
    paymentsPlans: selectors.getPaymentsPlans(state.financial),
    isFetchedPaymentInfo: isFetched,
    creditCard,
    ...ownProps,
    seats: getSeats(state.seats),
  };
};

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
