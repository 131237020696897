import React from 'react';
import cx from './FlatButton.module.scss';
import PropTypes from 'prop-types';


const FlatButton = ({
  label,
  onClick,
  href,
}) =>
  React.createElement(href ? 'a' : 'button', {
    href,
    onClick,
    className: cx.wrapper,
  }, label);

FlatButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

FlatButton.defaultProps = {
  href: undefined,
  onClick: () => {},
};

export default FlatButton;
