import { combineReducers } from 'redux';
import isAttached from './isAttached';
import isFetching from './isFetching';
import isFetched from './isFetched';
import shouldShowWarning from './shouldShowWarning';
import importData from './import';
import offices from './offices'

export default combineReducers({
  isFetching,
  isFetched,
  isAttached,
  shouldShowWarning,
  importData,
  offices
});
