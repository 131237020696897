import React from 'react';
import { compose } from 'redux';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import medicationsContainer, { fetchLayerHOC } from 'containers/Medications/MedicationsContainer';
import MedicationsPage from 'containers/Medications/MedicationsPage';

const MedicationsContainer = compose(
  chartToPatientContainer,
  medicationsContainer,
  fetchLayerHOC,
)(MedicationsPage);

export const ChartMedicationList = () => {
  const [searchParams] = useSearchParams();
  const { chartId } = useParams();
  return (
      <MedicationsContainer
        filter="active"
        medicationId={searchParams.get('medication')}
        linkBase={`/app/doctor/charts/${chartId}/medications`}
      >
        <Outlet />
      </MedicationsContainer>
  );
};
