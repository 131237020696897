import { NOT_DISPLAYED_AMOUNT_PLANS } from 'components/SeatUsersDropdown/helpers.js';

export default class PlansService {
  constructor(plans = []) {
    this.plans = plans;
  }

  byIncludeNotDisplayedAmountPlansFilter = plan => NOT_DISPLAYED_AMOUNT_PLANS.includes(plan.name)

  filterPlansToDisplayAmount = () =>
    this.plans.map((plan) => {
      if (this.byIncludeNotDisplayedAmountPlansFilter(plan)) {
        return {
          ...plan,
          amount: null,
        };
      }
      return plan;
    })
}
