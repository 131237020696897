export default class UpdateChartSubElementNameResponse {
  constructor({
    elementId, favoriteId, id, name,
  }) {
    this._id = id;
    this._elementId = elementId;
    this._favoriteId = favoriteId;
    this._name = name;
  }

  get id() {
    return this._id;
  }

  get elementId() {
    return this._elementId;
  }

  get favoriteId() {
    return this._favoriteId;
  }

  get name() {
    return this._name;
  }
}
