import { connect } from 'react-redux';

import React, { useEffect } from 'react';

import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import { SURGICAL } from 'modules/patientsHx/constants';
import { getPatientById } from 'modules/patients/selectors';

export const fetchLayerHOC = (Component) => {
  return props => {
    const {
      isFetching,
      patientId,
      fetchSurgical
    } = props;

    useEffect(() => {
      if (patientId && isFetching === undefined) {
        fetchSurgical(patientId);
      }
    }, [patientId]);

    return <Component {...props} />;
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isFetching: selectors.getIsFetchingList(state.patientsHx, SURGICAL, ownProps.patientId),
    surgicalItems: selectors.getSurgicalItems(state.patientsHx, ownProps.patientId),
    error: selectors.getListError(state.patientsHx, SURGICAL, ownProps.patientId),
    selectedId: ownProps.surgicalItemId,
    hasNoPreviousSurgeries: getPatientById(state.patients, ownProps.patientId)?.hasNoPreviousSurgeries,
  };
};

const mapActionCreators = {
  fetchSurgical: actions.fetchSurgical,
  createSurgicalItem: actions.createSurgicalItem,
  updateSurgicalItem: actions.updateSurgicalItem,
  deleteSurgicalItem: actions.deleteSurgicalItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
