import { createOrUpdateHxRvSystem } from 'modules/chartingSessions/actions';
import { saveChartDetails } from 'modules/charts/actions';
import { ADD_ELEMENT } from 'modules/chartingSessions/constants';

import * as constants from './constants';

import * as chartingHelpers from 'helpers/charting/charting';
import * as whiteProps from 'helpers/charting/whiteListProps';

import * as api from 'api/chartingAssets';
import * as chartingApi from 'api/charting';

export const fetchChartingAssets = chartId => ({
  types: [
    constants.CHARTING_ASSETS_FETCH,
    constants.CHARTING_ASSETS_FETCH_SUCCESS,
    constants.CHARTING_ASSETS_FETCH_FAIL,
  ],
  promise: async (dispatch) => {
    if (chartId) {
      const chartDetailsResponse = await api.fetchChartingAssetsByChart(chartId);
      dispatch(saveChartDetails(chartId, chartDetailsResponse.body.systems));

      return chartDetailsResponse;
    }

    return api.fetchChartingAssets();
  },
  chartId,
});

export const createSystem = system => ({
  types: [
    constants.SYSTEM_CREATE,
    constants.SYSTEM_CREATE_SUCCESS,
    constants.SYSTEM_CREATE_FAIL,
  ],
  promise: () => api.createSystem(system),
  chartId: system.chartId,
});

export const updateSystem = system => ({
  types: [
    constants.SYSTEM_UPDATE,
    constants.SYSTEM_UPDATE_SUCCESS,
    constants.SYSTEM_UPDATE_FAIL,
  ],
  promise: () => api.updateSystem(system),
  chartId: system.chartId,
});

export const deleteSystem = (chartId, system) => ({
  types: [
    constants.SYSTEM_DELETE,
    constants.SYSTEM_DELETE_SUCCESS,
    constants.SYSTEM_DELETE_FAIL,
  ],
  promise: () => api.deleteSystem(system.id),
  chartId,
  systemId: system.id,
  chartingId: system.chartingId,
});


const _createElement = (chartId, elementToCreate, elementToAdd, systemId) => ({
  types: [
    constants.ELEMENT_CREATE,
    constants.ELEMENT_CREATE_SUCCESS,
    constants.ELEMENT_CREATE_FAIL,
  ],
  promise: () => {
    return api.createElement(chartingHelpers.formatParamsWithWhiteList(elementToCreate, whiteProps.whitePropsForCretingElementRequest));
  },
  elementToAdd,
  elementToCreate,
  chartId,
  systemId,
});

export const createElement = (chartId, elementToCreate, elementToAdd) => (dispatch, getState) => {
  const _elementToCreate = { ...elementToCreate };

  createOrUpdateHxRvSystem({
    dispatch,
    getState,
    actionType: ADD_ELEMENT,
    systemId: elementToCreate.systemId,
    chartId,
    element: elementToCreate,
  });

  return dispatch(_createElement(chartId, _elementToCreate, elementToAdd, elementToCreate.systemId));
};

export const updateElement = (newElement, oldElement, chartId, systemId, chartingId) => ({
  types: [
    constants.ELEMENT_UPDATE,
    constants.ELEMENT_UPDATE_SUCCESS,
    constants.ELEMENT_UPDATE_FAIL,
  ],
  promise: () => api.updateChartElement({
    chartId, id: oldElement.id, name: newElement.name, systemId,
  }),
  newElement,
  oldElement,
  chartId,
  systemId,
  chartingId,
});

export const updateElementWrapper = (newElement, oldElement, chartId, systemId, chartingId) => (dispatch, getState) => {
  createOrUpdateHxRvSystem({
    dispatch,
    getState,
    actionType: ADD_ELEMENT,
    systemId,
    chartId,
    element: newElement,
  });

  return dispatch(updateElement(newElement, oldElement, chartId, systemId, chartingId));
};

export const createSubelement = subelement => ({
  types: [
    constants.SUBELEMENT_CREATE,
    constants.SUBELEMENT_CREATE_SUCCESS,
    constants.SUBELEMENT_CREATE_FAIL,
  ],
  promise: () => api.createSubelement(subelement),
});

export const deleteRightSideElement = data => ({
  types: [
    constants.ELEMENT_DELETE,
    constants.ELEMENT_DELETE_SUCCESS,
    constants.ELEMENT_DELETE_FAIL,
  ],
  promise: () => chartingApi.removeRightSideElement(data),
  id: data.id,
});

export const deleteSeveralRightSideElements = (dataForRequest, dataForLocalStorage) => ({
  types: [
    constants.ELEMENT_DELETE,
    constants.ELEMENTS_DELETE_SUCCESS,
    constants.ELEMENTS_DELETE_FAIL,
  ],
  promise: () => chartingApi.mergeElements(dataForRequest),
  dataForLocalStorage,
});

export const addAssetSystem = system => ({
  type: constants.ADD_ASSET_SYSTEM,
  payload: {
    ...system
  }
})
