import isEmpty from 'helpers/common/array/is-empty'

export const getOffices = state => state.providersOffices || []

export const getSelectedProvider = state => state.selectedProviderId

export const getSelectedOffice = state => state.selectedOffice

export const getSelectedOfficeRoom = state => state.selectedOfficeRoom

export const getRoomsBySelectedOffice = (state) => {
  const offices = getOffices(state)

  const selectedOffice = getSelectedOffice(state)

  if (isEmpty(offices) || selectedOffice === null) {
    return []
  }

  return offices.filter(office => office.id === selectedOffice)[0].rooms
}
