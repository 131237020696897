const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchRoles = () =>
  request.get('/api/rest/roles/')
    .end();

export const removeRole = id =>
  request
    .post('/api/rest/roles/delete')
    .send({ id })
    .end();

export const createRole = role =>
  request
    .post('/api/rest/roles/create')
    .send(role)
    .end();

export const updateRole = (role) => {
  return request
    .post('/api/rest/roles/update')
    .send(role)
    .end();
};
