import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import * as actions from 'modules/users/actions';
import * as selectors from 'modules/users/selectors';
import * as userSelectors from 'modules/user/selectors';
import { updatePermissions } from 'modules/user/actions';

import { isNullOrUndefined } from 'helpers';

export const fetchLayerHOC = (Component) => {
  return class UsersFetchLayer extends React.Component {
    static propTypes = {
      fetchUsers: PropTypes.func.isRequired,
      fetchUserImage: PropTypes.func.isRequired,
      isFetching: PropTypes.bool,
    };

    static defaultProps = {
      isFetching: null,
    };

    async componentDidMount() {
      if (!isNullOrUndefined(this.props.isFetching)) return;
      await this.props.fetchUsers();
    }

    render() {
      const { isFetching } = this.props;
      if (isFetching || isNullOrUndefined(isFetching)) return null;

      return <Component {...this.props} />;
    }
  };
};

const mapFetchLayerStateToProps = state => ({
  isFetching: selectors.getIsFetching(state.users),
});


const mapActionCreators = {
  ...actions,
  updatePermissions,
};

export const connectedFetchLayerHOC = component => connect(mapFetchLayerStateToProps, mapActionCreators)(fetchLayerHOC(component));

export default component => connect(null, mapActionCreators)(component);
