import React, { Component } from 'react';
import PropTypes from 'prop-types';

import verge from 'verge';

import { uniqBy, isEmpty } from 'lodash';

import Subelement from 'components/SubelementsList/Subelement';
import RegularElement from 'components/ElementsViewItem/RegularElement';
import Loader from 'components/Loader';

import * as elementsConstants from 'helpers/elements/constants';
import * as favoritesHelpers from 'helpers/favorites/favoritesHelpers';
import { search, parseElementNameWithVariables, isNullOrUndefined } from 'helpers';
import { formatParamsWithWhiteList } from 'helpers/charting/charting';
import { updateSocialSubElementParams } from 'helpers/charting/whiteListProps';
import UpdateSubElementsAfterUpdatingOne from 'helpers/subelements/update-name';
import stringParser from 'helpers/common/string/string-parser';
import updateArrayItemByIndex from 'helpers/common/array/update-item/by-index';

import SocialSubElementsResponse from 'response/social/sub-elements/fetch';
import UpdateSocialSubElementNameResponse from 'response/social/sub-elements/update-name';
import UpdateChartSubElementNameResponse from 'response/chart/sub-elements/update-name';

import { fetchSubelements, updateSubelement } from 'api/chartingAssets';
import { fetchSocialSubElements, updateSocialSubElement } from 'api/social';

import cx from './SubelementsList.module.scss';

class SubelementsList extends Component {
  state = {
    subelements: [],
    value: '',
    listMaxHeight: 0,
  };

  componentDidMount() {
    this.fetchSubelements();
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate() {
    this.onInitialized();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  onInitialized = () => {
    this.props.onInitialized && this.props.onInitialized();
  };

  getSubelementForUpdate = (subelement) => {
    const {
      patientId,
      setNumber,
    } = this.props;

    if (this.props.social === true) {
      const newSubElement = { ...subelement, patientId };
      return formatParamsWithWhiteList(newSubElement, updateSocialSubElementParams);
    }

    const newSubelement = { ...subelement, setNumber };

    delete newSubelement.favoriteId;

    return newSubelement;
  };

  getSubelementToAdd = subelementId => ({
    ...this.getDefaultSubElement(),
    id: subelementId,
    favoriteId: subelementId
  });

  getDefaultSubElement = () => {
    return {
      elementId: this.props.elementId,
      name: this.state.value,
      numberGroup: this.props.setNumber,
      position: this.props.position,
    };
  };

  getSetNumber = () => {
    if (this.props.currentItem) {
      const { setNumber } = parseElementNameWithVariables(this.props.currentItem.name)
        .variables[0];
      return setNumber;
    }

    return this.props.setNumber;
  };

  getFuncForFetchSubElements = (elementId, systemId, patientId, setNumber) => {
    if (this.props.social === true) {
      return fetchSocialSubElements(elementId, systemId, setNumber);
    }

    return fetchSubelements(elementId, setNumber);
  };

  getFuncForSocialUpdate = () => {
    if (this.props.social === true) {
      return updateSocialSubElement;
    }

    return updateSubelement;
  };

  getDefaultSubElementForCreateRequest = () => {
    const {
      value,
    } = this.state;

    return { name: value };
  };

  getChartSubElementForCreateRequest = () => {
    const {
      elementId,
      setNumber,
      chartId,
      systemId,
      step,
      position,
    } = this.props;

    return {
      ...this.getDefaultSubElementForCreateRequest(),
      elementId,
      setNumber,
      chartId,
      systemId,
      chartingId: step,
      position,
    };
  };

  getSubElementForCreateRequest = () =>
    (this.props.social === true
      ? this.getDefaultSubElementForCreateRequest()
      : this.getChartSubElementForCreateRequest());

  getSavingSubelement = subelement => ({
    ...subelement,
    isSaving: true,
  });

  handleSubelementSelect = async (subelement) => {
    const updatedSubElementWithExtraProps = this.attacheExtraPropsToSubElement(subelement);
    await this.props.onSubelementSelect(updatedSubElementWithExtraProps);
  };

  attacheExtraPropsToSubElement = (subElement) => {
    const {
      position,
    } = subElement;

    if (isNullOrUndefined(position)) {
      return {
        ...subElement,
        position: this.props.position,
      };
    }

    return subElement;
  };

  handleInputChange = (e) => {
    console.log('d');
    this.setState({ value: e.target.value }, () => {
      this.onInitialized();
    });
  };

  fetchSubelements = async () => {
    if (this.state.isFetching) return;

    if (this.props.element.id === 'new') {
      this.setState({
        subelements: [],
        isFetching: false,
        initialized: true,
      }, this.onInitialized);
      return;
    }

    this.setState({
      isFetching: true,
    });

    const {
      element,
      systemId,
      patientId,
      social,
    } = this.props;

    const setNumber = this.getSetNumber();

    let subelements = await this.getFuncForFetchSubElements(element.id, systemId, patientId, setNumber)
      .then(res => res.body);

    if (social) {
      subelements = this.formatSocialSubElements(subelements);
    }

    this.setState({
      subelements,
      isFetching: false,
      initialized: true,
    }, this.onInitialized);
  };

  formatSocialSubElements = (responseSubElements) => {
    if (isNullOrUndefined(responseSubElements) || isEmpty(responseSubElements)) {
      return [];
    }

    const parsedSubElementsFromServer = SocialSubElementsResponse.parse(responseSubElements);
    const subElementsWithNumberGroup = [];
    const {
      setNumber,
      position,
    } = this.props;

    parsedSubElementsFromServer.forEach((subElement) => {
      const newSubElement = {
        ...subElement,
        id: stringParser(subElement.id),
        numberGroup: setNumber,
        position,
        elementId: this.props.elementId,
      };
      subElementsWithNumberGroup.push(newSubElement);
    });

    return subElementsWithNumberGroup;
  };

  close = () => {
    if (!this.props.preventCloseOnSubmit) {
      this.props.onClose();
    }
  };

  createOrAddSubelement = () => {
    const {
      disableCreation,
    } = this.props;

    const { subelements } = this.state;

    const { value } = this.state;
    const searchValue = value.trim();

    const results = search(subelements, searchValue, 'name');

    const firstFoundItem = results.length && results[0];

    if (firstFoundItem) {
      this.close();
      return this.handleSubelementSelect(firstFoundItem, this.props.element);
    }

    return !disableCreation && this.createSubelement();
  };

  fetchSubelementIdFromResponse = (responsePromise) => {
    return stringParser(responsePromise.text);
  };

  createSubelement = async () => {
    if (this.state.isFetching || !this.state.value.trim()
      .length) return;

    this.setState({ isFetching: true });

    try {
      const subelement = this.getSubElementForCreateRequest();
      const res = await this.props.createSubelement(subelement);

      const subelementId = this.fetchSubelementIdFromResponse(res);

      const createdSubelement = this.getSubelementToAdd(subelementId);

      const newSubelements = [
        ...this.state.subelements,
        createdSubelement,
      ];

      this.setState({
        isFetching: false,
        value: '',
        subelements: newSubelements,
      });
      this.close();
      await this.handleSubelementSelect(createdSubelement, this.props.element);
    } catch (e) {
      this.setState({ isFetching: false });
    }
  };

  handleKeyUp = (e) => {
    if (this.state.blockKeyUp) return;
    switch (e.keyCode) {
      case 13:
        this.createOrAddSubelement();
        break;
      case 27:
        this.props.onClose();
        break;
      default:
        break;
    }
  };

  handleWindowResize = () => {
    this.setState({
      listMaxHeight: verge.viewportH() / 4,
    });
  };

  isSubelementSaving = subelement => subelement.isSaving;

  updateSubelementsInState = newSubelements =>
    this.setState(({ subelements: newSubelements }));

  updateSubelement = async (subelement) => {
    const indexSubelementForUpdate = this.state.subelements.findIndex(o => o.id === subelement.id);
    const updatedSubElement = this.state.subelements[indexSubelementForUpdate];

    if (indexSubelementForUpdate === -1 || this.isSubelementSaving(updatedSubElement)) return;

    const subelementsWithSavingOne = updateArrayItemByIndex(
      this.state.subelements, indexSubelementForUpdate, this.getSavingSubelement(updatedSubElement)
    );

    this.updateSubelementsInState(subelementsWithSavingOne);

    const res = await this.getFuncForSocialUpdate()(this.getSubelementForUpdate(subelement));
    let newSubElementId;
    if (this.props.social) {
      const updateSocialSubElementNameResponse = new UpdateSocialSubElementNameResponse(res.body);
      newSubElementId = updateSocialSubElementNameResponse.id;
    } else {
      const updateChartSubElementNameResponse = new UpdateChartSubElementNameResponse(res.body);
      newSubElementId = updateChartSubElementNameResponse.id;
    }

    const newSubElements = UpdateSubElementsAfterUpdatingOne.update(this.state.subelements, subelement, newSubElementId);
    this.updateSubelementsInState(newSubElements);
  };

  handleSubelementClick = async subelement =>
    this.handleSubelementSelect(subelement);


  handleElementAdd = async () =>
    this.createSubelement();


  render() {
    const {
      subelements,
      initialized,
      isFetching,
      step,
      value,
      listMaxHeight
    } = this.state;

    const {
      isChartSaving,
      disableCreation,
      systemId,
      exactMatch,
      broadMatch,
      social
    } = this.props;

    const renderedSubelements = favoritesHelpers.sortItems(subelements, exactMatch, broadMatch, systemId);

    const broadItems = favoritesHelpers.getFavoritesArray(broadMatch);
    const exactItems = favoritesHelpers.getFavoritesArray(exactMatch);

    let results = search(renderedSubelements, value, 'name');

    results = results.filter(result => result.name.length > 0);
    results = uniqBy(results, item => item.name);

    const elementAlreadyExists = subelements.map(o => o.name.trim()
      .toLowerCase())
      .includes(value.trim().toLowerCase());

    const showCreateButton = !elementAlreadyExists && !!value.trim()
      .length && !disableCreation;

    return (
      <div
        className={cx.wrapper}
        onKeyUp={this.handleKeyUp}
      >
        <div className={cx['input-wrapper']}>
          <input
            value={value}
            onChange={this.handleInputChange}
            className={cx.input}
            placeholder="Enter / Select"
          />
        </div>
        {(!!results.length || !!value.length) && (
          <div
            className={cx['subelements-wrapper']}
            style={{
              maxHeight: listMaxHeight,
            }}
          >
            {results.map(subelement => (
              <Subelement
                key={subelement.id}
                onClick={() => this.handleSubelementClick(subelement)}
                subelement={subelement}
                match={favoritesHelpers.getItemMatch(subelement.favoriteId, broadItems, exactItems, step === 1)}
                onToggleEdit={editMode => this.setState({ blockKeyUp: editMode })}
                updateSubelement={this.updateSubelement}
                social={social}
              />
            ))}
            {showCreateButton && (<RegularElement
              key="add"
              item={{
                name: value,
              }}
              circleButtonText={elementsConstants.ELEMENTS_TYPES.new.text}
              onClick={this.handleElementAdd}
            />)}
          </div>
        )}
        {!results.length && initialized && !value.length && <div className={cx['no-subelements']}>No items</div>}
        {(isFetching || isChartSaving) && (
          <div
            className={cx.loading__overlay}
          >
            <Loader className={cx.loading} />
          </div>
        )}
      </div>
    );
  }
}

SubelementsList.propTypes = {
  onInitialized: PropTypes.func,
  preventCloseOnSubmit: PropTypes.bool,
  onClose: PropTypes.func,
  onSubelementSelect: PropTypes.func,
  createSubelement: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableCreation: PropTypes.bool,
  setNumber: PropTypes.number,
  social: PropTypes.bool,
  currentItem: PropTypes.object,
  position: PropTypes.number,
  patientId: PropTypes.number,
  systemId: PropTypes.number,
  element: PropTypes.shape({
    id: PropTypes.number,
    subIds: PropTypes.array,
    numberGroup: PropTypes.number,
  })
    .isRequired,
  isChartSaving: PropTypes.bool,
  elementId: PropTypes.number,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
}

SubelementsList.defaultProps = {
  position: undefined,
  setNumber: 0,
  exactMatch: {},
  broadMatch: {},
}

export default SubelementsList;
