import React from 'react';
import PropTypes from 'prop-types';
import ChartReportTable from '../../ChartReportTable';
import ChartReportHeadline from '../../ChartReportHeadline';
import ChartReportSection from '../../ChartReportSection';
import cx from '../HPNote.module.scss';

import ReportSystem from '../ReportChartingStep/ReportSystem';
import SystemNoteContainer from 'containers/SystemNoteContainer';

import ChartReportAssessmentElement from './ChartReportAssessmentElement';
import { withRouter } from 'containers/RouterParams';
import Modal from 'components/Modal';

const ConnectedReportSystem = SystemNoteContainer(ReportSystem);

const headers = [
  {
    title: 'Type',
    width: '10%',
  },
  {
    title: 'Code',
    width: '30%',
  },
  {
    title: 'Description',
    width: '60%',
  },
];

const modalData = {
  type: 'confirm',
  title: 'Remove system',
  content: '<p>Are you sure you want to remove this system?</p><p class="warning-text"><strong>Warning:</strong> All enclosed elements will be removed!</p>',
};

const modalDataFixed = {
  type: 'confirm',
  title: 'Clear contents',
  content: '<p><strong style="color: orange">Warning:</strong> Are you sure you want to remove all enclosed elements?</p>',
};

class ChartReportAssessment extends React.Component {
  static propTypes = {
    toggleChartReport: PropTypes.func.isRequired,
    removeElement: PropTypes.func.isRequired,
    enclosedElements: PropTypes.object.isRequired,
    systems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    viewMode: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    removeSystem: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    systemNotes: PropTypes.object.isRequired,
    deleteSystemNote: PropTypes.func.isRequired,
  };

  static defaultProps = {
    viewMode: undefined,
  };

  state = {
    isModalOpen: false,
    systemToDelete: null,
  };

  openModal = (system) => {
    // Modal component is shit and doesn't update if its props
    // are changed when it is already rendered, so we have
    // to update system first and then show modal
    this.setState({
      systemToDelete: system,
    }, () => this.setState({ isModalOpen: true }));
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      systemToDelete: null,
    });
  };

  modalConfirm = () => {
    const { systemToDelete } = this.state;

    this.props.removeSystem(this.props.chartId, systemToDelete);
    this.closeModal();
  };

  render() {
    const {
      enclosedElements,
      systems,
      toggleChartReport,
      removeElement,
      chartId,
      step,
      location,
      viewMode,
      title,
      patient,
    } = this.props;

    const { isModalOpen, systemToDelete } = this.state;

    if (!systems?.length) return null;

    const assessmentSystem = systems[1];

    // there are only two systems here - ICD-10 and Assessment
    // we need only ICD-10
    const icd10Elements = enclosedElements[systems[0].id];
    const assessmentElements = enclosedElements[systems[1].id];

    const hasIcd10 = icd10Elements && icd10Elements.length > 0;
    const hasAssessment = assessmentElements?.length > 0;

    if (!hasIcd10 && !hasAssessment) return null;

    const currentDiagnosis = location.query.diagnosis && parseInt(location.query.diagnosis);

    const { openModal, closeModal, modalConfirm } = this;

    return (
      <ChartReportSection>
        <div className={cx['report-star']} />

        <ChartReportHeadline
          link={!viewMode && `/app/doctor/charts/${chartId}?step=${step}`}
          toggleChartReport={!viewMode && toggleChartReport}
        >
          {title}
        </ChartReportHeadline>

        {hasIcd10 && (
          <ChartReportTable
            headers={headers}
          >
            {icd10Elements.map(element => (
              <ChartReportAssessmentElement
                key={element.id}
                element={element}
                toggleChartReport={toggleChartReport}
                chartId={chartId}
                step={step}
                viewMode={viewMode}
                onRemove={() => removeElement({
                  chartId,
                  systemId: element.systemId,
                  element,
                  step: currentDiagnosis === element.id && location.pathname
                })}
              />
            ))}
          </ChartReportTable>
        )}
        {/* просто разделитель чтобы таблица не слипалась с секцией */}
        {hasIcd10 && hasAssessment && <div style={{ height: 20 }} />}

        {hasAssessment && (
          <ConnectedReportSystem
            key={assessmentSystem.id}
            system={assessmentSystem}
            elements={enclosedElements[assessmentSystem.id]}
            removeElement={element => removeElement({ chartId, systemId: assessmentSystem.id, element })}
            onSystemRemove={openModal}
            viewMode={viewMode}
            patient={patient}
            // for navigation to progress note
            toggleChartReport={toggleChartReport}
            chartId={chartId}
            step={step}
          />
        )}

        <Modal
          data={systemToDelete && systemToDelete.fixed ? modalDataFixed : modalData}
          isOpen={isModalOpen}
          onModalClose={closeModal}
          onModalConfirm={modalConfirm}
        />

      </ChartReportSection>

    );
  }
}

export default withRouter(ChartReportAssessment);
