import React from 'react';
import { number, func, bool } from 'prop-types';

import Loader from 'components/Loader';
import ElementsViewItems from 'components/ElementsViewItems';

import { elementsViewItemActionsModalData } from 'components/ElementsViewItemActions/data';

import { createSocialSubElement } from 'api/social';

import cx from './SocialItems.module.scss';

const SocialItems = (props) => {
  const {
    isFetching,
    activeSystem,
    items,
    activeItems,
    isSessionFetching,
    patientId,
    updateSocialSubElementsHistory,
    addIndexToAutoSet,
    addSystem,
    addElement,
    chartId,
    selectSubElementWrapper,
    selectElement,
    deleteRightSideElement
  } = props;

  const addSystemWrapper = systemId => addSystem(systemId, patientId);

  const addElementWrapper = (systemId, element) => {
    addElement(patientId, systemId, element, { needToAdd: true });
  };

  const selectSubElementCallback = (element, subElement) =>
    selectSubElementWrapper(element, subElement, patientId, activeSystem, chartId);


  if (isFetching !== false || isSessionFetching !== false) return <Loader />;

  return (
    <ElementsViewItems
      selectSocialElement={selectElement}
      items={items}
      className={cx['social-items']}
      addElement={addElementWrapper}
      addSystem={addSystemWrapper}
      systemId={activeSystem}
      isFetching={isFetching}
      activeItems={activeItems || []}
      createSubelement={createSocialSubElement}
      social
      patientId={patientId}
      addIndexToAutoSet={addIndexToAutoSet}
      updateSocialSubElementsHistory={updateSocialSubElementsHistory}
      chartId={chartId}
      selectSubElementCallback={selectSubElementCallback}
			deleteRightSideElement={rest => deleteRightSideElement({ ...rest, socialId: activeSystem })}
      editMode={{
        actions: [
          elementsViewItemActionsModalData.removeElement.id
        ]
      }}
    />
  );
};

SocialItems.propTypes = {
  updateSocialSubElementsHistory: func.isRequired,
  deleteRightSideElement: func.isRequired,
  addIndexToAutoSet: func.isRequired,
  addSystem: func.isRequired,
  addElement: func.isRequired,
  isFetching: bool,
  isSessionFetching: bool,
  activeSystem: number,
  selectSubElementWrapper: func,
  patientId: number.isRequired
};

SocialItems.defaultProps = {
  activeSystem: null,
  isFetching: false,
  isSessionFetching: false,
  selectSubElementWrapper: () => {},
};

export default SocialItems;
