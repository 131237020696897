import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { useImpersonate } from 'hooks/useImpersonate';

import { authGetHeally as authGetHeallyAction, logout } from 'modules/user/auth/actions';
import { fetchUser } from 'modules/user/actions';
import { getIsAuthenticated, getIsFetching, isBlankClinic } from 'modules/user/selectors';

import { isEmbedded } from 'helpers';

const missAuthCheckUrls = [
  '/app/recovery',
  '/app/signup'
];

const permittedUrls = [
  '/app/login',
  '/app/drchrono-login',
  '/app/signup',
  '/app/privacy-policy',
  '/app/user-agreement',
  '/app/terms-of-use',
  '/app/recovery',
];

const needRedirectUrl = [
  '/',
  '',
  '/app',
  '/app/'
];

const requireAuthentication = (ChildComponent) => (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { logout } = useImpersonate();
  const {
    authGetHeally,
    isAuthenticated,
    fetchUser,
    isUserFetching,
    isBlankClinic
  } = props;

  const checkAuth = useCallback(async () => {
    if (missAuthCheckUrls.includes(pathname)) return;

    const nextLocation = pathname === '/app/drchrono-login' ? '/app/drchrono-login' : '/app/login';

    try {
      const { statusCode } = await fetchUser();
      if (statusCode === 204) {
        logout();
      }
      if (statusCode === 200 && needRedirectUrl.includes(pathname)) {
        navigate('/app/doctor');
      }
    } catch (e) {
      navigate(nextLocation);
    }
  }, [pathname]);

  useEffect(() => {
    if (isEmbedded() && !isAuthenticated) {
      authGetHeally(Object.fromEntries(searchParams.entries()));
    } else {
      checkAuth();
    }
  }, []);

  const isUnsigned = !isAuthenticated || isBlankClinic;

  if (
    (isUnsigned || isUserFetching)
    && !permittedUrls.includes(pathname)
  ) {
    return null;
  }

  return (
    <ChildComponent {...props} />
  );
};

requireAuthentication.propTypes = {
  authGetHeally: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isUserFetching: PropTypes.bool.isRequired,
  isBlankClinic: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state.user),
  isUserFetching: getIsFetching(state.user),
  isBlankClinic: isBlankClinic(state.user)
});

const mapActionCreators = {
  authGetHeally: authGetHeallyAction,
  fetchUser,
  logout,
};

export default component => connect(mapStateToProps, mapActionCreators)(requireAuthentication(component));
