import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import TabsHeader from './TabsHeader';
import TabsBody from './TabsBody';

import cx from './Tabs.module.scss';

const Tabs = props => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    headerItems,
    children,
    type
  } = props;

  useEffect(() => {
    if (isCurrentRouteRestricted(props)) {
      const firstAvailableLink = headerItems.find(o => !o.disabled);
      navigate(!firstAvailableLink ? '/app/doctor' : firstAvailableLink.url);
    }
  }, [pathname]);

  const isCurrentRouteRestricted = ({ headerItems }) => {
    let currentRouteLink = headerItems.find(o => !o.indexLink && pathname.includes(o.url));

    if (!currentRouteLink) {
      currentRouteLink = headerItems.find(o => o.url === pathname);
    }

    return currentRouteLink?.disabled;
  };

  const changeTab = tabNum => {
    setActiveTab(tabNum);
  };

  return (
    <div className={`${cx.wrapper} custom-wrapper`}>
      <TabsHeader
        type={type}
        items={headerItems}
        activeTab={activeTab}
        onClick={changeTab}
      />
      <TabsBody type={type}>
        {/* check if current tab route is allowed before rendering */}
        {!isCurrentRouteRestricted(props) && children}
      </TabsBody>
    </div>
  );
};

Tabs.propTypes = {
  headerItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    mark: PropTypes.bool,
  })).isRequired,
  children: PropTypes.node,
};

export default Tabs;
