import { handleActions } from 'redux-actions';

import * as chartingHelpers from 'helpers/charting/charting';

import * as chartsConstants from '../../constants';
import * as chartingSessionsConstants from 'modules/chartingSessions/constants';

const defaultVitals = {
  temperature: null,
  pulse: null,
  pressureFirst: null,
  pressureSecond: null,
  respiratoryRate: null,
  oxygenSaturation: null,
  height: null,
  weight: null,
  pain: null,
  bloodSugar: null,
  smokingStatus: null,
  dateOfService: null,
};

export default handleActions({
  [chartsConstants.CHART_CREATE_SUCCESS]: (state, action) => ({
    ...defaultVitals,
    ...state,
    isFetching: false,
    error: false,
    chartId: chartingHelpers.fetchChartIdFromCreatingResponse(action.payload.result),
    patientId: action.payload.localChart.patientId,
    dateOfService: chartingHelpers.fetchDateOfSignFromCreatingResponse(action.payload.result),
  }),
  [chartingSessionsConstants.SAVE_CHARTING_SESSION]: (state, action) => ({
    ...defaultVitals,
    ...state,
    dateOfService: chartingHelpers.fetchDateOfSignFromCreatingResponse(action.payload),
  }),
  [chartsConstants.VITALS_FETCH]: (state, action) => ({
    ...state,
    isFetching: true,
    error: false,
  }),
  [chartsConstants.VITALS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result,
    error: false,
    isFetching: false,
  }),
  [chartsConstants.VITALS_FETCH_FAIL]: (state, action) => ({
    ...state,
    error: true,
    isFetching: false,
  }),
  [chartsConstants.VITALS_SAVE_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.vitals,
    error: false,
    isFetching: false,
  }),
}, undefined);
