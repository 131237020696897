export default class ActiveHcpcResource {
  constructor({
    id, description, favoriteId, name, ids,
  }) {
    this._id = id;
    this._description = description;
    this._favoriteId = favoriteId;
    this._name = name;
    this._ids = ids;
    this._hcpcMod = this._name;
  }

  getId = () => this._id
  getFavoriteId = () => this._favoriteId
  getDescription = () => this._description
  getHcpcMod = () => this._hcpcMod
  getIds = () => this._ids
  getName = () => this._name

  getFullName = () =>
    `${this._name} ${this._description}`
}
