export const formatDiagnosisForServer = diagnosis => {
  const result = { ...diagnosis };
  result.diagnosisId = result.diagnosis.id;
  delete result.diagnosis;

  return result;
};

export const diagnosisWhiteList = [
  'active',
  'chartId',
  'chronic',
  'description',
  'diagnosis',
  'id',
  'patientId',
  'diagnosedDate',
  'resolvedDate',
  'shouldAddDiagnosisToMedical',
];
