import { showBanner } from 'modules/banner';
import { getUserInfo } from 'modules/user/selectors';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: getUserInfo(state.user)
})

const mapDispatchToProps = {
  showBanner,
}

export default component => connect(mapStateToProps, mapDispatchToProps)(component)
