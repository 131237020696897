import React from 'react';
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import { formatLink } from 'helpers/URLUtils';

import cx from './DoorButton.module.scss';

const styles = `${cx.button} styleless-link`

const DoorButton = ({ style, link, onClick }) => {
  return (
    <Link
      className={styles}
      to={formatLink(link)}
      onClick={onClick}
      style={style}
    />
  );
};

DoorButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default DoorButton;
