import * as constants from './constants';

import * as api from 'api/clinic';

import { getImage } from 'api/images';

export const fetchClinicImage = imageName => ({
  types: [
    constants.FETCH_CLINIC_IMAGE,
    constants.FETCH_CLINIC_IMAGE_SUCCESS,
    constants.FETCH_CLINIC_IMAGE_FAIL,
  ],
  promise: () => getImage(imageName),
});

export const fetchClinicInfo = () => ({
  types: [
    constants.FETCH_CLINIC_INFO,
    constants.FETCH_CLINIC_INFO_SUCCESS,
    constants.FETCH_CLINIC_INFO_FAIL,
  ],
  promise: async (dispatch) => {
    const clinicInfo = await api.fetchClinicInfo();

    const { body } = clinicInfo;
    const { logo } = body;

    if (logo) {
      dispatch(fetchClinicImage(logo));
    }

    return clinicInfo;
  },
});

export const updateClinicInfo = (localClinicData, dataToRequest) => ({
  types: [
    constants.UPDATE_CLINIC_INFO,
    constants.UPDATE_CLINIC_INFO_SUCCESS,
    constants.UPDATE_CLINIC_INFO_FAIL,
  ],
  promise: () => api.updateClinicInfo(dataToRequest),
  clinic: localClinicData,
});
