const getUserFullName = (firstName, middleName, lastName) => {
  let fullName = firstName

  if (middleName) {
    fullName = `${fullName} ${middleName}`
  }

  return `${fullName} ${lastName}`
};

export default getUserFullName
