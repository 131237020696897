import React from 'react';
import cx from './PatientData.module.scss';
import PropTypes from 'prop-types';

const PatientData = ({
  children,
}) => (
  <div className={cx['patient-data']}>
    {children}
  </div>
);

PatientData.propTypes = {
  children: PropTypes.node,
};

export default PatientData;
