import { handleActions } from 'redux-actions';

const ENABLE_FORCE_FETCH = 'ENABLE_FORCE_FETCH';
const DISABLE_FORCE_FETCH = 'DISABLE_FORCE_FETCH';

const initialValue = false;

export default handleActions({
  [ENABLE_FORCE_FETCH]: (state, action) => true,

  [DISABLE_FORCE_FETCH]: (state, action) => false,
}, initialValue);

export const enableFavoritesForceFetch = () => ({
  type: ENABLE_FORCE_FETCH,
});

export const disableFavoritesForceFetch = () => ({
  type: DISABLE_FORCE_FETCH,
});