import * as constants from './constants';
import * as api from 'api/drchrono';

export const setDrChronoToken = (code) => ({
  types: [
    constants.DRCHRONO_TOKEN_SET,
    constants.DRCHRONO_TOKEN_SET_SUCCESS,
    constants.DRCHRONO_TOKEN_SET_FAIL,
  ],
  promise: () => api.setToken(code),
});

export const removeDrChronoToken = () => ({
  types: [
    constants.DRCHRONO_TOKEN_REMOVE,
    constants.DRCHRONO_TOKEN_REMOVE_SUCCESS,
    constants.DRCHRONO_TOKEN_REMOVE_FAIL,
  ],
  promise: () => api.removeToken(),
});

export const fetchDrChronoToken = () => ({
  types: [
    constants.DRCHRONO_TOKEN_FETCH,
    constants.DRCHRONO_TOKEN_FETCH_SUCCESS,
    constants.DRCHRONO_TOKEN_FETCH_FAIL,
  ],
  promise: () => api.fetchToken(),
});

export const setIsShowImportModal = isShowModal => ({
  type: constants.SET_IS_SHOW_IMPORT_MODAL,
  payload: {
    isShowModal,
  },
});

export const closeImportModal = () =>
  setIsShowImportModal(false);

export const turnSync = syncDirection => ({
  types: [
    constants.SET_TURN_SYNC,
    constants.SET_TURN_SYNC_SUCCESS,
    constants.SET_TURN_SYNC_FAIL,
  ],
  promise: () => api.turnSync(syncDirection)
})

export const fetchOffices = () => ({
  types: [
    constants.FETCH_OFFICES,
    constants.FETCH_OFFICES_SUCCESS,
    constants.FETCH_OFFICES_FAIL,
  ],
  promise: () => api.fetchOffices()
})

export const fetchActiveOffice = () => ({
  types: [
    constants.FETCH_ACTIVE_OFFICE,
    constants.FETCH_ACTIVE_OFFICE_SUCCESS,
    constants.FETCH_ACTIVE_OFFICE_FAIL,
  ],
  promise: () => api.fetchActiveOffice()
})

export const selectProvider = (provider) => ({
  type: constants.SELECT_PROVIDER,
  payload: { provider }
})

export const selectOffice = (selectedState, forServer) => ({
  types: [
    constants.SELECT_OFFICE,
    constants.SELECT_OFFICE_SUCCESS,
    constants.SELECT_OFFICE_FAIL,
  ],
  promise: () => api.setOfficeForLoggedUser(forServer),
  offices: selectedState
})

export const selectOfficeRoom = (room) => ({
  type: constants.SELECT_OFFICE_FAIL,
  payload: { room }
})
