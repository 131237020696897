import React from 'react';
import cx from './SortArrows.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

export default ({ desc }) => (
  <span className={
    cssClassesResolver([
      cx['arrows'],
      desc === true && cx['arrows--down'],
      desc === false && cx['arrows--up']

    ])}
  />
);
