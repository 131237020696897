import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tether from 'react-tether';

import EventListenerLayer from 'components/EventListenerLayer';
import SeatStatusContent from '../SeatStatusContent';

import cx from './SeatStatusDescription.module.scss';

const SeatStatusDescription = (props) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleDescription = () =>
    setIsVisible(!isVisible);

  const close = () =>
    setIsVisible(false);

  const {
    status,
  } = props;

  return (
    <Tether
      attachment="top center"
      targetAttachment="top center"
      constraints={[{
              to: 'scrollParent',
              attachment: 'together',
          }]}
      classPrefix="fullDescription"
      renderTarget={targetRef => (
        <span ref={targetRef}>
          <i className={cx['icon-status']} onClick={toggleDescription}>?</i>
        </span>
      )}
      renderElement={elementRef => isVisible && (
        <span ref={elementRef}>
          <EventListenerLayer
            onClickOutSide={close}
          >
            <span
              className={cx.fullDescription}
            >
              <SeatStatusContent status={status} />
            </span>
          </EventListenerLayer>
        </span>
      )}
    />
  );
}

SeatStatusDescription.propTypes = {
  status: PropTypes.object.isRequired,
}

export default SeatStatusDescription

