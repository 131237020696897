import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Tether from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import Tooltip from './Tooltip';

import cx from './ChartReportElementWithTooltip.module.scss';

const ChartReportElementWithTooltip = (props) => {
  const elementTooltipWrapperRef = useRef()

  const {
    children, isOpen = false, onOpen, onClose, disabled = false
  } = props;

  const dropdownRef = useClickOutSide({ onOutsideClick: onClose, exclude: [elementTooltipWrapperRef] });

  if (disabled) {
    return children[0];
  }

  return (
    <Tether
      attachment="bottom right"
      targetAttachment="top right"
      constraints={[{
        to: 'scrollParent',
        attachment: 'together',
      }]}
      classPrefix="reportElement"
      renderTarget={targetRef => {
        elementTooltipWrapperRef.current = targetRef.current;
        return (
          <a
            ref={targetRef}
            href={undefined}
            className={cx.button}
            onClick={isOpen ? onClose : onOpen}
          >
            {children[0]}
          </a>
        );
      }}
      renderElement={elementRef => isOpen && (
        <span ref={dropdownRef}>
          <Tooltip ref={elementRef}>
            {children[1]}
          </Tooltip>
        </span>
      )}
    />
  );
}

ChartReportElementWithTooltip.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
    .isRequired,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
};


export default ChartReportElementWithTooltip;
