import { handleActions } from 'redux-actions';

import { SET_HAS_PERFORMED_PRESENCE_VALIDATOR } from './constants';

const initialState = {
  hasPerformedPresenceValidator: false,
};

export default handleActions({
  [SET_HAS_PERFORMED_PRESENCE_VALIDATOR]: (state, action) => ({
    ...state,
    hasPerformedPresenceValidator: action.payload.hasPerformedPresenceValidator,
  }),
}, initialState);
