import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import { withRouter } from 'containers/RouterParams';

import Table from 'components/Table';
import Encounter from '../Encounter';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import AddButton from 'components/AddButton';
import NotAllowedStartNewNoteButton from 'components/NotAllowedStartNewNote';
import Header from 'components/Table/Header';
import { EmptyEncounter } from '../Encounter/EmptyEncounter';

import { inIframe } from 'helpers';
import getUserFullName from 'helpers/patient/full-name';
import { fetchChartIdFromCreatingResponse } from 'helpers/charting/charting';

import tableFields from './fields';

import cx from './EncountersTable.module.scss';

const deleteModalData = {
  type: 'keyword-confirm',
  keyWord: 'DELETE',
  title: 'Delete encounter',
  content: '<p>Are you sure you want to remove this encounter? Its data will be lost!',
};

const getSignModalData = signed => ({
  type: 'confirm',
  title: `${(signed && 'Unlock') || 'Sign'} chart`,
  content: `<p>Are you sure you want to ${(signed && 'unlock') || 'sign'} this chart?`,
});

let prevIsScrollVisible = null;

const sortParamsMap = {
  encounter: 'DOS',
  patient: 'PATIENT',
  'note type': 'NOTE_TYPE',
  reason: 'PATIENT_CC',
  status: 'COMPLETION_STATUS',
  provider: 'PROVIDER',
};

class EncountersTable extends PureComponent {
  constructor(props) {
    super(props);

    this.applyCustomTableFields();

    this.state = {
      deleteModal: {
        isOpen: false,
        chartId: null,
      },
      signModal: {
        isOpen: false,
        chartId: null,
      },
    };
  }

  componentDidMount() {
    // to determine if table has scrollbar we pass onTableMount callback to it
    // Table component mounts first and after fixed header
    // and there's no this.table in isScrollVisible available
    // to ensure that we know if there's scrollbar present we should forceUpdate on mount
    // so this component fully rerenders and calls isScrollVisible
    // when this.table is already present
    this.forceUpdate();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getSortedColumnTitle() {
    const { sortBy } = this.props;
    let columnTitle = '';

    Object.keys(sortParamsMap).forEach((key) => {
      const value = sortParamsMap[key];

      if (value === sortBy) {
        columnTitle = key;
      }
    });

    return columnTitle;
  }

  getProviderInitials(providerUserData) {
    const { firstName, lastName } = providerUserData;

    if (!firstName || !lastName) return '';

    return `${firstName[0].toUpperCase()}. ${lastName[0].toUpperCase()}.`;
  }

  handleSortClick = (column) => {
    const newSortBy = sortParamsMap[column];
    const defaultIsAsc = false;
    const {
      onSortToggle,
      sortBy,
      isAsc,
      signedStatus,
      activePage,
      activeProviderId,
    } = this.props;

    if (sortBy === newSortBy) {
      onSortToggle(sortBy, !isAsc, signedStatus);
      this.fetchCharts(activePage, sortBy, !isAsc, activeProviderId);
      return;
    }

    onSortToggle(newSortBy, defaultIsAsc, signedStatus);
    this.fetchCharts(activePage, newSortBy, defaultIsAsc, activeProviderId);
  };

  handleTableMount = (ref) => {
    this.table = ref;
  };

  isScrollVisible = () => {
    if (!this.table) return false;
    return this.table.scrollHeight > this.table.clientHeight;
  };

  applyCustomTableFields = () => {
    const { customTableFields } = this.props;

    this.tableFields = customTableFields || tableFields;
  };

  formatEncounterData = (encounter) => {
    if (typeof encounter === 'undefined') return;

    const providerUserDTO = encounter.providerUserDTO || {};

    let providerInitials = '';

    const {
      timeZoneDateConverter,
      elementNameConverter,
    } = this.context;

    const { firstName, middleName, lastName, patientDob, patientGender } = encounter;

    const patient = getUserFullName(firstName, middleName, lastName);
    const patientDobTimeZoned = timeZoneDateConverter.formattedDob(patientDob);

    if (
      typeof providerUserDTO !== 'undefined'
      && providerUserDTO !== null
    ) {
      providerInitials = this.getProviderInitials(providerUserDTO);
    }

    const syntheticPatient = {
      firstName,
      lastName,
      dob: patientDob,
      gender: patientGender.toUpperCase()
    };

    const cc = elementNameConverter.transformedSeveralNames(encounter?.patientCc, syntheticPatient);

    return {
      id: encounter.id,
      encounter: encounter.selectUserDate,
      rv: cc,
      providerInitials,
      patient,
      patientDob: patientDobTimeZoned,
      details: `${encounter.chartingType.name}`,
      status: encounter.status,
      signed: encounter.signed,
      processingSign: encounter.processingSign,
      signError: encounter.signError,
      cptCode: encounter.cptCode,
    };
  };

  formatEncounterDates = charts =>
    charts.map(encounter => ({
      ...encounter,
      encounter: this.context.timeZoneDateConverter.getFormattedDateWithTimeZone(encounter.encounter),
    }));


  handleWindowResize = () => {
    if (prevIsScrollVisible !== this.isScrollVisible()) {
      this.forceUpdate();
    }
  };

  openModal = (modal, chartId) => {
    this.setState({
      [modal]: {
        isOpen: true,
        chartId,
      },
    });
  };

  closeModal = (modal) => {
    this.setState({
      [modal]: {
        isOpen: false,
        chartId: null,
      },
    });
  };

  deleteChart = async () => {
    const { router, deleteChart, activeChart } = this.props;
    const { deleteModal } = this.state;

    const chartIdToDelete = deleteModal.chartId;

    await deleteChart(chartIdToDelete);

    const isCurrentChartActive = activeChart === chartIdToDelete;

    if (isCurrentChartActive) {
      router.push('/app/doctor');
    }

    this.closeModal('deleteModal');
  };

  changeChartSignedStatus = async () => {
    const {
      router,
      charts,
      fetchDashboardChartPages,
      changeSignedStatus,
    } = this.props;

    const { signModal } = this.state;
    const chart = charts.find(chart => chart.id === signModal.chartId);
    const { pathname } = router.getCurrentLocation();

    try {
      await changeSignedStatus({
        chartId: chart.id,
        signed: !chart.signed,
      });
    } catch (e) {
      console.error(e);
    }

    if (chart.signed) {
      router.push(`/app/doctor/charts/${chart.id}?step=1`);
      return;
    }

    if (pathname !== '/app/doctor') {
      router.push(`/app/doctor/patients/${chart.patientId}/encounters`);
    }

    if (pathname === '/app/doctor') {
      fetchDashboardChartPages();
    }

    this.closeModal('signModal');
  };

  createChart = async () => {
    const { isCreating, createChart, router } = this.props;

    if (isCreating) return false;

    const data = this.collectDataToCreateChartRequest();

    const { body } = await createChart(data);
    const chartId = fetchChartIdFromCreatingResponse(body);
    router.push(`/app/doctor/charts/${chartId}?step=1`);
  };

  collectDataToCreateChartRequest = () => {
    const {
      timeZoneDateConverter,
    } = this.context;

    return {
      chartingType: 1,
      patientId: this.props.patientId,
      dosDate: timeZoneDateConverter.getCurrentTimeZoneDateMs(),
    };
  };

  fetchCharts = (
    page,
    sortBy = this.props.sortBy || '',
    isAsc = this.props.isAsc || false,
    providerId = this.props.activeProviderId
  ) => {
    const {
      patientId,
      encountersType,
      fetchChartsByPage,
      fetchPatientChartsByPage,
      fetchLastChartsWithSorting,
    } = this.props;

    if (encountersType === 'general-encounters') {
      fetchChartsByPage(page, sortBy, isAsc, providerId);
      fetchLastChartsWithSorting(sortBy, isAsc, providerId);
    } else if (encountersType === 'patient-encounters') {
      fetchPatientChartsByPage(patientId, page, sortBy, isAsc, providerId);
    }
  };

  handleChangeSignedStatus = chartId => this.openModal('signModal', chartId);

  handleDelete = (chartId) => {
    if (!this.props.deleteEncounters) return;
    this.openModal('deleteModal', chartId);
  };

  handlePageClick = (data) => {
    const page = data.selected;

    const {
      signedStatus,
      onPageChange,
    } = this.props;

    this.fetchCharts(page);
    onPageChange(page, signedStatus);
  };

  confirmChangeSingStatusModal = () => {
    this.changeChartSignedStatus();
    this.closeModal('signModal');
  };

  confirmDeleteChartModal = () => {
    this.deleteChart();
    this.closeModal('deleteModal');
  };

  render() {
    const {
      charts = [],
      isFetching,
      isCreating,
      type,
      unlockSignedProgressNote,
      deleteEncounters,
      activeChart,
      patientId,
      userInfo,
      permissions,
      pageCount,
      isAsc,
      activePage,
      isBlockedForReadOnly,
      signedStatus
    } = this.props;

    let paginationClassName = 'pagination';

    if (typeof signedStatus !== 'undefined') {
      paginationClassName += `-${signedStatus}`;
    }

    const isPatientCharts = !!patientId;

    const {
      signModal,
      deleteModal,
    } = this.state;

    const {
      handleSortClick,
      handleTableMount,
      isScrollVisible,
      closeModal,
      formatEncounterDates,
      formatEncounterData,
    } = this;

    const sortByColumnTitle = this.getSortedColumnTitle();

    let renderedCharts = charts.map(formatEncounterData);
    renderedCharts = formatEncounterDates(renderedCharts);

    prevIsScrollVisible = isScrollVisible();

    const chartToSign = charts.find(o => o.id === signModal.chartId);
    const signModalData = getSignModalData(chartToSign?.signed);

    const showPagination = pageCount > 1;

    return (
      <div className={cx['outer-wrapper']}>
        {isPatientCharts && (
          isBlockedForReadOnly
            ?
            <NotAllowedStartNewNoteButton />
            :
            <div
              className={cx['new-chart-button-wrapper']}
            >
              <AddButton
                loading={isCreating}
                onClick={this.createChart}
              >
                Start New Note
              </AddButton>
            </div>
        )}
          <div className={cx.wrapper}>
            <Table
              color="blue"
              bodyColor="gray"
              onTableMount={handleTableMount}
            >
              <Header
                fields={this.tableFields}
                desc={!isAsc}
                onSort={handleSortClick}
                sortBy={sortByColumnTitle}
              />
              <tbody>
                {!charts.length && !isFetching ? (
                  <EmptyEncounter />
                ) :
                renderedCharts.map(
                encounter => (
                  <Encounter
                    fields={this.tableFields}
                    encounter={encounter}
                    userInfo={userInfo}
                    key={encounter.id}
                    onChangeSignedStatus={this.handleChangeSignedStatus}
                    unlockSignedProgressNote={unlockSignedProgressNote}
                    deleteEncounters={deleteEncounters}
                    onDelete={this.handleDelete}
                    active={encounter.id === activeChart}
                    permissions={permissions}
                  />
                )
              )}
              </tbody>
            </Table>

            {!inIframe() && showPagination &&
              <ReactPaginate
                previousLabel="«"
                nextLabel="»"
                breakLabel={<i>...</i>}
                breakClassName="break-me"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={cx[paginationClassName]}
                subContainerClassName="pages pagination"
                activeClassName={cx.active}
                // initialPage={activePage || 0}
                disableInitialCallback
                forcePage={activePage}
              />
            }

            <Modal
              data={deleteModalData}
              isOpen={deleteModal.isOpen}
              onModalClose={() => closeModal('deleteModal')}
              onModalConfirm={this.confirmDeleteChartModal}
            />

            <Modal
              data={signModalData}
              isOpen={signModal.isOpen}
              onModalClose={() => closeModal('signModal')}
              onModalConfirm={this.confirmChangeSingStatusModal}
            />

          </div>
        {isFetching && <div className={cx.overlay} />}
        {isFetching && <Loader />}
      </div>
    );
  }
}

EncountersTable.propTypes = {
  createChart: PropTypes.func,
  deleteChart: PropTypes.func.isRequired,
  changeSignedStatus: PropTypes.func.isRequired,
  isCreating: PropTypes.bool,
  fetchLastChartsWithSorting: PropTypes.func,
};

// EncountersTable.defaultProps = {
//   createChart: () => {},
//   type: 'encounters',
//   itemsPerPage: 20,
//   limit: false,
//   paginated: false,
//   isCreating: false,
//   fetchLastChartsWithSorting: () => {},
// };

EncountersTable.contextType = AppContext;

export default withRouter(EncountersTable);
