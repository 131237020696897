import { combineReducers } from 'redux';

import byId from './byId';
import byPatient from './byPatient';
import isFetching from './isFetching';
import isFetched from './isFetched';
import isSaving from './isSaving';

export default combineReducers({
  byId,
  byPatient,
  isFetched,
  isFetching,
  isSaving,
});
