import React, { useContext } from 'react';
import { shape, string, func, object } from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import Rv from 'containers/Encounters/Encounter/Rv';

import { DATE_FORMATS } from 'helpers/date/constants';

import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNoteHeader/Charts/Chart/Chart.module.scss';

const Chart = (props) => {
  const {
    chart,
    onClick,
    patient,
  } = props;

  const {
    timeZoneDateConverter,
    elementNameConverter,
  } = useContext(AppContext);

  const formattedDate = timeZoneDateConverter.getFormattedDateWithTimeZone(chart.selectUserDate, DATE_FORMATS.DOB);

  const cc = elementNameConverter.transformedSeveralNames(chart?.patientCc, patient);

  return (
    <tr className={cx['chart']}>
      <td onClick={onClick}>{formattedDate}</td>
      <td className={cx['cc']}>
        <Rv
          onClickText={onClick}
          value={cc}
        />
      </td>
      <td onClick={onClick}>{chart.chartingType} ({chart.visitType})</td>
    </tr>
  );
};

Chart.propTypes = {
  chart: shape({
    patientCc: string.isRequired,
    chartingType: string.isRequired,
    visitType: string.isRequired,
  }).isRequired,
  onClick: func.isRequired,
  patient: object.isRequired,
};

export default Chart;
