import { useRoute } from 'hooks/useRoute';
import stringParser from 'helpers/common/string/string-parser';
import { useOutletContext } from 'react-router';

export const FMSSBaseWrapper = Component => {
  const useBaseWrapper = ({ searchParamKey = '', propName = '', ...rest }) => {
    const { fromSearchParams } = useRoute();
    const { patientId } = useOutletContext();

    const extraProps = {
      [propName]: stringParser(fromSearchParams(searchParamKey))
    };

    return (
      <Component 
        {...rest}
        patientId={patientId}
        {...extraProps}
      />
    );
  };

  return useBaseWrapper;
};