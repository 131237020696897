import React from 'react';
import { useLocation, NavLink, useSearchParams } from 'react-router-dom';
import { number, node } from 'prop-types';

import Logo from 'components/Logo';
import StepIndicator from '../StepIndicator';
import Contacts from 'components/Contacts';
import MobileOnly from 'components/MobileOnly';
import DrChronoSSO from 'components/DrChronoSSO';

import { isRegistrationDisabled } from 'helpers';

import cx from './AuthFormSidebar.module.scss';

const registrationDisabled = isRegistrationDisabled();

const AuthFormSidebar = ({ children, step = 0 }) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <div className={cx.wrapper} id="auth-sidebar">
      <div className={cx['wrapper--padding']}>
        <div className={cx['top-part']}>
          <Logo/>
          <div className={cx.linksWrapper}>
            <NavLink
              to="/app/login"
              className={({ isActive }) => `${cx.link} ${isActive ? cx['link--active'] : ''}`}
            >
              Login
            </NavLink>
            <NavLink
              to="/app/signup"
              className={({ isActive }) =>
                `${cx.link} ${cx.signupLink} ${isActive ? cx['link--active'] : ''} ${registrationDisabled ? cx['link--disabled'] : ''}`
              }
              onClick={(e) => {
                if (registrationDisabled) {
                  e.preventDefault();
                }
              }}
            >
              {registrationDisabled &&
                <div className={
                  `${cx['registration-disabled-border']} ${cx['registration-disabled-border--top']}`
                }/>
              }
              Register
              {registrationDisabled && <p className={cx['coming-soon']}>Coming Soon</p>}
              {registrationDisabled &&
                <div className={
                  `${cx['registration-disabled-border']} ${cx['registration-disabled-border--bottom']}`
                }/>
              }
              {pathname === '/app/signup' && (
                <div className={cx.stepsWrapper}>
                  <StepIndicator step={step}/>
                </div>
              )}
            </NavLink>
          </div>
        </div>
        <div className={cx.form}>
          {children}
        </div>
      </div>

      <DrChronoSSO code={searchParams.get('code')}/>

      <MobileOnly>
        <Contacts/>
      </MobileOnly>
    </div>
  );
};

AuthFormSidebar.propTypes = {
  step: number,
  children: node.isRequired
};

export default AuthFormSidebar;
