import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import printjs from 'vendor/print';

import TetherComponent from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import { isMobileApp } from 'helpers';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './ChartActions.module.scss';

const ChartActions = props => {
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [disabledSignButton, setDisabledSignButton] = useState(false);

  const close = () => setIsOpen(false);

  const dropdownRef = useClickOutSide({ onOutsideClick: close, exclude: [inputRef] });

  const setDisabledSignButtonStatus = status => setDisabledSignButton(status);

  const handleShareClick = (pdfUrl) => {
    try {
      // webkit.messageHandlers.PDFShare.postMessage(pdfUrl);
    } catch (err) {
      console.log('Can not reach native code');
    }

    close();
  };

  const onChangeSignedStatusWrapper = async ({ onChangeSignedStatus }) => {
    setDisabledSignButtonStatus(true);
    await onChangeSignedStatus();
    setDisabledSignButtonStatus(false);
  };

  const {
    chartId,
    onChangeSignedStatus,
    signed,
    unlockSignedProgressNote,
    deleteEncounters,
    onDelete,
    permissions,
    isBlockedForReadOnly,
  } = props;

  const pdfShowUrl = `/api/rest/pdf/show/chart/${chartId}`;
  const disabledLink = isBlockedForReadOnly;

  return (
    <TetherComponent
      attachment="top center"
      targetAttachment="bottom center"
      classPrefix="chartActions"
      constraints={[{
        to: 'window',
        attachment: 'together',
      }]}
      renderTarget={(ref) => {
        inputRef.current = ref.current;
        return (
          <div
            ref={ref}
            className={cx.wrapper}
            onClick={() => {
              setIsOpen(prev => !prev);
            }}
          >
            <div className={cx.content}>Select...</div>
            <div className={cx['arrow-zone']}>
              <span className={cx.arrow}/>
            </div>
          </div>
        );
      }}
      renderElement={(ref) => isOpen && (
        <span ref={dropdownRef}>
          <div
            ref={ref}
            className={cx['menu-container']}
            style={{ zIndex: 10 }}
          >
            <div className={cx.menu}>
              {!signed && (
                disabledLink ? <span className={cx['option-disabled']}>Edit</span> :
                  <Link className={cx.option} to={`/app/doctor/charts/${chartId}?step=1`}>Edit</Link>
              )}
              {signed && (
                <a
                  className={cx.option}
                  onClick={close}
                  href={pdfShowUrl}
                >
                  View (Report)
                </a>
              )}
              <a
                className={cx.option}
                href={pdfShowUrl}
                target={isMobileApp() ? '_self' : '_blanc'}
              >
                Save as PDF
              </a>
              {signed && (
                <button
                  className={
                    cssClassesResolver([
                      !disabledLink && cx.option,
                      (disabledLink || !unlockSignedProgressNote) && cx['option-disabled']
                    ])}
                  onClick={() => onChangeSignedStatusWrapper({ onChangeSignedStatus })}
                  type="button"
                  disabled={disabledLink || !unlockSignedProgressNote || disabledSignButton}
                >
                  Unlock
                </button>
              )}
              {!signed && (
                <button
                  className={
                    cssClassesResolver([
                      !disabledLink && cx.option,
                      (disabledLink || !permissions.signEncounterNote) && cx['option-disabled']
                    ])}
                  onClick={() => onChangeSignedStatusWrapper({ onChangeSignedStatus })}
                  type="button"
                  disabled={disabledLink || !permissions.signEncounterNote || disabledSignButton}
                >
                  Sign
                </button>
              )}
              {!isMobileApp() && (
                <button
                  type="button"
                  className={cx.option}
                  onClick={() => printjs(pdfShowUrl)}
                >
                  Print
                </button>
              )}

              {isMobileApp() &&
                <button
                  type="button"
                  className={
                    cssClassesResolver([
                      cx['action-button'], cx['action-button--round']
                    ])}
                  onClick={() => handleShareClick(pdfShowUrl)}
                >
                  Share
                </button>
              }

              {!signed && (
                <button
                  className={
                    cssClassesResolver([
                      !disabledLink && cx.option,
                      (disabledLink || !permissions.signEncounterNote || !deleteEncounters) && cx['option-disabled']
                    ])}
                  type="button"
                  disabled={disabledLink || !permissions.signEncounterNote || !deleteEncounters}
                  onClick={() => {
                    onDelete();
                    close();
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </span>
      )
      }
    />
  );
};

export default ChartActions;

ChartActions.propTypes = {
  chartId: PropTypes.number.isRequired,
  deleteEncounters: PropTypes.bool.isRequired,
  unlockSignedProgressNote: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
};
