import React from 'react';
import PropTypes from 'prop-types';

import { isLockedPlan, getPlanImageName } from 'components/SeatUsersDropdown/helpers';

import cx from './RenderCard.module.scss';

const RenderCard = (props) => {
  const {
    card,
    hidePlansModal,
    setCurrentPlanToPurchaseSeat,
  } = props;

  const handleCardClick = () => {
    if (setCurrentPlanToPurchaseSeat) {
      setCurrentPlanToPurchaseSeat(card.id);
    }
    if (hidePlansModal) {
      hidePlansModal();
    }
  };

  return (
    <div>
      <button
        onClick={handleCardClick}
        className={`${cx['card-wrapper']} ${isLockedPlan(card.name) ? 'disabled-block' : cx.pointer}`}
      >
        <div className={cx.image}>
          <img src={card.image || getPlanImageName(card.name)} alt="" />
        </div>
        <div className={cx['card-container']}>
          <div className={cx['card-plan']}>{card.name}</div>
          {card.amount && <div className={cx['card-price']}>${card.amount}/mo</div>}
          <div className={cx['card-description']}>
            {card.description}
          </div>
        </div>
      </button>
    </div>
  );
};

RenderCard.propTypes = {
  card: PropTypes.object.isRequired,
  hidePlansModal: PropTypes.func,
  setCurrentPlanToPurchaseSeat: PropTypes.func,
};

export default RenderCard;
