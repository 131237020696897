import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';

import ChartReportItem from '../../../ChartReportItem';
import ChartReportMedicalItem from './ChartReportMedicalItem';

import * as medicalHelpers from 'helpers/fmss/medical/medical';
import ProgressNoteBase from 'helpers/fmss/additional-empty-message/factory/empty-message-entity/ProgressNoteBase';
import ProducerEmptyMessageFactory from 'helpers/fmss/additional-empty-message/factory/producer-factory';
import { FMSS_TYPES } from 'helpers/fmss/constants';

const ChartReportMedical = ({
  chartId,
  toggleChartReport,
  medicalItems,
  deleteMedicalItem,
  location,
  viewMode,
  hasNoPertinentMedical = false,
}) => {
  const currentMedicalItemId = location.query.medical && parseInt(location.query.medical);

  const sortedMedicalItems = medicalHelpers.sortMedicalItems(medicalItems);

  const conditionalData = new ProgressNoteBase(hasNoPertinentMedical, medicalItems.length);
  const emptyMessage = ProducerEmptyMessageFactory.getMessage(FMSS_TYPES.PROGRESS_NOTE_MEDICAL, conditionalData);

  return (
    <ChartReportItem
      title="Medical hx"
      tooltip={!viewMode}
      link={(!viewMode && `/app/doctor/charts/${chartId}/fmss/medical`) || null}
      toggleChartReport={!viewMode && toggleChartReport}
    >
      {medicalItems.length === 0 && emptyMessage}
      {
        !!medicalItems.length &&
          <ul style={{ padding: 0 }}>
            {sortedMedicalItems.map(medicalItem => (
              <li key={medicalItem.id}>
                <ChartReportMedicalItem
                  medicalItem={medicalItem}
                  toggleChartReport={toggleChartReport}
                  chartId={chartId}
                  onRemove={() => deleteMedicalItem(
                    medicalItem,
                    // if we are deleting currently active item we should redirect
                    currentMedicalItemId === medicalItem.id && location.pathname
                  )}
                  deleteMedicalItem={deleteMedicalItem}
                  viewMode={viewMode}
                />
              </li>
            ))}
          </ul>
      }
    </ChartReportItem>
  );
};

ChartReportMedical.propTypes = {
  hasNoPertinentMedical: PropTypes.bool,
};

export default withRouter(ChartReportMedical);
