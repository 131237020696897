import { connect } from 'react-redux';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import { MEDICAL } from 'modules/patientsHx/constants';

import { getPatientById } from 'modules/patients/selectors';

export const medicalFetchLayer = (Component) => {
  const useFetchLayer = props => {
    const {
      patientId,
      isFetching,
      fetchMedical
    } = props;

    useEffect(() => {
      if (patientId && isFetching === undefined) {
        fetchMedical(patientId);
      }
    }, [patientId]);

    return <Component {...props} />;
  };

  useFetchLayer.propTypes = {
    isFetching: PropTypes.bool,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fetchMedical: PropTypes.func.isRequired,
  };

  return useFetchLayer;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isFetching: selectors.getIsFetchingList(state.patientsHx, MEDICAL, ownProps.patientId),
    medicalItems: selectors.getMedicalItems(state.patientsHx, ownProps.patientId),
    error: selectors.getListError(state.patientsHx, MEDICAL, ownProps.patientId),
    selectedId: ownProps.medicalItemId,
    hasNoPertinentMedical: getPatientById(state.patients, ownProps.patientId)?.hasNoPertinentMedical,
  };
};

const mapActionCreators = {
  fetchMedical: actions.fetchMedical,
  createMedicalItem: actions.createMedicalItem,
  updateMedicalItem: actions.updateMedicalItem,
  deleteMedicalItem: actions.deleteMedicalItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
