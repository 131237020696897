import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { MedicationsProgressNoteContainer } from 'containers/Medications/MedicationsProgressNoteContainer';
import ChartingStepContainer from 'containers/ChartingStepContainer';
import MedicationsContainer, { fetchLayerHOC as medicationsFetchLayerHOC } from 'containers/Medications/MedicationsContainer';
import AllergiesContainer, { withAllergiesFetchLayer } from 'containers/Allergies/AllergiesContainer';

import { getBMI } from 'helpers/chart/vitals';
import displaySmokingStatus from 'helpers/chart/vitals/manage/display';

import ReportChartingStep from './ReportChartingStep';
import ChartReportSection from '../ChartReportSection';
import ChartReportHeadline from '../ChartReportHeadline';
import ChartReportTable from '../ChartReportTable';
import ChartReportFMSS from './ChartReportFMSS';
import ChartReportMedications from './ChartReportMedications';
import ChartReportAllergies from './ChartReportAllergies';
import ChartReportBilling from './ChartReportBilling';
import ChartReportAssessment from './ChartReportAssessment';

const Medications = compose(
  MedicationsContainer,
  medicationsFetchLayerHOC,
  MedicationsProgressNoteContainer
)(ChartReportMedications);

const Allergies = compose(
  AllergiesContainer,
  withAllergiesFetchLayer
)(ChartReportAllergies);

const ReportChartingStepComponent = compose(
  ChartingStepContainer,
)(ReportChartingStep);

const StepComponents = {
  5: ChartingStepContainer(ChartReportAssessment),
  7: ChartReportBilling,
};

const HPNote = (props) => {
  const {
    chartingSteps: chartingStepsWithSocial,
    chart, isFetching = null,
    toggleChartReport,
    viewMode,
    patient,
  } = props;

    // we shouldn't have social as dedicated step in report
  const chartingSteps = chartingStepsWithSocial.filter(o => o.type !== 'social');

  if (isFetching === null || isFetching) return null;

  const ChartingStepComponents = chartingSteps.map(step => StepComponents[step.id] || ReportChartingStepComponent);

  const CC = ChartingStepComponents[0];
  const HPI = ChartingStepComponents[1];
  const ROS = ChartingStepComponents[2];
  const PE = ChartingStepComponents[3];
  const A = ChartingStepComponents[4];
  const Plan = ChartingStepComponents[5];
  const Billing = ChartingStepComponents[6];

  const { vitals, showFMSS, showScreening, showCPT } = chart;

  const isDisplayFMSSSection = showFMSS || showScreening
  const baseFMSSSectionName = 'Family/Medical/Social/Surgical Hx'
  const screeningSectionName = 'Screening Hx'
  const resolvedFMSSSectionName = () => {
    if (showFMSS && !showScreening) {
      return baseFMSSSectionName
    }

    if (showFMSS && showScreening) {
      return `${baseFMSSSectionName}/${screeningSectionName}`
    }

    return screeningSectionName
  }

  const EMPTY_SMOKING_STATUS = ['BLANK'];
  const isPresentSmokingStatus = vitals.smokingStatus && !EMPTY_SMOKING_STATUS.includes(vitals.smokingStatus);
  const hasVitals = (vitals.temperature || vitals.pulse || (vitals.pressureFirst
      && vitals.pressureSecond) || vitals.bloodSugar || vitals.respiratoryRate || vitals.oxygenSaturation
      || vitals.height || vitals.weight || isPresentSmokingStatus || vitals.pain);

  return (
    <div>

      <CC
        step={chartingSteps[0].id}
        chartId={chart.id}
        viewMode={viewMode}
        title={chartingSteps[0].fullName}
      />

      <HPI
        step={chartingSteps[1].id}
        chartId={chart.id}
        viewMode={viewMode}
        title={chartingSteps[1].fullName}
      />


      {isDisplayFMSSSection && (
        <ChartReportFMSS
          sectionName={resolvedFMSSSectionName()}
          showFMSS={showFMSS}
          showScreening={showScreening}
          patientId={patient.id}
          chartId={chart.id}
          toggleChartReport={toggleChartReport}
          viewMode={viewMode}
        />
      )}

      {chart.showMedAllergy && (
      <ChartReportSection>
        <ChartReportHeadline>
              Medication & Allergies
        </ChartReportHeadline>

        <Medications
          patientId={patient.id}
          filter="active"
          toggleChartReport={toggleChartReport}
          chartId={chart.id}
          viewMode={viewMode}
        />

        <Allergies
          patientId={patient.id}
          filter="active"
          toggleChartReport={toggleChartReport}
          chartId={chart.id}
          viewMode={viewMode}
        />

      </ChartReportSection>
        )}

      <ROS
        step={chartingSteps[2].id}
        chartId={chart.id}
        viewMode={viewMode}
        title={chartingSteps[2].fullName}
      />

      {hasVitals && (
      <ChartReportSection>
        <ChartReportHeadline>
              Vitals
        </ChartReportHeadline>

        <ChartReportTable
          headers={[
                { title: 'Temperature' },
                { title: 'Pulse' },
                { title: 'Blood Pressure' },
                { title: 'Respiratory Rate' },
                { title: 'Oxygen Saturation' },
                { title: 'Pain' },
              ]}
        >
          <tr>
            <td>{(vitals.temperature && `${vitals.temperature} f`) || '-'}</td>
            <td>{(vitals.pulse && `${vitals.pulse} BPM`) || '-'}</td>
            <td>{(vitals.pressureFirst && vitals.pressureSecond && `${vitals.pressureFirst}/${vitals.pressureSecond}`) || '-'}</td>
            <td>{(vitals.respiratoryRate && `${vitals.respiratoryRate} rpm`) || '-'}</td>
            <td>{(vitals.oxygenSaturation && `${vitals.oxygenSaturation} %`) || '-'}</td>
            <td>{(vitals.pain && `${vitals.pain}/10`) || '-'}</td>
          </tr>
        </ChartReportTable>

        <ChartReportTable
          headers={[
                { title: 'Blood Sugar' },
                { title: 'Height' },
                { title: 'Weight' },
                { title: 'BMI' },
                { title: 'Smoking Status' },
              ]}
        >
          <tr>
            <td>{(vitals.bloodSugar && `${vitals.bloodSugar} mg/dl`) || '-'}</td>
            <td>{(vitals.height && `${vitals.height} inches`) || '-'}</td>
            <td>{(vitals.weight && `${vitals.weight} lbs`) || '-'}</td>
            <td>{getBMI(vitals)}</td>
            <td>{(vitals.smokingStatus && displaySmokingStatus(vitals.smokingStatus)) || '-'}</td>
          </tr>
        </ChartReportTable>
      </ChartReportSection>
        )}

      <PE
        step={chartingSteps[3].id}
        chartId={chart.id}
        viewMode={viewMode}
        title={chartingSteps[3].fullName}
      />

      <A
        step={chartingSteps[4].id}
        chartId={chart.id}
        viewMode={viewMode}
        title={chartingSteps[4].fullName}
      />

      <Plan
        step={chartingSteps[5].id}
        chartId={chart.id}
        viewMode={viewMode}
        title={chartingSteps[5].fullName}
        star
      />

      {showCPT && (
        <Billing
          step={chartingSteps[6].id}
          chartId={chart.id}
          chartCptCode={chart.cptCode}
          showChartCPT={chart.showCPT}
          title={chartingSteps[6].fullName}
          toggleChartReport={toggleChartReport}
        />
      )}
    </div>
  );
};

HPNote.propTypes = {
  chartingSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  chart: PropTypes.object.isRequired,
  toggleChartReport: PropTypes.func.isRequired,
  viewMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default HPNote;
