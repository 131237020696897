import ShouldRemoveElements from 'helpers/social/elements/remove/if-needed';

export default class PayloadDataToRemoveElements {
  constructor({ patientId = 0, systemId = 1, elements = [] }) {
    this.patientId = patientId;
    this.systemId = systemId;
    this.elements = elements;
    this._elementsIds = [];
  }

  set elementsIds(value) {
    this._elementsIds = value;
  }

  getLocalData = () => {
    const elementIds = this.getElementsIds();
    const isNeededRemoveElements = ShouldRemoveElements.isNeeded(elementIds);

    return {
      systemId: this.systemId,
      elementIds,
      isNeededRemoveElements,
    };
  };


  getRequestData = () => {
    return {
      patientId: this.patientId,
      elementIds: this.getElementsIds(),
    };
  };

  getElementsIds = () => {
    if (this._elementsIds.length > 0) {
      return this._elementsIds;
    }

    return this.calculateElementIds();
  };

  calculateElementIds = () => {
    const ids = this.elements.map(element => element.id);
    this.elementsIds = ids;
    return ids;
  }
}
