import { combineReducers } from 'redux';

import byId from './byId';
import isFetched from './isFetched';
import isFetching from './isFetching';
import currentProvider from './currentProvider';

export default combineReducers({
  byId,
  isFetched,
  isFetching,
  currentProvider,
});
