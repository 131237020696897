import React from 'react';
import PropTypes from 'prop-types';

import StateInput from 'components/StateInput';
import CityInput from 'components/CityInput';
import ValidatedField from 'components/Form/ValidatedField';

import { isZip } from 'helpers/validationRules';
import { isNullOrUndefined } from 'helpers';
import { disabled, selectOption } from 'helpers/propsGenerator';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import * as statesApi from 'api/states';

import cx from './style.module.scss';

const addressBlockStyles = resolveStylesV2({
  objectStyles:cx,
  moduleStyles: ['element-group', 'block-wrapper']
})

const stateLabelStyles = resolveStylesV2({
  objectStyles:cx,
  moduleStyles: ['label', 'state-wrapper__label']
})

const addressSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 210,
  heightControl: 28,
  fontSize: 12,
  isDisplayDropdownIndicator: false,
})

const AddressBlock = (props) => {
  const {
    disabled,
    address1,
    address2,
    zip,
    state,
    city,
    onChange,
  } = props;

  const createInput = ({ key, label, value, className }) => {
    return (
      <div className={cx[className]} key={key}>
        <label
          htmlFor={key}
          className={cx.label}
        >
          {label}
        </label>
        <input
          name={key}
          value={value}
          className={cx.input}
          onChange={e => onChange([key], e.target.value)}
          disabled={disabled}
        />
      </div>
    );
  }

  const updateZip = async (e) => {
    const { value } = e.target

    onChange('zip', e.target.value)

    if (!isZip(value)) {
      return;
    }

    const { body } = await statesApi.sendZip(value)
    if (!isNullOrUndefined(body)) {
      const {
        city: cityByZip,
        state: stateByZip
      } = body

      onChange('state', stateByZip)
      onChange('city', cityByZip)
    }
  }

  const elementList = [{
    key: 'address1',
    label: 'address 1',
    value: address1,
    className: 'element-wrapper',
  }, {
    key: 'address2',
    label: 'address 2',
    value: address2,
    className: 'element-wrapper',
  }];

  const inputList = elementList.map(createInput);

  return (
    <div className={addressBlockStyles}>
      {inputList}

      <div className={cx['line-wrapper']}>
        <div className={cx['zip-wrapper']}>
          <span
            className={cx.label}
          >
            zip
          </span>
          <ValidatedField>
            <input
              name="zip"
              type="text"
              value={zip}
              onChange={updateZip}
              className={cx.input}
              disabled={disabled}
            />
          </ValidatedField>
        </div>

        <div className={cx['state-wrapper']}>
          <span
            className={stateLabelStyles}
          >
            state
          </span>
          <StateInput
            className="state-input___autotest"
            value={state}
            disabled={disabled}
            onChange={stateOption => onChange('state', stateOption)}
            styles={addressSelectStyles}
          />
        </div>
      </div>

      <div className={cx['city-wrapper']}>
        <span
          className={cx.label}
        >
          city
        </span>
        <CityInput
          value={city}
          className="city-input___autotest"
          disabled={disabled}
          styles={addressSelectStyles}
          onChange={cityOption => onChange('city', cityOption)}
        />
      </div>
    </div>
  );
  // }
}

AddressBlock.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.oneOfType([PropTypes.string, selectOption.obj]),
  state: PropTypes.oneOfType([PropTypes.string, selectOption.obj]),
  zip: PropTypes.string,
  disabled: disabled.obj,
  onChange: PropTypes.func.isRequired,
};

AddressBlock.defaultProps = {
  address1: '',
  address2: '',
  zip: '',
  city: selectOption.default,
  state: selectOption.default,
  disabled: disabled.default,
};

export default AddressBlock;
