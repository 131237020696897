import * as SMOKING_STATUS from 'helpers/chart/vitals/constants/smoking-status/list-statuses';

export const SMOKING_STATUS_SELECT_OPTIONS = new Map([
  [SMOKING_STATUS.CURRENT_EVERY_DAY, {
    value: SMOKING_STATUS.CURRENT_EVERY_DAY,
    title: 'Current every day smoker',
  }],
  [SMOKING_STATUS.CURRENT_SOME_DAY, {
    value: SMOKING_STATUS.CURRENT_SOME_DAY,
    title: 'Current some day smoker',
  }],
  [SMOKING_STATUS.FORMER_SMOKER, {
    value: SMOKING_STATUS.FORMER_SMOKER,
    title: 'Former smoker',
  }],
  [SMOKING_STATUS.NEVER_SMOKER, {
    value: SMOKING_STATUS.NEVER_SMOKER,
    title: 'Never smoker',
  }],
  [SMOKING_STATUS.CURRENT_STATUS_UNKNOWN, {
    value: SMOKING_STATUS.CURRENT_STATUS_UNKNOWN,
    title: 'Smoker, current status unknown',
  }],
  [SMOKING_STATUS.UNKNOWN_EVER_SMOKED, {
    value: SMOKING_STATUS.UNKNOWN_EVER_SMOKED,
    title: 'Unknown if ever smoked',
  }],
  [SMOKING_STATUS.CURRENT_TOBACCO_SMOKER, {
    value: SMOKING_STATUS.CURRENT_TOBACCO_SMOKER,
    title: 'Heavy tobacco smoker',
  }],
  [SMOKING_STATUS.CURRENT_LIGHT_TOBACCO_SMOKER, {
    value: SMOKING_STATUS.CURRENT_LIGHT_TOBACCO_SMOKER,
    title: 'Light tobacco smoker',
  }],
  [SMOKING_STATUS.BLANK, {
    value: SMOKING_STATUS.BLANK,
    title: '',
  }],
]);
