import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';

import { getActivePatient } from 'modules/patients/selectors';

export const ChartFMSSRoot = () => {
  const { chartId } = useParams();
  const { id } = useSelector(state => getActivePatient(state.patients));
  return (
    <Outlet context={{ patientId: id, linkBase: `/app/doctor/charts/${chartId}/fmss` }} />
  );
};
