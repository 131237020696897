import React, { Component } from 'react';

import debounce from 'lodash/debounce';

import SmokingStatusDropdown
  from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/SmokingStatusDropdown';

import { getBMI, getBmiStatus } from 'helpers/chart/vitals';
import { addNewProperty } from 'helpers';

import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/VitalsForm/VitalsForm.module.scss';
import tableCx from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/VitalsTable.module.scss';

const handleEmptyStringValue = val => val || '';

class VitalsForm extends Component {

  saveVitalsDebounced = debounce(this.props.saveVitals, 1000);

  constructor(props) {
    super(props);

    const {
      pressureFirst,
      pressureSecond,
      bloodSugar,
      chartId,
      height,
      oxygenSaturation,
      pain,
      pulse,
      respiratoryRate,
      smokingStatus,
      temperature,
      weight,
    } = props.vitals;

    this.state = {
      pressureFirst: handleEmptyStringValue(pressureFirst),
      pressureSecond: handleEmptyStringValue(pressureSecond),
      chartId,
      bloodSugar: handleEmptyStringValue(bloodSugar),
      height: handleEmptyStringValue(height),
      oxygenSaturation: handleEmptyStringValue(oxygenSaturation),
      pain: handleEmptyStringValue(pain),
      pulse: handleEmptyStringValue(pulse),
      respiratoryRate: handleEmptyStringValue(respiratoryRate),
      smokingStatus,
      temperature: handleEmptyStringValue(temperature),
      weight: handleEmptyStringValue(weight),
    };
  }

  updateVitals = (prop, value) => {
    const newState = addNewProperty([prop], value, this.state);

    this.setState(newState);

    this.saveVitalsDebounced(newState);
  };

  render() {
    const bmi = getBMI(this.state);
    const bmiStatus = getBmiStatus(bmi);

    return (
      <form className={cx.wrapper}>
        <table className={`${tableCx.table} ${cx.table}`}>
          <thead>
            <tr>
              <th>Current</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.temperature}
                  onChange={e => this.updateVitals('temperature', e.target.value)}
                />
              </td>
              <td className={cx.label}>Temperature <span className={cx.measure}>(f)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.pulse}
                  onChange={e => this.updateVitals('pulse', e.target.value)}
                />
              </td>
              <td className={cx.label}>Pulse <span className={cx.measure}>(bpm)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.pressureFirst}
                  onChange={e => this.updateVitals('pressureFirst', e.target.value)}
                />
                <span> / </span>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.pressureSecond}
                  onChange={e => this.updateVitals('pressureSecond', e.target.value)}
                />
              </td>
              <td className={cx.label}>Blood Pressure <span className={cx.measure}>(mmHg)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.respiratoryRate}
                  onChange={e => this.updateVitals('respiratoryRate', e.target.value)}
                />
              </td>
              <td className={cx.label}>Respiratory Rate <span className={cx.measure}>(rpm)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.oxygenSaturation}
                  onChange={e => this.updateVitals('oxygenSaturation', e.target.value)}
                />
              </td>
              <td className={cx.label}>Oxygen Saturation <span className={cx.measure}>(%)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.height}
                  onChange={e => this.updateVitals('height', e.target.value)}
                />
              </td>
              <td className={cx.label}>Height <span className={cx.measure}>(in)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.weight}
                  onChange={e => this.updateVitals('weight', e.target.value)}
                />
              </td>
              <td className={cx.label}>Weight <span className={cx.measure}>(lbs)</span></td>
            </tr>
            <tr>
              <td style={{ color: bmiStatus && bmiStatus.color }} className={cx.bmi}>
                <span>
                  {bmi}
                </span>
                {bmiStatus && <img className={cx['bmi-icon']} height="20" src={bmiStatus.src} alt={bmiStatus.title} title={bmiStatus.title} />}
              </td>
              <td className={cx.label}>BMI</td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.pain}
                  onChange={e => this.updateVitals('pain', e.target.value)}
                />
              </td>
              <td className={cx.label}>Pain <span className={cx.measure}>(1-10)</span></td>
            </tr>
            <tr>
              <td>
                <input
                  className={cx.input}
                  type="text"
                  value={this.state.bloodSugar}
                  onChange={e => this.updateVitals('bloodSugar', e.target.value)}
                />
              </td>
              <td className={cx.label}>Blood Sugar <span className={cx.measure}>(mg/dl)</span></td>
            </tr>
            <tr>
              <td>
                <SmokingStatusDropdown
                  onChange={value => this.updateVitals('smokingStatus', value)}
                  activeSmokingStatus={this.state.smokingStatus}
                />
              </td>
              <td className={cx.label}>Smoking Status</td>
            </tr>
          </tbody>

        </table>
      </form>
    );
  }
}

export default VitalsForm;
