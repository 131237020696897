import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import CptEnclosedElements from 'containers/CptEnclosedElements';

import ChartReportHeadline from '../../ChartReportHeadline';
import ChartReportSection from '../../ChartReportSection';
import ChartReportBillingContent from 'components/ChartReport/ChartReportBody/HPNote/ChartReportBillingContent';

const ChartReportBilling = (props) => {
  const {
    chartId,
    step,
    title,
    chartCptCode = null,
    toggleChartReport,
    isDisplayBillingSection,
    cptToDisplay,
    isShowChartCPT,
    hcpcToDisplay,
  } = props;

  if (isDisplayBillingSection) {
    return (
      <ChartReportSection>
        <ChartReportHeadline
          link={`/app/doctor/charts/${chartId}?step=${step}`}
          toggleChartReport={toggleChartReport}
        >
          {title}
        </ChartReportHeadline>

        <ChartReportBillingContent
          chartCptCode={chartCptCode}
          chartId={chartId}
          cptToDisplay={cptToDisplay}
          isShowChartCPT={isShowChartCPT}
          hcpcToDisplay={hcpcToDisplay}
        />

      </ChartReportSection>
    );
  }

  return <React.Fragment />;
};

ChartReportBilling.propTypes = {
  toggleChartReport: PropTypes.func.isRequired,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  isShowChartCPT: PropTypes.bool.isRequired,
  chartCptCode: PropTypes.object,
  isDisplayBillingSection: PropTypes.bool.isRequired,
  cptToDisplay: PropTypes.array.isRequired,
  hcpcToDisplay: PropTypes.array.isRequired,
};

const ChartReportBillingWrapper = compose(
  CptEnclosedElements,
)(ChartReportBilling);

export default ChartReportBillingWrapper;
