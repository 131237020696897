import React from 'react';
import PropTypes from 'prop-types';

const MailToLinkIosApp = (props) => {
  const {
    className,
    linkTo,
    children,
  } = props;

  const buildLink = () => {
    const subject = 'Subject';
    const emailBody = 'Contact us';

    return 'mailto:' + linkTo + '?subject=' + subject + '&body=' + emailBody;
  };

  const onClick = (event) => {
    event.preventDefault();
    window.location = buildLink();
  };

  return (
    <button
      type='button'
      className={className}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

MailToLinkIosApp.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  children: PropTypes.node,
};

MailToLinkIosApp.defaultProps = {
  className: '',
  children: null,
};

export default MailToLinkIosApp;
