import EmptyHasNoDrugAllergiesMessage from 'helpers/fmss/allergies/empty-message/has-no-drug-allergies';
import EmptyMessageByFilter from 'helpers/fmss/allergies/empty-message/by-filter';

export default class EmptyMessageFactory {
  constructor(noDrugAllergies, filter) {
    this.noDrugAllergies = noDrugAllergies;
    this.filter = filter;
  }

  getMessage = () => {
    if (this.noDrugAllergies) {
      return EmptyHasNoDrugAllergiesMessage.getMessage();
    }

    return EmptyMessageByFilter.getMessage(this.filter);
  }
}
