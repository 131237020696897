import React from 'react';
import cx from './WelcomeBanner.module.scss';

const WelcomeBanner = () => (
  <div className={cx['welcome-banner']}>
    <div className={cx['image']} />
    <div className={cx['gradient']} />
    <div className={cx['plate-container_2']}>
      Welcome to the future of medical charting!
    </div>
  </div>
);


export default WelcomeBanner;
