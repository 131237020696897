import React, { Component } from 'react';

import Loader from 'components/Loader';
import VitalsItem from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/VitalsItem';
import VitalsForm from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/VitalsForm';

import { getScrollBarWidth } from 'helpers';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './Vitals.module.scss';

const styles = ({ standalone, fetching }) => ({
  wrapperStandalone: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: [['wrapper'], standalone && 'wrapper--standalone']
  }),
  innerWrapperLoading: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['inner-wrapper', fetching && 'inner-wrapper--loading']
  })
});

class Vitals extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      paddingBottom: 0,
    };
  }

  mountPrevVitals = (ref) => {
    if (!ref) return;
    this.scrollContainer = ref;

    // check if has scroll
    if (ref.scrollHeight > ref.offsetHeight) {
      // if it is true it means browser is probably ie11 so we have to add padding to bottom
      this.setState({ paddingBottom: getScrollBarWidth() });
    }
  };

  render() {
    const { paddingBottom } = this.state;
    const {
      isFetching,
      vitals,
      currentVitals,
      saveVitals,
      standalone,
    } = this.props;

    const fetching = isFetching === undefined || isFetching;
    const resolvedStyles = styles({ standalone });

    return (
      <div className={resolvedStyles.wrapperStandalone}>
        <div className={resolvedStyles.innerWrapperLoading}>
          {fetching && (
            <div
              className={cx['loader-wrapper']}
            >
              <Loader />
            </div>
          )}

          <div className={cx.form}>
            {!fetching && (
              <VitalsForm
                vitals={currentVitals}
                saveVitals={saveVitals}
              />
            )}
          </div>
          <div
            className={cx['prev-vitals']}
            ref={this.mountPrevVitals}
            style={{
              paddingBottom,
            }}
          >
            <div className={cx['prev-vitals-container']}>
              {vitals.map((vitalsItem, i) => (
                <VitalsItem
                  key={i}
                  vitals={vitalsItem}
                />
              ))}
            </div>
            <div
              className={cx.line}
              style={{
                bottom: getScrollBarWidth(),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Vitals;
