import { FMSSRoot } from './components/FMS';
import { MedicalRoute } from './routes/Medical';
import { SocialRoute } from './routes/Social';
import { SurgicalRoute } from './routes/Surgical';
import { ScreeningRoute } from './routes/Screening';
import { FamilyRoute } from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/routes/Family';

export const FMSSRoute = {
  path: 'fmss',
  element: <FMSSRoot />,
  children: [
    MedicalRoute,
    FamilyRoute,
    ScreeningRoute,
    SurgicalRoute,
    SocialRoute
  ]
};
