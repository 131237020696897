import { handleActions } from 'redux-actions';
import * as constants from '../constants';

export default handleActions({
  [constants.SYSTEM_NOTE_CREATE]: () => true,
  [constants.SYSTEM_NOTE_CREATE_SUCCESS]: () => false,
  [constants.SYSTEM_NOTE_CREATE_FAIL]: () => false,

  [constants.SYSTEM_NOTE_UPDATE]: () => true,
  [constants.SYSTEM_NOTE_UPDATE_SUCCESS]: () => false,
  [constants.SYSTEM_NOTE_UPDATE_FAIL]: () => false,
}, false);
