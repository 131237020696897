import React from 'react';

import ComposedBlockedForReadOnlyFetchLayer from 'containers/BlockedForReadOnly/BlockedForReadOnlyFetchLayer';

import ChartActions from '../ChartActions';
import Rv from './Rv';
import RadialProgressBar from 'components/RadialProgressBar';

import cx from './Encounter.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import LockIcon from '../../../images/lock.png';

const Encounter = props => {
  const {
    encounter,
    userInfo,
    fields,
    onChangeSignedStatus,
    unlockSignedProgressNote,
    deleteEncounters,
    onDelete,
    active,
    permissions,
  } = props;

  const isCptCodePresent = encounter?.cptCode?.cptCode;
  const isDisplayProgressBar = encounter.status !== null && isCptCodePresent;

  return (
    <tr className={cssClassesResolver([
      cx.encounter,
      active && cx['encounter--active']
    ])}>
      <td
        style={{ width: fields[0].width }}
        className={cx['encounter-date']}
      >
        {encounter.signed && !encounter.processingSign && !encounter.signError && (
          <img
            src={LockIcon}
            alt="Locked"
            title="Encounter is locked"
            width="20"
            className={cx['locked-icon']}
          />
        )}
        {encounter.signError && (
          <button
            type="button"
            className={cx.error}
            title="Something went wrong, click to retry"
            onClick={() => onChangeSignedStatus(encounter.id)}
          />
        )}
        {encounter.processingSign && (
          <span
            className={cx.loader}
          />
        )}
        <span className={cx['select-user-date']}>
          {encounter.encounter}
        </span>
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>
      <td style={{ width: fields[1].width }}>
        {encounter.rv && (
          <Rv
            value={encounter.rv}
          />
        )}
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>
      <td style={{ width: fields[2].width }}>
        <span className={cx.name}>{encounter.patient}</span>
        <span className={cx.dob}>{encounter.patientDob}</span>
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>
      <td style={{ width: fields[3].width }}>
        {encounter.providerInitials}
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>
      <td
        style={{
          width: fields[4].width,
        }}
        className={cx['note-type']}
      >
        <p>{encounter.details}</p>
        <p>{isCptCodePresent}</p>
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>

      <td style={{ width: fields[5].width, height: fields[5].height }}>
        {isDisplayProgressBar && (
          <RadialProgressBar
            progress={encounter.status}
            useCheck={false}
          />
        )}
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>
      <td>
        <ComposedBlockedForReadOnlyFetchLayer>
          <ChartActions
            chartId={encounter.id}
            userInfo={userInfo}
            signed={encounter.signed}
            onChangeSignedStatus={() => onChangeSignedStatus(encounter.id)}
            unlockSignedProgressNote={unlockSignedProgressNote}
            deleteEncounters={deleteEncounters}
            onDelete={() => onDelete(encounter.id)}
            permissions={permissions}
          />
        </ComposedBlockedForReadOnlyFetchLayer>
        {encounter.processingSign && <div className={cx.overlay} />}
      </td>
    </tr>
  );
};

Encounter.whyDidYouRender = true;

export default Encounter;
