import { handleActions } from 'redux-actions';

import keyBy from 'lodash/keyBy';

import vitals from './vitals';
import details from './details';

import * as chartingHelpers from 'helpers/charting/charting';
import deleteObjectProperty from 'helpers/common/object/delete-property';

import * as chartsConstants from '../constants';
import * as chartingSessionsConstants from 'modules/chartingSessions/constants';
import * as usersConstants from 'modules/users/constants';
import { SYSTEM_CREATE_SUCCESS, SYSTEM_UPDATE_SUCCESS, SYSTEM_DELETE_SUCCESS } from 'modules/chartingAssets/constants';

import favoritesFetchedByStep, { initialState as favoritesFetchedByStepInitialState } from './favoritesFetchedByStep';

const initialState = {};

const blackListProps = ['firstName', 'middleName', 'lastName', 'patientDob'];

const chartInitialFetchState = {
  isFetching: false,
  isFetched: false,
  isSaving: false,
};

const handleVitals = (state, action) => ({
  ...state,
  [action.payload.chartId]: {
    ...state[action.payload.chartId],
    vitals: vitals(state[action.payload.chartId].vitals, action),
  },
});

const handleDetails = (state, action) => ({
  ...state,
  [action.payload.chartId]: {
    ...state[action.payload.chartId],
    details: details(state[action.payload.chartId].details, action),
  },
});

const handleSaveFetchChartsResponse = chart => ({
  cptCode: chart.medCptCodeDTO,
  ...deleteObjectProperty(chart, 'medCptCodeDTO'),
});

export default handleActions({
  [chartsConstants.CHARTS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.map((chart) => {
      const _chart = {
        ...chart,
        ...chartInitialFetchState,
      };

      blackListProps.forEach(prop => delete (_chart[prop]));
      _chart.cptCode = _chart.medCptCodeDTO;
      delete _chart.medCptCodeDTO;

      return {
        ...state[_chart.id],
        ..._chart,
      };
    }), 'id'),
  }),
  [chartsConstants.CHARTS_BY_PAGE_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.encounterUserDTOS.map(handleSaveFetchChartsResponse), 'id'),
  }),
  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.map(handleSaveFetchChartsResponse), 'id'),
  }),
  [chartsConstants.PATIENT_CHARTS_BY_PAGE_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.encounterUserDTOS.map((chart) => {
      const nextChart = {
        ...state[chart.id],
        ...deleteObjectProperty(chart, 'medCptCodeDTO'),
        cptCode: chart.medCptCodeDTO,
        ...chartInitialFetchState,
      };

      return state[chart.id] && state[chart.id].isFetched
        ? { ...nextChart, isFetched: true }
        : nextChart;
    }), 'id'),
  }),

  [chartsConstants.CHART_FETCH]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      id: action.payload.chartId,
      ...chartInitialFetchState,
      isFetching: true,
      error: null,
    },
  }),

  [chartsConstants.CHART_FETCH_SUCCESS]: (state, action) => {
    if (action.payload.result.status === 204) {
      return {
        ...state,
        [action.payload.chartId]: {
          ...state[action.payload.chartId],
          isFetched: true,
          isFetching: false,
          error: {
            status: 204,
            message: 'Chart is deleted or doesn\'t exist',
          },
        },
      };
    }

    return {
      ...state,
      [action.payload.chartId]: {
        ...state[action.payload.chartId],
        ...action.payload.result.chart,
        favoritesFetchedByStep: favoritesFetchedByStepInitialState,
        isFetched: true,
        isFetching: false,
        error: null,
        note: undefined,
      },
    };
  },
  [chartsConstants.CHART_FETCH_FAIL]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      isFetching: false,
      error: {
        status: action.error.status,
        message: action.error.message,
      },
    },
  }),
  [chartsConstants.CHART_CREATE_SUCCESS]: (state, action) => {
    const chartId = chartingHelpers.fetchChartIdFromCreatingResponse(action.payload.result);

    return {
      ...state,
      [chartId]: {
        ...action.payload.localChart,
        id: chartId,
        ...chartInitialFetchState,
        errorDTO: action.payload.result.errorDTO,
        ...action.payload.result.userChartingListDTO,
        // after chart creation we should manually add vitals
        // because if vitals for patient are already fetched we won't fetch it again
        vitals: vitals(state[chartId] ? state[chartId].vitals : {}, action),
      },
    };
  },
  [chartsConstants.CHART_UPDATE_LOCAL]: (state, action) => ({
    ...state,
    [action.payload.localChart.id]: {
      ...state[action.payload.localChart.id],
      ...action.payload.localChart,
    },
  }),

  [chartsConstants.CHART_UPDATE_FAIL]: (state, action) => ({
    ...state,
    [action.payload.localChart.id]: {
      ...state[action.payload.localChart.id],
      ...action.payload.prevChart,
    },
  }),

  [chartsConstants.VITALS_FETCH]: handleVitals,
  [chartsConstants.VITALS_FETCH_SUCCESS]: (state, action) => {
    const { result } = action.payload;
    const changedCharts = result.map((vitalsItem) => {
      // chart to which belong past vitals can not be fetched,
      // so we create it
      const chart = state[vitalsItem.chartId] || {
        id: vitalsItem.chartId,
        patientId: vitalsItem.patientId,
        vitals: {
          chartId: vitalsItem.chartId,
          patientId: vitalsItem.patientId,
        },
      };

      return {
        ...chart,
        vitals: vitals(chart.vitals, {
          ...action,
          payload: {
            ...action.payload,
            result: vitalsItem,
          },
        }),
      };
    });

    return {
      ...state,
      ...keyBy(changedCharts, 'id'),
    };
  },
  [chartsConstants.VITALS_FETCH_FAIL]: handleVitals,
  [chartsConstants.VITALS_SAVE_SUCCESS]: handleVitals,
  [chartingSessionsConstants.SAVE_CHARTING_SESSION]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      isSaving: true,
      status: action.payload.completion,
      patientCc: action.payload.patientCc,
      vitals: vitals(state[action.payload.chartId].vitals, action),
    },
  }),
  [chartingSessionsConstants.SAVE_CHARTING_SESSION_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      favoritesFetchedByStep: favoritesFetchedByStep(state[action.payload.chartId].favoritesFetchedByStep, action),
      isSaving: false,
    },
  }),
  [chartingSessionsConstants.SAVE_CHARTING_SESSION_FAIL]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      isSaving: false,
    },
  }),
  [chartsConstants.COPY_PREVIOUS_NOTE]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      isCopyingPreviousNote: true,
    },
  }),
  [chartsConstants.COPY_PREVIOUS_NOTE_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      isCopyingPreviousNote: false,
    },
  }),
  [chartsConstants.COPY_PREVIOUS_NOTE_FAIL]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      isCopyingPreviousNote: false,
    },
  }),
  [chartsConstants.CHART_DELETE]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      deleted: true,
    },
  }),
  [chartsConstants.CHART_DELETE_FAIL]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      deleted: false,
    },
  }),
  [chartsConstants.CHART_CHANGE_SIGNED_STATUS]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      processingSign: true,
      signError: null,
    },
  }),
  [chartsConstants.CHART_CHANGE_SIGNED_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      ...action.payload.result,
      signed: action.payload.signed,
      processingSign: false,
      signError: null,
    },
  }),
  [chartsConstants.CHART_CHANGE_SIGNED_STATUS_FAIL]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      processingSign: false,
      signError: {
        status: action.error.status,
        message: action.error.message,
      },
    },
  }),
  [chartsConstants.UPDATE_CPT_CODE]: (state, action) => ({
    ...state,
    [action.payload.dataForStore.chartId]: {
      ...state[action.payload.dataForStore.chartId],
      cptCode: action.payload.dataForStore.cptCode,
    },
  }),
  [chartsConstants.UPDATE_SELECT_USER_DATE]: (state, action) => ({
    ...state,
    [action.payload.chartId]: {
      ...state[action.payload.chartId],
      selectUserDate: action.payload.selectUserDate,
    },
  }),
  [usersConstants.USER_UPDATE_SUCCESS]: (state, action) => {
    const nextState = { ...state };

    const provider = {
      id: action.payload.result.id,
      name: action.payload.result.firstName + ' ' + action.payload.result.lastName,
      suffix: action.payload.result.suffix,
      signature: action.payload.result.signature,
    };

    const chartsToUpdate = Object.values(nextState).filter(chart => chart.provider && chart.provider.id === action.payload.result.id);

    chartsToUpdate.forEach((chart) => {
      const nextChart = { ...nextState[chart.id] };
      nextChart.provider = provider;

      nextState[chart.id] = nextChart;
    });

    return nextState;
  },

  [chartsConstants.CHART_PROVIDER_SET_SUCCESS]: (state, action) => {
    const {
      chartId,
      providerId,
      providers,
    } = action.payload;

    const provider = providers.filter(p => p.id === providerId)[0];

    const _provider = {
      id: providerId,
      name: `${provider.firstName} ${provider.lastName}`,
      suffix: provider.suffix,
      signature: provider.signature,
    };

    return {
      ...state,
      [chartId]: {
        ...state[chartId],
        provider: {
          ...state[chartId].provider,
          ..._provider,
        },
      },
    };
  },
  [SYSTEM_CREATE_SUCCESS]: handleDetails,
  [SYSTEM_UPDATE_SUCCESS]: handleDetails,
  [chartsConstants.SAVE_CHARTS_DETAILS_SUCCESS]: handleDetails,
  [SYSTEM_DELETE_SUCCESS]: handleDetails,

}, initialState);
