import React from 'react';
import { Link } from 'react-router-dom';

import { replaceAll } from 'helpers';

import cx from './ChartReportHeadline.module.scss';

const ChartReportHeadline = ({ children, link, toggleChartReport }) => {
  let value = children;

  const childrenIsString = typeof children === 'string';

  if (childrenIsString) {
    value = replaceAll(children, 'HX', 'Hx');
  }

  return (
    <h1 className={cx['report-header']}>
      <Link
        className="styleless-link"
        to={link || null}
        onClick={link && toggleChartReport}
      >
        {value}
      </Link>
      <span className={cx['report-header-border']} />
    </h1>
  );
};

export default ChartReportHeadline;
