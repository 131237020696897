import React from 'react';
import PropTypes from 'prop-types';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './BurgerMenu.module.scss';

const BurgerMenu = ({
  toggleSidebar,
  active,
}) => (
  <div className={cssClassesResolver([
    cx['wrapper'], active && cx['wrapper--active']
  ])} onClick={toggleSidebar}>
    <div className={cx['burger']} />
  </div>
);

BurgerMenu.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default BurgerMenu;
