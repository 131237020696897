import { getPermissions, getUserId } from 'modules/user/selectors';
import { setInfoModalData } from 'modules/infoModal/actions';
import { setPurchaseSeatModalsView } from 'modules/purchaseSeat/actions';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  permissions: getPermissions(state.user),
  settings: state.settings,
  infoModalData: state.infoModal,
  currentUserId: getUserId(state.user),
  purchaseSeatModalsView: state.purchaseSeat,
});

const mapActionCreators = {
  setInfoModalData,
  setPurchaseSeatModalsView,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
