import { handleActions } from 'redux-actions';

import * as boxServiceConstants from './constants';

const initialState = {
  isFetching: false,
  clientId: '',
  connected: false,
};

export default handleActions({
  [boxServiceConstants.BOX_CODE_SAVE]: (state) => ({
    ...state,
    isFetching: true,
  }),

  [boxServiceConstants.BOX_CODE_SAVE_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
    connected: true,
  }),

  [boxServiceConstants.BOX_CODE_SAVE_FAIL]: (state) => ({
    ...state,
    isFetching: false,
  }),

  [boxServiceConstants.BOX_CODE_REMOVE]: (state) => ({
    ...state,
    isFetching: true,
  }),

  [boxServiceConstants.BOX_CODE_REMOVE_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
    connected: false,
  }),

  [boxServiceConstants.BOX_CODE_REMOVE_FAIL]: (state) => ({
    ...state,
    isFetching: false,
  }),

  [boxServiceConstants.BOX_CLIENT_ID_FETCH]: (state) => ({
    ...state,
    isFetching: true,
  }),

  [boxServiceConstants.BOX_CLIENT_ID_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result,
    isFetching: false,
  }),

  [boxServiceConstants.BOX_CLIENT_ID_FETCH_FAIL]: (state) => ({
    ...state,
    isFetching: false,
  }),

}, initialState);
