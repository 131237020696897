import React from 'react';
import AsyncSelect from 'react-select/async';

import { fetchCities } from 'api/states';

const CityInput = (props) => {
  const {
    className,
    value,
    disabled,
    styles,
    onChange
  } = props;

  const getCities = (input, callback) =>
    fetchCities(input)
      .then(
        result => callback(result.map(city => ({ value: city, label: city }))),
        () => callback([])
      );

  const handleChange = (cityValue) =>
    onChange(cityValue?.value)

  const buildSelectOption = initialValue => initialValue?.length ? { initialValue, label: initialValue } : null
  const option = typeof value === 'object' ? value : buildSelectOption(value)

  return (
    <AsyncSelect
      loadOptions={getCities}
      value={option}
      autoload={false}
      cacheOptions
      className={className}
      isClearable
      styles={styles}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

export default CityInput;
