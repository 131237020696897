import { connect } from 'react-redux';
import {
  fetchBoxClientId,
  removeBoxCode,
  saveBoxCode
} from 'modules/boxService/actions';
import * as selectors from 'modules/boxService/selectors';

const mapStateToProps = (state, ownProps) => ({
  isFetching: selectors.getIsFetching(state),
  boxService: selectors.getBoxService(state),
  ...ownProps,
});

const mapActionCreators = {
  fetchBoxClientId,
  removeBoxCode,
  saveBoxCode
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
