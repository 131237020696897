import UserEditForm from 'components/UserEditForm';
import { ConsentTable } from './ConsentTable';
import { Preview } from './Preview';

export const ConsentTab = () => {
  return (
    <UserEditForm.Wrapper>
      <UserEditForm.PlanCard>
        <ConsentTable />
      </UserEditForm.PlanCard>
      <UserEditForm.PlanDescription>
        <Preview />
      </UserEditForm.PlanDescription>
    </UserEditForm.Wrapper>
  );
};