export const PATIENT_SOCIAL_FETCH = 'PATIENT_SOCIAL_FETCH';
export const PATIENT_SOCIAL_FETCH_SUCCESS = 'PATIENT_SOCIAL_FETCH_SUCCESS';
export const PATIENT_SOCIAL_FETCH_FAIL = 'PATIENT_SOCIAL_FETCH_FAIL';

export const SOCIAL_SYSTEM_ADD = 'SOCIAL_SYSTEM_ADD';

export const SOCIAL_ELEMENT_ADD = 'SOCIAL_ELEMENT_ADD';
export const SOCIAL_ELEMENTS_REMOVE = 'SOCIAL_ELEMENTS_REMOVE';
export const SOCIAL_ELEMENTS_REMOVE_SUCCESS = 'SOCIAL_ELEMENTS_REMOVE_SUCCESS';
export const SOCIAL_ELEMENTS_REMOVE_FAIL = 'SOCIAL_ELEMENTS_REMOVE_FAIL';

