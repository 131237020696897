import { connect } from 'react-redux';

import { getChartById } from 'modules/charts/selectors';
import { getActivePatient } from 'modules/patients/selectors';

const mapStateToProps = (state, ownProps) => {
  const chartPatient = getActivePatient(state.patients);
  const { id } = chartPatient;
  const { isFetching } = getChartById(state.charts, ownProps.chartId);

  return {
    patientId: id,
    chartPatient,
    isFetching,
  };
};


export default component => connect(mapStateToProps)(component);
