import { FMSS_NO_KNOWN_ENTITY } from '../constants';

import isEqual from 'lodash/isEqual';

export const byNotPresentNoKnownAllergy = allergy =>
  Object.values(FMSS_NO_KNOWN_ENTITY)
    .every(noKnownAllergyOne => !isEqual(noKnownAllergyOne, allergy.allergy.name));

export const byPresentNoKnownAllergy = allergy =>
  Object.values(FMSS_NO_KNOWN_ENTITY)
    .some(noKnownAllergyOne => isEqual(allergy.allergy.name, noKnownAllergyOne));
