import React from 'react';
import PropTypes from 'prop-types';

import cx from './ReportValidationError.module.scss';

const ReportValidationError = (props) => {
  const { error = '', isDisplay = false } = props;

  return (
    <div className={cx.error}>
      {isDisplay && error}
    </div>
  );
};

ReportValidationError.propTypes = {
  error: PropTypes.string,
  isDisplay: PropTypes.bool,
};

export default ReportValidationError;
