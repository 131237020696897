import React from 'react';
import { useOutletContext } from 'react-router';

import screeningItemContainer from 'containers/FMSS/Screening/ScreeningItemContainer';
import ScreeningForm from 'containers/FMSS/Screening/ScreeningForm';
import { useRoute } from 'hooks/useRoute';

const Component = screeningItemContainer(ScreeningForm);

export const ScreeningFormWrapper = () => {
  const { fromSearchParams } = useRoute();
  const { patientId } = useOutletContext();
  return (
    <Component
      patientId={patientId}
      screeningItemId={fromSearchParams('screening')}
    />
  );
};
