import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { getBMI, getBmiStatus } from 'helpers/chart/vitals';
import { DATE_FORMATS } from 'helpers/date/constants';
import displaySmokingStatus from 'helpers/chart/vitals/manage/display';

import tablecx from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/VitalsTable.module.scss';
import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals/VitalsItem/VitalsItem.module.scss';

const VitalsItem = (props) => {
  const {
    vitals,
  } = props;

  const bmi = getBMI(vitals);
  const bmiStatus = getBmiStatus(bmi);

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const formattedDate = timeZoneDateConverter.getFormattedDateWithTimeZone(vitals.dateOfService, DATE_FORMATS.DEFAULT);

  return (
    <table className={`${tablecx.table} ${cx.table}`}>
      <thead>
        <tr>
          <th>{formattedDate}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{vitals.temperature}</td>
        </tr>
        <tr>
          <td>{vitals.pulse}</td>
        </tr>
        <tr>
          <td>{vitals.pressureFirst} / {vitals.pressureSecond}</td>
        </tr>
        <tr>
          <td>{vitals.respiratoryRate}</td>
        </tr>
        <tr>
          <td>{vitals.oxygenSaturation}</td>
        </tr>
        <tr>
          <td>{vitals.height}</td>
        </tr>
        <tr>
          <td>{vitals.weight}</td>
        </tr>
        <tr>
          <td style={{ color: bmiStatus && bmiStatus.color }} className={cx.bmi}>
            <span>{getBMI(vitals)}</span>
            {bmiStatus && (
              <img
                className={cx['bmi-icon']}
                height="20"
                src={bmiStatus.src}
                alt={bmiStatus.title}
                title={bmiStatus.title}
              />
            )}
          </td>
        </tr>
        <tr>
          <td>{vitals.pain}</td>
        </tr>
        <tr>
          <td>{vitals.bloodSugar}</td>
        </tr>
        <tr>
          <td>{vitals.smokingStatus && displaySmokingStatus(vitals.smokingStatus)}</td>
        </tr>
      </tbody>
    </table>
  );
};

VitalsItem.propTypes = {
  vitals: PropTypes.shape({
    dateOfService: PropTypes.number,
    temperature: PropTypes.number,
    pulse: PropTypes.number,
    pressureFirst: PropTypes.number,
    pressureSecond: PropTypes.number,
    respiratoryRate: PropTypes.number,
    oxygenSaturation: PropTypes.number,
    height: PropTypes.number,
    weight: PropTypes.number,
    pain: PropTypes.number,
    bloodSugar: PropTypes.number,
    smokingStatus: PropTypes.string,
  }).isRequired,
};

export default VitalsItem;
