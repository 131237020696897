import * as GENDERS from 'helpers/patient/gender/constants/list-constants';
import * as HE_SHE_CONSTANTS from 'helpers/elements/tags/converters/he-she/constants';

const heSheTagConverter = (gender, defaultCaseValue = HE_SHE_CONSTANTS.THEY) => {
  switch (gender) {
    case GENDERS.MALE:
      return HE_SHE_CONSTANTS.HE;
    case GENDERS.FEMALE:
      return HE_SHE_CONSTANTS.SHE;
    default:
      return defaultCaseValue;
  }
};

export default heSheTagConverter;
