import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return {
    navigate,
    location
  };
}; 