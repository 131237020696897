import React from 'react';
import PropTypes from 'prop-types';

import cx from './DrChronoBtn.module.scss';

const DrChronoBtn = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cx.btn}
    >
      {label}
    </button>
  );
};

DrChronoBtn.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

DrChronoBtn.defaultProps = {
  onClick: null,
};

export default DrChronoBtn;

