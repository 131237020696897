import * as GENDERS from 'helpers/patient/gender/constants/list-constants';
import * as HIS_HER_CONSTANTS from 'helpers/elements/tags/converters/his-her/constants';

const hisHerTagConverter = (gender) => {
  switch (gender) {
    case GENDERS.MALE:
      return HIS_HER_CONSTANTS.HIS;
    case GENDERS.FEMALE:
      return HIS_HER_CONSTANTS.HER;
    default:
      return HIS_HER_CONSTANTS.THEIR;
  }
};

export default hisHerTagConverter;
