import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isLocked, isProcessing, getError, unlock } from 'modules/locked';
import { toggleSidebar } from 'modules/sidebar';
import { fetchUser } from 'modules/user/actions';
import { acceptAgreement } from 'modules/user/info/actions';

import { getIsAuthenticated, getUserInfo, getPermissions } from 'modules/user/selectors';

import requireAuthentication from 'components/AuthCheck';
import RootComponent from 'routes/components/Root';

/**
 * обертка для инициализации
 * мы не должны вообще ничего делать пока не поймем, залогинен юзер или нет
 */
export const Root = props => {
  return <RootComponent {...props} />;
};

Root.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { sidebar } = state;

  return {
    sidebar,
    isAuthenticated: getIsAuthenticated(state.user),
    locked: isLocked(state.locked),
    lockProcessing: isProcessing(state.locked),
    unlockError: getError(state.locked),
    userInfo: getUserInfo(state.user),
    permissions: getPermissions(state.user),
  };
};

const RootWrapper = connect(
  mapStateToProps,
  {
    fetchUser,
    acceptAgreement,
    toggleSidebar,
    unlock,
  }
)(requireAuthentication(Root));

export default RootWrapper
