import { deleteIllness } from 'api/family';

import {
  DELETE_ILLNESS,
  DELETE_ILLNESS_FAILURE,
  DELETE_ILLNESS_SUCCESS
} from 'modules/med-select/constants';

export const addToDeleted = (id) => ({
  types: [
    DELETE_ILLNESS,
    DELETE_ILLNESS_SUCCESS,
    DELETE_ILLNESS_FAILURE
  ],
  promise: () => deleteIllness(id),
  id
})
