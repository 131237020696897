import React, { useContext } from 'react';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Tabs from 'components/Tabs';

import cx from './Reports.module.scss';
import ReportsList from '../ReportsRootList.js';

export const ReportsPage = () => {
  const { permissions } = useContext(AppContext);

  const tabsHead = [
    {
      title: 'Security review',
      url: '/app/doctor/reports',
      indexLink: true,
      disabled: !permissions.accessReportsAnalytics,
    },
  ];

  return (
    <div className={cx.page}>
      <div className={cx['tabs-wrapper']}>
        <Tabs headerItems={tabsHead}>
          <div className={cx.wrapper}>
            <ReportsList />
          </div>
        </Tabs>
      </div>
    </div>
  );
};
