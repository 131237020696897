import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ChartReportElementWithTooltip from '../../../../../ChartReportElementWithTooltip';
import RemoveButton from '../../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../../ChartReportElementWithTooltip/DoorButton';
import { DATE_FORMATS } from 'helpers/date/constants';

const MENU_BUTTON_DIAMETER = 40;

const ChartReportSurgicalItem = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { timeZoneDateConverter } = useContext(AppContext);

  const openMenu = () => setIsMenuOpen(true);

  const closeMenu = () => setIsMenuOpen(false);

  const {
    surgicalItem, toggleChartReport, chartId, onRemove, viewMode,
  } = props;

  let elementName = surgicalItem.surgery.name;

  if (surgicalItem.startDate) {
    elementName += ` (${timeZoneDateConverter.getFormattedDateWithTimeZone(surgicalItem.startDate, DATE_FORMATS.YEAR)})`;
  }

  return (
    <ChartReportElementWithTooltip
      isOpen={isMenuOpen}
      onOpen={openMenu}
      onClose={closeMenu}
      disabled={viewMode}
    >
      <span dangerouslySetInnerHTML={{ __html: elementName }} />
      <div className="flex-container">
        <DoorButton
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
            marginRight: 10,
          }}
          link={{ pathname: `/app/doctor/charts/${chartId}/fmss/surgical`, query: { surgical: surgicalItem.id } }}
          onClick={() => {
            closeMenu();
            toggleChartReport();
          }}
        />
        <RemoveButton
          onClick={onRemove}
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
          }}
        />
      </div>

    </ChartReportElementWithTooltip>
  );
};

ChartReportSurgicalItem.propTypes = {
  surgicalItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    surgery: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    startDate: PropTypes.number,
  }).isRequired,
  toggleChartReport: PropTypes.func,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onRemove: PropTypes.func.isRequired,
  viewMode: PropTypes.bool,
};

export default ChartReportSurgicalItem;

