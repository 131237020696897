import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { withRouter } from 'containers/RouterParams';
import { compose } from 'redux';

import InviteFormWrapper from 'containers/UserInviteFormContainer';

import EditFormTitle from 'components/EditForm/Title';

import ChoiceLinkToNavigationToUser from 'helpers/users/ChoiceLinkToNavigationToUser';
import * as usersHelpers from 'helpers/users/users';

import validator from 'validator';
import { checkExistingEmail, sendInvite } from 'api/user';
import UserRoleSelect from '../UserRow/components/UserRoleSelect';

import cx from './UserInviteForm.module.scss';

const defaultState = {
  isAbsentEmail: null,
  isInvalidEmail: false,
  isSuccessfulRequest: false,
  errorMessage: '',
  permissions: [],
  roleId: null,
  email: '',
};

const defaultErrorsState = {
  isAbsentEmail: null,
  isInvalidEmail: false,
  isSuccessfulRequest: false,
};

const inviteErrorMessage = {
  incorrect_email: 'Email is incorrect',
  exist_email_in_current_clinic: 'This user exists in the clinic',
  check_email: 'Email was sent',
};

class UserInviteForm extends Component {
  state = {
    ...defaultState,
  };

  onRoleChange = (userId, permissions, roleId) => {
    this.setState({ permissions, roleId });

    this.props.updatePermissions(permissions);
  };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      ...defaultErrorsState,
    });
    this.props.updateUser(e);
  };

  setErrorMessage = (errorMessage) => {
    this.setState({
      errorMessage,
    });
  };

  getEmailFromForm = () =>
    this.emailInput.value;

  submit = async () => {
    this.setErrorMessage(false);

    const email = this.getEmailFromForm();

    if (this.noNeedToSendInviteRequest(email)) {
      return;
    }

    clearTimeout(this.timeout);

    this.sendRequestToCheckEmail(email);
  };

  noNeedToSendInviteRequest = email =>
    this.isInvalidEmailInput(email) === true || this.isEmailExistInCurrentClinic(email) === true;

  updateIsAbsentEmail = (checkExistedEmailResponse) => {
    const isAbsentEmail = checkExistedEmailResponse === null;

    this.props.setIsDisplayInviteForm(!isAbsentEmail);
  };

  sendRequestToCheckEmail = (email) => {
    const emailData = usersHelpers.createEmailObjectToRequest(email);

    checkExistingEmail(emailData)
      .then((res) => {
        const { body } = res;

        this.updateIsAbsentEmail(body);

        if (typeof body === 'number') {
          this.sendInviteRequest(body);
        }
      });
  };

  sendInviteRequest = (userId) => {
    const inviteRequestData = this.collectDataToInviteRequest(userId);

    sendInvite(inviteRequestData)
      .then((res) => {
        this.afterSuccessfullInviting(res.body);
      });
  };

  afterSuccessfullInviting = (invitedUserResponse) => {
    const { id, enable } = invitedUserResponse;

    if (!usersHelpers.isUserPresent(id, this.props.users)) {
      this.props.createUserLocally(invitedUserResponse);
    }

    this.setErrorMessage(inviteErrorMessage.check_email);
    const { router: { push } } = this.props;
    push({
      pathname: ChoiceLinkToNavigationToUser.choose(enable),
      query: { userId: id }
    });
  };

  isInvalidEmailInput = (email) => {
    if (!validator.isEmail(email)) {
      this.setErrorMessage(inviteErrorMessage.incorrect_email);

      return true;
    }

    return false;
  };

  collectDataToInviteRequest = (userId) => {
    const permissionIds = this.state.permissions.map(item => item.id);
    const { roleId } = this.state;

    return {
      userId,
      permissionIds,
      roleId,
    };
  };

  isEmailExistInCurrentClinic = (email) => {
    if (usersHelpers.isUserBelongToCurrentClinic(email, this.props.users) === true) {
      this.setErrorMessage(inviteErrorMessage.exist_email_in_current_clinic);

      return true;
    }

    return false;
  };

  validateForm() {
    const {
      permissions,
      email,
    } = this.state;

    return !permissions.length || !email.length;
  }

  render() {
    const {
      isSuccessfulRequest,
      errorMessage,
    } = this.state;

    const {
      submit,
      onEmailChange,
    } = this;

    const {
      userLogin,
      isShowSubmitButton,
      userPermissions,
      roles
    } = this.props;

    return (
      <div className={cx.wrapper}>
        {isShowSubmitButton && (
          <EditFormTitle
            title="Add user"
          />
        )}
        <div
          className={cx['input-wrapper']}
        >
          <UserRoleSelect
            roles={roles}
            onRoleChange={this.onRoleChange}
            activePermissions={userPermissions}
            isInviteForm
          />
        </div>

        <div
          className={cx['input-wrapper']}
        >
          <input
            name="login"
            type="text"
            className={cx.input}
            placeholder="Enter email"
            ref={(input) => { this.emailInput = input; }}
            onChange={onEmailChange}
            value={userLogin}
          />
          <span className={cx['error-message']}>
            {errorMessage}
          </span>
          {isSuccessfulRequest && (
          <span className={cx['success-message']}>
                Email was sent successfully!
          </span>
            )}
        </div>
        {isShowSubmitButton && (
        <button
          type='button'
          className={cx['button--submit']}
          onClick={submit}
          disabled={this.validateForm()}
        >
              Submit
        </button>
          )}
      </div>
    );
  }
}

UserInviteForm.propTypes = {
  users: PropTypes.array,
  createUserLocally: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
  userLogin: PropTypes.string,
  isShowSubmitButton: PropTypes.bool,
  userPermissions: PropTypes.array,
  setIsDisplayInviteForm: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: func
  }).isRequired
};

UserInviteForm.defaultProps = {
  users: [],
  updateUser: null,
  userLogin: '',
  isShowSubmitButton: true,
  userPermissions: [],
};

export default compose(
  withRouter,
  InviteFormWrapper,
)(UserInviteForm);
