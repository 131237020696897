import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';

import ChartReportItem from 'components/ChartReport/ChartReportBody/ChartReportItem';
import ChartReportScreeningItem
  from 'components/ChartReport/ChartReportBody/HPNote/ChartReportFMSS/ChartReportScreening/ChartReportScreeningItem';

import stringParser from 'helpers/common/string/string-parser';

import { sortElementsByDateAndName } from 'helpers/fmss';

const ChartReportScreening = (props) => {
  const {
    chartId,
    toggleChartReport,
    screeningItems,
    deleteScreeningItem,
    location,
    viewMode,
  } = props;

  const sortedScreeningItems = sortElementsByDateAndName(
    screeningItems,
    { getItemName: item => item.screeningDetail.name }
  )

  const handleRemove = (screeningItem) => {
    const currentScreeningItemId = location.query.screening && stringParser(location.query.screening);

    const redirectPath = currentScreeningItemId === screeningItem.id ? location.pathname : null

    deleteScreeningItem(screeningItem, redirectPath)
  }

  return (
    <ChartReportItem
      title="Screening hx"
      tooltip={!viewMode}
      link={(!viewMode && `/app/doctor/charts/${chartId}/fmss/screening`) || null}
      toggleChartReport={!viewMode && toggleChartReport}
    >
      <ul style={{ padding: 0 }}>
        {sortedScreeningItems.map(screeningItem => (
          <li key={screeningItem.id}>
            <ChartReportScreeningItem
              toggleChartReport={toggleChartReport}
              chartId={chartId}
              viewMode={viewMode}
              screeningItem={screeningItem}
              onRemove={() => handleRemove(screeningItem)}
            />
          </li>
        ))}
      </ul>
    </ChartReportItem>
  )
}

const ChartReportScreeningComposer = compose(
  withRouter
)(ChartReportScreening)

export default ChartReportScreeningComposer;
