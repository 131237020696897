import React from 'react';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';

import financialTabContainer, { fetchLayerHOC } from 'containers/FinancialTabContainer';

import TabsHeader from 'components/UserEditForm/Header';

const tabsHead = [
  {
    name: 'payment hx',
    url: '/app/doctor/settings/financial',
    indexLink: true,
  },
  {
    name: 'plans',
    url: '/app/doctor/settings/financial/payments_plans',
  },
];

const FinancialContent = () => {
  return (
    <div className='child-tabs-wrapper'>
      <TabsHeader links={tabsHead} />
      <Outlet />
    </div>
  );
};

export const FinancialTab = compose(
  financialTabContainer,
  fetchLayerHOC
)(FinancialContent);
