import React from 'react';
import { func } from 'prop-types';

import Select from 'react-select';

import { selectOption } from 'helpers/propsGenerator';

const ClinicSelect = (props) => {
  const {
    selectedClinic = null,
    onClinicChange,
    clinics,
  } = props;

  return (
    <Select
      id="clinics"
      value={selectedClinic}
      placeholder="Choose clinic"
      onChange={onClinicChange}
      options={clinics}
    />
  );
};

ClinicSelect.propTypes = {
  onClinicChange: func.isRequired,
  selectedClinic: selectOption.obj,
  clinics: selectOption.arr.isRequired
};

export default ClinicSelect;
