import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import SeatUsersDropdown from 'components/SeatUsersDropdown';

import SeatDisabled from 'helpers/seats/SeatDisabled';
import EstimateTime from 'helpers/seats/estimate-time';
import TransformEstimateMessage from 'helpers/seats/transform-estimate-message';
import {
  SEATS_STATUS,
  isLockFreeSeat,
  getSeatPlan,
  getStatusClass
} from 'components/SeatUsersDropdown/helpers';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './SeatRow.module.scss';

const SeatRow = (props) => {
  const {
    index,
    users,
    seat,
    seats,
    areSeatsSaving,
    handleSeatUserChange,
    tableColumns,
    seatSetStatus,
    removeUserFromSeatLocally,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const seatIndex = index + 1;
  const unpaid = seat.status === SEATS_STATUS.NEED_TO_PAY;
  const isSeatDisabled = SeatDisabled.isDisabled(seat.status);

  const estimatedTime = EstimateTime.calculateEstimateTime(seat.expireDateLocking, timeZoneDateConverter);
  const estimateMessage = TransformEstimateMessage.getMessage(estimatedTime);

  return (
    <tr className={unpaid ? cx['disabled-block'] : ''}>
      <td width={tableColumns[0].width}>{`Seat ${seatIndex}`}</td>
      <td width={tableColumns[1].width}>
        <div className={cx['seat-status__container']}>
          <div className={resolveStylesV2({ objectStyles: cx, moduleStyles: ['planImage', getSeatPlan(seat), getStatusClass(seat)] })} />
          {isLockFreeSeat(seat) && (
          <div className={cx.estimate}>{estimateMessage}</div>
             )}
        </div>
        <br />
      </td>
      <td width={tableColumns[2].width}>
        <div className={cx['dropdown-wrapper']}>
          <SeatUsersDropdown
            users={users}
            handleSeatUserChange={handleSeatUserChange}
            seat={seat}
            seats={seats}
            areSeatsSaving={areSeatsSaving}
            seatSetStatus={seatSetStatus}
            disabled={isSeatDisabled}
            removeUserFromSeatLocally={removeUserFromSeatLocally}
          />
        </div>
      </td>
      <td width={tableColumns[3].width}>{unpaid ? '-' : '-'}</td>
      <td width="10%" />
    </tr>
  );
};

SeatRow.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SeatRow;
