import React from 'react';
import PropTypes from 'prop-types';

import Tether from 'react-tether';
import reactStringReplace from 'react-string-replace';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PosNegButton from 'components/PosNegButton';
import ElementEditButton from 'components/ElementEditButton';
import {
  VariableButton
} from 'components/NoteSystems/System/EnclosedElements/Element/VariableButton';
import ElementEditForm from 'components/NoteSystems/System/EnclosedElements/Element/ElementEditForm';
import DiagnosesList from 'components/NoteSystems/System/EnclosedElements/Element/DiagnosesList';

import { sortSubIdsByPosition } from 'helpers/chart';
import {
  convertToHtmlNewlines,
  isNullOrUndefined,
  variableButtonPattern,
} from 'helpers';
import DisableEditingElement from 'helpers/social/elements/disable-editing';
import DisableCreationElementAndSubElement from 'helpers/elements/disable-creation';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './Element.module.scss';

const MENU_BUTTON_DIAMETER = 30;

const elementStyles = ({ elementType }) => ({
  wrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'wrapper',
    globalStyles: 'break-text'
  }),
  rosType: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['type', !elementType && 'type--neg']
  })
});

export class Element extends React.PureComponent {
  state = {
    isMenuOpen: false,
    isDiagnosesMenuOpen: false,
    editMode: false,
    isAutoOpenSubElementModal: false,
  };
  constructor(props, context) {
    super(props);

    const {
      currentUserId,
    } = context;

    const {
      element,
      social,
    } = props;

    const {
      userId,
    } = element;

    const disableEditingElement = new DisableEditingElement(userId, currentUserId, social);

    this.isDisabledEditingElement = disableEditingElement.isDisabled();

    this.inputRef = React.createRef();
    this.popupRef = React.createRef();
    this.diagnosesListRef = React.createRef();
    this.exclude = [this.inputRef, this.diagnosesListRef];
  }

  closeAllMenu = () => this.setState({ isDiagnosesMenuOpen: false, isMenuOpen: false });

  handleClickOutside(event, instance) {
    if (instance.exclude.some(excludeRef => excludeRef?.current?.contains(event.target))) {
      return;
    }
    if (!instance.popupRef?.current?.contains(event.target)) {
      instance.closeAllMenu();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', e => this.handleClickOutside(e, this));
    this.forceUpdateSocialSystem(this.props);
  }

  componentDidUpdate() {
    this.forceUpdateSocialSystem(this.props);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setIsAutoOpenSubElementModal = (isAutoOpenSubElementModal) => {
    this.setState({
      isAutoOpenSubElementModal,
    });
  };

  getIndexToAutoSetSubElement = () => {
    const {
      listOfAutoOpenSubelementsIndexes,
    } = this.props;

    const indexesToAutoSet = listOfAutoOpenSubelementsIndexes[this.props.element.id];

    if (indexesToAutoSet && indexesToAutoSet.indexToAutoSet.length > 0) {
      return indexesToAutoSet.indexToAutoSet[0];
    }

    return -1;
  };

  getVariableContentByIndex = (subIds, index, setNumber) => {
    if (subIds && subIds.length && subIds[index] && subIds[index].name) {
      const { elementNameConverter } = this.context;
      return elementNameConverter.transformElementNameWrapper(subIds[index].name, this.props.patient);
    }

    return `??${  setNumber || ''}`;
  };

  getTransformedElementName = () => {
    const {
      element,
      patient,
      disableSubelements,
    } = this.props;

    if (element.name === undefined) return null;

    const { elementNameConverter } = this.context;
    let displayNameAsArray = elementNameConverter.transformElementNameWrapper(element.name, patient);

    const needRenderSubElements = displayNameAsArray.some(part =>
      typeof part === 'string' && part.includes('??')
    ) && !disableSubelements;

    if (needRenderSubElements) {
      let subElementIndex = -1;
      displayNameAsArray = reactStringReplace(displayNameAsArray, variableButtonPattern, () =>
        this.renderVariableButton(++subElementIndex)
      );
    }
    displayNameAsArray = convertToHtmlNewlines(displayNameAsArray);

    return displayNameAsArray;
  };

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  repositionTether = () => {
    if (this.tetherRef && this.tetherRef.getTether()) {
      this.tetherRef.getTether()
        .position();
    }
  };

  handleSave = async (name) => {
    const {
      element,
      updateElementName,
    } = this.props;

    const updatedElement = {
      name,
      id: element.id,
    };

    await updateElementName(updatedElement, element);
  };

  forceUpdateSocialSystem = (propsData) => {
    const {
      listOfAutoOpenSubelementsIndexes,
      element,
      social,
    } = propsData;

    if (this.isNeedAutoOpenSubElementsModal(listOfAutoOpenSubelementsIndexes[element.id], social)) {
      this.setIsAutoOpenSubElementModal(true);
    }
  };

  isNeedAutoOpenSubElementsModal = (listOfAutoOpenSubelementsIndexesForElement, isSocial) => {
    return listOfAutoOpenSubelementsIndexesForElement && listOfAutoOpenSubelementsIndexesForElement.indexToAutoSet[0] && isSocial === true;
  };

  openMenu = (e) => {
    // костыль
    // e.stopPropagation почему-то не работает, надо запретить открытие меню по клику на переменную
    if (e.target.className.includes(cx.variable)) {
      return;
    }
    this.setState({
        isMenuOpen: true,
        isDiagnosesMenuOpen: true
      },
      () => {
        this.repositionTether();

        if (this.outerTetherRef && this.outerTetherRef.getTether()) {
          this.outerTetherRef.getTether()
            .position();
        }
      }
    );
  };

  handleSubelementSelect = (subelement, activeSubelement, elementId, index) => {
    const subIds = (this.props.element.subIds && [...this.props.element.subIds]) || [];

    subIds[index] = subelement;

    return this.updateElement('subIds', subIds);
  };

  handleDiagnosisSelect = async (diagnosis) => {
    const {
      element,
      saveChart,
      chartId,
      step,
    } = this.props;

    let subElementIds = [];
    const subElementObjects = element.ids || [];

    if (element.ids) {
      subElementIds = subElementObjects.filter(o => o).map(o => o.id);
    }

    const newSubElements = subElementIds.includes(diagnosis.id)
      ? subElementObjects.filter(o => o.id !== diagnosis.id)
      : [...subElementObjects, diagnosis];

    await this.updateElement('ids', newSubElements);
    return saveChart(chartId, step);
  };

  updateElement = (prop, value) => {
    const element = { ...this.props.element };

    element[prop] = value;
    return this.props.updateElement(element);
  };

  renderVariableButton = (index) => {
    const {
      element,
      social,
      allowCreation,
      isChartSaving,
      saveChart,
      selectAfterFirstSubElementCallback,
      exactMatch,
      broadMatch,
    } = this.props;

    const { permissions } = this.context;

    const {
      name,
    } = element;

    let setNumber = parseInt(name.match(/\?\?\d?/g)[index]?.replace('??', ''));
    if (Number.isNaN(setNumber)) {
      setNumber = 0;
    }

    const newElement = sortSubIdsByPosition(element, social);

    const variableContent = this.getVariableContentByIndex(newElement.subIds, index, setNumber);

    const indexToAutoSet = this.getIndexToAutoSetSubElement();

    const disableSubelementCreation = DisableCreationElementAndSubElement.isDisabled(social, permissions);

    const props = {
      selectAfterFirstSubElementCallback,
      onCreateSubelement: this.props.createSubelement,
      disableSubelementCreation,
      element: this.props.element,
      elementId: this.props.element.id,
      onSelect: (subelement, activeSubelement, elementId, index) => this.handleSubelementSelect(subelement, activeSubelement, elementId, index),
      activeSubelement: newElement.subIds && newElement.subIds[index],
      step: this.props.step,
      chartId: this.props.chartId,
      social: this.props.social,
      systemId: this.props.systemId,
      patientId: this.props.patientId,
      indexToAutoSet,
      index,
      resetIndexToAutoSet: this.props.resetIndexToAutoSet,
      isAutoOpenSubElementModal: this.state.isAutoOpenSubElementModal,
      setNumber,
      isDisabledSubElement: this.isDisabledEditingElement || !allowCreation,
      isChartSaving,
      saveChart,
      exactMatch,
      broadMatch,
    };

    return (
      <VariableButton {...props} key={`variablebutton_${index}`} >
        {variableContent}
      </VariableButton>
    );
  };

  handleElementRemove = () =>
    this.props.removeElement();

  handleClickByElementName = () => {
    if (this.props.element.deleted) {
      const { setInfoModalData } = this.context;
      setInfoModalData(
        {
          message: 'This element has already been renamed on a newer Encounter Note. Please remove this element and select a new one',
          title: 'Warning',
        }
      );
    } else {
      this.setEditMode();
    }
  };

  setEditMode = () =>
    this.setState(state => ({ editMode: !state.editMode }));

  cancelEditMode = () => {
    this.setState({ editMode: false });
  };

  render() {
    const {
      element,
      isROS,
      patientId,
      editable,
      assignedDiagnoses,
      isChartSaving,
      saveChart,
      chartId,
      step,
      isAllowAttachingIcdTenCodes,
    } = this.props;

    if (element.name === undefined) return null;

    const isEditable = !this.isDisabledEditingElement && editable;

    const {
      openMenu,
      updateElement,
      handleDiagnosisSelect,
    } = this;
    const { isMenuOpen, editMode, isDiagnosesMenuOpen } = this.state;

    let type;

    if (!isNullOrUndefined(element.type)) {
      type = element.type ? 'pos' : 'neg';
    }

    const { name } = element;
    const elementIds = element?.ids?.filter(o => o).map(o => o.id) || [];
    const displayName = this.getTransformedElementName();

    const resolvedStyles = elementStyles({ elementType: element?.type });

    return (
      <div
        className={resolvedStyles.wrapper}
        style={{
          width: (editMode && '100%') || 'initial',
        }}
      >
        <Tether
          attachment="top center"
          targetAttachment="bottom center"
          constraints={[
            {
              to: 'window',
              attachment: 'together',
            },
          ]}
          classPrefix="enclosedElementDiagnoses"
          renderTarget={topTetherTargetRef => (
            <div ref={topTetherTargetRef} className={cx['inner-wrapper']}>
              <Tether
                attachment="top center"
                targetAttachment="top center"
                constraints={[{
                  to: 'scrollParent',
                }]}
                offset="50px 0"
                classPrefix="enclosedElement"
                renderTarget={innerTetherTargetRef => {
                  this.inputRef.current = innerTetherTargetRef.current;
                  return (
                    <div ref={innerTetherTargetRef} className={cx.element} onClick={isMenuOpen ? this.closeAllMenu : openMenu}>
                    <span className={cx['element-flex-container']}>
                      {type && <span className={resolvedStyles.rosType}>{type}</span>}
                      {type && ' '}
                      <span className={cx['element-name']}>
                        {
                          isEditable &&
                          this.state.editMode && (
                            <ElementEditForm
                              onChange={this.repositionTether}
                              value={name}
                              isUpdating={element.isUpdating}
                              onCancel={this.cancelEditMode}
                              onSave={this.handleSave}
                            />
                          )
                        }

                        {!this.state.editMode && (
                          <span>
                            {displayName}
                          </span>
                        )}

                        {!!element?.ids?.filter(o => o).length && (
                          <span className={cx.diagnoses}>
                            {' '}
                            ({element.ids
                            .filter(o => o)
                            .map(diagnosis => diagnosis.name)
                            .join(', ')})
                          </span>
                        )}
                      </span>
                    </span>
                    </div>
                  );
                }}
                renderElement={topTetherElementRef => isMenuOpen && (
                  <span ref={this.popupRef}>
                  <div ref={topTetherElementRef} className={cx['popup-wrapper']} >
                    <div className={cx.popup}>
                      {isROS &&
                        <PosNegButton
                          neg={element.type}
                          onClick={async () => {
                            await updateElement('type', !element.type);
                            saveChart(chartId, step);
                          }}
                          diameter={MENU_BUTTON_DIAMETER}
                        />
                      }

                      {isEditable && (
                        <ElementEditButton
                          diameter={MENU_BUTTON_DIAMETER}
                          onClick={this.handleClickByElementName}
                        />
                      )}

                      <button
                        type='button'
                        className={cx['remove-button']}
                        onClick={this.handleElementRemove}
                        style={{
                          width: MENU_BUTTON_DIAMETER,
                          height: MENU_BUTTON_DIAMETER,
                        }}
                      />
                    </div>
                  </div>
                </span>
                )}
              />
            </div>
          )}
          renderElement={innerTetherElementRef => isAllowAttachingIcdTenCodes &&
            isDiagnosesMenuOpen && (
              <span ref={this.diagnosesListRef}>
                  <div ref={innerTetherElementRef} className={cx['diagnoses-list-wrapper']}>
                    <div className={cx['diagnoses-list-inner-wrapper']}>
                      <DiagnosesList
                        patientId={patientId}
                        diagnoses={assignedDiagnoses}
                        onSelect={handleDiagnosisSelect}
                        selectedDiagnoses={elementIds}
                        isChartSaving={isChartSaving}
                      />
                    </div>
                  </div>
                </span>
            )}
        />
      </div>
    );
  }
}

Element.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    subIds: PropTypes.arrayOf(PropTypes.object),
    systemId: PropTypes.number,
    locked: PropTypes.bool,
    type: PropTypes.bool,
    ids: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    deleted: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isAutoSetSubElements: PropTypes.bool,
    countIndexesForAutoSet: PropTypes.number,
  }).isRequired,
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.number.isRequired,
    gender: PropTypes.string.isRequired,
  }).isRequired,
  removeElement: PropTypes.func.isRequired,
  isROS: PropTypes.bool,
  updateElement: PropTypes.func,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  updateElementName: PropTypes.func,
  social: PropTypes.bool,
  listOfAutoOpenSubelementsIndexes: PropTypes.object.isRequired,
  createSubelement: PropTypes.func.isRequired,
  resetIndexToAutoSet: PropTypes.func.isRequired,
  allowCreation: PropTypes.bool.isRequired,
  isChartSaving: PropTypes.bool,
  saveChart: PropTypes.func,
  isAllowAttachingIcdTenCodes: PropTypes.bool.isRequired,
  removeElementCallback: PropTypes.func,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

Element.defaultProps = {
  social: false,
  saveChart: null,
  removeElementCallback: () => {},
  exactMatch: {},
  broadMatch: {},
};

Element.contextType = AppContext;

export default Element;
