import React from 'react';
import { Outlet } from 'react-router-dom';

import TabsHeader from 'components/UserEditForm/Header';

import { connectedFetchLayerHOC } from 'containers/Users/UsersContainer';

const Users = () => {
  const tabsHead = [
    {
      name: 'Seats',
      url: '/app/doctor/settings/users/seats',
    },
    {
      name: 'Active Users',
      url: '/app/doctor/settings/users',
      indexLink: true,
    },
    {
      name: 'Inactive Users',
      url: '/app/doctor/settings/users/inactive',
    },
    {
      name: 'Roles',
      url: '/app/doctor/settings/users/roles',
    },
  ];

  return (
    <div className='child-tabs-wrapper'>
      <TabsHeader links={tabsHead} />
      <Outlet />
    </div>
  );
};

export const UsersTab = connectedFetchLayerHOC(Users);
