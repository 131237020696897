import React from 'react';
import PropTypes from 'prop-types';

import ProfileImage from './ProfileImage';
import About from './About';

import cx from './PersonShortInfo.module.scss';

const PersonShortInfo = ({
  person,
}) => (
  <div className={cx.wrapper}>
    <ProfileImage
      imageUrl={person.imageUrl}
    />
    {person.lastName && (
      <About
        firstName={person.firstName}
        lastName={person.lastName}
        gender={person.gender}
        dob={person.dob}
      />
    )}
  </div>
);

PersonShortInfo.propTypes = {
  person: PropTypes.shape({
    image: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    dob: PropTypes.number,
    imageUrl: PropTypes.string
  }).isRequired,
};

export default PersonShortInfo;
