import React from 'react';
import PropTypes from 'prop-types';

import Datepicker from 'components/Datepicker';
import DatePickerTitle from 'components/DatePickerTitle';
import ValidatedField from 'components/Form/ValidatedField';

const DatePicker = (props) => {
  const {
    value,
    title,
    isDisabledFutureDates = false,
    onChange,
  } = props;

  return (
    <>
      <DatePickerTitle title={title} />
      <ValidatedField>
        <Datepicker
          name='dob'
          value={value}
          onChange={onChange}
          isDisabledFutureDates={isDisabledFutureDates}
        />
      </ValidatedField>
    </>
  );
}

DatePicker.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDisabledFutureDates: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
