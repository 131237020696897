import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = null;

export default handleActions({
  [constants.PATIENTS_FETCH]: () => null,
  [constants.PATIENTS_FETCH_SUCCESS]: () => null,
  [constants.PATIENTS_FETCH_FAIL]: (state, action) => ({
    status: action.error.status,
    message: action.error.message,
  }),
}, initialState);

export const getError = state => state;
