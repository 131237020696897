import React, { Component } from 'react';
import cx from './TableHead.module.scss';
import PropTypes from 'prop-types';

import Arrows from '../SortArrows';
import * as helpers from 'components/SeatUsersDropdown/helpers';
import SeatStatusDescription from 'components/SeatUsersDropdown/SeatStatusDescription';

const components = {
  div: ({ children, width }) => <div style={{ width }}>{children}</div>,
  default: ({ children, width }) => <th style={{ width }}>{children}</th>,
};

class TableHead extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    sortable: PropTypes.bool,
    width: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center']),
    component: PropTypes.oneOf(['div', 'default']),
    onClick: PropTypes.func,
    desc: PropTypes.bool,
    children: PropTypes.node,
    hideSubtitleLine: PropTypes.bool,
    upperCase: PropTypes.bool,
    swapTitles: PropTypes.bool,
  }

  static defaultProps = {
    title: undefined,
    subtitle: undefined,
    sortable: undefined,
    width: undefined,
    textAlign: undefined,
    component: 'default',
    onClick: undefined,
    desc: undefined,
    children: undefined,
    hideSubtitleLine: undefined,
    upperCase: true,
    swapTitles: false,
  }

  renderTitles({ justifyContent }) {
    const {
      title,
      subtitle,
      textAlign,
      sortable,
      onClick,
      desc,
      hideSubtitleLine,
      upperCase,
      swapTitles,
    } = this.props;

    const titleJSX = (
      <span
        onClick={onClick}
        className={cx[upperCase ? 'title' : 'title--lower-case']}
        style={{
          cursor: sortable ? 'pointer' : 'default',
          justifyContent,
        }}
        key={title}
      >
        {title}{' '}{sortable ? <Arrows desc={desc} /> : null}
      </span>
    );

    const subtitleJSX = (
      <div
        className={cx['subtitle-wrapper']}
        style={{
          justifyContent,
        }}
        key={`${title}_${subtitle}`}
      >
        {!hideSubtitleLine && (
          <span
            className={cx['subtitle']}
            style={{
              textAlign: textAlign || 'center',
            }}
          >
            {subtitle || '\u00a0'}
          </span>
        )}
      </div>
    );

    if (swapTitles) {
      return [subtitleJSX, titleJSX];
    }

    return [titleJSX, subtitleJSX];
  }

  render() {
    const {
      width,
      textAlign,
      component,
      children,
    } = this.props;

    const Component = components[component] || components.default;
    let justifyContent;

    switch (textAlign) {
      case 'left':
        justifyContent = 'flex-start';

        break;
      case 'right':
        justifyContent = 'flex-end';

        break;
      default:
        justifyContent = 'center';
    }

    if (typeof children !== 'undefined') {
      return (
        <Component
          width={width}
        >
          {children}
        </Component>
      );
    }

    return (
      <Component
        width={width}
      >
        {this.renderTitles({ justifyContent })}

        {this.props.subtitle === 'indicator' && (
        <div className={cx['cell-wrapper']}>
          <SeatStatusDescription
            status={helpers.seatStatusDescription}
          />
        </div>
          )}
      </Component>
    );
  }
}

export default TableHead;
