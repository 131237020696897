import React from 'react';
import { func } from 'prop-types';

import cx from './Overlay.module.css';

const Overlay = ({
  onClick,
}) => (
  <div className={cx.overlay} onClick={onClick && onClick} />
);

Overlay.propTypes = {
  onClick: func,
};

Overlay.defaultProps = {
  onClick: () => {},
};

export default Overlay;
