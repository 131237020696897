import React from 'react';
import PropTypes from 'prop-types';

import PatientHistoryItemsTableContainer from 'containers/PatientHistoryItemsTable';

import { isNullOrUndefined } from 'helpers';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import fields from './fields';

import cx from './DiagnosesTable.module.scss';

const rowStyle = ({selectedId, item}) => cssClassesResolver([
  cx.item, item.id === selectedId && cx['item--active']
])

const DiagnosesTable = ({
  items,
  selectedId,
  showDiagnosisById,
  filter,
  error,
  onReload,
}) => (
  <PatientHistoryItemsTableContainer
    items={items}
    defaultDesc={false}
    defaultSortBy="code"
    mapItemToField={item => ({
          id: item.id,
          code: item.diagnosis.name.substring(0, item.diagnosis.name.indexOf(' ')),
          diagnosis: item.diagnosis.name.substring(item.diagnosis.name.indexOf(' ')),
          comment: item.description,
          status: !isNullOrUndefined(item.chronic) ? (item.chronic && 'Chronic') || 'Acute' : null,
        })}
    error={error}
    onReload={onReload}
    fields={fields}
    itemRenderer={item => (
      <tr
        className={rowStyle({selectedId, item})}
        key={item.id}
        onClick={() => showDiagnosisById(item.id)}
      >
        <td>{item.code}</td>
        <td>
          <span className={cx.name}>{item.diagnosis}</span>
        </td>
        <td>{item.comment}</td>
        <td>{item.status}</td>
      </tr>
        )}
    emptyMessage={`No ${filter} Diagnoses`}
  />
);

DiagnosesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    diagnosis: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string,
    chronic: PropTypes.bool,
  })).isRequired,
  showDiagnosisById: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  selectedId: PropTypes.number,
  onReload: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

DiagnosesTable.defaultProps = {
  selectedId: undefined,
  error: undefined,
};

export default DiagnosesTable;
