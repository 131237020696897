import { isEmpty } from 'lodash';

import * as fromByChart from './byChart/selectors';

import deleteMultipleProperties from 'helpers/common/object/deleteMultipleProperties';

export const getFavoritesToReset = (state, chartId) => fromByChart.getFavoritesToReset(state.byChart, chartId);

export const resetBroadOrExactFavorites = (state, broadOrExactFavorites, chartId) =>
  (isEmpty(broadOrExactFavorites)
    ? broadOrExactFavorites
    : deleteMultipleProperties(broadOrExactFavorites, getFavoritesToReset(state, chartId)));
