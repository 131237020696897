import collectSubElementData, {
  completeSubElementProps,
  STRATEGIES
} from 'helpers/elements/subElementData/converters/fromSubElements';

export default class ElementDTO {
  constructor(element) {
    this.element = element;
  }

  formatForClient = () => ({
    id: this.element.getId(),
    ids: this.element.getIds(),
    type: this.element.getType(),
    subIds: this.element.getSubIds(),
    name: this.element.getName(),
    deleted: this.element.getDeleted(),
    prescribed: this.element.getPrescribed(),
    favoriteId: this.element.getFavoriteId(),
    subElementData: collectSubElementData(this.element.getSubIds()),
    subElementFavoriteData: collectSubElementData(this.element.getSubIds(), completeSubElementProps(STRATEGIES.FAVORITE)),
  })

  formatForAddElement = () => ({
    id: this.element.getId(),
    name: this.element.getName(),
    favoriteId: this.element.getFavoriteId(),
    type: this.element.getType(),
    subIds: this.element.getSubIds(),
  })
}
