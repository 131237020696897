import * as api from 'api/social';

import * as constants from './constants';
import { addElement, selectElement } from 'modules/socialSessions/actions';

import SocialElementService from 'helpers/social/SocialElementService';

export const fetchElementsBySystem = (systemId, patientId) => ({
  types: [
    constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM,
    constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_SUCCESS,
    constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_FAIL,
  ],
  promise: () => api.fetchElementsBySystem(systemId, patientId),
  systemId,
});

export const createElement = (patientId, element, elementToAdd) => ({
  types: [
    constants.CREATE_SOCIAL_ELEMENT,
    constants.CREATE_SOCIAL_ELEMENT_SUCCESS,
    constants.CREATE_SOCIAL_ELEMENT_FAIL,
  ],
  promise: dispatch => api.createElement({ socialId: element.systemId, name: element.name, patientId })
    .then((res) => {
      dispatch(addElement(
        patientId,
        element.systemId,
        {
          ...element,
          id: res.body,
        },
        elementToAdd
      )
      );
      return res;
    }),
  patientId,
  element,
});

export const updateElement = (dataToLocalSave, dataToRequest) => ({
  types: [
    constants.UPDATE_SOCIAL_ELEMENT,
    constants.UPDATE_SOCIAL_ELEMENT_SUCCESS,
    constants.UPDATE_SOCIAL_ELEMENT_FAIL,
  ],
  promise: () => api.updateElement(dataToRequest),
  ...dataToLocalSave,
});

export const updateSocialSubElementsHistory = (dataToRequest, localData) => ({
  types: [
    constants.UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY,
    constants.UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_SUCCESS,
    constants.UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_FAIL,
  ],
  promise: async () => api.updateSocialSubElementForElement(dataToRequest),
  localData,
  patientId: dataToRequest.patientId,
});

export const updateSocialSubElementsHistoryWrapper = (element, subElement, patientId, activeSystemId) => async (dispatch) => {
  const socialElementService = new SocialElementService(element, patientId, activeSystemId);
  const { dataToRequest, localData } = socialElementService.collectDataToUpdateSubElementsHistoryWrapper(subElement);

  dispatch(updateSocialSubElementsHistory(dataToRequest, localData));
};

export const selectSubElementWrapper = (element, subElement, patientId, activeSystemId, chartId) => async (dispatch) => {
  await dispatch(selectElement({
    patientId, elementId: element.id, socialId: activeSystemId, chartId,
  }));

  const socialElementService = new SocialElementService(element, patientId, activeSystemId);
  const { dataToRequest, localData } = socialElementService.collectDataToUpdateSubElementsHistoryWrapper(subElement);

  dispatch(updateSocialSubElementsHistory(dataToRequest, localData));
};

export const deleteRightSideElement = ({ id, socialId }) => ({
  types: [
    constants.DELETE_RIGHT_SIDE_ELEMENT,
    constants.DELETE_RIGHT_SIDE_ELEMENT_SUCCESS,
    constants.DELETE_RIGHT_SIDE_ELEMENT_FAIL
  ],
  promise: async () => api.deleteRightSideElement(id),
  id,
  socialId
});
