import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import {
  ACCESS_ACCOUNT_SUCCESS
} from 'modules/impersonate/constants';
import { USER_FETCH_SUCCESS } from 'modules/user/constants';

const initialState = {
  userId: null
};

export default handleActions({
  [ACCESS_ACCOUNT_SUCCESS]: (state, action) => ({
    userId: action.payload.userId
  }),
  [USER_FETCH_SUCCESS]: (state, action) => ({
    userId: action.payload.result?.impersonate ? action.payload.result?.user?.id : null
  })
}, initialState);


export const isUserImpersonate = createSelector(
  [state => state.impersonate],
  impersonate => Number.isInteger(impersonate.userId)
);
