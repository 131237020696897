import React from 'react';

import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';
import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import ValidatedDatepicker from 'components/ValidatedDatepicker';
import RadioButtons from 'components/RadioButtons';
import RadioButtonGroup from 'components/RadioButtonGroup';
import CommentBox from 'components/CommentBox';
import ValidatedField from 'components/Form/ValidatedField';
import DiagnosisInput from 'components/DiagnosisInput';
import SimpleSelect from 'components/MedSelect/SimpleSelect/SimpleSelect';

import { medicationWhiteList } from 'helpers/medications';
import { buildSchema } from 'helpers/validationRules';
import { selectOption } from 'helpers/propsGenerator';
import { multiSelectOptions } from 'components/Select/customStyles';
import { formatMedOption } from 'helpers/selectHelpers';

import { newMedication, searchMedication, deleteMedMedication } from 'api/medications';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptyMedication = {
  medication: selectOption.default,
  active: true,
  prescribed: false,
  description: '',
};

const statusItems = [{
  name: 'Active',
  value: true,
}, {
  name: 'Inactive',
  value: false,
}];

const mapProps = (medication) => ({
  id: medication.id,
  medication: formatMedOption(medication.medication),
  diagnosis: medication.diagnosis,
  startDate: medication.startDate,
  endDate: medication.endDate,
  active: medication.active,
  prescribed: medication.prescribed,
  description: medication.description,
})

const validationSchema = () => buildSchema({
  medication: 'requiredSelectOption',
})

const MedicationForm = (props) => (
  <FormHOC
    emptyItem={emptyMedication}
    item={props.medication}
    itemId={props.medicationId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={data => props.onUpdate({ ...data, printInChart: true })}
    onCreate={data => props.onCreate({ ...data, printInChart: true })}
    onDelete={props.onDelete}
    patientId={props.patientId}
    whiteList={medicationWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({
 itemId: medicationId, item, fetching, clean, update, del, submit
}) => {
  return (
    <form
      onSubmit={submit}
      className={cx.form}
    >
      <EditForm>
        <div>
          <EditFormTitle
            title={medicationId ? 'Editing medication' : 'Adding medication'}
            onClick={medicationId ? clean : () => null}
          />

          <div className={cx.row}>
            <ValidatedField>
              <SimpleSelect
                currentValue={item?.medication}
                name="medication"
                onCreate={newMedication}
                onChange={value => update('medication', value)}
                loadOptions={searchMedication}
                onRemoveOption={deleteMedMedication}
              />
            </ValidatedField>
          </div>

          <div className={cx.row}>
            <h3 className="section-title">Associated diagnosis</h3>
            <DiagnosisInput
              patientId={props.patientId}
              onChange={value => update('diagnosis', value)}
              value={item.diagnosis}
              multi
              placeholder="Search existing diagnoses for this patient"
              styles={multiSelectOptions}
            />
          </div>

          <div className={cx['pair-dates']}>
            <div className={cx.datepicker}>
              <ValidatedDatepicker
                title="start date"
                value={item?.startDate}
                onChange={date => update('startDate', date)}
              />
            </div>
            <div className={cx.datepicker}>
              <ValidatedDatepicker
                title="end date"
                value={item?.endDate}
                onChange={date => update('endDate', date)}
              />
            </div>
          </div>

          <div className={cx.row}>
            <div className={cx['status-wrapper']}>
              <RadioButtons
                title="Status"
                required
                active={item.active}
                items={statusItems}
                onChange={value => update('active', value)}
              />
            </div>

            <div className={cx.prescribed}>
              <h3 className={cx['prescribed-title']}>Prescribed by other doctor?</h3>
              <RadioButtonGroup
                items={[{
                  name: 'Yes',
                  value: true,
                }, {
                  name: 'No',
                  value: false,
                }]}
                checked={item.prescribed}
                onChange={value => update('prescribed', value)}
              />
            </div>
          </div>

          <div className={cx.row}>
            <CommentBox
              title="medication instructions"
              placeholder="Enter an instruction for this medication"
              value={item?.description}
              onChange={e => update('description', e.target.value)}
            />
          </div>
        </div>

        <EditFormActions
          deleteButton={!!medicationId}
          cancelButton
          submitButton
          disabledSubmit={fetching}
          disabledCancel={fetching}
          onDelete={del}
          onCancel={clean}
        />

      </EditForm>
    </form>
  )}}
</FormHOC>
);

MedicationForm.propTypes = generateFormPropTypes('medication', 'medicationId');

const DirtyCheckedWrapper = dirtyCheckLayerHOC(MedicationForm);

export default MedicationForm;

// export default props => (
//   <LoaderLayer
//     disabled={props.medicationId === undefined}
//     status={props.status}
//     reload={() => props.fetchMedication(props.medicationId)}
//   >
//     <DirtyCheckedWrapper
//       {...props}
//       dirtyCheckIdentity="medicationId"
//     />
//   </LoaderLayer>
// );
