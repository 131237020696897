import React from 'react';
import PropTypes from 'prop-types'

import cx from './ChartReportFooter.module.scss';

const footerLogo = require('../../../images/EHR-Logo-Name.png')

const ChartReportFooter = ({ removeEhrsynergyLogo }) => (
  <div className={cx.wrapper}>
    <div className={cx.wish}>The Future of Medical Charting!</div>
    <div>
      {!removeEhrsynergyLogo && (
        <div className={cx.logo}>
          <img src={footerLogo} alt="" />
        </div>
      )}
      <div className={cx.copyright}>© {new Date().getFullYear()} EHRsynergy, LLC</div>
    </div>
  </div>
);

ChartReportFooter.propTypes = {
  removeEhrsynergyLogo: PropTypes.bool.isRequired
}

export default ChartReportFooter;
