import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = false;

export default handleActions({
  [constants.ROLE_CREATE]: () => true,

  [constants.ROLE_CREATE_SUCCESS]: () => false,

  [constants.ROLE_CREATE_FAIL]: () => false,
}, initialState)
