import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from 'modules/financial/paymentsPlans/actions';
import { setPurchaseSeatModalsView } from 'modules/purchaseSeat/actions';

import { getPaymentsPlans } from 'modules/financial/paymentsPlans/selectors';

export const fetchLayerHOC = (Component) => {
  class UserPaymentInfoFetchLayer extends React.PureComponent {
    componentDidMount() {
      const { fetchPaymentInfo, isFetchedPaymentInfo } = this.props;

      if (isFetchedPaymentInfo === false) {
        fetchPaymentInfo();
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };

  UserPaymentInfoFetchLayer.propTypes = {
    fetchPaymentInfo: PropTypes.func.isRequired,
    isFetchedPaymentInfo: PropTypes.bool.isRequired,
  };

  return UserPaymentInfoFetchLayer;
};

const mapStateToProps = (state, ownProps) => ({
  paymentsPlans: getPaymentsPlans(state.financial),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
  setPurchaseSeatModalsView
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
