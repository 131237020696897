import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from 'components/Loader';

import * as selectors from 'modules/drChronoAuth/selectors';
import * as actions from 'modules/drChronoAuth/actions';

export const fetchLayerHOC = Component => (
  class DrChronoFetchLayer extends React.Component {
    static propTypes = {
      setDrChronoToken: PropTypes.func.isRequired,
      fetchDrChronoToken: PropTypes.func.isRequired,
      isFetching: PropTypes.bool,
      isFetched: PropTypes.bool,
    };

    componentDidMount = async () => {
      const {
        setDrChronoToken,
        location,
        router,
        isDrChronoAttached,
        fetchDrChronoToken,
        setIsShowImportModal,
      } = this.props;

      const { code } = location.query;

      if (code && !isDrChronoAttached) {
        await setDrChronoToken(code, router);
        await fetchDrChronoToken();
        setIsShowImportModal(true);
      }
    };

    render() {
      const { code } = this.props.location.query;

      return code !== undefined
        ? <Loader />
        : <Component {...this.props} />;
    }
  }
);

const mapStateToProps = state => ({
  isDrChronoAttached: selectors.getIsAttached(state),
  drChronoToken: selectors.getDrChronoToken(state),
  isFetching: selectors.getIsFetching(state),
  isFetched: selectors.getIsFetched(state),
});

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
