import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/SelectInput';

import getMapValueByKey from 'helpers/common/map/values/get-value-by-key';
import { SMOKING_STATUS_SELECT_OPTIONS } from 'helpers/chart/vitals/constants/smoking-status/select-options';
import fetchAllMapValues from 'helpers/common/map/values/fetch-all';

const SmokingStatusDropdown = (props) => {
  const {
    onChange,
    activeSmokingStatus,
  } = props;

  const smokingStatusOptions = fetchAllMapValues(SMOKING_STATUS_SELECT_OPTIONS);
  const activeOption = activeSmokingStatus
    ? getMapValueByKey(SMOKING_STATUS_SELECT_OPTIONS, activeSmokingStatus)
    : undefined;

  return (
    <Select
      options={smokingStatusOptions}
      defaultOption={activeOption}
      noPlaceholder
      position="top"
      onChange={value => onChange(value)}
    />
  );
};

SmokingStatusDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeSmokingStatus: PropTypes.string,
};

export default SmokingStatusDropdown;
