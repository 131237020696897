export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';

export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';

export const USER_EMAIL_CONFIRM = 'USER_EMAIL_CONFIRM';
export const USER_EMAIL_CONFIRM_SUCCESS = 'USER_EMAIL_CONFIRM_SUCCESS';
export const USER_EMAIL_CONFIRM_FAIL = 'USER_EMAIL_CONFIRM_FAIL';

export const USER_PASSWORD_RECOVER = 'USER_PASSWORD_RECOVER';
export const USER_PASSWORD_RECOVER_SUCCESS = 'USER_PASSWORD_RECOVER_SUCCESS';
export const USER_PASSWORD_RECOVER_FAIL = 'USER_PASSWORD_RECOVER_FAIL';

export const PASSWORD_RECOVERY_TOKEN_VALIDATE = 'PASSWORD_RECOVERY_TOKEN_VALIDATE';
export const PASSWORD_RECOVERY_TOKEN_VALIDATE_SUCCESS = 'PASSWORD_RECOVERY_TOKEN_VALIDATE_SUCCESS';
export const PASSWORD_RECOVERY_TOKEN_VALIDATE_FAIL = 'PASSWORD_RECOVERY_TOKEN_VALIDATE_FAIL';

export const SET_USER_CLINIC = 'SET_USER_CLINIC';
export const SET_USER_CLINIC_SUCCESS = 'SET_USER_CLINIC_SUCCESS';
export const SET_USER_CLINIC_FAIL = 'SET_USER_CLINIC_FAIL';

export const GETHEALLY_LOGIN = 'GETHEALLY_LOGIN';
export const GETHEALLY_LOGIN_SUCCESS = 'GETHEALLY_LOGIN_SUCCESS';
export const GETHEALLY_LOGIN_FAIL = 'GETHEALLY_LOGIN_FAIL';
