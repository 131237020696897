import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import RadialProgressBar from 'components/RadialProgressBar';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/ChartingSteps/ChartingSteps.module.scss';
import { build } from 'helpers/URLUtils';

const divStyles = ({ className }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['step--no-link'],
  globalStyles: className
});

const DivComponent = ({ children, className, style }) => <div className={divStyles({ className })} style={style}>{children}</div>;

const styles = ({ isStepActive }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['step', isStepActive && 'step--active']
});

const DEFAULT_ONE_STEP_HEIGHT_PX = 45;
const AVERAGE_PIXEL_CORRECT_COEFFICIENT = 1.6;

const definePixelCorrectCoefficient = () =>
  window.devicePixelRatio > 1 ? AVERAGE_PIXEL_CORRECT_COEFFICIENT : 1;

const ChartingSteps = (props) => {
  const stepsSectionRef = useRef();
  const [oneStepHeight, setOneStepHeight] = useState(DEFAULT_ONE_STEP_HEIGHT_PX);

  const {
    steps, activeStep, chartId, location, completion,
  } = props;

  const handleWindowResize = () => {
    const realStepsSectionHeight = stepsSectionRef?.current?.clientHeight;
    const realOneStepHeight = realStepsSectionHeight / steps.length;

    const pixelCorrectCoefficient = definePixelCorrectCoefficient();
    const stepsSectionToDisplayHeight = realStepsSectionHeight - realOneStepHeight*pixelCorrectCoefficient;
    const oneStepToDisplayHeight = stepsSectionToDisplayHeight / steps.length;
    setOneStepHeight(oneStepToDisplayHeight);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('orientationchange', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('orientationchange', handleWindowResize);
    };
  });

  const { permissions } = useContext(AppContext);

  const isStepActive = (stepId) => activeStep === stepId;

  const resolvedStyles = (stepId) => styles({ isStepActive: isStepActive(stepId) });

  return (
    <div ref={stepsSectionRef} className={cx.wrapper}>
      {steps.map((step) => {
        const Component = (!permissions.viewEditPatientClinicalInformation && step.type === 'social' && DivComponent) || Link;

        return (
          <Component
            key={step.id}
            className={resolvedStyles(step.id)}
            style={{ height: `${oneStepHeight}px` }}
            to={(step.type !== 'social' && build({
              pathname: location.pathname,
              query: { step: step.id },
            })) || `/app/doctor/charts/${chartId}/fmss/social`}
          >
            <RadialProgressBar
              height={oneStepHeight}
              {...completion[step.id]}
              parentStyles={cx}
              label={step.name}
            />
          </Component>
        );
      })}
    </div>
  );
};

ChartingSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    freezed: PropTypes.number,
  })).isRequired,
  activeStep: PropTypes.number,
  location: PropTypes.object,
  completion: PropTypes.objectOf(PropTypes.shape({
    isReady: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired,
    useCheck: PropTypes.bool.isRequired,
  })),
};

export default ChartingSteps;