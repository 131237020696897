import React from 'react';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './Actions.module.scss';

const actionsStyles = ({ deleteButton }) => ({
  wrapper: cssClassesResolver([
    cx.wrapper,  !deleteButton && cx['no-delete-button']
  ]),
  cancel: cssClassesResolver([
    cx.button, cx['button-cancel']
  ]),
  submit: cssClassesResolver([
    cx.button, cx['button-submit']
  ])
})

const Actions = ({
  deleteButton,
  cancelButton,
  submitButton,
  // onSubmit,
  onCancel,
  onDelete,
  disabledSubmit,
  disabledCancel,
  disabledDelete,
  submitButtonText,
  cancelButtonText,
}) => (
  <div className={actionsStyles({ deleteButton }).wrapper}>
    {deleteButton && <button type="button" className={cx['button-delete']} onClick={onDelete} disabled={disabledDelete} />}
    {' '}
    <div className={cx.group}>
      {
        cancelButton &&
          <button type="button" className={actionsStyles({}).cancel} onClick={onCancel} disabled={disabledCancel}>
            {cancelButtonText || 'Cancel'}
          </button>
      }
      {
        submitButton &&
          <button type="submit" className={actionsStyles({}).submit} disabled={disabledSubmit}>
            {submitButtonText || 'Save'}
          </button>
      }
    </div>
  </div>
);

Actions.propTypes = {
  deleteButton: PropTypes.bool,
  cancelButton: PropTypes.bool,
  submitButton: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  disabledSubmit: PropTypes.bool,
  disabledCancel: PropTypes.bool,
  disabledDelete: PropTypes.bool,
};

export default Actions;
