import * as constants from './constants';

import * as diagnosesApi from 'api/diagnoses';
import * as medicationsApi from 'api/medications';
import * as allergiesApi from 'api/allergies';
import * as familyApi from 'api/family';
import * as medicalApi from 'api/medical';
import * as surgicalApi from 'api/surgical';
import * as screeningApi from 'api/screening';

import { addResources, addResource } from '../resources/actions';
import { createDiagnosisFromElement } from 'modules/chartingSessions/actions';

import { togglePertinentMedicalHx } from 'modules/patients/actions';
import { addElement } from '../chartingSessions/actions';

import { getMedicalNameFromDiagnosis } from 'helpers';
import AdditionalMedicalCheckboxDataFactory from 'helpers/fmss/additional-checkbox/factory/props/MedicalFactory';

// map api functions to history item type
const functionsMap = {
  [constants.DIAGNOSES]: {
    fetch: diagnosesApi.fetchDiagnoses,
    fetchOne: diagnosesApi.fetchDiagnosis,
    create: diagnosesApi.createDiagnosis,
    delete: diagnosesApi.deleteDiagnosis,
    update: diagnosesApi.updateDiagnosis,
  },
  [constants.MEDICATIONS]: {
    fetch: medicationsApi.fetchMedications,
    fetchOne: medicationsApi.fetchMedication,
    create: medicationsApi.createMedication,
    delete: medicationsApi.deleteMedication,
    update: medicationsApi.updateMedication,
  },
  [constants.ALLERGIES]: {
    fetch: allergiesApi.fetchAllergies,
    fetchOne: allergiesApi.fetchAllergy,
    create: allergiesApi.createAllergy,
    delete: allergiesApi.deleteAllergy,
    update: allergiesApi.updateAllergy,
  },
  [constants.FAMILY]: {
    fetch: familyApi.fetchFamily,
    fetchOne: familyApi.fetchFamilyItem,
    create: familyApi.createFamilyItem,
    delete: familyApi.deleteFamilyItem,
    update: familyApi.updateFamilyItem,
  },
  [constants.MEDICAL]: {
    fetch: medicalApi.fetchMedical,
    fetchOne: medicalApi.fetchMedicalItem,
    create: medicalApi.createMedicalItem,
    delete: medicalApi.deleteMedicalItem,
    update: medicalApi.updateMedicalItem,
  },
  [constants.SURGICAL]: {
    fetch: surgicalApi.fetchSurgical,
    fetchOne: surgicalApi.fetchSurgicalItem,
    create: surgicalApi.createSurgicalItem,
    delete: surgicalApi.deleteSurgicalItem,
    update: surgicalApi.updateSurgicalItem,
  },
  [constants.SCREENING]: {
    fetch: screeningApi.fetchScreening,
    fetchOne: screeningApi.fetchScreeningItem,
    create: screeningApi.createScreeningItem,
    delete: screeningApi.deleteScreeningItem,
    update: screeningApi.updateScreeningItem,
  },
};

const getFilter = (item) => {
  if (item.active === undefined) {
    return undefined;
  }
  return (item.active && 'active') || 'inactive';
};

// map object properties we should add to resources to item type
const resourcePropertyMap = {
  [constants.DIAGNOSES]: 'diagnosis',
  [constants.MEDICATIONS]: 'medication',
};

const fetchItems = (hxType, patientId, filter) => ({
  types: [
    constants.HISTORY_ITEMS_FETCH,
    constants.HISTORY_ITEMS_FETCH_SUCCESS,
    constants.HISTORY_ITEMS_FETCH_FAIL,
  ],
  promise: dispatch => functionsMap[hxType].fetch(patientId, filter)
    .then((res) => {
      if (!resourcePropertyMap[hxType]) return res;
      dispatch(addResources(hxType.toLowerCase(), res.body.map(o => o[resourcePropertyMap[hxType]])));
      return res;
    }),
  patientId: parseInt(patientId, 10),
  filter,
  hxType,
});

export const fetchDiagnoses = (...args) => fetchItems(constants.DIAGNOSES, ...args);
export const fetchMedications = (...args) => fetchItems(constants.MEDICATIONS, ...args);
export const fetchAllergies = (...args) => fetchItems(constants.ALLERGIES, ...args);
export const fetchFamily = patientId => fetchItems(constants.FAMILY, patientId);
export const fetchMedical = patientId => fetchItems(constants.MEDICAL, patientId);
export const fetchSurgical = patientId => fetchItems(constants.SURGICAL, patientId);
export const fetchScreening = patientId => fetchItems(constants.SCREENING, patientId);

const fetchItem = (hxType, itemId) => ({
  types: [
    constants.HISTORY_ITEM_FETCH,
    constants.HISTORY_ITEM_FETCH_SUCCESS,
    constants.HISTORY_ITEM_FETCH_FAIL,
  ],
  promise: () => functionsMap[hxType].fetchOne(itemId),
  itemId: parseInt(itemId, 10),
  hxType,
});

export const fetchDiagnosis = diagnosisId => fetchItem(constants.DIAGNOSES, diagnosisId);
export const fetchMedication = medicationId => fetchItem(constants.MEDICATIONS, medicationId);
export const fetchAllergy = allergyId => fetchItem(constants.ALLERGIES, allergyId);
export const fetchFamilyItem = familyItemId => fetchItem(constants.FAMILY, familyItemId);
export const fetchMedicalItem = medicalItemId => fetchItem(constants.MEDICAL, medicalItemId);
export const fetchSurgicalItem = surgicalItemId => fetchItem(constants.SURGICAL, surgicalItemId);
export const fetchScreeningItem = screeningItemId => fetchItem(constants.SCREENING, screeningItemId);

const createItem = (hxType, item) => ({
  types: [
    constants.HISTORY_ITEM_CREATE,
    constants.HISTORY_ITEM_CREATE_SUCCESS,
    constants.HISTORY_ITEM_CREATE_FAIL,
  ],
  promise: async (dispatch, getState) => {
    const res = await functionsMap[hxType].create(item);
    if (resourcePropertyMap[hxType]) {
      dispatch(addResource(hxType.toLowerCase(), item[resourcePropertyMap[hxType]]));
    }

    const callback = createCallbacksMap[hxType];

    if (callback) {
      await callback({
        dispatch, item, getState,
      });
    }

    return res;
  },
  patientId: item.patientId,
  filter: getFilter(item),
  item,
  hxType,
});

export const createDiagnosis = diagnosis => createItem(constants.DIAGNOSES, diagnosis);
export const createMedication = medication => createItem(constants.MEDICATIONS, medication);
export const createAllergy = allergy => createItem(constants.ALLERGIES, allergy);
export const createFamilyItem = familyItem => createItem(constants.FAMILY, familyItem);
export const createMedicalItem = medicalItem => createItem(constants.MEDICAL, medicalItem);
export const createSurgicalItem = surgicalItem => createItem(constants.SURGICAL, surgicalItem);
export const createScreeningItem = screeningItem => createItem(constants.SCREENING, screeningItem);

const createCallbacksMap = {
  [constants.MEDICATIONS]: async ({
    dispatch, item: medication,
  }) => {
    if (!medication.diagnosis) return;

    for (const diagnosis of medication.diagnosis) {
      await dispatch(createDiagnosisFromElement(
        diagnosis,
        medication.chartId,
        medication.patientId
      ));
    }
  },
  [constants.MEDICAL]: async ({ dispatch, item, getState }) => {
    const medicalCheckboxDataFactory = new AdditionalMedicalCheckboxDataFactory({
      patientId: item.patientId,
      state: getState(),
    });

    const {
      isPresentItems,
      checkboxValue,
    } = medicalCheckboxDataFactory.getData();

    if (!isPresentItems && checkboxValue) {
      dispatch(togglePertinentMedicalHx(item.patientId, false));
    }
  },
  [constants.DIAGNOSES]: async ({
    dispatch, item: diagnosis,
  }) => {
    const { shouldAddDiagnosisToMedical } = diagnosis;

    if (shouldAddDiagnosisToMedical) {
      // название illness будет такое же, как название диагноза без icd кода
      const illnessName = getMedicalNameFromDiagnosis(diagnosis.diagnosis.name);

      // надо понять, есть ли уже такой illness в базе, придется искать по названию
      const { body: existingIllness } = await familyApi.searchIllnesses(illnessName);

      let illnessId = existingIllness.length && existingIllness[0].id;

      if (!existingIllness.length) {
        const res = await familyApi.createIllness(illnessName);
        illnessId = res.body;
      }

      const medicalItem = {
        description: diagnosis.description,
        diagnosis: [],
        illness: {
          id: illnessId,
          name: illnessName,
        },
        patientId: diagnosis.patientId,
        startDate: diagnosis.diagnosedDate,
      };

      await dispatch(createItem(constants.MEDICAL, medicalItem));
    }
  },
};

export const deleteItemFromList = (hxType, patientId, filter, itemId) => ({
  type: constants.HISTORY_ITEM_DELETE_FROM_LIST,
  payload: {
    patientId,
    filter,
    hxType,
    itemId,
  },
});

export const deleteDiagnosisFromList = (patientId, filter, diagnosisId) => {
  return deleteItemFromList(constants.DIAGNOSES, patientId, filter, diagnosisId);
};

export const deleteMedicationFromList = (patientId, filter, medicationId) => {
  return deleteItemFromList(constants.MEDICATIONS, patientId, filter, medicationId);
};

export const deleteAllergyFromList = (patientId, filter, allergyId) => {
  return deleteItemFromList(constants.ALLERGIES, patientId, filter, allergyId);
};

// if app should redirect after delete operation, pass redirectPath
export const deleteItem = (hxType, item, redirectPath, chartId) => ({
  types: [
    constants.HISTORY_ITEM_DELETE,
    constants.HISTORY_ITEM_DELETE_SUCCESS,
    constants.HISTORY_ITEM_DELETE_FAIL,
  ],
  promise: () => {
    const deleteFunction = functionsMap[hxType].delete;
    return deleteFunction(item.id, chartId);
  },
  itemId: item.id,
  patientId: item.patientId,
  filter: getFilter(item),
  item,
  hxType,
  chartId,
});

export const deleteDiagnosis = (diagnosis, redirectPath) => {
  return deleteItem(constants.DIAGNOSES, diagnosis, redirectPath);
};

export const deleteMedication = (medication, redirectPath) => {
  return deleteItem(constants.MEDICATIONS, medication, redirectPath);
};

export const deleteAllergy = (allergy, redirectPath) => {
  return deleteItem(constants.ALLERGIES, allergy, redirectPath);
};

export const deleteFamilyItem = (familyItem, redirectPath) => {
  return deleteItem(constants.FAMILY, familyItem, redirectPath);
};

export const deleteMedicalItem = (medicalItem, redirectPath) => {
  return deleteItem(constants.MEDICAL, medicalItem, redirectPath);
};

export const deleteSurgicalItem = (surgicalItem, redirectPath) => {
  return deleteItem(constants.SURGICAL, surgicalItem, redirectPath);
};

export const deleteScreeningItem = (screeningItem, redirectPath) => {
  return deleteItem(constants.SCREENING, screeningItem, redirectPath);
};

export const updateItem = (hxType, item) => ({
  types: [
    constants.HISTORY_ITEM_UPDATE,
    constants.HISTORY_ITEM_UPDATE_SUCCESS,
    constants.HISTORY_ITEM_UPDATE_FAIL,
  ],
  promise: async (dispatch) => {
    const updateFunction = functionsMap[hxType].update;

    const res = await updateFunction(item);

    if (item.active === undefined) return res;

    dispatch(deleteItemFromList(
      hxType,
      item.patientId,
      item.active ? 'inactive' : 'active',
      item.id
    ));

    return res;
  },
  patientId: item.patientId,
  filter: getFilter(item),
  item,
  hxType,
});

export const updateDiagnosis = diagnosis => updateItem(constants.DIAGNOSES, diagnosis);
export const updateMedication = medication => updateItem(constants.MEDICATIONS, medication);
export const updateAllergy = allergy => updateItem(constants.ALLERGIES, allergy);
export const updateFamilyItem = familyItem => updateItem(constants.FAMILY, familyItem);
export const updateMedicalItem = medicalItem => updateItem(constants.MEDICAL, medicalItem);
export const updateSurgicalItem = surgicalItem => updateItem(constants.SURGICAL, surgicalItem);
export const updateScreeningItem = screeningItem => updateItem(constants.SCREENING, screeningItem);

export const newMedication = (name, systemId, chartId) => ({
  types: [
    constants.HISTORY_ITEM_NEW,
    constants.HISTORY_ITEM_NEW_SUCCESS,
    constants.HISTORY_ITEM_NEW_FAIL,
  ],
  promise: dispatch => medicationsApi.newMedication(name)
    .then(
      (res) => {
        const medication = { id: res.body, name };
        dispatch(addResource('medications', medication));
        dispatch(addElement(chartId, systemId, {
          ...medication,
          type: null,
        }));
        return res;
      }
    ),
  name,
  systemId,
  chartId,
  hxType: constants.MEDICATIONS,
});
