import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';

import * as chartingSessionsSelectors from 'modules/chartingSessions/selectors';
import { getActiveSystemsByStepForChart } from 'modules/rootSelectors';
import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import * as fromIsSystemSaving from './isSystemSaving';
import { getEnclosedElementsForSystem } from 'modules/chartingSessions/selectors';

import { hpChartTabs } from 'helpers/chart';

export const getIsSystemSaving = state =>
  fromIsSystemSaving.getIsSystemSaving(state.isSystemSaving);

export const getStatus = (state, chartId) => {
  const defaultStatus = { isFetching: false, isFetched: false };

  if (chartId) {
    return state.statusByChart[chartId] || defaultStatus;
  }

  return state.status;
};

export const getSections = (state) => Object.values(state.data.sections);

/**
 *
 * @param state
 * @param stepIds : number[]
 */
export const getSectionsExclude = (state, stepIds) =>
  getSections(state)
    .filter(step => !stepIds.includes(step.id))

export const getSectionById = (state, sectionId) => {
  return state.data.sections[sectionId];
};

export const getSystemById = (state, systemId) => {
  return state.data.systems[systemId];
};

export const getSystemsByChartingStep = (state, sectionId, chartId) => {
  if (chartId === undefined) {
    throw new Error('chartId was not provided!');
  }

  return Object.values(state.data.systems)
    .filter(system => system.chartingId === sectionId);
};

export const getActiveElementsInAllSystemsByStep = (state, sectionId, chartId) => {
  const activeSystems = getActiveSystemsByStepForChart(state, sectionId, chartId);
  const activeSystemsIds = activeSystems.map(o => o.id);

  const chartingSession = chartingSessionsSelectors.getChartingSession(state.chartingSessions, chartId);
  let activeItems = [];
  activeSystemsIds.forEach((activeSystemId) => {
    if (chartingSession[activeSystemId]) {
      activeItems = [
        ...activeItems,
        ...chartingSession[activeSystemId].map(o => o.id),
      ];
    }
  });

  return activeItems;
};

export const getActiveElementsForCurrentSystems = (state, systemId, chartId) => {
  const chartingSession = chartingSessionsSelectors.getChartingSession(state.chartingSessions, chartId);

  return (chartingSession[systemId] && chartingSession[systemId].map(o => o.id)) || [];
};

export const getActiveElements = (step, state, systemId, chartId) => {
  switch (step) {
    case hpChartTabs.RV.step:
      return chartingAssetsSelectors.getActiveElementsInAllSystemsByStep(state, step, chartId);
    default:
      return getActiveElementsForCurrentSystems(state, systemId, chartId);
  }
};

// для правой панели в чартинге
export const getNoteSystemsByChartingStep = (state, sectionId, chartId) => {
  const sectionAppId = getSectionById(state, sectionId).appId;

  let result = getSystemsByChartingStep(state, sectionId, chartId);
  const isPlan = sectionAppId === hpChartTabs.Plan.appId;
  const isPe = sectionAppId === hpChartTabs.PE.appId;
  const isNeedLogicToFilterCustomSystems = isPlan === true || isPe === true;

  // если берем системы для плана, при равенстве order надо брать с самым большим id,
  // тк она была создана последней
  if (isNeedLogicToFilterCustomSystems) {
    const byOrder = groupBy(result, 'order');

    result = Object.keys(byOrder).reduce((acc, val) => {
      const systemsByStep = [...acc];
      if (byOrder[val].length === 1) {
        return systemsByStep.concat(byOrder[val]);
      }

      const sorted = [...byOrder[val]];

      sorted.sort((a, b) => b.id - a.id);
      const maxId = sorted[0].id;


      const system = byOrder[val].find(iteratedSystem => iteratedSystem.id === maxId);
      systemsByStep.push(system);


      return systemsByStep;
    }, []);
  }

  return result;
};

export const getDiagnosesSystem = state => values(state.data.systems).find(system => system.type === 1);

export const getResourceSystem = (state, resourceSystemType) =>
  Object.values(state.data.systems)
    .find(system => isEqual(system.type, resourceSystemType));

export const getResourceEnclosedElements = (state, resourceSystemType, chartId) => {
  const { chartingAssets, chartingSessions } = state;

  const { id: resourceSystemId } = getResourceSystem(chartingAssets, resourceSystemType);
  return getEnclosedElementsForSystem(chartingSessions, chartId, resourceSystemId);
};

const notDeleted = element => !element.deleted;

export const getElementsBySectionId = (state, sectionId) => {
  return Object.values(state.data.elements)
    .filter(o => o.chartingId === sectionId)
    .filter(notDeleted);
};
export const getElementsBySystem = (state, systemId) => {
  return Object.values(state.data.elements)
    .filter(o => o.systemId === systemId)
    .filter(notDeleted);
};

export const getElements = (state, { systemId, sectionId }) => {
  if (systemId) {
    return getElementsBySystem(state, systemId);
  }

  return getElementsBySectionId(state, sectionId);
};

export const getElementsArrayBySystem = (state, systemId) =>
  getElementsBySystem(state, systemId)
    .map(o => o.id);

export const getElementById = (state, elementId) => {
  return state.data.elements[elementId];
};
