import isArrayEmpty from 'helpers/common/array/is-empty';

export const DESC = 'desc';

export const sort = (array, keys = [] || '', orders = [] || '') => {
  if (isArrayEmpty(array)) {
    return array;
  }

  const params = typeof keys === 'string' ? [keys] : keys;
  const directions = typeof orders === 'string' ? [orders] : orders;

  return internalSort([...array], params, directions);
};

const internalSort = (array, params = [], directions = []) => {
  return array.sort((a, b) => {
    for (let i = 0; i < params.length; i++) {
      const key = params[i];
      const order = (directions[i] ?? 'asc') === 'desc' ? -1 : 1;

      if (a[key] < b[key]) {
        return -1 * order;
      }
      if (a[key] > b[key]) {
        return 1 * order;
      }
    }
    return 0;
  });
};