import { components } from 'react-select';

export const Action = props => {
  const { data, componentsMap, children } = props;

  if (!componentsMap[data.value]) {
    return (
      <components.Option {...props}>
        {children}
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      {componentsMap[data.value].render()}
    </components.Option>
  );
};