import React, { useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';

import { Formik } from 'formik';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ValidatedDatepicker from 'components/ValidatedDatepicker';
import ValidateMaskInput from 'components/ValidateMaskInput';
import AddressBlock from 'components/AddressBlock';
import UserImageLoader from 'components/UserImageLoader';
import DropdownHOC from 'components/DropdownHOC';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Loader from 'components/Loader';
import PatientAge from 'components/PatientAge';
import ValidatedField from 'components/Form/ValidatedField';
import UploadImageWrapper from 'components/UploadImageWrapper';
import GenderDropdown from 'components/GenderDropdown';
import StaticOptions from 'components/Select/StaticOptions';

import { maskStr, unMaskStr } from 'helpers/mask';
import { stripWhiteListProps } from 'helpers';
import DefaultPatientImage from 'helpers/images/defaults/patient';
import { buildSchema } from 'helpers/validationRules';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import { fetchEthnicities, fetchRaces } from 'api/user';

import cx from './style.module.scss';

const chooseSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 210,
  heightControl: 28,
  fontSize: 12,
});

const masks = {
  phoneNumber: '(111) 111-1111',
  ssn: '999-99-9999',
  dob: '11/11/1111',
};

const phoneNumberTypes = [
  {
    label: 'Mobile',
    value: 'mobile',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
];

const getPhoneTypeByValue = value =>
  phoneNumberTypes.filter(o => o.value === value)[0];

const isPatientId = (id) =>
  !Number.isNaN(parseInt(id, 10));

const isNewPatient = (props) => {
  let res = false;
  if (!isPatientId(props.patientId)) {
    res = true;
  }

  return res;
};

const genderStyles = () =>
  cssClassesResolver([
    cx['gender-select___autotest']
  ]);

const raceStyles = () =>
  cssClassesResolver([
    cx['race-select___autotest']
  ]);

const ethnicityStyles = () =>
  cssClassesResolver([
    cx['ethnicity-select___autotest']
  ]);

const whiteListProps = [
  'active',
  'address1',
  'address2',
  'dob',
  'email',
  'ethnicityId',
  'firstName',
  'gender',
  'id',
  'imageName',
  'lastName',
  'middleName',
  'notes',
  'phoneNumber',
  'phoneNumberType',
  'raceId',
  'secondPhoneNumber',
  'secondPhoneNumberType',
  'ssn',
  'zip',
  'state',
  'city',
  'group'
];

const DemographicsForm = (props) => {
  const formikRef = useRef();

  const {
    patient
  } = props;

  const formatToOption = (item) => {
    if (item?.id && item?.name) {
      return { value: item.id, label: item.name };
    }

    return null;
  };

  const getFormattedPatient = useCallback((receivedPatient) => {
    const p = { ...receivedPatient };

    Object.keys(p).forEach((key) => {
      if (p[key] === null && key !== 'imageName') {
        p[key] = '';
      }
    });

    p.ssn = maskStr(p.ssn, masks.ssn);
    p.race = formatToOption(p?.race);
    p.ethnicity = formatToOption(p?.ethnicity);

    return p;
  }, []);

  useEffect(() => {
    if (!isNewPatient(props)) {
      formikRef.current.resetForm({ values: getFormattedPatient(patient) });
    }
  }, [patient, getFormattedPatient]);

  const {
    permissions: { createEditPatients },
    timeZoneDateConverter
  } = useContext(AppContext);

  const formDisabled = !createEditPatients;

  const getDummyPatient = () => {
    return {
      active: true,
      firstName: '',
      lastName: '',
      middleName: '',
      gender: '',
      ssn: '',
      address1: '',
      address2: '',
      zip: '',
      state: '',
      city: '',
      phoneNumber: '',
      phoneNumberType: '',
      secondPhoneNumber: '',
      secondPhoneNumberType: '',
      email: '',
      notes: '',
      race: null,
      ethnicity: null,
      imageUrl: null,
      group: ''
    };
  };

  const getPatientFromProps = () =>
    isNewPatient(props) ? getDummyPatient() : getFormattedPatient(props.patient);

  const initialValues = { ...getPatientFromProps() };
  const validationSchema = buildSchema({
    firstName: 'required',
    lastName: 'required',
    gender: 'required',
    dob: 'required',
    email: 'email',
    zip: 'zipUnrequired',
    ssn: 'ssn',
  });

  const getPhoneNumberType = (phoneNumber, phoneNumberType) => {
    if (phoneNumber) {
      if (phoneNumberType) {
        return phoneNumberType;
      }

      return phoneNumberTypes[0].value;
    }

    return '';
  };

  const getPatientDataForRequest = (patientFromState) => {
    const patient = { ...patientFromState };

    patient.firstName = patient.firstName.trim();
    patient.middleName = patient.middleName.trim();
    patient.lastName = patient.lastName.trim();
    patient.ethnicityId = patient?.ethnicity?.value;
    patient.raceId = patient?.race?.value;
    patient.city = typeof patient?.city === 'string' ? patient?.city : patient?.city?.value;
    patient.state = typeof patient?.state === 'string' ? patient?.state : patient?.state?.value;

    patient.ssn = unMaskStr(patient.ssn);

    patient.phoneNumberType = getPhoneNumberType(patient.phoneNumber, patient.phoneNumberType);
    patient.secondPhoneNumberType = getPhoneNumberType(patient.secondPhoneNumber, patient.secondPhoneNumberType);

    if (typeof patient.dob === 'string') {

      patient.dob = timeZoneDateConverter.convertStringDateToMs(patient.dob);
    }

    if (patient.state === '') {
      delete (patient.state);
    }

    if (patient.city === '') {
      delete (patient.city);
    }

    return stripWhiteListProps(patient, whiteListProps);
  };

  const onSubmit = (values, { setFieldValue }) => {
    const patient = getPatientDataForRequest(values);

    if (isNewPatient(props)) {
      props.createPatient(patient, values).then((res) => {
        const patientId = res.body.patientDTO.id;
        setFieldValue('id', patientId);
        props.router.push(`/app/doctor/patients/${  patientId}`);
      }, err => Promise.reject(err));
    } else {
      props.updatePatient(patient, values).then(() => {
      }, err => Promise.reject(err));
    }
  };

  const renderAge = ({ values }) => {
    return (
      <div className={cx['text-wrapper']}>
        <span className={cx.label}>
          age
        </span>
        <span className={cx['text-age']}>
          <PatientAge
            dob={values.dob}
            color={PatientAge.colors.light_black}
            ageLabel={PatientAge.labels.yrs}
          />
        </span>
      </div>
    );
  };

  const renderBaseInputs = ({ values, handleChange, setFieldValue }) => {
    return (
      <div className={cx['element-group__base-inputs']}>
        <div className={cx['element-wrapper']}>
          <span
            className={cx['label-required']}
          >
            first&nbsp;name
          </span>
          <ValidatedField>
            <input
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                className={cx.input}
                disabled={formDisabled}
            />
          </ValidatedField>
        </div>

        <div className={cx['element-wrapper']}>
          <span className={cx.label}>middle&nbsp;name</span>
            <input
              name="middleName"
              value={values.middleName}
              onChange={handleChange}
              className={cx.input}
              disabled={formDisabled}
            />
        </div>

        <div className={cx['element-wrapper']}>
          <span className={cx['label-required']} >last&nbsp;name</span>
          <ValidatedField>
            <input
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              className={cx.input}
              disabled={formDisabled}
            />
          </ValidatedField>
        </div>

        <div className={cx['dob-wrapper']}>
          <span className={cx['label-required']}>
            date&nbsp;of&nbsp;birth
          </span>
          <ValidatedDatepicker
            onChange={date => setFieldValue('dob', date)}
            value={values.dob}
            isDisabledFutureDates
          />
        </div>

        <div
          className={cx['element-wrapper']}
        >
          <span
            className={cx['label-required']}
          >
            gender
          </span>

          <ValidatedField>
            <GenderDropdown
              name='gender'
              value={values.gender}
              disabled={formDisabled}
              className={genderStyles()}
              styles={chooseSelectStyles}
            />
          </ValidatedField>
        </div>

        <div
          className={cx['element-wrapper']}
        >
          <span
            className={cx.label}
          >
            Race
          </span>

          <DropdownHOC
            value={values.race}
            loadOptions={fetchRaces}
            optionMapper={value => ({
              value: value.id,
              label: value.name,
            })}
            className={raceStyles()}
            disabled={formDisabled}
            selectStyles={chooseSelectStyles}
            onChange={raceOption => setFieldValue('race', raceOption)}
          />

        </div>

        <div
          className={cx['element-wrapper']}
        >
          <span
            className={cx.label}
          >
            Ethnicity
          </span>

          <DropdownHOC
            value={values.ethnicity}
            loadOptions={fetchEthnicities}
            optionMapper={value => ({
              value: value.id,
              label: value.name,
            })}
            disabled={formDisabled}
            className={ethnicityStyles()}
            selectStyles={chooseSelectStyles}
            onChange={ethnicityOption => setFieldValue('ethnicity', ethnicityOption)}
          />
        </div>

        <div className={cx['element-wrapper']}>
          <span
            className={cx.label}
          >
            ssn
          </span>
          <ValidatedField>
            <ValidateMaskInput
              value={values.ssn}
              name="ssn"
              onChange={handleChange}
              mask={masks.ssn}
              placeholder="123-45-6789"
              className={cx.input}
              disabled={formDisabled}
            />
          </ValidatedField>
        </div>

         {renderAge({ values })}
      </div>
    );
  };

  const renderPhones = ({ values, handleChange, setFieldValue }) => {
    return (
      <div className={cx['element-group']}>
        <div className={cx['phone-wrapper']}>
          <span className={cx.label}>
            phone 1
          </span>
          <div className={cx['phone-input-container']}>
            <ValidatedField>
              <input
                type="tel"
                name="phoneNumber"
                value={values.phoneNumber}
                className={cx.input}
                onChange={handleChange}
                disabled={formDisabled}
              />
            </ValidatedField>
          </div>
          <StaticOptions
            onChange={option => setFieldValue('phoneNumberType', option?.value)}
            value={getPhoneTypeByValue(values.phoneNumberType)}
            isClearable
            className={cssClassesResolver([
              cx['phone-number-type'], cx['phone-one___autotest']
            ])}
            options={phoneNumberTypes}
            styles={chooseSelectStyles}
          />
        </div>
        <div className={cx['phone-wrapper']}>
        <span className={cx.label}>
           phone 2
        </span>
          <div className={cx['phone-input-container']}>
            <ValidatedField>
              <input
                type="tel"
                name="secondPhoneNumber"
                value={values.secondPhoneNumber}
                className={cx.input}
                onChange={handleChange}
              />
            </ValidatedField>
           </div>
          <StaticOptions
            onChange={option => setFieldValue('secondPhoneNumberType', option?.value)}
            value={getPhoneTypeByValue(values.secondPhoneNumberType)}
            className={cssClassesResolver([
              cx['phone-number-type'], cx['phone-two___autotest']
            ])}
            options={phoneNumberTypes}
            disabled={formDisabled}
            isClearable
            styles={chooseSelectStyles}
          />
        </div>
       </div>
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
      <form
        className={cx.form}
        onSubmit={handleSubmit}
      >
         <div className="text-right">
          {values?.id && <span className={cx.patientId}>#{values?.id}</span>}
         </div>

         <div className={cx['active-buttons-wrapper']}>
          <RadioButtonGroup
            items={[{
              name: 'Active',
              value: true,
            }, {
              name: 'Inactive',
              value: false,
            }]}
            checked={values.active}
            onChange={value => setFieldValue('active', value)}
          />
         </div>
        {renderBaseInputs({ values, handleChange, setFieldValue })}

         <div className={cx['element-group__image-loader']}>
            <UploadImageWrapper
              fetchImage={props.fetchPatientImage}
              imageName={values.imageName}
              entityId={values.id}
              imageUrl={values.imageUrl}
              onChange={imageUrl => setFieldValue('imageUrl', imageUrl)}
            >
              <UserImageLoader
                isDownloadingImage={props.isDownloadingImage}
                defaultImage={DefaultPatientImage.get()}
                imageUrl={values.imageUrl}
                entityIdentifier={values.id}
                disabled={formDisabled}
                backgroundSize="cover"
                onChange={({ imageName, imageUrl }) => {
                  setFieldValue('imageName', imageName);
                  setFieldValue('imageUrl', imageUrl);
                }}
              />
            </UploadImageWrapper>
         </div>

         <div className={cx['element-group__notes']}>
          <p>Notes</p>
          <textarea
            onChange={handleChange}
            value={values.notes}
            name="notes"
          />
         </div>

         <AddressBlock
          address1={values.address1}
          address2={values.address2}
          city={values.city}
          state={values.state}
          zip={values.zip}
          disabled={formDisabled}
          onChange={(field, value) => setFieldValue(field, value)}
         />

         {renderPhones({ values, handleChange, setFieldValue })}

        <div className={cx['email-wrapper']}>
          <span className={cx.label}>
            email
          </span>
          <ValidatedField>
            <input
              name="email"
              value={values.email}
              className={cx.input}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </ValidatedField>
        </div>

        <div className={cx['element-group']}>
          <div className={cx['element-wrapper']}>
            <span className={cx.label}>
              group
            </span>
              <input
                name="group"
                value={values.group}
                className={cx.input}
                onChange={handleChange}
                disabled={formDisabled}
              />
          </div>
        </div>

         <div className={cx['controls-group']}>
          <button
            type="reset"
            className={cx.button}
          >
            cancel
          </button>
          {!formDisabled && (
            <button
              type="submit"
              className={cx.save}
            >
              save
            </button>
          )}
         </div>
      </form>
        )}
    </Formik>
  );
};

DemographicsForm.propTypes = {
  patient: PropTypes.shape({
    raceId: PropTypes.number,
    ethnicityId: PropTypes.number,
    isUpdating: PropTypes.bool,
    imageUrl: PropTypes.string,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  createPatient: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
  fetchPatientImage: PropTypes.func.isRequired,
  isDownloadingImage: PropTypes.bool.isRequired,
};

const isFetching = (props) => {
  let res = false;
  if (props.patient && (props.patient.isFetching || props.patient.isFetching === undefined)) {
    res = true;
  }

  return res;
};

const isUpdating = (props) => {
  const {
    patient,
  } = props;

  const {
    isUpdating,
  } = patient;

  let res = false;
  if (patient && (isUpdating || isUpdating === undefined)) {
    res = true;
  }

  return res;
};

const MemoizedDemographicsForm = React.memo(DemographicsForm);

const Wrapper = (props) => {
  const isDisplayLoader = isFetching(props) || isUpdating(props);

  if (!isNewPatient(props) && isDisplayLoader) {
    return <div className={cx['loader-wrapper']}><Loader /></div>;
  }

  return <MemoizedDemographicsForm {...props} />;
};

Wrapper.propTypes = {
  patientId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  patient: PropTypes.object,
};

export default withRouter(Wrapper);
