import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchPatientId } from 'api/patients';
import { authDrChrono } from 'api/auth';
import { checkExistingChart } from 'api/charting';

import { createChart } from 'modules/charts/actions';
import { fetchUser } from 'modules/user/actions';

import TimeZoneDateConverter from 'helpers/date/time-zone-date-converter';
import TimeZone from 'helpers/date/TimeZone';
import { fetchChartIdFromCreatingResponse } from 'helpers/charting/charting';

export const useDrChronoLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginWithDrChrono = async ({ drChronoData }) => {
    await authDrChrono(drChronoData.userId);
    const { body } = await dispatch(fetchUser());

    if (drChronoData?.patientId) {
      const res = await fetchPatientId(drChronoData.patientId);

      const patientId = res.body.id;

      if (drChronoData.source === 'clinical_note') {
        const { settings } = body;

        const {
          timeZone, adjustClock,
        } = settings;
        const timeZoneDateConverter = new TimeZoneDateConverter(new TimeZone({ timeZone, adjustClock }));

        const chartData = {
          chartingType: 1,
          patientId,
          dosDate: timeZoneDateConverter.getCurrentTimeZoneDateMs(),
        };

        let chartId;

        const chartCheckRes = await checkExistingChart(drChronoData.appointmentId);


        if (chartCheckRes.status === 204) {
          try {
            const chartRes = await dispatch(createChart(chartData));
            chartId = fetchChartIdFromCreatingResponse(chartRes.body);
          } catch (e) {
            return;
          }
        } else {
          chartId = chartCheckRes.body;
        }

        navigate(`/app/doctor/charts/${chartId}?step=1`);
      }
    }
  };

  return { apiCallback: loginWithDrChrono };
};