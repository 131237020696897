import { connect } from 'react-redux';
import { getOffices, getSelectedOffice } from 'modules/drChronoAuth/selectors';
import { fetchActiveOffice, fetchOffices, selectOffice } from 'modules/drChronoAuth/actions';

const mapStateToProps = state => {
  return {
    officesInfo: getOffices(state),
    selectedOffice: getSelectedOffice(state)
  }
}

const mapActionsCreators = {
  fetchOffices,
  fetchActiveOffice,
  selectOffice
}

export default component => connect(mapStateToProps, mapActionsCreators)(component)
