import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import orderBy from 'lodash/orderBy';

import ElementsFetchLayerHOC from 'containers/ElementsFetchLayerHOC';

import * as chartingSessionsActions from 'modules/chartingSessions/actions';
import { fetchResources } from 'modules/resources/actions';
import { toggleChartReport } from 'modules/chartReportVisible';
import { enableFavoritesForceFetch } from 'modules/favoritesForceFetch';

import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import * as chartingSessionsSelectors from 'modules/chartingSessions/selectors';
import * as resourcesSelectors from 'modules/resources/selectors';
import * as patientsHxSelectors from 'modules/patientsHx/selectors';
import * as patientsHxConstants from 'modules/patientsHx/constants';
import * as chartsSelectors from 'modules/charts/selectors';
import * as selectors from 'modules/systemNotes/selectors';
import { deleteSystemNote } from 'modules/systemNotes/actions';
import { getFlattenedElementsForSave } from 'modules/rootSelectors';
import { getActivePatient } from 'modules/patients/selectors';

import {
  formatElementsForContainerParam,
  collectChartsForFindFavoritesRequest,
  formatElementsForIcdTen, formatCpt
} from 'helpers/charting/charting';
import * as favoritesHelpers from 'helpers/favorites/favoritesHelpers';
import FilterElementsBySystems from 'helpers/elements/filters/elements-by-system';
import { hpChartTabs } from 'helpers/chart';
import isEmpty from 'helpers/common/array/is-empty';
import { isNullOrUndefined } from 'helpers';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';

const getInitialState = () => ({
  exactMatch: {
    systems: {},
    elements: {},
  },
  broadMatch: {
    systems: {},
    elements: {},
  },
  subExactMatch: {},
  subBroadMatch: {},
  specialMatch: {
    medications: {
      broadMatch: {},
      exactMatch: {},
    },
    diagnoses: {
      broadMatch: {},
      exactMatch: {},
    },
    cptCodes: {
      broadMatch: {},
      exactMatch: {},
    },
    hcpcCodes: {
      broadMatch: {},
      exactMatch: {},
    },
  },
});

export const favoritesFetchLayerHOC = (Component) => {
  class FavoritesFetchLayer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ...getInitialState(),
      };
    }

    componentDidMount() {
      this.fetchFavorites();
    }

    componentDidUpdate = async (prevProps) => {
      const {
        isChartSaving,
        step,
        chartId,
        systemId,
        favoritesForceFetch,
        disableFavoritesForceFetch,
      } = this.props;

      if (isChartSaving) return;

      if (
        (isChartSaving === false && prevProps.isChartSaving === true)
        && favoritesForceFetch
      ) {
        await this.fetchFavorites();
        disableFavoritesForceFetch();
      }

      if (
        step !== prevProps.step ||
        chartId !== prevProps.chartId ||
        (
          step !== 1
          && systemId !== prevProps.systemId
        )
      ) {
        this.setState(getInitialState());
        this.fetchFavorites();
      }
    };

    getMatch = (match) => {
      // if there is system and its type is 2 we are in medications system
      if (this.props.system && this.props.system.type === hpChartTabs.Plan.systems.PRESCRIPTIONS.type) {
        return this.state.specialMatch.medications[match];
      }

      // if there is system and its type is 1 we are in diagnoses system
      if (this.props.system && this.props.system.type === hpChartTabs.A.systems.ICD_TEN.type) {
        return this.state.specialMatch.diagnoses[match];
      }

      if (this.props.system && this.props.system.type === BILLING_SYSTEMS.CPT.type) {
        return this.state.specialMatch.cptCodes[match];
      }

      if (this.props.system && this.props.system.type === BILLING_SYSTEMS.HCPC.type) {
        return this.state.specialMatch.hcpcCodes[match];
      }

      return (this.props.system && this.state[match].elements) || this.state[match].systems;
    };

    getNoteParam = (step, systemOrder) => {
      const {
        diagnosesSystemElements,
        medicationsSystemElements,
        containers,
        cptCodes,
      } = this.props;

      let transformedContainers = this.transformContainersParam(containers, step, systemOrder);
      transformedContainers = this.removeElementsWithEmptyChartsParam(transformedContainers);
      transformedContainers = formatElementsForContainerParam(transformedContainers, null, collectChartsForFindFavoritesRequest);

      return {
        containers: transformedContainers,
        medications: formatElementsForIcdTen(medicationsSystemElements),
        diagnosis: formatElementsForIcdTen(diagnosesSystemElements),
        cptCodes: formatCpt(cptCodes),
      };
    };

    processSpecialMatchData = (data) => {
      return Object.keys(data).reduce((acc, item) => {
        return {
          ...acc,
          [item]: data[item].count
        };
      }, {});
    };

    processServerResponse = (response) => {
      const {
        broadSystems,
        broadElements,
        exactSystems,
        exactElements,
        medicationsExactMatch,
        medicationsBroadMatch,
        diagnosisExactMatch,
        diagnosisBroadMatch,
        cptCodesExactMatch,
        cptCodesBroadMatch,
        hcpcCodesExactMatch,
        hcpcCodesBroadMatch,
      } = response;

      return {
        exactMatch: {
          elements: exactElements,
          systems: exactSystems,
        },
        broadMatch: {
          elements: broadElements,
          systems: broadSystems,
        },
        specialMatch: {
          medications: {
            exactMatch: this.processSpecialMatchData(medicationsExactMatch),
            broadMatch: this.processSpecialMatchData(medicationsBroadMatch),
          },
          diagnoses: {
            exactMatch: this.processSpecialMatchData(diagnosisExactMatch),
            broadMatch: this.processSpecialMatchData(diagnosisBroadMatch),
          },
          cptCodes: {
            exactMatch: this.processSpecialMatchData(cptCodesExactMatch),
            broadMatch: this.processSpecialMatchData(cptCodesBroadMatch),
          },
          hcpcCodes: {
            exactMatch: this.processSpecialMatchData(hcpcCodesExactMatch),
            broadMatch: this.processSpecialMatchData(hcpcCodesBroadMatch)
          },
        },
      };
    };

    fetchFavorites = () => {
      const {
        chartId,
        step,
        system,
      } = this.props;

      const checkNeedForFetchingFavorites = favoritesHelpers.checkNeedForFetchingFavorite(step, system);

      if (!chartId || !step || !checkNeedForFetchingFavorites) return;


      // RV elements favorites old implementation
      // now favorites are not used in RV
      if (step === 1) {
        return;
      }

      this.fetchRegularFavorites();
    };

    fetchRegularFavorites = () => {
      const {
        step,
        fetchFavorites,
      } = this.props;

      const dataForRequest = this.collectDataForFetchingFavoritesRequest();

      fetchFavorites(dataForRequest).then((res) => {
        if (res.body.step === step) {
          this.setState(this.processServerResponse(res.body));
        }
        return res;
      }, err => err);
    };

    collectDataForFetchingFavoritesRequest = () => {
      const {
        chartId,
        step,
        system,
      } = this.props;

      const systemOrder = system?.order;
      const note = this.getNoteParam(step, systemOrder);

      return {
        chartId,
        step,
        note,
        order: systemOrder,
        currentSystemId: system?.id,
        currentSystemName: system?.name,
      };
    };

    transformContainersParam = (containers, step, systemOrder) => {
      const filteredContainersByStep = this.filterContainersByHierarchy(containers, step, systemOrder);
      return this.sortContainersByTabAndSystemOrder(filteredContainersByStep);
    };

    filterContainerByLessThenStep = (container, step) => container.chartingId < step;

    filterContainerByEqualStepAndLessThenSystemOrder = (container, step, order) =>
      container.chartingId === step && container.order <= order;

    filterContainerByEqualStepAndNoSystem = (container, step, order) =>
      container.chartingId === step && isNullOrUndefined(order);

    filterContainersByHierarchy = (containers, step, order) =>
      containers.filter(container =>
        this.filterContainerByLessThenStep(container, step) ||
        this.filterContainerByEqualStepAndLessThenSystemOrder(container, step, order) ||
        this.filterContainerByEqualStepAndNoSystem(container, step, order)
      );

    removeElementsWithEmptyChartsParam = containers =>
      containers.reduce((formattedSystems, system) => {
        return isEmpty(system.charts)
          ? formattedSystems
          : [
            ...formattedSystems,
            system,
          ];
      }, []);


    sortContainersByTabAndSystemOrder = (containers) => {
      return orderBy(containers, [container => container.chartingId, container => container.order]);
    };

    render() {
      const { items } = this.props;

      return (
        <Component
          {...this.props}
          exactMatch={this.getMatch('exactMatch')}
          broadMatch={this.getMatch('broadMatch')}
          items={items}
        />
      );
    }
  }

  FavoritesFetchLayer.propTypes = {
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isChartSaving: PropTypes.bool,
    favoritesFetchedByStep: PropTypes.object,
    fetchFavorites: PropTypes.func.isRequired,
    disableFavoritesForceFetch: PropTypes.func.isRequired,
    system: PropTypes.shape({
      type: PropTypes.number,
      name: PropTypes.string
    })
  };

  FavoritesFetchLayer.defaultProps = {
    system: undefined
  };

  return FavoritesFetchLayer;
};

const favoritesFetchLayerMapStateToProps = (state, ownProps) => {
  const favoritesFetchedByStep = chartsSelectors.getChartFavoritesFetchedByStep(state.charts, ownProps.chartId);

  return {
    ...ownProps,
    ...getFlattenedElementsForSave(state, ownProps.chartId),
    favoritesFetchedByStep,
  };
};

export const favoritesFetchLayerContainer = Component => connect(
  favoritesFetchLayerMapStateToProps,
  {
    fetchFavorites: chartingSessionsActions.fetchFavorites,
  }
)(favoritesFetchLayerHOC(Component));

// TODO: refactor
const mapStateToProps = (state, ownProps) => {
  // get all systems by current step
  const allSystems = chartingAssetsSelectors.getSystemsByChartingStep(state.chartingAssets, ownProps.step, ownProps.chartId);

  const isSystemSaving = chartingAssetsSelectors.getIsSystemSaving(state.chartingAssets);

  // get data for current charting session
  const chartingSession = chartingSessionsSelectors.getChartingSession(state.chartingSessions, ownProps.chartId);

  // map systems full models over ids from charting session
  const chartingSessionSystemsIds = Object.keys(chartingSession);
  const chartingSessionSystems =
    chartingSessionSystemsIds.map(systemId => chartingAssetsSelectors.getSystemById(state.chartingAssets, systemId));

  let stepSystems = allSystems
    .filter(system => system.chartingId === ownProps.step);

  stepSystems = orderBy(stepSystems, 'order');

  // systems in central pane
  let systems =
    // filter all fixed systems
    allSystems.filter(system => system && system.fixed)
      // concat with systems in current charting session
      // and remove fixed systems from chartingSessionSystems
      // to prevent duplication
      .concat(chartingSessionSystems.filter(system => system && !system.fixed))
      // finally remove systems with wrong chartingId
      .filter(system => system.chartingId === ownProps.step);


  // if there is only one system possible in current charting step we shouldn't  display controls
  const shouldDisplayControls = allSystems.filter(system => system && system.chartingId === ownProps.step).length > 1;

  // we should not fetch elements before we get current active system
  // because we need system type to determine what we should request
  // (eg if system type is 1 we should get diagnoses)
  const system = chartingAssetsSelectors.getSystemById(state.chartingAssets, ownProps.system);

  // hash of elements enclosed in systems in central pane
  const enclosedElements = {};

  Object.keys(chartingSession).forEach((systemId) => {
    enclosedElements[systemId] = chartingSession[systemId].map((element) => {
      // we get element model from redux store
      // also we have ids and type (pos/neg) in element,
      // so we have to merge these objects
      return ({
        ...chartingAssetsSelectors.getElementById(state.chartingAssets, element.id),
        ...element,
      });
    });
  });

  const chart = chartsSelectors.getChartById(state.charts, ownProps.chartId);

  // we need patientId to get medications and diagnoses
  const patient = getActivePatient(state.patients);
  const { id: patientId } = patient;

  const allPatientDiagnoses = patientsHxSelectors.getDiagnoses(state.patientsHx, patientId, 'active');
  const allPatientDiagnosesIds = allPatientDiagnoses.map(o => o.diagnosis.id);

  const allPatientMedications = patientsHxSelectors.getMedications(state.patientsHx, patientId, 'active');
  const allPatientMedicationsIds = allPatientMedications.map(o => o.medication.id);

  // we should check if any of systems in current charting step has special type
  allSystems.forEach((system) => {
    switch (system.type) {
      // diagnoses
      case 1:
        enclosedElements[system.id] = chartingSession[system.id] && chartingSession[system.id].map(diagnosis => ({
          ...resourcesSelectors.getResourceById(state.resources, 'diagnoses', diagnosis.id),
          systemId: system.id,
        }));
        break;
      // medications
      case 2:
        enclosedElements[system.id] = chartingSession[system.id] && chartingSession[system.id].map(medication => ({
          ...resourcesSelectors.getResourceById(state.resources, 'medications', medication.id),
          systemId: system.id,
          prescribed: medication?.prescribed,
          ids: medication.ids && medication.ids.map((diagnosisId) => {
            if (typeof diagnosisId === 'object') {
              return diagnosisId;
            }
            return resourcesSelectors.getResourceById(state.resources, 'diagnoses', diagnosisId);
          }),
        }));
        break;
      default:
        enclosedElements[system.id] = enclosedElements[system.id] && enclosedElements[system.id].map(element => ({
          ...element,
          ids: element.ids && element.ids.map((diagnosisId) => {
            if (typeof diagnosisId === 'object') {
              return diagnosisId;
            }
            return resourcesSelectors.getResourceById(state.resources, 'diagnoses', diagnosisId);
          }),
        }));
        break;
    }
  });

  // sort systems
  // systems = orderBy(systems, 'id');
  systems = orderBy(systems, 'order');

  // items in right pane
  let items;
  // this is used to hide elements from right panel
  let activeItems = [];

  // if there is system in query then we should display elements
  if (ownProps.system && system) {
    switch (system.type) {
      // diagnoses
      case hpChartTabs.A.systems.ICD_TEN.type:
        items = resourcesSelectors
          .getResources(state.resources, 'diagnoses')
          .map(diagnosis => ({
            ...diagnosis,
            filter: allPatientDiagnosesIds.includes(diagnosis.id) ? 'active' : undefined,
          }));
        activeItems = (chartingSession[system.id] && chartingSession[system.id].map(o => o.id)) || [];
        break;
      // medications
      case hpChartTabs.Plan.systems.PRESCRIPTIONS.type:
        items = resourcesSelectors
          .getResources(state.resources, 'medications')
          .map(medication => ({
            ...medication,
            filter: allPatientMedicationsIds.includes(medication.id) ? 'active' : undefined,
          }));
        activeItems = (chartingSession[system.id] && chartingSession[system.id].map(o => o.id)) || [];
        break;
      default: {
        const section = chartingAssetsSelectors.getSectionById(state.chartingAssets, system.chartingId);

        const filterElementsBySystems = new FilterElementsBySystems(state.chartingAssets, section.appId, system);
        items = filterElementsBySystems.getElements();

        activeItems = chartingAssetsSelectors.getActiveElements(system.chartingId, state, system.id, ownProps.chartId) || [];
        break;
      }
    }


    // else we should display systems
  } else {
    items = allSystems;
    activeItems = systems.map(o => o.id);
  }

  let nextSystem;

  // NOTE this should probably be always in the end
  // if we are currently working with one system we should filter out rest systems
  if (ownProps.system) {
    // before we filter out inactive systems we should get next system
    nextSystem = systems[systems.findIndex(currentSystemCandidate => currentSystemCandidate.id === ownProps.system) + 1];
    systems = systems.filter(notCurrentSystem => notCurrentSystem.id === ownProps.system);
  }

  let areSystemsFetching = chartingAssetsSelectors.getStatus(state.chartingAssets).isFetching;
  // if we are in step 5 there are icd10, so we should check if patient diagnoses are fetched
  if (ownProps.step === 5) {
    areSystemsFetching = areSystemsFetching || patientsHxSelectors.getIsFetchingList(state.patientsHx, patientsHxConstants.DIAGNOSES, patientId, 'active');
  }

  const areElementsFetching = !!ownProps.system && areSystemsFetching;

  return {
    isSystemSaving,
    isChartSaving: chart.isSaving,
    areSystemsFetching,
    areElementsFetching,
    systems,
    items,
    chartId: ownProps.chartId,
    systemId: ownProps.system,
    enclosedElements,
    nextSystem,
    system,
    shouldDisplayControls,
    activeItems,
    patient,
    systemNotes: selectors.getSystemNotes(state.systemNotes),
    stepSystems,
  };
};

const mapActionCreators = {
  ...chartingSessionsActions,
  fetchResources,
  toggleChartReport,
  removeSystem: chartingSessionsActions.removeSystemWithCallback,
  removeElement: chartingSessionsActions.removeElementWithCallback,
  addElement: chartingSessionsActions.addElement,
  deleteSystemNote,
  enableFavoritesForceFetch,
};

export default component => connect(mapStateToProps, mapActionCreators)(ElementsFetchLayerHOC(component));
