import sortBy from 'lodash/sortBy';

export const formatAllergyForServer = (allergy) => {
  const result = { ...allergy };
  result.reactionIds = result.reactions && result.reactions.map(o => o.id);
  result.allergyId = result.allergy.id;
  result.allergyName = result.allergy.name;
  delete result.allergy;
  delete result.reactions;

  return result;
};

export const allergyWhiteList = [
  'active',
  'allergy',
  'chartId',
  'description',
  'drug',
  'id',
  'onset',
  'patientId',
  'reactions',
  'severity',
];

export const sortAllergiesForProgressNote = allergies =>
  sortBy(allergies, [item => item.allergy.name]);


export const sortAllergyReactions = reactions =>
  sortBy(reactions, [item => item.name]);
