import React from 'react';
import PropTypes from 'prop-types';

import RecentPatients from 'containers/Patients/RecentPatientsContainer';

import AddButton from 'components/AddButton';
import Loader from 'components/Loader';
import PatientPlate from './PatientPlate';
import SearchInput from '../SearchInput';

import { isNullOrUndefined } from 'helpers';

import cx from './PatientsList.module.scss';

const render = ({
  searchPatients,
  isFetching,
  isSearching,
  searchQuery,
  onSelectPatient,
  activePatient,
  error,
  fetchPatients,
  permissions,
  patientsFromSearchWithTrueOrder,
  clearPatientsSearch,
  defaultPatients
}) => {
  let patientsToRender = searchQuery?.length ? patientsFromSearchWithTrueOrder : defaultPatients;
  // let patientsToRender =  patientsFromSearchWithTrueOrder;

  patientsToRender = !isNullOrUndefined(patientsToRender) ? Object.values(patientsToRender).filter(o => o.active) : [];

  return (
    <div className={cx.content}>
      {!error && (
        <div className={cx['search-header']}>
          <SearchInput
            clearSearchingResultsCallback={clearPatientsSearch}
            onSearch={searchPatients}
            searchQuery={searchQuery}
            placeholder="Type three chars"
            isSafeSearching
          />
          {permissions.createEditPatients && (
            <div className={cx.buttonWrapper}>
              <AddButton
                link="/app/doctor/patients/new"
                diameter={56}
              >
                Add New Patient
              </AddButton>
            </div>
          )}
        </div>
      )}

      {!error && !searchQuery && (
        <div>
          <h1 className={cx.header}>Recent:</h1>
          {!isFetching && (
            <RecentPatients
              activePatient={activePatient}
              onSelectPatient={onSelectPatient}
            />
          )}

          {isFetching && (
            <Loader
              style={{
                position: 'relative',
                top: 20,
                marginTop: 10,
              }}
            />
          )}
        </div>
      )}

      {!error && (
        <div className={cx.listWrapper}>
          {searchQuery ? (
            <h1 className={cx.header}>
              Patients matching '{searchQuery}':
            </h1>
          ) : (
            <h1 className={cx.header}>All patients:</h1>
          )}
          {patientsToRender.map(patient => (
            <PatientPlate
              key={patient.id}
              active={patient.id === activePatient}
              patient={patient}
              onClick={onSelectPatient}
            />
          ))}
          {(isFetching || isSearching) && (
            <Loader
              style={{
                position: 'relative',
                top: 20,
                marginTop: 10,
              }}
            />
          )}
          {!patientsToRender.length && searchQuery && !isSearching && (
            <span
              style={{
                paddingLeft: 16,
              }}
            >
              No patients found
            </span>
          )}

        </div>
      )}

      {error && (
        <div className={cx.error}>
          <span className="text-center">
            {error.message}<br />
            <button
              type="button"
              className="generic-button"
              onClick={fetchPatients}
            >
              Reload
            </button>
          </span>
        </div>
      )}
    </div>
  );
};

const PatientsList = (props) => render(props);

PatientsList.propTypes = {
  patients: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  searchPatients: PropTypes.func.isRequired,
  onSelectPatient: PropTypes.func.isRequired,
  clearPatientsSearch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isSearching: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  patientsFromSearchWithTrueOrder: PropTypes.array,
};

PatientsList.defaultProps = {
  patientsFromSearchWithTrueOrder: [],
  searchQuery: '',
  isFetching: false,
};

export default PatientsList;
