import React, { Component } from 'react';
import PropTypes from 'prop-types';

import difference from 'lodash/difference';

import Datepicker from 'components/Datepicker';
import PatientSearchInput from 'components/PatientSearchInput';
import DropdownHOC from 'components/DropdownHOC';
import ReportValidationError from 'components/ReportValidationError';

import validators from 'helpers/reports/filters/validators';
import { REPORTS_ACTION } from 'helpers/reports/constants';
import { VALIDATORS_SETS, PLAIN_VALIDATION_FIELDS } from 'helpers/reports/filters/validatorsGroups';
import { addNewProperty, isNullOrUndefined } from 'helpers';
import { formatParamsWithWhiteList } from 'helpers/charting/charting';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import { fetchUsers } from 'api/users';
import { fetchActions, fetchActionTypes } from 'api/reports';

import cx from './ReportPageFilters.module.scss';

const chooseUserSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 200,
  heightControl: 32
})

const chooseSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 145,
  heightControl: 32
})

class ReportPageFilters extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      filters: {
        from: undefined,
        to: undefined,
        patient: undefined,
        user: undefined,
        action: undefined,
        actionType: undefined,
      },
      validators,
    };
  }

  getValidationFilterError = (filterName) => {
    return this.state.validators[filterName].error;
  };

  getActiveFilters = (filters) => {
    let newFilters = { ...filters };

    Object.keys(newFilters).forEach((filterName) => {
      if (!newFilters[filterName] || newFilters[filterName].length === 0) {
        delete newFilters[filterName];
      }
    });

    newFilters = formatParamsWithWhiteList(newFilters, PLAIN_VALIDATION_FIELDS);

    return Object.keys(newFilters);
  };

  getNewStateFilters = (fieldName, value) => {
    let newFilters = {
      ...this.state.filters,
    };

    newFilters = addNewProperty(fieldName, value, newFilters);

    this.setState({
      filters: newFilters,
    });

    return newFilters;
  };

  toggleAllValidators = () => {
    Object.keys(validators).forEach((validatorName) => {
      validators[validatorName].valid = false;
    });

    this.updateAllValidatorsInState(validators);
  };

  isFormInvalid = () => {
    if (this.isExportNotesToPdfFilter(this.state.filters.action?.value)) {
      let countInvalidFields = 0;

      Object.keys(validators).forEach((field) => {
        if (this.state.validators[field].valid === false) {
          countInvalidFields += 1;
        }
      });

      return countInvalidFields > 0;
    }

    return false;
  };

  updateAllValidatorsInState = (newValidators) => {
    this.setState({ validators: newValidators });
  };

  isDisplayError = (filterName) => {
    const validator = this.state.validators[filterName];

    return this.isExportNotesToPdfFilter(this.state.filters.action?.value) && validator && !validator.valid;
  };

  isCurrentFiltersEqualValidationGroup = (validationGroup = [], newState) => {
    const setOfCurrentFilters = this.getActiveFilters(newState);

    const isEqualLength = setOfCurrentFilters.length === validationGroup.length;
    const isValidationGroupItemsIncludeCurrentFilters = setOfCurrentFilters.every(value => validationGroup.includes(value));

    return isEqualLength && isValidationGroupItemsIncludeCurrentFilters;
  };

  isFiltersEmpty = (filters = {}) => {
    let countEmpty = 0;

    PLAIN_VALIDATION_FIELDS.forEach((field) => {
      const filterValue = filters[field];
      if (!isNullOrUndefined(filterValue)) {
        countEmpty += 1;
      }
    });

    return countEmpty === 0;
  };

  updateValidatorsOnFilterChange = (fieldName, value, currentFilters) => {
    Object.keys(VALIDATORS_SETS).every((validatorGroupName) => {
      const validatorGroupItems = VALIDATORS_SETS[validatorGroupName];
      const isCurrentFiltersEqualValidationGroup = this.isCurrentFiltersEqualValidationGroup(validatorGroupItems, currentFilters);
      const isChangedFilterInValidationGroup = validatorGroupItems.includes(fieldName);

      if (isCurrentFiltersEqualValidationGroup) {
        this.resetValidators();

        return false;
      } if (isChangedFilterInValidationGroup) {
        const othersValidationFields = difference(validatorGroupItems, [fieldName]);

        this.updateValidatorsByFilter(fieldName, value);

        othersValidationFields.forEach((field) => {
          this.updateValidatorsByFilter(field, currentFilters[field]);
        });

        return true;
      } if (this.isExportNotesToPdfFilter(currentFilters.action?.value) && this.isFiltersEmpty(currentFilters)) {
        this.toggleAllValidators();

        return false;
      }

      return true;
    });
  };

  isExportNotesToPdfFilter = (currentAction = '') => {
    return currentAction === REPORTS_ACTION.EXPORT_NOTES_TO_PDF;
  };

  updateValidatorsByFilter = (field, value) => {
    if (!value || (value.length === 0 && validators[field].valid === true)) {
      validators[field].valid = false;
      this.updateAllValidatorsInState(validators);
    } else if (value || (value.length > 0 && validators[field].valid === false)) {
      validators[field].valid = true;

      this.updateAllValidatorsInState(validators);
    }
  };

  resetValidators = () => {
    Object.keys(validators).forEach((fieldName) => {
      validators[fieldName].valid = true;
    });

    this.updateAllValidatorsInState(validators);
  };

  updateFilter = (filter, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filter]: value,
      },
    });

    this.updateValidators(filter, value);
  };

  updateDateFilter = (filter, value) => {
    const transformedValue = value?.length === 0 ? undefined : value

    this.updateFilter(filter, transformedValue)
  }


  applyFilters = () => {
    const { filters } = this.state;

    const {
      from, to, patient, user, action, actionType,
    } = filters;

    const { onApplyFilters } = this.props

    onApplyFilters({
      from,
      to,
      patientId: patient?.id,
      userId: user?.value,
      action: action?.value,
      actionType: actionType?.value,
    });
  };

  updateValidators(fieldName, value) {
    const newFilters = this.getNewStateFilters(fieldName, value);

    this.updateValidatorsOnFilterChange(fieldName, value, newFilters);

    this.updateAllValidatorsInState(validators);
  }

  render() {
    const { filters } = this.state;
    const {
      from, to, patient, user, action, actionType,
    } = filters;

    const isFormInvalid = this.isFormInvalid();

    return (
      <div className={cx.wrapper}>
        <div className={cx['form-group']}>
          <p className={cx.label}>Start date</p>
          <Datepicker
            value={from}
            onChange={value => this.updateDateFilter('from', value)}
          />
          <ReportValidationError
            isDisplay={this.isDisplayError('from')}
            error={this.getValidationFilterError('from')}
          />
        </div>

        <div className={cx['form-group']}>
          <p className={cx.label}>End date</p>
          <Datepicker
            value={to}
            onChange={value => this.updateDateFilter('to', value)}
          />
          <ReportValidationError
            isDisplay={this.isDisplayError('to')}
            error={this.getValidationFilterError('to')}
          />
        </div>

        <div className={cx['form-group']}>
          <p className={cx.label}>Patient</p>
          <PatientSearchInput
            value={patient}
            onChange={patientOption => this.updateFilter('patient', patientOption)}
            styles={chooseUserSelectStyles}
          />
          <ReportValidationError
            isDisplay={this.isDisplayError('patient')}
            error={this.getValidationFilterError('patient')}
          />
        </div>

        <div className={cx['form-group']}>
          <p className={cx.label}>User</p>
          <DropdownHOC
            isSearchable={false}
            value={user}
            onChange={userOption => this.updateFilter('user', userOption)}
            loadOptions={fetchUsers}
            optionMapper={value => ({
              label: `${value.firstName  } ${  value.lastName}`,
              value: value.id,
            })}
            selectStyles={chooseUserSelectStyles}
          />
          <ReportValidationError
            isDisplay={this.isDisplayError('user')}
            error={this.getValidationFilterError('user')}
          />
        </div>

        <div className={cx['form-group']}>
          <p className={cx.label}>Action</p>
          <DropdownHOC
            value={action}
            onChange={value => this.updateFilter('action', value)}
            loadOptions={fetchActions}
            optionMapper={value => ({
              label: value,
              value,
            })}
            valueMapper={option => (option && option.value) || undefined}
            selectStyles={chooseSelectStyles}
          />
        </div>

        <div className={resolveStylesV2({ objectStyles: cx, moduleStyles: ['form-group', 'form-group--last'] })}>
          <p className={cx.label}>Action type</p>
          <DropdownHOC
            value={actionType}
            onChange={value => this.updateFilter('actionType', value)}
            loadOptions={fetchActionTypes}
            optionMapper={value => ({
              label: value,
              value,
            })}
            selectStyles={chooseSelectStyles}
          />
        </div>

        <button
          type="button"
          className={cx['apply-button']}
          onClick={this.applyFilters}
          disabled={isFormInvalid}
        >
          Apply
        </button>
      </div>
    );
  }
}

ReportPageFilters.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default ReportPageFilters;
