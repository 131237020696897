import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import * as constants from 'modules/patientsHx/constants';

import * as patientsSelectors from 'modules/patients/selectors';
import { getIsAttached } from 'modules/drChronoAuth/selectors';

import { byNotPresentNoKnownAllergy } from 'helpers/fmss/allergies/noKnowAllergiesFilter';

export const withAllergiesFetchLayer = (Component) => props => {
  const {
    patientId,
    filter,
    isFetching,
    fetchAllergies
  } = props;

  const fetchAllergiesWrapper = (patientIdCandidate, filterCandidate) => {
    if (!patientIdCandidate) return;
    fetchAllergies(patientId, filterCandidate);
  };

  useEffect(() => {
    if (isFetching === undefined) {
      fetchAllergiesWrapper(patientId, filter);
    }
  }, [patientId, filter]);

  return <Component {...props} />;
};

withAllergiesFetchLayer.propTypes = {
  isFetching: PropTypes.bool,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  filter: PropTypes.string.isRequired,
  fetchAllergies: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const filteredAllergies = selectors
    .getAllergies(state.patientsHx, ownProps.patientId, ownProps.filter)
    .filter(byNotPresentNoKnownAllergy);

  return {
    ...ownProps,
    allergies: filteredAllergies,
    isFetching: selectors.getIsFetchingList(state.patientsHx, constants.ALLERGIES, ownProps.patientId, ownProps.filter),
    hasActiveAllergies: !!selectors.getAllergies(state.patientsHx, ownProps.patientId, 'active').length,
    isInitialized: selectors.getIsInitializedList(state.patientsHx, constants.ALLERGIES, ownProps.patientId, 'active'),
    error: selectors.getListError(state.patientsHx, constants.ALLERGIES, ownProps.patientId, ownProps.filter),
    hasNoDrugAllergies: patientsSelectors.getPatientById(state.patients, ownProps.patientId).hasNoDrugAllergies,
    isDrChronoAttached: getIsAttached(state),
  };
};

const mapActionCreators = {
  fetchAllergies: actions.fetchAllergies,
  createAllergy: actions.createAllergy,
  updateAllergy: actions.updateAllergy,
  deleteAllergy: actions.deleteAllergy,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
