import React from 'react';
import PropTypes from 'prop-types';

import cx from './Sidebar.module.scss';

const Sidebar = ({
  isActiveOnMobile,
  children,
  offset,
}) => (
  <div
    className={cx[
      isActiveOnMobile ? 'sidebar--visible' : 'sidebar'
    ]}
    style={{ marginTop: offset && offset.top }}
  >
    {children}
  </div>
);

Sidebar.propTypes = {
  isActiveOnMobile: PropTypes.bool,
  children: PropTypes.node,
};

export default Sidebar;
