export default class TransformEstimateMessage {
  static getMessage(estimateTime = '') {
    let newEstimateMessage;
    const hours = estimateTime.split(' ')[0];
    const timeUnit = estimateTime.split(' ')[1];

    if (timeUnit === 'years') {
      newEstimateMessage = hours + ' yrs';
    } else if (timeUnit === 'hours') {
      newEstimateMessage = hours + ' hrs';
    } else if (timeUnit === 'months') {
      newEstimateMessage = hours + ' mns';
    } else if (timeUnit === 'minutes') {
      newEstimateMessage = hours + ' mins';
    } else {
      newEstimateMessage = estimateTime;
    }

    return newEstimateMessage;
  }
}
