import { combineReducers } from 'redux';

import byId from './byId';
import isFetching from './isFetching';
import isFetched from './isFetched';

export default combineReducers({
  byId,
  isFetching,
  isFetched,
});
