const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchClinicInfo = () =>
  request.get('/api/rest/clinic/')
    .end();

export const updateClinicInfo = data =>
  request.post('/api/rest/clinic/update')
    .send(data)
    .end();

export const register = data =>
  request
    .post('/api/administrator/register')
    .send(data)
    .end()
