import { handleActions } from 'redux-actions';
import * as constants from '../constants';

export default handleActions({
  [constants.DRCHRONO_TOKEN_FETCH]: () => true,

  [constants.DRCHRONO_TOKEN_FETCH_SUCCESS]: () => false,

  [constants.DRCHRONO_TOKEN_FETCH_FAIL]: () => false,

  [constants.DRCHRONO_TOKEN_REMOVE]: () => true,

  [constants.DRCHRONO_TOKEN_REMOVE_SUCCESS]: () => false,

  [constants.DRCHRONO_TOKEN_REMOVE_FAIL]: () => false,
}, false);
