import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = {};

export default handleActions({
  [constants.CHART_SYSTEM_NOTES_FETCH]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      isFetching: true,
      isFetched: false,
    },
  }),

  [constants.CHART_SYSTEM_NOTES_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.patientId]: {
      isFetching: false,
      isFetched: true,
    },
  }),
}, initialState);
