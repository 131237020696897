import React, { Component } from 'react';

import Textarea from 'react-textarea-autosize';

import debounce from 'lodash/debounce';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './ChartReportComment.module.scss';

const MAX_LENGTH = 255;

const styles = ({ isDisabled }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['textarea', isDisabled && 'textarea--disabled']
})

class ChartReportComment extends Component {
  debouncedUpdateChartExtraData = debounce((newChart, prevChart) => {
    this.props.updateChart(newChart);
    this.props.updateChartExtraData(newChart, prevChart);
  }, 1000)

  constructor(props) {
    super(props);

    this.state = {
      text: props.chart.comment,
    };
  }

  updateComment = e => {
    if (e.target.value.length > MAX_LENGTH) return;

    this.setState({ text: e.target.value });

    const { chart } = this.props;

    const data = {
      id: chart.id,
      showFMSS: chart.showFMSS,
      showCPT: chart.showCPT,
      showMedAllergy: chart.showMedAllergy,
      comment: chart.comment,
    };

    data.comment = e.target.value;

    // this.props.updateChart(data);
    this.debouncedUpdateChartExtraData(data, this.props.chart);
  }

  render() {
    const { disabled } = this.props;
    const { text } = this.state;

    if (disabled && !text) return null;

    const { updateComment } = this;

    const resolvedStyles = styles({ isDisabled: disabled })

    return (
      <div className={cx.wrapper}>
        <Textarea
          className={resolvedStyles}
          placeholder={(!disabled && 'Enter comment') || null}
          value={text || ''}
          onChange={!disabled && updateComment}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default ChartReportComment;
