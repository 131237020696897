import React from 'react';
import PropTypes from 'prop-types';

import BillingInfoModalFetchLayer from 'containers/BillingInfoModalFetchLayer';

import BillingInfoModal from './BillingInfoModal';

const BillingInfoModalContainer = (props) => {
  const {
    closeModal,
    location,
    setDidPayment,
    savePaymentInfo,
    doPayment,
    seats
  } = props;

  const savePaymentInfoWrapper = (transaction) => {
    return savePaymentInfo(transaction)
      .then((res) => {
        closeModal();
        return res;
      });
  };

  return (
    <BillingInfoModal
      seats={seats}
      doPayment={doPayment}
      onModalClose={closeModal}
      savePaymentInformation={savePaymentInfoWrapper}
      action={location || 'buy'}
      setDidPayment={setDidPayment}
    />
  );
}

BillingInfoModalContainer.propTypes = {
  savePaymentInfo: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  setDidPayment: PropTypes.func.isRequired,
};

export default BillingInfoModalFetchLayer(BillingInfoModalContainer);
