import { logout } from 'modules/user/auth/actions';
import { toast } from 'react-toastify';

const requestNotificationsBlacklist = [
  'USER_FETCH',
];

const logoutErrorCodes = [204, 401];

export default function clientMiddleware() {
  return ({ dispatch, getState }) => {
    return next => (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ payload: { ...rest }, type: REQUEST });

      const actionPromise = promise(dispatch, getState);
      actionPromise.then(
        (payload) => {
          try {
            return next({ payload: { status: payload && payload.status, result: payload && payload.body ? payload.body : payload, ...rest }, type: SUCCESS });
          } catch (e) {
            console.error(e, REQUEST);
          }
        },
        (error) => {
          if (
            logoutErrorCodes.includes(error.status)
            && !requestNotificationsBlacklist.includes(REQUEST)
          ) {
            toast('Session has timed out.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              type: toast.TYPE.INFO,
            });
          }
          return next({ payload: { ...rest }, error, type: FAILURE });
        }
      ).catch((error) => {
        console.error('MIDDLEWARE ERROR:', error);
        next({ payload: { ...rest }, error, type: FAILURE });
      });

      return actionPromise;
    };
  };
}
