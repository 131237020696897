import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';

import removeItemByValue from 'helpers/common/array/remove-element/by-value';
import deleteObjectProperty from 'helpers/common/object/delete-property';

export const ADD_CONSENT = 'ADD_CONSENT';
export const UPDATE_CONSENT = 'UPDATE_CONSENT';
export const LIST_CONSENTS = 'LIST_CONSENTS';

export const DELETE_CONSENT = 'DELETE_CONSENT';

export const addConsent = (data) => ({
  type: ADD_CONSENT,
  payload: data
});

export const updateConsent = (newConsent) => ({
  type: UPDATE_CONSENT,
  payload: newConsent
});

export const addConsents = data => ({
  type: LIST_CONSENTS,
  payload: data
});

export const deleteConsent = id => ({
  type: DELETE_CONSENT,
  payload: { id }
});

const initialState = {
  byId: {},
  ids: []
};

export const getConsentIds = createSelector(
  [state => state.consent],
  consent => consent.ids
);

export const getConsentById = createSelector(
  [state => state.consent.byId, (state, id) => id],
  (byId, id) => byId[id]
);

export const getListConsents = createSelector(
  [state => state.consent.byId],
  consents => Object.values(consents).map(({ id, name }) => ({ id, name }))
);

export default handleActions({
  [ADD_CONSENT]: (state, action) => {
    return {
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload,
      },
      ids: [
        ...state.ids,
        action.payload.id
      ]
    };
  },
  [UPDATE_CONSENT]: (state, action) => {
    return {
      byId: {
        ...state.byId,
        ...keyBy([action.payload], 'id')
      },
      ids: [...state.ids]
    };
  },
  [LIST_CONSENTS]: (state, action) => {
    return {
      byId: keyBy(action.payload, 'id'),
      ids: action.payload.map(item => item.id)
    };
  },
  [DELETE_CONSENT]: (state, action) => {
    const id = action.payload.id;
    return {
      byId: deleteObjectProperty(state.byId, id),
      ids: removeItemByValue(state.ids, action.payload.id)
    };
  }
},initialState );