import React, { useContext } from 'react';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Modal from 'components/Modal';

import { isNullOrUndefined } from 'helpers';

const InfoModal = () => {
  const {
    infoModalData,
    setInfoModalData,
  } = useContext(AppContext);

  const {
    title,
    message,
    messageType
  } = infoModalData;

  const isShowModal = message && !isNullOrUndefined(message) && message.length > 0;

  return (
    <Modal
      isOpen={isShowModal}
      data={{
        title,
        messageType
      }}
      onModalClose={() => setInfoModalData({ message: '' })}
    >
      {message}
    </Modal>
  );
};

export default InfoModal;
