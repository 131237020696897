import classnames from 'classnames';

import isEmpty from 'helpers/common/array/is-empty';

const fetchRules = ({ objectStyles, className }) => objectStyles[className];

export default ({ objectStyles, moduleStyles, globalStyles }) => {
  const accumulatedStyles = [];

  if (typeof moduleStyles === 'string') {
    accumulatedStyles.push(fetchRules({ objectStyles, className: moduleStyles }));
  }

  if (!isEmpty(moduleStyles)) {
    moduleStyles.forEach(styles =>
      accumulatedStyles.push(fetchRules({ objectStyles, className:styles }))
    );
  }

  if (typeof globalStyles === 'string' || !isEmpty(globalStyles)) {
    accumulatedStyles.push(globalStyles);
  }

  return classnames(...accumulatedStyles);
};
