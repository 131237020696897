import mapObjectsByParam from 'helpers/common/array/get-objects-ids';

const formatChartIds = elements =>
  elements.reduce((chartFormattedIds, element) => {
    const {
      ids,
    } = element;
    return [
      ...chartFormattedIds,
      {
        ...element,
        ids: mapObjectsByParam(ids, 'id'),
      },
    ];
  }, []);

export default formatChartIds;
