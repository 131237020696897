import React from 'react';
import PropTypes from 'prop-types';

import { isNullOrUndefined } from 'helpers';

export const fetchLayerHOC = Component => (class extends React.Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    activeSystem: PropTypes.number,
    patientId: PropTypes.number.isRequired,
    fetchElementsBySystem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeSystem: null,
  };

  componentDidMount() {
    const {
      isFetching,
      activeSystem,
      fetchElementsBySystem,
    } = this.props;

    if (!isNullOrUndefined(isFetching)) return;
    fetchElementsBySystem(activeSystem);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isFetching,
      activeSystem,
      fetchElementsBySystem,
      patientId,
    } = nextProps;

    if (isNullOrUndefined(isFetching) && this.props.activeSystem !== activeSystem) {
      fetchElementsBySystem(activeSystem, patientId);
    }
  }

  render() {
    return <Component {...this.props} />;
  }
});
