import React, { useState } from 'react';
import { bool, func } from 'prop-types'

import Modal from 'components/Modal';

import cx from './ChartActionsButtons.module.scss'

const getSignModalData = signed => ({
  type: 'confirm',
  title: `${(signed && 'Unlock') || 'Sign'} chart`,
  content: `<p>Are you sure you want to ${(signed && 'unlock') || 'sign'} this chart?`,
});

const Sign = (props) => {
  const [disabled, setDisabled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const {
    signed,
    onChangeSignedStatus
  } = props

  const confirmModalWrapper = async () => {
    setIsOpen(false);

    setDisabled(true)
    await onChangeSignedStatus();
    setDisabled(false)
  }

  return (
    <>
      <button
        type="button"
        className={cx['round-button']}
        disabled={disabled}
        onClick={() => setIsOpen(true)}
      >
        {signed ? 'Unlock' : 'Sign'}
      </button>

      <Modal
        confirm
        data={getSignModalData(signed)}
        isOpen={isOpen}
        onModalClose={() => setIsOpen(false)}
        onModalConfirm={confirmModalWrapper}
      />
    </>
  )
}

Sign.propTypes = {
  signed: bool.isRequired,
  onChangeSignedStatus: func.isRequired
}

export default Sign
