import AdditionalMedicalCheckboxDataFactory from 'helpers/fmss/additional-checkbox/factory/props/MedicalFactory';
import AdditionalFamilyCheckboxDataFactory from 'helpers/fmss/additional-checkbox/factory/props/FamilyFactory';
import AdditionalSurgicalCheckboxDataFactory from 'helpers/fmss/additional-checkbox/factory/props/SurgicalFactory';
import AdditionalAllergyCheckboxDataFactory from 'helpers/fmss/additional-checkbox/factory/props/AllergyFactory';

import { FMSS_TYPES } from 'helpers/fmss/constants';

export default class AbstractPropsFactory {
  static getHxFactory = (hxType) => {
    switch (hxType) {
      case FMSS_TYPES.MEDICAL:
        return AdditionalMedicalCheckboxDataFactory;
      case FMSS_TYPES.FAMILY:
        return AdditionalFamilyCheckboxDataFactory;
      case FMSS_TYPES.SURGICAL:
        return AdditionalSurgicalCheckboxDataFactory;
      case FMSS_TYPES.ALLERGY:
        return AdditionalAllergyCheckboxDataFactory;
      default:
        throw Error('Additional checkbox is not present for that hx type');
    }
  }
}
