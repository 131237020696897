import { formatMedicationForServer } from 'helpers/medications';

const request = require('superagent-promise')(require('superagent'), Promise);

// get medications by patient (active or inactive)
export const fetchMedications = (patientId, filter) =>
  request.get(`/api/rest/medication/patient/${filter}/${patientId}`)
    .end();

export const fetchMedication = medicationId =>
  request.get(`/api/rest/medication/${medicationId}`)
    .end();

// medications search (for select)
export const searchMedication = (searchRequest, favorites) => {
  if (!favorites) {
    return request.post('/api/rest/medications/search')
      .send({ searchRequest })
      .end();
  }

  return request.post('/api/rest/medications/note/search')
    .send({ searchRequest, ids: favorites })
    .end();
};

export const deleteMedMedication = (id) =>
  request
    .del(`/api/rest/medications/${id}`)
    .end();

export const removeRightSideElementPlanPrescription = id =>
  request
    .del('/api/rest/medications/delete')
    .send({ id })
    .end();

export const createMedication = (medication) => {
  return request.post('/api/rest/medication/create')
    .send(formatMedicationForServer(medication))
    .end();
};

export const updateMedication = (medication) => {
  return request.post('/api/rest/medication/update')
    .send(formatMedicationForServer(medication))
    .end();
};

export const deleteMedication = medicationId =>
  request.post('/api/rest/medication/delete')
    .send({ id: medicationId })
    .end();

export const newMedication = name =>
  request.post('/api/rest/medications/create')
    .send({ name })
    .end();
