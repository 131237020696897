import React from 'react';
import PropTypes from 'prop-types';
import cx from './CrossButton.module.scss';

const CrossButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className={cx['button']}
      onClick={onClick}
    />
  );
};

CrossButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CrossButton;
