import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProvidersContainer, { withProvidersFetchLayer } from 'containers/ProvidersContainer';

import { setChartProvider } from 'modules/charts/actions';

import { getChartActiveProviderOption, getProviderOptions } from 'helpers/providers/providers';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import StaticOptions from 'components/Select/StaticOptions';
import Loader from 'components/Loader';

import cx from 'components/ProvidersSelect/ProviderSelect.module.scss';

const providersSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 40,
  widthControl: 163,
  selectOption: {
    textAlign: 'left',
    color: '#2f9dc6',
    cursor: 'pointer',
    padding: '7px 12px',

    ':hover': {
      backgroundColor: '#60b8d9',
      color: 'white',
    }
  },
})

const ProvidersSelectChart = (props) => {
  const onChange = (value) => {
    const {
      setChartProvider,
      chartId,
      providers,
    } = props;

    setChartProvider(value, chartId, providers);
  }

  const {
    providersWithSeat,
    isFetched,
    currentUserProviderId,
    withSeatOnly,
    chartProviderId,
    isFetching
  } = props;

  const options = getProviderOptions(providersWithSeat, isFetched, withSeatOnly);

  const activeOption = getChartActiveProviderOption(options, chartProviderId, currentUserProviderId);

  if (isFetching) {
    return (
      <div className={cx['loader-wrapper']}>
        <Loader />
      </div>
    );
  }

  return (
    <StaticOptions
      options={options}
      onChange={option => onChange(option.value)}
      value={activeOption}
      placeholder="Select provider"
      styles={providersSelectStyles}
      isClearable={false}
      isSearchable={false}
    />
  );
}

ProvidersSelectChart.propTypes = {
  isFetching: PropTypes.bool,
  isFetched: PropTypes.bool,
  providers: PropTypes.array,
  providersWithSeat: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  currentUserProviderId: PropTypes.number,
  withSeatOnly: PropTypes.bool,
  setChartProvider: PropTypes.func,
};

const mapActionCreators = {
  setChartProvider,
};

export default connect(null, mapActionCreators)(ProvidersContainer(withProvidersFetchLayer(ProvidersSelectChart)));
