import isEmpty from 'helpers/common/array/is-empty';

export default class ResetSubElementsHistoryByGroups {
  static updateHistory = (element, groupsToDelete) => {
    const isGroupsToDeletePresent = !isEmpty(groupsToDelete);
    if (!isGroupsToDeletePresent) {
      return element;
    }

    const {
      subElementHistoryJson,
    } = element;

    const {
      numberGroupMap = {},
    } = subElementHistoryJson;

    // TODO Maybe use reduce() method to iterate groupsToDelete
    const newNumberGroupMap = { ...numberGroupMap };
    groupsToDelete.forEach((group) => {
      const historyByGroup = newNumberGroupMap[group];
      const isHistoryByGroupPresent = !isEmpty(historyByGroup);

      if (isHistoryByGroupPresent) {
        delete newNumberGroupMap[group];
      }
    });

    return {
      ...element,
      numberGroupMap: newNumberGroupMap,
    };
  }
}
