import {
  orderBy,
  isEmpty,
  union
} from 'lodash/';

import getFavoriteIdBySystem from 'helpers/favorites/get-favorite-id-by-system';
import { hpChartTabs } from 'helpers/chart';
import stringParser from 'helpers/common/string/string-parser';

export const NO_NEED_FETCHING_FAVORITES_FOR_STEPS = [
  hpChartTabs.HXRV.step,
  hpChartTabs.A.step,
];

export const getFavoritesArray = (match = {}) => {
  return Object.keys(match)
    .map(o => stringParser(o));
};

export const sortElements = (items, exactMatch, broadMatch, systemId) => {
  const exactFavorites = items
    .filter(iteratedItem => exactMatch[getFavoriteIdBySystem(systemId, iteratedItem)])

  const broadFavorites = items
    .filter(iteratedItem => broadMatch[getFavoriteIdBySystem(systemId, iteratedItem)] && !exactMatch[getFavoriteIdBySystem(systemId, iteratedItem)])

  return [
    ...orderBy(exactFavorites, (ef => exactMatch[getFavoriteIdBySystem(systemId, ef)]), 'desc'),
    ...orderBy(broadFavorites, (ef => broadMatch[getFavoriteIdBySystem(systemId, ef)]), 'desc'),
    ...items.filter(item => !exactMatch[getFavoriteIdBySystem(systemId, item)] && !broadMatch[getFavoriteIdBySystem(systemId, item)])
  ]
};

export const displayFavoritesOnly = (items, exactMatch, broadMatch, systemId) => {
  const exactAndBroadItemsIds = union(Object.keys(exactMatch), Object.keys(broadMatch))

  return items.reduce((onlyFavoritesItems, nextItem) => {
    const nextItemId = getFavoriteIdBySystem(systemId, nextItem);
      return exactAndBroadItemsIds.includes(nextItemId.toString())
        ? [
          ...onlyFavoritesItems,
          nextItem
        ]
        : [...onlyFavoritesItems]
  }, [])
}

const sortSystems = (items, exactMatch, broadMatch) => {
  return orderBy(items, [(item) => {
    return (
      !!(exactMatch[item.id] || broadMatch[item.id]) * 1
    );
  }, item => item.order], ['desc', 'asc']);
};

const createNewItems = (items, getItemMatch) => items.filter((item) => {
  item.match = getItemMatch(getFavoriteIdBySystem(null, item)) || 'a';
  return item;
});

const deleteUnusedProps = (sortedArray) => {
  return sortedArray.map((item) => {
    delete item.match;

    return item;
  });
};

export const sortByMatch = (items, getItemMatch) => {
  const newItemsWithMatchParam = createNewItems(items, getItemMatch);

  const sortedItems = orderBy(
    newItemsWithMatchParam,
    ['match'],
    ['desc']
  );

  deleteUnusedProps(sortedItems);
  return sortedItems;
};

export const sortItems = (items, exactMatch, broadMatch, systemId, getItemMatch) => {
  if (!broadMatch || !exactMatch) return items;

  if (!systemId) {
    const sortedSystems = sortSystems(items, exactMatch, broadMatch);
    return sortByMatch(sortedSystems, getItemMatch);
  }

  return sortElements(items, exactMatch, broadMatch, systemId);
};

export const getItemMatch = (itemId, broadMatchItems, exactMatchItems, skip) => {
  // completely skip match assignment because in cc we don't need it
  if (skip) {
    return null;
  }
  let match = null;
  if (broadMatchItems.includes(itemId)) match = 'broad';
  if (exactMatchItems.includes(itemId)) match = 'exact';
  return match;
};

export const getItemMatchCurried = (broadMatchItems, exactMatchItems, skip) => {
  return (itemId) => {
    if (skip) {
      return null;
    }

    let match = null;
    if (broadMatchItems.includes(itemId)) match = 'broad';
    if (exactMatchItems.includes(itemId)) match = 'exact';
    return match;
  };
};

const checkNoNeedFetchingFavoritesForHxrv = (step, system) => {
  return NO_NEED_FETCHING_FAVORITES_FOR_STEPS.includes(step) && isEmpty(system);
};

export const checkNeedForFetchingFavorite = (step, system) => {
  return !checkNoNeedFetchingFavoritesForHxrv(step, system);
};
