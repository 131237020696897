import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import Loader from 'components/Loader';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './AddButton.module.scss';

const ButtonContent = ({ loading, children, diameter }) => (
  <span>
    {loading ? <Loader className={cx.loader} /> : <span className={cx.icon} style={{ width: diameter, height: diameter }} />}
    {' '}
    {children && (
      <span className={cx.text}>
        {children}
      </span>
    )}
  </span>
);

ButtonContent.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  diameter: PropTypes.number,
};

ButtonContent.defaultProps = {
  loading: false,
  children: undefined,
  diameter: undefined,
};

const linkStyles = cssClassesResolver([
  'styleless-link', cx.button
]);

const AddButton = ({
  onClick, children, loading, link, diameter,
}) => {
  if (link) {
    return (
      <Link
        className={linkStyles}
        to={link}
      >
        <ButtonContent
          loading={loading}
          diameter={diameter}
        >
          {children}
        </ButtonContent>
      </Link>
    );
  }

  return (
    <button
      className={cx.button}
      onClick={onClick}
      type="button"
    >
      <ButtonContent
        loading={loading}
        diameter={diameter}
      >
        {children}
      </ButtonContent>
    </button>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
  link: PropTypes.string,
  diameter: PropTypes.number,
};

AddButton.defaultProps = {
  onClick: undefined,
  children: undefined,
  loading: undefined,
  link: undefined,
  diameter: undefined,
};

export default AddButton;

