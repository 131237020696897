import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import elementClass from 'element-class';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Loader from 'components/Loader';
import StripeEndpoint from './StripeEndpoint';

import * as cardHelpers from 'helpers/card/card';

import cx from './BillingInfoModal.module.scss';
import { isEqual } from 'lodash';

class BillingInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardDataErrors: cardHelpers.cardDataErrors,
      loading: false,
      formLoaded: true,
      isPaymentSuccess: false,
    };
  }

  componentDidMount() {
    this.updateOverlay();
    elementClass(this.element).add(cx['modal-window--active']);
  }

  componentDidUpdate(prevState, prevProps) {
    const isChangedErrors = !isEqual(prevState.cardDataErrors, this.state.cardDataErrors);

    if (isChangedErrors) {
      this.updateOverlay();
    }
  }

  componentWillUnmount() {
    if (this.element) {
      ReactDOM.unmountComponentAtNode(this.element);
      document.getElementById('root').removeChild(this.element);
    }
  }

  setFormData = (id, value) => {
    const { cardData } = this.state;
    cardData[id] = value;

    this.setCardData(cardData);
  };

  setCardData = (newCardData) => {
    this.setState({
      cardData: newCardData,
    }, this.updateOverlay);
  };

  setCardDataErrors = (cardDataErrors) => {
    this.setState({
      cardDataErrors,
    });
  };

  updateBillingFormErrors = (invalidCardDataError) => {
    const cardDataErrors = cardHelpers.iterateThroughBraintreeErrors(invalidCardDataError);

    this.setCardDataErrors(cardDataErrors);
  };

  updateIsPaymentSuccess = (newIsPaymentSuccessValue) => {
    this.setState({
      isPaymentSuccess: newIsPaymentSuccessValue,
    });
  };

  updateLoadingState = (newLoadingValue) => {
    this.setState({
      loading: newLoadingValue,
    }, this.updateOverlay);
  };

  chargeCardWithNonce = (data) => {
    const transaction = this.collectDataForRequest(data);

    this.updatePaymentAndLoadingStatus(true, false);

    const {
      savePaymentInformation,
      onModalClose,
      setDidPayment,
      doPayment,
      seats
    } = this.props;

    savePaymentInformation(transaction).then((res) => {
      this.updatePaymentAndLoadingStatus(true, false);
      onModalClose();

      try {
        const {
          billingService,
        } = this.context;

        if (billingService.getNoPaymentSeats(seats).length !== 0) {
          const seatIds = billingService.getNeedToPaySeatIds(seats);

          doPayment(seatIds);

          setDidPayment(true);
        }
      } catch (e) {
        setDidPayment(true);
      }

      return res;
    }, (err) => {
      if (err.response && err.response.body && err.response.body.error && err.response.body.error.message) {
        this.updatePaymentAndLoadingStatus(false, false);
      }
      return Promise.reject(err);
    });
  };

  collectDataForRequest = ({ token }) => {
    return {
      cardNonce: token,
    };
  };

  updatePaymentAndLoadingStatus = (newPaymentStatus, newLoadingStatus) => {
    this.updateIsPaymentSuccess(newPaymentStatus);
    this.updateLoadingState(newLoadingStatus);
  };

  sendCardNonce = (nonce) => {
    this.chargeCardWithNonce(nonce);
  };

  resetFormLoaderState = () => {
    this.setState({ formLoaded: true }, this.updateOverlay);
  };

  updateOverlay = () => {
    const { onModalClose } = this.props;
    const { loading, formLoaded } = this.state;
    if (!this.element) {
      this.element = document.createElement('div');
      this.element.className = cx['modal-window'];
      document.getElementById('root').appendChild(this.element);
    }

    const overlay = (
      <div className={cx['modal-window-container']}>
        <div className={cx['modal-window-overlay']} />
        <div className={cx['modal-window-content']}>
          <div>
            {!formLoaded && <div className={cx['modal-form-load-overlay']} />}
            {!formLoaded && <Loader style={{ zIndex: 101 }} />}

            <div className={cx['modal-window-title']}>Billing Information</div>
              {this.renderCardSuccess()}
              <StripeEndpoint
                onModalClose={onModalClose}
                isLoading={loading}
                buttonTitle={(this.props.action === 'buy' && 'Subscribe') || 'Save'}
                onSubmit={this.chargeCardWithNonce}
              />
          </div>
        </div>
      </div>
    );
    ReactDOM.render(overlay, this.element);
  }

  renderCardSuccess() {
    if (!this.state.isPaymentSuccess) {
      return null;
    }
    return (
      <div className={cx['modal-element-wrapper']}>
        <div className={cx['card-success']}>
          Card Charged Succesfully!
        </div>
      </div>
    );
  }

  renderCardError = (cardErrors) => {
    return (
      <div className={cx['error-wrapper']}>
        {Object.keys(cardErrors).map(fieldError => (
          <div
            key={fieldError}
          >
            {cardErrors[fieldError] && (
              <div
                className={cx['card-error']}
                key={fieldError}
              >
                {cardErrors[fieldError]}
              </div>
            )}
          </div>
        )
        )}
      </div>
    );
  }

  render = () => <></>;
}

BillingInfoModal.contextType = AppContext;

BillingInfoModal.propTypes = {
  setDidPayment: PropTypes.func.isRequired,
  savePaymentInformation: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default BillingInfoModal;
