import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import BillingInfoModalContainer from '../../routes/routes/App/routes/Settings/routes/Financial/components/Plans/BillingInfoModalContainer';
import { fetchLayerHOC as paymentsPlansFetchLayer } from 'containers/PaymentsPlansContainer';

import Modal from 'components/Modal';
import SeatPlansModal from 'components/SeatPlansModal';

import * as api from 'api/seats';

import {
  fetchPaymentsPlans,
  setCurrentPlanToPurchaseSeat,
} from 'modules/financial/paymentsPlans/actions';
import { purchaseSeat } from 'modules/seats/actions';
import { fetchPaymentInfo } from 'modules/financial/paymentInfo/actions';
import { setDidPayment } from 'modules/pastdue/actions';
import { getPaymentsPlans } from 'modules/financial/paymentsPlans/selectors';
import { setPurchaseSeatModalsView } from 'modules/purchaseSeat/actions';
import { getPaymentInfo } from 'modules/financial/paymentInfo/selectors';

const PaymentsPlansWrapper = compose(
  paymentsPlansFetchLayer,
)(SeatPlansModal);

class SeatPaymentConfirm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      processingPurchase: false,
    };
  }

  confirmModal = async (modalState) => {
    const { password } = modalState;

    const { paymentsPlans, setDidPayment, purchaseSeat } = this.props;

    this.setState({
      error: '',
      processingPurchase: true,
    });

    setDidPayment(false);

    const { body } = await api.createSeat(paymentsPlans.currentPlan);
    const {
      id: seatId,
    } = body;

    const priceResponse = await api.fetchPrice(seatId);

    const purchaseOptions = {
      amount: priceResponse.body,
      password,
      seatId,
    };

    try {
      await purchaseSeat(purchaseOptions);

      this.setState({
        processingPurchase: false,
      }, () => {
        this.closeConfirmPasswordModal();
      });
    } catch (err) {
      const error = err.response.body?.errorDTO?.message;

      if (error && error != null && error.length > 0) {
        this.setState({
          error,
          processingPurchase: false,
        });
      } else {
        this.setState({
          processingPurchase: false,
        });
      }

      await api.deleteSeat(seatId);
    }
  };

  closePlansModal = () => {
    this.props.setPurchaseSeatModalsView({
      isModalOpen: true,
      isPlansModalOpen: false,
      isBillingInfoModalOpen: false,
    });
  };

  closePlansModalOnly = () => {
    this.props.setPurchaseSeatModalsView({
      isModalOpen: false,
      isPlansModalOpen: false,
      isBillingInfoModalOpen: false,
    });
  };

  closeConfirmPasswordModal = () => {
    this.props.setPurchaseSeatModalsView({
      isModalOpen: false,
      isPlansModalOpen: false,
      isBillingInfoModalOpen: false,
    });
  };

  openBillingInfoModal = () => {
    this.props.setPurchaseSeatModalsView({
      isModalOpen: true,
      isPlansModalOpen: false,
      isBillingInfoModalOpen: true,
    });
  };

  closeBillingInfoModal = () => {
    this.props.setPurchaseSeatModalsView({
      isModalOpen: true,
      isPlansModalOpen: false,
      isBillingInfoModalOpen: false,
    });
  };

  render() {
    const {
      cancelDisabled,
      clickOutsideDisabled,
      paymentsPlans,
      fetchPaymentsPlans,
      purchaseSeatModalsView,
      fetchPaymentInfo,
      setCurrentPlanToPurchaseSeat,
      paymentInfo,
    } = this.props;

    const {
      error,
      processingPurchase,
    } = this.state;

    const {
      isModalOpen,
      isPlansModalOpen,
      isBillingInfoModalOpen,
    } = purchaseSeatModalsView;

    const {
      isFetched,
      creditCard,
    } = paymentInfo;

    return (
      <div>
        <Modal
          isOpen={isPlansModalOpen}
          data={{
            type: 'plans',
            title: 'Plans',
            content: '',
          }}
          onModalClose={this.closePlansModalOnly}
          cancelDisabled
          clickOutsideDisabled
        >
          <PaymentsPlansWrapper
            paymentsPlans={paymentsPlans}
            fetchPaymentsPlans={fetchPaymentsPlans}
            closePlansModal={this.closePlansModal}
            setCurrentPlanToPurchaseSeat={setCurrentPlanToPurchaseSeat}
          />
        </Modal>
        <Modal
          paymentsPlans={paymentsPlans}
          fetchPaymentInfo={fetchPaymentInfo}
          isFetchedPaymentInfo={isFetched}
          data={{
              type: 'password-confirm-with-card',
              title: 'Purchase Confirmation',
              content: '<p>You are about to purchase an additional seat. You will be charged a prorated fee according to your current billing cycle.  At the start of your next billing cycle, this new seat will be added to your existing plan(s) on a reoccurring basis.   To cancel a purchased seat, please reach out to our support department at least 14-days before the start of your next billing cycle.</p><p><strong>Please enter your password to confirm your purchase</strong></p>',
              cardData: creditCard,
              passwordError: false,
              purchaseError: error,
              processingPurchase,
              shouldRenderCard: true,
            }}
          size="medium"
          isOpen={isModalOpen}
          onModalClose={this.closeConfirmPasswordModal}
          onModalConfirm={this.confirmModal}
          cancelDisabled
          clickOutsideDisabled
          openBillingInfoModal={this.openBillingInfoModal}
        />
        {isBillingInfoModalOpen && (
          <BillingInfoModalContainer
            closeModal={this.closeBillingInfoModal}
            location="buy"
          />
        )}
      </div>
    );
  }
}

SeatPaymentConfirm.propTypes = {
  fetchPaymentsPlans: PropTypes.func.isRequired,
  paymentsPlans: PropTypes.shape({
    listPlans: PropTypes.array.isRequired,
  }).isRequired,
  clickOutsideDisabled: PropTypes.bool,
  setDidPayment: PropTypes.func.isRequired,
  setPurchaseSeatModalsView: PropTypes.func.isRequired,
  purchaseSeat: PropTypes.func.isRequired,
};

SeatPaymentConfirm.defaultProps = {
  clickOutsideDisabled: false,
};

const mapStateToProps = (state) => {
  return {
    paymentsPlans: getPaymentsPlans(state.financial),
    purchaseSeatModalsView: state.purchaseSeat,
    paymentInfo: getPaymentInfo(state.financial),
  };
};

const mapDispatchToProps = {
  fetchPaymentsPlans,
  fetchPaymentInfo,
  setDidPayment,
  setPurchaseSeatModalsView,
  setCurrentPlanToPurchaseSeat,
  purchaseSeat,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatPaymentConfirm);
