import {
  shape,
  oneOfType,
  arrayOf,
  instanceOf,
  string,
  number,
  bool,
  func
} from 'prop-types';

export const selectOption = {
  obj:
    shape({
      value: oneOfType([string, number]),
      label: oneOfType([string, number])
    }),
  medObj:
    shape({
      id: oneOfType([string, number]),
      name: oneOfType([string, number])
    }),
  arr:
    arrayOf(
      shape({
        value: oneOfType([string, number]),
        label: oneOfType([string, number])
      })
  ),
  medArr:
    arrayOf(
      shape({
        id: number,
        name: string
      })
    ),
  default: null,
  medDefault: null,
};

export const disabled = {
  obj: bool,
  default: false,
};

export const refTypeGenerator = {
  obj: shape({
    current: instanceOf(Element)
  }),
};

export const routerGenerator = {
  obj: shape({
    push: func
  })
};
