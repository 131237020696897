import { handleActions } from 'redux-actions';
import * as constants from '../constants';

const initialState = {};

export default handleActions({
  [constants.CLINICS_FETCH_SUCCESS]: (state, action) => ({
    // we don't pass old clinics data so
    // we only store data for currently logging user
    // ...state,
    ...action.payload.result,
  }),
}, initialState);
