import { handleActions } from 'redux-actions';

import { removeChartIdFromPage, getPageNumbersArr, fetchChartIdFromCreatingResponse } from 'helpers/charting/charting';

import * as chartsConstants from '../constants';

const initialState = [];

export default handleActions({

  [chartsConstants.CHARTS_BY_PAGE_FETCH]: (state, action) => {
    const result = { ...state };

    if (action.payload.signedStatus === 'unsigned') {
      result.isFetching = true;
    }

    return result;
  },

  [chartsConstants.CHARTS_BY_PAGE_FETCH_FAIL]: state => ({
    ...state,
    isFetching: false,
  }),

  [chartsConstants.CHARTS_BY_PAGE_FETCH_SUCCESS]: (state, action) => {
    const { signedStatus } = action.payload;
    const { countPages } = action.payload.result;

    const result = {
      ...state,
      isFetching: false,
    };

    if (signedStatus === 'unsigned') {
      result[action.payload.page] = [...action.payload.result.encounterUserDTOS.map(item => item.id)];
      result.pagesCount = countPages;
    }

    return result;
  },

  [chartsConstants.CHART_CREATE_SUCCESS]: (state, action) => {
    const newState = { ...state };

    const chartId = fetchChartIdFromCreatingResponse(action.payload.result);

    const pageNumbersArr = getPageNumbersArr(newState);

    const maxPage = Math.max(...pageNumbersArr);

    if (!pageNumbersArr.length) {
      newState[0] = [chartId];
      newState.pagesCount = 1;

      return newState;
    }

    if (state[maxPage].length >= 20) {
      newState[maxPage + 1] = [chartId];
      newState.pagesCount += 1;
    } else {
      newState[maxPage].push(chartId);
    }

    return newState;
  },

  [chartsConstants.CHART_DELETE_SUCCESS]: (state, action) => {
    const newState = { ...state };

    const { chartId } = action.payload;
    const pageNumbersArr = getPageNumbersArr(newState);
    const pagesObj = {};

    pageNumbersArr.forEach(pageNumber => { pagesObj.pagesNumber = newState[pageNumber] });

    const resultIdsByPage = removeChartIdFromPage(pagesObj, chartId);

    return ({
      ...newState,
      ...resultIdsByPage,
    });
  },


}, initialState);
