import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';

import { Formik } from 'formik';

import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';
import RolesContainer from 'containers/RolesContainer';

import UserInviteForm from 'routes/routes/App/routes/Settings/routes/Users/components/UserInviteForm';
import PermissionsForm from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm/Permissions';
import InfoForm from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm/Info';

import { addNewProperty, isNullOrUndefined, stripWhiteListProps } from 'helpers';
import { getUserRoleId } from 'helpers/roles';
import * as usersConstants from 'helpers/users/constants';
import { buildSchema } from 'helpers/validationRules';

import { checkExistingEmail } from 'api/user';

import cx from 'components/EditForm/FormAssets.module.scss';
import ChoiceLinkToNavigationToUser from 'helpers/users/ChoiceLinkToNavigationToUser';

const ConnectedInfoForm = compose(
  RolesContainer,
)(InfoForm);

const ConnectedInviteForm = compose(
  RolesContainer,
)(UserInviteForm);

const whiteListProps = [
  'id',
  'firstName',
  'lastName',
  'login',
  'password',
  'enable',
  'permissions',
  'signature',
  'npi',
  'pin',
  'suffix',
];

const emptyUser = {
  firstName: '',
  lastName: '',
  login: '',
  password: '',
  confirmedPassword: '',
  enable: true,
  permissions: [],
  signature: null,
  npi: '',
  pin: '',
  suffix: '',
  imageUrl: null,
};

class UserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissionsDirty: false,
      infoDirty: false,
      isDisplayInviteForm: true,
    };

    this.formikRef = React.createRef();
  }

  setIsDisplayInviteForm = (isDisplayInviteForm) => {
    this.setState({
      isDisplayInviteForm,
    });
  };

  formatOutgoingUser = (user) => {
    const data = stripWhiteListProps(user, whiteListProps);

    if (!data.password.length) {
      delete data.password;
    }

    return data;
  };

  isSectionActive = (sectionName) => {
    const { location } = this.props;
    return location.query.section === sectionName;
  };

  updatePermissionsForCurrentUser = (user) => {
    const { isCurrentUser, updatePermissionsForCurrentUserLocally } = this.props;
    if (!isCurrentUser) {
      return;
    }

    updatePermissionsForCurrentUserLocally(user.permissions);
  };

  submitEditForm = (user, stateUser) => {
    const { updateUser } = this.props;
    updateUser(user, stateUser);

    this.updatePermissionsForCurrentUser(user);
  };

  submitUserInfo = (values) => {
    const { userId, user: userFromProps } = this.props;
    const isNewForm = !userId;

    const user = this.formatOutgoingUser(values);

    const currentLogin = userFromProps?.login;
    const { login } = values;

    const newUser = this.transformUser(user);
    const newEditUser = this.transformEditUser(newUser);

    if (isNewForm) {
      checkExistingEmail({ login }).then(({ body }) => {
        const emailExists = typeof body === 'number';

        this.setState({ emailExists }, () => {
          if (emailExists) return;

          this.submitNewForm(newUser, values);
        });
      });
    } else if (login === currentLogin) {
      this.submitEditForm(newEditUser, values);
    } else {
      checkExistingEmail({ login }).then(({ body }) => {
        const emailExists = body;

        this.setState({ emailExists }, () => {
          if (emailExists) {
            this.toggleSection({
              sectionName: 'info',
              userId: userFromProps?.id,
            });
          } else {
            this.submitEditForm(newEditUser, values);
          }
        });
      });
    }

    // const { setDirty } = this.props;
    // setDirty(false);
  };

  transformUser = (user) => {
    const { roles } = this.props;

    const userRoles = roles;
    const userPermissions = user.permissions;

    const roleId = getUserRoleId(userPermissions, userRoles);

    return addNewProperty('roleId', roleId, user);
  };

  transformEditUser = (oldUser) => {
    const {
      seatId,
    } = this.props;

    return {
      ...oldUser,
      seatId,
    };
  };

  updateProp = (prop, value) => {
    this.formikRef.current.setFieldValue([prop], value);

    const isNeedChangeInfoDirty = this.isNeedChangeInfoDirty();
    const updatedInfoDirty = !!isNeedChangeInfoDirty;

    // const { setDirty } = this.props;
    // setDirty(updatedInfoDirty);
  };

  updateUser = (prop, value) => {
    this.updateProp(prop, value);

    const isNeedChangeInfoDirty = this.isNeedChangeInfoDirty();
    const updatedInfoDirty = !!isNeedChangeInfoDirty;

    this.setState({
      infoDirty: updatedInfoDirty,
      emailExists: null,
    });
  };

  isNeedChangeInfoDirty = () => {
    const {
      isDisplayInviteForm,
    } = this.state;

    return isDisplayInviteForm === false;
  };

  updatePermissions = (value) => {
    this.updateProp('permissions', value);
    this.setState({ permissionsDirty: true });
  };

  cancelInfo = (values, { resetForm }) => {
    const newUser = {
      ...emptyUser,
      ...values,
      permissions: values?.permissions,
    };

    resetForm({ values: newUser });
    const { permissionsDirty } = this.state;
    // const { setDirty } = this.props;

    this.setState({ infoDirty: false }, () => {
      if (!permissionsDirty) {
        // setDirty(false);
      }
    });
  };

  cancelPermissions = (values, { resetForm }) => {
    const newUser = {
      ...values,
      permissions: values?.permissions || [],
    };

    resetForm({ values: newUser });

    const { infoDirty } = this.state;
    const { userId, router, location } = this.props;

    this.setState({
      permissionsDirty: false,
    }, () => {
      if (!infoDirty) {
        // setDirty(false);
      }
      if (!userId) {
        router.push({ pathname: location.pathname, query: { section: undefined } });
      }
    });
  };

  getInitialValues = () => {
    const { user } = this.props;
    return {
      ...emptyUser,
      ...user,
    };
  };

  resolveValidationSchemaByFormType = () => {
    const { user } = this.props;

    return user?.id
      ? this.generateValidationSchemaForEditForm()
      : this.generateValidationSchemaForNewForm();
  };

  generateValidationSchemaForEditForm = () => buildSchema({
    firstName: 'required',
    lastName: 'required',
    login: 'requiredEmail',
  });

  generateValidationSchemaForNewForm = () => buildSchema({
    firstName: 'required',
    lastName: 'required',
    password: 'passwordValidation',
    confirmedPassword: 'passwordsMatch'
  });

  toggleSection = ({ sectionName, userId }) => {
    const query = {
      section: sectionName,
    };

    if (userId) {
      query.userId = userId;
    }

    const { router, location } = this.props;

    router.push({ pathname: location.pathname, query });
  };

  submitNewForm(user, stateUser) {
    if (!this.isSectionActive(usersConstants.USERS_ROUTE_PARAMS.section.permissions)) {
      this.toggleSection({
        sectionName: usersConstants.USERS_ROUTE_PARAMS.section.permissions,
      });
      return;
    }

    const { createUser, router } = this.props;
    createUser(user, stateUser).then((res) => {
      const { id, enable } = res.body;

      router.push({
        pathname: ChoiceLinkToNavigationToUser.choose(enable),
        query: { userId: id }
      });
      return res;
    });
  }

  render() {
    const {
      emailExists,
      isDisplayInviteForm,
      permissionsDirty,
    } = this.state;

    const {
      location,
      userId,
      isCurrentUser,
      isUpdating,
      isCreating,
      router,
      showModal,
      fetchUserImage,
      isDownloadingSignature,
      user,
    } = this.props;

    const {
      updateUser,
      cancelInfo,
      cancelPermissions,
      updatePermissions,
      submitUserInfo
    } = this;

    const currentLogin = user?.login;

    const isProvider = user?.id === user?.providerId;

    return (
      <Formik
        innerRef={this.formikRef}
        enableReinitialize
        initialValues={this.getInitialValues()}
        onSubmit={submitUserInfo}
        validationSchema={this.resolveValidationSchemaByFormType()}
      >
        {({
            values,
            handleSubmit,
            resetForm,
          }) => {
          if (isNullOrUndefined(user?.id) && isDisplayInviteForm) {
            return (
              <span className={cx['invite-container']}>
                <ConnectedInviteForm
                  updatePermissions={updatePermissions}
                  userLogin={values?.login}
                  userPermissions={values?.permissions}
                  updateUser={e => updateUser('login', e.target.value)}
                  setIsDisplayInviteForm={this.setIsDisplayInviteForm}
                />
              </span>
            );
          }

          if (location.query.section !== 'permissions') {
            return (
              <ConnectedInfoForm
                updatePermissions={updatePermissions}
                emailExists={emailExists}
                userId={userId}
                user={values}
                currentLogin={currentLogin}
                isCurrentUser={isCurrentUser}
                isProvider={isProvider}
                isUpdating={isUpdating}
                isCreating={isCreating}
                updateUser={updateUser}
                onCancel={() => cancelInfo(values, { resetForm })}
                onSubmit={handleSubmit}
                fetchUserImage={fetchUserImage}
                isDownloadingSignature={isDownloadingSignature}
              />
            );
          }
           return (
            <PermissionsForm
              userId={userId}
              user={values}
              permissionsDirty={permissionsDirty}
              isCurrentUser={isCurrentUser}
              isUpdating={isUpdating}
              isCreating={isCreating}
              updatePermissions={updatePermissions}
              onCancel={() => cancelPermissions(values, { resetForm })}
              onSubmit={handleSubmit}
              router={router}
              location={location}
              showModal={showModal}
            />
          );
        }}
      </Formik>
    );
  }
}

UserForm.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  userId: PropTypes.number,
  isCurrentUser: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool,
  isCreating: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  isConfirmModalOpen: PropTypes.bool,
  updatePermissions: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
  updatePermissionsForCurrentUserLocally: PropTypes.func.isRequired,
  seatId: PropTypes.number.isRequired,
  setUserInactive: PropTypes.func.isRequired,
  fetchUserImage: PropTypes.func.isRequired,
  isDownloadingSignature: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
};

const LoaderLayer = (props) => {
  if (props?.user?.error) {
    return (
      <div className={cx.error}>User does not exist</div>
    );
  }

  return <UserForm {...props} />;
};

const DirtyCheckedWrapper = withRouter(dirtyCheckLayerHOC(LoaderLayer));

export default props => (
  <DirtyCheckedWrapper
    {...props}
    dirtyCheckIdentity="userId"
    shouldUpdate={(nextDirty, nextIdentifier) => !nextDirty || props.userId === nextIdentifier}
  />
);
