import React, { useEffect } from 'react';

import { hpChartTabs } from 'helpers/chart';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';

const ElementsFetchLayerHOC = (Component) => props => {
  useEffect(() => {
    if (props.areElementsFetching === undefined && props.system) {
      fetchElements(props.system);
    }

  }, [props?.system?.id]);

  const fetchElements = (system) => {
    const { fetchResources } = props;
    switch (system.type) {
      case 1:
        fetchResources({
          systemId: system.id,
          resourceType: 'diagnoses',
        });
        break;
      case 2:
        fetchResources({
          systemId: system.id,
          resourceType: 'medications',
        });
        break;
      case BILLING_SYSTEMS.CPT.type:
        fetchResources({
          systemId: system.id,
          resourceType: hpChartTabs.BILLING.systems.CPT.resourceType,
        });
        break;
      case BILLING_SYSTEMS.HCPC.type:
        fetchResources({
          systemId: system.id,
          resourceType: BILLING_SYSTEMS.HCPC.resourceType,
        });
        break;
      default:
        break;
    }
  };

    return <Component {...props} />;
};

export default ElementsFetchLayerHOC;
