import React from 'react';

import UserRoleSelect from './components/UserRoleSelect';
import VerifyEmailStatus from 'components/VerifyEmailStatus';

import cx from './UserRow.module.scss';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

const rowStyles = ({isSelected, isCurrentUser}) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [
    'wrapper',
    isSelected && 'wrapper--active',
    isCurrentUser && 'wrapper--current'
  ]
})

const roleFieldStyles = () => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: 'role-select-wrapper'
})

const UserRow = ({
  user,
  fields,
  onClick,
  isSelected,
  isCurrentUser,
  onRoleChange,
  updateUserPermissionsLocal,
  showModal,
  roles
}) => {
  return (
    <tr className={rowStyles({isCurrentUser, isSelected})} onClick={onClick}>
      <td
        style={{
          width: fields[0].width,
        }}
      >
        <VerifyEmailStatus
          verifyEmail={user.verifyEmail}
        />
      </td>
      <td
        style={{
          width: fields[1].width,
        }}
      >
        {user.user}
      </td>
      <td
        style={{
          width: fields[2].width,
        }}
      >
        {
          <div className={roleFieldStyles()}>
            <UserRoleSelect
              user={user}
              onRoleChange={onRoleChange}
              isCurrentUser={isCurrentUser}
              updateUserPermissionsLocal={updateUserPermissionsLocal}
              showModal={showModal}
              roles={roles}
            />
          </div>
        }
      </td>
    </tr>
  );
};

export default UserRow;
