export const RESOURCE_REMOVE = 'RESOURCE_REMOVE';
export const RESOURCE_REMOVE_SUCCESS = 'RESOURCE_REMOVE_SUCCESS';
export const RESOURCE_REMOVE_FAIL = 'RESOURCE_REMOVE_FAIL';

export const RESOURCES_ADD = 'RESOURCES_ADD';
export const RESOURCE_ADD = 'RESOURCE_ADD';

export const RESOURCES_FETCH = 'RESOURCES_FETCH';
export const RESOURCES_FETCH_SUCCESS = 'RESOURCES_FETCH_SUCCESS';
export const RESOURCES_FETCH_FAIL = 'RESOURCES_FETCH_FAIL';
