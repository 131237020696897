import UpdateSubElementsHistoryOnSelect from 'helpers/social/elements/sub-elements/on-select-sub-element/update-history';
import UpdateActiveSubElementOnSelect from 'helpers/social/elements/sub-elements/on-select-sub-element/update-active-sub-element';
import PayloadDataToUpdateSubElementsForElement from 'payload/social/elements/sub-elements/update';

export default class SocialElementService {
  constructor(socialElement, patientId, activeSystemId) {
    this.socialElement = socialElement;
    this.patientId = patientId;
    this.activeSystemId = activeSystemId;
  }

  updateElementOnSelectSubElement = (subElement) => {
    const {
      subIds,
      subElementHistoryJson,
    } = this.socialElement;

    const { numberGroup } = subElement;

    const updatedSubElementsHistory = UpdateSubElementsHistoryOnSelect.getNewHistory(subElementHistoryJson, subElement, numberGroup);
    const newSubElements = UpdateActiveSubElementOnSelect.getActiveSubElementsForElement(subElement, subIds);

    return {
      ...this.socialElement,
      subIds: newSubElements,
      ...updatedSubElementsHistory,
    };
  }

  collectDataToUpdateSubElementsHistory = (elementToUpdate) => {
    const data = {
      element: elementToUpdate,
      patientId: this.patientId,
      socialId: this.activeSystemId,
    };

    const payloadDataToUpdateElement = new PayloadDataToUpdateSubElementsForElement(data);

    return {
      dataToRequest: payloadDataToUpdateElement.getRequestData(),
      localData: payloadDataToUpdateElement.getLocalData(),
    };
  }

  collectDataToUpdateSubElementsHistoryWrapper = (subElement) => {
    const elementAfterSubElementSelect = this.updateElementOnSelectSubElement(subElement);

    return this.collectDataToUpdateSubElementsHistory(elementAfterSubElementSelect);
  }
}
