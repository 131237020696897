import React from 'react';
import { compose } from 'redux';

import PaymentsForm from '../PaymentsForm';
import paymentsContainer, { fetchLayerHOC } from 'containers/UserPaymentsContainer';

import cx from './PaymentsTab.module.scss';

const PaymentsFormContainer = compose(
  paymentsContainer,
  fetchLayerHOC
)(PaymentsForm);

export const PaymentsTab = () => {
  return (
    <div className={cx['tab-content']}>
      <PaymentsFormContainer />
    </div>
  );
};
