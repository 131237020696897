import React, { Component } from 'react';
import PropTypes from 'prop-types';

import orderBy from 'lodash/orderBy';

import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import Loader from 'components/Loader';
import EmptyMessage from 'components/UserEditForm/EmptyMessage';
import PaymentRow from '../PaymentRow';

import fields from './fields';

import cx from './PaymentsForm.module.scss';

class PaymentsForm extends Component {
  state = {
    sortBy: 'date',
    desc: true,
  };

  onSort = (column) => {
    if (this.state.sortBy === column) {
      this.setState({ desc: !this.state.desc });
      return;
    }
    this.setState({
      sortBy: column,
      desc: false,
    });
  };

  getSortedItems = () => {
    const { payments } = this.props;

    const { sortBy, desc } = this.state;
    const direction = desc ? 'desc' : 'asc';

    return orderBy(payments.listPayments.map(payment => ({
      transaction: payment.transactionId,
      amount: payment.amount,
      cc: payment.cc,
      date: payment.date,
      status: payment.status,
      startDate: payment.startDate,
      expireDate: payment.expireDate,
      period: payment.startDate,
    })), sortBy, direction);
  };

  render() {
    const { sortBy, desc } = this.state;
    const { payments } = this.props;
    const renderedItems = this.getSortedItems();

    if (!payments.listPayments.length) {
      return (
        <div className={cx.container}>
          <EmptyMessage>No payment Hx</EmptyMessage>
        </div>
      );
    }

    return (
      <div className={cx.container}>
        <Table
          color="blue"
          style={{ height: '100%', display: 'block' }}
        >
          <thead>
            <tr>
              {fields.map(field => (
                <TableHead
                  {...field}
                  key={field.title}
                  desc={field.title === sortBy ? desc : undefined}
                  onClick={() => {
                    if (!field.sortable || !this.onSort) return;
                    this.onSort(field.title);
                  }}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {payments.listPayments.length && (
              renderedItems.map((value) => {
               return <PaymentRow value={value} key={value.transaction} />;
              })
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

PaymentsForm.propTypes = {
  payments: PropTypes.object.isRequired,
};

const Wrapper = (props) => {
  const { payments } = props;

  if (!payments.isFetched || payments.isFetching) {
    return <div className={cx['loader-wrapper']}><Loader /></div>;
  }
  return <PaymentsForm {...props} />;
};

export default Wrapper;
