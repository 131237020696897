import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'containers/RouterParams';

import { ChartContext } from 'routes/routes/App/routes/Charts/components/Chart/context';

import FavoritesForceFetchContainer from 'containers/FavoritesForceFetchContainer';
import ChartingStepContainer, { favoritesFetchLayerContainer } from 'containers/ChartingStepContainer';

import Loader from 'components/Loader';

import ChartingSteps from 'routes/routes/App/routes/Charts/components/ProgressNote/ChartingSteps';
import ChartingStep from 'routes/routes/App/routes/Charts/components/ProgressNote/ChartingStep';

import stringParser from 'helpers/common/string/string-parser';

import cx from 'routes/routes/App/routes/Charts/components/ProgressNote/Charting/Charting.module.scss';
import { useOutletContext } from 'react-router';

const ConnectedChartingStep = ChartingStepContainer(FavoritesForceFetchContainer(favoritesFetchLayerContainer(ChartingStep)));

const Charting = (props) => {
  const { chartId } = useOutletContext();
  const { lastQuery, updateLastQuery } = useContext(ChartContext);
  const {
    isFetching,
    chartingSteps,
    completion,
    isCopyingPreviousNote,
    patientId,
    router,
    location
  } = props;

  useEffect(() => {
    const hasStepInQuery = !!location.query.step;
    const actualSteps = chartingSteps.filter(o => o.type !== 'social');
    const hasSteps = !!actualSteps.length;
    const noSuchStep = !actualSteps.map(step => step.id).includes(parseInt(location.query.step, 10));

    if (hasSteps && (!hasStepInQuery || noSuchStep)) {
      redirectToStep(location.pathname, actualSteps[0].id);
    }

    if (lastQuery && !hasStepInQuery) {
      router.replace(location.pathname + lastQuery);
    }

    updateLastQuery(location.search);
  }, [location.query.step]);


  const redirectToStep = (pathname, step) => {
    router.replace({
      pathname,
      query: {
        step,
      },
    });
  };

  const isLoaded = !(isFetching || isFetching === null);
  const noSuchStep = !chartingSteps.map(step => step.id).includes(stringParser(location.query.step));

  if (!isLoaded || noSuchStep || isCopyingPreviousNote) {
    return <Loader />;
  }

  const activeStep = parseInt(location.query.step, 10);
  const activeSystem = location.query.system && parseInt(location.query.system, 10);

  const chartIdFormatted = chartId && parseInt(chartId, 10);

  return (
    <div className={cx.wrapper}>
      <ChartingSteps
        steps={chartingSteps}
        activeStep={activeStep}
        location={location}
        completion={completion}
        chartId={chartIdFormatted}
      />
      <div className={cx['step-content']}>
        <ConnectedChartingStep
          step={activeStep}
          system={activeSystem}
          chartId={chartIdFormatted}
          patientId={patientId}
        />
      </div>
    </div>
  );
};

Charting.propTypes = {
  chartingSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  completion: PropTypes.object,
  isCopyingPreviousNote: PropTypes.bool,
};

export default withRouter(Charting);
