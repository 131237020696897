import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import FavoritesForceFetchContainer from 'containers/FavoritesForceFetchContainer';

import { transformOverflowName } from 'helpers/chart';
import { differencesInSubelementsNumberGroups } from 'helpers';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import cx from './System.module.scss';
import EnclosedElements from './EnclosedElements';
import SearchForm from '../SearchForm';

const systemsStyles = ({ activeSystem }) => ({
  removeButton: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: activeSystem ? ['remove-button', 'remove-button-active'] : ['remove-button']
  })
});

const System = (props) => {
  const addElement = (element) => {
    return props.addElement(props.chartId, props.system.id,
      {
        ...element,
        // if type is undefined then regular element was clicked
        // and for such elements we should set type to null
        // else it is either true or false
        // which means pos/neg element was clicked
        type: element.type !== undefined ? element.type : null,

      });
  };

  const createElement = (elementToCreate, elementToAdd) => {
    return props.createElement(
      props.chartId,
      {
        ...elementToCreate,
        systemId: props.system.id,
        medSystemName: props.system.name,
        chartId: props.chartId,
      },
      elementToAdd && {
        ...elementToAdd,
        // if type is undefined then regular element was clicked
        // and for such elements we should set type to null
        // else it is either true or false
        // which means pos/neg element was clicked
        type: elementToAdd.type !== undefined ? elementToAdd.type : null,
      }
    );
  };

  const createSubelement = subelement => props.createSubelement(subelement);

  const updateElementNameHandler = async (newElement, oldElement) => {
    const {
      updateElement,
      addIndexToAutoSet,
      social,
      patient,
      chartId = null,
      system,
      enableFavoritesForceFetch,
    } = props;

    const {
      id,
      chartingId,
    } = system;

    const {
      id: patientId,
    } = patient;

    const res = await updateElement(newElement, oldElement, (social && patientId) || chartId, id, chartingId);
    const {
      body,
    } = res;

    enableFavoritesForceFetch();

    const differenceInNumberGroups = differencesInSubelementsNumberGroups(newElement.name, oldElement.name);
    const elementId = social ? body : body.id;
    addIndexToAutoSet(differenceInNumberGroups, elementId);
    return res;
  };

  const removeSystemWrapper = (element, { removeElement, systemId, chartId, systemChartingId, social }) =>
    removeElement(social ? { systemId, element } : { chartId, systemId, element, step: systemChartingId });

  const {
    allowElementUpdate,
    allowCreation,
    activeSystem,
    system,
    onSystemClick,
    onRemove,
    elements,
    removeElement,
    isROS = false,
    searchableItems,
    allElements,
    fetchResources,
    isFetching,
    chartId = null,
    assignedDiagnoses,
    social,
    newMedication,
    exactMatch = {},
    broadMatch = {},
    patient,
    addIndexToAutoSet,
    resetIndexToAutoSet,
    listOfAutoOpenSubelementsIndexes,
    isChartSaving = false,
    saveChart,
    isAllowAttachingIcdTenCodes,
    selectElementCallback = () => {},
    selectSubElementCallback,
    selectAfterFirstSubElementCallback,
    rvElementIds
  } = props;

  const {
    id: patientId,
  } = patient;

  const {
    elementNameConverter,
  } = useContext(AppContext);

  const transformedSystemName = elementNameConverter.transformedSeveralNames(system?.name, patient);
  const overflowedName = transformOverflowName(transformedSystemName, 40, 36);

  const resolvedStyles = systemsStyles({ activeSystem });

  return (
    <div className={cx.wrapper}>
      <div className={activeSystem ? cx['system-name-active'] : cx['system-name']}>
        <span
          className={activeSystem ? cx['name-active'] : cx.name}
        >
          {overflowedName}
        </span>
        <span className={resolvedStyles.removeButton} onClick={onRemove} />
      </div>
      <div
        className={cx['elements-controls']}
        onClick={onSystemClick}
      >
        <EnclosedElements
          selectAfterFirstSubElementCallback={selectAfterFirstSubElementCallback}
          allowCreation={allowCreation}
          elements={elements}
          removeElement={element =>
            removeSystemWrapper(
              element,
              {
                removeElement, chartId, systemId: system.id, systemChartingId: system.chartingId, social
              }
            )
          }
          updateElement={addElement}
          updateElementName={updateElementNameHandler}
          isROS={isROS}
          systemType={system.type}
          systemId={system.id}
          patientId={patientId}
          allowElementUpdate={allowElementUpdate}
          assignedDiagnoses={assignedDiagnoses}
          patient={patient}
          createSubelement={createSubelement}
          step={system.chartingId}
          chartId={chartId}
          social={social}
          resetIndexToAutoSet={resetIndexToAutoSet}
          listOfAutoOpenSubelementsIndexes={listOfAutoOpenSubelementsIndexes}
          isChartSaving={isChartSaving}
          saveChart={saveChart}
          isAllowAttachingIcdTenCodes={isAllowAttachingIcdTenCodes}
          exactMatch={exactMatch}
          broadMatch={broadMatch}
        />
        <div onClick={onSystemClick}>
          <SearchForm
            rvElementIds={rvElementIds}
            selectElementCallback={selectElementCallback}
            selectSubElementCallback={selectSubElementCallback}
            activeElements={elements}
            system={system}
            activeSystem={activeSystem}
            searchableItems={searchableItems}
            isROS={isROS}
            addElement={addElement}
            systemType={system.type}
            allElements={allElements}
            createElement={system.type === 2 ? ({ name }) => newMedication(name, system.id, chartId) : createElement}
            allowCreation={allowCreation}
            fetchResources={fetchResources}
            systemId={system.id}
            isFetching={isFetching}
            exactMatch={exactMatch}
            broadMatch={broadMatch}
            step={system.chartingId}
            chartId={chartId}
            createSubelement={createSubelement}
            social={social}
            patientId={patientId}
            addIndexToAutoSet={addIndexToAutoSet}
          />
        </div>
      </div>
    </div>
  );
};

System.propTypes = {
  saveChart: PropTypes.func,
  createElement: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  addIndexToAutoSet: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  elements: PropTypes.array.isRequired,
  allElements: PropTypes.array.isRequired,
  searchableItems: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  system: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  chartId: PropTypes.number,
  isROS: PropTypes.bool,
  enableFavoritesForceFetch: PropTypes.func.isRequired,
  isChartSaving: PropTypes.bool,
  allowCreation: PropTypes.bool.isRequired,
  allowElementUpdate: PropTypes.bool.isRequired,
  createSubelement: PropTypes.func.isRequired,
  resetIndexToAutoSet: PropTypes.func.isRequired,
  isAllowAttachingIcdTenCodes: PropTypes.bool.isRequired,
  selectElementCallback: PropTypes.func,
  selectSubElementCallback: PropTypes.func,
  selectAfterFirstSubElementCallback: PropTypes.func,
  exactMatch: PropTypes.object,
  broadMatch: PropTypes.object,
};

export default FavoritesForceFetchContainer(System);
