import * as patientsConstants from './constants';

import { getActiveAndInactiveAllergies } from 'modules/patientsHx/selectors';
import { createAllergy, deleteAllergy } from 'modules/patientsHx/actions';

import isEmpty from 'helpers/common/array/is-empty';
import { byPresentNoKnownAllergy } from 'helpers/fmss/allergies/noKnowAllergiesFilter';
import { FMSS_NO_KNOWN_ENTITY } from 'helpers/fmss/constants';
import stringParser from 'helpers/common/string/string-parser';

import * as patientsApi from 'api/patients';
import { getImage } from 'api/images';
import { findOrCreateAllergyByName } from 'api/allergies';

export const fetchPatients = () => ({
  types: [
    patientsConstants.PATIENTS_FETCH,
    patientsConstants.PATIENTS_FETCH_SUCCESS,
    patientsConstants.PATIENTS_FETCH_FAIL,
  ],
  promise: () => patientsApi.fetchPatients(),
});

export const searchTabFetchPatients = () => ({
  types: [
    patientsConstants.SEARCH_TAB_PATIENTS_FETCH,
    patientsConstants.SEARCH_TAB_PATIENTS_FETCH_SUCCESS,
    patientsConstants.SEARCH_TAB_PATIENTS_FETCH_FAIL,
  ],
  promise: () => patientsApi.searchTabFetchPatients(),
});

export const fetchPatientsPage = pageNum => ({
  types: [
    patientsConstants.PATIENTS_PAGE_FETCH,
    patientsConstants.PATIENTS_PAGE_FETCH_SUCCESS,
    patientsConstants.PATIENTS_PAGE_FETCH_FAIL,
  ],
  promise: () => patientsApi.fetchPagePatients(pageNum),
  pageNum,
});

export const fetchPatientImage = (imageName, id) => ({
  types: [
    patientsConstants.FETCH_PATIENT_IMAGE,
    patientsConstants.FETCH_PATIENT_IMAGE_SUCCESS,
    patientsConstants.FETCH_PATIENT_IMAGE_FAIL,
  ],
  promise: () => getImage(imageName),
  patientId: id,
});

export const fetchPatient = patientId => ({
  types: [
    patientsConstants.PATIENT_FETCH,
    patientsConstants.PATIENT_FETCH_SUCCESS,
    patientsConstants.PATIENT_FETCH_FAIL,
  ],
  promise: () => patientsApi.fetchPatient(patientId),
  patientId: parseInt(patientId, 10),
});

export const searchPatients = (q, api) => ({
  types: [
    patientsConstants.PATIENTS_SEARCH,
    patientsConstants.PATIENTS_SEARCH_SUCCESS,
    patientsConstants.PATIENTS_SEARCH_FAIL,
  ],
  promise: () => api(q),
  q,
});

export const findPatientsOnSearchTab = q => async (dispatch) => {
  const { body } = await dispatch(searchPatients(q, patientsApi.findOnSearchTab));

  const promises = body.map((patient) => {
    const { imageName, id } = patient;
    if (imageName) {
      return Promise.resolve(dispatch(fetchPatientImage(imageName, id)));
    }
    return Promise.resolve();
  });

  await Promise.all(promises);
};

export const findPatientsOnPatientsTab = q => (dispatch) => {
  dispatch(searchPatients(q, patientsApi.findOnPatientsTab));
};

export const updatePatient = (patientToSend, localPatient) => ({
  types: [
    patientsConstants.PATIENT_UPDATE,
    patientsConstants.PATIENT_UPDATE_SUCCESS,
    patientsConstants.PATIENT_UPDATE_FAIL,
  ],
  promise: () => patientsApi.updatePatient(patientToSend),
  patientId: patientToSend.id,
  localPatient,
});

export const createPatient = (patientToSend, localPatient) => ({
  types: [
    patientsConstants.PATIENT_CREATE,
    patientsConstants.PATIENT_CREATE_SUCCESS,
    patientsConstants.PATIENT_CREATE_FAIL,
  ],
  promise: () => patientsApi.createPatient(patientToSend),
  localPatient,
});

export const toggleDrugAllergies = (patientId, value, isDrChronoAttached) => ({
  types: [
    patientsConstants.TOGGLE_DRUG_ALLERGIES,
    patientsConstants.TOGGLE_DRUG_ALLERGIES_SUCCESS,
    patientsConstants.TOGGLE_DRUG_ALLERGIES_FAIL,
  ],
  promise: async (dispatch, getState) => {
    await patientsApi.toggleDrugAllergies(patientId, value);

    try {
      const allItems = getActiveAndInactiveAllergies(getState().patientsHx, patientId);
      const noKnownAllergyEntities = !isEmpty(allItems) ? allItems.filter(byPresentNoKnownAllergy) : null;

      const isNeedRemoveNoKnownAllergyEntity = noKnownAllergyEntities !== null && !value;
      if (isNeedRemoveNoKnownAllergyEntity) {
        noKnownAllergyEntities.forEach(noKnownAllergyEntity => dispatch(deleteAllergy(noKnownAllergyEntity)));
        dispatch(deleteAllergy(noKnownAllergyEntities[0]));
      }
      const isNeedCreateNoKnownAllergy = isDrChronoAttached && value;
      if (isNeedCreateNoKnownAllergy) {
        const { body } = await findOrCreateAllergyByName(FMSS_NO_KNOWN_ENTITY.ALLERGY);
        const { id: allergyId } = body;
        dispatch(createAllergy({
          active: true,
          allergy: { id: allergyId, name: FMSS_NO_KNOWN_ENTITY.ALLERGY_DR_CHRONO },
          patientId: stringParser(patientId),
          reactions: null,
          chartId: null,
          description: '',
          onset: null,
          severity: null,
          drug: null,
        }));
      }
    } catch (e) {
      return Promise.resolve(e);
    }

    return Promise.resolve();
  },
  patientId,
  hasNoDrugAllergies: value,
});

export const togglePertinentFamilyHx = (patientId, value) => ({
  types: [
    patientsConstants.TOGGLE_PERTINENT_FAMILY_MEDICAL,
    patientsConstants.TOGGLE_PERTINENT_FAMILY_MEDICAL_SUCCESS,
    patientsConstants.TOGGLE_PERTINENT_FAMILY_MEDICAL_FAIL,
  ],
  promise: () => patientsApi.togglePertinentFamilyHx(patientId, value),
  patientId,
  hasNoPertinentFamily: value,
});

export const togglePertinentMedicalHx = (patientId, value) => ({
  types: [
    patientsConstants.TOGGLE_PERTINENT_MEDICAL_HX,
    patientsConstants.TOGGLE_PERTINENT_MEDICAL_HX_SUCCESS,
    patientsConstants.TOGGLE_PERTINENT_MEDICAL_HX_FAIL,
  ],
  promise: () => patientsApi.togglePertinentMedicalHx(patientId, value),
  patientId,
  hasNoPertinentMedical: value,
});

export const togglePreviousSurgeries = (patientId, value) => ({
  types: [
    patientsConstants.TOGGLE_PREVIOUS_SURGERIES,
    patientsConstants.TOGGLE_PREVIOUS_SURGERIES_SUCCESS,
    patientsConstants.TOGGLE_PREVIOUS_SURGERIES_FAIL,
  ],
  promise: () => patientsApi.togglePreviousSurgeries(patientId, value),
  patientId,
  hasNoPreviousSurgeries: value,
});

export const addRecentPatient = (patientId) => {
  return {
    type: patientsConstants.PATIENT_ADD_TO_RECENT,
    payload: {
      patientId,
    },
  };
};

export const setPatientForChart = (activePatient) => ({
  type: patientsConstants.SET_PATIENT_FOR_CHART,
  payload: {
    ...activePatient
  }
});

export const goToChartFromCalendar = ({ patientId, chartId }) => async (dispatch, getState) => {
  const existedPatient = getState().patients.byId[patientId];

  if (existedPatient !== null) {
    await dispatch(setPatientForChart(existedPatient));
  }

  // dispatch(push(`/app/doctor/charts/${chartId}?step=1`));
};

export const clearPatientsSearch = () => ({
  type: patientsConstants.PATIENTS_SEARCH_QUERY_CLEAR,
});

export const consentsListAction = ({ patientId, consents, ids }) => ({
  type: patientsConstants.PATIENTS_LIST_CONSENTS,
  payload: {
    patientId,
    consents,
    ids
  }
});

export const addConsentAction = ({ patientId, consent, consentId }) => ({
  type: patientsConstants.ADD_PATIENT_CONSENT,
  payload: {
    patientId,
    consent,
    consentId
  }
});

export const deletePatientConsentAction = ({ id, patientId }) => ({
  type: patientsConstants.DELETE_PATIENT_CONSENT,
  payload: { id, patientId }
});
