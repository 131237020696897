import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import UserContainer from 'containers/Users/UserContainer';

import UserForm from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm';
import UsersStartPage from 'routes/routes/App/routes/Settings/routes/Users/components/UsersStartPage';

import UsersRouteParamsManager from 'helpers/users/UsersRouteParamsManager';

const User = UserContainer(UserForm);

const UserRouteWrapper = (props) => {
  const [searchParams] = useSearchParams();
  const { showModal } = props;

  const usersRouteParamsManager = new UsersRouteParamsManager(Object.fromEntries(searchParams));

  const isDisplayUserOnRightSide = usersRouteParamsManager.getIsDisplayUserOnRightSide();
  const userId = usersRouteParamsManager.getUserId;

  if (isDisplayUserOnRightSide) {
    return (
      <User
        userId={userId && parseInt(userId)}
        showModal={showModal}
      />
    );
  }

  return (
    <UsersStartPage />
  );
};

UserRouteWrapper.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default UserRouteWrapper;
