import { combineReducers } from 'redux';

import auth from './auth';
import info from './info';
import permissions from './permissions';
import isFetched from './isFetched';
import isFetching from './isFetching';
import isDownloadingSignature from './isDownloadingSignature';

export default combineReducers({
  auth,
  info,
  permissions,
  isFetched,
  isFetching,
  isDownloadingSignature,
});
