import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { build } from 'helpers/URLUtils';

const navigateWrapper = (path, navigationFunc) => {
  if (typeof path === 'string') {
    navigationFunc(path);
  } else if (typeof path === 'object') {
    navigationFunc(build(path));
  }
};

export const withRouter = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const extraProps = {
    router: {
      push: path => navigateWrapper(path, navigate),
      replace: path => navigateWrapper(path, navigate),
      getCurrentLocation: () => ({ pathname: location.pathname })
    },
    location: {
      ...location,
      query: Object.fromEntries(searchParams)
    }
  };

  return <Component {...props} {...extraProps}  />;
};