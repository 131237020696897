import React, { useContext, useState } from 'react';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ChartReportElementWithTooltip from 'components/ChartReport/ChartReportElementWithTooltip';
import RemoveButton from 'components/ChartReport/ChartReportElementWithTooltip/RemoveButton';
import DoorButton from 'components/ChartReport/ChartReportElementWithTooltip/DoorButton';

import { DATE_FORMATS } from 'helpers/date/constants';

const MENU_BUTTON_DIAMETER = 40;

const ChartReportScreeningItem = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openMenu = () => setIsMenuOpen(true);

  const closeMenu = () => setIsMenuOpen(false);

  const {
    screeningItem,
    toggleChartReport,
    chartId,
    onRemove,
    viewMode,
  } = props;

  let elementName = screeningItem.screeningDetail.name;

  const {
    timeZoneDateConverter
  } = useContext(AppContext);

  if (screeningItem.startDate) {
    elementName += ` (${timeZoneDateConverter.getFormattedDateWithTimeZone(screeningItem.startDate, DATE_FORMATS.DEFAULT)})`;
  }

  return (
    <ChartReportElementWithTooltip
      isOpen={isMenuOpen}
      onOpen={openMenu}
      onClose={closeMenu}
      disabled={viewMode}
    >
      <span dangerouslySetInnerHTML={{ __html: elementName }} />
      <div className="flex-container">
        <DoorButton
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
            marginRight: 10,
          }}
          link={{ pathname: `/app/doctor/charts/${chartId}/fmss/screening`, query: { screening: screeningItem.id } }}
          onClick={() => {
            closeMenu();
            toggleChartReport();
          }}
        />
        <RemoveButton
          onClick={onRemove}
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
          }}
        />
      </div>
    </ChartReportElementWithTooltip>
  );
};

export default ChartReportScreeningItem;
