import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

import { ChartContext } from 'routes/routes/App/routes/Charts/components/Chart/context';
import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import { withRouter } from 'containers/RouterParams';

import chartContainer, { fetchLayerHOC as chartFetchLayerHOC } from 'containers/ChartContainer';
import ChartReport from 'containers/ChartReportContainer';

import Tabs from 'components/Tabs';
import PersonShortInfo from 'components/PersonShortInfo';
import Loader from 'components/Loader';
import ChartReportLink from 'components/ChartReportLink';

import { isNullOrUndefined } from 'helpers';
import { formatParamsWithWhiteList } from 'helpers/charting/charting';
import { chartSubElementCreate } from 'helpers/charting/whiteListProps';
import stringParser from 'helpers/common/string/string-parser';

import cx from './Chart.module.scss';

const fmsCompletionStatus = ({ isSocialCompletionReady }) =>
  `${cx['fms-completion']} ${isSocialCompletionReady ? cx.success : cx.warning}`;


const createTabsHeader = ({
  chart, chartId, permissions, socialCompletion, selectUserDateWithTimeZone,
}) => (
  [
    {
      title: 'H&P Encounter Note',
      subtitle: chart?.selectUserDate && selectUserDateWithTimeZone,
      url: `/app/doctor/charts/${chartId}?step=1`,
      indexLink: true,
      disabled: !permissions.viewEditEncounterNote && !permissions.viewEditPatientClinicalInformation,
    },
    {
      title: 'Encounters Hx',
      url: `/app/doctor/charts/${chartId}/encounters`,
      disabled: !permissions.viewEditPatientClinicalInformation,
    },
    {
      title: 'Demographics',
      url: `/app/doctor/charts/${chartId}/demographics`,
      disabled: !permissions.viewEditPatientClinicalInformation,
    },
    {
      title: 'Medication List',
      url: `/app/doctor/charts/${chartId}/medications`,
      disabled: !permissions.viewEditPatientClinicalInformation,
    },
    {
      title: 'Allergy List',
      url: `/app/doctor/charts/${chartId}/allergies`,
      disabled: !permissions.viewEditPatientClinicalInformation,
    },
    {
      title: 'F/M/S/S/S Hx',
      url: `/app/doctor/charts/${chartId}/fmss`,
      mark: socialCompletion && !socialCompletion.isReady,
      disabled: !permissions.viewEditPatientClinicalInformation,
      subtitle: (
        <span
          className={fmsCompletionStatus({ isSocialCompletionReady: socialCompletion?.isReady })}
        >
          {socialCompletion && `${socialCompletion.progress  }%`}
        </span>
      ),
    },
    {
      title: 'diagnosis List',
      url: `/app/doctor/charts/${chartId}/diagnoses`,
      disabled: !permissions.viewEditPatientClinicalInformation,
    },
  ]
);

class Chart extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      lastQuery: null,
    };
  }

  componentDidMount() {
    const {
      patient,
      addRecentPatient,
      chart,
      router,
    } = this.props;

    const {
      id,
    } = patient;

    if (id) {
      addRecentPatient(id);
    }

    if (
      (chart.signed)
    ) {
      router.push('/app/doctor');
    }
  }


  componentDidUpdate(prevProps) {
    const {
      patient,
      chartId,
      router,
      chart,
    } = prevProps;

    const {
      patient: updatedPatient,
      chart: updatedChart,
      chartId: updatedChartId,
      addRecentPatient
    } = this.props;

    const {
      id,
    } = patient;

    if (id !== updatedPatient.id && updatedPatient.id) {
      addRecentPatient(updatedPatient.id);
    }

    if (chartId !== updatedChartId) {
      this.updateLastQuery('');
    }

    if (
      (typeof chart.signed === 'undefined' || !chart.signed)
      && updatedChart.signed
      && typeof updatedPatient.id !== 'undefined'
    ) {
      router.push({ pathname: `/app/doctor/patients/${updatedPatient.id}/encounters` });
    }
  }

  updateLastQuery = lastQuery => this.setState({ lastQuery });

  createSubelementProcess = async (subElement) => {
    const {
      createSubelement,
      chartingsDetailsUpdateSystemAfterCreatingSubElement,
      chartId,
      patient,
      location,
    } = this.props;

    const {
      id: patientId,
    } = patient;

    const { query } = location;
    const { system } = query;
    const parsedSystemId = stringParser(system);

    const mapDataForRequest = formatParamsWithWhiteList(subElement, chartSubElementCreate);

    const createSublementResponse = await createSubelement(mapDataForRequest);
    const { body } = createSublementResponse;
    const newSubElement = {
      ...subElement,
      id: body,
      favoriteId: body
    };

    chartingsDetailsUpdateSystemAfterCreatingSubElement(newSubElement, chartId, parsedSystemId, patientId);

    return createSublementResponse;
  };

  reloadButtonClick = () => {
    const { fetchChart, chartId } = this.props;
    fetchChart(chartId);
  };

  render() {
    const {
      children,
      chartId,
      chart,
      patient,
      toggleChartReport,
      socialCompletion,
      isReadOnly,
      isSystemSaving,
      isChartReportVisible,
    } = this.props;

    const { lastQuery } = this.state;

    const activeRequest = chart.isSaving || isSystemSaving;

    const {
      timeZoneDateConverter,
      permissions
    } = this.context;

    const selectUserDateWithTimeZone = timeZoneDateConverter.getFormattedDateWithTimeZone(chart.selectUserDate);

    let tabsHead = createTabsHeader({
      chart, chartId, permissions, socialCompletion, selectUserDateWithTimeZone,
    });

    if (isReadOnly) {
      tabsHead = tabsHead.map(item => ({ ...item, disabled: true }));
    }

    if (isNullOrUndefined(chart.isFetching) || chart.isFetching) {
      return <Loader />;
    }

    if (
      (chart.error && (chart.error.status === 404 || chart.error.status === 204))
        || chart.deleted
    ) {
      return <div className={cx['error-wrapper']}>Chart is deleted or does not exist</div>;
    }

    if (chart.error) {
      return (
        <div className={cx['error-wrapper']}>
          <div className="text-center">
            {chart.error.message || 'And error occured'}
            <br />
            <button
              type="button"
              className="generic-button"
              onClick={this.reloadButtonClick}
            >
              Reload
            </button>
          </div>
        </div>
      );
    }

    return (
      <ChartContext.Provider value={{
        lastQuery,
        updateLastQuery: this.updateLastQuery,
        createSubelementProcess: this.createSubelementProcess,
      }}>
        <div className={cx.wrapper}>
          {activeRequest && <div className={cx['loader-overlay']}><Loader /></div>}
          <div> {/* when we switch between tabs header-wrapper is jumping around without this div, no idea why, something to do with flexbox */}
            <div className={cx['header-wrapper']}>
              <PersonShortInfo
                person={patient}
              />
              {permissions.viewEditEncounterNote && (
                <ChartReportLink onClick={toggleChartReport} />
              )}
            </div>
          </div>
          <div className={cx.tabsWrapper}>
            <Tabs headerItems={tabsHead}>
              {children}
            </Tabs>
          </div>
          {isChartReportVisible && chartId && (
            <ChartReport
              chartId={chartId}
            />
          )}
        </div>
      </ChartContext.Provider>

    );
  }
}

Chart.contextType = AppContext;

Chart.propTypes = {
  children: PropTypes.node,
  chartId: PropTypes.string,
  chart: PropTypes.object,
  patient: PropTypes.object.isRequired,
  toggleChartReport: PropTypes.func.isRequired,
  fetchChart: PropTypes.func.isRequired,
  socialCompletion: PropTypes.object,
  isReadOnly: PropTypes.bool,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      system: PropTypes.string
    })
  }).isRequired,
  addRecentPatient: PropTypes.func.isRequired,
  createSubelement: PropTypes.func.isRequired,
  chartingsDetailsUpdateSystemAfterCreatingSubElement: PropTypes.func.isRequired,
};

const ChartWithData = compose(
  chartContainer,
  chartFetchLayerHOC,
)(Chart);

const Wrapper = ({
  router,
  location,
}) => {
  const { chartId } = useParams();
  return (
      <ChartWithData
          chartId={chartId}
          location={location}
          router={router}
      >
        <Outlet context={{ chartId }} />
      </ChartWithData>
  );
};


export default withRouter(Wrapper);
