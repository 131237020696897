export const VERIFIED_EMAIL_STATUS = {
  empty: '',
  pending: 'Pending',
};

export const USERS_ROUTE_PARAMS = {
  formType: {
    add: 'add',
    invite: 'invite',
  },
  section: {
    permissions: 'permissions',
  },
};

export const LINK_TO_NEW_USER_FORM = `/app/doctor/settings/users?formType=${USERS_ROUTE_PARAMS.formType.add}`;

export const LINK_TO_ACTIVE_USER = '/app/doctor/settings/users';

export const LINK_TO_INACTIVE_USER = '/app/doctor/settings/users/inactive';
