import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from 'modules/charts/actions';
import {
  fetchChartingAssets,
  createSubelement,
} from 'modules/chartingAssets/actions';
import { isChartReportVisible, toggleChartReport } from 'modules/chartReportVisible';
import {
  saveChart,
  chartingsDetailsUpdateSystemAfterCreatingSubElement,
} from 'modules/chartingSessions/actions';
import { addRecentPatient, setPatientForChart } from 'modules/patients/actions';

import { getChartById, getIsCopyingPreviousNote } from 'modules/charts/selectors';
import { getActivePatient, getPatientById } from 'modules/patients/selectors';
import { getStatus, getIsSystemSaving } from 'modules/chartingAssets/selectors';

import {
  getFlattenedElementsForSave,
  getChartCCString,
  getChartCompletionPercentage,
  getCompletionForStepsFromState,
} from 'modules/rootSelectors';

export const fetchLayerHOC = Component => {
  class ChartFetchLayer extends React.Component {
    componentDidMount() {
      if (!this.props.isChartFetched && !this.props.isChartFetching) {
        this.fetchChartWrapper(this.props.chartId);
      } else {
        const { cachedChartPatient, setPatientForChart } = this.props;

        setPatientForChart(cachedChartPatient);
      }

      if (!this.props.areAssetsFetched && !this.props.areAssetsFetching) {
        this.props.fetchChartingAssets();
      }

      if (!this.props.chartAssetsFetched && !this.props.chartAssetsFetching) {
        this.props.fetchChartingAssets(this.props.chartId);
      }
    }

    componentDidUpdate(prevProps) {
      const {
        chartId,
        isChartFetching,
        isChartFetched,
        chartAssetsFetched,
        chartAssetsFetching,
      } = this.props;

      if (
        chartId // chart id present
        && (prevProps.chartId !== chartId) // chart has changed
        && !isChartFetching // chart is not fetching
        && !isChartFetched // chart is not fetched
      ) {
        this.fetchChartWrapper(chartId);

        if (!chartAssetsFetched && !chartAssetsFetching) {
          this.props.fetchChartingAssets(chartId);
        }
      }
    }

    fetchChartWrapper = (id) => {
      if (!id) return;
      const { fetchChart } = this.props;
      fetchChart(id);
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  ChartFetchLayer.propTypes = {
    isChartFetching: PropTypes.bool,
    isChartFetched: PropTypes.bool,
    chartId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    areAssetsFetched: PropTypes.bool.isRequired,
    areAssetsFetching: PropTypes.bool.isRequired,
    chartAssetsFetched: PropTypes.bool.isRequired,
    chartAssetsFetching: PropTypes.bool.isRequired,
    fetchChartingAssets: PropTypes.func.isRequired,
    saveChart: PropTypes.func.isRequired,
    fetchChart: PropTypes.func.isRequired,
    chartingSessionElements: PropTypes.array.isRequired,
    chart: PropTypes.shape({
      signed: PropTypes.bool,
      cptCode: PropTypes.object,
    }).isRequired,
    containers: PropTypes.array.isRequired,
    diagnosesSystemElements: PropTypes.array.isRequired,
    medicationsSystemElements: PropTypes.array.isRequired,
    completion: PropTypes.number,
    isCopyingPreviousNote: PropTypes.bool,
  };

  ChartFetchLayer.defaultProps = {
    isChartFetching: false,
    isChartFetched: false,
  };

  return ChartFetchLayer;
};

const mapStateToProps = (state, ownProps) => {
  const { chartId } = ownProps;

  const chart = getChartById(state.charts, chartId);
  const patient = getActivePatient(state.patients);
  const cachedChartPatient = getPatientById(state.patients, chart.patientId);
  const isChartFetching = chart?.isFetching;
  const isChartFetched = chart?.isFetched;
  const isChartFetchedPartially = chart?.isFetchedPartially;
  const isChartSaving = chart?.isSaving || false;
  const ccString = getChartCCString(state, chartId);
  const completion = getChartCompletionPercentage(state, chartId);

  const assetsStatus = getStatus(state.chartingAssets);
  const areAssetsFetching = assetsStatus.isFetching;
  const areAssetsFetched = assetsStatus.isFetched;

  const chartAssetsStatus = getStatus(state.chartingAssets, chartId);
  const chartAssetsFetching = chartAssetsStatus.isFetching;
  const chartAssetsFetched = chartAssetsStatus.isFetched;

  const socialCompletion = getCompletionForStepsFromState(state, chartId);
  const isCopyingPreviousNote = getIsCopyingPreviousNote(state.charts, chartId);

  const isSystemSaving = getIsSystemSaving(state.chartingAssets);

  return {
    ...ownProps,
    chart,
    patient,
    cachedChartPatient,
    isChartFetching,
    isChartFetched,
    isChartFetchedPartially,
    isChartSaving,
    isSystemSaving,
    ...getFlattenedElementsForSave(state, chartId),
    completion,
    ccString,
    socialCompletion: socialCompletion && socialCompletion[0],
    areAssetsFetched,
    areAssetsFetching,
    chartAssetsFetching,
    chartAssetsFetched,
    isCopyingPreviousNote,
    isChartReportVisible: isChartReportVisible(state.chartReportVisible),
  };
};

const mapActionCreators = {
  ...actions,
  toggleChartReport,
  saveChart,
  addRecentPatient,
  setPatientForChart,
  fetchChartingAssets,
  createSubelement,
  chartingsDetailsUpdateSystemAfterCreatingSubElement,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
