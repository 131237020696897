import React from 'react';
import PropTypes from 'prop-types';

import ValidateMaskInput from 'components/ValidateMaskInput';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './TextInput.module.scss';

const textInputStyles = resolveStylesV2({
  objectStyles:cx,
  moduleStyles: 'title',
  globalStyles: 'section-title'
})

const baseInputStyles = () => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: 'input'
})

export default class TextInput extends React.Component {
  static defaultProps = {
    type: 'text',
  }

  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    mask: PropTypes.string,
  }

  render() {
    const {
      title,
      onChange,
      value,
      name,
      type,
      disabled,
      mask,
    } = this.props;

    return (
      <div className={cx.container}>
        {title && <h3 className={textInputStyles}>{title}</h3>}

        <div className={cx.wrapper}>
          {!mask && (
            <input
              type={type}
              name={name}
              onChange={onChange}
              value={value}
              className={baseInputStyles()}
              disabled={disabled}
            />
          )}
          {mask && (
            <ValidateMaskInput
              type={type}
              name={name}
              mask={mask}
              onChange={onChange}
              value={value}
              className={baseInputStyles()}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    );
  }
}
