import { handleActions } from 'redux-actions';

import { SYSTEM_CREATE_SUCCESS, SYSTEM_DELETE_SUCCESS, SYSTEM_UPDATE_SUCCESS } from 'modules/chartingAssets/constants';
import { SAVE_CHARTS_DETAILS_SUCCESS } from 'modules/charts/constants';

const initialState = {
  specialSystems: [],
};

const specialSystemMap = specialSystem => ({
  id: specialSystem.id,
  chartingId: specialSystem.chartingId,
  name: specialSystem.name
});

const removeBySystemIdFilter = (system, removingSystemId) => system.id !== removingSystemId;

export default handleActions({
  [SYSTEM_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    specialSystems: [
      ...state.specialSystems,
      specialSystemMap(action.payload.result),
    ],
  }),
  [SYSTEM_UPDATE_SUCCESS]: (state, action) => {
    const nextState = { ...state }

    const index = nextState.specialSystems.findIndex(ss => ss.id === action.payload.result.id)

    if (index !== -1) {
      nextState.specialSystems[index] = specialSystemMap(action.payload.result)
    }

    return nextState
  },
  [SAVE_CHARTS_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    specialSystems: [
      ...action.payload.specialSystems.map(specialSystemMap),
    ],
  }),
  [SYSTEM_DELETE_SUCCESS]: (state, action) => ({
    ...state,
    specialSystems: [
      ...state.specialSystems.filter(system =>
        removeBySystemIdFilter(system, action.payload.systemId)
      ),
    ],
  }),
}, initialState);
