import { UserTab } from './routes/User/components/User';
import { UserManager } from './routes/User/routes/Manager/components/Manager';
import { AdminClinicTab } from './routes/Clinic/components';
import { Clinic } from './routes/Clinic/routes/Create/Clinic';
import { ClinicManager } from './routes/Clinic/routes/Manager';
import { AdminService } from './routes/Service/AdminService';
import { LazyLoading } from 'containers/lazy-loading';

export const AdminRoute = {
  path: 'admin',
  element: <LazyLoading load={import('components/Admin')} />,
  children: [
    {
      element: <LazyLoading load={import('./routes/Notice/components/Notice')} />,
      children: [
        { index: true, element: <LazyLoading load={import('./routes/Notice/routes/RestartServer/Notice/Notice')} /> },
        { path: 'notification', element: <LazyLoading load={import('./routes/Notice/routes/UserMessage/UserMessage')} /> }
      ]
    },
    {
      path: 'user',
      element: <UserTab />,
      children: [
        { index: true, element: <UserManager /> }
      ]
    },
    {
      path: 'clinic',
      element: <AdminClinicTab />,
      children: [
        { index: true, element: <Clinic /> },
        { path: 'manager', element: <ClinicManager /> }
      ]
    },
    {
      path: 'service',
      element: <AdminService />
    }
  ]
};
