import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PatientHistoryItemsTableContainer from 'containers/PatientHistoryItemsTable';

import { transformBold } from 'helpers/chart';
import { DATE_FORMATS } from 'helpers/date/constants';
import DateDisplaying from 'helpers/date/displaying';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import fields from './fields';

import cx from './MedicationsTable.module.scss';

const tableStyles = ({item, selectedId}) => ({
  row: cssClassesResolver([
    cx.item, item.id === selectedId && cx['item--active']
  ])
})

const MedicationsTable = (props) => {
  const {
    items,
    selectedId,
    showMedicationById,
    filter,
    error,
    onReload,
  } = props;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const formatUseTimeField = (date) => {
    return date && timeZoneDateConverter.getFormattedDateWithTimeZone(date, DATE_FORMATS.DOB);
  };

  return (
    <PatientHistoryItemsTableContainer
      items={items}
      defaultDesc={false}
      defaultSortBy="medication"
      mapItemToField={item => ({
        id: item.id,
        medication: item.medication.name,
        instructions: item.description,
        'use time': DateDisplaying.display(formatUseTimeField(item.startDate), formatUseTimeField(item.endDate)),
      })}
      error={error}
      onReload={onReload}
      fields={fields}
      itemRenderer={item => (
        <tr
          className={tableStyles({item, selectedId}).row}
          key={item.id}
          onClick={() => showMedicationById(item.id)}
        >
          <td>
            <span
              className={cx.name}
              dangerouslySetInnerHTML={{ __html: transformBold(item.medication) }}
            />
          </td>
          <td>{item.instructions}</td>
          <td>{item['use time']}</td>
        </tr>
      )}
      emptyMessage={`No ${filter} Medications`}
    />
  );
};

MedicationsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    medication: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  })).isRequired,
  showMedicationById: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  selectedId: PropTypes.number,
  onReload: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default MedicationsTable;
