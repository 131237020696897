const fetchAllMapValues = (map) => {
  const values = [];
  map.forEach((value) => {
    values.push(value);
  });

  return values;
};

export default fetchAllMapValues;
