import React from 'react';
import PropTypes from 'prop-types';
import ChartReportVisibilityLayer from '../ChartReportVisibilityLayer';
import ChartReportFetchLayer from '../ChartReportFetchLayer';
import ChartReport from 'components/ChartReport';

const ChartReportComposer = props => (
  <ChartReportVisibilityLayer
    isReportVisible={props.isReportVisible}
    toggleChartReport={props.toggleChartReport}
  >
    <ChartReportFetchLayer
      ready={props.ready}
      chartId={props.chart.id}
      patientId={props.patient.id}
      assetsStatus={props.assetsStatus}
      fetchChartingAssets={props.fetchChartingAssets}
      medicationsStatus={props.medicationsStatus}
      fetchMedications={props.fetchMedications}
      diagnosesStatus={props.diagnosesStatus}
      fetchDiagnoses={props.fetchDiagnoses}
      allergiesStatus={props.allergiesStatus}
      fetchAllergies={props.fetchAllergies}
      familyStatus={props.familyStatus}
      fetchFamily={props.fetchFamily}
      medicalStatus={props.medicalStatus}
      fetchMedical={props.fetchMedical}
      surgicalStatus={props.surgicalStatus}
      fetchSurgical={props.fetchSurgical}
      screeningStatus={props.screeningStatus}
      fetchScreening={props.fetchScreening}
      vitalsStatus={props.vitalsStatus}
      fetchVitals={props.fetchVitals}
      socialStatus={props.socialStatus}
      fetchPatientSocial={props.fetchPatientSocial}
      socialAssetsStatus={props.socialAssetsStatus}
      fetchSystems={props.fetchSystems}
      clinicStatus={props.clinicStatus}
      fetchClinicInfo={props.fetchClinicInfo}
      systemNotesStatus={props.systemNotesStatus}
      fetchChartSystemNotes={props.fetchChartSystemNotes}
    >
      <ChartReport {...props} />
    </ChartReportFetchLayer>
  </ChartReportVisibilityLayer>
);

ChartReportComposer.propTypes = {
  isReportVisible: PropTypes.bool.isRequired,
  toggleChartReport: PropTypes.func.isRequired,
};

export default ChartReportComposer;
