import React, { useRef, useState } from 'react';

import Tether from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import Tooltip from '../../../../ChartReportElementWithTooltip/Tooltip';
import RemoveButton from '../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../ChartReportElementWithTooltip/DoorButton';

import * as allergiesHelpers from 'helpers/fmss/allergies/allergies';

import cx from './ChartReportAllergy.module.scss';

const MENU_BUTTON_DIAMETER = 40;

const ChartReportAllergy = (props) => {
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(prev => !prev);

  const dropdownRef = useClickOutSide({ onOutsideClick: toggleMenu, exclude: [inputRef] });

  const renderReactionItem = (reactions) => {
    const sortedReactions = allergiesHelpers.sortAllergyReactions(reactions);

    return (
      sortedReactions.map(reaction => (
        <div
          className={cx.reaction}
          key={reaction.id}
        >
          {reaction.name}
        </div>
      ))
    );
  };

  const renderRow = ({ ref, allergy, clickHandler }) => {
    inputRef.current = ref.current;
    return (
      <tr
        ref={ref}
        className={cx.allergy}
        onClick={clickHandler}
      >
        <td>{allergy.allergy.name}</td>
        <td>
          {allergy.reactions && renderReactionItem(allergy.reactions)}
        </td>
      </tr>
    );
  };

  const {
    allergy, toggleChartReport, onRemove, chartId, viewMode,
  } = props;

  if (viewMode) {
    return renderRow({ allergy });
  }

  return (
    <Tether
      attachment="bottom center"
      targetAttachment="top center"
      constraints={[{
        to: 'scrollParent',
        attachment: 'together',
      }]}
      classPrefix="reportElement"
      renderTarget={targetRef => (
        renderRow({ ref: targetRef, allergy, clickHandler: toggleMenu })
      )}
      renderElement={elementRef => isOpen && (
        <span ref={dropdownRef}>
          <Tooltip
            ref={elementRef}
          >
            <DoorButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
                marginRight: 10,
              }}
              onClick={() => {
                toggleMenu();
                toggleChartReport();
              }}
              link={`/app/doctor/charts/${chartId}/allergies?allergy=${allergy.id}`}
            />
            <RemoveButton
              style={{
                width: MENU_BUTTON_DIAMETER,
                height: MENU_BUTTON_DIAMETER,
              }}
              onClick={onRemove}
            />
          </Tooltip>
        </span>
      )}
    />
  );
};

export default ChartReportAllergy;
