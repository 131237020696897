import moment from 'moment';

export default class EstimateTime {
  static calculateEstimateTime(expireDateLocking, timeZoneDateConverter) {
    if (expireDateLocking !== null) {
      const currentTime = timeZoneDateConverter.transformCurrentDate();
      const momentExpireDateLocking = timeZoneDateConverter.transformByDate(expireDateLocking);
      const hoursLeft = moment.duration(momentExpireDateLocking.diff(currentTime)).asHours();

      return moment.duration(hoursLeft, 'hours').humanize();
    }

    return '';
  }
}
