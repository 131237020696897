import { handleActions } from 'redux-actions';
import * as constants from '../../../constants';

import { handleResponseFromCreatingHistoryItem } from 'modules/patientsHx/byId';

export const initialState = {
  isFetching: undefined,
  items: [],
  isInitialized: false,
  error: null,
};

const deleteItem = (state, itemId) => ({
  ...state,
  items: state.items.filter(_itemId => _itemId !== itemId),
});

const addItem = (state, itemId) => {
  const { id } = handleResponseFromCreatingHistoryItem(itemId);

  if (state.items.includes(id)) return state;

  return {
    ...state,
    items: [...state.items, id],
  };
};

const handlers = {
  [constants.HISTORY_ITEMS_FETCH]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.HISTORY_ITEMS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload.result.map(item => item.id),
    isInitialized: true,
    error: null,
  }),
  [constants.HISTORY_ITEMS_FETCH_FAIL]: (state, action) => ({
    ...state,
    isFetching: false,
    isInitialized: true,
    error: {
      status: action.error.status,
      message: action.error.message,
    },
  }),
  // if item updated from active to inactive and vise versa it should be added to appropriate list
  // that's why we add item on update
  [constants.HISTORY_ITEM_UPDATE_SUCCESS]: (state, action) => {
    return addItem(state, action.payload.item.id);
  },
  [constants.HISTORY_ITEM_CREATE_SUCCESS]: (state, action) => {
    return addItem(state, action.payload.result);
  },
  [constants.HISTORY_ITEM_DELETE_FROM_LIST]: (state, action) => {
    return deleteItem(state, action.payload.itemId);
  },
  [constants.HISTORY_ITEM_DELETE]: (state, action) => {
    return deleteItem(state, action.payload.itemId);
  },
  [constants.HISTORY_ITEM_DELETE_FAIL]: (state, action) => {
    return addItem(state, action.payload.itemId);
  },
};

const reducer = handleActions(handlers, initialState);

// for action to reach this reducer it has to meet requirements of its parents
// and also filter in payload should be the same as filter used to create this reducer

// we are passing action === undefined to get initial state for initialization
export default (filter) => {
  if (!filter) return reducer;

  return (state = initialState, action) => {
    if (!action || filter !== action.payload.filter) return state;
    return reducer(state, action);
  };
};
