import { useOutletContext } from 'react-router-dom';
import UserEditForm from 'components/UserEditForm';
import surgicalItemContainer from 'containers/FMSS/Surgical/SurgicalItemContainer';
import SurgicalForm from 'containers/FMSS/Surgical/SurgicalForm';
import { compose } from 'redux';
import {
  FMSSBaseWrapper
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/FMS/components/FMSSBaseWrapper';
import surgicalContainer, { fetchLayerHOC } from 'containers/FMSS/Surgical/SurgicalContainer';
import SurgicalTable from 'containers/FMSS/Surgical/SurgicalTable';
import AdditionalCheckboxConnector from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxConnector';
import AdditionalEmptyMessageCheckbox from 'components/FMSSPage/AdditionalEmptyMessageCheckbox';
import { FMSS_TYPES } from 'helpers/fmss/constants';

const FormWrapper = compose(
  FMSSBaseWrapper,
  surgicalItemContainer
)(SurgicalForm);

const TableWrapper = compose(
  FMSSBaseWrapper,
  surgicalContainer,
  fetchLayerHOC
)(SurgicalTable);

const CheckboxWrapper = AdditionalCheckboxConnector(
  AdditionalEmptyMessageCheckbox, FMSS_TYPES.SURGICAL
);

export const SurgicalComponent = () => {
  const { patientId, linkBase } = useOutletContext();

  return (
    <UserEditForm.Root
      tabLinks={[
        { url: { pathname: linkBase }, name: 'Family Hx', indexLink: true },
        { url: { pathname: `${linkBase}/medical` }, name: 'Medical Hx' },
        { url: { pathname: `${linkBase}/social` }, name: 'Social Hx' },
        { url: { pathname: `${linkBase}/surgical` }, name: 'Surgical Hx' },
        { url: { pathname: `${linkBase}/screening` }, name: 'Screening Hx' },
      ]}
      formComponent={<FormWrapper searchParamKey={'surgical'} propName={'surgicalItemId'} />}
      customLabelRenderer={<CheckboxWrapper label="No Previous Surgeries" patientId={patientId} />}
    >
      <TableWrapper searchParamKey={'surgical'} propName={'surgicalItemId'} />
    </UserEditForm.Root>
  );
};

export const SurgicalRoute = {
  path: 'surgical',
  element: <SurgicalComponent />
};
