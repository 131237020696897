import {
  getDefaultPatientsForSearchTab,
  getIsSearching,
  getPatientsFromSearch,
  getPatientsFromSearchWithTrueOrder,
  getSearchQuery
} from 'modules/patients/selectors';
import {
  clearPatientsSearch,
  fetchPatientImage,
  findPatientsOnSearchTab,
  searchTabFetchPatients
} from 'modules/patients/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { patients } = state

  return {
    patients: getPatientsFromSearch(patients),
    patientsFromSearchWithTrueOrder: getPatientsFromSearchWithTrueOrder(patients),
    isSearching: getIsSearching(patients),
    searchQuery: getSearchQuery(patients),
    defaultPatients: getDefaultPatientsForSearchTab(patients)
  }
}

const mapActionCreators = {
  searchTabFetchPatients,
  clearPatientsSearch,
  searchPatients: findPatientsOnSearchTab,
  fetchPatientImage,
}

export default component => connect(mapStateToProps, mapActionCreators)(component)
