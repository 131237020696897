import { MESSAGE_TYPES } from 'helpers/notice/BannerService';

export const TITTLE_MESSAGE = 'NOTICE: EHRsynergy will be restarting...'

const SEPARATOR = '.'
export const buildSubscriptionUserUrl = ({ url, id: userId, clinicId }) =>
  `${url}${SEPARATOR}${clinicId}${SEPARATOR}${userId}`

export const ACTIONS = {
  RESTART_SERVER: 'RESTART_SERVER',
  USER_MESSAGE: 'USER_MESSAGE'
}

export const OUTPUT = {
  banner: 'banner',
  window: 'window'
}

const handler = {
  [ACTIONS.RESTART_SERVER]: ({ showModal, message }) => {
    showModal({
      message,
      title: TITTLE_MESSAGE,
      messageType: MESSAGE_TYPES.DANGER
    })
  },
  [ACTIONS.USER_MESSAGE]: ({ showModal, message, title }) => {
    showModal({
      message,
      title,
      messageType: MESSAGE_TYPES.DANGER
    })
  }
}

export default ({ eventType }) => handler[eventType]

