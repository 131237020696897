import { combineReducers } from 'redux';
import byId from './byId';
import isFetching from './isFetching';
import isUpdating from './isUpdating';

export default combineReducers({
  byId,
  isFetching,
  isUpdating,
});
