import React from 'react';
import PropTypes from 'prop-types';

const ChartReportBillingContentRow = ({ code, description, type }) => {
  return (
    <tr>
      <td>{type}</td>
      <td>{code}</td>
      <td>{description}</td>
    </tr>
  );
};

ChartReportBillingContentRow.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ChartReportBillingContentRow;
