import React from 'react';
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import cx from './DocumentHeader.module.scss';

const DocumentHeader = ({ children }) => {
  return (
    <div className={cx.document}>
      <div className={cx.header}>
        <Link
          className={cx['back-button']}
          to="/app/login"
        >
          Back
        </Link>
      </div>
      <div className={cx['document-wrapper']}>
        <div className={cx['document-container']}>
          {children}
        </div>
      </div>
    </div>
  );
};

DocumentHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DocumentHeader;
