import { handleActions } from 'redux-actions';

import * as patientsConstants from '../constants';

export default handleActions({
  [patientsConstants.PATIENTS_SEARCH]: () => true,
  [patientsConstants.PATIENTS_SEARCH_SUCCESS]: () => false,
  [patientsConstants.PATIENTS_SEARCH_FAIL]: () => false,
}, false);

export const getIsSearching = state => state;
