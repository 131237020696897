import isEmpty from 'helpers/common/array/is-empty';
import stringParser from 'helpers/common/string/string-parser';
import { createSelector } from 'reselect';

export const getIsFetching = state => state.isFetching;

export const getUsersByStatus = createSelector(
  [state => state.list, (state, active) => active],
  (list, active) => list.filter(o => o.enable === active)
);

export const getUser = (state, userId) => state.list.find(o => o.id === userId);

export const getIsCreating = state => state.isCreating;

export const getIsUpdating = state => state.isUpdating;

export const getSeatUserAssigned = (state, userId) => {
  const {
    seats,
  } = state;

  const {
    byId,
  } = seats;

  const findSeatIdUserAssigned = Object.keys(byId).filter(seatId => byId[seatId].userId === userId);

  return isEmpty(findSeatIdUserAssigned)
    ? 0
    : stringParser(findSeatIdUserAssigned[0]);
};
