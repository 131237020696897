import React from 'react';

import cx from './ChartReportSection.module.scss';

const ChartReportSection = ({ children }) => (
  <div className={cx['wrapper']}>
    {children}
  </div>
);

export default ChartReportSection;
