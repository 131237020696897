import React from 'react';
import { compose } from 'redux';
import { useOutletContext } from 'react-router';

import { useRoute } from 'hooks/useRoute';

import familyContainer, { familyFetchLayer } from 'containers/FMSS/Family/FamilyContainer';
import { FamilyTable } from 'containers/FMSS/Family/FamilyTable';

const FamilyList = compose(
  familyContainer,
  familyFetchLayer
)(FamilyTable);

export const FamilyListWrapper = () => {
  const { fromSearchParams } = useRoute();
  const { patientId } = useOutletContext();

  return (
    <FamilyList
      patientId={patientId}
      familyItemId={parseInt(fromSearchParams('family'))}
    />
  );
};
