import React from 'react';
import PropTypes from 'prop-types';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './DiagnosisList.module.scss';

const buttonStyles = ({isActive}) => resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['diagnosis', isActive && 'diagnosis--active']
})

const DiagnosesList = ({
  diagnoses,
  onSelect,
  selectedDiagnoses,
  isChartSaving = false,
}) => {

  return (
    <div className={cx.wrapper}>
      <div className={cx['inner-wrapper']}>
        {!diagnoses.length && <div className={cx['no-diagnoses-message']}>No available diagnoses</div>}
        {diagnoses.map(diagnosis => (
          <button
            type="button"
            onClick={() => onSelect(diagnosis)}
            key={diagnosis.id}
            className={buttonStyles({isActive: selectedDiagnoses.includes(diagnosis.id)})}
          >
            {diagnosis.name}
          </button>
        ))}
      </div>
      {isChartSaving && <div className={cx.overlay} />}
    </div>
  )
};

DiagnosesList.propTypes = {
  diagnoses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedDiagnoses: PropTypes.arrayOf(PropTypes.number).isRequired,
  isChartSaving: PropTypes.bool,
};

export default DiagnosesList;
