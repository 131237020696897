import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from 'components/RadioButton';

import cx from 'components/RadioButtonGroup/RadioButtonGroup.module.scss';

const RadioButtonGroup = (props) => {
  const {
    checked,
    items,
    onChange
  } = props;

  const onClick = (value) =>
    value !== checked && onChange(value)

  return (
    <>
      {items.map(
        (item) => (
          <button
            type="button"
            key={item.name}
            className={cx.item}
            onClick={() => onClick(item.value)}
          >
            <div className={cx['radio-item']}>
              <RadioButton checked={checked === item.value} />
            </div>
            <span className={cx.label}>{item.name}</span>
          </button>
        )
      )}
    </>
  );
}

RadioButtonGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
  })).isRequired,
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
};

export default RadioButtonGroup;
