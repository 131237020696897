import { handleActions } from 'redux-actions';

import keyBy from 'lodash/keyBy';

import * as autoOpenSubElementsModalHelpers from 'helpers/autoOpenSubElementsModalHelpers';

import * as constants from './constants';

const initialState = {};

export default handleActions({
  [constants.ADD_INDEX_TO_AUTO_SET]: (state, action) => {
    const {
      elementId,
      indexToAutoSet,
    } = action.payload;

    const item = autoOpenSubElementsModalHelpers.createItem(elementId, indexToAutoSet);

    return {
      ...state,
      ...keyBy([item], 'elementId'),
    };
  },
  [constants.RESET_INDEX_TO_AUTO_SET]: (state, action) => {
    const nextState = { ...state };

    const {
      elementId,
      indexToReset,
    } = action.payload;

    if (typeof nextState[elementId] === 'undefined') return nextState;
    
    let newIndexesToAutoSet = nextState[elementId].indexToAutoSet;
    newIndexesToAutoSet = newIndexesToAutoSet.filter(index => index !== indexToReset);

    nextState[elementId].indexToAutoSet = newIndexesToAutoSet;

    return nextState;
  },
}, initialState);
