import { connect } from 'react-redux';
import React from 'react';
import * as actions from 'modules/patientsHx/actions';
import * as constants from 'modules/patientsHx/constants';
import FetchLayer, { getMapStateToProps } from '../PatientHistoryItem/PatientHistoryItemFetchLayerHOC';

const allergyContainer = Component => props => (
  <FetchLayer
    itemId={props.allergyId}
    fetchItem={props.fetchAllergy}
    status={props.status}
  >
    <Component {...props} />
  </FetchLayer>
);

const mapStateToProps = getMapStateToProps(constants.ALLERGIES, 'allergy', 'allergyId');

const mapActionCreators = {
  fetchAllergy: actions.fetchAllergy,
};

export default component => connect(mapStateToProps, mapActionCreators)(allergyContainer(component));
