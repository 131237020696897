import React, { useRef, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import Tether from 'react-tether';

import { useClickOutSide } from 'hooks/useClickOutside';

import vitalsContainer, { fetchLayerHOC } from 'containers/VitalsContainer';
import chartToPatientContainer from 'containers/ChartToPatientContainer';
import patientEncountersContainer, { withPatientEncountersFetchLayer } from 'containers/Encounters/PatientEncountersContainer';

import Datepicker from 'components/Datepicker';
import ProvidersSelectChart from 'components/ProvidersSelect/ProvidersSelectChart';
import VitalsComponent from 'routes/routes/App/routes/Charts/components/ProgressNote/Vitals';
import Modal from 'components/Modal';
import Charts from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNoteHeader/Charts';
import StaticOptions from 'components/Select/StaticOptions';

import { formatArrayItems } from 'helpers/selectHelpers';
import { CPT_CODES_LIST, SELECT_INPUT_CPT_FORMAT } from 'helpers/chart/cpt/cptConstants';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';

import cx from './ProgressNoteHeader.module.scss';

import VITALS_ICON from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNoteHeader/images/vitals-icon.png';
import COPY_PREVIOUS_NOTES_ICON from 'routes/routes/App/routes/Charts/components/ProgressNote/ProgressNoteHeader/images/copy-icon.png';

const PatientEncounters = compose(
  chartToPatientContainer,
  patientEncountersContainer,
  withPatientEncountersFetchLayer
)(Charts);

const Vitals = compose(
  vitalsContainer,
  fetchLayerHOC
)(VitalsComponent);

const NULL_CODE_LABEL = 'Select Code';

const modalData = {
  type: 'confirm',
  title: 'Copy previous note',
  content: '<p><strong style="color: orange">Warning:</strong> Are you sure you want to copy previous note?<br />Current encounter note data will be lost!</p>',
};

const styles = () => ({
  procedureContainer: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['select-wrapper', 'procedure-input-container']
  }),
  vitalsIcon: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['button', 'icon-button']
  })
});

const providerSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 40,
  dropdownIndicatorSize: 40
});

const ProgressNoteHeader = (props) => {
  const [isVitalsOpen, setIsVitalsOpen] = useState(false);
  const [isChartsMenuOpen, setIsChartsMenuOpen] = useState(false);
  const [isCopyPreviousNoteModalOpen, setIsCopyPreviousNoteModalOpen] = useState(false);
  const [copyFromId, setCopyFromId] = useState(null);

  const toggleVitals = () => setIsVitalsOpen(prev => !prev);

  const vitalsInputRef = useRef(null);
  const vitalsDropdownRef = useClickOutSide({
    onOutsideClick: toggleVitals,
    exclude: [vitalsInputRef]
  });

  const toggleCharts = () => setIsChartsMenuOpen(prev => !prev);

  const copyNotesInputRef = useRef(null);
  const copyNotesRef = useClickOutSide({
    onOutsideClick: toggleCharts,
    exclude: [copyNotesInputRef]
  });


  const closeCharts = () => setIsChartsMenuOpen(false);

  const handleCopyPreviousNote = (tempCopyFromId) => {
    setIsCopyPreviousNoteModalOpen(true);
    closeCharts();
    setCopyFromId(tempCopyFromId);
  };

  const closeModal = () => {
    setIsCopyPreviousNoteModalOpen(false);
    setCopyFromId(null);
  };

  const modalConfirm = () => {
    setIsCopyPreviousNoteModalOpen(false);
    const { copyPreviousNote, chartId } = props;
    copyPreviousNote(chartId, copyFromId);
  };

  const updateCptCodeWrapper = async (option) => {
    const {
      value,
      label,
      description,
    } = option;

    let cpt = {
      id: value,
      cptCode: label,
      description,
    };

    if (cpt.id === null) {
      cpt = null;
    }

    const { cptCode: oldCptCode, chartId, updateCptCode } = props;

    const dataToRequest = { chartId, medCptCodeId: value };
    const dataForStore = { chartId, oldCptCode, cptCode: { ...cpt, favoriteId: cpt.id } };

    await updateCptCode(dataToRequest, dataForStore);
  };

  const updateSelectUserDateWrapper = ({ dateValueInMs, chartId, updateSelectUserDate }) =>
    updateSelectUserDate(chartId, dateValueInMs);

  const {
    chartId,
    chart,
    cptCode,
    updateSelectUserDate
  } = props;

  const code = cptCode !== null ? cptCode : { cptCode: NULL_CODE_LABEL, id: null };

  const {
    selectUserDate,
    createdDate,
    provider,
  } = chart;

  const chartSignDate = selectUserDate || createdDate || Date.now();
  const cptCodesOptions = formatArrayItems(CPT_CODES_LIST, SELECT_INPUT_CPT_FORMAT);

  const resolvedStyles = styles();

  return (
    <div className={cx.wrapper}>
      <div className={cx['central-wrapper']}>

        <div className={cx['procedure-code']}>
          <span className={cx['procedure-label']}>CPT:</span>
          <div className={resolvedStyles.procedureContainer}>
            <StaticOptions
              value={{ label: code.cptCode, value: code.id }}
              options={cptCodesOptions}
              onChange={updateCptCodeWrapper}
              styles={providerSelectStyles}
              isClearable={false}
            />
          </div>
        </div>

        <div className={cx['datepicker-wrapper']}>
          <span className={cx['procedure-label']}>DOS:</span>
          <div className={cx['procedure-input-container']}>
            <Datepicker
              value={chartSignDate}
              onChange={(value) => updateSelectUserDateWrapper({
                dateValueInMs: value,
                chartId,
                updateSelectUserDate
              })}
              isDisabledEditing
              momentToMsStrategyType={Datepicker.strategies.WITH_TIME}
            />
          </div>
        </div>
      </div>


      <div className={cx['buttons-wrapper']}>
        <div className={cx['provider-select-wrapper']}>
          <span className={cx['procedure-label']}>Provider:</span>
          <ProvidersSelectChart
            className={cx['provider-select']}
            chartId={chartId}
            chartProviderId={provider.id}
            isChartProvider
          />
        </div>

        <Tether
          attachment="top right"
          targetAttachment="top right"
          offset="100px 60px"
          constraints={[{
            to: 'window',
          }]}
          classPrefix="vitals"
          renderTarget={targetVitalsRef => {
            vitalsInputRef.current = targetVitalsRef.current;
            return (
              <button
                ref={targetVitalsRef}
                className={resolvedStyles.vitalsIcon}
                type="button"
                onClick={toggleVitals}
              >
                <img alt='vitals' src={VITALS_ICON} />
              </button>
            );
          }}
          renderElement={elementVitalsRef => isVitalsOpen && (
            <span ref={vitalsDropdownRef}>
              <span ref={elementVitalsRef}>
                <Vitals
                  chartId={chartId}
                />
              </span>
            </span>
          )}
        />
        <Tether
          attachment="top right"
          targetAttachment="bottom right"
          offset="100px 55px"
          constraints={[{
            to: 'window',
          }]}
          classPrefix="copyPreviousNoteCharts"
          renderTarget={copyNotesTargetRef => {
            copyNotesInputRef.current = copyNotesTargetRef.current;
            return (
              <button
                ref={copyNotesTargetRef}
                className={resolvedStyles.vitalsIcon}
                type="button"
                onClick={toggleCharts}
              >
                <img alt='Copy Notes' src={COPY_PREVIOUS_NOTES_ICON} />
              </button>
            );
          }}
          renderElement={copyNotesElementRef => isChartsMenuOpen && (
            <span ref={copyNotesRef}>
              <div ref={copyNotesElementRef}>
                <PatientEncounters
                  chartId={chartId}
                  onCopyPreviousNote={handleCopyPreviousNote}
                  componentType="encounter-header"
                />
              </div>
            </span>
          )}
        />
      </div>

      <Modal
        data={modalData}
        isOpen={isCopyPreviousNoteModalOpen}
        onModalClose={closeModal}
        onModalConfirm={modalConfirm}
      />
    </div>
  );
};

ProgressNoteHeader.propTypes = {
  chartId: PropTypes.number.isRequired,
  copyPreviousNote: PropTypes.func.isRequired,
  updateSelectUserDate: PropTypes.func.isRequired,
  updateCptCode: PropTypes.func.isRequired,
};

export default ProgressNoteHeader;
