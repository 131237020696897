import { components } from 'react-select';

export const ReplaceTemplateOption = (props) => {
  const { data } = props;

  if (data.value === 'replace') {
    return (
      <components.Option {...props}>
        Replace
      </components.Option>
    );
  }

  if (data.value === 'delete') {
    return (
      <components.Option {...props}>
        Delete
      </components.Option>
    );
  }
};