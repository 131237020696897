import React from 'react';
import PropTypes from 'prop-types';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from 'components/SelectInput/components/Dropdown/Dropdown.module.scss';

const Dropdown = ({
  options = [],
  isActive = false,
  onOptionClick,
  activeOptionIndex,
  position,
}) => (
  <div
    className={cssClassesResolver([
      isActive ? cx['wrapper-active'] : cx['wrapper'],
      position && cx[`wrapper--${position}`]
    ])

    }
  >
    {options.map(
      (option, index) => (
        <div
          className={cx[activeOptionIndex === index ? 'option-active' : 'option']}
          key={index} data-value={option.value} onClick={() => onOptionClick(option, index)}
        >
          {option.title}
        </div>
      )
    )}
  </div>
);

Dropdown.propTypes = {
  options: PropTypes.array,
  isActive: PropTypes.bool,
  position: PropTypes.string,
  onOptionClick: PropTypes.func,
  activeOptionIndex: PropTypes.number,
};

export default Dropdown;
