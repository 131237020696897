import React from 'react';
import cx from 'components/UserEditForm/Container/Container.module.scss';
import PropTypes from 'prop-types';

const Container = ({
  children,
}) => (
  <div className={cx.container}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
