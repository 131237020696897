/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import cx from './MedDataHeader.module.scss';

const MedDataHeader = ({
  title,
}) => (
  <div className={cx.content}>
    <h3 className={cx.title}>
      <span>{title}</span>
    </h3>
  </div>
);

MedDataHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MedDataHeader;
