import React from 'react';

import Arrows from 'components/Table/SortArrows';

import cx from './TableHead/TableHead.module.scss';

const Header = props => {
  const {
    fields,
    desc,
    onSort,
    sortBy
  } = props;

  const handleTitleClick = ({ field: { sortable, title } }) => {
    if (!sortable || !onSort) return;
    onSort(title);
  };

  return (
    <thead>
    <tr>
      {fields.map(({ title, subtitle, sortable }) => (
        <th
          key={title}
        >
            <span
              className={cx.title}
              style={{
                cursor: sortable ? 'pointer' : 'default',
              }}
              onClick={() => handleTitleClick({ field: { sortable, title } })}
            >
              {title}{' '}{sortable ? <Arrows desc={title === sortBy ? desc : undefined} /> : null}
            </span>
          <div
            className={cx.subtitleWrapper}
          >
              <span
                className={cx.subtitle}
              >
              {subtitle}
            </span>
          </div>
        </th>
      ))}
    </tr>
    </thead>
  );
};

export default Header;