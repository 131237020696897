import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { compose } from 'redux';

import patientEncountersContainer, { withPatientEncountersFetchLayer } from 'containers/Encounters/PatientEncountersContainer';
import EncountersTable from 'containers/Encounters/EncountersTable';
import PatientEncountersPaginationWrapper from 'components/PatientEncountersPaginationWrapper';

import tableFields from './fields';

const EncountersTableComponent = compose(
  patientEncountersContainer,
  withPatientEncountersFetchLayer
)(EncountersTable);

const PatientsEncountersWrapper = () => {
  const [patientId] = useOutletContext();
  return (
    <PatientEncountersPaginationWrapper
      patientId={patientId}
    >
      <EncountersTableComponent customTableFields={tableFields} />
    </PatientEncountersPaginationWrapper>
  );
};

export default PatientsEncountersWrapper;
