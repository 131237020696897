import React, { useContext, useState } from 'react';
import { func, bool, string, number, shape, arrayOf } from 'prop-types';

import { EditModeContext } from './context';

import RegularElementCheckbox from './RegularElementCheckbox';
import ElementsViewItemActions from '../ElementsViewItemActions';
import EditModeModal from '../EditModeModal';
import { elementsViewItemActionsModalData } from '../ElementsViewItemActions/data';

import { verifyPassword } from 'api/user';

import cx from '../ElementsViewItems/ElementsViewItems.module.scss';

const PlainRegularElement = (props) => {
  const [passwordError, setPasswordError] = useState(false);
  const [isEditModeModalOpen, setIsEditModeModalOpen] = useState(false);
  const {
    currentEditModeAction,
    editModeRemoveElementAction,
    editModeMergeElementsAction,
  } = useContext(EditModeContext);

  const {
    element,
    currentItemForMerge,
    setItemsForMerge,
    isCheckboxToMergeItemsClicked,
    minimalConditionsForEditModeState,
    setCurrentEditModeAction,
    setCurrentItemForEditMode,
    isEditModeDoneBtnDisabled,
    handleMergeElements,
    itemsForMerge,
    isOpenModalForMergeElements,
    editModeResetFavoritesAction,
    updateItemsForMergeInState,
    setIsEditModeDoneBtnDisabled,
    closeEditModeModalForMergeElements
  } = props;

  const isEditModeModalOpenForMergeElements = () => {
    return isOpenModalForMergeElements === true && currentItemForMerge.id === element.id;
  };

  const getIsEditModeModalOpen = () => {
    return isEditModeModalOpen === true || isEditModeModalOpenForMergeElements() === true;
  };

  const getIsElementsViewItemActionsButtonClicked = () =>
    element.id === currentItemForMerge.id;

  const openEditModeModal = () => setIsEditModeModalOpen(true);

  const closeEditModeModal = () => setIsEditModeModalOpen(false);

  const resetMergeElementsStatus = () => {
    if (currentEditModeAction === elementsViewItemActionsModalData.mergeElements.id) {
      setCurrentEditModeAction(elementsViewItemActionsModalData.resetFavorite.id);

      updateItemsForMergeInState([]);
      setIsEditModeDoneBtnDisabled(false);
      closeEditModeModalForMergeElements();
    }
  };

  const closeEditModeModalWrapper = () => {
    resetMergeElementsStatus();

    closeEditModeModal();
  };

  const changeEditButtonClickedStatus = () => {
    resetMergeElementsStatus();
  };

  const afterSuccessEditModeAction = () => {
    closeEditModeModalWrapper();

    setCurrentItemForEditMode({});
  };

  const performCorrespondingAction = () => {
    switch (currentEditModeAction) {
      case elementsViewItemActionsModalData.resetFavorite.id:
        return editModeResetFavoritesAction(element);

      case elementsViewItemActionsModalData.removeElement.id:
        return editModeRemoveElementAction(element.id);

      case elementsViewItemActionsModalData.mergeElements.id:
        return editModeMergeElementsAction(element.id);

      default:
        return editModeResetFavoritesAction(element.id);
    }
  };

  const handleVerifyPasswordResponse = (isValidUserPassword) => {
    if (isValidUserPassword === true) {
      performCorrespondingAction();
      afterSuccessEditModeAction();

      return;
    }

    setPasswordError(true);
  };

  const confirmEditModeModal = async (modalState) => {
    const { password } = modalState;

    const { body } = await verifyPassword({ password });
    handleVerifyPasswordResponse(body);
  };

  const isActionsButtonClicked = getIsElementsViewItemActionsButtonClicked() === true;

  return (
    <form>
      <RegularElementCheckbox
        isElementsViewItemActionsButtonClicked={isActionsButtonClicked}
        element={element}
        setItemsForMerge={setItemsForMerge}
        isCheckboxToMergeItemsClicked={isCheckboxToMergeItemsClicked}
      />

      {minimalConditionsForEditModeState === true && (
        <span className={cx['control-button-wrapper']}>
          <ElementsViewItemActions
            changeEditButtonClickedStatus={changeEditButtonClickedStatus}
            isElementsViewItemActionsButtonClicked={isActionsButtonClicked}
            openEditModeModal={openEditModeModal}
            setCurrentEditModeAction={setCurrentEditModeAction}
            setCurrentItemForEditMode={setCurrentItemForEditMode}
            item={element}
            isEditModeDoneBtnDisabled={isEditModeDoneBtnDisabled}
            handleMergeElements={handleMergeElements}
          />
        </span>
      )}

      <EditModeModal
        passwordError={passwordError}
        currentElementName={currentItemForMerge.name}
        isOpen={getIsEditModeModalOpen()}
        onModalClose={closeEditModeModalWrapper}
        onModalConfirm={confirmEditModeModal}
        itemsForMerge={itemsForMerge}
      />
    </form>
  );
};

PlainRegularElement.propTypes = {
  currentItemForMerge: shape({
    id: number,
    name: string
  }).isRequired,
  element: shape({
    id: number
  }).isRequired,
  itemsForMerge: arrayOf(shape({
    name: string
  })).isRequired,
  setCurrentEditModeAction: func.isRequired,
  updateItemsForMergeInState: func.isRequired,
  setIsEditModeDoneBtnDisabled: func.isRequired,
  closeEditModeModalForMergeElements: func.isRequired,
  setCurrentItemForEditMode: func.isRequired,
  editModeResetFavoritesAction: func.isRequired,
  setItemsForMerge: func.isRequired,
  isOpenModalForMergeElements: bool.isRequired,
  isCheckboxToMergeItemsClicked: bool.isRequired,
  minimalConditionsForEditModeState: bool.isRequired,
  isEditModeDoneBtnDisabled: bool.isRequired,
  handleMergeElements: func.isRequired,
};

export default PlainRegularElement;

