export default [
  {
    title: 'allergy',
    subtitle: 'Name',
    sortable: true,
  },
  {
    title: 'severity',
    subtitle: 'Title',
    sortable: true,
  },
  {
    title: 'reaction',
    subtitle: 'Type',
    sortable: true,
  },
  {
    title: 'onset',
    subtitle: 'Time',
    sortable: true,
  },
];
