import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import AsyncSelect from 'react-select/async';

import isEmpty from 'helpers/common/array/is-empty';

import SelectContainer from 'components/MedSelect/SelectContainer';

import { selectOption } from 'helpers/propsGenerator';

import { searchDiagnoses, fetchDiagnoses } from 'api/diagnoses';

const DiagnosisInput = (props) => {
  const [defaultDiagnosis, setDefaultDiagnosis] = useState([])

  const {
    patientId,
    className,
    multi,
    value,
    placeholder,
    onChange,
    styles
  } = props;

  useEffect(() => {
    let mounted = true

    fetchDiagnoses(patientId, 'active')
      .then(
        (result) =>  {
            if (!isEmpty(result.body) && mounted) {
              const diagnosis = result.body.map(diagnosisFromServer => ({
                value: diagnosisFromServer.diagnosis.id,
                label: diagnosisFromServer.diagnosis.name,
              }))

              setDefaultDiagnosis(diagnosis)
            }
        }
      )

    return function cleanup() {
      mounted = false
    };
  }, [patientId])

  const getDiagnoses = (input, callback) =>
    searchDiagnoses(input).then(
      (result) => {
        callback(
          result.body.map(diagnosis => ({ value: diagnosis.id, label: diagnosis.name })),
        )},
      () => callback(null, [])
    )

  const debouncedGetDiagnoses = debounce(getDiagnoses, 1000, { leading: true, trailing: true });

  const loadDiagnoses = (input, callback) => {
    return debouncedGetDiagnoses(input, callback);
  };

  let options;

  if (multi) {
    options = value ? value.map(option => ({ value: option.id, label: option.name })) : [];
  } else {
    options = value ? { value: value.id, label: value.name } : null;
  }

  return (
    <>
      <AsyncSelect
        autosize={false}
        components={{ SelectContainer }}
        styles={styles}
        className={className}
        defaultOptions={defaultDiagnosis}
        isMulti={multi}
        placeholder={placeholder || null}
        loadOptions={loadDiagnoses}
        value={options}
        onChange={(changedValue) => {
          let result;

          if (multi) {
            result = isEmpty(changedValue) ? [] : changedValue.map(option => ({ id: option.value, name: option.label }));
          } else {
            result = changedValue ? { id: value.value, name: changedValue.label } : null;
          }

          onChange(result);
        }}
        isClearable
      />
    </>
  );
}

DiagnosisInput.propTypes = {
  value: PropTypes.oneOfType([selectOption.arr, selectOption.obj]),
  onChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool
};


export default DiagnosisInput;
