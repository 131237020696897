import { handleActions } from 'redux-actions';

import { fetchChartIdFromCreatingResponse } from 'helpers/charting/charting';
import * as chartsConstants from '../constants';

const initialState = [];

export default handleActions({
  [chartsConstants.CHART_CREATE_SUCCESS]: (state, action) => ([
    ...state,
    fetchChartIdFromCreatingResponse(action.payload.result),
  ]),

  [chartsConstants.CHARTS_BY_PAGE_FETCH_SUCCESS]: (state, action) => ([
    ...new Set([
      ...state,
      ...action.payload.result.encounterUserDTOS.map(item => item.id),
    ]),
  ]),

  [chartsConstants.CHARTS_LAST_WITH_SORTING_FETCH_SUCCESS]: (state, action) => ([
    ...new Set([
      ...state,
      ...action.payload.result.map(item => item.id),
    ]),
  ]),

  [chartsConstants.CHART_DELETE_SUCCESS]: (state, action) => ([
    ...(state.filter(id => id !== action.payload.chartId)),
  ])
}, initialState);
