import Element from 'helpers/elements/Element';
import ElementDTO from 'helpers/elements/ElementDTO';
import { BILLING_SYSTEMS } from 'helpers/systems/ids';
import ActiveBillingCode from 'helpers/resourses/ActiveBillingCode';
import BillingCodeMapper from 'helpers/resourses/BillingCodeMapper';
import HcpcResourceMapper from 'helpers/resourses/HcpcResourceMapper';
import ActiveHcpcResource from 'helpers/resourses/ActiveHcpcResource';

const defaultParser = (element) => {
  const plainElement = new Element(element);
  const elementDTO = new ElementDTO(plainElement);

  return elementDTO.formatForClient();
};

const activeBillingCodeParser = (element) => {
  const activeBillingCode = new ActiveBillingCode(element);
  const activeBillingCodeMapper = new BillingCodeMapper(activeBillingCode);

  return activeBillingCodeMapper.basicFormat();
};

const activeHcpcParser = (element) => {
  const activeHcpc = new ActiveHcpcResource(element);
  const activeBillingCodeMapper = new HcpcResourceMapper(activeHcpc);

  return activeBillingCodeMapper.basicFormat();
};

const parseElementBySystemTypeStrategyManager = (systemType) => {
  switch (systemType) {
    case BILLING_SYSTEMS.CPT.type:
      return activeBillingCodeParser;
    case BILLING_SYSTEMS.HCPC.type:
      return activeHcpcParser;
    default:
      return defaultParser;
  }
};

export default parseElementBySystemTypeStrategyManager;
