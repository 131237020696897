export const ADD_SYSTEM = 'ADD_SYSTEM';

export const REMOVE_SYSTEM = 'REMOVE_SYSTEM';

export const ADD_ELEMENT = 'ADD_ELEMENT';

export const REMOVE_ELEMENT = 'REMOVE_ELEMENT';

export const REMOVE_ELEMENT_IN_ALL_SYSTEMS = 'REMOVE_ELEMENT_IN_ALL_SYSTEMS';

export const SAVE_CHARTING_SESSION = 'SAVE_CHARTING_SESSION';
export const SAVE_CHARTING_SESSION_SUCCESS = 'SAVE_CHARTING_SESSION_SUCCESS';
export const SAVE_CHARTING_SESSION_FAIL = 'SAVE_CHARTING_SESSION_FAIL';

export const SAVE_CHARTING_SESSION_WRAPPER = 'SAVE_CHARTING_SESSION_WRAPPER';
export const SAVE_CHARTING_SESSION_WRAPPER_SUCCESS = 'SAVE_CHARTING_SESSION_WRAPPER_SUCCESS';
export const SAVE_CHARTING_SESSION_WRAPPER_FAIL = 'SAVE_CHARTING_SESSION_WRAPPER_FAIL';

export const FAVORITES_FETCH = 'FAVORITES_FETCH';
export const FAVORITES_FETCH_SUCCESS = 'FAVORITES_FETCH_SUCCESS';
export const FAVORITES_FETCH_FAIL = 'FAVORITES_FETCH_FAIL';
