import { sendCompletionStatusToServerWrapper } from 'modules/charts/actions';

import * as constants from './constants';
import * as api from 'api/social';

export const fetchPatientSocial = patientId => ({
  types: [
    constants.PATIENT_SOCIAL_FETCH,
    constants.PATIENT_SOCIAL_FETCH_SUCCESS,
    constants.PATIENT_SOCIAL_FETCH_FAIL,
  ],
  promise: () => api.fetchPatientSocial(patientId),
  patientId,
});

export const addSystem = (systemId, patientId) => ({
  type: constants.SOCIAL_SYSTEM_ADD,
  payload: {
    systemId,
    patientId,
  },
});

export const addElement = (patientId, systemId, elementToCreate, elementToAdd) => ({
  type: constants.SOCIAL_ELEMENT_ADD,
  payload: {
    patientId,
    systemId,
    elementToCreate,
    elementToAdd,
  },
});

export const removeElements = (localData, requestData) => ({
  types: [
    constants.SOCIAL_ELEMENTS_REMOVE,
    constants.SOCIAL_ELEMENTS_REMOVE_SUCCESS,
    constants.SOCIAL_ELEMENTS_REMOVE_FAIL,
  ],
  promise: () => {
    const {
      isNeededRemoveElements,
    } = localData;

    if (isNeededRemoveElements) {
      return api.deleteElement(requestData);
    }

    return Promise.resolve();
  },
  ...localData,
  patientId: requestData.patientId,
});

export const removeElementsWrapper = (localData, requestData, chartId) => async (dispatch) => {
  await dispatch(removeElements(localData, requestData));

  dispatch(sendCompletionStatusToServerWrapper(chartId));
};

export const selectElement = data => async (dispatch) => {
  const {
    patientId,
    elementId,
    socialId,
    chartId,
  } = data;

  await api.addElementToSystem({ patientId, elementId, socialId });

  dispatch(sendCompletionStatusToServerWrapper(chartId));
};
