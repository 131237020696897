import difference from 'lodash/difference';

export default class SearchableItemsForRightSideSearch {
  constructor(
    allItems = [],
    itemsForMerge = [],
    currentItemForEditMode = {},
    hiddenItems = [],
  ) {
    this.allItems = allItems;
    this.itemsForMerge = itemsForMerge;
    this.currentItemForEditMode = currentItemForEditMode;
    this.hiddenItems = hiddenItems;
  }

  getSearchableItems = () => {
    return difference(this.allItems, this.itemsForMerge, [this.currentItemForEditMode], this.hiddenItems);
  };
}
