import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './Checkbox.module.scss';

const checkboxStyles = ({ checked, enabled, checkboxSize, checkedColor, shape }) => ({
  wrapper: cx.wrapper,
  checkbox:
    cssClassesResolver([
      cx[`checkbox--${checkboxSize}`],
      cx[`shape--${shape}`],
      !checked && cx.checkbox,
      (checked || enabled) && cx[`checkbox--active--${checkedColor}`],
      (checked || enabled) && cx[`active-color--${checkedColor}`],
    ])
})

class Checkbox extends React.Component {
  static SIZE = {
    SMALL: 'small',
    LARGE: 'large',
  };

  static SHAPE = {
    circle: 'circle',
    square: 'square'
  };

  static CHECKED_COLOR = {
    BLUE: 'blue',
    GREEN: 'green',
    YELLOW: 'yellow',
  };

  handleOnClick = (e) => {
    const {
      disabled,
      onClick,
      enabled,
    } = this.props;

    if (disabled || enabled) return;

    onClick(e);
  };

  render() {
    const {
      checked,
      children,
      loading,
      enabled,
      checkedColor,
      checkboxSize,
      shape
    } = this.props;

    const styles = checkboxStyles({ checked, checkedColor, enabled, checkboxSize, shape })

    return (
      <div
        onClick={this.handleOnClick}
        className={styles.wrapper}
      >
        {loading && (
          <Loader
            isStatic
            secondaryColor="#e8f0f3"
            className={cx.loader}
          />
        )}
        {!loading && (
          <i
            className={styles.checkbox}
          />
        )}

        {children && (
          <span className={cx.label}>
            {children}
          </span>)}
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
  checkboxSize: PropTypes.string,
  checkedColor: PropTypes.string,
  shape: PropTypes.string
};

Checkbox.defaultProps = {
  checkboxSize: Checkbox.SIZE.SMALL,
  checkedColor: Checkbox.CHECKED_COLOR.BLUE,
  shape: Checkbox.SHAPE.circle
};

export default Checkbox;
