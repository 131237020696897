import { useParams, useSearchParams } from 'react-router-dom';

export const useRoute = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromParams = (key = '') => params[key];
  const fromSearchParams = (key = '') => searchParams.get(key);

  return {
    fromParams,
    fromSearchParams,
    setSearchParams
  };
};