import { connect } from 'react-redux';

import { getSidebarState, toggleSidebar } from 'modules/sidebar';
import { getUserInfo } from 'modules/user/selectors';
import { lock, isLocked, isProcessing } from 'modules/locked';

import PageHeader from '../components/PageHeader';

const mapStateToProps = state => ({
  sidebarActive: getSidebarState(state.sidebar),
  lockButtonEnabled: getUserInfo(state.user)?.quickPassword || false,
  locked: isLocked(state.locked),
  lockProcessing: isProcessing(state.locked),
});

const mapActionCreators = {
  toggleSidebar,
  lock,
};

export default connect(mapStateToProps, mapActionCreators)(PageHeader);
