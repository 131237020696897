import { NEED_FOR_FETCHING_SYSTEMS_BY_STEP_CHART_ID } from 'helpers/right-side-items/fetch/constants';
import { isNullOrUndefined } from 'helpers';

const isNeedFilteringSystemsByStepAndChartId = (stepId, systemId) => {
  const checkByStep = NEED_FOR_FETCHING_SYSTEMS_BY_STEP_CHART_ID.includes(stepId);
  const isNoActiveSystem = isNullOrUndefined(systemId);

  return checkByStep && isNoActiveSystem;
};

export default isNeedFilteringSystemsByStepAndChartId;
