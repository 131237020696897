import { handleActions } from 'redux-actions';
import { DELETE_ILLNESS } from 'modules/med-select/constants';

const initialState = {
  deletedItems: []
}

export default handleActions({
  [DELETE_ILLNESS]: (state, action) => ({
    ...state,
    deletedItems: [
      ...state.deletedItems,
      action.payload.id
    ]
  })
}, initialState)
