import React from 'react';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './RoleRow.module.scss';

const rowStyles = ({isSelected}) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: ['wrapper', isSelected && 'wrapper--active']
})

const RoleRow = ({
  role,
  tableColumns,
  navigateToRole,
  removeRole,
  isSelected,
}) => (
  <tr
    className={rowStyles({isSelected})}
    onClick={() => navigateToRole(role.id)}
  >
    <td
      style={{
        width: tableColumns[0].width,
      }}
    />
    <td
      style={{
        width: tableColumns[1].width,
        textAlign: 'left',
      }}
    >
      {role.name}
    </td>
    <td
      style={{
        width: tableColumns[2].width,
      }}
    >
      {!role.original && (
        <div className={cx['controls']}>
          <button
            className={cx['button--edit']}
            onClick={() => navigateToRole(role.id)}
          />
          <button
            className={cx['button--remove']}
            onClick={() => removeRole(role)}
          />
        </div>
      )}
    </td>
  </tr>
);

export default RoleRow;
