import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PatientHistoryItemsTableContainer from 'containers/PatientHistoryItemsTable';

import Loader from 'components/Loader';

import { DATE_FORMATS } from 'helpers/date/constants';
import Base from 'helpers/fmss/additional-empty-message/factory/empty-message-entity/Base';
import ProducerEmptyMessageFactory from 'helpers/fmss/additional-empty-message/factory/producer-factory';
import { FMSS_TYPES } from 'helpers/fmss/constants';
import fields from './fields';


import cx from './SurgicalHXTable.module.scss';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import { useNavigateWrapper } from 'hooks/useNavigateWrapper';
import { build } from 'helpers/URLUtils';


const SurgicalHXTable = props => {
  const { timeZoneDateConverter } = useContext(AppContext);
  const { location, navigate } = useNavigateWrapper();

  const showSurgicalById = (id) =>
    navigate(
      build({
        pathname: location.pathname,
        query: {
          surgical: id,
        },
      })
    );

  const formattedDateField = date => timeZoneDateConverter.getFormattedDateWithTimeZone(date, DATE_FORMATS.YEAR);

  const {
    surgicalItems,
    isFetching,
    selectedId,
    error,
    fetchSurgical,
    hasNoPreviousSurgeries,
    patientId
  } = props;

  if (isFetching) {
    return <Loader />;
  }

  const conditionalData = new Base(hasNoPreviousSurgeries);
  const emptyMessage = ProducerEmptyMessageFactory.getMessage(FMSS_TYPES.SURGICAL, conditionalData);

  return (
    <PatientHistoryItemsTableContainer
      items={surgicalItems}
      defaultDesc={false}
      defaultSortBy="surgery"
      mapItemToField={item => ({
        id: item.id,
        surgery: item.surgery.name,
        date: formattedDateField(item.startDate),
        comment: item.description,
      })}
      error={error}
      onReload={() => fetchSurgical(patientId)}
      fields={fields}
      itemRenderer={surgicalItem => (
        <tr
          className={
            cssClassesResolver([
              cx.item, surgicalItem.id === selectedId && cx['item--active']
            ])
          }
          key={surgicalItem.id}
          onClick={() => showSurgicalById(surgicalItem.id)}
        >
          <td>
            <span className={cx.name}>{surgicalItem.surgery}</span>
          </td>
          <td>{surgicalItem.date}</td>
          <td>{surgicalItem.comment}</td>
        </tr>
      )}
      emptyMessage={emptyMessage}
    />
  );
};

SurgicalHXTable.propTypes = {
  surgicalItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    surgery: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    startDate: PropTypes.number,
    description: PropTypes.string,
  })).isRequired,
  selectedId: PropTypes.number,
  fetchSurgical: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasNoPreviousSurgeries: PropTypes.bool,
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

SurgicalHXTable.defaultProps = {
  selectedId: undefined,
  error: undefined,
  isFetching: undefined,
  hasNoPreviousSurgeries: false,
};

export default SurgicalHXTable;
