import React from 'react';
import PropTypes from 'prop-types';

import cx from './Modal.module.scss';

const ModalConfirm = (props) => {
  const createMarkup = content => {
    return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    );
  };

  const {
    data,
    cancelDisabled = true,
    onModalClose,
    onModalConfirm,
  } = props;

  return (
    <div>
      <div className={cx['content-container']}>
        {createMarkup(data.content)}
      </div>
      <div className={cx['modal-window-controls']}>
        {!cancelDisabled &&
          <button type="button" className={cx.button} onClick={onModalClose}>
            Cancel
          </button>
        }
        <button type="button" className={cx.button} onClick={onModalConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ModalConfirm;

ModalConfirm.propTypes = {
  cancelDisabled: PropTypes.bool,
};
