import { connect } from 'react-redux';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import { SCREENING } from 'modules/patientsHx/constants';

import { getPatientById } from 'modules/patients/selectors';

export const ScreeningFetchLayer = Component => {

  const useFetchLayer = props => {
    const {
      patientId,
      isFetching,
      fetchScreening
    } = props;

    useEffect(() => {
      if (patientId && isFetching === undefined) {
        fetchScreening(patientId);
      }
    }, [patientId]);

    return <Component {...props} />;
  };

  useFetchLayer.propTypes = {
    isFetching: PropTypes.bool,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fetchScreening: PropTypes.func.isRequired,
  };

  return useFetchLayer;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isFetching: selectors.getIsFetchingList(state.patientsHx, SCREENING, ownProps.patientId),
    screeningItems: selectors.getScreeningItems(state.patientsHx, ownProps.patientId),
    error: selectors.getListError(state.patientsHx, SCREENING, ownProps.patientId),
    selectedId: ownProps.screeningItemId,
    hasNoPertinentScreening: getPatientById(state.patients, ownProps.patientId)?.hasNoPertinentScreening,
  };
};

const mapActionCreators = {
  fetchScreening: actions.fetchScreening,
  createScreeningItem: actions.createScreeningItem,
  updateScreeningItem: actions.updateScreeningItem,
  deleteScreeningItem: actions.deleteScreeningItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
