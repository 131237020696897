import React from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import chartToPatientContainer from 'containers/ChartToPatientContainer';
import patientContainer, { fetchLayerHOC } from 'containers/Patients/PatientContainer';
import DemographicsForm from 'containers/Demographics/DemographicsForm';

const DemographicsContainer = compose(
  chartToPatientContainer,
  patientContainer,
  fetchLayerHOC
)(DemographicsForm);

const Page = () => {
  const { chartId } = useParams();
  return (
    <DemographicsContainer
      chartId={chartId}
    />
  );
};

export default Page;
