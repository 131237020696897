import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import { usePopper } from 'react-popper';

import cx from './InfoPopup.module.scss';

const Popup = props => {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const {
    position = 'top',
    small,
    children
  } = props;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
  });

  const togglePopup = () => setVisible(prev => !prev);

  return (
    <>
      <button
        className={'no-button'}
        type="button"
        ref={setReferenceElement}
        onClick={togglePopup}
      >
        <i
          className={cx[small ? 'help-icon-small' : 'help-icon']}
        >
          ?
        </i>
      </button>
      {visible && createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={`${cx.popup} ${cx['popup-visible']}`}
        >
          {children}
        </div>,
        document.getElementById('root')
      )}
    </>
  );
};

export default Popup;
