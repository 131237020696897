import DefaultClinicImage from 'helpers/images/defaults/clinic';

export default class ImgSrc {
  static define = (imageUrl) => {
    if (imageUrl) {
      return imageUrl;
    }

    return DefaultClinicImage.get();
  }
}
