import { handleActions } from 'redux-actions';
import * as constants from '../../constants';
import * as chartsConstants from 'modules/charts/constants';

export const initialState = {
  data: {},
  status: {
    isFetching: false,
    isUpdating: false,
    isFetched: false,
    error: null,
  },
};

const handlers = {
  [constants.HISTORY_ITEM_FETCH]: (state, action) => ({
    data: {
      ...state.data,
      id: action.payload.itemId,
    },
    status: {
      ...state.status,
      isFetching: true,
      error: null,
    },
  }),
  [constants.HISTORY_ITEM_FETCH_SUCCESS]: (state, action) => ({
    data: {
      ...state.data,
      ...action.payload.result,
    },
    status: {
      ...state.status,
      isFetching: false,
      isFetched: true,
    },
  }),
  [constants.HISTORY_ITEM_FETCH_FAIL]: (state, action) => ({
    data: {
      ...state.data,
      id: action.payload.itemId,
    },
    status: {
      ...state.status,
      isFetched: false,
      isFetching: false,
      error: {
        status: action.error.status,
        message: action.error.message,
      },
    },
  }),
  [constants.HISTORY_ITEM_CREATE_SUCCESS]: (state, action) => ({
    data: {
      ...state.data,
      ...action.payload.item,
    },
    status: {
      ...state.status,
      isFetched: true,
      error: null,
      isFetching: false,
    },
  }),
  [constants.HISTORY_ITEM_UPDATE]: (state, action) => ({
    data: {
      ...state.data,
      id: action.payload.item.id,
    },
    status: {
      ...state.status,
      isUpdating: true,
    },
  }),
  [constants.HISTORY_ITEM_UPDATE_SUCCESS]: (state, action) => ({
    data: {
      ...state.data,
      ...action.payload.item,
      errorMap: action.payload.result?.errorDTO?.errorMap,
    },
    status: {
      ...state.status,
      isUpdating: false,
    },
  }),
  [constants.HISTORY_ITEM_UPDATE_FAIL]: (state, action) => ({
    data: {
      ...state.data,
      id: action.payload.item.id,
    },
    status: {
      ...state.status,
      isUpdating: false,
    },
  }),
  [constants.HISTORY_ITEM_DELETE_FAIL]: (state, action) => ({
    data: {
      ...state.data,
      ...action.payload.item,
    },
    status: {
      ...state.status,
      ...initialState.status,
    },
  }),
  [chartsConstants.CHART_DELETE_SUCCESS]: (state) => ({
    ...state,
    data: {
      ...state.data,
      deleted: true,
    },
  }),
};

export default handleActions(handlers, initialState);
