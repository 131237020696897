import React from 'react';

import cx from 'components/RadioButton/RadioButton.module.scss';

const radioButtonStyles = ({ checked }) =>
  cx[checked ? 'radio-button-active' : 'radio-button']

const RadioButton = ({
	checked,
	onChange,
}) => (
  <div
    className={radioButtonStyles({checked})}
    onClick={onChange}
  >
  </div>
);

export default RadioButton;
