import React from 'react';

import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import PropTypes from 'prop-types';

import cx from './PatientHxItemsTable.module.scss';

const PatientsHxItemsTable = ({
  fields,
  onSort,
  sortBy,
  desc,
  items,
  itemRenderer,
}) => {
  return (
    <div className={cx['container']}>
      <Table>
        <thead>
          <tr>
            {fields.map(
              field => (
                <TableHead
                  {...field}
                  key={field.title}
                  onClick={() => onSort(field.title)}
                  desc={field.title === sortBy ? desc : undefined}
                />
              )
            )}
          </tr>
        </thead>
        <tbody>
          {items.map(itemRenderer)}
        </tbody>
      </Table>
    </div>
  );
};

PatientsHxItemsTable.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    sortable: PropTypes.bool,
  })).isRequired,
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  desc: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemRenderer: PropTypes.func.isRequired,
};

export default PatientsHxItemsTable;
