import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/RouterParams';

import debounce from 'lodash/debounce';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Checkbox from 'components/Checkbox';
import ChartActionsButtons from 'components/ChartActionsButtons';
import PatientAge from 'components/PatientAge';
import ChartReportLink from '../../ChartReportLink';

import ImgSrc from 'helpers/images/styles/img-src';
import BackgroundImageStyle from 'helpers/images/styles/background-image';
import getGenderValue from 'helpers/elements/tags/converters/gender';
import getUserFullName from 'helpers/patient/full-name';
import nullSafeString from 'helpers/common/string/null-safe';

import cx from './ChartReportHeader.module.scss';

const ChartReportHeader = props => {
  const {
    updateChartExtraData,
    chart,
    toggleChartReport,
    patient,
    settings,
    clinic,
    onBack,
    unlockSignedProgressNote,
  } = props;
  const debouncedUpdateChartExtraData = debounce((newChart, prevChart) => updateChartExtraData(newChart, prevChart), 1000);

  const getPracticeAddress = (addressPresence, clinic) => {
    if (addressPresence) {
      const cityStateString = nullSafeString(
        `${clinic.city  } ${  clinic.state  } ${  clinic.zip}`
      );

      return (
        <div className={cx['practice-address']}>
          {clinic.address1}
          {clinic.address2 && <br />}
          {clinic.address2}
          {cityStateString.length && <br />}
          {cityStateString}
        </div>
      );
    }

    return null;
  };

  const getPhoneWrapper = (phone, i) => {
    return (<p key={i} className={cx['practice-phone']}>{phone.number} {phone.type}</p>);
  };

  const getPracticePhones = (phonePresence, faxPresence, phones) => {
    const filteredPhones = phones.filter(o => o.number && o.number.trim().length);

    const faxNumbers = filteredPhones.filter(o => o.type === 'fax');
    const phoneNumbers = filteredPhones.filter(o => o.type !== 'fax');

    return (
      <div className={cx['phones-container']}>
        {phonePresence && phoneNumbers.map(getPhoneWrapper)}
        {faxPresence && faxNumbers.map(getPhoneWrapper)}
      </div>
    );
  };

  const getPatientPhone = (phoneNumber, phoneNumberType) => {
    return (
      <p className={cx.phone}>
        {`${phoneNumber}  ${phoneNumberType}`}
      </p>
    );
  };

  const getPatientPresentedPhoneInfo = ({
    phoneNumber, phoneNumberType,
  }) => {
    if (phoneNumber && phoneNumberType) {
      return { phoneNumber, phoneNumberType };
    }
    return { phoneNumber: '', phoneNumberType: '' };
  };

  const updateChart = (prop, value) => {
    const { chart, updateChart } = props;

    const data = {
      id: chart.id,
      showFMSS: chart.showFMSS,
      showCPT: chart.showCPT,
      showScreening: chart.showScreening,
      showMedAllergy: chart.showMedAllergy,
      comment: chart.comment,
      [prop]: value
    };

    updateChart(data);
    debouncedUpdateChartExtraData(data, chart);
  };

  const signChart = async () => {
    const {
      changeSignedStatus,
      toggleChartReport,
      chart,
      router,
      patient,
    } = props;

    await changeSignedStatus({
      chartId: chart.id,
      signed: !chart.signed,
      callback: toggleChartReport,
    });

    router.push({ pathname: `/app/doctor/patients/${patient.id}/encounters` });
  };

  const {
    phoneNumber,
    phoneNumberType,
    secondPhoneNumber,
    secondPhoneNumberType,
  } = clinic;

  const {
    timeZoneDateConverter,
  } = useContext(AppContext);

  const selectUserDateWithTimeZone = timeZoneDateConverter.getFormattedDateWithTimeZone(chart.selectUserDate);
  const genderValue = getGenderValue(patient.gender);
  const {
    phoneNumber: presentedPatientPhoneNumber,
    phoneNumberType: presentedPatientPhoneNumberType,
  } = getPatientPresentedPhoneInfo(patient);

  return (
    <div className={cx.wrapper}>
      <div className={cx.content}>
        <div className={cx.actions}>
          <ChartReportLink
            onClick={(!chart.signed && toggleChartReport) || onBack}
            className={cx['toggle-report']}
          >
            {chart.signed && 'Back'}
          </ChartReportLink>

          <ChartActionsButtons
            onChangeSignedStatus={signChart}
            signed={chart.signed}
            processingSign={chart.processingSign}
            chartId={chart.id}
            hideSignButton={(chart.signed && !unlockSignedProgressNote) || chart.providerId === 0}
          />

        </div>

        {!chart.signed && (
          <div className={cx['visibility-buttons']}>

            <div className={cx['checkbox-wrapper']}>
              <Checkbox
                checked={chart.showMedAllergy}
                onClick={() => updateChart('showMedAllergy', !chart.showMedAllergy)}
              >
                Med/Allergy List
              </Checkbox>
            </div>

            {' '}

            <div className={cx['checkbox-wrapper']}>
              <Checkbox
                checked={chart.showFMSS}
                onClick={() => updateChart('showFMSS', !chart.showFMSS)}
              >
                F/M/S/S Hx
              </Checkbox>
            </div>

            {' '}

            <div className={cx['checkbox-wrapper']}>
              <Checkbox
                checked={chart.showCPT}
                onClick={() => updateChart('showCPT', !chart.showCPT)}
              >
                Show CPT Code
              </Checkbox>
            </div>

            <div className={cx['checkbox-wrapper']}>
              <Checkbox
                checked={chart.showScreening}
                onClick={() => updateChart('showScreening', !chart.showScreening)}
              >
                Show Screening Hx
              </Checkbox>
            </div>

          </div>
        )}
      </div>
      <div className={cx['patient-doctor-info']}>
        <div className={cx['patient-doctor-info-container']}>
          <div className={cx['visit-info']}>
            <div className={cx['practice-contact-info']}>
              {settings.practiceLogo && (
                <div className={cx['logo-container']}>
                  <img
                    src={ImgSrc.define(clinic.imageUrl)}
                    alt="Clinic"
                  />
                </div>
              )}
              {getPracticeAddress(settings.practiceAddress, clinic)}
              {getPracticePhones(
                settings.practicePhone,
                settings.practiceFax,
                [{ number: phoneNumber, type: phoneNumberType },
                { number: secondPhoneNumber, type: secondPhoneNumberType }]
              )}
            </div>
            {chart.provider && (
              <p className={cx['doctor-name']}>
                <span className={cx.bold}>Provider:</span>
                {chart.provider.name}{chart.provider.suffix && `, ${chart.provider.suffix}`}
              </p>
            )}
            {chart.selectUserDate && (
              <p>
                <span className={cx.bold}>Visit:</span>
                {' '}
                {selectUserDateWithTimeZone}
              </p>
            )}
            <p><span className={cx.bold}>Patient ID:</span> {patient.id}</p>
          </div>

          <div className={cx['patient-info']}>
            <h1 className={cx['patient-name']}>
              {getUserFullName(patient.firstName, patient.middleName, patient.lastName)}
            </h1>
            <p className={cx['patient-data']}>
              <span>
                {patient && `DOB: ${timeZoneDateConverter.formattedDob(patient.dob)}`}
              </span>
              {' '}
              <PatientAge
                dob={patient.dob}
              />
              {' '}
              <span>
                {patient && `SEX: ${genderValue}`}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={cx.line} >
        <div className={cx['image-container']}>
          {(settings.practicePatientPhoto && (
            <div
              className={cx.image}
              style={{
                ...BackgroundImageStyle.define(patient.imageUrl),
              }}
              role="presentation"
            />
          )) || <div className={cx['image-placeholder']} />}
          <div>
            {settings.patientPhoneEmail && getPatientPhone(
                presentedPatientPhoneNumber, presentedPatientPhoneNumberType
              )
            }
            {(settings.patientPhoneEmail && patient.email) && <p className={cx.email}>{patient.email}</p>}
          </div>
        </div>
      </div>

    </div>
  );
};

ChartReportHeader.propTypes = {
  updateChart: PropTypes.func.isRequired,
  changeSignedStatus: PropTypes.func.isRequired,
  toggleChartReport: PropTypes.func.isRequired,
  updateChartExtraData: PropTypes.func.isRequired,
  chart: PropTypes.object.isRequired,
};

export default withRouter(ChartReportHeader);
