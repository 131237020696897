import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { MenuLink } from 'components/MenuLink';

import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './MenuItem.module.scss';

const MenuItem = ({
  url,
  title,
  onClick,
  className = '',
  indexLink = false
}) => {
  const [searchParams] = useSearchParams();
  const searchParamQuery = searchParams.get('search');
  const computedActiveClass = title === 'search' || !!searchParamQuery ? '' : cx['link--active'];

  return (
    <li className={cx.wrapper} onClick={onClick}>
      <MenuLink
        to={url}
        activeLinkStyles={computedActiveClass}
        baseStyles={cssClassesResolver([
          cx.link,
          (title === 'search' && !!searchParamQuery) && cx['link--active'],
          cx[className]
        ])}
        indexLink={indexLink}
      >
        <span className={cx.inner}>
          <span className={cx[`icon--${title}`]}/>
          <span className={cx['text-container']}>
            <span className={cx.text}>{title}</span>
          </span>
        </span>
      </MenuLink>
    </li>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuItem;
