import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';

import boxAuthorizationContainer from 'containers/BoxDotCom/BoxAuthorizationContainer';
import BoxClientFetchLayer from 'containers/BoxDotCom/BoxClientFetchLayer';

import BoxService from 'components/BoxService';

import cx from './BoxComTab.module.scss';

const BoxServiceContainer = compose(
  withRouter,
  boxAuthorizationContainer,
  BoxClientFetchLayer
)(BoxService);

export const BoxComTab = () => (
  <div className={cx['tab-content']}>
    <BoxServiceContainer />
  </div>
);
