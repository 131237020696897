import React, { useContext, useState } from 'react';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ChartReportElementWithTooltip from '../../../../ChartReportElementWithTooltip';
import RemoveButton from '../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../ChartReportElementWithTooltip/DoorButton';
import PosNegButton from 'components/PosNegButton';

import cx from './ReportElement.module.scss';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

const MENU_BUTTON_DIAMETER = 40;

const elementNameStyles = ({ hasNewlines, zeroIndex, hasComma, isPositive }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [
    'white-space',
    hasNewlines && 'block-element', (hasNewlines && zeroIndex) && 'block-element--first', hasComma && 'with-comma'
  ],
  globalStyles: isPositive && 'text-bold'
});

const ReportElement = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => setIsMenuOpen(true);

  const closeMenu = () => setIsMenuOpen(false);

  const {
    element,
    removeElement,
    toggleChartReport,
    chartId,
    step,
    systemId,
    isROS,
    onUpdate,
    viewMode,
    patient,
    index,
    elementsCount,
  } = props;

  const {
    elementNameConverter,
  } = useContext(AppContext);

  const displayName = elementNameConverter.transformElementNameWithSubElements(element, patient);

  const hasNewlines = (/\n/g).test(displayName);

  const hasComma = hasNewlines && index !== elementsCount - 1;

  const divider = hasComma ? ', ' : '';

  const resolvedStyles = elementNameStyles({
    hasComma, hasNewlines, zeroIndex: index === 0, isPositive: element.type
  });

  return (
    <ChartReportElementWithTooltip
      isOpen={isMenuOpen}
      onOpen={openMenu}
      onClose={closeMenu}
      disabled={viewMode}
    >
      <span
        className={cx['no-wrap']}
      >
        {isROS && element.type && <span className={cx.positive} /> }
        {isROS && !element.type && <span className={cx.negative} />}
        {isROS && (
          '\u00A0'
        )}
        <span
          className={resolvedStyles}
        >
          {displayName}{divider}
        </span>
        {element.ids && !!element.ids.filter(o => o).length && (
          <span className={cx.diagnoses}>
            ({element.ids.filter(o => o).map(diagnosis => diagnosis.name).join(', ')})
          </span>
        )}
      </span>
      <div className="flex-container">
        <DoorButton
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
            marginRight: 10,
          }}
          link={`/app/doctor/charts/${chartId}?step=${step}&system=${systemId}`}
          onClick={() => {
            closeMenu();
            toggleChartReport();
          }}
        />
        {isROS && (
          <PosNegButton
            neg={element.type}
            onClick={onUpdate}
            diameter={MENU_BUTTON_DIAMETER}
          />
        )}
        <RemoveButton
          onClick={removeElement}
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
          }}
        />
      </div>
    </ChartReportElementWithTooltip>
  );
};

export default ReportElement;
