import React from 'react';
import PropTypes from 'prop-types';

import TextArea from 'components/TextArea';

import cx from './CommentBox.module.scss';

const CommentBox = (props) => {
  const {
    placeholder,
    title,
    value = null,
    onChange,
  } = props;

  return (
    <div className="wrapper">
      {title && <h3 className={cx.title}>{title}</h3>}
      <TextArea
        value={value || ""}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}

CommentBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CommentBox;
