import React from 'react';
import PropTypes from 'prop-types';

import PosNegButton from 'components/PosNegButton';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './ElementsViewItem.module.scss';

const favoriteStyles = ({ isBroad, isExact }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [isBroad && 'broadMatch', isExact && 'exactMatch']
})

const ROSItem = (props) => {

  const { match, item } = props

  const resolvedStyles = favoriteStyles({ isBroad: match === 'broad', isExact: match === 'exact' })

  return (
    <div className={cx.wrapper}>
      <div className={`${cx.item} break-text`}>
        <PosNegButton
          onClick={() => props.onClick(true)}
          className={resolvedStyles}
        />
        {' '}
        <PosNegButton
          neg
          onClick={() => props.onClick(false)}
          className={resolvedStyles}
        />
        {' '}
        <span className={`${cx['item-name']} ${cx['item-name--ros']}`}>{item.name}</span>
      </div>
    </div>
  );
};

ROSItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  match: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

ROSItem.defaultProps = {
  match: '',
};

export default ROSItem;
