import React from 'react';
import PropTypes from 'prop-types';


import TabTitle from 'components/TabTitle';

import cx from './TabsHeaderItem.module.scss';
import { useLocation } from 'react-router-dom';
import { MenuLink } from 'components/MenuLink';

const TabsHeaderItem = ({
  type,
  title,
  url,
  subtitle,
  indexLink,
  disabled,
  active,
  onClick,
  mark,
  isSingle,
}) => {
  const { pathname } = useLocation();

  let wrapperClass = type === 'inner' ? 'wrapper-inner' : 'wrapper';

  // if this is the only link in header panel
  if (isSingle) {
    return (
      <span className={cx[`${wrapperClass}--active`]}>
        <span style={{ textDecoration: 'none' }} className={cx.title}>{title}{mark && <span className={cx.mark} />}</span>
        <span className={cx.subtitle}>{subtitle || '\u00a0'}</span>
      </span>
    );
  }

  // Warning! A critical workaround for making General tab button
  // active at '/app/doctor/settings/preferences' path.
  if (pathname === '/app/doctor/settings/preferences' && url === '/app/doctor/settings') {
    wrapperClass = `${wrapperClass}--active`;
  }

  if (url) {
    return (
      <MenuLink
        to={url}
        baseStyles={disabled ? cx[`${wrapperClass} disabled`] : cx[`${wrapperClass}`]}
        activeLinkStyles={cx[`${wrapperClass}--active`]}
        indexLink={indexLink}
      >
        <span
          className={cx.title}
        >
          <TabTitle>
            {title}
          </TabTitle>
          {mark && <span className={cx.mark} />}
        </span>
        {/* non-breaking space here to maintain height */}
        <span className={cx.subtitle}>{subtitle || '\u00a0'}</span>
        {active}
      </MenuLink>
    );
  }

  return (
    <div
      className={cx(active ? `${wrapperClass}--active` : wrapperClass)}
      onClick={onClick}
    >
      {title}
      {subtitle}
      {active}
    </div>
  );
};

TabsHeaderItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  mark: PropTypes.bool,
  isSingle: PropTypes.bool,
};

export default TabsHeaderItem;
