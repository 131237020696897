import { handleActions } from 'redux-actions';

import * as assetsConstants from 'modules/chartingAssets/constants';

export default handleActions({
  [assetsConstants.SYSTEM_CREATE]: () => true,

  [assetsConstants.SYSTEM_CREATE_SUCCESS]: () => false,

  [assetsConstants.SYSTEM_UPDATE]: () => true,

  [assetsConstants.SYSTEM_UPDATE_SUCCESS]: () => false,
}, null);

export const getIsSystemSaving = state => state;
