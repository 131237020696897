export const CHART_SYSTEM_NOTES_FETCH = 'PATIENT_SYSTEM_NOTES_FETCH';
export const CHART_SYSTEM_NOTES_FETCH_SUCCESS = 'PATIENT_SYSTEM_NOTES_FETCH_SUCCESS';
export const CHART_SYSTEM_NOTES_FETCH_FAIL = 'PATIENT_SYSTEM_NOTES_FETCH_FAIL';

export const SYSTEM_NOTE_FETCH = 'SYSTEM_NOTE_FETCH';
export const SYSTEM_NOTE_FETCH_SUCCESS = 'SYSTEM_NOTE_FETCH_SUCCESS';
export const SYSTEM_NOTE_FETCH_FAIL = 'SYSTEM_NOTE_FETCH_FAIL';

export const SYSTEM_NOTE_CREATE = 'SYSTEM_NOTE_CREATE';
export const SYSTEM_NOTE_CREATE_SUCCESS = 'SYSTEM_NOTE_CREATE_SUCCESS';
export const SYSTEM_NOTE_CREATE_FAIL = 'SYSTEM_NOTE_CREATE_FAIL';

export const SYSTEM_NOTE_UPDATE = 'SYSTEM_NOTE_UPDATE';
export const SYSTEM_NOTE_UPDATE_SUCCESS = 'SYSTEM_NOTE_UPDATE_SUCCESS';
export const SYSTEM_NOTE_UPDATE_FAIL = 'SYSTEM_NOTE_UPDATE_FAIL';

export const SYSTEM_NOTE_DELETE = 'SYSTEM_NOTE_DELETE';
export const SYSTEM_NOTE_DELETE_SUCCESS = 'SYSTEM_NOTE_DELETE_SUCCESS';
export const SYSTEM_NOTE_DELETE_FAIL = 'SYSTEM_NOTE_DELETE_FAIL';
