import React, { useRef } from 'react'
import { arrayOf, shape, number, string, func } from 'prop-types'

import Option from 'components/MedSelect/SimpleSelect/Option'

import isEmpty from 'helpers/common/array/is-empty'

import cx from './SimpleSelect.module.scss'

const Options = (props) => {
  const optionsRef = useRef()
  const {
    options,
    onSelect,
    onRemove,
    closeOptions,
    checkAllowingToRemoveOption
  } = props

  if (isEmpty(options)) {
    return null
  }

  return (
    <div
      className={cx['dropdown-container']}
      ref={optionsRef}
    >
      <button
        type="button"
        className={cx['close-options']}
        onClick={closeOptions}
      >
        CLOSE
      </button>
      {options.map(option =>
        <Option
          isAllowToRemove={checkAllowingToRemoveOption(option)}
          key={option.id}
          option={option}
          onSelect={() => onSelect(option)}
          onRemove={() => onRemove(option.id)}
        />
      )}
    </div>
  )
}

Options.propTypes = {
  onSelect: func.isRequired,
  checkAllowingToRemoveOption: func.isRequired,
  closeOptions: func.isRequired,
  onRemove: func.isRequired,
  options: arrayOf(shape({
    id: number,
    label: string
  })).isRequired
}

export default Options
