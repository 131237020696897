import React from 'react';
import cx from './ChartReportMedications.module.scss';
import ChartReportTable from '../../ChartReportTable';

import { withRouter } from 'containers/RouterParams';

import ChartReportMedication from './ChartReportMedication';

const headers = [
  {
    title: 'Current medication & dosage',
  },
  {
    title: 'Sig',
  },
  {
    title: 'Associated diagnosis',
  },
];

const ChartReportMedications = (props) => {
  const {
    medications = [],
    toggleChartReport,
    deleteMedication,
    location,
    chartId,
    viewMode,
  } = props;

  if (!medications.length) {
    return <div className={cx['no-medications-wrapper']}>No active medications</div>;
  }

  const currentMedication = location.query.medication && parseInt(location.query.medication);

  return (
    <ChartReportTable
      headers={headers}
    >
      {medications.map(medication => (
        <ChartReportMedication
          key={medication.id}
          medication={medication}
          toggleChartReport={toggleChartReport}
          onRemove={() => deleteMedication(
              medication,
              currentMedication === medication.id && location.pathname
            )}
          chartId={chartId}
          viewMode={viewMode}
        />
        ))}
    </ChartReportTable>
  );
};

export default withRouter(ChartReportMedications);
