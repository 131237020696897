import React from 'react';

import cx from './ChartReportTable.module.scss';

const ChartReportTable = ({ children, headers }) => {
  return (
    <table className={cx['table']}>
      {headers && (
      <thead>
        <tr className={cx['header']}>
          {headers.map(header => <th {...header} key={header.title}>{header.title}</th>)}
        </tr>
      </thead>
        )}
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

export default ChartReportTable;
