import * as api from 'api/user';
import * as userConstants from './constants';
import * as apiPermissions from 'api/permissions';

export const fetchUser = () => ({
  types: [
    userConstants.USER_FETCH,
    userConstants.USER_FETCH_SUCCESS,
    userConstants.USER_FETCH_FAIL,
  ],
  promise: () => api.fetchUser(),
});

export const fetchUserId = () => ({
  types: [
    userConstants.USER_ID_FETCH,
    userConstants.USER_ID_FETCH_SUCCESS,
    userConstants.USER_ID_FETCH_FAIL,
  ],
  promise: () => api.checkExistingEmail(),
});

export const updatePermissions = (permissionIds, roleId) => ({
  types: [
    userConstants.PERMISSIONS_UPDATE,
    userConstants.PERMISSIONS_UPDATE_SUCCESS,
    userConstants.PERMISSIONS_UPDATE_FAIL,
  ],
  promise: () => apiPermissions.setPermissions(permissionIds, roleId),
});

export const updatePermissionsForCurrentUserLocally = newPermissions => ({
  type: userConstants.PERMISSIONS_UPDATE_FOR_CURRENT_USER_LOCALLY,
  payload: {
    newPermissions,
  },
});
