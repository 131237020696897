import { FMSS_TYPES } from 'helpers/fmss/constants';
import { EMPTY_MESSAGE_FAMILY } from 'helpers/fmss/family/constants';
import { EMPTY_MESSAGE_MEDICAL } from 'helpers/fmss/medical/constants';
import { EMPTY_MESSAGE_SURGICAL } from 'helpers/fmss/surgical/constants';

export default class EmptyMessageConstantFactory {
  static getConstant = (fmssType) => {
    switch (fmssType) {
      case FMSS_TYPES.FAMILY:
      case FMSS_TYPES.PROGRESS_NOTE_FAMILY:
        return EMPTY_MESSAGE_FAMILY;
      case FMSS_TYPES.MEDICAL:
      case FMSS_TYPES.PROGRESS_NOTE_MEDICAL:
        return EMPTY_MESSAGE_MEDICAL;
      case FMSS_TYPES.SURGICAL:
      case FMSS_TYPES.PROGRESS_NOTE_SURGICAL:
        return EMPTY_MESSAGE_SURGICAL;
    }
  }
}
