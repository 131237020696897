import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import { ConsentRow } from './ConsentRow';
import Modal from 'components/Modal';
import { ConsentForm } from './ConsentForm';

import { addConsents, getConsentIds } from 'modules/consent';
import { listConsents } from 'api/consent';
import { build } from 'helpers/URLUtils';
import AddButton from 'components/AddButton';

import cx from './ConsentForm.module.scss';

const columns = [
  {
    title: 'name',
    subtitle: '',
    sortable: true,
    width: '25%',
  },
  {
    title: 'Date added',
    subtitle: '',
    sortable: true,
    width: '35%',
  },
];

const emptyColumn = {
    title: '',
    subtitle: '',
    sortable: false,
    width: '40%',
};

export const ConsentTable = () => {
  const [sortBy, setSortBy] = useState('name');
  const [desc, setDesc] = useState(true);
  const dispatch = useDispatch();
  const consentIds = useSelector(getConsentIds);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleConsentForm = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    const listConsentsWrapper = async () => {
      const response = await listConsents();
      const list = await response.json();
      dispatch(addConsents(list));
    };

    listConsentsWrapper();
  }, []);

  const setSortWrapper = (column) => {
    if (sortBy === column) {
      setDesc(prev => !prev);
      return;
    }
    setSortBy(column);
    setDesc(false);
  };

  const navigateToConsentDetail = useCallback(id => {
    navigate(build({ pathname, query: { consentId: id } }));
  }, []);

  return (
    <>
      <Table>
        <thead>
          <tr className={cx['table__head']}>
            {columns.map((column, index) => (
              <TableHead
                {...column}
                key={`${column.title}_${index}`}
                onClick={() => setSortWrapper(column.title)}
                desc={column.title === sortBy ? desc : undefined}
              />
            ))}
            <TableHead
              {...emptyColumn}
            >
              <AddButton
                onClick={toggleConsentForm}
                diameter={24}
              >
                Add consent form
              </AddButton>
            </TableHead>
          </tr>
        </thead>
        <tbody>
          {consentIds.map(
            consentId =>
              <ConsentRow
                key={consentId}
                id={consentId}
                onClick={navigateToConsentDetail}
              />
          )}
        </tbody>
      </Table>
      <Modal
        isOpen={isOpen}
        data={{
          type: 'consent',
          title: 'Consent',
          content: '',
        }}
        onModalClose={toggleConsentForm}
      >
        <ConsentForm confirm={toggleConsentForm} reset={toggleConsentForm} />
      </Modal>
    </>
  );
};