import getHistorySearchingResultsOptions from 'helpers/fmss/searching-results/options/strategies/abstract';

const getSearchingResultsOptionsBasicStrategy = (searchingItems, input = '') =>
  getHistorySearchingResultsOptions({
    searchingItems,
    iteratees: [
      item => item.name.toUpperCase().indexOf(input.toUpperCase()),
      item => item.name.toUpperCase(),
    ],
  });


export default getSearchingResultsOptionsBasicStrategy;
