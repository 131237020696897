import React from 'react';
import PropTypes from 'prop-types';

import orderBy from 'lodash/orderBy';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import Table from 'components/Table';
import FixedHeader from 'components/Table/FixedHeader';
import PatientLine from '../PatientLine';

import { DATE_FORMATS } from 'helpers/date/constants';
import { getScrollBarWidth } from 'helpers';
import tableFields from './fields';

import cx from './PatientsList.module.scss';

let prevIsScrollVisible = null;

export default class PatientsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'patient id',
      desc: true,
    };
  }

  componentDidMount() {
    // to determine if table has scrollbar we pass onTableMount callback to it Table
    // component mounts first and after fixed header and there's no this.table in
    // isScrollVisible available to ensure that we know if there's scrollbar present
    // we should forceUpdate on mount so this component fully rerenders and calls
    // isScrollVisible when this.table is already present
    this.forceUpdate();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getSortedItems = (patients) => {
    const { sortBy, desc } = this.state;
    const direction = desc ? 'desc' : 'asc';

    let sortCriteria = sortBy;

    switch (sortBy) {
      case 'first':
      case 'last':
        sortCriteria = o => o[sortBy].toLowerCase();
        break;
      default:
        break;
    }

    const {
      timeZoneDateConverter,
    } = this.context;

    return orderBy(patients.map(patient => ({
      id: patient.id,
      'patient id': patient.id,
      first: patient.firstName,
      last: patient.lastName,
      dob: patient.dob,
      phone: patient.phone,
      'last encounter': patient.lastEncounter,
      active: patient.active,
    })), sortCriteria, direction)
      .map(patient => ({
        ...patient,
        dob: timeZoneDateConverter.formattedDob(patient.dob),
        'last encounter': patient['last encounter'] && timeZoneDateConverter.getFormattedDateWithTimeZone(patient['last encounter'], DATE_FORMATS.DOB),
      }));
  };

  handleTableMount = (ref) => {
    this.table = ref;
  };

  isScrollVisible = () => {
    if (!this.table) {
      return false;
    }

    return this.table.scrollHeight > this.table.clientHeight;
  };

  handleWindowResize = () => {
    if (prevIsScrollVisible !== this.isScrollVisible()) {
      this.forceUpdate();
    }
  };

  sort = (column, { sortBy, desc }) => {
    if (sortBy === column) {
      this.setState({ desc: !desc });
      return;
    }
    this.setState({
      sortBy: column,
      desc: false,
    });
  };

  render() {
    const { patients } = this.props;

    const {
      sort,
      handleTableMount,
      isScrollVisible,
      getSortedItems,
    } = this;

    const { sortBy, desc } = this.state;

    prevIsScrollVisible = isScrollVisible();
    const renderedItems = getSortedItems(Object.values(patients));

    return (
      <div className={cx['wrapper']}>
        <FixedHeader
          fields={tableFields}
          style={{ paddingRight: isScrollVisible() ? getScrollBarWidth() : 0 }}
          onSort={column => sort(column, { sortBy, desc })}
          desc={desc}
          sortBy={sortBy}
        />
        <Table
          color="blue"
          bodyColor="gray"
          style={{ marginTop: 55, height: 'calc(100% - 55px)' }}
          onTableMount={handleTableMount}
        >
          <tbody>
            {renderedItems.map(patient => (
              <PatientLine
                patient={patient}
                key={patient['patient id']}
                fields={tableFields}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

PatientsList.propTypes = {
  patients: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),
    PropTypes.array,
  ]).isRequired,
};

PatientsList.contextType = AppContext;
