import 'moment-timezone';
import moment from 'moment';

import { DATE_FORMATS } from 'helpers/date/constants';
import DateTimeSetter from 'helpers/date/date-time-setter';
import { isNullOrUndefined } from 'helpers';

export default class TimeZoneDateConverter {
  constructor(timeZoneSettings) {
    this.timeZoneSettings = timeZoneSettings;
  }

  transformCurrentDate = () => {
    return moment.tz(this.timeZoneSettings.getTimeZone());
  };

  transformByDate = (date) => {
    return moment(date).tz(this.timeZoneSettings.getTimeZone());
  };

  getFormattedDateWithTimeZone = (dateInMs, format = DATE_FORMATS.DEFAULT) => {
    if (isNullOrUndefined(dateInMs)) {
      return null;
    }
    return this.transformByDate(dateInMs).format(format);
  };

  getDateInMs = (momentDate) => {
    return momentDate.utc().valueOf();
  };

  getTimeZoneOffset = date =>
    moment.tz.zone(this.timeZoneSettings.getTimeZone()).utcOffset(date);

  getDateWithUTCAndTimeZoneOffset = (dateInMs, time) => {
    const dateWithTime = DateTimeSetter.formatTime(dateInMs, time);

    const dateTimeZoneOffset = this.getTimeZoneOffset(dateWithTime.valueOf());

    return dateWithTime.add(dateTimeZoneOffset, 'minutes').utc();
  };

  convertStringDateToMs = (stringDate) => {
    const newDate = this.transformByDate(stringDate);

    return newDate.valueOf();
  };

  calculatePatientAge = (dob) => {
    return moment().diff(moment(dob), 'years');
  };

  formattedDob = (dobInMs, format = DATE_FORMATS.DEFAULT) => {
    return moment.utc(dobInMs).format(format);
  };

  getCurrentTimeZoneDateMs = () => {
    return this.getDateInMs(this.transformCurrentDate());
  };

  getTimeZoneDateMs = (date) => {
    const momentWithTimeByTimeZone = this.setCurrentTimeZoneTimeToMoment(date);
    return this.getDateInMs(momentWithTimeByTimeZone);
  };

  setCurrentTimeZoneTimeToMoment = (momentObject) => {
    const currentDateByTimeZone = this.transformCurrentDate();

    return momentObject
      .hour(currentDateByTimeZone.get('hour'))
      .minutes(currentDateByTimeZone.get('minutes'))
      .seconds(currentDateByTimeZone.get('seconds'));
  };
}
