const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchSystemNote = data =>
  request
    .post('/api/rest/systemdetails/search')
    .send(data)
    .end();

export const createSystemNote = data =>
  request
    .post('/api/rest/systemdetails/create')
    .send(data)
    .end();

export const updateSystemNote = data =>
  request
    .post('/api/rest/systemdetails/update')
    .send(data)
    .end();

export const deleteSystemNote = data =>
  request
    .del('/api/rest/systemdetails/delete')
    .send(data)
    .end();

export const fetchByChart = chartId =>
  request
    .get(`/api/rest/systemdetails/chart/${chartId}`)
    .end();
