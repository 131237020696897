import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import RadioButtons from 'components/RadioButtons';
import TextInput from 'components/TextInput';
import textInputCx from 'components/TextInput/TextInput.module.scss';
import UserInviteForm from 'routes/routes/App/routes/Settings/routes/Users/components/UserInviteForm';
import UserFormLinks from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm/UserFormLinks';
import ValidatedField from 'components/Form/ValidatedField';
import UploadImageWrapper from 'components/UploadImageWrapper';
import SignatureInput from 'routes/routes/App/routes/Settings/routes/Users/components/UserForm/Info/SignatureInput';

import * as permissionsHelpers from 'helpers/permissions';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import DefaultClinicImage from 'helpers/images/defaults/clinic';

import { sendRecoveryEmail } from 'api/auth';

import cx from 'components/EditForm/FormAssets.module.scss';

const itemWrapperStyles = () => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: 'row'
});

const quickPasswordLabelStyles = () => resolveStylesV2({
  objectStyles: textInputCx,
  moduleStyles: 'title',
  globalStyles: 'section-title'
});

const pinWrapperStyles = () => resolveStylesV2({
  objectStyles: textInputCx,
  moduleStyles: ['wrapper', 'pin-wrapper']
});

export const statusItems = [{
  name: 'Active',
  value: true,
}, {
  name: 'Inactive',
  value: false,
}];

const masks = {
  phoneNumber: '(999) 999-9999',
  npi: '9999999999',
  taxId: '99-9999999',
};

export default class UserFormInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resettingPassword: false,
      resetMessage: null,
      resetError: false,
    };
  }

  // componentDidUpdate(prevProps) {
  //   const errorMsg = renderError('This login already exists');
  //
  //   if (this.props.userId !== prevProps.userId) {
  //     this.setValidityState({ silent: true });
  //     this.setState({
  //       resettingPassword: false,
  //       resetMessage: null,
  //       resetError: false,
  //     });
  //   }
  //
  //   if (this.props.emailExists) {
  //     this.form.showError('login', () => errorMsg);
  //   }
  //
  //   const isChangedImageUrl = this.props.user.imageUrl !== prevProps.user.imageUrl;
  //   const isChangedSignature = this.props.user.signature !== prevProps.user.signature;
  //   isChangedImageUrl && this.updateSignatureUrl(this.props.user.imageUrl);
  //   isChangedSignature && this.updateSignature(this.props.user.signature);
  // }

  updateSignatureUrl = (imageUrl) => {
    const { updateUser } = this.props;

    updateUser('imageUrl', imageUrl);
  };

  updateSignature = ({ imageName, imageUrl }) => {
    const { updateUser } = this.props;

    updateUser('signature', imageName);
    this.updateSignatureUrl(imageUrl);
  };

  updateUser = (prop, value) => {
    const { updateUser } = this.props;

    updateUser(prop, value);
  };

  resetPassword = async () => {
    this.setState({ resettingPassword: true, resetMessage: null, resetError: false });

    try {
      await sendRecoveryEmail({ login: this.props.user.login });
      this.setState({
        resettingPassword: false,
        resetMessage: 'Message sent! Check your mailbox',
      });
    } catch (e) {
      this.setState({
        resettingPassword: false,
        resetMessage: 'Something went wrong',
        resetError: true,
      });
    }
  };

  isEditForm() {
    return !!this.props.userId;
  }

  renderUserFormLinks = ({
    valid, isEditForm, userId, emailExists,
  }) => {
    const jsx = <UserFormLinks userId={userId} activeStep="info" />;

    if (isEditForm) {
      return jsx;
    }

    // emailExists может принимать значение null, если проверка еще не проводилась,
    // поэтому нельзя сокращать до !emailExists
    if (valid && emailExists === false) {
      return jsx;
    }
  };

  render() {
    const {
      userId,
      isUpdating,
      isCreating,
      isCurrentUser,
      isProvider,
      user,
      onCancel,
      fetchUserImage,
      onSubmit,
      updatePermissions,
      roles
    } = this.props;

    const {
      del,
      updateUser,
      renderUserFormLinks,
    } = this;

    const {
      valid,
      resettingPassword,
      resetMessage,
      resetError,
    } = this.state;

    const emailExists = this.props.emailExists !== null ?
      this.props.emailExists :
      this.state.emailExists;

    const fetching = userId ? isUpdating : isCreating;

    const isEditForm = !!userId;

    const hasSignEncounterPermission = permissionsHelpers.isUserPermissionsContainPermission(
      user.permissions,
      permissionsHelpers.purePermissions.SIGN_ENCOUNTER_NOTE
    );

    return (
        <form
          onSubmit={onSubmit}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={this.isEditForm() ? 'Edit user' : 'Add user'}
              />

              {!isEditForm && (
                <UserInviteForm
                  roles={roles}
                  updatePermissions={updatePermissions}
                  userLogin={user.login}
                  userPermissions={user.permissions}
                  updateUser={e => updateUser('login', e.target.value)}
                  isShowSubmitButton={false}
                />
              )}

              {
                renderUserFormLinks({
                  valid,
                  isProvider,
                  isEditForm,
                  userId,
                  emailExists,
                })
              }

              {!isCurrentUser && (
                <div className={itemWrapperStyles()}>
                  <RadioButtons
                    title="Status"
                    active={user.enable}
                    items={statusItems}
                    onChange={value => updateUser('enable', value)}
                    required
                  />
                </div>
              )}

              <div className={itemWrapperStyles()}>
                <ValidatedField>
                  <TextInput
                    name="firstName"
                    title="First name"
                    value={user.firstName}
                    onChange={e => updateUser('firstName', e.target.value)}
                  />
                </ValidatedField>
              </div>

              <div className={itemWrapperStyles()}>
                <ValidatedField>
                  <TextInput
                    name="lastName"
                    title="Last name"
                    value={user.lastName}
                    onChange={e => updateUser('lastName', e.target.value)}
                  />
                </ValidatedField>
              </div>

              {isEditForm && (
                <div className={itemWrapperStyles()}>
                  <ValidatedField>
                    <TextInput
                      name="login"
                      title="Email"
                      value={user.login}
                      onChange={e => updateUser('login', e.target.value)}
                    />
                  </ValidatedField>
                </div>
              )}
              {(hasSignEncounterPermission) && (
                <div className={itemWrapperStyles()}>
                  <TextInput
                    name="npi"
                    title="Npi"
                    value={user.npi}
                    onChange={e => updateUser('npi', e.target.value)}
                    mask={masks.npi}
                  />
                </div>
              )}

              <div className={itemWrapperStyles()}>
                <TextInput
                  name="suffix"
                  title="suffix"
                  value={user.suffix}
                  onChange={e => updateUser('suffix', e.target.value)}
                />
              </div>

              {(hasSignEncounterPermission) && (
                <div className={itemWrapperStyles()}>
                  <UploadImageWrapper
                    imageName={user.signature}
                    onChange={this.updateSignatureUrl}
                    entityId={user.id}
                    imageUrl={user.imageUrl}
                    fetchImage={fetchUserImage}
                  >
                    <SignatureInput
                      wrapperClass="demographics-wrapper"
                      onChange={this.updateSignature}
                      title="Signature"
                      imageUrl={user.imageUrl}
                      defaultImage={DefaultClinicImage.get()}
                      isDownloadingImage={false}
                      entityId={user.id}
                    />
                  </UploadImageWrapper>
                </div>
              )}

              {!isEditForm && (
                <div className={itemWrapperStyles()}>
                <ValidatedField>
                  <TextInput
                    name="password"
                    title="Password"
                    type="password"
                    value={user.password}
                    onChange={e => updateUser('password', e.target.value)}
                  />
                </ValidatedField>
                </div>
              )}
              {!isEditForm && (
                <div className={itemWrapperStyles()}>
                <ValidatedField>
                  <TextInput
                    name="confirmedPassword"
                    title="Confirm password"
                    type="password"
                    value={user.confirmedPassword}
                    onChange={e => updateUser('confirmedPassword', e.target.value)}
                  />
                </ValidatedField>
                </div>
              )}

              <div className={itemWrapperStyles()}>
                  <h3 className={quickPasswordLabelStyles()}>
                    Quick password (6-digit)
                  </h3>
                  <div className={pinWrapperStyles()}>
                    <input
                      type="password"
                      className={textInputCx.input}
                      value={user.pin}
                      onChange={e => updateUser('pin', e.target.value)}
                    />
                  </div>
              </div>

              {isEditForm && (
                <div
                  className={cx.row}
                  style={{ textAlign: 'right' }}
                >
                  {resettingPassword && (
                    <Loader
                      isStatic
                      secondaryColor="#e8f0f3"
                      style={{
                        verticalAlign: 'middle',
                        marginRight: 10,
                        width: 20,
                        height: 20,
                      }}
                    />
                  )}

                  <button
                    type="button"
                    className="generic-button"
                    onClick={this.resetPassword}
                  >
                    Reset Password
                  </button>
                  {resetMessage && (
                    <div
                      style={{
                        color: (resetError && 'red') || 'green',
                      }}
                    >
                      {resetMessage}
                    </div>
                  )}
                </div>
              )}
            </div>

            <EditFormActions
              // disable user deletion for now
              deleteButton={false}
              cancelButton
              submitButton
              submitButtonText={isEditForm ? 'Save' : 'Next'}
              disabledSubmit={fetching}
              disabledCancel={fetching}
              onDelete={del}
              onCancel={onCancel}
            />
          </EditForm>

        </form>
    );
  }
}

UserFormInfo.propTypes = {
  user: PropTypes.object.isRequired,
  userId: PropTypes.number,
  updateUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  isCreating: PropTypes.bool,
  isCurrentUser: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isProvider: PropTypes.bool,
  emailExists: PropTypes.bool,
  fetchUserImage: PropTypes.func.isRequired,
  isDownloadingSignature: PropTypes.bool.isRequired,
  updatePermissions: PropTypes.func.isRequired
};
