import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RegularElement from './RegularElement';
import ROSItem from './RosItem';
import ElementEditButton from 'components/ElementEditButton';
import SaveRoundButton from 'components/SaveRoundButton';
import CancelRoundButton from 'components/CancelRoundButton';

import { isNullOrUndefined, isEnoughStringLength } from 'helpers';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import stringParser from 'helpers/common/string/string-parser';

import cx from './ElementsViewItem.module.scss';
import { useParams } from 'react-router';

const styles = ({ editMode }) => ({
  form: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['outer-wrapper', editMode && 'outer-wrapper--edit']
  }),
  editButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'control-button-wrapper',
    globalStyles: editMode && 'hidden'
  }),
  editButton: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['edit-button', 'control-button']
  }),
  saveButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['control-button-wrapper', 'save-button-wrapper'],
    globalStyles: !editMode && 'hidden'
  }),
  cancelButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'control-button-wrapper',
    globalStyles: !editMode && 'hidden'
  })
});

const Item = (props) => {
  const {
    item,
    updateItem,
    onClick,
    activeSystem,
    match,
    isROS,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [itemName, setItemName] = useState(item.name);
  const [originalItemName, setOriginalItemName] = useState(item.name);
  const [saving, setSaving] = useState(false);
  const { chartId } = useParams();

  useEffect(() => {
    setItemName(item.name);
  }, [item]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSystemNameChanged()) return;

    setSaving(true);
    try {
      await updateItem({
        id: item.id,
        name: itemName,
        order: item.order,
        chartId: stringParser(chartId),
        chartingId: item.chartingId,
        type: item.type,
      });

      setEditMode(false);
      setOriginalItemName(itemName);
    } finally {
      setSaving(false);
    }
  };

  const isSystemNameChanged = () => {
    return itemName === item.name || !isEnoughStringLength(itemName);
  };

  const toggleEdit = () => {
    setEditMode((prevEditMode) => !prevEditMode);
    setItemName(originalItemName);
  };

  const updateItemName = (e) => {
    setItemName(e.target.value);
  };

  const editable = (item.chartingId && item.type === 5) || item.type === 4;
  const elementViewItem = { ...item, name: itemName };
  const resolvedStyles = styles({ editMode });

  if (isROS && !isNullOrUndefined(activeSystem)) {
    return <ROSItem onClick={onClick} item={elementViewItem} match={match} />;
  }

  return (
    <form className={resolvedStyles.form} onSubmit={handleSubmit}>
      <RegularElement
        onClick={onClick}
        item={elementViewItem}
        match={match}
        editMode={editMode}
        onChange={updateItemName}
      />
      {editable && (
        <span>
          <span className={resolvedStyles.editButtonWrapper}>
            <ElementEditButton
              className={resolvedStyles.editButton}
              onClick={toggleEdit}
            />
          </span>
          <span className={resolvedStyles.saveButtonWrapper}>
            <SaveRoundButton className={cx['control-button']} loading={saving} />
          </span>
          <span className={resolvedStyles.cancelButtonWrapper}>
            <CancelRoundButton
              className={cx['control-button']}
              onClick={toggleEdit}
            />
          </span>
        </span>
      )}
    </form>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    systemId: PropTypes.number,
    locked: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  activeSystem: PropTypes.number,
  match: PropTypes.string,
  isROS: PropTypes.bool,
  updateItem: PropTypes.func,
};

export default Item;
