import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';

import CancelRoundButton from 'components/CancelRoundButton';
import SaveRoundButton from 'components/SaveRoundButton';

import striptags from 'vendor/striptags/';
import { elementTemplateMap } from 'helpers/chart';
import { isCtrl, isEnter } from 'helpers/KeyboardUtils';

import cx from './ElementEditForm.module.scss';

class ElementEditForm extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  handleChange = e => {
    const { value } = e.target;

    this.setState({ value }, () => {
      this.props.onChange();
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    const value = striptags(this.state.value.trim(), Object.keys(elementTemplateMap));

    if (value === '') return;

    this.props.onSave(value);
  };

  textareaKeyboardHandler = (e) => {
    if (isEnter(e) && isCtrl(e)) {
      this.handleSave(e);
    }
  };

  render() {
    const { onCancel, isUpdating } = this.props;

    const valuesAreEqual = this.state.value === this.props.value;

    return (
      <form
        className={cx.wrapper}
        onSubmit={this.handleSave}
      >
        <Textarea
          value={this.state.value}
          onChange={this.handleChange}
          ref={ref => { ref?.focus(); }}
          className={cx.textarea}
          onKeyDown={this.textareaKeyboardHandler}
        />

        <div
          className={cx['control-buttons']}
          style={{
            marginLeft: (!valuesAreEqual && -(30 + 30 + 5) / 2) || -30 / 2,
          }}
        >
          {!valuesAreEqual && (
            <SaveRoundButton
              loading={isUpdating}
            />
          )}

          <CancelRoundButton
            onClick={onCancel}
            className={cx.cancel}
            style={{
              marginLeft: valuesAreEqual && 0,
            }}
          />

        </div>

      </form>
    );
  }
}

export default ElementEditForm;
