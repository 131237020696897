import { combineReducers } from 'redux';
import byId from './byId/';
import byPatient from './byPatient/';
import isCreating from './isCreating';
import * as constants from './constants';
import * as chartConstants from '../charts/constants';

const reducer = combineReducers({
  byId,
  byPatient,
  isCreating,
});

// redux-actions doesn't accept actions without type
// pass empty type for initialization
const initialState = reducer(undefined, { type: '', payload: {} });

// list of global action creator identifiers every reducer here should handle
const globalActions = new Set([
  chartConstants.CHART_DELETE_SUCCESS,
]);

const createReducer = name => (state = initialState, action = {}) => {
  const payload = action.payload || {};

  if (payload.hxType === name.toUpperCase() || globalActions.has(action.type)) {
    return reducer(state, action);
  }

  return state;
};

export default combineReducers({
  [constants.DIAGNOSES.toLowerCase()]: createReducer(constants.DIAGNOSES),
  [constants.MEDICATIONS.toLowerCase()]: createReducer(constants.MEDICATIONS),
  [constants.ALLERGIES.toLowerCase()]: createReducer(constants.ALLERGIES),
  [constants.FAMILY.toLowerCase()]: createReducer(constants.FAMILY),
  [constants.MEDICAL.toLowerCase()]: createReducer(constants.MEDICAL),
  [constants.SURGICAL.toLowerCase()]: createReducer(constants.SURGICAL),
  [constants.SCREENING.toLowerCase()]: createReducer(constants.SCREENING),
});
