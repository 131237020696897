const request = require('superagent-promise')(require('superagent'), Promise);

// get usa states
export const fetchStates = () =>
  request.get('/api/usa/states')
    .end();

// get usa cities
export const fetchCities = (searchRequest) => {
  return request.post('/api/usa/cities/search')
    .send({ searchRequest })
    .end()
    .then(
      res => res.body,
      err => Promise.reject(err)
    );
};

// get state and city by zip code
export const sendZip = (searchRequest) =>
  request.post('/api/usa/zip/search')
    .send({ searchRequest })
    .end();
