import { handleActions } from 'redux-actions';

import * as constants from '../constants';
import { isNullOrUndefined } from 'helpers';

const initialState = {};

export default handleActions({
  [constants.FETCH_CLINIC_INFO_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result,
  }),
  [constants.FETCH_CLINIC_IMAGE_SUCCESS]: (state, action) => {
    let newState = { ...state };

    let imageUrl = null;
    if (!isNullOrUndefined(action.payload.result)) {
      imageUrl = URL.createObjectURL(action.payload.result);
    }

    newState = {
      ...newState,
      imageUrl,
    };

    return newState;
  },
  [constants.UPDATE_CLINIC_INFO_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.clinic,
  }),
}, initialState);
