import { useFetchPatientConsents } from './usePatientConsents';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import { PatientConsentItem } from './PatientConsentItem';

const columns = [
  {
    title: 'Form',
    subtitle: 'NAME',
    sortable: true,
    width: '35%',
  },
  {
    title: 'Provider',
    subtitle: 'Initials',
    sortable: true,
    width: '10%',
  },
  {
    title: 'Created',
    subtitle: 'DATE',
    sortable: false,
    width: '15%',
  },
  {
    title: 'Actions',
    subtitle: 'Type',
    sortable: false,
    width: '40%',
  }
];

export const PatientConsents = () => {
  const { consentIds } = useFetchPatientConsents();
  return (
    <Table>
      <thead>
        {columns.map(column => (
          <TableHead
            {...column}
            key={column.title}
          />
        ))}
      </thead>
      <tbody>
        {consentIds.map(consentId => (
          <PatientConsentItem
            key={`patient_consent_${consentId}`}
            consentId={consentId}
          />
        ))}
      </tbody>
    </Table>
  );
};