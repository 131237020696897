import React from 'react';

import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';
import FormHOC, { generateFormPropTypes } from 'containers/PatientHistoryItem/PatientHistoryItemFormHOC';
import LoaderLayer from 'containers/PatientHistoryItem/PatientHistoryItemLoaderHOC';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import ValidatedDatepicker from 'components/ValidatedDatepicker';
import RadioButtons from 'components/RadioButtons';
import CommentBox from 'components/CommentBox';
import Checkbox from 'components/Checkbox';
import MedSelect from 'components/MedSelect';
import ValidatedField from 'components/Form/ValidatedField';
import SelectContainer from 'components/MedSelect/SelectContainer';

import { illnessItems, statusItems } from 'containers/Diagnoses/helpers';
import { diagnosisWhiteList } from 'helpers/diagnoses';
import { medSelectOptions } from 'components/Select/customStyles';
import { formatMedOption } from 'helpers/selectHelpers';
import { buildSchema } from 'helpers/validationRules';

import { searchDiagnoses } from 'api/diagnoses';

import cx from 'components/EditForm/FormAssets.module.scss';

const emptyDiagnosis = {
  chronic: null,
  active: true,
  description: '',
  shouldAddDiagnosisToMedical: false,
};

const mapProps = (diagnose) => ({
  id: diagnose.id,
  diagnosis: formatMedOption(diagnose.diagnosis),
  shouldAddDiagnosisToMedical: diagnose.shouldAddDiagnosisToMedical,
  diagnosedDate: diagnose.diagnosedDate,
  resolvedDate: diagnose.resolvedDate,
  chronic: diagnose.chronic,
  active: diagnose.active,
  description: diagnose.description,
})

const validationSchema = () => buildSchema({
  diagnosis: 'requiredSelectOption',
})

const DiagnosisForm = props => (
  <FormHOC
    emptyItem={emptyDiagnosis}
    item={props.diagnosis}
    itemId={props.diagnosisId}
    params={props.params}
    setDirty={props.setDirty}
    onUpdate={props.onUpdate}
    onCreate={props.onCreate}
    onDelete={props.onDelete}
    patientId={props.patientId}
    whiteList={diagnosisWhiteList}
    status={props.status}
    isCreating={props.isCreating}
    mapItem={mapProps}
    resolveValidationSchema={validationSchema}
  >
    {({
      itemId: diagnosisId,
      item: diagnosis,
      fetching,
      clean,
      update,
      del,
      submit,
    }) => {
      return (
        <form
          onSubmit={submit}
          className={cx.form}
        >
          <EditForm>
            <div>
              <EditFormTitle
                title={diagnosisId ? 'Editing diagnosis' : 'Adding diagnosis'}
                onClick={diagnosisId ? clean : () => null}
              />

              <div className={cx.row}>
                <ValidatedField>
                  <MedSelect
                    replacementComponents={{ SelectContainer }}
                    name="diagnosis"
                    loadOptions={searchDiagnoses}
                    onCreate={null}
                    onChange={value => update('diagnosis', value)}
                    value={diagnosis.diagnosis}
                    styles={medSelectOptions}
                  />
                </ValidatedField>
              </div>

              <div className={cx.row}>
                <Checkbox
                  onClick={() => update('shouldAddDiagnosisToMedical', !diagnosis.shouldAddDiagnosisToMedical)}
                  checked={diagnosis.shouldAddDiagnosisToMedical}
                >
                  Add to Medical Hx
                </Checkbox>
              </div>

              <div className={cx.row}>
                <div className={cx.datepicker}>
                  <ValidatedDatepicker
                    title="start date"
                    value={diagnosis.diagnosedDate}
                    onChange={value => update('diagnosedDate', value)}
                  />
                </div>
                <div className={cx.datepicker}>
                  <ValidatedDatepicker
                    title="end date"
                    value={diagnosis.resolvedDate}
                    onChange={value => update('resolvedDate', value)}
                  />
                </div>
              </div>

              <div className={cx.row}>
                <RadioButtons
                  title="Illness status"
                  active={diagnosis.chronic}
                  items={illnessItems}
                  onChange={value => update('chronic', value)}
                />
              </div>

              <div className={cx.row}>
                <RadioButtons
                  title="Status"
                  required
                  active={diagnosis.active}
                  items={statusItems}
                  onChange={value => update('active', value)}
                />
              </div>

              <div className={cx.row}>
                <CommentBox
                  title="diagnosis comment"
                  placeholder="Enter an instruction for this diagnosis"
                  value={diagnosis.description}
                  onChange={e => update('description', e.target.value)}
                />
              </div>
            </div>

            <EditFormActions
              deleteButton={!!diagnosisId}
              cancelButton
              submitButton
              // disabledSubmit={!diagnosis.diagnosis || fetching}
              disabledSubmit={fetching}
              disabledCancel={fetching}
              // onSubmit={submit}
              onDelete={del}
              onCancel={clean}
            />

          </EditForm>
        </form>
      );
    }}
  </FormHOC>
);

DiagnosisForm.propTypes = generateFormPropTypes({ itemName: 'diagnosis', itemIdName: 'diagnosisId' });

const DirtyCheckedWrapper = dirtyCheckLayerHOC(DiagnosisForm);

export default props => (
  <LoaderLayer
    disabled={props.diagnosisId === undefined}
    status={props.status}
    reload={() => props.fetchDiagnosis(props.diagnosisId)}
  >
    <DirtyCheckedWrapper
      {...props}
      dirtyCheckIdentity="diagnosisId"
    />
  </LoaderLayer>
);
