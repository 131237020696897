import { handleActions } from 'redux-actions';
import * as constants from '../constants';

import {
  assembleNoteId,
  formatNote,
} from '../helpers';

const initialState = {};

export default handleActions({
  [constants.CHART_SYSTEM_NOTES_FETCH_SUCCESS]: (state, action) => {
    const {
      chartId,
      result,
    } = action.payload;

    const notesObj = {};

    Object.keys(result).forEach((systemId) => {
      const id = assembleNoteId({ chartId, systemId });

      notesObj[id] = { ...result[systemId] };
      notesObj[id].isFetched = true;
    });

    return {
      ...state,
      ...notesObj,
    };
  },

  [constants.SYSTEM_NOTE_FETCH_SUCCESS]: (state, action) => {
    const {
      result,
      chartId,
      systemIds,
    } = action.payload;

    const notesObj = {};

    const id = assembleNoteId({ chartId, systemId: systemIds[0] });

    notesObj[id] = {};

    if (result.length) {
      notesObj[id] = { ...result[0] };
    }

    notesObj[id].isFetched = true;

    return {
      ...state,
      ...notesObj,
    };
  },

  [constants.SYSTEM_NOTE_CREATE_SUCCESS]: (state, action) => {
    const {
      systemId,
      chartId,
      freeText,
    } = action.payload;

    const id = action.payload.result;

    const noteObj = formatNote({
      systemId,
      chartId,
      freeText,
      id,
    });

    return {
      ...state,
      ...noteObj,
    };
  },

  [constants.SYSTEM_NOTE_UPDATE_SUCCESS]: (state, action) => {
    const {
      systemId,
      chartId,
      freeText,
      id,
    } = action.payload;

    const noteObj = formatNote({
      systemId,
      chartId,
      freeText,
      id,
    });

    return {
      ...state,
      ...noteObj,
    };
  },

  [constants.SYSTEM_NOTE_DELETE_SUCCESS]: (state, action) => {
    const {
      systemNoteId,
    } = action.payload;

    const newState = { ...state };

    delete newState[systemNoteId];

    return newState;
  },
}, initialState);
