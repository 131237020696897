import { connect } from 'react-redux';

import {
  removeSystemWithCallback,
  createDiagnosisFromElement,
  createMedicationFromElement,
  createMedicalItemFromDiagnosis,
  saveChart,
  saveChartingSessionWrapper
} from 'modules/chartingSessions/actions';

import * as chartingAssetsSelectors from 'modules/chartingAssets/selectors';
import * as chartingSessionsSelectors from 'modules/chartingSessions/selectors';
import * as patientsHxSelectors from 'modules/patientsHx/selectors';
import * as resourcesSelectors from 'modules/resources/selectors';
import { isChartReportVisible } from 'modules/chartReportVisible';
import { getActivePatient } from 'modules/patients/selectors';

import * as rootSelectors from 'modules/rootSelectors';

import { isNullOrUndefined } from 'helpers';
import { getNextSystem } from 'helpers/charting/charting';

import orderBy from 'lodash/orderBy';

const ICD10_SYSTEM_ID = 35;
const MEDICATIONS_SYSTEM_ID = 36;

const mapStateToProps = (state, ownProps) => {
  const { chartId, chartingStepId, activeSystem } = ownProps;

  // systems in central pane
  // all systems available for current charting step by default
  let systems = chartingAssetsSelectors.getSystemsByChartingStep(state.chartingAssets, chartingStepId, chartId);

  // if there is only one system possible in current charting step we shouldn't  display controls
  // and the output in report should be different

  const { id: patientId } = getActivePatient(state.patients);

  let diagnosesToAdd;
  let medicationsToAdd;

  // if we have chartId we should get only active systems
  if (!isNullOrUndefined(chartId)) {
    // get all active systems for current charting step
    const activeSystems = rootSelectors.getActiveSystemsByStepForChart(state, chartingStepId, chartId);

    const activeDiagnoses =
      patientsHxSelectors
        .getDiagnoses(state.patientsHx, patientId, 'active')
        .map(o => o.diagnosis.id);

    const chartingSessionDiagnoses =
      chartingSessionsSelectors
        .getEnclosedElementsForSystem(state.chartingSessions, chartId, ICD10_SYSTEM_ID)
        .map(o => o.id);

    diagnosesToAdd =
      chartingSessionDiagnoses
        .filter(id => !activeDiagnoses.includes(id))
        .map(id => resourcesSelectors.getResourceById(state.resources, 'diagnoses', id));


    const activeMedications =
      patientsHxSelectors
        .getMedications(state.patientsHx, patientId, 'active')
        .map(o => o.medication.id);

    // medication can have ids so logic is a bit different
    const chartingSessionMedications =
      chartingSessionsSelectors
        .getEnclosedElementsForSystem(state.chartingSessions, chartId, MEDICATIONS_SYSTEM_ID);

    medicationsToAdd =
      chartingSessionMedications
        .filter(med => !activeMedications.includes(med.id))
        .map(med => ({
          ...resourcesSelectors.getResourceById(state.resources, 'medications', med.id),
          ids: med.ids,
          prescribed: med.prescribed
        }));

    systems =
      // filter all fixed and special systems
      systems.filter(system => system && system.fixed)
      // concat with systems in current charting session
      // and remove fixed systems from activeSystems
      // to prevent duplication
        .concat(activeSystems.filter(system => system && !system.fixed));
  }

  systems = orderBy(systems, 'order');

  // when we have active system and it is not last in list
  // if there are more than one systems we can navigate to next active system
  let nextSystem;

  // NOTE this should probably be always in the end
  // if we are currently working with one system we should filter out rest systems
  if (!isNullOrUndefined(activeSystem)) {
    // before we filter out inactive systems we should get next system
    const nextSystemForPlan = getNextSystem(chartingStepId, systems, activeSystem);
    nextSystem = nextSystemForPlan === null ? systems[systems.findIndex(system => system.id === activeSystem) + 1] : nextSystemForPlan;
    systems = systems.filter(system => system.id === activeSystem);
  }

  return {
    chartId,
    chartingStepId,
    systems,
    nextSystem,
    patientId,
    diagnosesToAdd,
    medicationsToAdd,
    isChartReportVisible: isChartReportVisible(state.chartReportVisible),
    chartingSessions: state.chartingSessions,
  };
};

const mapActionCreators = {
  removeSystem: removeSystemWithCallback,
  createDiagnosisFromElement,
  createMedicationFromElement,
  createMedicalItemFromDiagnosis,
  saveChart,
  saveChartingSessionWrapper,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
