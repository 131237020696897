import { connect } from 'react-redux';

import { createUserLocally } from 'modules/users/actions';

import * as selectors from 'modules/users/selectors';

const mapStateToProps = state => ({
  users: selectors.getUsersByStatus(state.users, true),
});

const mapActionCreators = {
  createUserLocally,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
