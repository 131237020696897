import { connect } from 'react-redux';
import React from 'react';

import * as actions from 'modules/roles/actions';
import { clearUserPermissionsForRemovedRole } from 'modules/users/actions';
import * as selectors from 'modules/roles/selectors';
import * as roleFormSelectors from 'modules/roleForm/selectors';
import { setIsNeedHideErrors } from 'modules/roleForm/actions';

export const fetchLayerHOC = (Component) => {
  return class RolesFetchLayer extends React.Component {
    componentDidMount() {
      if (this.props.isFetched || this.props.isFetching) return;

      this.props.fetchRoles();
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => ({
  roles: selectors.getRoles(state.roles),
  role: selectors.getRole(state.roles, ownProps.roleId),
  isFetched: selectors.getIsFetched(state.roles),
  isFetching: selectors.getIsFetching(state.roles),
  isCreating: selectors.getIsCreating(state.roles),
  isUpdating: selectors.getIsUpdating(state.roles),
  isNeedHideErrors: roleFormSelectors.getIsNeedHideErrors(state.roleForm),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
  clearUserPermissionsForRemovedRole,
  setIsNeedHideErrors,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
