import { PdfEditor } from './PdfEditor';
import { PatientConsents } from './PatientConsents';

export const DemographicsForms = () => {
  return (
    <>
      <PdfEditor />
      <PatientConsents />
    </>
  );
};