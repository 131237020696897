const request = require('superagent-promise')(require('superagent'), Promise);

export const fetchChartingAssets = () =>
  request.get('/api/rest/charting/')
    .end();

export const fetchChartingAssetsByChart = chartId =>
  request.get(`/api/rest/chartings/${chartId}/details`)
    .end();

export const createSystem = system =>
  request.post('/api/rest/system/create')
    .send(system)
    .end();

export const updateSystem = system =>
  request.post('/api/rest/system/update')
    .send(system)
    .end();

export const deleteSystem = systemId =>
  request.post('/api/rest/system/delete')
    .send({ id: systemId })
    .end();

export const createElement = element =>
  request.put('/api/rest/element/create')
    .send(element)
    .end();

export const findElementBySystem = data =>
  request.post('/api/rest/element/findBySystem')
    .send(data)
    .end();

export const findElementByStep = data =>
  request.post('/api/rest/element/findByStep')
    .send(data)
    .end();

export const rwSearch = data =>
  request.post('/api/rest/element/rwSearch')
    .send(data)
    .end();

export const updateChartElement = data =>
  request.post('/api/rest/element/update')
    .send(data)
    .end();

export const createSubelement = subelement =>
  request.post('/api/rest/subelement/create')
    .send(subelement)
    .end();

export const updateSubelement = subelement =>
  request.post('/api/rest/subelement/update')
    .send(subelement)
    .end();


export const fetchSubelements = (elementId, setNumber = 0) =>
  request
    .get('/api/rest/subelement')
    .query({ id: elementId })
    .query({ setNumber })
    .end();
