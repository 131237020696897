import { useLocation, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import deleteObjectProperty from 'helpers/common/object/delete-property';

export const useSearchTab = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const buildSearchTabUrl = (searchParams = '') => {
    return `${pathname}?${searchParams}`;
  };

  const buildStringSearchParams = (searchParams = {}) => {
    return new URLSearchParams(searchParams).toString();
  };

  const searchTabUrl = useMemo(() => {
    if (search === '') {
      return buildSearchTabUrl('search=1');
    }
    const objectParams = Object.fromEntries(searchParams);
    if (search.includes('search=1')) {
      const woSearchParam = deleteObjectProperty(objectParams, 'search');
      return buildSearchTabUrl(buildStringSearchParams(woSearchParam));
    } else {
      const withSearchParam = { ...objectParams, 'search': 1 };
      return buildSearchTabUrl(buildStringSearchParams(withSearchParam));
    }
  }, [pathname, search]);

  return { searchTabUrl };
};