export const createConsent = data =>
  fetch('/api/clinic/consent/', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    }
  });

const request = require('superagent-promise')(require('superagent'), Promise);

export const downloadTemplate = (id) =>
  request
    .post(`/api/clinic/consent/${id}/download`)
    .set('Accept', 'application/octet-stream')
    .type('form')
    .responseType('arraybuffer');

export const uploadTemplate = data =>
  request
    .post('/api/clinic/consent/upload')
    .send(data)
    .end();

export const updateConsentById = ({ id, data }) =>
  fetch(`/api/clinic/consent/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    }
  });

export const listConsents = () =>
  fetch('/api/clinic/consent/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

export const deleteConsentById = id => 
  fetch(`/api/clinic/consent/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });