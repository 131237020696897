import React from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router';

import PatientEncountersContainer, { withPatientEncountersFetchLayer } from 'containers/Encounters/PatientEncountersContainer';
import EncountersTable from 'containers/Encounters/EncountersTable';
import ChartToPatientContainer from 'containers/ChartToPatientContainer';
import PatientEncountersPaginationWrapper from 'components/PatientEncountersPaginationWrapper';

const Encounters = compose(
  ChartToPatientContainer,
  PatientEncountersContainer,
  withPatientEncountersFetchLayer
)(EncountersTable);

const EncountersHxWrapper = () => {
  const { chartId } = useParams();
  return (
    <PatientEncountersPaginationWrapper>
      <Encounters
        chartId={chartId}
        type="encounters"
        paginated
      />
    </PatientEncountersPaginationWrapper>
  );
};

export default EncountersHxWrapper;
