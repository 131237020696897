import { connect } from 'react-redux';

import * as patientsSelectors from 'modules/patients/selectors';
import * as actions from 'modules/patients/actions';

const mapStateToProps = (state, ownProps) => ({
  patients: patientsSelectors.getAllPatients(state.patients),
  patientsByPage: page => patientsSelectors.getPatientsByPage(state.patients, page),
  totalPages: patientsSelectors.getTotalPages(state.patients),
  patientsFromSearch: patientsSelectors.getPatientsFromSearch(state.patients),
  patientsFromSearchWithTrueOrder: patientsSelectors.getPatientsFromSearchWithTrueOrder(state.patients),
  isFetching: patientsSelectors.getIsFetching(state.patients),
  isFetched: patientsSelectors.getIsFetched(state.patients),
  isSearching: patientsSelectors.getIsSearching(state.patients),
  searchQuery: patientsSelectors.getSearchQuery(state.patients),
  error: patientsSelectors.getError(state.patients),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
  searchPatients: actions.findPatientsOnPatientsTab
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
