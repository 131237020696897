import { handleActions } from 'redux-actions';

const BANNER_STATUS = 'BANNER_STATUS'

export const getStatus = state => state.banner

export const showBanner = ({ title, message, messageType }) => ({
  type: BANNER_STATUS,
  payload: {
    isShow: message?.length > 0,
    title,
    message,
    messageType
  }
})

export const hideBanner = () => ({
  type: BANNER_STATUS,
  payload: {
    isShow: false,
  }
})

const initialState = {
  isShow: false,
  title: null,
  message: null
}

export default handleActions({
  [BANNER_STATUS]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
