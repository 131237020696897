export default class DisableCreationElementAndSubElement {
  static isDisabled = (social, permissions) => {
    const {
      viewEditPatientClinicalInformation,
      addEditElements,
    } = permissions;

    if (social) {
      return !viewEditPatientClinicalInformation;
    }
    return !addEditElements;
  }
}
