import { combineReducers } from 'redux';

import byId from './byId';
import isFetched from './isFetched';
import isFetching from './isFetching';

export default combineReducers({
  byId,
  isFetched,
  isFetching,
});
