import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';

import SignaturePad from 'components/PDFEditor/signature-pad/signature_pad';
import { Navigation } from 'components/PDFEditor/Navigation';
import { Actions } from 'components/PDFEditor/Actions';

import cx from 'components/PDFEditor/PDFViewer.module.scss';

const PDFViewer = ({ buffers, file, renderToolbar }) => {
  const pdfRef = useRef(null);
  const pageRefs = useRef([]);
  const [numPages, setNumPages] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const signaturePads = useRef([]);
  const signatureData = useRef({});
  const canvasContainer = useRef(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [pageDimensions, setPageDimensions] = useState({ width: 1, height: 1 });
  const [canvasDimensions, setCanvasDimensions] = useState({});

  useEffect(() => {
    function resizeCanvas() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize',  resizeCanvas);
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  const actWrapper = ({ action = 'draw', tool = 'pen' }) => {
    const signaturePad = signaturePads.current[currentPage - 1];
    if (action === 'draw') {
      signaturePad.minWidth = 0.9;
      signaturePad.maxWidth = 1;
      signaturePad.compositeOperation = 'source-over';
      signaturePad.tool = tool;
    } else if (action === 'erase') {
      signaturePad.minWidth = 8;
      signaturePad.maxWidth = 8;
      signaturePad.compositeOperation = 'destination-out';
    } else {
      const data = signaturePad.toData();
      if (data && data.length > 0) {
        data.pop();
        signaturePad.fromData(data);
        signatureData.current[currentPage - 1] = data;
      }
    }
  };

  const onLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
    setCurrentPage(1);
    pageRefs.current = new Array(pdf.numPages).fill().map(() => React.createRef());
    canvasContainer.current = new Array(pdf.numPages).fill().map(() => React.createRef());
    signaturePads.current = Array(pdf.numPages).fill(null);
  };

  const pageLoadSuccess = (page) => {
    const pageIndex = page.pageNumber - 1;
    const canvas = pageRefs.current[pageIndex].current.querySelector('.canvas-container canvas');
    const pageCanvas = pageRefs.current[pageIndex].current.querySelector('.react-pdf__Page__canvas');

    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.width = pageCanvas.clientWidth ;
    canvas.height = pageCanvas.clientHeight;

    setPageDimensions({
      width: page.view[2] - page.view[0],
      height: page.view[3] - page.view[1],
    });

    setCanvasDimensions(prev => ({
      ...prev,
      [pageIndex]: { width: canvas.width, height: canvas.height },
    }));

    restorePageData({ pageIndex });
};

  const calculateScale = () => {
    const margin = 40;
    const availableWidth = windowSize.width - margin;
    const availableHeight = windowSize.height - 110;

    // Соотношения сторон окна и страницы
    const windowAspectRatio = availableWidth / availableHeight;
    const pageAspectRatio = pageDimensions.width / pageDimensions.height;

    let scale;

    if (windowAspectRatio > pageAspectRatio) {
      // Ограничивающее измерение - высота
      scale = availableHeight / pageDimensions.height;
    } else {
      // Ограничивающее измерение - ширина
      scale = availableWidth / pageDimensions.width;
    }

    return scale;
  };

  /**
   * 
   * @param {HTMLElement} can 
   * @param {number} page zero-based index of page 
   */
  const initializeSignaturePad = (can, page) => {
    if (can != null && !signaturePads.current[page]) {

      const signaturePad = new SignaturePad(can, {
        minWidth: 0.7,
        maxWidth: 0.8,
        penColor: 'black',
        velocityFilterWeight: 0.9,
        minDistance: 1,
      });
      signaturePads.current[page] = signaturePad;
      signaturePad.addEventListener('endStroke', () => {
        signatureData.current[page] = signaturePad.toData();
      });
    }
  };

  const restorePageData = ({ pageIndex }) => {
    const signaturePad = signaturePads.current[pageIndex];
    if (signaturePad && canvasDimensions[pageIndex] && signatureData.current[pageIndex]) {
      const { width } = canvasDimensions[pageIndex];

      let lines = signatureData.current[pageIndex];
      let scale = signaturePad.canvas.width / width;
      lines.forEach(line => {
        line.points.forEach(point => {
          point.x *= scale;
          point.y *= scale;
        });
      });

      signaturePad.clear();
      signaturePad.fromData(lines);
    }
  };

  const pageHeightLimit = useMemo(() => {
    return pageRefs.current[0]?.current?.clientHeight / 3;
  }, [pageRefs.current[0]?.current?.clientHeight]);

  const handleScroll = () => {
    if (pdfRef.current) {
      const scrollTop = pdfRef.current.scrollTop;
      let foundPage = 1;
      let cumulativeHeight = 0;

      for (let i = 0; i < numPages; i++) {
        const pageHeight = pageRefs.current[i]?.current?.clientHeight || 0;
        cumulativeHeight += pageHeight;

        if (scrollTop + pageHeightLimit < cumulativeHeight) {
          foundPage = i + 1;
          break;
        }
      }

      if (foundPage !== currentPage) {
        setCurrentPage(foundPage);
      }
    }
  };

  useEffect(() => {
    const pdfContainer = pdfRef.current;

    if (pdfContainer) {
      pdfContainer.removeEventListener('scroll', handleScroll);
      pdfContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (pdfContainer) {
        pdfContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, numPages]);

  const initializePDF = async () => {
    return await PDFDocument.load(buffers);
  };

  const downloadPDFWithAnnotations = async () => {
    const pdfDoc = await initializePDF();

    for (let i = 0; i < numPages; i++) {
      const signaturePad = signaturePads.current[i];

      if (signaturePad && !signaturePad.isEmpty()) {
        const imageURL = signaturePad.toDataURL();
        const imageBytes = await fetch(imageURL).then(res => res.arrayBuffer());

        const image =  await pdfDoc.embedPng(imageBytes);
        const pdfPage = pdfDoc.getPage(i);


        const pdfPageSize = pdfPage.getSize();

        pdfPage.drawImage(image, {
          x:0,
          y: 0,
          width: pdfPageSize.width,
          height: pdfPageSize.height,
        });
      }
    }

    return pdfDoc.save();
  };

  /**
   * If prev is true then prev button has been clicked otherwise the next button
   * @param navigateTo
   * @param {boolean} prev
   */
  const navigateWrapper = ({ navigateTo, prev = false }) => {
    navigateTo(pageRefs.current[prev ? currentPage - 2 : currentPage]?.current);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.mjs';

  return (
    <div className={cx['pdf-viewer']}>
      {renderToolbar({ onSave: downloadPDFWithAnnotations })}
      <Actions
        onAct={actWrapper}
      />
      <div className={cx['document-container']} onScroll={handleScroll} ref={pdfRef}>
        <Document file={file} onLoadSuccess={onLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <div key={`page_${index + 1}`} ref={pageRefs.current[index]}>
              <Page
                onLoadSuccess={pageLoadSuccess}
                scale={calculateScale()}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              >
                <div className={`canvas-container ${cx['page-container']}`}>
                  <canvas
                    className={cx['canvas']}
                    ref={(can) => initializeSignaturePad(can, index)}
                  />
                </div>
              </Page>
            </div>
          ))}
        </Document>
      </div>
      <Navigation
        currentPage={currentPage}
        totalPages={numPages}
        navigate={navigateWrapper}
      />
    </div>
  );
};

export default PDFViewer;