import React, { useEffect, useState } from 'react';
import PropTypes, {number, shape, oneOfType, string} from 'prop-types';

import Select from 'react-select';

import CUSTOM_STYLES from 'components/Select/customStyles';

const DropdownHOC = (props) => {
  const [options, setOptions] = useState([]);

  const {
    value,
    disabled,
    selectStyles = CUSTOM_STYLES,
    className,
    loadOptions,
    optionMapper,
    isClearable = true,
    onChange,
    isSearchable = false,
  } = props;

  useEffect(() => {
    const asyncLoadOptions = async () => {
      const { body: values } = await loadOptions();

      setOptions(values.map(optionMapper))
    };

    asyncLoadOptions()
  }, []);

  const handleChange = (option) => onChange(option);

  return (
    <Select
      isSearchable={isSearchable}
      className={className}
      options={options}
      onChange={handleChange}
      value={value}
      searchable={false}
      disabled={disabled}
      isClearable={isClearable}
      styles={selectStyles}
    />
  );
}

DropdownHOC.propTypes = {
  isSearchable: PropTypes.bool,
  loadOptions: PropTypes.func.isRequired,
  optionMapper: PropTypes.func.isRequired,
  value: shape({
    value: oneOfType([number, string]),
    label: string
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  selectStyles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default DropdownHOC;
