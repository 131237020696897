import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AllergiesTable from '../AllergiesTable';
import Loader from 'components/Loader';

import { build } from 'helpers/URLUtils';

export const AllergiesList = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const selectAllergy = allergyId => navigate(
    build({
      pathname: pathname,
      query: { allergy: allergyId }
    })
  );

  const {
    allergies,
    isFetching,
    error,
    hasNoDrugAllergies,
    filter,
    patientId
  } = props;
  if (isFetching) return <Loader />;

  return (
    <AllergiesTable
      items={allergies}
      showAllergyById={selectAllergy}
      selectedId={parseInt(searchParams.get('allergy'))}
      filter={filter}
      error={error}
      onReload={() => this.props.fetchAllergies(patientId, filter)}
      hasNoDrugAllergies={hasNoDrugAllergies}
    />
  );
};
