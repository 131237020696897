import React from 'react';

import PropTypes from 'prop-types';

import ChartReportSocialSystemName from './ChartReportSocialSystemName';
import ChartReportSocialElement from './ChartReportSocialElement';

import sortBy from 'lodash/sortBy';

const ChartReportSocialItem = (props) => {
  const { elements } = props;

  if (!elements.length) return null;

  return (
    <div>
      <ChartReportSocialSystemName
        chartId={props.chartId}
        toggleChartReport={props.toggleChartReport}
        systemId={props.system.id}
        viewMode={props.viewMode}
        removeElements={() => props.removeElements(elements)}
        patient={props.patient}
        elements={elements}
      >
        <span className="text-bold">{props.system.name}:</span>
      </ChartReportSocialSystemName>
      {' '}
      {sortBy(elements, [element => element.name.toLowerCase()]).map((element, i) => (
        <span key={element.id}>
          <ChartReportSocialElement
            element={element}
            chartId={props.chartId}
            toggleChartReport={props.toggleChartReport}
            systemId={props.system.id}
            removeElements={() => props.removeElements([element])}
            viewMode={props.viewMode}
            patient={props.patient}
          />
          {i !== elements.length - 1 && ', '}
        </span>
        ))}
    </div>
  );
};

ChartReportSocialItem.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })
    .isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }))
    .isRequired,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  toggleChartReport: PropTypes.func,
  removeElements: PropTypes.func,
  viewMode: PropTypes.bool,
  patient: PropTypes.object.isRequired,
};

export default ChartReportSocialItem;
