import React from 'react';
import { connect } from 'react-redux';

import * as selectors from 'modules/financial/paymentsPlans/selectors';
import * as actions from 'modules/financial/paymentsPlans/actions';

import * as financialActions from 'modules/financial/financialTab/actions';

import { getFinancialInfo } from 'modules/financial/financialTab/selectors';

export const fetchLayerHOC = (Component) => {
  return class PaymentsPlansFetchLayer extends React.Component {
    componentDidMount() {
      if (this.props.paymentsPlans.isFetched !== true) {
        this.props.fetchPaymentsPlans();
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
};

const mapStateToProps = (state, ownProps) => ({
  paymentsPlans: selectors.getPaymentsPlans(state.financial),
  financialInfo: getFinancialInfo(state.financial),
  ...ownProps,
});

const mapActionCreators = {
  ...actions,
  ...financialActions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
