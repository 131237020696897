import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useNavigateWrapper } from 'hooks/useNavigateWrapper';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import PatientHistoryItemsTableContainer from 'containers/PatientHistoryItemsTable';

import Loader from 'components/Loader';

import { DATE_FORMATS } from 'helpers/date/constants';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';
import fields from './fields';
import { build } from 'helpers/URLUtils';

import cx from './ScreeningHXTable.module.scss';

const rowStyles = ({ screeningItem, selectedId }) => cssClassesResolver([
  cx.item, screeningItem.id === selectedId && cx['item--active']
]);

const ScreeningHXTable = props => {
  const { timeZoneDateConverter } = useContext(AppContext);
  const { navigate, location } = useNavigateWrapper();
  const {
    screeningItems,
    isFetching,
    selectedId,
    error,
    fetchScreening,
    hasNoPertinentScreening,
    patientId
  } = props;

  const showScreeningById = (id) => 
    navigate(
      build({
        pathname: location.pathname,
        query: {
          screening: id,
        }
      })
    );

  const formattedDateField = date => timeZoneDateConverter.getFormattedDateWithTimeZone(date, DATE_FORMATS.YEAR);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <PatientHistoryItemsTableContainer
      items={screeningItems}
      defaultDesc={false}
      defaultSortBy="screening"
      mapItemToField={item => ({
        id: item.id,
        screening: item.screeningDetail.name,
        date: formattedDateField(item.startDate),
        comment: item.description,
      })}
      error={error}
      onReload={() => fetchScreening(patientId)}
      fields={fields}
      itemRenderer={screeningItem => (
        <tr
          className={rowStyles({ selectedId, screeningItem })}
          key={screeningItem.id}
          onClick={() => showScreeningById(screeningItem.id)}
        >
          <td>
            <span className={cx.name}>{screeningItem.screening}</span>
          </td>
          <td>{screeningItem.date}</td>
          <td>{screeningItem.comment}</td>
        </tr>
      )}
      additionalEmptyMessage={!!hasNoPertinentScreening && 'No Pertinent Screening History'}
      emptyMessage="Patient has no screening history"
    />
  );
};

ScreeningHXTable.propTypes = {
  screeningItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    screening: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    startDate: PropTypes.number,
    description: PropTypes.string,
  })).isRequired,
  selectedId: PropTypes.number,
  fetchScreening: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasNoPertinentScreening: PropTypes.bool,
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ScreeningHXTable.defaultProps = {
  selectedId: undefined,
  error: undefined,
  isFetching: undefined,
  hasNoPertinentScreening: undefined,
};

export default ScreeningHXTable;
