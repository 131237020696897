import * as api from 'api/financial';
import * as constants from './constants';

export const fetchLastBillingPlan = () => ({
  types: [
    constants.LAST_BILLING_PLAN_FETCH,
    constants.LAST_BILLING_PLAN_FETCH_SUCCESS,
    constants.LAST_BILLING_PLAN_FETCH_FAIL,
  ],
  promise: () => api.fetchLastBillingPlan(),
});

export const setAutoUpdate = data => ({
  types: [
    constants.SET_AUTO_UPDATE,
    constants.SET_AUTO_UPDATE_SUCCESS,
    constants.SET_AUTO_UPDATE_FAIL,
  ],
  promise: () => api.setAutoUpdate(data),
  data,
});
