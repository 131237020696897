import { handleActions } from 'redux-actions';

const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

export const toggleSidebar = () => ({
  type: SIDEBAR_TOGGLE,
});

export default handleActions({
  [SIDEBAR_TOGGLE]: state => !state,
}, false);

export const getSidebarState = state => state;
