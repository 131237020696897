import orderByIterateesStrategy from 'helpers/common/sort/strategies/order-by-iteratees-strategies';
import { formatArrayItems } from 'helpers/selectHelpers';

const getHistorySearchingResultsOptions = ({ searchingItems, iteratees, formatStrategy }) => {
  const sortedItems = orderByIterateesStrategy(searchingItems, iteratees);

  return formatArrayItems(sortedItems, formatStrategy);
};

export default getHistorySearchingResultsOptions;
