import { handleActions } from 'redux-actions';
import * as constants from '../constants';

import drChronoIsAttachedStrategyManager from 'helpers/drChrono/drChronoIsAttachedStrategyManager';

const initialState = false;

export default handleActions({
  [constants.DRCHRONO_TOKEN_FETCH_SUCCESS]: (state, action) => {
    const { result, status } = action.payload;

    return drChronoIsAttachedStrategyManager(status)(result);
  },
}, initialState);
