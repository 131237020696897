import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import PatientsSearch from '../PatientsSearch';

import { containsAny } from 'helpers';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import cx from './PageContainer.module.scss';

const customOverflowRoutes = [
  'privacy-policy',
  'terms-of-use',
  'user-agreement',
  'contact-us',
];

const PageContainer = ({ children, isSidebarActive, offset }) => {
  const { search, pathname } = useLocation();
  const searchParamQuery = new URLSearchParams(search).get('search');
  return (
    <div
      className={cssClassesResolver([
        cx[isSidebarActive ? 'wrapper--active' : 'wrapper'],
        containsAny(pathname, customOverflowRoutes) && cx['with-overflow']
      ])}
      style={{
        height: offset && offset.top && `calc(100% - ${offset.top}px)`,
      }}
    >
      {!!searchParamQuery && (
        <PatientsSearch visible={!!searchParamQuery} />
      )}
      <div className={cx.container}>
        {children}
      </div>
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  isSidebarActive: PropTypes.bool,
};

export default PageContainer;
