export default class MapConverterToObj {
  static convert = (map) => {
    if (map instanceof Map) {
      const obj = {};

      map.forEach((value, key) => {
        obj[key] = value;
      });
      return obj;
    }

    return {};
  };
}
