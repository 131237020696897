import React from 'react'

import { components } from 'react-select';

import cx from './DropdownIndicator.module.scss'

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<span className={cx.icon} />
		</components.DropdownIndicator>
	)
}

export default DropdownIndicator
