import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'redux';
import { func, bool, string } from 'prop-types';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';

import AuthForm from 'routes/components/AuthForm';
import ClinicsPanel from 'components/ClinicsPanel';
import ClinicsContainer from 'containers/Clinic/ClinicsListContainer';
import { withClinicsFetchLayer } from 'containers/Clinic/withClinicsFetchLayer';
import LoginContainer from 'containers/LoginContainer';

import { isEmbedded } from 'helpers';
import { buildSchema } from 'helpers/validationRules';

import cx from 'styles/AuthFormAssets.module.scss';
import { useImpersonate } from '../../../../../hooks/useImpersonate';

const ConnectedClinicsPanel = compose(
  ClinicsContainer,
  withClinicsFetchLayer
)(ClinicsPanel);

const Login = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isListVisible, setIsListVisible] = useState(false);
  const { logout } = useImpersonate();

  const {
    isAuthenticated,
    isSuperAdmin,
    authGetHeally,
    setClinic,
    error,
    isAuthenticating,
    message,
    isBlankClinic
  } = props;

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: buildSchema({
      username: 'requiredEmail',
      password: 'required',
    }),
    onSubmit: async values => {
      const { login } = props;

      await login({ ...values, isListVisible });

      if (isAuthenticated && isBlankClinic) {
        setIsListVisible(true);
      }
    },
  });

  useEffect(() => {
    if (isSuperAdmin && isAuthenticated) {
      navigate('/app/admin');
    }

    if (isAuthenticated && !isBlankClinic && !isSuperAdmin) {
      navigate('/app/doctor');
    }

    if (isEmbedded() && !isAuthenticated) {
      authGetHeally(Object.fromEntries(searchParams.entries()));
    }

    if (isAuthenticated && isBlankClinic) {
      setIsListVisible(true);
    }
  }, [isAuthenticated, isBlankClinic]);

  const hideClinicList = useCallback(() => {
    setIsListVisible(false);

    logout();
  }, []);

  const handleClinicSelect = useCallback(clinicId => setClinic(clinicId), []);


  if (isAuthenticated && !isBlankClinic) return null;

  return (
    <div className={cx['auth-form-wrapper']}>
      <AuthForm>
        <form
          className={cx.form}
          onSubmit={formik.handleSubmit}
        >
          <div>
            {message ? (
              <p
                className={cx[error ? 'error-message' : 'success-message']}
              >
                {message}
              </p>
            ) : null}

            <div className={cx.row}>
              <p className={cx.label}>Email</p>
              <div className="relative">
                <input
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  className={cx.input}
                />
                {formik.errors.username && (
                  <span className="validation-error">{formik.errors.username}</span>
                )}
              </div>
            </div>

            <div className={cx.row}>
              <p className={cx.label}>Password</p>
              <div className="relative">
                <input
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={cx.input}
                />
                {formik?.errors?.password && (
                  <span className="validation-error additional-error">{formik.errors.password}</span>
                )}
              </div>
            </div>
            <div className={cx.recoveryLinkWrapper}>
              <Link to="/app/recovery" className={cx.recoveryLink}>Forgot password?</Link>
            </div>
          </div>
          <div className={cx.actions}>
            <div className={cx['submit-button-wrapper']}>
              <button
                disabled={!error && isAuthenticating}
                type="submit"
                className={cx.submit}
              >
                Sign in
              </button>
            </div>
          </div>
        </form>
      </AuthForm>
      <ConnectedClinicsPanel
        isVisible={isListVisible}
        onClinicSelect={handleClinicSelect}
        hideClinicList={hideClinicList}
      />
    </div>
  );
};

Login.propTypes = {
  login: func.isRequired,
  isBlankClinic: bool.isRequired,
  setClinic: func.isRequired,
  error: bool,
  isAuthenticating: bool,
  isAuthenticated: bool.isRequired,
  isSuperAdmin: bool.isRequired,
  message: string,
  authGetHeally: func.isRequired,
};

export default LoginContainer(Login);
