import { connect } from 'react-redux';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as actions from 'modules/patientsHx/actions';
import * as selectors from 'modules/patientsHx/selectors';
import { FAMILY } from 'modules/patientsHx/constants';
import { getPatientById } from 'modules/patients/selectors';

export const familyFetchLayer = (Component) => {
  const useFetchLayer = props => {
    const {
      patientId,
      fetchFamily,
      isFetching
    } = props;

    useEffect(() => {
      if (patientId && isFetching === undefined) {
        fetchFamily(patientId);
      }
    }, [patientId]);

    return <Component {...props} />;
  };

  useFetchLayer.propTypes = {
    isFetching: PropTypes.bool,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fetchFamily: PropTypes.func.isRequired,
  };

  return useFetchLayer;
};

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;

  return {
    ...ownProps,
    patientId,
    isFetching: selectors.getIsFetchingList(state.patientsHx, FAMILY, patientId),
    familyItems: selectors.getFamilyItems(state.patientsHx, patientId),
    error: selectors.getListError(state.patientsHx, FAMILY, patientId),
    selectedId: ownProps.familyItemId,
    hasNoPertinentFamily: getPatientById(state.patients, patientId).hasNoPertinentFamily,
  };
};

const mapActionCreators = {
  fetchFamily: actions.fetchFamily,
  createFamilyItem: actions.createFamilyItem,
  updateFamilyItem: actions.updateFamilyItem,
  deleteFamilyItem: actions.deleteFamilyItem,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
