import { handleActions } from 'redux-actions';

import { CHART_FETCH_SUCCESS } from 'modules/charts/constants';
import {
  FETCH_PATIENT_IMAGE_SUCCESS,
  SET_PATIENT_FOR_CHART,
  PATIENT_UPDATE_SUCCESS
} from 'modules/patients/constants';

import { isNullOrUndefined } from 'helpers';

export default handleActions({
  [CHART_FETCH_SUCCESS]: (state, action) => {
    if (action.payload.result.status === 204) return state;

    return {
      ...action.payload.result.patient,
    };
  },
  [SET_PATIENT_FOR_CHART]: (state, action) => ({
    ...action.payload
  }),
  [FETCH_PATIENT_IMAGE_SUCCESS]: (state, action) => {
    const {
      result,
      patientId
    } = action.payload;

    if (patientId !== state.id) {
      return state;
    }

    let imageUrl = null;
    if (!isNullOrUndefined(result)) {
      imageUrl = URL.createObjectURL(result);
    }

    return {
      ...state,
      imageUrl,
    };
  },
  [PATIENT_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload.result.patientDTO,
    imageUrl: action.payload.localPatient.imageUrl,
  }),
}, {});
