import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import store from 'store';
import createRoutes from 'routes';

// import { unregister } from 'serviceWorker';

// unregister();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <RouterProvider router={createRoutes} />
  </Provider>
);
