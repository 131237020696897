import singleSession from './singleSession';
import * as constants from './constants';
import * as socialElementsConstants from '../socialElements/constants';

const initialState = {};

const ACTIONS_TO_HANDLE = [
  constants.SOCIAL_SYSTEM_ADD,
  constants.PATIENT_SOCIAL_FETCH,
  constants.PATIENT_SOCIAL_FETCH_SUCCESS,
  constants.PATIENT_SOCIAL_FETCH_FAIL,
  constants.SOCIAL_ELEMENT_ADD,
  constants.SOCIAL_ELEMENTS_REMOVE,
  socialElementsConstants.UPDATE_SOCIAL_ELEMENT,
  socialElementsConstants.UPDATE_SOCIAL_ELEMENT_SUCCESS,
  socialElementsConstants.UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_SUCCESS,
];

export default (state = initialState, action) => {
  if (ACTIONS_TO_HANDLE.includes(action.type)) {
    const { patientId } = action.payload;

    return {
      ...state,
      [patientId]: singleSession(state[patientId], action),
    };
  }

  return state;
};
