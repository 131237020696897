import { getGenderOptionLabel, isGenderPresentInOptions } from 'helpers/patient/gender/manage/select-options';
import { EMPTY } from 'helpers/patient/gender/constants/list-constants';

const genderTagDefaultValue = EMPTY;

const getGenderValue = (gender) => {
  const formattedGender = String(gender).toUpperCase()

  return isGenderPresentInOptions(formattedGender)
    ? getGenderOptionLabel(formattedGender)
    : genderTagDefaultValue;
};

export default getGenderValue;
