export const VALIDATORS_SETS = {
  patient: ['patient'],
  patientAndDate: ['patient', 'from', 'to'],
  userAndDate: ['user', 'from', 'to'],
  userAndPatientAndDate: ['user', 'patient', 'from', 'to'],
};

export const PLAIN_VALIDATION_FIELDS = [
  'from',
  'to',
  'patient',
  'user',
];
