import * as fromByPatient from './byPatient/selectors';
import * as fromById from './byId/selectors';
import * as fromIsCreating from './isCreating';

import * as constants from './constants';

export const getIsFetchingList = (state, hxType, patientId, filter) => {
  return fromByPatient.getIsFetchingList(state[hxType.toLowerCase()].byPatient, patientId, filter);
};

export const getItems = (state, hxType, patientId, filter) => {
  if (!state) return [];

  const type = hxType.toLowerCase();

  return fromByPatient.getItems(state[type].byPatient, patientId, filter)
    .map(itemId => fromById.getItemById(state[type].byId, itemId))
    .filter(item => !item.deleted);
};

export const getActiveAndInactiveItems = (state, hxType, patientId) =>
  fromByPatient.getActiveAndInactiveItems(state[hxType.toLowerCase()].byPatient, patientId);

export const getIsInitializedList = (state, hxType, patientId, filter) =>
  fromByPatient.getIsInitializedList(state[hxType.toLowerCase()].byPatient, patientId, filter);

export const getItemById = (state, hxType, itemId) =>
  fromById.getItemById(state[hxType.toLowerCase()].byId, itemId);

export const getItemStatus = (state, hxType, itemId) => {
  return fromById.getItemStatus(state[hxType.toLowerCase()].byId, itemId);
};

export const getIsCreating = (state, hxType) => fromIsCreating.getIsCreating(state[hxType.toLowerCase()].isCreating);

export const getListError = (state, hxType, patientId, filter) =>
  fromByPatient.getListError(state[hxType.toLowerCase()].byPatient, patientId, filter);

export const getActiveItemsByChartId = (state, hxType, patientId, chartId) =>
  getItems(state, hxType, patientId, 'active').filter(o => o.chartId === chartId);

export const getDiagnoses = (state, patientId, filter) => getItems(state, constants.DIAGNOSES, patientId, filter);
export const getMedications = (state, patientId, filter) => getItems(state, constants.MEDICATIONS, patientId, filter);
export const getAllergies = (state, patientId, filter) => getItems(state, constants.ALLERGIES, patientId, filter);
export const getFamilyItems = (state, patientId) => getItems(state, constants.FAMILY, patientId);
export const getMedicalItems = (state, patientId) => getItems(state, constants.MEDICAL, patientId);
export const getScreeningItems = (state, patientId) => getItems(state, constants.SCREENING, patientId);
export const getSurgicalItems = (state, patientId) => getItems(state, constants.SURGICAL, patientId);

export const getActiveAndInactiveAllergies = (state, patientId) =>
  getActiveAndInactiveItems(state, constants.ALLERGIES, patientId)
    .map(allergyId => getItemById(state, constants.ALLERGIES, allergyId));

