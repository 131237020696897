import React from 'react';
import PropTypes from 'prop-types';

import ReportLine from '../ReportLine';
import Table from 'components/Table';
import FixedHeader from 'components/Table/FixedHeader';
import Loader from 'components/Loader';
import PageError from 'components/PageError';
import ReportPageNav from '../ReportPageNav';
import ReportPageFilters from '../ReportPageFilters';

import { getScrollBarWidth } from 'helpers';

import fields, { sortMap, invertedSortMap } from './fields';

import cx from './ReportsList.module.scss';

let prevIsScrollVisible = null;

class ReportsList extends React.Component {
  static propTypes = {
    logs: PropTypes.array.isRequired,
    params: PropTypes.shape({
      page: PropTypes.number.isRequired,
      from: PropTypes.number,
      to: PropTypes.number,
    }).isRequired,
    location: PropTypes.object.isRequired,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    onReload: PropTypes.func.isRequired,
    lastPage: PropTypes.bool,
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // to determine if table has scrollbar we pass onTableMount callback to it
    // Table component mounts first and after fixed header
    // and there's no this.table in isScrollVisible available
    // to ensure that we know if there's scrollbar present we should forceUpdate on mount
    // so this component fully rerenders and calls isScrollVisible
    // when this.table is already present
    this.forceUpdate();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.logs !== prevProps.logs) {
      this.handleWindowResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleTableMount = (ref) => {
    this.table = ref;
  };

  handleWindowResize = () => {
    if (prevIsScrollVisible !== this.isScrollVisible()) {
      this.forceUpdate();
    }
  };

  isScrollVisible = () => {
    if (!this.table) {
      return false;
    }

    return this.table.scrollHeight > this.table.clientHeight;
  };

  applyFilters = (params) => {
    this.props.router.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.params,
        ...params,
        page: 0,
      },
    });
  };

  sort = (sortBy) => {
    const sortKey = invertedSortMap[sortBy];

    const { params } = this.props;
    const { sortBy: currentSortBy, desc } = params;

    let newDesc;

    if (currentSortBy === sortKey) {
      newDesc = !desc;
    } else {
      newDesc = false;
    }

    this.props.router.replace({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.params,
        sortBy: sortKey,
        desc: newDesc,
      },
    });
  };

  render() {
    const { isScrollVisible, handleTableMount, sort } = this;

    const {
      logs, params, location, isFetching, error, onReload, lastPage,
    } = this.props;

    prevIsScrollVisible = isScrollVisible();

    return (
      <div className={cx.wrapper}>

        <ReportPageFilters
          params={params}
          onApplyFilters={this.applyFilters}
        />

        <div className={cx.wrapper}>
          {!error && (
            <FixedHeader
              fields={fields}
              style={{
                paddingRight: isScrollVisible() ? getScrollBarWidth() : 0,
                height: 'auto',
              }}
              // we have no subtitles on this table, so no need for extra line in header
              hideSubtitleLine
              onSort={sort}
              desc={params.desc}
              sortBy={sortMap[params.sortBy]}
            />
          )}
          {!error && (
            <Table
              color="blue"
              bodyColor="white"
              style={{ marginTop: 33 }}
              onTableMount={handleTableMount}
            >
              <tbody>
                {logs.map(
                  log => (
                    <ReportLine
                      key={log.id}
                      log={log}
                      fields={fields}
                    />
                  )
                )}
              </tbody>
            </Table>
          )}
          {isFetching && <div className={cx['loader-overlay']} />}
          {isFetching && <Loader className={cx.loader} />}
          {error && (
            <PageError
              message={error}
              reload={onReload}
            />
          )}
        </div>
        <ReportPageNav
          location={location}
          page={params.page}
          lastPage={lastPage}
          isFetching={isFetching}
        />
      </div>
    );
  }
}

export default ReportsList;
