import React, { useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Select from 'react-select';

import { useConsents } from './useConsents';
import { usePatient } from 'hooks/usePatient';

import { SingleValue } from 'components/Consents/SingleValue';
import PDFViewer from 'components/PDFEditor/PDFViewer';

import { formatArrayItems } from 'helpers/selectHelpers';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import stringParser from 'helpers/common/string/string-parser';
import cssClassesResolver from 'helpers/common/styles/resolveStyles';

import { downloadTemplate } from 'api/consent';
import { create } from 'api/patient-consent';
import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import {
  usePatientConsents
} from 'routes/routes/App/routes/Patients/routes/Patient/routes/Demographics/Forms/usePatientConsents';

import cx from './PdfEditor.module.scss';
import Loader from 'components/Loader';

const chooseSelectStyles = CUSTOM_WITH_STYLED_DROP_ZONE({
  widthControl: 240,
  heightControl: 28,
  fontSize: 12,
});

export const PdfEditor = () => {
  const [isOpenPdfEditor, setIsOpenPdfEditor] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState();
  const { timeZoneDateConverter } = useContext(AppContext);
  const { patientId } = usePatient();
  const { consents } = useConsents();
  const { addConsent, providerInitials } = usePatientConsents();
  const buffer = useRef(null);
  const file = useRef(null);
  const pdfEditorRef = useRef();
  const [saveLoading, setSaveLoading] = useState(false);

  const openPdfEditor = () => setIsOpenPdfEditor(true);

  const closePdfEditor = () => setIsOpenPdfEditor(false);

  const consentOptions = formatArrayItems(consents);

  const selectConsent = option => {
    setSelectedConsent(option);
    downloadPdf(option.value);
  };

  const downloadPdf = async (id) => {
    const { body } = await downloadTemplate(id);
    const uint8Array = new Uint8Array(body);
    buffer.current = body;
    let blob = new Blob([uint8Array]);
    file.current = new File([blob], 'filename');
    openPdfEditor();
  };

  const close = () => {
    closePdfEditor();
  };

  const saveWrapper = async (saveCallback) => {
    toggleSaveLoader();
    const bytes = await saveCallback();
    await save(bytes);
    toggleSaveLoader();
  };

  const toggleSaveLoader = () => {
    setSaveLoading(prev => !prev);
  };

  const save = async (pdfBytes) => {
    const date = timeZoneDateConverter.getCurrentTimeZoneDateMs();
    const data = {
      clinicConsentId: selectedConsent.value,
      signedTemplate: Array.from(pdfBytes),
      patientId: stringParser(patientId),
      date
    };
    const { body: consentId } = await create(data);
    addConsent({
      consentId,
      consent: { date, name: selectedConsent.label, provider: providerInitials, id: consentId }
    });
    setSelectedConsent(null);
    close();
  };

  const pdfEditorWrapper = createPortal(
    <div ref={pdfEditorRef} className={cssClassesResolver([
      isOpenPdfEditor && cx.container
    ])}>
      {isOpenPdfEditor && (
          <PDFViewer
            buffers={buffer.current}
            file={file.current}
            renderToolbar={({ onSave }) => {
                return (
                  <div className={cx.toolbar}>
                    <button className="generic-button" onClick={close}>Close</button>
                    <button className="generic-button" onClick={() => saveWrapper(onSave)}>Save</button>
                    {saveLoading && (
                      <Loader primaryColor='white' secondaryColor='#2f9dc6' />
                    )}
                  </div>
                );
            }}
          />
      )}
    </div>, document.getElementById('root')
  );

  const buildSingleValue = innerProps => {
    return (
      <SingleValue
        {...innerProps}
        placeholder='Select new Consent Form' />
    );
  };

  return (
    <div style={{ paddingBlock: '40px' }}>
      {!isOpenPdfEditor && (
        <Select
          options={consentOptions}
          onChange={selectConsent}
          components={{ SingleValue: buildSingleValue }}
          placeholder="Select new Consent Form"
          styles={chooseSelectStyles}
        />
      )}
      {pdfEditorWrapper}
    </div>
  );
};