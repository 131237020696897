import { connect } from 'react-redux';

import * as actions from 'modules/user/auth/actions';
import * as selectors from 'modules/user/selectors';

const mapStateToProps = (state) => {
  const loginError = selectors.getLoginError(state.user);
  const loginMessage = selectors.getLoginMessage(state.user);

  const confirmError = selectors.getConfirmError(state.user);
  const confirmMessage = selectors.getConfirmMessage(state.user);

  const recoverError = selectors.getRecoverError(state.user);
  const recoverMessage = selectors.getRecoverMessage(state.user);

  const recoveryTokenValidationError = selectors.getRecoveryTokenValidationError(state.user);
  const recoveryTokenValidationMessage = selectors.getRecoveryTokenValidationMessage(state.user);


  const message = loginMessage || confirmMessage || recoveryTokenValidationMessage || recoverMessage;

  let error;

  if (recoverMessage) {
    error = recoverError;
  }

  if (recoveryTokenValidationMessage) {
    error = recoveryTokenValidationError;
  }

  if (confirmMessage) {
    error = confirmError;
  }

  if (loginMessage) {
    error = loginError;
  }

  return {
    isAuthenticating: selectors.getIsAuthenticating(state.user),
    isAuthenticated: selectors.getIsAuthenticated(state.user),
    isSuperAdmin: selectors.getIsSuperAdmin(state.user),
    error,
    message,
    isBlankClinic: selectors.isBlankClinic(state.user)
  };
};

const mapActionCreators = {
  ...actions,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
