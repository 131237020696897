import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import CancelRoundButton from 'components/CancelRoundButton';
import SaveRoundButton from 'components/SaveRoundButton';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import Item from '../../ElementsViewItem/RegularElement';

import cx from './NewItemForm.module.scss';

const styles = ({ editMode }) => ({
  saveButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['control-button-wrapper', 'save-button-wrapper'], 
    globalStyles: !editMode && 'hidden'
  }),
  cancelButtonWrapper: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'control-button-wrapper',
    globalStyles: !editMode && 'hidden'
  })
})

class NewItemForm extends Component {
  static propTypes = {
    item: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  state = {
    name: '',
    editMode: false,
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.item, prevProps.item)) {
      this.cancel();
    }
  }

  getItemName = () => {
    if (this.state.editMode) {
      return this.state.name;
    }

    const hasPredefinedName = !!this.props.item.name;

    if (hasPredefinedName) {
      return `Add ${this.props.item.name}`;
    }

    return 'Create system';
  }

  handleClick = () => {
    const { item, onSubmit } = this.props;

    const hasPredefinedName = !!item.name;

    if (hasPredefinedName) {
      onSubmit(item);
      return;
    }

    this.setState({ editMode: true });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit({
      ...this.props.item,
      name: this.state.name,
    });
  }

  cancel = () => {
    this.setState({
      name: '',
      editMode: false,
    });
  }

  render() {
    const { item } = this.props;
    const { editMode } = this.state;
    const { handleClick, getItemName } = this;

    const resolvedStyles = styles({ editMode })

    return (
      <form
        className={cx.wrapper}
        onSubmit={this.handleSubmit}
      >
        <Item
          item={{
            ...item,
            name: getItemName(),
          }}
          onClick={handleClick}
          editMode={editMode}
          onChange={e => this.setState({ name: e.target.value })}
          inputClassName={cx.input}
          iconType="plus"
        />
        <span className={resolvedStyles.saveButtonWrapper}>
          <SaveRoundButton
            className={cx['control-button']}
            loading={false}
          />
        </span>

        <span className={resolvedStyles.cancelButtonWrapper}>
          <CancelRoundButton
            className={cx['control-button']}
            onClick={this.cancel}
          />
        </span>
      </form>
    );
  }
}

export default NewItemForm;
