import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import deepEqual from 'deep-equal';

import rolesContainer, { fetchLayerHOC } from 'containers/RolesContainer';
import StaticOptions from 'components/Select/StaticOptions';

import { formatOption, formatOptions } from 'helpers/selectHelpers';
import { CUSTOM_WITH_STYLED_DROP_ZONE } from 'components/Select/customStyles';
import * as rolesHelpers from 'helpers/roles';

import cx from './UserRoleSelect.module.scss';

const SIGN_ENCOUNTER_PERMISSION = 'Sign encounter note';

const roleSelectOptions = CUSTOM_WITH_STYLED_DROP_ZONE({
  heightControl: 32
});

class UserRoleSelect extends Component {
  static propTypes = {
    isCurrentUser: PropTypes.bool,
    onRoleChange: PropTypes.func.isRequired,
    updateUserPermissionsLocal: PropTypes.func,
    roles: PropTypes.object.isRequired,
    user: PropTypes.object,
    showModal: PropTypes.func,
    isInviteForm: PropTypes.bool,
  };

  static defaultProps = {
    isCurrentUser: false,
    user: undefined,
    updateUserPermissionsLocal: () => {},
  };

  shouldComponentUpdate(nextProps) {
    return !deepEqual(this.props, nextProps);
  }

  haveUncheckedSignPermission(nextPermissions) {
    const { user } = this.props;

    const criteria = (o) => (o.name === SIGN_ENCOUNTER_PERMISSION || o.description === SIGN_ENCOUNTER_PERMISSION);

    const prevSignPermission = user.permissions.filter(criteria);
    const currentSignPermission = nextPermissions.filter(criteria);

    return !!prevSignPermission.length && !currentSignPermission.length;
  }

  sendPermissions = (userId, roles, roleId) => {
    const {
      isCurrentUser,
      onRoleChange,
      updateUserPermissionsLocal,
      user,
      showModal,
      isInviteForm,
    } = this.props;

    const abscentSignPermission = !isInviteForm && this.haveUncheckedSignPermission(roles[roleId].permissions);

    if (!isInviteForm && user.setToSeat && abscentSignPermission) {
      showModal();
    } else {
      const roleIdForRequest = rolesHelpers.getUserRoleId(roles[roleId].permissions, roles);

      if (isCurrentUser === true) {
        const permissionsIds = roles[roleId].permissions.map(role => role.id);

        onRoleChange(permissionsIds, roleIdForRequest);
        updateUserPermissionsLocal(userId, roles[roleId].permissions);
        return;
      }

      this.props.onRoleChange(userId, roles[roleId].permissions, roleId, roleIdForRequest);
    }
  }

  handleRoleChange = (option) => {
    const roleId = option.value;
    const { user, roles } = this.props;

    const userId = user ? user.id : undefined;

    this.sendPermissions(userId, roles, roleId);
  }

  render() {
    const {
      roles,
      user,
      activePermissions,
    } = this.props;

    const permissions = user ? user.permissions : activePermissions;

    const options = formatOptions(roles);
    const currentRole = rolesHelpers.getCurrentRole(permissions, roles);

    let placeholder;
    let value;

    if (!currentRole) {
      if ((user && !user.permissions.length) || !user) {
        placeholder = 'Select role...';
      } else {
        placeholder = 'Custom role';
      }
    } else {
      value = formatOption(currentRole);
    }

    return (
      <div className={cx.wrapper} onClick={e => e.stopPropagation()}>
        {user && user.isUpdating &&
          <div className={cx.overlay}>
            <i className={cx['select-loading-icon']} />
          </div>
        }
        <StaticOptions
          value={value}
          placeholder={placeholder}
          options={options}
          onChange={this.handleRoleChange}
          isClearable={false}
          styles={roleSelectOptions}
        />
      </div>
    );
  }
}

export default UserRoleSelect;
