import { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchVitals, saveVitals } from 'modules/charts/actions';
import { isVitalsFetching, getVitals } from 'modules/charts/selectors';
import { getActivePatient } from 'modules/patients/selectors';
import { sort, DESC } from 'helpers/common/sort/sort';

export const fetchLayerHOC = (Component) => (props) => {
  useEffect(() => {
    if (props.isFetching !== undefined || !props.patientId) return;

    props.fetchVitals(props.patientId, props.chartId);
  }, [props.patientId, props.chartId, props.isFetching]);

  return <Component {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const { chartId } = ownProps;
  const patientId = getActivePatient(state.patients).id;

  const isFetching = isVitalsFetching(state.charts, chartId);

  const allVitals = getVitals(state.charts, patientId).filter(o => o);
  const currentVitals = allVitals.find(vitalsItem => vitalsItem.chartId === chartId);

  const vitals = allVitals
    .filter(vitalsItem => vitalsItem.chartId !== chartId);

  return {
    patientId,
    chartId,
    isFetching,
    vitals: sort(vitals, 'dateOfService', DESC),
    currentVitals,
  };
};

const mapActionCreators = {
  fetchVitals,
  saveVitals,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
