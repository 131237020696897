import { stripWhiteListProps, addNewProperty, isNullOrUndefined } from 'helpers';
import isNeedSendingElementNameToServer from 'helpers/tabs/isNeedSendingElementNameToServer';
import * as whiteList from 'helpers/charting/whiteListProps';
import WhitePropsElementsFilter from 'helpers/elements/filters/white-props';
import NoNullSubElementsFilter from 'helpers/elements/filters/no-null-elements';
import formatChartIds from 'helpers/charting/formatters/charts/ids';
import isArrayEmpty from 'helpers/common/array/is-empty';
import ActiveBillingCode from 'helpers/resourses/ActiveBillingCode';
import BillingCodeMapper from 'helpers/resourses/BillingCodeMapper';
import stringParser from 'helpers/common/string/string-parser';

export const formatParamsWithWhiteList = (objectParams, whiteListProps) => {
  const result = { ...objectParams };

  return stripWhiteListProps(result, whiteListProps);
};

export const formatElementsForChart = (elements, whiteListProps) => {
  const elementsWithWhiteListProps = WhitePropsElementsFilter.getElements(elements, whiteListProps);
  const formattedChartIds = formatChartIds(elementsWithWhiteListProps);
  return NoNullSubElementsFilter.getElements(formattedChartIds);
};

export const formatElementsForIcdTen = (icdTen) => {
  if (isNullOrUndefined(icdTen)) {
    return [];
  }

  return icdTen.map(element => element.id);
};

export const formatCpt = (cptCodes) =>
  cptCodes.map((cpt) => {
    const activeBillingCode = new ActiveBillingCode(cpt);
    const billingCodeMapper = new BillingCodeMapper(activeBillingCode);
    return billingCodeMapper.formatCptCodesForFetchingFavorites();
  })

export const createNewContainerParam = (container, charts) => {
  return {
    id: container.id,
    charts,
    chartingId: container.chartingId,
    name: container.name
  };
};

const getWhiteListPropsForChartParam = chartingId =>
  (isNeedSendingElementNameToServer(chartingId)
    ? whiteList.chartingElementForDrChronoServerPropsWhiteList
    : whiteList.chartingElementForSaveChart);

export const collectChartsForSavingChartRequest = (container) => {
  const whiteListProps = getWhiteListPropsForChartParam(container.chartingId);

  return formatElementsForChart(container.charts, whiteListProps);
};

export const formatElementsForContainerParam = (containers, whiteListProps, collectCharts) => {
  const newContainers = [];

  containers.forEach((container) => {
    const newChartParam = collectCharts(container);

    let newContainer = createNewContainerParam(container, newChartParam);

    newContainer = stripWhiteListProps(newContainer, whiteListProps || whiteList.whiteListPropsForContainersParam);

    newContainer = addNewProperty('chartingId', container.chartingId, newContainer);

    newContainers.push(newContainer);
  });

  return newContainers;
};

export const formatElementToFavorite = ({ favoriteId, type, subElementFavoriteData }) => {
  const base = {
    id: favoriteId,
    positive: type,
  }

  return isArrayEmpty(subElementFavoriteData)
    ? base
    : { ...base, favoriteSubElements: subElementFavoriteData }
};

export const collectChartsForFindFavoritesRequest = container =>
  formatChartIds(container.charts)
    .map(formatElementToFavorite);


export const getNextSystem = (step, systems, currentActiveSystem) => {
  if (step === 6) {
    const followUpSystem = systems.filter(system => system.name.toLowerCase() === 'follow-up');

    // if we are in Plan we should push follow-up to the end
    if (followUpSystem.length > 0) {
      const planSystems = [
        ...systems.filter(system => system.name.toLowerCase() !== 'follow-up'),
        followUpSystem[0],
      ];
      return planSystems[planSystems.findIndex(system => system.id === currentActiveSystem) + 1];
    }
  }

  return null;
};

export const formatParamsForUpdateRvSystem = (rvSystem) => {
  const result = { ...rvSystem };

  return stripWhiteListProps(result, whiteList.rvSystemUpdateServerPropsWhiteList);
};

export const getDataForMergeElementsRequest = (step, mainElement, secondaryElements) => {
  const secondaryElementsFavoriteIds = secondaryElements.map(secondElement => secondElement.favoriteId);

  return {
    step,
    primaryFavoriteId: mainElement,
    secondaryFavoriteIds: secondaryElementsFavoriteIds,
  };
};

export const getDataForLocalUpdate = (itemsForMerge) => {
  return itemsForMerge.map(item => item.id);
};

export const fetchChartIdFromCreatingResponse = (response) => {
  return response.userChartingListDTO.id;
};

export const fetchDateOfSignFromCreatingResponse = (response) => {
  return response.selectUserDate;
};

export const getPageNumbersArr = state => (
  Object.keys(state).filter(item => typeof item === 'number')
);

export const removeChartIdFromPage = (pages, pageId) => {
  const pagesArr = Object.keys(pages);
  const newPages = { ...pages };

  let found = false;

  for (let i = 0; i < pagesArr.length; i+=1) {
    const page = pagesArr[i];

    for (let j = 0; j < newPages[page].length; j+=1) {
      const currentPageId = newPages[page][j];

      if (currentPageId === pageId) {
        found = true;
      }

      if (found) {
        if (typeof newPages[page][j + 1] !== 'undefined') {
          newPages[page][j] = newPages[page][j + 1];
        } else if (typeof newPages[stringParser(page) + 1] !== 'undefined') {
          newPages[page][j] = newPages[stringParser(page) + 1][0];
        } else {
          newPages[page].splice(j, 1);
          break;
        }
      }
    }
  }

  return newPages;
};
