export default class HcpcResource {
  constructor({
    id, hcpcMod = '', shortDescription = '', favoriteId, ids = [],
  }) {
    this._id = id;
    this._hcpcMod = hcpcMod;
    this._shortDescription = shortDescription;
    this._favoriteId = favoriteId;
    this._ids = ids;
  }

  getId = () => this._id

  getHcpcMod = () => this._hcpcMod

  getDescription = () => this._shortDescription

  getFavoriteId = () => this._favoriteId

  getIds = () => this._ids

  getFullName = () =>
    `${this._hcpcMod} ${this._shortDescription}`
}
