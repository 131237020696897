import AdditionalCheckboxDataTemplate from 'containers/FMSS/FMSSPage/AdditionalCheckbox/AdditionalCheckboxDataTemplate.js';

import { getSurgicalItems } from 'modules/patientsHx/selectors.js';

export default class AdditionalSurgicalCheckboxDataFactory {
  constructor({ patientId, state }) {
    this.patientId = patientId;
    this.state = state;
  }

  getData = () => {
    const additionalCheckboxDataTemplate = new AdditionalCheckboxDataTemplate({
      patientId: this.patientId,
      state: this.state,
      checkboxParamName: 'hasNoPreviousSurgeries',
      items: this.getItems(),
    });

    return additionalCheckboxDataTemplate.getMappedProps();
  };

  getItems = () => {
    return getSurgicalItems(this.state.patientsHx, this.patientId);
  }
}
