import { ALLERGY_STATUS } from 'helpers/fmss/allergies/constants.js';

export default class NoDrugAllergies {
  constructor(filter = ALLERGY_STATUS.active, hasNoDrugAllergies = false) {
    this.filter = filter;
    this.hasNoDrugAllergies = hasNoDrugAllergies;
  }

  isPresent = () => {
    return this.hasNoDrugAllergies && this.filter === ALLERGY_STATUS.active;
  }
}
