import { chartingElementServerPropsWhiteList } from 'helpers/charting/whiteListProps';
import { stripWhiteListProps } from 'helpers';

export default class WhitePropsElementsFilter {
  static getElements = (elements, whiteListProps) => {
    const newElements = [];
    elements.forEach((element) => {
      let newElement = { ...element };

      newElement = stripWhiteListProps(newElement, whiteListProps || chartingElementServerPropsWhiteList);

      newElements.push(newElement);
    });

    return newElements;
  }
}
