import React from 'react';
import { compose } from 'redux';
import { useRoute } from 'hooks/useRoute';

import patientContainer, { fetchLayerHOC } from 'containers/Patients/PatientContainer';
import DemographicsForm from 'containers/Demographics/DemographicsForm';

const PatientComponent = compose(
  patientContainer,
  fetchLayerHOC
)(DemographicsForm);


const DemographicsPage = () => {
  const { fromParams } = useRoute();
  const patientId = fromParams('patientId');
  return (
    <PatientComponent
      patientId={patientId}
    />
  );
};

export default DemographicsPage;
