export const PAST_DUE_DAYS_VALUES = {
  READ_ONLY_DAYS: 15,
  START_READ_ONLY_DAYS: 16,
  ERROR_TRESHOLD: 7,
  PASTDUE_DAYS_BEFORE_WARNING: 4,
};

export const SEVERITY_MAP = {
  0: 'error',
  1: 'info',
  2: 'warning',
  3: 'warning',
};
