import React from 'react';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from 'routes/routes/App/routes/Settings/routes/General/components/Preferences/DaylightSavingCheckbox/DaylightSavingCheckbox.module.scss';

const checkBoxStyles = ({ checked }) => resolveStylesV2({
  objectStyles: cx,
  moduleStyles: [checked ? 'checkbox--active' : 'checkbox']
});

const DaylightSavingCheckbox = (props) => {

  const { name, checked, onClick } = props;

  const handleClick = () => onClick(name);

  return (
    <div className={cx['checkbox-wrapper']}>
      <i
        onClick={handleClick}
        className={checkBoxStyles({ checked })}
      />
      <label
        onClick={handleClick}
        className={cx.label}
      >
        Automatically adjust clock for Daylight Saving Time
      </label>
    </div>
  );
};

export default DaylightSavingCheckbox;
