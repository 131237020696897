import { hpChartTabs } from 'helpers/chart';

export const ROS_SYSTEMS = {
  EARS: {
    id: 4,
    name: 'Ears',
  },
  NOSE: {
    id: 5,
    name: 'Nose',
  },
  THROAT: {
    id: 7,
    name: 'Throat',
  },
  ENT: {
    id: 82,
    name: 'Ent',
  },
};

export const PE_SYSTEMS = {
  EARS: {
    id: 54,
    name: 'Ears',
  },
  NOSE: {
    id: 55,
    name: 'Nose',
  },
  THROAT: {
    id: 57,
    name: 'Throat',
  },
  ENT: {
    id: 84,
    name: 'Ent',
  },
};

export const BILLING_SYSTEMS = {
  HCPC: {
    type: 7,
    name: 'HCPC',
    resourceType: 'hcpc',
  },
  CPT: {
    type: 6,
    name: 'CPT',
    resourceType: 'cpt',
  },
};

export const systemsTypesToExcludeReportRendering = [hpChartTabs.BILLING.systems.CPT.type];
