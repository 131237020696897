import { handleActions } from 'redux-actions';
import * as constants from '../constants';
import * as chartsConstants from '../../charts/constants';
import * as chartingAssetsConstants from '../../chartingAssets/constants';

import deleteObjectProperty from 'helpers/common/object/delete-property';
import updateArrayItemByIndex from 'helpers/common/array/update-item/by-index';
import collectSubElementData, {
  completeSubElementProps,
  STRATEGIES
} from 'helpers/elements/subElementData/converters/fromSubElements';
import { hpChartTabs } from 'helpers/chart';
import isEmpty from 'helpers/common/array/is-empty';
import parseElementBySystemTypeStrategyManager from 'helpers/elements/parseElementBySystemTypeStrategyManager';

const initialState = {
};

const defaultElement = {
  ids: null,
  type: null,
  subIds: [],
};

const fetchChartFilterExcludeEmptyElementsStrategy = step =>
  !isEmpty(step.elements);

const fetchChartFilterDefaultStrategy = step => step;

const fetchChartFilterSystemsStrategies = {
  [hpChartTabs.HXRV.step]: fetchChartFilterExcludeEmptyElementsStrategy,
  default: fetchChartFilterDefaultStrategy,
};

const parseSystems = (systems, filterStrategy) =>
  systems
    .filter(filterStrategy)
    .reduce((oldState, iteratedSystem) => {
      const parseServerElementStrategy = parseElementBySystemTypeStrategyManager(iteratedSystem.systemType);
      const mappedElements = iteratedSystem.elements
        .map(parseServerElementStrategy);

      return {
        ...oldState,
        [iteratedSystem.id]: mappedElements,
      };
    }, {});

const parseActiveElements = responseData =>
  responseData.reduce((oldState, iteratedStep) => {
    const { chartingId, systems } = iteratedStep;
    const filterSystemsStrategies = fetchChartFilterSystemsStrategies[chartingId]
      || fetchChartFilterSystemsStrategies.default;

    const parsedSystems = parseSystems(systems, filterSystemsStrategies);

    return {
      ...oldState,
      ...parsedSystems,
    };
  }, {});

const addElement = (state, element, systemId) => {
  const nextState = { ...state };

  // create system if it doesn't exist
  if (!nextState[systemId]) {
    nextState[systemId] = [];
  }

  const elementIndex = nextState[systemId].findIndex(_element => _element.id === element.id);

  const subElementData = collectSubElementData(element.subIds);
  const formattedElement = {
    ...defaultElement,
    ...element,
    subElementData,
    subElementFavoriteData: collectSubElementData(element.subIds, completeSubElementProps(STRATEGIES.FAVORITE)),
  };


  // if element exists we update it
  if (elementIndex !== -1) {
    nextState[systemId][elementIndex] = formattedElement;

    return nextState;
  }

  nextState[systemId] = [...nextState[systemId], { ...formattedElement }];
  return nextState;
};

const handlers = {
  [constants.ADD_SYSTEM]: (state, action) => {
    if (state[action.payload.systemId]) return state;

    return {
      ...state,
      [action.payload.systemId]: [],
    };
  },
  [constants.REMOVE_SYSTEM]: (state, action) => {
    const nextState = { ...state };
    delete (nextState[action.payload.systemId]);
    return nextState;
  },
  [constants.REMOVE_ELEMENT_IN_ALL_SYSTEMS]: (state) => {
    return state;
  },
  [chartingAssetsConstants.ELEMENT_CREATE_SUCCESS]: (state, action) => {
    if (!action.payload.elementToAdd) return state;

    const element = {
      ...action.payload.elementToAdd,
      name: action.payload.elementToCreate.name,
      id: action.payload.result.id,
      favoriteId: action.payload.result.favoriteId,
      deleted: false,
    };
    return addElement(state, element, action.payload.systemId);
  },
  [constants.ADD_ELEMENT]: (state, action) => addElement(state, action.payload.element, action.payload.systemId),
  [constants.REMOVE_ELEMENT]: (state, action) => ({
    ...state,
    [action.payload.systemId]: state[action.payload.systemId].filter(element => element.id !== action.payload.element.id),
  }),
  [chartsConstants.CHART_FETCH_SUCCESS]: (state, action) => {
    const {
      status,
      chart,
    } = action.payload.result;

    if (status === 204) return state;

    return parseActiveElements(chart.note);
  },
  [chartsConstants.COPY_PREVIOUS_NOTE_SUCCESS]: (state, action) => {
    const {
      result,
    } = action.payload;

    if (result.status === 204) return state;

    return parseActiveElements(result);
  },
  [chartingAssetsConstants.ELEMENT_UPDATE_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      systemId, oldElement, newElement, result,
    } = action.payload;
    const { type, id: oldElementId } = oldElement;
    const { name: newElementName } = newElement;
    const { id: resultId, deleted: resultDeleted } = result;

    const nextStateWithoutCurrentSystem = deleteObjectProperty(nextState, systemId);

    const elementToChange = oldElementId;

    const index = nextState[systemId].findIndex(el => el.id === elementToChange);

    const nextSystem = [
      ...nextState[systemId],
    ];

    nextSystem[index] = {
      ...defaultElement,
      ...nextState[systemId][index],
      id: resultId,
      name: newElementName,
      type: type !== undefined ? type : defaultElement.type,
      deleted: resultDeleted,
    };

    nextState[systemId] = nextSystem;


    return Object.keys(nextStateWithoutCurrentSystem).reduce((oldState, notCurrentSystemId) => {
      if (nextStateWithoutCurrentSystem[notCurrentSystemId].filter(element => element.id === oldElementId).length) {
        const indexElementToSetDeletedTrue = nextState[notCurrentSystemId].findIndex(el => el.id === oldElementId);
        const oldElementWithDeletedTrue = {
          ...oldState[notCurrentSystemId][indexElementToSetDeletedTrue],
          deleted: true,
        };

        const updatedNewState = updateArrayItemByIndex(nextState[notCurrentSystemId], indexElementToSetDeletedTrue, oldElementWithDeletedTrue);

        return {
          ...oldState,
          [notCurrentSystemId]: updatedNewState,
        };
      }

      return oldState;
    }, { ...nextState });
  }
};

export const handledActions = Object.keys(handlers);

export default handleActions(handlers, initialState);
