export const smokingStatusArray = [
  { title: 'Current every day smoker', value: 1 },
  { title: 'Current some day smoker', value: 2 },
  { title: 'Former smoker', value: 3 },
  { title: 'Never smoker', value: 4 },
  { title: 'Smoker, current status unknown', value: 5 },
  { title: 'Unknown if ever smoked', value: 6 },
  { title: 'Heavy tobacco smoker', value: 7 },
  { title: 'Light tobacco smoker', value: 8 },
];

export const getBMI = ({ weight: _weight, height: _height }) => {
  const weight = parseFloat(_weight);
  const height = parseFloat(_height);

  if (!weight || !height) return '-';
  return Math.round((weight / Math.pow(height, 2)) * 703 * 100) / 100;
};

const underweightIcon = require('./images/underweight.png');
const normalIcon = require('./images/normal.png');
const overweightIcon = require('./images/overweight.png');
const obesity1Icon = require('./images/obesity1.png');
const obesity2Icon = require('./images/obesity2.png');
const obesity3Icon = require('./images/obesity3.png');

export const getBmiStatus = (bmi) => {
  if (parseFloat(bmi) !== bmi) return null;

  if (bmi < 18.5) {
    return {
      src: underweightIcon,
      title: 'Underweight',
      color: '#ff9000',
    };
  }

  if (bmi >= 18.5 && bmi < 25) {
    return {
      src: normalIcon,
      title: 'Normal',
      color: '#30c6a1',
    };
  }

  if (bmi >= 25 && bmi < 30) {
    return {
      src: overweightIcon,
      title: 'Overweight',
      color: '#ff5a5a',
    };
  }

  if (bmi >= 30 && bmi < 35) {
    return {
      src: obesity1Icon,
      title: 'Obesity (Class I)',
      color: '#ff5a5a',
    };
  }

  if (bmi >= 35 && bmi < 40) {
    return {
      src: obesity2Icon,
      title: 'Obesity (Class II)',
      color: '#ff5a5a',
    };
  }

  return {
    src: obesity3Icon,
    title: 'Extreme Obesity (Class III)',
    color: '#ff5a5a',
  };
};
