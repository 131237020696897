import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';

import ChartReportElementWithTooltip from '../../../../../../ChartReportElementWithTooltip';
import RemoveButton from '../../../../../../ChartReportElementWithTooltip/RemoveButton';
import DoorButton from '../../../../../../ChartReportElementWithTooltip/DoorButton';

import { sortSubIdsByPosition } from 'helpers/chart';

const MENU_BUTTON_DIAMETER = 40;

const ChartReportSocialElement = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { elementNameConverter } = useContext(AppContext);

  const openMenu = () => setIsMenuOpen(true);

  const closeMenu = () => setIsMenuOpen(false);

  const {
    element,
    chartId,
    toggleChartReport,
    systemId,
    viewMode = false,
    patient,
    removeElements,
  } = props;

  const newElement = sortSubIdsByPosition(element);

  const displayName = elementNameConverter.transformElementNameWithSubElements(newElement, patient);

  return (
    <ChartReportElementWithTooltip
      isOpen={isMenuOpen}
      onOpen={openMenu}
      onClose={closeMenu}
      disabled={viewMode}
    >
      <span>
        {displayName}
      </span>

      <div className="flex-container">
        <DoorButton
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
            marginRight: 10,
          }}
          link={{ pathname: `/app/doctor/charts/${chartId}/fmss/social`, query: { system: systemId } }}
          onClick={() => {
            closeMenu();
            toggleChartReport();
          }}
        />
        <RemoveButton
          onClick={removeElements}
          style={{
            width: MENU_BUTTON_DIAMETER,
            height: MENU_BUTTON_DIAMETER,
          }}
        />
      </div>

    </ChartReportElementWithTooltip>
  );
};

ChartReportSocialElement.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  chartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  toggleChartReport: PropTypes.func.isRequired,
  systemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  removeElements: PropTypes.func.isRequired,
  viewMode: PropTypes.bool,
  patient: PropTypes.object.isRequired,
};

export default ChartReportSocialElement;
