import { handleActions } from 'redux-actions';

import keyBy from 'lodash/keyBy';

import * as constants from '../constants';
import * as socialSessionsConstants from '../../socialSessions/constants';

import SocialInitialElement from 'payload/social/elements/initializing';

import { isNullOrUndefined } from 'helpers';
import deleteObjectProperty from 'helpers/common/object/delete-property';

const initialState = {};

export default handleActions({
  [constants.FETCH_SOCIAL_ELEMENTS_BY_SYSTEM_SUCCESS]: (state, action) => ({
    ...state,
    ...keyBy(action.payload.result.map((element) => {
      return { ...state[element.id], ...SocialInitialElement.initializeElement(element) };
    }), 'id'),
  }),
  [socialSessionsConstants.PATIENT_SOCIAL_FETCH_SUCCESS]: (state, action) => {
    let nextState = { ...state };
    const { result } = action.payload;

    if (isNullOrUndefined(result.social)) return nextState;

    action.payload.result.social.forEach((system) => {
      const elements = keyBy(system.elements.map((element) => {
        return { ...element, systemId: system.id };
      }), 'id');
      nextState = { ...nextState, ...elements };
    });

    return nextState;
  },
  [socialSessionsConstants.SOCIAL_ELEMENT_ADD]: (state, action) => ({
    ...state,
    [action.payload.elementToCreate.id]: {
      ...state[action.payload.elementToCreate.id],
      ...SocialInitialElement.initializeElement(action.payload.elementToCreate),
      ...SocialInitialElement.getInitialSubElements(),
    },
  }),
  [constants.CREATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.result]: {
      ...state[action.payload.result],
      ...action.payload.element,
      id: action.payload.result,
      ...SocialInitialElement.getInitialElement(),
    },
  }),
  [constants.UPDATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      result,
      oldElement,
      newElement,
    } = action.payload;

    delete nextState[oldElement.id];

    nextState[result] = {
      ...oldElement,
      ...newElement,
      id: result,
    };

    return nextState;
  },
  [constants.UPDATE_SOCIAL_ELEMENT_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      result,
      oldElement,
      newElement,
    } = action.payload;

    delete nextState[oldElement.id];

    nextState[result] = {
      ...oldElement,
      ...newElement,
      id: result,
    };

    return nextState;
  },
  [constants.UPDATE_SOCIAL_SUB_ELEMENTS_HISTORY_SUCCESS]: (state, action) => {
    const nextState = { ...state };
    const {
      localData,
    } = action.payload;

    const {
      newElement,
    } = localData;

    nextState[newElement.id] = {
      ...nextState[newElement.id],
      ...newElement,
      ...SocialInitialElement.getInitialSubElements(),
    };

    return nextState;
  },
	[constants.DELETE_RIGHT_SIDE_ELEMENT_SUCCESS]: (state, action) => {
    const { id } = action.payload;
    const nextState = { ...state };

    return deleteObjectProperty(nextState, id);
	}
}, initialState);
