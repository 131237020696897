import React, { useState } from 'react';
import { func } from 'prop-types'

import RadioButtonGroup from 'components/RadioButtonGroup';
import SearchUser from 'routes/routes/Admin/routes/User/routes/Manager/components/SearchUser';
import SearchUserByClinic from 'routes/routes/Admin/routes/User/routes/Manager/components/SeachUserByClinic';

const ChooseUser = (props) => {
  const searchStrategy = {
    byUser: 'search_by_user',
    byClinic: 'search_by_clinic'
  }

  const [selectedSearchStrategy, setSelectedSearchStrategy] = useState(searchStrategy.byUser)

  const {
    onSelectUser
  } = props

  const changeStrategy = (option) => {
    setSelectedSearchStrategy(option)

    onSelectUser({ internalSelectedUser: null, internalSelectedClinic: null })
  }

  return (
    <>
      <h3>Search By</h3>
      <RadioButtonGroup
        items={[{
          name: 'User',
          value: searchStrategy.byUser,
        }, {
          name: 'Clinic',
          value: searchStrategy.byClinic,
        }]}
        checked={selectedSearchStrategy}
        onChange={changeStrategy}
      />
      {(selectedSearchStrategy === searchStrategy.byUser) && (
        <SearchUser
          onSelect={onSelectUser}
        />
      )}
      {(selectedSearchStrategy === searchStrategy.byClinic) && (
        <SearchUserByClinic
          onSelect={onSelectUser}
        />
      )}
    </>
  )
}

ChooseUser.propTypes = {
  onSelectUser: func.isRequired,
}

export default ChooseUser
