import { connect } from 'react-redux';

import * as selectors from 'modules/chartingAssets/selectors';

import * as chartsSelectors from 'modules/charts/selectors';

import { withRouter } from 'containers/RouterParams';

import { getCompletionForStepsFromState } from 'modules/rootSelectors';
import { getActivePatient } from 'modules/patients/selectors';

const mapStateToProps = (state, ownProps) => {
  const chartingSteps = selectors.getSections(state.chartingAssets);

  const { id: patientId } = getActivePatient(state.patients);
  const { chartId } = ownProps;

  const isCopyingPreviousNote = chartsSelectors.getIsCopyingPreviousNote(state.charts, chartId);

  const socialChartingStep = {
    fullName: 'F/S Hx',
    id: 0,
    name: 'F/S Hx',
    type: 'social',
  };

  const chartingStepsWithSocial = [...chartingSteps];
  chartingStepsWithSocial.splice(2, 0, socialChartingStep);

  return {
    isFetching: false,
    chartingSteps: chartingStepsWithSocial,
    patientId,
    isCopyingPreviousNote,
    completion: getCompletionForStepsFromState(state, chartId),
  };
};

const mapActionCreators = {};

export default component => withRouter(connect(mapStateToProps, mapActionCreators)(component));
