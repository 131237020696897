import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import PageError from 'components/PageError';

export default class LoaderLayer extends React.Component {
  static propTypes = {
    status: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      isFetched: PropTypes.bool.isRequired,
      error: PropTypes.shape({
        status: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
      }),
    }),
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    reload: PropTypes.func.isRequired,
  }

  render() {
    const { status, disabled, children, reload } = this.props;

    if (disabled) return children;

    if (status.isFetching && !status.isFetched) {
      return <Loader secondaryColor="#e8f0f3" />;
    }

    if (status.error) {
      return (
        <PageError message={status.error.message} reload={reload} />
      );
    }

    return children;
  }
}
