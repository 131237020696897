import { handleActions } from 'redux-actions';
import {
  SYSTEM_NOTE_FETCH,
  SYSTEM_NOTE_FETCH_SUCCESS,
  SYSTEM_NOTE_FETCH_FAIL,
} from '../constants';

export default handleActions({
  [SYSTEM_NOTE_FETCH]: () => true,
  [SYSTEM_NOTE_FETCH_SUCCESS]: () => false,
  [SYSTEM_NOTE_FETCH_FAIL]: () => false,
}, false);
