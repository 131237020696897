import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import SearchInput from 'routes/routes/App/components/SearchInput';
import Loader from 'components/Loader';
import EventListenerLayer from 'components/EventListenerLayer';

import cx from './ClinicsList.module.scss';

const ClinicsList = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const clinicListRef = useRef();

  const searchClinics = (value) => setSearchValue(value);

  const {
    onClinicSelect,
    areClinicsFetching,
    clinics = [],
    hideClinicList,
  } = props;

  const mappedClinics = map(clinics);

  let filteredClinics;

  if (searchValue.length) {
    filteredClinics = mappedClinics.filter((clinic) => {
      if (!clinic.legalName) return [];

      return clinic.legalName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    });
  } else {
    filteredClinics = mappedClinics;
  }

  return (
    <EventListenerLayer
      onClickOutSide={hideClinicList}
      connectedNodes={[clinicListRef]}
    >
      <div className={cx.wrapper}>
        <div className={cx['search-wrapper']}>
          <h1 className={cx['search-title']}>
            <b>C</b>hoose <b>L</b>ocation
          </h1>
          <div className={cx['input-wrapper']}>
            <SearchInput
              onSearch={searchClinics}
              placeholder="Clinic name"
              clearSearchingResultsCallback={() => {}}
            />
          </div>
        </div>


        <div ref={clinicListRef} className={cx['clinics-list']}>
          {areClinicsFetching &&
            <Loader />
          }
          {!areClinicsFetching && !!filteredClinics.length && filteredClinics.map((clinic) => (
            <div
              onClick={() => onClinicSelect(clinic.id)}
              className={cx['clinic-item']}
              key={clinic.id}
            >
              <div className={cx.icon}>
                <i className={cx['add-button']} />
              </div>
              <div className={cx.info}>
                <div className={cx.title}>{clinic.legalName}</div>
                <div
                  className={cx.address}
                  title={`${clinic.address1}, ${clinic.city}`}
                >
                  {clinic.address1}, {clinic.city}
                </div>
              </div>
            </div>
          ))}
          {!filteredClinics.length && !!searchValue.length && (
            <div className={cx['empty-message']}>
              {`No clinics found with name "${searchValue}".`}
            </div>
          )}
          {!areClinicsFetching && !filteredClinics.length && !searchValue.length && (
            <div className={cx['empty-message']}>
              No clinics found.
            </div>
          )}
        </div>
      </div>
    </EventListenerLayer>
  );
};

ClinicsList.propTypes = {
  hideClinicList: PropTypes.func.isRequired,
  onClinicSelect: PropTypes.func.isRequired,
  clinics: PropTypes.shape({
    legalName: PropTypes.string,
  })
};

export default ClinicsList;
