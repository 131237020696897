export const PATIENTS_FETCH = 'PATIENTS_FETCH';
export const PATIENTS_FETCH_SUCCESS = 'PATIENTS_FETCH_SUCCESS';
export const PATIENTS_FETCH_FAIL = 'PATIENTS_FETCH_FAIL';

export const SEARCH_TAB_PATIENTS_FETCH = 'SEARCH_TAB_PATIENTS_FETCH';
export const SEARCH_TAB_PATIENTS_FETCH_SUCCESS = 'SEARCH_TAB_PATIENTS_FETCH_SUCCESS';
export const SEARCH_TAB_PATIENTS_FETCH_FAIL = 'SEARCH_TAB_PATIENTS_FETCH_FAIL';

export const PATIENTS_PAGE_FETCH = 'PATIENTS_PAGE_FETCH';
export const PATIENTS_PAGE_FETCH_SUCCESS = 'PATIENTS_PAGE_FETCH_SUCCESS';
export const PATIENTS_PAGE_FETCH_FAIL = 'PATIENTS_PAGE_FETCH_FAIL';

export const PATIENTS_SEARCH = 'PATIENTS_SEARCH';
export const PATIENTS_SEARCH_SUCCESS = 'PATIENTS_SEARCH_SUCCESS';
export const PATIENTS_SEARCH_FAIL = 'PATIENTS_SEARCH_FAIL';
export const PATIENTS_SEARCH_QUERY_CLEAR = 'PATIENTS_SEARCH_QUERY_CLEAR';

export const PATIENT_FETCH = 'PATIENT_FETCH';
export const PATIENT_FETCH_SUCCESS = 'PATIENT_FETCH_SUCCESS';
export const PATIENT_FETCH_FAIL = 'PATIENT_FETCH_FAIL';

export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_UPDATE_FAIL = 'PATIENT_UPDATE_FAIL';

export const FETCH_PATIENT_IMAGE = 'FETCH_PATIENT_IMAGE';
export const FETCH_PATIENT_IMAGE_SUCCESS = 'FETCH_PATIENT_IMAGE_SUCCESS';
export const FETCH_PATIENT_IMAGE_FAIL = 'FETCH_PATIENT_IMAGE_FAIL';

export const PATIENT_CREATE = 'PATIENT_CREATE';
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS';
export const PATIENT_CREATE_FAIL = 'PATIENT_CREATE_FAIL';

export const TOGGLE_DRUG_ALLERGIES = 'TOGGLE_DRUG_ALLERGIES';
export const TOGGLE_DRUG_ALLERGIES_SUCCESS = 'TOGGLE_DRUG_ALLERGIES_SUCCESS';
export const TOGGLE_DRUG_ALLERGIES_FAIL = 'TOGGLE_DRUG_ALLERGIES_FAIL';

export const TOGGLE_PERTINENT_FAMILY_MEDICAL = 'TOGGLE_PERTINENT_FAMILY_MEDICAL';
export const TOGGLE_PERTINENT_FAMILY_MEDICAL_SUCCESS = 'TOGGLE_PERTINENT_FAMILY_MEDICAL_SUCCESS';
export const TOGGLE_PERTINENT_FAMILY_MEDICAL_FAIL = 'TOGGLE_PERTINENT_FAMILY_MEDICAL_FAIL';

export const TOGGLE_PERTINENT_MEDICAL_HX = 'TOGGLE_PERTINENT_MEDICAL_HX';
export const TOGGLE_PERTINENT_MEDICAL_HX_SUCCESS = 'TOGGLE_PERTINENT_MEDICAL_HX_SUCCESS';
export const TOGGLE_PERTINENT_MEDICAL_HX_FAIL = 'TOGGLE_PERTINENT_MEDICAL_HX_FAIL';

export const TOGGLE_PREVIOUS_SURGERIES = 'TOGGLE_PREVIOUS_SURGERIES';
export const TOGGLE_PREVIOUS_SURGERIES_SUCCESS = 'TOGGLE_PREVIOUS_SURGERIES_SUCCESS';
export const TOGGLE_PREVIOUS_SURGERIES_FAIL = 'TOGGLE_PREVIOUS_SURGERIES_FAIL';

export const PATIENT_ADD_TO_RECENT = 'PATIENT_ADD_TO_RECENT';

export const SET_PATIENT_FOR_CHART = 'SET_PATIENT_FOR_CHART';

export const PATIENTS_LIST_CONSENTS = 'PATIENTS_LIST_CONSENTS';

export const ADD_PATIENT_CONSENT = 'ADD_PATIENT_CONSENT';

export const DELETE_PATIENT_CONSENT = 'DELETE_PATIENT_CONSENT';
