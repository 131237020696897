import FetchCardResponseStatusHandler from 'response/card/fetch/handle-action';

export default class FetchCardResponseProducer {
  static getCard = (fetchingCardResponse) => {
    const fetchCardResponseStatusHandler = new FetchCardResponseStatusHandler(fetchingCardResponse);
    const handler = fetchCardResponseStatusHandler.getHandler();

    return handler.getCard();
  }
}
