import { handleActions } from 'redux-actions';

import * as constants from 'modules/resetFavorites/constants';

export default handleActions({
  [constants.RESET_FAVORITES]: (state, action) => ({
    ...state,
    [action.payload.chartId]: [
      ...action.payload.favoritesToReset,
    ],
  }),
}, {});
