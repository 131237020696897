import React from 'react';
import PropTypes from 'prop-types';

import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';

import cx from './PermissionCheckableItem.module.scss';

const permissionItemStyles = ({disabled, active}) => ({
  btn: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: ['wrapper', disabled && 'disabled']
  }),
  icon: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: active ? 'icon--active' : 'icon'
  }),
  label: resolveStylesV2({
    objectStyles: cx,
    moduleStyles: 'name'
  })
})

const PermissionItem = ({
  disabled, active, name, onClick,
}) => {

  const { btn, icon, label } = permissionItemStyles({active, disabled})

  const onClickProp = !disabled ? { onClick: onClick } : {};

  return (
    <button
      type="button"
      className={btn}
      {...onClickProp}
    >
      <i className={icon} />
      <span className={label}>{name}</span>
    </button>
  )
};

PermissionItem.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

PermissionItem.defaultProps = {
  disabled: false,
  active: false,
};

export default PermissionItem;
