import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';

import InfoPopup from 'components/InfoPopup';
import Loader from 'components/Loader';

import { isMobileApp } from 'helpers';
import ImgSrc from 'helpers/images/styles/img-src';
import isEmpty from 'helpers/common/array/is-empty';
import resolveStylesV2 from 'helpers/common/styles/resolveStylesV2';
import { isImageTypeRejected, getFileType } from 'helpers/FileUtils';

import { uploadPatientPhoto } from 'api/patients';

import cx from './SignatureInput.module.scss';

const bytesToMb = bytes => bytes / 1024 /1024;

// file size limit in mb
const FILE_SIZE_LIMIT_BYTES = 3145728;
const FILE_SIZE_LIMIT_MB = bytesToMb(FILE_SIZE_LIMIT_BYTES);

const SignatureInput = (props) => {
  const [uploadedImage, setUploadedImage] = useState(undefined);
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const {
    imageUrl,
    entityId,
    onChange
  } = props;

  const resetImage = useCallback(() => {
    const imageUrlOrNull = imageUrl || null;
    setUploadedImage(imageUrlOrNull);
  }, [imageUrl]);

  useEffect(() => {
    resetImage();
  }, [entityId, resetImage]);

  useEffect(() => {
    setUploadedImage(imageUrl);
  }, [imageUrl]);

  const onDrop = useCallback(acceptedFiles => {
    const isPresentAcceptedFile = !isEmpty(acceptedFiles);
    if (isPresentAcceptedFile) {
      const acceptedFile = acceptedFiles[0];

      if (acceptedFile.size > FILE_SIZE_LIMIT_BYTES) {
        setUploadError({ message: `File size exceeds ${FILE_SIZE_LIMIT_MB}mb limit` });
        return;
      }

      const imageType = getFileType(acceptedFile.name);

      if (isImageTypeRejected(imageType)) {
        setUploadError({ message: 'This image type is not supported', });
        resetImage();

        return;
      }

      setIsUploading(true);

      uploadPatientPhoto(acceptedFile, 0)
        .then((res) => {
          const buildImageUrl = URL.createObjectURL(acceptedFile);
          setUploadedImage(buildImageUrl);
          onChange({ imageUrl: buildImageUrl, imageName: res.body.name });

          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(false);
          setUploadError({ message: 'Something went wrong', });
          resetImage();
        });
    }
  }, [onChange, resetImage]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    multiple: false,
    accept: 'image/*',
    disabled: false,
  });

  const {
    title,
  } = props;

  return (
    <div className={cx.container}>
      {title && <h3 className={
        resolveStylesV2({ objectStyles: cx, moduleStyles: 'title', globalStyles: 'section-title' })
      }>{title}</h3>}

      <div className={cx['loader-container']}>

        <div
          className={cx['image-container']}
        >
          <img
            src={ImgSrc.define(uploadedImage)}
            className={
              resolveStylesV2({ objectStyles: cx, moduleStyles: ['image', isUploading  && 'image--loading'] })}
            alt="Signature"
          />
          {isUploading && (
            <div className={cx['loader-wrapper']}>
              <Loader secondaryColo="transparent" />
            </div>
          )}
        </div>
        {!isMobileApp() && (
          <>
            <div
              className={cx.controls}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <div className={cx['button-container']}>
                <button
                  type="button"
                  className={cx['file-button']}
                >
                  Add from file
                </button>
              </div>
            </div>
            <div className={cx['info-popup-container']}>
              <InfoPopup small>
                Image file size should be less than {FILE_SIZE_LIMIT_MB}mb.
              </InfoPopup>
            </div>
          </>
        )}
      </div>
      {uploadError && (
        <div className={cx.error}>
          {uploadError.message}
        </div>
      )}
    </div>
  );
};

SignatureInput.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func,
  imageUrl: PropTypes.string,
};

SignatureInput.defaultProps = {
  title: undefined,
  onUploadStart: undefined,
  onUploadEnd: undefined,
  imageUrl: null,
};

export default SignatureInput;
