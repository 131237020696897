import singleSession, { handledActions } from './singleSession';

import * as constants from './constants';
import * as helpers from '../../helpers';

const initialState = {};

export default (state = initialState, action) => {
  if (handledActions.includes(action.type)) {
    const { chartId } = action.payload;

    if (action.type === constants.REMOVE_ELEMENT_IN_ALL_SYSTEMS) {
      return helpers.deleteElementsFromSessionByIds(state, action.payload.elementIds);
    }

    return {
      ...state,
      [chartId]: singleSession(state[chartId], action),
    };
  }

  return state;
};
