import React, { Component, useRef, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'containers/RouterParams';
import PropTypes from 'prop-types';

import { Formik } from 'formik';

import dirtyCheckLayerHOC from 'containers/DirtyCheckLayer';
import PermissionsContainer, { fetchLayerHOC } from 'containers/PermissionsContainer';

import EditForm from 'components/EditForm';
import EditFormTitle from 'components/EditForm/Title';
import EditFormActions from 'components/EditForm/Actions';
import TextInput from 'components/TextInput';
import PermissionsList from 'components/PermissionsList';
import ValidatedField from 'components/Form/ValidatedField';

import { buildSchema } from 'helpers/validationRules';

import cx from './RoleForm.module.scss';

const PermissionsListContainer = compose(
  PermissionsContainer,
  fetchLayerHOC
);

const PermissionsListComponent = PermissionsListContainer(PermissionsList);

const emptyRole = {
  name: '',
  permissions: [],
};

const RoleForm = (props) => {
  const [isPermissionsFetched, setIsPermissionsFetched] = useState(false);
  const formikRef = useRef();

  const getInitialValues = (roleFromProps) => ({
    ...emptyRole,
    ...roleFromProps,
  });

  const handlePermissionsFetch = () => {
    setIsPermissionsFetched(true);
  };

  const clearRole = () => {
    const {
      setDirty,
      navigateToRolesTab,
    } = props;

    // setDirty(false);

    formikRef.current.resetForm({ values: { ...emptyRole } });

    navigateToRolesTab();
  };

  const submitRole = (values) => {
    const {
      roleId,
      createRole,
      updateRole,
      setDirty,
    } = props;

    if (!roleId) {
      createRole(values).then((res) => {
        clearRole();
        return res;
      });
    } else {
      updateRole(values);
    }

    // setDirty(false);
  };

  const onRemoveRole = () => {
    const {
      roleId,
      removeRole,
      clearUserPermissionsForRemovedRole,
      role,
    } = props;

    clearUserPermissionsForRemovedRole(role);

    removeRole(roleId);
    clearRole();
  };

  const updateRole = (prop, value) => {
    formikRef.current.setFieldValue([prop], value);

    const { setDirty } = props;
    // setDirty(true);
  };

  const handlePermissionsChange = (activePermissions) => {
    updateRole('permissions', activePermissions);
  };

  const generateValidationSchema = () => buildSchema({
    name: 'required',
  });

  const {
    roleId,
    isUpdating,
    isCreating,
    role,
  } = props;

  const isFetching = roleId ? isUpdating : isCreating;
  const fetching = isPermissionsFetched ? isFetching : true;

  const disabledRoleEdit = fetching || role?.original;

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={getInitialValues(role)}
      validationSchema={generateValidationSchema()}
      onSubmit={submitRole}
    >
      {({
          values,
          handleSubmit,
        }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={cx.form}
          >
            <EditForm responsiveHeight>
              <div className={cx['form-body']}>
                <EditFormTitle
                  title={roleId ? 'Edit role' : 'Add role'}
                />

                <div className={cx.row}>
                  <ValidatedField>
                    <TextInput
                      name="name"
                      title="Title"
                      value={values.name}
                      validations={['required']}
                      onChange={e => updateRole('name', e.target.value)}
                      disabled={disabledRoleEdit}
                    />
                  </ValidatedField>
                </div>

                <div className={cx['permissions-container']}>
                  <PermissionsListComponent
                    onPermissionsFetched={handlePermissionsFetch}
                    disabled={values.original}
                    activePermissions={values.permissions.map(o => o.id)}
                    onChange={handlePermissionsChange}
                  />
                </div>
              </div>

              <div className="form-footer">
                <EditFormActions
                  deleteButton={!!roleId}
                  cancelButton
                  submitButton
                  disabledSubmit={disabledRoleEdit}
                  disabledCancel={fetching}
                  disabledDelete={disabledRoleEdit}
                  onDelete={onRemoveRole}
                  onCancel={clearRole}
                />
              </div>
            </EditForm>
          </form>
        );
      }}
    </Formik>
  );
};

RoleForm.propTypes = {
  navigateToRolesTab: PropTypes.func.isRequired,
  removeRole: PropTypes.func.isRequired,
  createRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  clearUserPermissionsForRemovedRole: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
};

class LoaderLayer extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !nextProps.dirty;
  }

  render() {
    const { roleId, role } = this.props;

    if (role && role.error) {
      return (
        <div className={cx.error}>Role does not exist</div>
      );
    }

    return <RoleForm {...this.props} roleId={roleId} isUpdating={role?.isUpdating} />;
  }
}

const DirtyCheckedWrapper = withRouter(dirtyCheckLayerHOC(LoaderLayer));

const RoleFormWrapper = (props) => {
  return (
    <DirtyCheckedWrapper
      {...props}
      dirtyCheckIdentity="roleId"
      responsiveHeight
    />
  );
};

export default RoleFormWrapper;
