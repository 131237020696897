import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import orderBy from 'lodash/orderBy';

import * as actions from 'modules/charts/actions';
import { setPatientForChart } from 'modules/patients/actions';

import * as selectors from 'modules/charts/selectors';
import * as settingsSelectors from 'modules/settings/selectors';
import { getUserInfo, getPermissions } from 'modules/user/selectors';

import { inIframe } from 'helpers';
import stringParser from 'helpers/common/string/string-parser';

const generalFetchRequestComponentTypes = [
  'short-patient-info',
  'encounter-header',
];

export const withPatientEncountersFetchLayer = Component => props => {
  const {
    patientId,
    permissions: { viewEditPatientClinicalInformation = false },
    isFetching = false
  } = props;

  const getPatientChartsFetchFunction = patientCandidateId => {
    const {
      sortBy,
      isAsc,
      fetchPatientCharts,
      fetchPatientChartsByPage,
      componentType,
    } = props;

    const isKnownComponentType = typeof componentType !== 'undefined' &&
     generalFetchRequestComponentTypes.includes(componentType);

    if (isKnownComponentType) {
      return fetchPatientCharts(patientCandidateId);
    }

    return fetchPatientChartsByPage(patientCandidateId, 0, sortBy, isAsc);
  };

  useEffect(() => {
    if (!isFetching && viewEditPatientClinicalInformation) {
      getPatientChartsFetchFunction(patientId);
    }
  }, [patientId]);

  return <Component {...props} />;
};

withPatientEncountersFetchLayer.propTypes = {
  isFetching: PropTypes.bool,
  permissions: PropTypes.shape({
    viewEditPatientClinicalInformation: PropTypes.bool.isRequired,
  }).isRequired,
  patientId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sortBy: PropTypes.string,
  isAsc: PropTypes.bool,
};

withPatientEncountersFetchLayer.defaultProps = {
  isFetching: false,
};

const mapStateToProps = (state, ownProps) => {
  const {
    patientId,
    activePage,
    isFetching,
    chartId,
    componentType,
  } = ownProps;

  let charts;

  if (
    typeof componentType !== 'undefined'
    && generalFetchRequestComponentTypes.includes(componentType)
  ) {
    charts = selectors.getCopyPreviousNotesCharts(state.charts);
  } else {
    charts = selectors.getChartsByPatientByPage(state.charts, patientId, activePage);
  }

  charts = orderBy(charts, ['selectUserDate'], 'desc');

  if (inIframe() && !!charts.length) {
    charts = [charts[0]];
  }

  return {
    charts,
    pageCount: selectors.getChartsbyPatientPagesCount(state.charts, patientId),
    isFetching: isFetching || selectors.getIsFetchingForPatient(state.charts, patientId),
    patientId,
    isCreating: selectors.getIsCreating(state.charts),
    unlockSignedProgressNote: settingsSelectors.getSettings(state).unlockSignedProgressNote,
    deleteEncounters: settingsSelectors.getSettings(state).deleteEncounters,
    activeChart: stringParser(chartId),
    userInfo: getUserInfo(state.user),
    seats: state.seats.byId,
    permissions: getPermissions(state.user),
    encountersType: 'patient-encounters',
    activePage,
  };
};

const mapActionCreators = {
  ...actions,
  setPatientForChart,
};

export default component => connect(mapStateToProps, mapActionCreators)(component);
