import { createSelector } from 'reselect';

const mapSettings = settings => {
  return Object.keys(settings)
        .filter((key) => !['isFetching', 'isFetched', 'isSaving'].includes(key))
        .reduce((newObj, key) => {
          newObj[key] = settings[key];
          return newObj;
        }, {});
};

export const getSettings = createSelector(
  [state => state.settings],
  settings => mapSettings(settings)
);

export const getIsFetched = settings => settings.isFetched;

export const getIsFetching = settings => settings.isFetching;

export const getIsSaving = settings => settings.isSaving;

export const getClinicId = settings => settings.id;
