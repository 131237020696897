import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import cx from './BrowserBackButton.module.scss';

import ArrowButton from './Arrow.svg';

const BrowserBackButton = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  const isDisplayBackButton = () => pathname.includes('charts');

  return (
    <div>
      {isDisplayBackButton() && (
        <div
          className={cx['back-button__wrapper']}
        >
          <span
            onClick={handleClick}
            className={cx['back-btn']}
          >
            <img alt="Back" src={ArrowButton} />
          </span>
        </div>
      )}
    </div>
  );
};

export default BrowserBackButton;
