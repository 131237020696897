import { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as selectors from 'modules/patientsHx/selectors';
import { getSettings } from 'modules/settings/selectors';

const PatientHistoryItemFetchLayer = ({ itemId, status, fetchItem, children }) => {
  useEffect(() => {
    if (itemId && !status.isFetched) {
      fetchItem(itemId);
    }
  }, [itemId, status.isFetched]);

  return children;
};

PatientHistoryItemFetchLayer.propTypes = {
  itemId: PropTypes.string,
  status: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isFetched: PropTypes.bool.isRequired,
  }).isRequired,
  fetchItem: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PatientHistoryItemFetchLayer;

// helper function to map patient history items to props
export const getMapStateToProps = (hxType, itemName, itemIdName) => (state, ownProps) => {
  const {
    patientId,
  } = ownProps;

  const itemId = ownProps[itemIdName];

  const item = itemId && selectors.getItemById(state.patientsHx, hxType, itemId);
  const status = selectors.getItemStatus(state.patientsHx, hxType, itemId);
  const isCreating = selectors.getIsCreating(state.patientsHx, hxType);
  const itemsInTable = selectors.getItems(state.patientsHx, hxType, patientId);

  return {
    [itemIdName]: itemId,
    [itemName]: item,
    status,
    isCreating,
    itemsInTable,
    shouldAddDiagnosisToMedical: getSettings(state).shouldAddDiagnosisToMedical,
  };
};
