import SuccessFetchingCartHandler from 'response/card/fetch/handle-action/success';
import FetchingEmptyCartHandler from 'response/card/fetch/handle-action/empty';

class Card {
  constructor({
                cardBrand = '', expireMonth = '', expireYear = '', last4 = '',
              }) {
    this.cardBrand = cardBrand;
    this.expireMonth = expireMonth;
    this.expireYear = expireYear;
    this.last4 = last4;
  }

  getPlainObject = () => {
    return {
      cardBrand: this.cardBrand,
      expireMonth: this.expireMonth,
      expireYear: this.expireYear,
      last4: this.last4,
    };
  };
}

export default class FetchCardResponseStatusHandler {
  constructor(response) {
    this.response = response;
  }

  getHandler = () => {
    const {
      status,
      body,
    } = this.response;

    if (status === 200) {
      return new SuccessFetchingCartHandler(new Card(body));
    }
    return new FetchingEmptyCartHandler(new Card({}));
  };
}
