const getProvidersScope = state => state.providers;

const getProvidersArr = (providersById) => {
  return Object.keys(providersById).map(id => providersById[id]);
};

export const getProviders = state => getProvidersArr(getProvidersScope(state).byId);

export const getProvidersWithSeat = (state) => {
  const providersArr = getProvidersArr(getProvidersScope(state).byId);

  return providersArr.filter(provider => provider?.setToSeat);
};

export const providerMap = provider => ({ id: provider.id, name: `${provider.firstName}  ${provider.lastName}` })

export const getIsFetching = state => getProvidersScope(state).isFetching;

export const getIsFetched = state => getProvidersScope(state).isFetched;

export const getActiveProviderId = state => getProvidersScope(state).currentProvider;
