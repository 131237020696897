import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { refTypeGenerator } from 'helpers/propsGenerator';

const useClickOutSide = ({ onClickOutSide, connectedNodes = [] }) => {
  const [touched, setTouched] = useState(false);
  const [startCoords, setStartCoords] = useState(null);
  const [endCoords, setEndCoords] = useState(null);

  const handleTouchStart = e => {
    setTouched(true);
    setStartCoords({
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    });
  };

  const handleTouchMove = e => {
    if (!touched) return;
    setEndCoords({
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    });
  };

  const handleTouchEnd = () => {
    if (!endCoords) {
      setTouched(false);
      setStartCoords(null);
      // onClickOutSideHandler(e);
      return;
    }

    const distanceX = Math.abs(endCoords.x - startCoords.x);
    const distanceY = Math.abs(endCoords.y - startCoords.y);

    const distance = Math.sqrt(distanceX**2 + distanceY**2);

    setTouched(false);
    setStartCoords(null);
    setEndCoords(null);

    if (distance < 10) {
      // onClickOutSideHandler(e);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (connectedNodes.some(node => !node?.current?.contains(event.target))) {
        onClickOutSide();
      }
    }

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [connectedNodes]);
};

const EventListenerLayer = ({ onClickOutSide, children, connectedNodes }) => {
  useClickOutSide({ onClickOutSide, connectedNodes });

  return children;
};

export default EventListenerLayer;

EventListenerLayer.propTypes = {
  onClickOutSide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  connectedNodes: PropTypes.arrayOf(refTypeGenerator.obj),
};
