import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import UsersContainer from 'containers/Users/UsersContainer';

import UserEditForm from 'components/UserEditForm';
import Modal from 'components/Modal';
import UsersTable from './UsersTable';
import UserRouteWrapper from 'components/UserRouteWrapper';

import { getUsersByStatus } from 'modules/users/selectors';
import { getUserInfo } from 'modules/user/selectors';

const ConnectedUsersTable = UsersContainer(UsersTable);

export const UsersList = ({ active = true }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const users = useSelector(state => getUsersByStatus(state.users, active));
  const { id } = useSelector(state => getUserInfo(state.user));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <UserEditForm.Wrapper>
      <UserEditForm.PlanCard>
        <ConnectedUsersTable
          active={active}
          users={users}
          showModal={showModal}
          currentUserId={id}
        />
      </UserEditForm.PlanCard>
      <UserEditForm.MedData>
        <UserRouteWrapper
          showModal={showModal}
        />
      </UserEditForm.MedData>
      <Modal
        isOpen={isModalVisible}
        data={{
          title: 'Warning',
        }}
        onModalClose={closeModal}
      >
        Please remove user from seat before disabling chart sign permission.
      </Modal>
    </UserEditForm.Wrapper>
  );
};
