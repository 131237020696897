import React from 'react';
import PropTypes from 'prop-types';
import ROSItem from './RosItem';
import cx from './ElementsViewItem.module.scss';
import RegularElement from './RegularElement';

import { isNullOrUndefined } from 'helpers';

const PlainItem = (props) => {
  const {
    onClick,
    elementViewItem,
    match = ''
  } = props;
  if (props.isROS && !isNullOrUndefined(props.activeSystem)) {
    return (
      <ROSItem
        onClick={onClick}
        item={elementViewItem}
        match={match}
      />
    );
  }

  const onClickWrapper = () => onClick(null);

  return (
    <form
      className={cx['outer-wrapper']}
      onSubmit={() => {}}
    >
      <RegularElement
        onClick={onClickWrapper}
        item={elementViewItem}
        match={match}
        editMode={false}
      />
    </form>
  );
};

PlainItem.propTypes = {
  isROS: PropTypes.bool.isRequired,
  match: PropTypes.string,
  activeSystem: PropTypes.number.isRequired,
  elementViewItem: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PlainItem;
