import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from 'containers/App/AppContext/AppContextProvider';
import { ChartContext } from 'routes/routes/App/routes/Charts/components/Chart/context';

import SystemContainer from 'containers/SystemContainer';

import System from 'components/NoteSystems/System';
import NoteInput from 'components/NoteSystems/Systems/NoteInput';

import { hpChartTabs } from 'helpers/chart';

import cx from './SystemsList.module.scss';

const ConnectedSystem = SystemContainer(System);

const systemTypesWhereCreationDisabled = [
  hpChartTabs.A.systems.ICD_TEN.type,
  hpChartTabs.BILLING.systems.CPT.type,
];

const systemTypesWhereElementUpdateDisabled = [
  hpChartTabs.A.systems.ICD_TEN.type,
  hpChartTabs.Plan.systems.PRESCRIPTIONS.type,
  hpChartTabs.BILLING.systems.CPT.type,
];

const stepsWhereElementUpdateDisabled = [
  hpChartTabs.BILLING.step,
];

const stepsWhereCreationDisabled = [
  hpChartTabs.BILLING.step,
];

const stepsToAllowAttachingIcdTenCodes = [
  hpChartTabs.Plan.step,
];

const systemTypesToAllowAttachingIcdTenCodes = [
  hpChartTabs.BILLING.systems.CPT.type,
  hpChartTabs.BILLING.systems.HCPC.type
];

/**
 * This component is used when we need to render both multiple and single systems
 * In case, when we render single system, the system note input is being shown for that system
 */
const SystemsList = (props) => {
  const {
    transitionToSystem,
    openModal,
    systems,
    chartId,
    activeSystem,
    patientId,
    exactMatch,
    broadMatch,
    isNoteInputActive,
    elementsCount,
    systemNotes,
    systemNote,
    isSystemNoteSaving,
    isSystemNoteFetching,
    updateSystemNote,
    createSystemNote,
    stepId,
    isChartSaving,
    saveChart,
    saveChartingSessionWrapper,
  } = props;

  const {
    createSubelementProcess,
  } = useContext(ChartContext);

  const { permissions } = useContext(AppContext);

  // kostyl
  const getNotICDTen = system => system.name !== 'ICD-10'

  const allowCreationBySystem = (system) => {
    const {
      type,
    } = system;

    const {
      addEditElements,
    } = permissions;

    return !systemTypesWhereCreationDisabled.includes(type)
      && !stepsWhereCreationDisabled.includes(system.chartingId)
      && addEditElements;
  };

  const allowElementUpdateBySystem = (system) => {
    const {
      type,
      chartingId,
    } = system;

    const {
      addEditElements,
    } = permissions;

    return !systemTypesWhereElementUpdateDisabled.includes(type)
      && !stepsWhereElementUpdateDisabled.includes(chartingId)
      && addEditElements;
  };

  const defineIsAllowAttachingIcdTenCodes = (system) => {
    const { chartingId, type } = system;
    return stepsToAllowAttachingIcdTenCodes.includes(chartingId) ||
      systemTypesToAllowAttachingIcdTenCodes.includes(type);
  }

  const selectElementCallback = () => saveChartingSessionWrapper(chartId, stepId)

  const selectSubElementCallback = () => saveChartingSessionWrapper(chartId, stepId)

  const selectAfterFirstSubElementCallback = () => saveChartingSessionWrapper(chartId, stepId)

  let filteredSystems = systems;

  if (stepId === 6) {
    const nonEditableSystems = systems.filter(system => (system.type !== 5) && (system.name.toLowerCase() !== 'follow-up'));
    const followUpSystem = systems.filter(system => system.name.toLowerCase() === 'follow-up');
    const editableSystems = systems.filter(system => system.type === 5);

    filteredSystems = [...nonEditableSystems, ...editableSystems, ...followUpSystem];
  }

  const systemNoteProps = {
    systemNotes,
    systemNote,
    isSystemNoteSaving,
    isSystemNoteFetching,
    updateSystemNote,
    createSystemNote,
  };

  return (
    <div className={cx['systems-list']}>
      {filteredSystems.map(system => (
        <div key={system.id}>
          <ConnectedSystem
            selectSubElementCallback={selectSubElementCallback}
            selectAfterFirstSubElementCallback={selectAfterFirstSubElementCallback}
            selectElementCallback={selectElementCallback}
            isAllowAttachingIcdTenCodes={defineIsAllowAttachingIcdTenCodes(system)}
            allowElementUpdate={allowElementUpdateBySystem(system)}
            createSubelement={createSubelementProcess}
            allowCreation={allowCreationBySystem(system)}
            activeSystem={activeSystem}
            key={system.id}
            system={system}
            onSystemClick={() => transitionToSystem(system.id)}
            chartId={chartId}
            onRemove={() => openModal(system)}
            patientId={patientId}
            exactMatch={exactMatch}
            broadMatch={broadMatch}
            isChartSaving={isChartSaving}
            saveChart={saveChart}
            {...systemNoteProps}
          />
          {
            isNoteInputActive && getNotICDTen(system) &&
            <NoteInput
              title={system.name}
              systemId={system.id}
              chartId={chartId}
              elementsCount={elementsCount}
              {...systemNoteProps}
            />
          }
        </div>
      ))}
    </div>
  );
}

SystemsList.propTypes = {
  saveChart: PropTypes.func.isRequired,
  transitionToSystem: PropTypes.func.isRequired,
  updateSystemNote: PropTypes.func.isRequired,
  createSystemNote: PropTypes.func.isRequired,
  saveChartingSessionWrapper: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  systems: PropTypes.array,
  isNoteInputActive: PropTypes.bool,
  patientId: PropTypes.number,
  chartId: PropTypes.number,
  activeSystem: PropTypes.number,
}


export default SystemsList;
