export const CHARTING_ASSETS_FETCH = 'CHARTING_ASSETS_FETCH';
export const CHARTING_ASSETS_FETCH_SUCCESS = 'CHARTING_ASSETS_FETCH_SUCCESS';
export const CHARTING_ASSETS_FETCH_FAIL = 'CHARTING_ASSETS_FETCH_FAIL';

export const ADD_ASSET_SYSTEM = 'ADD_ASSET_SYSTEM';

export const ELEMENT_CREATE = 'ELEMENT_CREATE';
export const ELEMENT_CREATE_SUCCESS = 'ELEMENT_CREATE_SUCCESS';
export const ELEMENT_CREATE_FAIL = 'ELEMENT_CREATE_FAIL';

export const ELEMENT_UPDATE = 'ELEMENT_UPDATE';
export const ELEMENT_UPDATE_SUCCESS = 'ELEMENT_UPDATE_SUCCESS';
export const ELEMENT_UPDATE_FAIL = 'ELEMENT_UPDATE_FAIL';

export const ELEMENT_DELETE = 'ELEMENT_DELETE';
export const ELEMENT_DELETE_SUCCESS = 'ELEMENT_DELETE_SUCCESS';
export const ELEMENT_DELETE_FAIL = 'ELEMENT_DELETE_FAIL';

export const ELEMENTS_DELETE = 'ELEMETS_DELETE';
export const ELEMENTS_DELETE_SUCCESS = 'ELEMENTS_DELETE_SUCCESS';
export const ELEMENTS_DELETE_FAIL = 'ELEMENTS_DELETE_FAIL';

export const SYSTEM_CREATE = 'SYSTEM_CREATE';
export const SYSTEM_CREATE_SUCCESS = 'SYSTEM_CREATE_SUCCESS';
export const SYSTEM_CREATE_FAIL = 'SYSTEM_CREATE_FAIL';

export const SYSTEM_UPDATE = 'SYSTEM_UPDATE';
export const SYSTEM_UPDATE_SUCCESS = 'SYSTEM_UPDATE_SUCCESS';
export const SYSTEM_UPDATE_FAIL = 'SYSTEM_UPDATE_FAIL';

export const SYSTEM_DELETE = 'SYSTEM_DELETE';
export const SYSTEM_DELETE_SUCCESS = 'SYSTEM_DELETE_SUCCESS';
export const SYSTEM_DELETE_FAIL = 'SYSTEM_DELETE_FAIL';

export const SUBELEMENT_CREATE = 'SUBELEMENT_CREATE';
export const SUBELEMENT_CREATE_SUCCESS = 'SUBELEMENT_CREATE_SUCCESS';
export const SUBELEMENT_CREATE_FAIL = 'SUBELEMENT_CREATE_FAIL';

