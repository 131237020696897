import { handleActions } from 'redux-actions';

import * as authActions from 'api/auth';

import * as authConstants from './user/auth/constants';

export const APP_LOCK_TOGGLE = 'APP_LOCK_TOGGLE';

export const APP_LOCK = 'APP_LOCK';
export const APP_LOCK_SUCCESS = 'APP_LOCK_SUCCESS';
export const APP_LOCK_FAIL = 'APP_LOCK_FAIL';

export const APP_UNLOCK = 'APP_UNLOCK';
export const APP_UNLOCK_SUCCESS = 'APP_UNLOCK_SUCCESS';
export const APP_UNLOCK_FAIL = 'APP_UNLOCK_FAIL';

const initialState = {
  locked: false,
  processing: false,
  error: null,
};

export default handleActions({
  [APP_LOCK]: state => ({
    ...state,
    processing: true,
    error: null,
  }),
  [APP_LOCK_SUCCESS]: state => ({
    ...state,
    locked: true,
    processing: false,
    error: null,
  }),
  [APP_LOCK_FAIL]: state => ({
    ...state,
    processing: false,
    error: null,
  }),
  [APP_UNLOCK]: state => ({
    ...state,
    processing: true,
    error: null,
  }),
  [APP_UNLOCK_SUCCESS]: state => ({
    ...state,
    locked: false,
    processing: false,
  }),
  [APP_UNLOCK_FAIL]: (state, action) => ({
    ...state,
    processing: false,
    error: {
      status: action.error.status,
      message: action.error.message,
    },
  }),
  [authConstants.USER_LOGIN_SUCCESS]: state => ({
    ...state,
    processing: false,
    locked: false,
    error: null,
  }),
}, initialState);

export const lock = () => ({
  types: [
    APP_LOCK,
    APP_LOCK_SUCCESS,
    APP_LOCK_FAIL,
  ],
  promise: authActions.lock,
});

export const unlock = pin => ({
  types: [
    APP_UNLOCK,
    APP_UNLOCK_SUCCESS,
    APP_UNLOCK_FAIL,
  ],
  promise: () => authActions.unlock(pin),
});

export const isLocked = state => state.locked;
export const isProcessing = state => state.processing;
export const getError = state => state.error;
