import React from 'react';
import cx from './PlanDescription.module.scss';
import PropTypes from 'prop-types';

const PlanDescription = ({
  children,
}) => (
    <div className={cx['edit-form']}>
      <div className={cx['edit-form-wrapper']}>
        {children}
      </div>
    </div>
  );

PlanDescription.propTypes = {
  children: PropTypes.node,
};

export default PlanDescription;
