import React from 'react';
import { compose } from 'redux';

import BlockedForReadOnlyConnect from 'containers/BlockedForReadOnly/BlockedForReadOnlyConnect';
import isUserAssignedToSeat from 'helpers/users/is-current-user-assigned-to-seat';
import { SEAT_PLANS } from 'components/SeatUsersDropdown/helpers';

const BlockedForReadOnlyFetchLayer = (props) => {
  const {
    pastdue,
    seats,
    currentUserId,
    children,
  } = props;

  const {
    isReadOnly,
  } = pastdue;

  const isCurrentUserAssignedToVipSeat = isUserAssignedToSeat(seats, currentUserId, SEAT_PLANS.vipPlan.name);
  const isBlockedForReadOnly = isReadOnly && !isCurrentUserAssignedToVipSeat;

  return React.cloneElement(
    children,
    { isBlockedForReadOnly });
};

const ComposedBlockedForReadOnlyFetchLayer = compose(
  BlockedForReadOnlyConnect,
)(BlockedForReadOnlyFetchLayer);


export default ComposedBlockedForReadOnlyFetchLayer;
